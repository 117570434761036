export const debounce = <T extends (...args: any[]) => any>(fn: T, ms = 300) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      fn.apply(null, args);
    }, ms);
  };
};

export const shallowCompareObjects = (o1: unknown, o2: unknown): boolean => {
  return JSON.stringify(o1) === JSON.stringify(o2);
};

export const isString = (value: unknown): value is string => typeof value === 'string';
export const isNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(value);
export const isObject = (obj: unknown): obj is Record<string, unknown> => obj === Object(obj);

export const referenceToNestedObject = <T extends Record<string, unknown>>(object: T, keys: string[]) => {
  return keys.reduce((acc: T, key: string) => {
    if (key in acc && isObject(acc[key])) return acc[key] as T;

    return object;
  }, object);
};

// @ts-ignore
export function getValueOfNestedObject<T extends Record<string, unknown>>(obj: T, path: string) {
  if (!path) return obj;
  const properties = path.split('-');

  // @ts-ignore
  return getValueOfNestedObject(obj ? obj[properties.shift()] : {}, properties.join('-'));
}

export function deleteNestedKeyAndCollapseEmptyProperties<T extends Record<string, unknown>>(
  object: T,
  keys: string[]
): T {
  const last: string = keys.pop() ?? '';
  delete referenceToNestedObject(object, keys)[last];
  return object;
}

export function updateValueOfNestedObject<T extends Record<string, unknown>, U extends string[], K>(
  object: T,
  keys: U,
  value: K
): T {
  const last: string = keys.pop() ?? '';

  // @ts-ignore
  referenceToNestedObject(object, keys)[last] = value;

  return object;
}

export const getObjectCopyWithoutMethods = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}
