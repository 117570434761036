import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components';
import classNames from 'classnames';
import React, { FC } from 'react';

import { MODAL_STATUS, MODAL_TITLE } from '../../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../../hooks';
import { DatabaseSnapshotsResponse } from '../../../../../store/api/database/types';
import { selectDbActiveSnapshots, selectDbSelectedSnapshotNames } from '../../../../../store/selectors';
import { getFormatedDateAndTimeString } from '../../../../../utils';

interface Props {
  data?: DatabaseSnapshotsResponse;
}

export const SnapshotsView: FC<Props> = ({ data }) => {
  const { setSnapshotsViewerVisible, setSelectedSnapshotName, setMessageModal } = useActionCreators();
  const selectedSnapshotNames = useAppSelector(selectDbSelectedSnapshotNames);
  const { checked, halfChecked } = useAppSelector(selectDbActiveSnapshots);

  const handleSnapshotSelect = async (name: string) => {
    if (!selectedSnapshotNames.includes(name) && selectedSnapshotNames.length === 6) {
      setMessageModal({
        type: MODAL_STATUS.WARNING,
        title: MODAL_TITLE.warning,
        description: 'modals.max-images',
      });

      await new Promise(() => {
        setTimeout(() => setMessageModal(false), 2000);
      });

      return;
    }

    setSelectedSnapshotName(name);
  };

  return (
    <div className="snapshots-view__wrapper">
      <div className="snapshots-view__content">
        <h3 className="side-menu__title">
          <TranslatedText textKey="Snapshots" />
        </h3>
        {data?.map(({ analysisResultId, snapshots, sampleName, analysisDate }) => {
          return (
            (checked.includes(analysisResultId) || halfChecked.includes(analysisResultId)) && (
              <div key={analysisResultId}>
                <h4 className="snapshots-view__row__title">
                  {sampleName}_{getFormatedDateAndTimeString(analysisDate)}
                </h4>
                <div className="snapshots-view__row__content">
                  {snapshots.map(
                    ({ snapshotName, snapshotBase64, analysisName }) =>
                      checked.includes(snapshotName) && (
                        <div
                          key={snapshotName}
                          className={classNames('snapshots-view__row-item__wrapper', {
                            selected: selectedSnapshotNames.includes(snapshotName),
                          })}
                          onClick={() => handleSnapshotSelect(snapshotName)}
                        >
                          <div className="img__wrapper">
                            <img src={`data:image/png;base64,${snapshotBase64}`} alt={analysisName} />
                          </div>
                          <div className="snapshots-view__row-item__title">{analysisName}</div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="snapshots-view__controls">
        <Button
          title={'Compare snapshots'}
          disabled={!selectedSnapshotNames.length}
          onClick={() => setSnapshotsViewerVisible(true)}
        />
        <Button
          title={'Reset selected'}
          disabled={!selectedSnapshotNames.length}
          onClick={() => setSelectedSnapshotName([])}
        />
      </div>
    </div>
  );
};
