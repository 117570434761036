import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import Button from '@sms/plasma-ui/lib/components/Button';
import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React from 'react';

import {
  BACKWALL_MODIFICATION_MODES,
  BACKWALL_MODIFICATION_OPTIONS,
  BACKWALL_MODIFICATION_OPTIONS_TITLES,
  BACKWALL_ZONES_SELECTION_TYPE,
  BACKWALL_ZONES_SELECTION_TYPE_OPTIONS,
  BACKWALL_ZONES_TYPES,
  BACKWALL_ZONES_TYPES_OPTIONS,
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  GREY_SECONDARY_EXTRA_LIGHT,
  WHITE_PRIMARY,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import {
  useDeleteDistanceZoneMutation,
  useModifyDistanceLineMutation,
  useModifyDistanceRectMutation,
} from '../../../../store/api/backwall';
import {
  IDistanceLineZone,
  IDistanceRectZone,
  TDistanceSelectionType,
  TDistanceZoneType,
} from '../../../../store/api/backwall/types';
import {
  selectAllDistanceZones,
  selectDistanceModificationMode,
  selectDistanceSelectionTypes,
  selectDistanceZoneTypes,
  selectSelectedDistanceIds,
} from '../../../../store/selectors';

export const DistanceModify = () => {
  const modificationMode = useAppSelector(selectDistanceModificationMode);
  const {
    setDistanceModificationMode,
    setSelectedDistanceSelectionTypes,
    setSelectedDistanceZoneTypes,
    resetBackwallSelectedDistanceIds,
    setBackwallSelectedDistanceIds,
  } = useActionCreators();
  const allZones = useAppSelector(selectAllDistanceZones);
  const selectedZoneTypes = useAppSelector(selectDistanceZoneTypes);
  const selectedSelectionTypes = useAppSelector(selectDistanceSelectionTypes);
  const selectedDistanceIds = useAppSelector(selectSelectedDistanceIds);
  const [modifyLineTrigger] = useModifyDistanceLineMutation();
  const [modifyRectTrigger] = useModifyDistanceRectMutation();
  const [deleteZoneTrigger] = useDeleteDistanceZoneMutation();

  const switchModificationMode = (e: any) => {
    const mode = e.target.name;

    setDistanceModificationMode(mode);
    resetBackwallSelectedDistanceIds();
    setSelectedDistanceZoneTypes();
    setSelectedDistanceSelectionTypes();

    if (mode === BACKWALL_MODIFICATION_MODES.insert) {
      setSelectedDistanceZoneTypes([BACKWALL_ZONES_TYPES.other]);
      setSelectedDistanceSelectionTypes([BACKWALL_ZONES_SELECTION_TYPE.rectangular]);
    }
  };

  const handleZoneTypeChange = async (e: any, type: TDistanceZoneType) => {
    if (modificationMode === BACKWALL_MODIFICATION_MODES.insert) {
      setSelectedDistanceZoneTypes([type]);
    }

    if (modificationMode === BACKWALL_MODIFICATION_MODES.modify) {
      if (selectedDistanceIds.length === 0) return;

      setSelectedDistanceZoneTypes([type]);

      const lZone = allZones?.linearZones.find((i) => i.id === selectedDistanceIds[0]);

      if (lZone) {
        await modifyLineTrigger({ ...lZone, zoneType: type });
        return;
      }

      const rZone = allZones?.rectangularZones.find((i) => i.id === selectedDistanceIds[0]);

      if (rZone) {
        await modifyRectTrigger({ ...rZone, zoneType: type });
      }
    }

    if (modificationMode === BACKWALL_MODIFICATION_MODES.delete) {
      if (!allZones) return;

      const types = selectedZoneTypes.includes(type)
        ? selectedZoneTypes.filter((i) => i !== type)
        : [...selectedZoneTypes, type];
      setSelectedDistanceZoneTypes(types);

      if (types.length === 0 && selectedSelectionTypes.length === 0) {
        resetBackwallSelectedDistanceIds();
        return;
      }

      const neededZones: (IDistanceLineZone | IDistanceRectZone)[] = [];

      if (selectedSelectionTypes.includes(BACKWALL_ZONES_SELECTION_TYPE.lines) || selectedSelectionTypes.length === 0) {
        neededZones.push(...allZones.linearZones);
      }

      if (
        selectedSelectionTypes.includes(BACKWALL_ZONES_SELECTION_TYPE.rectangular) ||
        selectedSelectionTypes.length === 0
      ) {
        neededZones.push(...allZones.rectangularZones);
      }

      const matchedIds = neededZones
        .filter(({ zoneType }) => (types.length > 0 ? types.includes(zoneType) : true))
        .map((i) => i.id);

      resetBackwallSelectedDistanceIds();
      setBackwallSelectedDistanceIds(matchedIds);
    }
  };

  const handleSelectionTypeChange = (e: any, type: TDistanceSelectionType) => {
    if (modificationMode === BACKWALL_MODIFICATION_MODES.insert) {
      setSelectedDistanceSelectionTypes([type]);
    }

    if (modificationMode === BACKWALL_MODIFICATION_MODES.delete) {
      if (!allZones) return;

      const types = selectedSelectionTypes.includes(type)
        ? selectedSelectionTypes.filter((i) => i !== type)
        : [...selectedSelectionTypes, type];
      setSelectedDistanceSelectionTypes(types);

      if (types.length === 0 && selectedZoneTypes.length === 0) {
        resetBackwallSelectedDistanceIds();
        return;
      }

      const neededZones: (IDistanceLineZone | IDistanceRectZone)[] = [];

      if (types.includes(BACKWALL_ZONES_SELECTION_TYPE.lines) || types.length === 0) {
        neededZones.push(...allZones.linearZones);
      }

      if (types.includes(BACKWALL_ZONES_SELECTION_TYPE.rectangular) || types.length === 0) {
        neededZones.push(...allZones.rectangularZones);
      }

      const matchedIds = neededZones
        .filter((x) => (selectedZoneTypes.length > 0 ? selectedZoneTypes.includes(x.zoneType) : true))
        .map((i) => i.id);

      resetBackwallSelectedDistanceIds();
      setBackwallSelectedDistanceIds(matchedIds);
    }
  };

  const deleteSelectedDistanceZonesHandler = () => {
    deleteZoneTrigger(selectedDistanceIds);
  };

  return (
    <>
      <h3 className="side-menu__title">
        <TranslatedText textKey="Modify" />
      </h3>
      <div className="distance__modify__selection">
        <div className="distance__modify__zones">
          <h3 className="sub-menu__title">
            <TranslatedText textKey="backwall.zones-types" />
          </h3>
          <ul className="modify__zones__radio-group">
            {BACKWALL_ZONES_TYPES_OPTIONS.map((type) => (
              <li key={type}>
                <Checkbox
                  hint="Hint"
                  hintMode="hidden"
                  title={type}
                  value={type}
                  disabled={
                    !modificationMode ||
                    ((modificationMode === BACKWALL_MODIFICATION_MODES.modify ||
                      modificationMode === BACKWALL_MODIFICATION_MODES.info) &&
                      selectedDistanceIds.length === 0)
                  }
                  name={type}
                  checked={selectedZoneTypes.includes(type)}
                  onChange={(e) => handleZoneTypeChange(e, type)}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="distance__modify__zones">
          <h3 className="sub-menu__title">
            <TranslatedText textKey="backwall.zones-selection-type" />
          </h3>
          <ul className="modify__zones__radio-group">
            {BACKWALL_ZONES_SELECTION_TYPE_OPTIONS.map((type) => (
              <li key={type}>
                <Checkbox
                  hint="Hint"
                  hintMode="hidden"
                  title={type}
                  value={type}
                  disabled={
                    !modificationMode ||
                    modificationMode === BACKWALL_MODIFICATION_MODES.modify ||
                    (modificationMode === BACKWALL_MODIFICATION_MODES.info && selectedDistanceIds.length === 0)
                  }
                  name={type}
                  checked={selectedSelectionTypes.includes(type)}
                  onChange={(e) => handleSelectionTypeChange(e, type)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="distance__modification-options">
        {BACKWALL_MODIFICATION_OPTIONS.map((option, idx) => (
          <Button
            key={idx}
            name={option}
            title={BACKWALL_MODIFICATION_OPTIONS_TITLES[option]}
            style={{
              backgroundColor: modificationMode === option ? BLUE_PRIMARY : 'transparent',
              borderColor: modificationMode === option ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
              color: modificationMode === option ? WHITE_PRIMARY : BLACK_PRIMARY,
            }}
            onClick={switchModificationMode}
          />
        ))}
        <Button
          title={'common.delete-selected'}
          disabled={modificationMode !== BACKWALL_MODIFICATION_MODES.delete}
          onClick={deleteSelectedDistanceZonesHandler}
        />
      </div>
    </>
  );
};
