import React, { FC } from 'react';

import { RED_PRIMARY, SLICE_TYPES, WHITE_PRIMARY } from '../../../../consts';
import { IGeometryBase } from '../../../../store/api/geometry/types';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  getBaseSvgElemStyles,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { Path } from '../../../Path';
import { GeometryImage } from '../GeometryImage';

interface IProps {
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  filledDomains: TTupleOfTwoNumbers;
  geometryBase: IGeometryBase;
  graphParams: IGraphParams;
  sliceType: string;
}

export const GeometryBase: FC<IProps> = ({
  cartesianModeParams,
  domains,
  filledDomains,
  geometryBase,
  graphParams,
  sliceType,
}) => {
  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);
  const lineSigns = geometryBase?.cornerPoints && [
    {
      sign: 'L0',
      x: getXOffset(Math.max(geometryBase.cornerPoints[3].x, geometryBase.cornerPoints[2].x)),
      y: getYOffset((geometryBase.cornerPoints[3].y - geometryBase.cornerPoints[2].y) / 2),
    },
    {
      sign: 'L1',
      x: getXOffset((geometryBase.cornerPoints[0].x - geometryBase.cornerPoints[3].x) / 2),
      y: getYOffset(Math.min(geometryBase.cornerPoints[3].y, geometryBase.cornerPoints[0].y) - 10), // minus 10 is for offsetting the sign
    },
    {
      sign: 'L2',
      x: getXOffset(Math.min(geometryBase.cornerPoints[0].x, geometryBase.cornerPoints[1].x) - 15), // minus 15 is for offsetting the sign
      y: getYOffset((geometryBase.cornerPoints[0].y - geometryBase.cornerPoints[1].y) / 2),
    },
    {
      sign: 'L3',
      x: getXOffset((geometryBase.cornerPoints[1].x - geometryBase.cornerPoints[2].x) / 2),
      y: getYOffset(Math.min(geometryBase.cornerPoints[1].y, geometryBase.cornerPoints[2].y) + 10), // plus 10 is for offsetting the sign
    },
  ];

  return (
    <>
      <GeometryImage
        cartesianModeParams={cartesianModeParams}
        domains={domains}
        filledDomains={filledDomains}
        image={geometryBase.imageBase64}
      />
      {geometryBase.cornerPoints && sliceType !== SLICE_TYPES.cut && (
        <>
          <Path
            cartesianModeParams={cartesianModeParams}
            data={geometryBase.cornerPoints}
            domains={domains}
            graphParams={graphParams}
            isCartesianMode={true}
            style={getBaseSvgElemStyles(RED_PRIMARY, 1.5)}
          />
          {lineSigns?.map(({ sign, x, y }, idx) => (
            <text key={idx} x={x} y={y} stroke={WHITE_PRIMARY} fill={WHITE_PRIMARY}>
              {sign}
            </text>
          ))}
        </>
      )}
    </>
  );
};
