import { createSelector } from '@reduxjs/toolkit';

import { TLogo } from '../../types';
import { settingsApi } from '../api/settings';
import { TypedRootState } from '../types';

export const selectSettings = (state: TypedRootState) => state.settings;
export const selectFetchedSettings = (state: TypedRootState) =>
  settingsApi.endpoints.getSettings.select(undefined)(state)?.data;

export const selectInitialSettings = createSelector(selectFetchedSettings, (initialSettings) => {
  if (!initialSettings) return;

  const { generalAnalysisSettings, astmSettings, gostSettings } = initialSettings;
  return { ...generalAnalysisSettings, astmSettings, gostSettings };
});

export const selectLogoImage = createSelector(
  [
    (state: TypedRootState) => settingsApi.endpoints.getLogos.select()(state)?.data,
    (state: TypedRootState, id: TLogo) => id,
  ],
  (logosData, id) => logosData?.[id] || '',
);
