import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const forceAnalysisChangeSlice = createSlice({
  name: 'forceAnalysisChange',
  initialState,
  reducers: {
    forceAnalysisChange: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { actions: forceAnalysisChangeActions, reducer: forceAnalysisChange } = forceAnalysisChangeSlice;
