import React from 'react';

import { BLACK_PRIMARY, TRANSLUCENT_DIM_GREY } from '../../../consts';

interface IProps {
  fill?: string;
  disabled?: boolean;
}

export const Ruler = ({ fill = BLACK_PRIMARY, disabled = false }: IProps) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style={{ transform: 'rotate(45deg)' }}
    >
      <g id="ruler">
        <path
          d="M0.586,34L16,49.414L49.414,16L34,0.586L0.586,34z M16,46.586L3.414,34L5.5,31.914l4.793,4.793l1.414-1.414L6.914,30.5
          l3.586-3.586l2.793,2.793l1.414-1.414L11.914,25.5l3.586-3.586l4.793,4.793l1.414-1.414L16.914,20.5l3.586-3.586l2.793,2.793
          l1.414-1.414L21.914,15.5l3.586-3.586l4.793,4.793l1.414-1.414L26.914,10.5L30.5,6.914l2.793,2.793l1.414-1.414L31.914,5.5
          L34,3.414L46.586,16L16,46.586z"
          fill={disabled ? TRANSLUCENT_DIM_GREY : fill}
        />
      </g>
      <g></g>
    </svg>
  );
};
