export const getDateAttributes = (inputDate: string | Date) => {
  const date = new Date(inputDate);
  const timeZoneOffset = new Date().getTimezoneOffset();
  const localDate = new Date(date.getTime() - timeZoneOffset * 60000);

  const DD = String(localDate.getDate()).padStart(2, '0');
  const MM = String(localDate.getMonth() + 1).padStart(2, '0');
  const year = localDate.getFullYear();
  const hh = String(localDate.getHours()).padStart(2, '0');
  const mm = String(localDate.getMinutes()).padStart(2, '0');
  const ss = String(localDate.getSeconds()).padStart(2, '0');

  return { DD, MM, year, hh, mm, ss };
};

export const getFormattedCurrentDateAndTime = () => {
  const { DD, MM, year, hh, mm, ss } = getDateAttributes(new Date());
  return `${year}-${MM}-${DD}-${hh}-${mm}-${ss}`;
};

export const getFormatedDateAndTimeString = (date: string | Date) => {
  const { DD, MM, year, hh, mm } = getDateAttributes(date);
  return `${DD}.${MM}.${year} ${hh}:${mm}`;
};

export const getRangeOfTime = (weeks = 1) => {
  const date = new Date();
  const timestamp = date.getTime();
  const timeZoneOffset = date.getTimezoneOffset();
  const start = new Date(timestamp - timeZoneOffset * 60 * 1000 - weeks * 7 * 24 * 60 * 60 * 1000).toISOString();
  const end = new Date(timestamp - timeZoneOffset * 60 * 1000).toISOString();

  return {
    start,
    end,
  };
};
