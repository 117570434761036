import { TextInput, TranslatedText } from '@sms/plasma-ui';
import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React, { ChangeEvent } from 'react';

import { FILE_TYPE_VALUES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { TFileType } from '../../../store/api/scandata/types';
import { selectDBScanDataFilters } from '../../../store/selectors';
import { BDDateRangeInput } from '../DBDateRangeInput';

export const DBSampleParameters = () => {
  const scanDataFileFilter = useAppSelector(selectDBScanDataFilters);
  const { setDatabaseName, setDatabaseFileType } = useActionCreators();

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDatabaseName(e.target.value);
  };

  const handleFileTypeChange = (value: TFileType) => {
    setDatabaseFileType(value);
  };

  return (
    <div className="db__form-section">
      <h3 className="side-menu__title">
        <TranslatedText textKey="db.sample-parameters" />
      </h3>
      <div className="db__form">
        <div className="db__form-input">
          <TextInput
            label={'Name'}
            name={'name'}
            value={scanDataFileFilter.name.value}
            onChange={handleNameChange}
          />
        </div>
        <BDDateRangeInput values={scanDataFileFilter.createdAt} section={'scanDataFileFilter'} label="db.upload-date" />
        <div className="db__form-input">
          <p>
            <TranslatedText textKey="File type" />
          </p>
          <div className="db__form-choices">
            {FILE_TYPE_VALUES.map((value) => (
              <Checkbox
                key={value}
                name={value}
                title={value}
                checked={scanDataFileFilter.fileType.value === value}
                onChange={() => handleFileTypeChange(value)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
