import './styles.scss';

import { Button, TranslatedText } from '@sms/plasma-ui';
import { Pagination } from '@sms/plasma-ui/lib/components/Pagination/Pagination';
import React, { useEffect, useMemo } from 'react';

import {
  FILES_TABLE_HEIGHT,
  fileTableColumnsWithRecipes,
  isHostEnvStandalone,
  RECIPE_ANALYSIS_STATUS,
  RECIPE_MODAL_MODES,
  SEARCH_SCANDATA_POLLING_TIME,
} from '../../consts';
import { useActionCreators, useAppSelector, useFileSelectWithShift } from '../../hooks';
import { useFilesSearch } from '../../hooks/useFilesSearch';
import {
  useCancelAutomaticAnalysisTaskMutation,
  useGetAutomaticAnalysisStatusQuery,
  useSetAutomaticAnalysisTaskMutation,
} from '../../store/api/automatic-analysis';
import { useDeleteRecipesMutation } from '../../store/api/recipes';
import { selectRecipesSelectedFiles, selectRecipesSelectedRecipes, selectScanDataFilters } from '../../store/selectors';
import { FilesFilters } from '../Modals/FileLoaderModal/FilesFilters';
import { FilesTable } from '../Modals/FileLoaderModal/FilesTable';

export const RecipesIDVFiles = () => {
  const {
    setPage,
    setRecipesListVisible,
    resetRecipeSelectedRecipe,
    setAutomaticAnalysisStartedVisible,
    resetRecipeSelectedFile,
  } = useActionCreators();
  useGetAutomaticAnalysisStatusQuery(undefined, { skip: !isHostEnvStandalone });
  const selectedFiles = useAppSelector(selectRecipesSelectedFiles);
  const selectedRecipes = useAppSelector(selectRecipesSelectedRecipes);
  const filters = useAppSelector(selectScanDataFilters);
  const [setAutomaticAnalysisTaskTrigger] = useSetAutomaticAnalysisTaskMutation();
  const [deleteRecipesTrigger] = useDeleteRecipesMutation();
  const [cancelAutoAnalysisTrigger] = useCancelAutomaticAnalysisTaskMutation();

  const { items, isLoading, handleSearchFiles, totalItems } = useFilesSearch();

  const handleFileClick = useFileSelectWithShift(items, selectedFiles);

  const handleAssignRecipeClick = () => {
    setRecipesListVisible(RECIPE_MODAL_MODES.assignRecipe);
  };

  const handleStartAnalysisClick = () => {
    const selectedRecipesIds = selectedRecipes.map(({ id }) => id);

    setAutomaticAnalysisTaskTrigger(selectedRecipesIds).then(() => setAutomaticAnalysisStartedVisible(true));
  };

  const handleDeleteRecipesClick = async () => {
    const analysisInQueue: string[] = [];
    const analysisNotInQueue: string[] = [];

    selectedRecipes.forEach(({ id, analysisStatus }) => {
      if (analysisStatus === RECIPE_ANALYSIS_STATUS.inQueue) {
        analysisInQueue.push(id);
        return;
      }

      analysisNotInQueue.push(id);
    });

    if (analysisInQueue.length) {
      await cancelAutoAnalysisTrigger(analysisInQueue);
    }

    if (analysisNotInQueue.length) {
      await deleteRecipesTrigger(analysisNotInQueue);
    }

    await handleSearchFiles();
    resetRecipeSelectedRecipe();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleSearchFiles();
    }, SEARCH_SCANDATA_POLLING_TIME);

    return () => clearInterval(interval);
  }, [items]);

  const modalContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="file-loader__loading" style={{ height: `${FILES_TABLE_HEIGHT}px` }}>
          <TranslatedText textKey="common.searching" />
          ...
        </div>
      );
    }

    const onPageChange = (page: number) => {
      setPage(page);
      resetRecipeSelectedRecipe();
      resetRecipeSelectedFile();

      handleSearchFiles();
    };

    return (
      <>
        {items && items.length > 0 ? (
          <>
            <FilesTable
              columns={fileTableColumnsWithRecipes}
              data={items}
              selectedRows={selectedFiles}
              onRowClick={handleFileClick}
              multiple
            />
            <div className="recipes__idv-actions">
              <Pagination
                simple
                defaultCurrent={1}
                defaultPageSize={filters.pageSize}
                pageSize={filters.pageSize}
                total={totalItems}
                onChange={onPageChange}
                current={filters.page}
              />
              <Button
                title={'recipes.delete-recipe'}
                disabled={!selectedRecipes?.length}
                onClick={handleDeleteRecipesClick}
              />
              <Button
                title={'recipes.assign-recipe'}
                disabled={!selectedFiles?.length}
                onClick={handleAssignRecipeClick}
              />
              <Button
                title={'recipes.start-analysis'}
                disabled={!selectedRecipes?.length}
                onClick={handleStartAnalysisClick}
              />
            </div>
          </>
        ) : (
          <TranslatedText textKey="modals.no-files-found" />
        )}
      </>
    );
  }, [items, filters.page, filters.pageSize, isLoading, selectedFiles, selectedRecipes, totalItems]);

  return (
    <div className="recipes__idv-files">
      {/*<RecipeSideMenu />*/}
      <FilesFilters />
      {modalContent}
    </div>
  );
};
