import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import Cookies from 'js-cookie';

import {
  AWS_EXPORTS,
  COOKIES,
  DEFAULT_ERROR_MESSAGE,
  ec2AutoInstanceHeader,
  isCloudHostEnv,
  LOCALSTORAGE_KEYS,
  MODAL_STATUS,
  MODAL_TITLE,
} from '../../../consts';
import { ISliderRange } from '../../../types/scanData';
import { createModalMessageBody, getFromLocalStorage, setToLocalStorage } from '../../../utils';
import { allActions } from '../../actions';
import { IScanDataFilters } from '../../slices';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { settingsApi } from '../settings';
import { ISettingsResponse } from '../settings/types';
import {
  CheckInstanceBody,
  CheckInstanceLambdaResponse,
  IFilesSearchData,
  ISampleDataParameters,
  IScanData,
  IVolumeInfo,
  LambdaResponse,
  ScanDataInfo,
  ScanDataUploadInfo,
  ScanDataUploadPayload,
  StartInstanceBody,
} from './types';

const {
  setSpinnerVisible,
  setCRangeForLoadedGeometry,
  setMaxProjectionSliderRange,
  setSingleLayerSliderRange,
  setBackwallMaxProjectionSliderRange,
  setBackwallSingleLayerSliderRange,
  setMessageModal,
} = allActions;

export const scanDataApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkVolumeId: build.query<boolean, string>({
      query: (volumeId) => `scandata/sample/volume/Exists?volumeId=${volumeId}`,
    }),
    setScanData: build.mutation<void, IScanData>({
      query: (body) => ({
        url: 'scandata',
        method: 'POST',
        body,
      }),
    }),
    updateFilesInfo: build.mutation<void, void>({
      query: () => ({
        url: 'scandata/updateFilesInfo',
        method: 'POST',
        headers: ec2AutoInstanceHeader,
      }),
    }),
    initSampleDataLoading: build.mutation<string, IScanData>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'scandata/sample/download',
          method: 'POST',
          body,
        });

        if (result.error) {
          dispatch(setSpinnerVisible(false));
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<string, FetchBaseQueryError>;
      },
    }),
    getSampleDataInfo: build.query<ScanDataInfo, void>({
      async queryFn(_, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'scandata/sample/download',
          method: 'GET',
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data;

        return { data } as QueryReturnValue<ScanDataInfo, FetchBaseQueryError, unknown>;
      },
    }),
    cancelSampleDownload: build.query<void, string>({
      async queryFn(taskId, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'scandata/sample/download',
          params: {
            taskId,
          },
          method: 'DELETE',
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    initSampleDataUpload: build.mutation<string, ScanDataUploadPayload>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(
          setMessageModal({
            type: MODAL_STATUS.NOTIFICATION,
            title: MODAL_TITLE.notification,
            description: 'Initialization of uploading the rotated file to the cloud.',
          }),
        );

        const result = await fetchWithBQ({
          url: 'scandata/sample/upload',
          method: 'POST',
          body,
        });

        if (result.error) {
          dispatch(setSpinnerVisible(false));
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<string, FetchBaseQueryError>;
      },
    }),
    getSampleDataUploadInfo: build.query<ScanDataUploadInfo | null, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'scandata/sample/upload',
          method: 'GET',
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ScanDataUploadInfo;

        if (data) {
          if (data.sampleUploadProcessState === 'Waiting') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.NOTIFICATION,
                title: MODAL_TITLE.notification,
                description: 'The uploading process in the queue.',
              }),
            );
          }

          if (data.sampleUploadProcessState === 'Uploading') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.NOTIFICATION,
                title: MODAL_TITLE.notification,
                description: 'Uploading the rotated file to the cloud.',
              }),
            );
          }

          if (data.sampleUploadProcessState === 'Ready') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.NOTIFICATION,
                title: MODAL_TITLE.notification,
                description: 'The rotated file has uploaded.',
              }),
            );
          }
        }

        return { data } as QueryReturnValue<ScanDataUploadInfo, FetchBaseQueryError, unknown>;
      },
    }),
    cancelSampleUpload: build.query<void, string>({
      async queryFn(taskId, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'scandata/sample/upload',
          params: {
            taskId,
          },
          method: 'DELETE',
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    loadSampleImageData: build.mutation<IVolumeInfo, IScanData>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'scandata/sample/download/image',
          method: 'POST',
          body,
        });

        if (result.error) {
          dispatch(setSpinnerVisible(false));
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        return { data: result.data } as QueryReturnValue<IVolumeInfo, FetchBaseQueryError>;
      },
    }),
    searchFiles: build.mutation<IFilesSearchData, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const { scanDataFilters } = getState() as TypedRootState;
        const body = { ...scanDataFilters } as Partial<IScanDataFilters>;

        if (!scanDataFilters.startDate) {
          delete body.startDate;
        }

        if (!scanDataFilters.endDate) {
          delete body.endDate;
        }

        const headers: Record<string, string> = {
          ...ec2AutoInstanceHeader,
        };

        if (isCloudHostEnv) {
          headers.Authorization = `Bearer ${localStorage.getItem(
            `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${getFromLocalStorage(
              LOCALSTORAGE_KEYS.lastAuthUser,
            )}.idToken`,
          )}`;
        }

        const result = await fetchWithBQ({
          url: 'scandata/search',
          method: 'POST',
          headers,
          body: {
            ...body,
            orderBy: Object.entries(scanDataFilters.orderBy)
              .map((item) => item.join(' '))
              .join(', '),
          },
        });

        return result as QueryReturnValue<IFilesSearchData, FetchBaseQueryError, unknown>;
      },
    }),
    startInstance: build.mutation<LambdaResponse, StartInstanceBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = (await fetchWithBQ({
          url: `${process.env.REACT_APP_CLOUD_LAMBDA_API_URL}/instance/start`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${getFromLocalStorage(
                LOCALSTORAGE_KEYS.lastAuthUser,
              )}.idToken`,
            )}`,
          },
          method: 'POST',
          body,
        })) as QueryReturnValue<LambdaResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data;

        if (!data.OperationSuccesfull) {
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, data?.Message || DEFAULT_ERROR_MESSAGE),
            ),
          );
        }

        if (data.InstanceId && data?.Ec2InstanceType && data?.State) {
          setToLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceId, data.InstanceId);
          setToLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceType, data.Ec2InstanceType);
          setToLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceState, data.State);
        }

        return result;
      },
    }),
    checkInstance: build.mutation<CheckInstanceLambdaResponse, CheckInstanceBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = (await fetchWithBQ({
          url: `${process.env.REACT_APP_CLOUD_LAMBDA_API_URL}/instance/type`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${getFromLocalStorage(
                LOCALSTORAGE_KEYS.lastAuthUser,
              )}.idToken`,
            )}`,
          },
          method: 'POST',
          body,
        })) as QueryReturnValue<CheckInstanceLambdaResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        if (!result.data.OperationSuccesfull) {
          dispatch(
            setMessageModal(
              createModalMessageBody(
                MODAL_STATUS.ERROR,
                MODAL_TITLE.error,
                result.data?.Message || DEFAULT_ERROR_MESSAGE,
              ),
            ),
          );
        }

        return result;
      },
    }),
    stopInstance: build.mutation<void, void>({
      async queryFn(_, _api, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `${process.env.REACT_APP_CLOUD_LAMBDA_API_URL}/instance/stop`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${getFromLocalStorage(
                LOCALSTORAGE_KEYS.lastAuthUser,
              )}.idToken`,
            )}`,
          },
          body: JSON.stringify({
            InstanceId: getFromLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceId),
          }),
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        if (isCloudHostEnv) {
          Cookies.remove(COOKIES.HDSESSION, {
            path: '/',
            domain: process.env.REACT_APP_DOMEN_URL?.split('https://api')[1],
          });
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    clearInstance: build.query<void, string>({
      async queryFn(volumeId, _api, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ(
          `${process.env.REACT_APP_API_URL}/scandata/sample/volume/clear?volumeId=${volumeId}`,
        );

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        return result.data as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    getInstanceStatus: build.query<LambdaResponse, string>({
      async queryFn(instanceId, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `${process.env.REACT_APP_CLOUD_LAMBDA_API_URL}/instance/status?instanceId=${instanceId}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${getFromLocalStorage(
                LOCALSTORAGE_KEYS.lastAuthUser,
              )}.idToken`,
            )}`,
          },
          method: 'GET',
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as LambdaResponse;

        if (data?.State) {
          setToLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceState, data.State);
        }

        return { data } as QueryReturnValue<LambdaResponse, FetchBaseQueryError, unknown>;
      },
    }),
    getScanDataParameters: build.query<ISampleDataParameters, string>({
      async queryFn(volumeId, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ(`scandata/sample/volume/parameters?volumeId=${volumeId}`);

        if (result.error) {
          dispatch(setSpinnerVisible(false));
          return { error: result.error as FetchBaseQueryError };
        }

        const settingsResult = await dispatch(
          settingsApi.endpoints.getSettings.initiate(undefined, { forceRefetch: true }),
        );

        if (settingsResult.error) {
          dispatch(setSpinnerVisible(false));
          return { error: settingsResult.error as FetchBaseQueryError };
        }

        const data = result.data as ISampleDataParameters;

        const {
          generalAnalysisSettings: {
            backwallSettings: {
              rangeDifference: backwallRangeDifference,
              rangeStart: backwallStartRange,
              rangeEnd: backwallEndRange,
              autoRangeStart,
              autoRangeEnd,
            },
            generalSettings: { rangeStart: rangeStartSliceC, rangeEnd: rangeEndSliceC },
          },
        } = settingsResult.data as ISettingsResponse;

        const getBackwallRange = (start: number, end: number, maxSize: number, diff: number): ISliderRange => {
          if (maxSize < end) {
            return { start: maxSize - diff, end: maxSize - 0.1 };
          }

          if (maxSize === end) {
            return { start, end: end - 0.1 };
          }

          return { start, end };
        };

        const sliceC = {
          start: rangeStartSliceC.parameter,
          end: rangeEndSliceC.parameter,
        };

        dispatch(setCRangeForLoadedGeometry(sliceC));

        dispatch(
          setMaxProjectionSliderRange({
            sliceB: {
              start: 0,
              end: data.sizeZ,
            },
            sliceC,
            sliceD: {
              start: 0,
              end: data.sizeX,
            },
          }),
        );

        dispatch(
          setSingleLayerSliderRange({
            sliceB: {
              start: Math.floor(data.sizeZ / 2),
              end: data.sizeZ,
            },
            sliceC: {
              ...sliceC,
              end: data.sizeY,
            },
            sliceD: {
              start: Math.floor(data.sizeX / 2),
              end: data.sizeX,
            },
          }),
        );

        dispatch(
          setBackwallMaxProjectionSliderRange(
            getBackwallRange(
              autoRangeStart.parameter
                ? data.bottomSensorStartLine ?? backwallStartRange.parameter
                : backwallStartRange.parameter,
              autoRangeEnd.parameter ? data.sizeY : backwallEndRange.parameter,
              data.sizeY,
              backwallRangeDifference.parameter,
            ),
          ),
        );

        dispatch(
          setBackwallSingleLayerSliderRange(
            getBackwallRange(
              autoRangeStart.parameter
                ? data.bottomSensorStartLine ?? backwallStartRange.parameter
                : backwallStartRange.parameter,
              autoRangeEnd.parameter ? data.sizeY : backwallEndRange.parameter,
              data.sizeY,
              backwallRangeDifference.parameter,
            ),
          ),
        );

        return { data };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useCheckVolumeIdQuery,
  useCheckInstanceMutation,
  useLazyGetSampleDataInfoQuery,
  useInitSampleDataLoadingMutation,
  useInitSampleDataUploadMutation,
  useLazyGetSampleDataUploadInfoQuery,
  useSearchFilesMutation,
  useLazyGetScanDataParametersQuery,
  useSetScanDataMutation,
  useLazyGetInstanceStatusQuery,
  useUpdateFilesInfoMutation,
  useStartInstanceMutation,
  useStopInstanceMutation,
  useLazyCancelSampleDownloadQuery,
  useLazyCancelSampleUploadQuery,
  useLazyClearInstanceQuery,
} = scanDataApi;
