import './styles.scss';

import React from 'react';

import { Modals } from '../../../components/Modals';
import { useGetDbSnapshotsMutation } from '../../../store/api/database';
import { SnapshotController } from './components/SnapshotController';
import { SnapshotsView } from './components/SnapshotsView';

export const SnapshotsPage = () => {
  const [, { data }] = useGetDbSnapshotsMutation({ fixedCacheKey: 'dbSnapshots' });
  return (
    <>
      <div className="page-container db-container snapshots">
        <SnapshotController data={data} />
        <SnapshotsView data={data} />
      </div>
      <Modals />
    </>
  );
};
