import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { SLICE_LAYER_TYPES } from '../../../consts';
import { checkFileType } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { ISliceData } from '../slice/types';
import {
  IDistanceInfo,
  IDistanceLineInsertion,
  IDistanceLineZone,
  IDistanceRectInsertion,
  IDistanceRectZone,
  IDistanceZonesResponse,
  TDistanceStatisticsResponse,
} from './types';

const { setBackwallDistanceStatistics, setPrevSliceImage } = allActions;

export const backwallApi = api.injectEndpoints({
  endpoints: (build) => ({
    setDistanceData: build.mutation<ISliceData, void>({
      async queryFn(_, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId, fileType },
          toolbarOptions: { isMaxProjection },
          backwall: {
            sliderRanges: { maxProjection, singleLayer },
          },
        } = getState() as TypedRootState;

        const { isImageFileType } = checkFileType(fileType);

        const result = await fetchWithBQ({
          url: 'backwall/distance/data',
          method: 'POST',
          body: {
            volumeId,
            sliceLayerType: isMaxProjection ? SLICE_LAYER_TYPES.range : SLICE_LAYER_TYPES.singleLayer,
            sliderRange: isImageFileType ? null : isMaxProjection ? maxProjection : singleLayer,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ISliceData;

        dispatch(
          setPrevSliceImage({
            backwall: data.sliceBackwall?.imageBase64,
          }),
        );

        return result as QueryReturnValue<ISliceData, FetchBaseQueryError, unknown>;
      },
    }),
    getDistanceZones: build.query<IDistanceZonesResponse, void>({
      async queryFn(args, { getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ(`backwall/distance/zones?volumeId=${volumeId}`);

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IDistanceZonesResponse;

        return { data };
      },
      providesTags: ['Backwall Distance'],
    }),
    addDistanceLine: build.mutation<TDistanceStatisticsResponse, IDistanceLineInsertion>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = (await fetchWithBQ({
          url: 'backwall/distance/add/line',
          method: 'POST',
          body: {
            volumeId,
            ...body,
          },
        })) as QueryReturnValue<TDistanceStatisticsResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setBackwallDistanceStatistics(result.data));
        return result;
      },
      invalidatesTags: ['Backwall Distance'],
    }),
    addDistanceRect: build.mutation<TDistanceStatisticsResponse, IDistanceRectInsertion>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = (await fetchWithBQ({
          url: 'backwall/distance/add/rect',
          method: 'POST',
          body: {
            volumeId,
            ...body,
          },
        })) as QueryReturnValue<TDistanceStatisticsResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setBackwallDistanceStatistics(result.data));
        return result;
      },
      invalidatesTags: ['Backwall Distance'],
    }),
    modifyDistanceLine: build.mutation<TDistanceStatisticsResponse, IDistanceLineZone>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = (await fetchWithBQ({
          url: 'backwall/distance/modify/line',
          method: 'POST',
          body: {
            volumeId,
            ...body,
          },
        })) as QueryReturnValue<TDistanceStatisticsResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setBackwallDistanceStatistics(result.data));
        return result;
      },
      invalidatesTags: ['Backwall Distance'],
    }),
    modifyDistanceRect: build.mutation<TDistanceStatisticsResponse, IDistanceRectZone>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = (await fetchWithBQ({
          url: 'backwall/distance/modify/rect',
          method: 'POST',
          body: {
            volumeId,
            ...body,
          },
        })) as QueryReturnValue<TDistanceStatisticsResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setBackwallDistanceStatistics(result.data));
        return result;
      },
      invalidatesTags: ['Backwall Distance'],
    }),
    getDistanceInfo: build.mutation<IDistanceInfo, number>({
      async queryFn(id, { getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'backwall/distance/info',
          method: 'POST',
          body: {
            volumeId,
            id,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<IDistanceInfo, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Backwall Distance'],
    }),
    deleteDistanceZone: build.mutation<TDistanceStatisticsResponse, number[]>({
      async queryFn(ids, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = (await fetchWithBQ({
          url: 'backwall/distance/delete',
          method: 'POST',
          body: {
            volumeId,
            ids,
          },
        })) as QueryReturnValue<TDistanceStatisticsResponse, FetchBaseQueryError, unknown>;

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setBackwallDistanceStatistics(result.data));
        return result;
      },
      invalidatesTags: ['Backwall Distance'],
    }),
    getDistanceStatisticsZones: build.query<TDistanceStatisticsResponse, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ(`backwall/distance/statistics?volumeId=${volumeId}`);

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as TDistanceStatisticsResponse;
        dispatch(setBackwallDistanceStatistics(data));

        return { data };
      },
    }),
  }),
});

export const {
  useSetDistanceDataMutation,
  useGetDistanceZonesQuery,
  useAddDistanceLineMutation,
  useAddDistanceRectMutation,
  useDeleteDistanceZoneMutation,
  useModifyDistanceLineMutation,
  useModifyDistanceRectMutation,
  useGetDistanceStatisticsZonesQuery,
} = backwallApi;
