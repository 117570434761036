import { Button, NumberInput, TranslatedText } from '@sms/plasma-ui';
import React, { useMemo } from 'react';

import {
  BACKWALL_FLAWS_THRESHOLDS,
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  GREY_SECONDARY_EXTRA_LIGHT,
  MAX_DEFECTIVE_VALUE,
  WHITE_PRIMARY,
} from '../../../../consts';
import { IBackwallFlawsChangeParams, useActionCreators, useAppSelector, useBackwall } from '../../../../hooks';
import { selectBackwallFlawsAllowModification, selectBackwallFlawsThresholds } from '../../../../store/selectors';
import { TBackwallThreshold, typedEntries } from '../../../../types';
import { debounce, isNumber, isStartEndNumberInputValid } from '../../../../utils';
import { NumberInputValue } from '../../../RangeBar';

export const BackwallFlawsThresholds = () => {
  const { setBackwallFlawsThreshold, setBackwallFlawsAreaModification } = useActionCreators();
  const thresholds = useAppSelector(selectBackwallFlawsThresholds);
  const allowAreaModification = useAppSelector(selectBackwallFlawsAllowModification);
  const { handleBackwallFlawsChange } = useBackwall();

  const debouncedBackwallFlawThresholdChange = useMemo(
    () =>
      debounce((newThreshold: IBackwallFlawsChangeParams) => {
        handleBackwallFlawsChange(newThreshold);
      }, 300),
    []
  );

  const handleThresholdChange = (value: NumberInputValue, threshold: TBackwallThreshold) => {
    if (!isNumber(value) || value < 0 || value > MAX_DEFECTIVE_VALUE) return;
    let validValue = 0;

    if (value > MAX_DEFECTIVE_VALUE) {
      validValue = MAX_DEFECTIVE_VALUE - 1;
    } else {
      validValue = value;
    }

    setBackwallFlawsThreshold({ value: validValue, threshold });
    debouncedBackwallFlawThresholdChange({ [threshold]: validValue });
  };

  const handleNumberInputBlur = (key: TBackwallThreshold) => {
    const endValue = thresholds.upperThreshold;
    const startValue = thresholds.lowerThreshold;

    const isInputsValid = isStartEndNumberInputValid(startValue, endValue);

    if (isInputsValid) return;

    if (key === 'lowerThreshold') {
      setBackwallFlawsThreshold({ threshold: 'upperThreshold', value: startValue });
      debouncedBackwallFlawThresholdChange({ upperThreshold: startValue });
    }

    if (key === 'upperThreshold') {
      setBackwallFlawsThreshold({ threshold: 'lowerThreshold', value: endValue });
      debouncedBackwallFlawThresholdChange({ lowerThreshold: endValue });
    }
  };

  return (
    <>
      <div className="backwall-flaws__thresholds">
        <h3 className="side-menu__title">
          <TranslatedText textKey="backwall.zones-types" />
        </h3>
        <div className="backwall-flaws__thresholds__inputs">
          {typedEntries(BACKWALL_FLAWS_THRESHOLDS).map(([key, label]) => (
            <NumberInput
              key={key}
              name={key}
              label={label}
              value={thresholds[key]}
              onChange={(value) => handleThresholdChange(value, key)}
              onBlur={() => handleNumberInputBlur(key)}
            />
          ))}
        </div>
        <Button
          title={'side-menu.auto-threshold'}
          onClick={() => handleBackwallFlawsChange({ lowerThreshold: -1, upperThreshold: -1 })}
        />
        <Button
          title={'side-menu.allow-area-modification'}
          style={{
            backgroundColor: allowAreaModification ? BLUE_PRIMARY : 'transparent',
            borderColor: allowAreaModification ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
            color: allowAreaModification ? WHITE_PRIMARY : BLACK_PRIMARY,
          }}
          onClick={() => setBackwallFlawsAreaModification()}
        />
      </div>
    </>
  );
};
