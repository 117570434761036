import { TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { IMMStatistics, TSegStatistics } from '../../../../../store/api/segregation/types';

interface IProps {
  statistics: TSegStatistics;
  mmStatistics: IMMStatistics | null | undefined;
}
export const SepClassRow: FC<IProps> = ({ statistics, mmStatistics }) => {
  return (
    <>
      {statistics && 'sepClass' in statistics && (
        <li className='seg__statistics__item'>
          <p><TranslatedText textKey="side-menu.sep-class"/>: </p>
          <p>{statistics.sepClass + `${mmStatistics ? ` (${mmStatistics.segClass})` : ''}`}</p>
        </li>
      )}
    </>
  );
};

