import './styles.scss';

import { CheckInfo, DataNode, navigate, Tree } from '@sms/plasma-ui';
import { Button, TranslatedText } from '@sms/plasma-ui/lib/components';
import React, { FC, useCallback, useState } from 'react';

import { useActionCreators, useAppSelector } from '../../../../../hooks';
import { DatabaseSnapshotsResponse } from '../../../../../store/api/database/types';
import { selectDbActiveSnapshots } from '../../../../../store/selectors';
import { ReactKeys, TreeCheckedKeys } from '../../../../../types';
import { getFormatedDateAndTimeString } from '../../../../../utils';

interface Props {
  data?: DatabaseSnapshotsResponse;
}

export const SnapshotController: FC<Props> = ({ data }) => {
  const { setActiveSnapshots } = useActionCreators();
  const [expandedKeys, setExpandedKeys] = useState<ReactKeys>(['snapshots']);
  const checkedKeys = useAppSelector(selectDbActiveSnapshots);

  const handleTreeExpand = useCallback((keys: ReactKeys) => {
    setExpandedKeys(keys);
  }, []);

  const handleTreeCheck = useCallback((keys: TreeCheckedKeys | ReactKeys, info: CheckInfo) => {
    if (Array.isArray(keys)) {
      setActiveSnapshots({ checked: keys, halfChecked: info.halfCheckedKeys ?? [] });
      return;
    }

    setActiveSnapshots(keys);
  }, []);

  function renderTreeChildren(data: DatabaseSnapshotsResponse): DataNode[] {
    return data.map(({ analysisResultId, sampleName, analysisDate, snapshots }) => {
      return {
        key: analysisResultId,
        checkable: true,
        disabled: false,
        title: `${sampleName}_${getFormatedDateAndTimeString(analysisDate)}`,
        children: snapshots.map(({ analysisName, snapshotName }) => ({
          key: snapshotName,
          checkable: true,
          disabled: false,
          title: `${analysisName}`,
          children: undefined,
        })),
      };
    });
  }
  return (
    <div className="snapshot-controller">
      <div className="snapshot-controller__content">
        <h3 className="side-menu__title">
          <TranslatedText textKey="Snapshot Controller" />
        </h3>
        {data && (
          <Tree
            checkable
            selectable={false}
            expandedKeys={expandedKeys}
            checkedKeys={checkedKeys}
            onExpand={handleTreeExpand}
            onCheck={handleTreeCheck}
            treeData={[
              {
                children: renderTreeChildren(data),
                key: 'snapshots',
                title: <TranslatedText textKey="Snapshots" />,
              },
            ]}
          />
        )}
      </div>
      <Button title={'Back to the results table'} onClick={() => navigate('/database/results')} />
    </div>
  );
};
