import './styles.scss';

import React, { FC } from 'react';

import { Modals } from '../../components/Modals';
import { Toolbar } from '../../components/ToolBar';

interface IProps {
  children: JSX.Element;
}

export const DefaultLayout: FC<IProps> = ({ children }) => {
  return (
    <>
      <Toolbar />
      <div className="main" id="main">
        {children}
      </div>
      <Modals />
    </>
  );
};
