// @ts-ignore
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import {
  BACKWALL_MODIFICATION_MODES,
  BLACK_PRIMARY,
  IDs,
  INITIAL_COORDINATES,
  INITIAL_RECT_PARAMS,
  TRANSPARENT,
} from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { useAddDistanceRectMutation } from '../../../../../store/api/backwall';
import { selectDistanceModificationMode, selectDistanceZoneTypes } from '../../../../../store/selectors';
import { RectParams, TCoordinates, TTupleOfTwoNumbers } from '../../../../../types';
import { convertCartesianModeCoordsInPxToMm, getBaseSvgElemStyles, ICartesianModeParams } from '../../../../../utils';

interface IProps {
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  stroke?: string;
}

export const BackwallRectInsertion = ({ stroke = BLACK_PRIMARY, domains, cartesianModeParams }: IProps) => {
  const modificationMode = useAppSelector(selectDistanceModificationMode);
  const selectedZoneTypes = useAppSelector(selectDistanceZoneTypes);
  const [addDistanceRectZone] = useAddDistanceRectMutation();

  const startDrawingCoords = useRef<TCoordinates>({ ...INITIAL_COORDINATES });
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [currentRect, setCurrentRect] = useState<RectParams>(INITIAL_RECT_PARAMS);

  useEffect(() => {
    if (modificationMode !== BACKWALL_MODIFICATION_MODES.insert) return;

    const drawingArea = d3.select(`#${IDs.backwallGroupWrapper}`);

    function onMouseDown(this: SVGGElement, e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      startDrawingCoords.current.x = x;
      startDrawingCoords.current.y = y;

      setIsDrawing(true);
    }

    function onMouseMove(this: SVGGElement, e: MouseEvent) {
      if (!isDrawing) return;

      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      setCurrentRect({
        x: Math.min(startDrawingCoords.current.x, x),
        y: Math.min(startDrawingCoords.current.y, y),
        width: Math.abs(startDrawingCoords.current.x - x),
        height: Math.abs(startDrawingCoords.current.y - y),
      });
    }

    function onMouseUp() {
      const { x, y, width, height } = currentRect;

      const { x: x0, y: y0 } = convertCartesianModeCoordsInPxToMm({ x, y: y + height }, cartesianModeParams, domains);
      const { x: x1, y: y1 } = convertCartesianModeCoordsInPxToMm({ x: x + width, y }, cartesianModeParams, domains);

      if (x0 === x1 || y0 === y1) {
        setIsDrawing(false);
        setCurrentRect(INITIAL_RECT_PARAMS);
        return;
      }

      addDistanceRectZone({ zoneType: selectedZoneTypes[0], rectangle: { x0, x1, y0, y1 } });

      setIsDrawing(false);
      setCurrentRect(INITIAL_RECT_PARAMS);
    }

    drawingArea.on('mousedown', onMouseDown).on('mousemove', onMouseMove).on('mouseup', onMouseUp);

    return () => drawingArea.on('mousedown', null).on('mousemove', null).on('mouseup', null);
  }, [
    domains,
    cartesianModeParams.realSampleWidth,
    cartesianModeParams.realSampleHeight,
    isDrawing,
    modificationMode,
    currentRect,
    selectedZoneTypes,
  ]);

  return (
    <rect
      x={currentRect.x}
      y={currentRect.y}
      width={currentRect.width}
      height={currentRect.height}
      style={getBaseSvgElemStyles(stroke, 1, TRANSPARENT)}
    />
  );
};
