import { TranslatedText } from '@sms/plasma-ui';
import Button from '@sms/plasma-ui/lib/components/Button';
import React from 'react';

import { SETTINGS_SECTIONS_TITLE, typedStandartsSettingsEntries } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useLazyGetSettingsQuery, useUpdateAstmSettingsMutation, useUpdateGostSettingsMutation } from '../../../store/api/settings';
import { selectInitialSettings, selectSettings } from '../../../store/selectors';
import { shallowCompareObjects } from '../../../utils';
import { FormInput } from '../../FormInput';

export const StandardsSettings = () => {
  const storeSettings = useAppSelector(selectSettings);
  const initialSettings = useAppSelector(selectInitialSettings);
  const { setAnalysisSetting } = useActionCreators();
  const [getSettingsTrigger] = useLazyGetSettingsQuery();
  const [updateAstmSettingsTrigger] = useUpdateAstmSettingsMutation();
  const [updateGostSettingsTrigger] = useUpdateGostSettingsMutation();

  const updateSettingsHandler = async () => {
    if (!initialSettings) return;

    if (!shallowCompareObjects(initialSettings.astmSettings, storeSettings.astmSettings)) {
      await updateAstmSettingsTrigger();
    }

    if (!shallowCompareObjects(initialSettings.gostSettings, storeSettings.gostSettings)) {
      await updateGostSettingsTrigger();
    }

    getSettingsTrigger();
  };

  return (
    <>
      <div className="settings__section">
        {typedStandartsSettingsEntries.map(([section, options]) => (
          <div key={section}>
            <h3 className="side-menu__title">
              <TranslatedText textKey={SETTINGS_SECTIONS_TITLE[section]} />
            </h3>
            <div className="settings__options">
              {options.map((key) => (
                <FormInput
                  key={key}
                  id={key}
                  label={`settings.${section}.${key}`}
                  //@ts-ignore
                  value={storeSettings[section][key]?.parameter}
                  hintMode={'hidden'}
                  //@ts-ignore
                  hint={`settings.hint.${storeSettings[section][key]?.description}`}
                  section={section}
                  onChange={setAnalysisSetting}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <Button title="common.save" onClick={updateSettingsHandler} />
    </>
  );
};
