import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import { Icon } from '@sms/plasma-ui/lib/components/Icon/Icon';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';

import { getISODateWithTimezone } from '../../../utils';

interface IProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  name: string;
}

export const DateInput = ({ label, value, onChange, onClear, name }: IProps) => {
  const datePickerRef = useRef<any>(null);

  const handleLogoChangeClick = () => {
    datePickerRef.current?.setFocus();
  };

  const handleDateChange = (value: Date | null) => {
    if (value === null) return;

    const date = new Date(value);
    onChange(getISODateWithTimezone(date));
  };

  return (
    <div className="input__date-container">
      <label>
        <TranslatedText textKey={label} />
      </label>
      <DatePicker
        ref={datePickerRef}
        selected={value ? new Date(value) : null}
        onChange={handleDateChange}
        name={name}
        dateFormat="dd.MM.yyyy"
        todayButton="Today"
        showPopperArrow={false}
      />
      {value && <button className="clear-date-button" onClick={onClear} />}
      <Icon name="calendar" className="input__date__icon" onClick={() => handleLogoChangeClick()} />
    </div>
  );
};
