import { SLICE_TYPES } from './geometry';

export const BACKEND_SLICE_TYPES_VALUES = {
  SlabMiddle: SLICE_TYPES.slabMiddle,
  BilletRectangular: SLICE_TYPES.rect,
  SlabRight: SLICE_TYPES.slabRight,
  BilletRound: SLICE_TYPES.round,
  SlabLeft: SLICE_TYPES.slabLeft,
  SampleLong: SLICE_TYPES.sampleLong,
} as const;
