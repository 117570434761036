import './styles.scss';

import React from 'react';

export const Spinner = () => {
  return (
    <div className="x-spinner">
      <div className="x-spinner__box">
        <div className="x-spinner__spin">
          <div className="x-spinner__item" />
        </div>
      </div>
    </div>
  );
};
