import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CRACKS_ANALYSIS } from '../../consts';
import { TCracksAnalysis } from '../../types';
import { ICracksStatistics, TCrackType, TModificationMode } from '../api/cracks/types';

interface IInitialState {
  areZonesShown: boolean;
  analysis: TCracksAnalysis;
  statistics: ICracksStatistics;
  selectedCrackIds: number[];
  modificationMode: TModificationMode | null;
  selectedCrackTypes: TCrackType[];
}

const initialState: IInitialState = {
  areZonesShown: false,
  analysis: CRACKS_ANALYSIS.sms,
  statistics: {
    statistics: [],
    cracksNsStatistics: null,
    allCracksCount: 0,
    worstCracksClass: 0,
    analysisType: CRACKS_ANALYSIS.sms,
  },
  selectedCrackIds: [],
  modificationMode: null,
  selectedCrackTypes: [],
};

export const cracksSlice = createSlice({
  name: 'cracks',
  initialState,
  reducers: {
    setCracksSelectedCrackIds: (state, action: PayloadAction<number | number[]>) => {
      if (Array.isArray(action.payload)) {
        state.selectedCrackIds = action.payload;
        return;
      }
      state.selectedCrackIds.push(action.payload);
    },
    removeCracksSelectedCrackIds: (state, action: PayloadAction<number | number[]>) => {
      if (Array.isArray(action.payload)) {
        // state.selectedCrackIds = state.selectedCrackIds.filter(
        //   (i) => !action.payload.includes(i)
        // );
        return;
      }
      state.selectedCrackIds = state.selectedCrackIds.filter((i) => i !== action.payload);
    },
    resetCracksSelectedCrackIds: (state) => {
      state.selectedCrackIds = [];
    },
    setModificationMode: (state, action: PayloadAction<TModificationMode | null>) => {
      if (state.modificationMode === action.payload) {
        state.modificationMode = null;
        return;
      }

      state.modificationMode = action.payload;
    },
    setCracksAnalysis: (state, action: PayloadAction<TCracksAnalysis>) => {
      state.analysis = action.payload;
    },
    setCracksShowingZones: (state) => {
      state.areZonesShown = !state.areZonesShown;
    },
    setSelectedCrackTypes: (state, action: PayloadAction<TCrackType[] | undefined>) => {
      if (!action.payload) {
        state.selectedCrackTypes = [];
        return;
      }

      state.selectedCrackTypes = action.payload;
    },
    setCracksStatistics: (state, action: PayloadAction<ICracksStatistics>) => {
      state.statistics = action.payload;
    },
  },
});

export const { actions: cracksActions, reducer: cracks } = cracksSlice;
