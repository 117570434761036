import React, { FC } from 'react';

import { RED_BROWN_ORANGE, SLICE_TYPES } from '../../consts';
import { TDirection, TTupleOfTwoNumbers } from '../../types';

interface IProps {
  graphWidth: number;
  graphHeight: number;
  xOffset: number;
  yOffset: number;
  domains: TTupleOfTwoNumbers;
  sliceType: string;
  factor: number;
  firstHorizontalIndex: number;
  lastHorizontalIndex: number;
  heightAnalysisVertical: number[];
  heightAnalysisHorizontal: number[];
}

export const HeightAnalysis: FC<IProps> = ({
  graphWidth,
  graphHeight,
  xOffset,
  yOffset,
  domains,
  sliceType,
  factor,
  firstHorizontalIndex,
  lastHorizontalIndex,
  heightAnalysisVertical,
  heightAnalysisHorizontal,
}) => {
  const getCoordinates = (intensityValues: number[], direction: TDirection = 'horizontal') => {
    const maxIntensity = Math.max(...intensityValues);
    const minIntensity = Math.min(...intensityValues);
    const k = (maxIntensity - minIntensity) / factor;
    const polyLineWidth = direction === 'vertical' ? graphHeight : graphWidth;
    const tick = polyLineWidth / intensityValues.length;
    let firstPositiveIndex = intensityValues.findIndex((value) => value > 0);
    let lastPositiveIndex = intensityValues.lastIndexOf(
      [...intensityValues].reverse().find((value) => value > 0) ?? 1e10
    );

    if (direction === 'horizontal') {
      firstPositiveIndex = firstHorizontalIndex;
      lastPositiveIndex = lastHorizontalIndex;
    }

    const scaledHeights = [];

    for (let i = firstPositiveIndex; i <= lastPositiveIndex; i++) {
      scaledHeights.push((-1 * intensityValues[i]) / k);
    }

    const maxHeight = Math.max(...scaledHeights);
    const minHeight = Math.min(...scaledHeights);

    const offset = maxHeight - (minHeight > 0 ? 0 : minHeight);

    return scaledHeights.reduce((acc, value, idx) => {
      // converting "value" in mm to px
      return direction === 'vertical'
        ? acc +
            ` ${(value / domains[0]) * graphHeight - offset + xOffset}, ${
              polyLineWidth - (idx + firstPositiveIndex) * tick + yOffset
            }`
        : acc +
            ` ${(idx + firstPositiveIndex) * tick + xOffset}, ${(value / domains[1]) * graphWidth - offset + yOffset}`;
    }, '');
  };

  return (
    <>
      <polyline
        points={getCoordinates(heightAnalysisVertical, 'vertical')}
        fill="none"
        strokeWidth={sliceType === SLICE_TYPES.cut ? 0.5 : 1}
        stroke={RED_BROWN_ORANGE}
      />
      <polyline
        points={getCoordinates(heightAnalysisHorizontal)}
        fill="none"
        strokeWidth={sliceType === SLICE_TYPES.cut ? 0.5 : 1}
        stroke={RED_BROWN_ORANGE}
      />
    </>
  );
};
