import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import { selectVolumeId } from '../../../../store/selectors';
import { selectGeometryBLines } from '../../../../store/selectors/geometry.selector';

export const BLinesRow = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const { deviationsLeft, deviationsRight } = useAppSelector((state) => selectGeometryBLines(state, volumeId));
  const bLinesInfo = [
    {
      title: 'geometry.left-side',
      value: deviationsLeft.length > 0 ? deviationsLeft.map((item, idx) => `B${idx}: ${item}`).join(' ') : '-',
    },
    {
      title: 'geometry.right-side',
      value:
        deviationsRight.length > 0
          ? deviationsRight.map((item, idx) => `B${idx + deviationsLeft.length}: ${item}`).join(' ')
          : '-',
    },
  ];

  return (
    <li className="geometry__statistic__item b-lines">
      <p className="geometry__statistic__item__title">
        <TranslatedText textKey="geometry.b-lines" /> [mm]:
      </p>
      <ul className="geometry__statistic__inner__list">
        {bLinesInfo.map(({ title, value }, idx) => (
          <li key={idx} className="geometry__statistic__inner__item">
            <p className="geometry__statistic__inner__title">
              <TranslatedText textKey={title} />:
            </p>
            <p className="geometry__statistic__item__content">{value}</p>
          </li>
        ))}
      </ul>
    </li>
  );
};
