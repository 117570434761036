import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IZonesApplyment, IZonesIdentifiedZone, IZonesZone } from '../api/zones/types';

interface IInitialState {
  allowZoneInsertion: boolean;
  allowZoneRemoving: boolean;
  allowZoneModifying: boolean;
  allowAxesRotation: boolean;
  selectedZoneId: number | null;
  prevRotationAngle: number;
  rotationAngle: number;
  useIn: IZonesApplyment;
  zones: IZonesZone[];
}

const initialState: IInitialState = {
  allowZoneInsertion: false,
  allowZoneRemoving: false,
  allowZoneModifying: false,
  allowAxesRotation: false,
  selectedZoneId: null,
  prevRotationAngle: 0,
  rotationAngle: 0,
  useIn: {
    useInSegregations: false,
    useInFlaws: false,
    useInCracks: false,
    useInBackwall: false,
  },
  zones: [],
};

export const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    allowZonesZoneInsertion: (state) => {
      state.allowZoneInsertion = true;
      state.allowZoneRemoving = false;
      state.allowZoneModifying = false;
      state.allowAxesRotation = false;
      state.selectedZoneId = null;
    },
    allowZonesZoneRemoving: (state) => {
      state.allowZoneRemoving = true;
      state.allowZoneInsertion = false;
      state.allowZoneModifying = false;
      state.allowAxesRotation = false;
      state.selectedZoneId = null;
    },
    allowZonesZoneModifying: (state) => {
      state.allowZoneModifying = true;
      state.allowZoneInsertion = false;
      state.allowZoneRemoving = false;
      state.allowAxesRotation = false;
      state.selectedZoneId = null;
    },
    allowZonesAxesRotation: (state) => {
      state.allowAxesRotation = true;
      state.allowZoneModifying = false;
      state.allowZoneInsertion = false;
      state.allowZoneRemoving = false;
      state.selectedZoneId = null;
    },
    addZonesZones: (state, action: PayloadAction<IZonesZone[]>) => {
      Object.assign(state.zones, action.payload);
    },
    addZonesZone: (state, action: PayloadAction<IZonesZone>) => {
      state.zones.push(action.payload);
    },
    removeZonesZone: (state, action: PayloadAction<number>) => {
      state.zones.splice(action.payload, 1);
    },
    clearZonesZone: (state) => {
      state.selectedZoneId = null;
      state.zones = [];
      state.rotationAngle = 0;
      state.prevRotationAngle = 0;
    },
    modifyZonesZone: (state, action: PayloadAction<Partial<IZonesIdentifiedZone>>) => {
      const { zoneId, newZoneRectangle, zoneApplyment } = action.payload;

      if (zoneId === undefined) return;

      if (newZoneRectangle) {
        Object.assign(state.zones[zoneId], { newZoneRectangle });
      }

      if (zoneApplyment) {
        Object.assign(state.zones[zoneId], { zoneApplyment });
      }
    },
    modifyZonesZones: (state, action: PayloadAction<IZonesZone[]>) => {
      state.zones = action.payload;
    },
    setSelectedZonesZoneId: (state, action: PayloadAction<number | null>) => {
      state.selectedZoneId = action.payload;
    },
    setUsedIn: (state, action: PayloadAction<Partial<string>>) => {
      const key = action.payload as keyof typeof state.useIn;
      state.useIn[key] = !state.useIn[key];
    },
    setRotationAngle: (state, action: PayloadAction<number>) => {
      state.rotationAngle = action.payload;
    },
    setPrevRotationAngle: (state, action: PayloadAction<number>) => {
      state.prevRotationAngle = action.payload;
    },
  },
});

export const { actions: zonesActions, reducer: zones } = zonesSlice;
