import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSnapshotId: '',
};

export const snapshotsSlice = createSlice({
  name: 'snapshots',
  initialState,
  reducers: {
    setSelectedSnapshotId: (state, action: PayloadAction<string>) => {
      state.selectedSnapshotId = action.payload;
    },
  },
});

export const { actions: snapshotActions, reducer: snapshots } = snapshotsSlice;
