import { Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { ChangeEvent, useCallback, useState } from 'react';

import { REMARKS_NAMES } from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { selectSegAnalysis, selectSegSelectedPolygon } from '../../../../store/selectors';
import { selectRemarks } from '../../../../store/selectors/remarks.selector';
import { Remarks } from '../../../Remarks';
import { SelectedArea } from '../../../SelectedArea';

const { segregations: segRemarksNames } = REMARKS_NAMES;

export const AreaStatistics = () => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const { segregations } = useAppSelector(selectRemarks);
  const { setSegRemarks } = useActionCreators();
  const selectedPolygon = useAppSelector(selectSegSelectedPolygon);
  const analysis = useAppSelector(selectSegAnalysis);

  const handleRemarksChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setSegRemarks({ data: e.target.value, analysis });
    },
    [analysis]
  );

  return (
    <div className="seg__statistics__area">
      <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
        <TabPane tab={<TranslatedText textKey="side-menu.selected-area" />} key={'0'}>
          <SelectedArea selectedPolygon={selectedPolygon} />
        </TabPane>
        <TabPane tab={<TranslatedText textKey="Remarks" />} key={'1'}>
          <Remarks
            value={segregations[segRemarksNames[analysis]]}
            name={segRemarksNames[analysis]}
            label={''}
            onChange={handleRemarksChange}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
