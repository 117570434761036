import React, { FC } from 'react';

import { useActionCreators, useAppSelector, useFlaw } from '../../../../../../hooks';
import {
  useSetASTMZoneRoundChangedMutation,
  useSetGOSTZoneRoundChangedMutation,
} from '../../../../../../store/api/flaws';
import { TFlawsRoundZones } from '../../../../../../store/api/flaws/types';
import {
  selectFlawsAnalysis,
  selectFlawsIsZoneInFocus,
  selectFlawsSelectedRegionId,
  selectVolumeId,
} from '../../../../../../store/selectors';
import { IBilletCutParams } from '../../../../../../store/slices/types';
import { ICircleParams, TTupleOfTwoNumbers } from '../../../../../../types';
import { ICartesianModeParams, setFlawsZoneFill } from '../../../../../../utils';
import { CircularZone } from '../../../../../CircularZone';

interface IProps {
  zones: TFlawsRoundZones;
  graphParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  billetCutParams: IBilletCutParams;
  allowModification: boolean;
}

export const RoundZones: FC<IProps> = ({ zones, graphParams, domains, billetCutParams, allowModification }) => {
  const {
    setFlawsRoundCRegion,
    setFlawsRoundSRegion,
    setFlawsRoundRRegion,
    setFlawsRoundInnerRegion,
    setFlawsRoundOuterRegion,
  } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const isZoneInFocus = useAppSelector(selectFlawsIsZoneInFocus);
  const selectedRegionId = useAppSelector(selectFlawsSelectedRegionId);
  const flawAnalysis = useAppSelector(selectFlawsAnalysis);
  const [setGOSTZoneRoundChangedTrigger] = useSetGOSTZoneRoundChangedMutation();
  const [setASTMZoneRoundChangedTrigger] = useSetASTMZoneRoundChangedMutation();

  const setParamsHandler = async (id: string, params: Pick<ICircleParams, 'radius'>) => {
    const baseBody = {
      volumeId,
      diameter: params.radius * 2,
    };

    if (id.includes('outerRegion')) {
      const index = +id.split('outerRegion')[1];

      setFlawsRoundOuterRegion(params);

      await setGOSTZoneRoundChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('innerRegion')) {
      const index = +id.split('innerRegion')[1];

      setFlawsRoundInnerRegion(params);

      await setGOSTZoneRoundChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('regionS')) {
      const index = +id.split('regionS')[1];

      setFlawsRoundSRegion(params);

      await setASTMZoneRoundChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('regionR')) {
      const index = +id.split('regionR')[1];

      setFlawsRoundRRegion(params);

      await setASTMZoneRoundChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('regionC')) {
      const index = +id.split('regionC')[1];

      setFlawsRoundCRegion(params);

      await setASTMZoneRoundChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }
  };

  return (
    <>
      {'outerRegion' in zones && (
        <CircularZone
          id={`outerRegion${zones.outerRegion.id}`}
          allowModification={allowModification}
          params={zones.outerRegion.circle}
          graphParams={graphParams}
          domains={domains}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          setParams={setParamsHandler}
          zoneNumber={zones.outerRegion.id + 1}
          stroke={setFlawsZoneFill(zones.outerRegion.id, selectedRegionId, isZoneInFocus)}
        />
      )}
      {'innerRegion' in zones && (
        <CircularZone
          id={`innerRegion${zones.innerRegion.id}`}
          allowModification={allowModification}
          params={zones.innerRegion.circle}
          graphParams={graphParams}
          domains={domains}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          setParams={setParamsHandler}
          zoneNumber={zones.innerRegion.id + 1}
          stroke={setFlawsZoneFill(zones.innerRegion.id, selectedRegionId, isZoneInFocus)}
        />
      )}
      {'regionS' in zones && (
        <CircularZone
          id={`regionS${zones.regionS.id}`}
          allowModification={allowModification}
          params={zones.regionS.circle}
          graphParams={graphParams}
          domains={domains}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          setParams={setParamsHandler}
          zoneNumber={zones.regionS.id + 1}
          stroke={setFlawsZoneFill(zones.regionS.id, selectedRegionId, isZoneInFocus)}
        />
      )}
      {'regionR' in zones && (
        <CircularZone
          id={`regionR${zones.regionR.id}`}
          allowModification={allowModification}
          params={zones.regionR.circle}
          graphParams={graphParams}
          domains={domains}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          setParams={setParamsHandler}
          zoneNumber={zones.regionR.id + 1}
          stroke={setFlawsZoneFill(
            selectedRegionId === 0 ? zones.regionS.id : zones.regionR.id,
            selectedRegionId,
            isZoneInFocus,
          )}
        />
      )}
      {'regionC' in zones && (
        <CircularZone
          id={`regionC${zones.regionC.id}`}
          allowModification={allowModification}
          params={zones.regionC.circle}
          graphParams={graphParams}
          domains={domains}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          setParams={setParamsHandler}
          zoneNumber={zones.regionC.id + 1}
          stroke={setFlawsZoneFill(zones.regionR.id, selectedRegionId, isZoneInFocus)}
        />
      )}
    </>
  );
};
