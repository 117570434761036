import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_COORDINATES, PX_INITIAL_COORDINATES } from '../../consts';
import {
  IAdaptImageScaleCoords,
  ISliceImage,
  ISliceImageCroppingCoords,
} from '../api/adapt-image/types';

const initialState = {
  isAccepted: false,
  isCropping: false,
  isScaleInserted: false,
  imageParams: {
    imageMmHeight: 0,
    imageMmWidth: 0,
    imagePxHeight: 0,
    imagePxWidth: 0,
  },
  cropCoords: {
    leftBottomPoint: { ...PX_INITIAL_COORDINATES },
    leftTopPoint: { ...PX_INITIAL_COORDINATES },
    rightTopPoint: { ...PX_INITIAL_COORDINATES },
    rightBottomPoint: { ...PX_INITIAL_COORDINATES },
  },
  scaleParams: {
    scaleLengthMm: 10,
    startPoint: { ...INITIAL_COORDINATES },
    endPoint: { ...INITIAL_COORDINATES },
  },
  imageBase64: '',
};

export const adaptImageSlice = createSlice({
  name: 'adaptImage',
  initialState,
  reducers: {
    setAdaptImage: (state, action: PayloadAction<ISliceImage>) => {
      Object.assign(state, action.payload);
    },
    setAdaptImageData: (
      state,
      action: PayloadAction<Partial<typeof initialState>>
    ) => {
      Object.assign(state, action.payload);
    },
    setAdaptImageCrop: (state, action: PayloadAction<boolean | undefined>) => {
      state.isCropping = !state.isCropping;

      if (action.payload === undefined) {
        state.isScaleInserted = false;
        return;
      }

      state.isScaleInserted = action.payload;
    },
    setAdaptImageScaleInsert: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isScaleInserted = !state.isScaleInserted;

      if (action.payload === undefined) {
        state.isCropping = false;
        return;
      }

      state.isCropping = action.payload;
    },
    setAdaptImageAccepted: (state) => {
      state.isAccepted = true;
    },
    setAdaptImageScaleLength: (state, action: PayloadAction<number>) => {
      state.scaleParams.scaleLengthMm = action.payload;
    },
    setAdaptImageScaleCoords: (
      state,
      action: PayloadAction<Partial<IAdaptImageScaleCoords>>
    ) => {
      Object.assign(state.scaleParams, action.payload);
    },
    setAdaptImageCropCoords: (
      state,
      action: PayloadAction<Partial<ISliceImageCroppingCoords>>
    ) => {
      Object.assign(state.cropCoords, action.payload);
    },
  },
});

export const {
  actions: adaptImageActions,
  reducer: adaptImage,
} = adaptImageSlice;
