import { useRouter } from '@sms/plasma-ui';
import { useCallback } from 'react';

import { IDENTIFIED_SNAPSHOTS, IDs } from '../consts';
import { useTakeSnapshotMutation } from '../store/api/snaphot';
import { selectBackwallTab } from '../store/selectors';
import { asyncSetTimeout, checkAppRoute, getBase64Snapshot, getShortBase64String } from '../utils';
import { useAppSelector } from './useAppSelector';
import { useBackwall } from './useBackwall';
import { useCracks } from './useCracks';
import { useFlaw } from './useFlaw';
import { useSegregation } from './useSegregation';

export const useSnapshots = () => {
  const {
    route: { path },
  } = useRouter('route', 'pages');
  const { isSegregation, isFlaws, isCracks, isBackwall, isZones } = checkAppRoute(path);
  const { handleTakeSegReportScreenshots } = useSegregation();
  const { handleTakeFlawsReportScreenshots } = useFlaw();
  const { handleTakeCracksReportScreenshots } = useCracks();
  const { handleTakeBackwallReportScreenshots } = useBackwall();
  const backwallTab = useAppSelector(selectBackwallTab);
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();

  const handleTakeZonesReportScreenshots = useCallback(async () => {
    await asyncSetTimeout(async () => {
      const imageBase64String = await getBase64Snapshot(`#${IDs.commonGraphInnerWrapper}`);
      const imageWidth = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetWidth ?? 0;
      const imageHeight = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetHeight ?? 0;

      await takeSnapshotTrigger({
        imageBase64String: getShortBase64String(imageBase64String),
        name: IDENTIFIED_SNAPSHOTS.zones,
        description: '',
        imageWidth,
        imageHeight,
      });
    }, 200);
  }, []);

  if (isSegregation) return handleTakeSegReportScreenshots;
  if (isFlaws) return handleTakeFlawsReportScreenshots;
  if (isCracks) return handleTakeCracksReportScreenshots;
  if (isZones) return handleTakeZonesReportScreenshots;
  if (isBackwall) return handleTakeBackwallReportScreenshots.bind(null, backwallTab);
};
