import { createSelector } from '@reduxjs/toolkit';

import { INITIAL_COORDINATES, SLICE_TYPES } from '../../consts';
import { geometryApi } from '../api/geometry';
import { ITransformedDimensions } from '../api/geometry/types';
import { TypedRootState } from '../types';
import { selectAutomaticAnalysisResult } from './automaticAnalysis.selector';
import { selectSliceType } from './sliceType.selector';

export const selectGeometryBase = (state: TypedRootState) =>
  geometryApi.endpoints.getGeometryBase.select()(state)?.data?.geometryBaseResult;

export const selectGeometryBaseCut = (state: TypedRootState) =>
  geometryApi.endpoints.getGeometryBaseCut.select()(state)?.data?.geometryBaseResult;

export const selectGeometryBaseRound = createSelector(
  [
    (state: TypedRootState) => geometryApi.endpoints.getGeometryBaseRound.select()(state)?.data?.geometryBaseResult,
    selectAutomaticAnalysisResult,
  ],
  (geometryBaseRound, analysisResult) => geometryBaseRound || analysisResult?.geometryBaseBilletRound,
);

export const selectGeometryALines = createSelector(
  [
    (state: TypedRootState, volumeId: string) => geometryApi.endpoints.getALines.select(volumeId)(state)?.data,
    selectAutomaticAnalysisResult,
  ],
  (geometryALines, analysisResult) =>
    geometryALines ||
    analysisResult?.geometryALinesResponse || {
      aLeft: '',
      aRight: '',
      leftALinePoints: [],
      rightALinePoints: [],
      leftMedianPoints: [],
      rightMedianPoints: [],
    },
);

export const selectGeometryBLines = createSelector(
  [
    (state: TypedRootState, volumeId: string) => geometryApi.endpoints.getBLines.select(volumeId)(state)?.data,
    selectAutomaticAnalysisResult,
  ],
  (geometryBLines, analysisResult) =>
    geometryBLines ||
    analysisResult?.geometryBLinesResponse || {
      bPointsLeft: [],
      bPointsRight: [],
      deviationsLeft: [],
      deviationsRight: [],
    },
);

export const selectGeometryCLines = createSelector(
  [
    (state: TypedRootState, volumeId: string) => geometryApi.endpoints.getCLines.select(volumeId)(state)?.data,
    selectAutomaticAnalysisResult,
  ],
  (geometryCLines, analysisResult) =>
    geometryCLines ||
    analysisResult?.geometryCLinesResponse || {
      cLinesPointsLeft: {
        startCornerPoint: INITIAL_COORDINATES,
        endCornerPoint: INITIAL_COORDINATES,
        straightLineEndPoint: INITIAL_COORDINATES,
      },
      cLinesPointsRight: {
        startCornerPoint: INITIAL_COORDINATES,
        endCornerPoint: INITIAL_COORDINATES,
        straightLineEndPoint: INITIAL_COORDINATES,
      },
      leftDeviation: 0,
      rightDeviation: 0,
    },
);

export const selectGeometryRoundDiagonals = createSelector(
  [
    (state: TypedRootState, volumeId: string) => geometryApi.endpoints.getRoundDiagonals.select(volumeId)(state)?.data,
    selectAutomaticAnalysisResult,
  ],
  (geometryRoundDiagonals, analysisResult) =>
    geometryRoundDiagonals ||
    analysisResult?.geometryRoundDiagonalResponse || {
      firstDiagonalPoints: [],
      firstDiameter: 0,
      secondDiameter: 0,
      secondDiagonalPoints: [],
    },
);

export const selectGeometryDimensions = createSelector(
  [
    (state: TypedRootState, volumeId: string) => geometryApi.endpoints.getDimensions.select(volumeId)(state)?.data,
    selectAutomaticAnalysisResult,
  ],
  (geometryDimensions, analysisResult) => {
    const dimensions = geometryDimensions || analysisResult?.geometryDimensionsResponse;

    if (!dimensions) {
      return {
        horizontalLines: [],
        verticalLines: [],
        horizontalLengths: [],
        verticalLengths: [],
      };
    }

    const {
      fromBottomLeftCornerToRight,
      fromBottomLeftCornerToTop,
      fromBottomRightCornerToLeft,
      fromBottomRightCornerToTop,
      fromTopLeftCornerToBottom,
      fromTopLeftCornerToRight,
      fromTopRightCornerToBottom,
      fromTopRightCornerToLeft,
    } = dimensions;

    const data: ITransformedDimensions = {
      horizontalLengths: [],
      verticalLengths: [],
      horizontalLines: [],
      verticalLines: [],
    };

    [
      fromBottomLeftCornerToRight,
      fromTopLeftCornerToRight,
      fromTopRightCornerToLeft,
      fromBottomRightCornerToLeft,
    ].forEach(({ linePoints, length }) => {
      data.horizontalLines.push(linePoints);
      data.horizontalLengths.push(length);
    });

    [
      fromBottomLeftCornerToTop,
      fromTopLeftCornerToBottom,
      fromTopRightCornerToBottom,
      fromBottomRightCornerToTop,
    ].forEach(({ linePoints, length }) => {
      data.verticalLines.push(linePoints);
      data.verticalLengths.push(length);
    });

    return data;
  },
);

export const selectGeometryDiagonals = (state: TypedRootState, volumeId: string) =>
  geometryApi.endpoints.getDiagonals.select(volumeId)(state)?.data ?? {
    firstDiagonalPoints: [],
    firstDiameter: 0,
    secondDiameter: 0,
    secondDiagonalPoints: [],
  };

export const selectGeometry = createSelector(
  [selectGeometryBase, selectGeometryBaseRound, selectGeometryBaseCut, selectSliceType],
  (rect, round, cut, sliceType) => {
    if (sliceType === SLICE_TYPES.round) return round;
    if (sliceType === SLICE_TYPES.cut) return cut;
    return rect;
  },
);
