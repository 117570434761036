import React, { useMemo } from 'react';

import { BLACK_PRIMARY, IDs, ORANGE_PRIMARY, SLICE_TYPES, TRANSPARENT } from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { useSetBackwallFlawsZoneChangedMutation } from '../../../../store/api/backwall-flaws';
import { IBackwallFlawsPolygonCoordinates } from '../../../../store/api/backwall-flaws/types';
import {
  selectBackwallFlawsAllowModification,
  selectBackwallFlawsForbiddenZones,
  selectBackwallFlawsInfoShownPolygons,
  selectBackwallFlawsPolygons,
  selectBackwallFlawsSelectedPolygon,
  selectBackwallFlawZone,
  selectBorderData,
  selectPaletteData,
  selectSliceType,
  selectVolumeId,
} from '../../../../store/selectors';
import { IBilletCutParams } from '../../../../store/slices/types';
import { IRectCoordinates, TTupleOfTwoNumbers } from '../../../../types';
import { ICartesianModeParams, IGraphParams } from '../../../../utils';
import { ForbiddenZones } from '../../../ForbiddenZones';
import { Polygons } from '../../../Polygons';
import { RectangularZone } from '../../../RectangularZone';
import { BackwallFlawInfo } from './BackwallFlawInfo';

interface Props {
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  billetCutParams: IBilletCutParams;
  billetCutDomains: TTupleOfTwoNumbers;
  imageHeight: number;
  imageWidth: number;
  graphParams: IGraphParams;
}

export const BackwallFlawsSvgElements = ({
  cartesianModeParams,
  domains,
  billetCutParams,
  billetCutDomains,
  imageHeight,
  imageWidth,
  graphParams,
}: Props) => {
  const { realSampleHeight, realSampleWidth, x: originX, y: originY } = cartesianModeParams;
  const { setBackwallFlawsSelectedPolygon, setBackwallFlawsInfoShownPolygons } = useActionCreators();
  const { isMonochrome } = useAppSelector(selectPaletteData);
  const sliceType = useAppSelector(selectSliceType);
  const polygonsData = useAppSelector(selectBackwallFlawsPolygons);
  const forbiddenZones = useAppSelector(selectBackwallFlawsForbiddenZones);
  const selectedPolygon = useAppSelector(selectBackwallFlawsSelectedPolygon);
  const zone = useAppSelector(selectBackwallFlawZone);
  const polygonsWithInfoShown = useAppSelector(selectBackwallFlawsInfoShownPolygons);
  const allowModification = useAppSelector(selectBackwallFlawsAllowModification);
  const volumeId = useAppSelector(selectVolumeId);
  const [zoneChangeTrigger] = useSetBackwallFlawsZoneChangedMutation();
  const borderData = useAppSelector((state) => selectBorderData(state, volumeId));

  const convertedBorderData = useMemo(
    () =>
      borderData.outerBackwallBorderPx.map((i) => ({
        i: i.i,
        j: imageHeight - i.j,
      })),
    [borderData, imageHeight]
  );

  const zoneStroke = isMonochrome ? ORANGE_PRIMARY : BLACK_PRIMARY;

  const wRatio = imageWidth / realSampleWidth;
  const hRatio = imageHeight / realSampleHeight;

  const setParamsHandler = (id: string, params: Partial<IRectCoordinates>) => {
    zoneChangeTrigger(params);
  };

  const handlePolygonClick = (polygon: IBackwallFlawsPolygonCoordinates) => {
    const isInfoShown = polygonsWithInfoShown.find(({ id }) => id === polygon.id);
    setBackwallFlawsInfoShownPolygons(polygon);

    if (isInfoShown && selectedPolygon?.id !== polygon.id) return;

    setBackwallFlawsSelectedPolygon(polygon);
  };

  return (
    <>
      <g id={IDs.backwallFlawsGroupWrapper} transform={`translate(${originX}, ${originY})`}>
        <rect x={0} y={0} width={realSampleWidth} height={realSampleHeight} style={{ fill: TRANSPARENT }} />
      </g>
      {forbiddenZones && forbiddenZones.length > 0 && (
        <ForbiddenZones
          zones={forbiddenZones}
          cartesianModeParams={cartesianModeParams}
          domains={domains}
          graphParams={graphParams}
          isCartesianMode={true}
        />
      )}
      {zone && (
        <RectangularZone
          id="backwallFlawZone"
          graphParams={cartesianModeParams}
          domains={domains}
          coordinates={zone}
          allowModification={allowModification}
          setParams={setParamsHandler}
          stroke={zoneStroke}
        />
      )}
      {polygonsData.length > 0 && (
        <Polygons
          selectedPolygon={selectedPolygon}
          polygonsCoordinates={polygonsData}
          domains={sliceType === SLICE_TYPES.cut ? billetCutDomains : domains}
          graphWidth={cartesianModeParams.realSampleWidth}
          graphHeight={cartesianModeParams.realSampleHeight}
          xOffset={cartesianModeParams.x}
          yOffset={cartesianModeParams.y}
          graphParams={cartesianModeParams}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          selectPolygon={handlePolygonClick}
        />
      )}
      {polygonsWithInfoShown.length > 0 &&
        polygonsWithInfoShown.map((polygon) => (
          <BackwallFlawInfo
            key={polygon.id}
            polygon={polygon}
            domains={domains}
            cartesianModeParams={cartesianModeParams}
            wRatio={wRatio}
            hRatio={hRatio}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            color={zoneStroke}
            convertedBorderData={convertedBorderData}
          />
        ))}
    </>
  );
};
