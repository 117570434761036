import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { FC, Suspense, useCallback, useMemo } from 'react';

import { INITIAL_3D_SCALE } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { useGetThreeDQuery } from '../../store/api/glb';
import {
  selectIsMaxProjection,
  selectIsVolume,
  selectMaxProjectionSliderRanges,
  selectPrevSliceImages,
  selectSingleLayerSliderRanges,
} from '../../store/selectors';
import { selectCroppedImages } from '../../store/selectors/croppedImages.selector';
import { BuildTuple } from '../../types';
import { MaxProjectionView } from './MaxProjectionView';
import { Model } from './Model';
import { OriginBox } from './OriginBox';
import { SingleLayersView } from './SingleLayersView';
import { IPseudo3DProps } from './types';

export const Pseudo3d: FC<IPseudo3DProps> = ({ scanView, scanDataParameters }) => {
  const { setSpinnerVisible } = useActionCreators();
  const isVolume = useAppSelector(selectIsVolume);
  const { data } = useGetThreeDQuery(undefined, { skip: !isVolume });
  const {
    imageBase64CutSliceC,
    imageBase64CutMirrorSliceC,
    imageBase64CutMirrorSliceB,
    imageBase64CutSliceB,
    imageBase64CutMirrorSliceD,
    imageBase64CutSliceD,
  } = useAppSelector(selectCroppedImages);
  const { B, C, D } = useAppSelector(selectPrevSliceImages);
  const isMaxProjection = useAppSelector(selectIsMaxProjection);
  const { sliceB, sliceD, sliceC } = useAppSelector(
    isMaxProjection ? selectMaxProjectionSliderRanges : selectSingleLayerSliderRanges,
  );
  const { sizeX, sizeY, sizeZ } = scanDataParameters;
  const originArgs: BuildTuple<3, number> = useMemo(() => [sizeX, sizeY, sizeZ], [sizeX, sizeY, sizeZ]);

  const onStartModelLoading = useCallback(() => {
    setSpinnerVisible(true);
  }, []);

  const onLoadModel = useCallback(() => {
    setSpinnerVisible(false);
  }, []);

  return (
    <div className="projection-item">
      <div className="projection-item__title">{scanView}</div>
      <Canvas>
        <Suspense fallback={null}>
          <ambientLight intensity={1} />
          <OriginBox boxArgs={originArgs} scale={INITIAL_3D_SCALE} />
          {isVolume ? (
            data && <Model scale={INITIAL_3D_SCALE} url={data} onStart={onStartModelLoading} onLoad={onLoadModel} />
          ) : (
            <>
              <MaxProjectionView
                isVisible={isMaxProjection}
                xSliceImage={imageBase64CutSliceD}
                ySliceImage={imageBase64CutSliceC}
                zSliceImage={imageBase64CutSliceB}
                x1SliceImage={imageBase64CutMirrorSliceD}
                y1SliceImage={imageBase64CutMirrorSliceC}
                z1SliceImage={imageBase64CutMirrorSliceB}
                prevXSliceImage={D}
                prevYSliceImage={C}
                prevZSliceImage={B}
                sizeX={sizeX}
                sizeY={sizeY}
                sizeZ={sizeZ}
                sliceX={sliceB}
                sliceY={sliceC}
                sliceZ={sliceD}
                scale={INITIAL_3D_SCALE}
              />
              <SingleLayersView
                isVisible={!isMaxProjection}
                xSliceImage={imageBase64CutSliceD}
                ySliceImage={imageBase64CutSliceC}
                zSliceImage={imageBase64CutSliceB}
                x1SliceImage={imageBase64CutMirrorSliceD}
                y1SliceImage={imageBase64CutMirrorSliceC}
                z1SliceImage={imageBase64CutMirrorSliceB}
                prevXSliceImage={D}
                prevYSliceImage={C}
                prevZSliceImage={B}
                sizeX={sizeX}
                sizeY={sizeY}
                sizeZ={sizeZ}
                sliceX={sliceB}
                sliceY={sliceC}
                sliceZ={sliceD}
                scale={INITIAL_3D_SCALE}
              />
            </>
          )}
          <OrbitControls />
        </Suspense>
      </Canvas>
    </div>
  );
};
