import React, { FC } from 'react';

import { ORANGE_PRIMARY, TRANSPARENT } from '../../../../../../consts';
import { TCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import { getBaseSvgElemStyles, getXOffsetFunc, getYOffsetFunc, ICartesianModeParams } from '../../../../../../utils';

interface IProps {
  data: TCoordinates;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx?: number;
  billetCutDy?: number;
  radius: number;
  fill?: string;
}

export const Circle: FC<IProps> = ({
  data,
  cartesianModeParams,
  domains,
  radius,
  fill = TRANSPARENT,
  billetCutDx = 0,
  billetCutDy = 0,
}) => {
  const { x, y } = data;

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  return (
    <circle
      cx={getXOffset(x + billetCutDx)}
      cy={getYOffset(y + billetCutDy)}
      r={(radius / domains[0]) * cartesianModeParams.realSampleWidth}
      style={getBaseSvgElemStyles(ORANGE_PRIMARY, 1, fill)}
    />
  );
};
