import { IRectCoordinates } from '../../../types';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '..';
import { IBackwallFlawsBody, IBackwallFlawsResponse } from './types';

const { setBackwallFlaws, resetBackwallFlawsZones, setBackwallFlawsCompleted } = allActions;

export const backwallFlawsApi = api.injectEndpoints({
  endpoints: (build) => ({
    setBackwallFlawsParams: build.mutation<IBackwallFlawsResponse, IBackwallFlawsBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'backwall/flaws',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IBackwallFlawsResponse;

        dispatch(resetBackwallFlawsZones());
        dispatch(setBackwallFlaws(data));
        dispatch(setBackwallFlawsCompleted());

        return { data };
      },
    }),
    setBackwallFlawsZoneChanged: build.mutation<IBackwallFlawsResponse, Partial<IRectCoordinates>>({
      async queryFn(newZone, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          backwallFlaws: { zones },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'backwall/flaws/zoneChanged',
          method: 'POST',
          body: { newZone: { ...zones?.flawsZone, ...newZone } },
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IBackwallFlawsResponse;

        dispatch(resetBackwallFlawsZones());
        dispatch(setBackwallFlaws(data));

        return { data };
      },
    }),
  }),
});

export const { useSetBackwallFlawsParamsMutation, useSetBackwallFlawsZoneChangedMutation } = backwallFlawsApi;
