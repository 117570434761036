import { createSelector } from '@reduxjs/toolkit';

import { TSegAnalysis } from '../../types';
import { segregationApi } from '../api/segregation';
import { IHeightAnalysisData, IMMStatistics, TSegStatistics, TSegZone } from '../api/segregation/types';
import { IZonesNewZoneParams } from '../api/zones/types';
import { IPolygonCoordinates, TSelectedPolygon } from '../slices/types';
import { TypedRootState } from '../types';

export const selectSegHeightAnalysisData = (state: TypedRootState, volumeId: string): IHeightAnalysisData =>
  segregationApi.endpoints.getSegHeightAnalysisData.select(volumeId)(state)?.data ?? {
    heightAnalysisVertical: [],
    heightAnalysisHorizontal: [],
    firstHorizontalIndex: 0,
    lastHorizontalIndex: 0,
    factor: 1,
  };

export const selectIsSegHeightAnalysis = (state: TypedRootState): boolean => state.segregation.isHeightAnalysis;
export const selectSegregation = (state: TypedRootState) => state.segregation;
export const selectSegThreshold = (state: TypedRootState): number => state.segregation.threshold;
export const selectIsPoresIncluded = (state: TypedRootState): boolean => state.segregation.isPoresIncluded;
export const selectSegAllowAreaModification = (state: TypedRootState): boolean =>
  state.segregation.allowAreaModification;
export const selectSegZones = (state: TypedRootState): TSegZone | null => state.segregation.zones;
export const selectSegAnalysis = (state: TypedRootState): TSegAnalysis => state.segregation.analysis;
export const selectSegMessage = (state: TypedRootState): string | null => state.segregation.message;
export const selectSegStatistics = (state: TypedRootState): TSegStatistics => state.segregation.statistics;
export const selectMMStatistics = (state: TypedRootState): IMMStatistics | null | undefined =>
  state.segregation.mmanStatistics;
export const selectSegSelectedPolygon = (state: TypedRootState): TSelectedPolygon => state.segregation.selectedPolygon;
export const selectSegForbiddenZones = (state: TypedRootState): IZonesNewZoneParams[] =>
  state.segregation.forbiddenZones;

export const selectSegLineY = (state: TypedRootState): number => {
  if (state.segregation.zones && 'segLineY' in state.segregation.zones) {
    return state.segregation.zones.segLineY;
  }

  return -1;
};

export const selectSegZoneHeight = (state: TypedRootState): number => {
  if (state.segregation.zones && 'zoneHeight' in state.segregation.zones) {
    return state.segregation.zones.zoneHeight;
  }

  return -1;
};

export const selectSegZoneWidth = (state: TypedRootState): number => {
  if (state.segregation.zones && 'zoneWidth' in state.segregation.zones) {
    return state.segregation.zones.zoneWidth;
  }

  return -1;
};

export const selectSegZoneDiameter = (state: TypedRootState): number => {
  if (state.segregation.zones && 'zoneDiameter' in state.segregation.zones) {
    return state.segregation.zones.zoneDiameter;
  }

  return -1;
};

export const selectSegCenterPoints = createSelector(selectSegZones, (zones) => {
  if (zones && 'centerPoint' in zones) {
    return zones.centerPoint;
  }

  return { x: -1, y: -1 };
});

export const selectSegYCenterPoint = (state: TypedRootState): number => {
  if (
    state.segregation.zones &&
    'centerPoint' in state.segregation.zones &&
    state.segregation.zones.centerPoint !== null
  ) {
    return state.segregation.zones.centerPoint.y;
  }

  return -1;
};

export const selectSegInnerRectangleX0 = (state: TypedRootState): number => {
  if (
    state.segregation.zones &&
    'innerRectangle' in state.segregation.zones &&
    state.segregation.zones.innerRectangle !== null
  ) {
    return state.segregation.zones.innerRectangle.x0;
  }

  return -1;
};

export const selectSegInnerRectangleX1 = (state: TypedRootState): number => {
  if (
    state.segregation.zones &&
    'innerRectangle' in state.segregation.zones &&
    state.segregation.zones.innerRectangle !== null
  ) {
    return state.segregation.zones.innerRectangle.x1;
  }

  return -1;
};

export const selectSegPolygonsCoordinates = (state: TypedRootState): IPolygonCoordinates[] | [] =>
  state.segregation.polygonsCoordinates;
