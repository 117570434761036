import './styles.scss';

// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useMemo } from 'react';

import {
  BLACK_PRIMARY,
  GREEN_PRIMARY,
  IDs,
  ORANGE_PRIMARY,
  RED_PRIMARY, TRANSLUCENT_DIM_GREY,
  TRANSPARENT,
  WHITE_PRIMARY,
  YELLOW_PRIMARY,
} from '../../../../../../consts';
import { IZonesNewZoneParams, IZonesZone } from '../../../../../../store/api/zones/types';
import { ISVGCircleParams, TTupleOfTwoNumbers } from '../../../../../../types';
import {
  getBaseSvgElemStyles, getRectPathData,
  getRotationRectCoordsOffsetsInPx,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../../../../../utils';
import {
  convertRecalculatedCoordsToMm,
  getRecalculatedCoordsInPx,
} from '../../../../../../utils/getRecalculatedCoordsInPx';

interface IProps {
  id?: string;
  selectedZoneId: number | null;
  graphParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  billetCutDx?: number;
  billetCutDy?: number;
  allowModification?: boolean;
  allowZoneRemoving?: boolean;
  fill?: boolean;
  zoneParams: IZonesZone;
  setParams?: (id: number, params: IZonesNewZoneParams) => void;
  zoneRemovingHandler?: (e: MouseEvent, id: number) => void;
  selectZoneHandler?: (id: number) => void;
}

export const ZonesRectangularZone: FC<IProps> = ({
  id = '',
  selectedZoneId,
  allowModification = false,
  allowZoneRemoving = false,
  setParams,
  zoneRemovingHandler,
  selectZoneHandler,
  graphParams,
  domains,
  billetCutDx = 0,
  billetCutDy = 0,
  zoneParams,
}) => {
  const zoneId = +id.split('zonesRect')[1];
  const { zoneApplyment, newZoneRectangle } = zoneParams;
  const getXOffset = getXOffsetFunc(graphParams, domains);
  const getYOffset = getYOffsetFunc(graphParams, domains);

  const x = getXOffset(newZoneRectangle.leftTop.x + billetCutDx);
  const y = getYOffset(newZoneRectangle.leftTop.y - billetCutDy);

  const width = ((newZoneRectangle.rightTop.x - newZoneRectangle.leftTop.x) * graphParams.realSampleWidth) / domains[0];
  const height = ((newZoneRectangle.leftTop.y - newZoneRectangle.leftBottom.y) * graphParams.realSampleHeight) / domains[1];

  const blockedAnalysisAmount = Object.values(zoneApplyment).filter(ui => ui === true).length;
  const coordsOffsets = getRotationRectCoordsOffsetsInPx(newZoneRectangle, graphParams, domains, billetCutDx, billetCutDy);

  const zone = useMemo(() => ([
    { active: true,
      styles: getBaseSvgElemStyles(
        selectedZoneId === zoneId ? WHITE_PRIMARY : BLACK_PRIMARY,
        4,
        selectedZoneId === zoneId ? TRANSLUCENT_DIM_GREY : TRANSPARENT
      ),
    },
    { active: zoneApplyment.useInSegregations,
      styles: getBaseSvgElemStyles(RED_PRIMARY, 4),
    },
    { active: zoneApplyment.useInFlaws,
      styles: getBaseSvgElemStyles(GREEN_PRIMARY, 4),
    },
    { active: zoneApplyment.useInCracks,
      styles: getBaseSvgElemStyles(YELLOW_PRIMARY, 4),
    },
    { active: zoneApplyment.useInBackwall,
      styles: getBaseSvgElemStyles(ORANGE_PRIMARY, 4),
    },
    { active: true,
      styles: getBaseSvgElemStyles(TRANSPARENT, 4),
    },
  ]), [selectedZoneId, zoneId, zoneApplyment.useInSegregations, zoneApplyment.useInFlaws, zoneApplyment.useInCracks, zoneApplyment.useInBackwall]);

  useEffect(() => {
    // if (!allowModification && !allowZoneRemoving) return;
    if (!selectZoneHandler) return;

    const path = d3.select(`#${id} .origin-path`);

    path.on('click', (e: MouseEvent) => {
      e.stopPropagation();
      selectZoneHandler(zoneId);
    });

    return () => path.on('click', null);
  }, [id, allowModification, selectZoneHandler, zoneId]);

  useEffect(() => {
    const paths = d3.selectAll(`#${id} path`);
    const firstAuxiliaryPoint = d3.select(`#${id} .${IDs.firstAuxiliaryPoint}`);
    const secondAuxiliaryPoint = d3.select(`#${id} .${IDs.secondAuxiliaryPoint}`);
    const points = [firstAuxiliaryPoint, secondAuxiliaryPoint];

    if (!allowModification) {
      points.forEach(line => {
        line.attr('cursor', 'auto').call(d3.drag());
      });

      return;
    }

    firstAuxiliaryPoint
      .datum({ cx: x, cy: y + height, r: 3 })
      .attr('cursor', 'grab');

    secondAuxiliaryPoint
      .datum({ cx: x + width, cy: y, r: 3 })
      .attr('cursor', 'grab');

    function dragstarted(this: SVGCircleElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = event.x))
          .attr('cy', (d.cy = event.y));

        const newCoords = getRecalculatedCoordsInPx(event, coordsOffsets, 'leftTop');

        paths.attr('d', getRectPathData(newCoords));
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = event.x))
          .attr('cy', (d.cy = event.y));

        const newCoords = getRecalculatedCoordsInPx(event, coordsOffsets, '');

        paths.attr('d', getRectPathData(newCoords));
      }
    }

    function dragended(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      if (!setParams) return;

      d3.select(this).attr('cursor', 'grab');
      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        setParams(zoneId, convertRecalculatedCoordsToMm(getRecalculatedCoordsInPx(event, coordsOffsets, 'leftTop'), graphParams, domains));
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        setParams(zoneId, convertRecalculatedCoordsToMm(getRecalculatedCoordsInPx(event, coordsOffsets, ''), graphParams, domains));
      }

    }

    points.forEach(line => {
      line.call(
        d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );
    });

    return () => {
      points.forEach(line => {
        line.call(
          d3.drag()
            .on('start', null)
            .on('drag', null)
            .on('end', null)
        );
      });
    };
  }, [id, domains, graphParams, x, y, width, height, allowModification, selectedZoneId, coordsOffsets, zoneId]);

  useEffect(() => {
    if (!allowZoneRemoving) return;
    if (!zoneRemovingHandler) return;

    const g = d3.select(`#${id}`);

    g.on('dblclick', (e: MouseEvent) => zoneRemovingHandler(e, zoneId));

    return () => g.on('dblclick', null);
  }, [id, allowZoneRemoving, zoneRemovingHandler, zoneId]);

  return (
    <g id={id} className={`block-in-${blockedAnalysisAmount}`}>
      {zone.map(({ active, styles }, idx, array) =>
        active
          ? <path
            key={idx}
            className={idx === array.length - 1 ? 'origin-path' : ''}
            style={styles}
            d={getRectPathData(coordsOffsets)}
          />
          : null)
      }
      {(allowModification || allowZoneRemoving) && selectedZoneId === zoneId && (
        <>
          <circle
            className={IDs.firstAuxiliaryPoint}
            cx={coordsOffsets.leftTop.x}
            cy={coordsOffsets.leftTop.y}
            r={5}
            style={getBaseSvgElemStyles(TRANSPARENT, 0, WHITE_PRIMARY)}
          />
          <circle
            className={IDs.secondAuxiliaryPoint}
            cx={coordsOffsets.rightBottom.x}
            cy={coordsOffsets.rightBottom.y}
            r={5}
            style={getBaseSvgElemStyles(TRANSPARENT, 0, WHITE_PRIMARY)}
          />
        </>
      )}
    </g>
  );
};

