/**************************************************************
 THIS FILE IS AUTOMATICALLY GENERATED BY PLASMA PLATFORM TOOLS.
 DO NOT MODIFY THIS FILE -- YOUR CHANGES WILL BE ERASED!
 ***************************************************************/

import { PageConfig } from '@sms/plasma-ui';

import { ThreeD } from './3D';
import { Adapt } from './Adapt';
import { AutoAnalysisCredentials } from './autoanalysiscredentials';
import { B } from './B';
import { Backwall } from './backwall';
import { BCDPlus } from './BCD+';
import { BCDA } from './BCDA';
import { C } from './C';
import { Cracks } from './cracks';
import { D } from './D';
import { Database } from './database';
import { Charts } from './database/charts';
import { HeightAnalysisPage } from './database/height-analysis';
import { DatabaseResults } from './database/results';
import { SnapshotsPage } from './database/snapshots';
import { Flaws } from './flaws';
import { Geometry } from './geometry';
import { Home } from './home';
import { Images } from './images';
import { Info } from './info';
import { Recipes } from './recipes';
import { Report } from './report';
import { Segregation } from './segregation';
import { Settings } from './settings';
import { Zones } from './zones';

const pages: PageConfig[] = [
  {
    key: 'adapt',
    path: '/adapt-image',
    page: Adapt,
    title: 'pages.adapt-image',
  },
  {
    key: 'bcdplus',
    path: '/bcdplus',
    page: BCDPlus,
    title: 'BCD+',
  },
  {
    key: 'bcda',
    path: '/bcda',
    page: BCDA,
    title: 'BCDA',
  },
  {
    key: 'segregation',
    path: '/segregation',
    page: Segregation,
    title: 'pages.segregation',
  },
  {
    key: 'flaws',
    path: '/flaws',
    page: Flaws,
    title: 'pages.flaws',
  },
  {
    key: 'geometry',
    path: '/geometry',
    page: Geometry,
    title: 'pages.geometry',
  },
  {
    key: 'zones',
    path: '/zones',
    page: Zones,
    title: 'Zones',
  },
  {
    key: 'b',
    path: '/b',
    page: B,
    title: 'B',
  },
  {
    key: 'c',
    path: '/c',
    page: C,
    title: 'C',
  },
  {
    key: 'd',
    path: '/d',
    page: D,
    title: 'D',
  },
  {
    key: '3d',
    path: '/3d',
    page: ThreeD,
    title: '3D',
  },
  {
    key: 'report',
    path: '/report',
    page: Report,
    title: 'Report',
  },
  {
    key: 'home',
    path: '/',
    page: Home,
    title: 'home',
  },
  {
    key: 'images',
    path: '/images',
    page: Images,
    title: 'pages.images',
  },
  {
    key: 'info',
    path: '/info',
    page: Info,
    title: 'Info',
  },
  {
    key: 'settings',
    path: '/settings',
    page: Settings,
    title: 'pages.settings',
  },
  {
    key: 'cracks',
    path: '/cracks',
    page: Cracks,
    title: 'pages.cracks',
  },
  {
    key: 'backwall',
    path: '/backwall',
    page: Backwall,
    title: 'pages.backwall',
  },
  {
    key: 'recipes',
    path: '/recipes',
    page: Recipes,
    title: 'Methods',
  },
  {
    key: 'database',
    path: '/database',
    page: Database,
    title: 'Database',
  },
  {
    key: 'results',
    path: '/database/results',
    page: DatabaseResults,
    title: 'Database Results',
  },
  {
    key: 'height-analysis',
    path: '/database/results/height-analysis',
    page: HeightAnalysisPage,
    title: 'Height analysis',
  },
  {
    key: 'snapshots',
    path: '/database/results/snapshots',
    page: SnapshotsPage,
    title: 'Snapshots',
  },
  {
    key: 'charts',
    path: '/database/results/charts',
    page: Charts,
    title: 'Charts',
  },
  {
    key: 'autoanalysiscredentials',
    path: '/autoanalysiscredentials',
    page: AutoAnalysisCredentials,
    title: 'Auto Analysis Credentials',
  },
];

export default pages;
