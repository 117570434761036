import { ILineCoordinates, TTupleOfTwoNumbers } from '../types';

export const getRatio = (width: number, height: number, domains: TTupleOfTwoNumbers) => {
  return { x: width / domains[0], y: height / domains[1] };
};

export const getDistanceCoordinates = (
  type: 'real' | 'standard',
  coordinates: ILineCoordinates,
  offsetX: number,
  offsetY: number,
  ratio: {
    x: number;
    y: number;
  }
): ILineCoordinates => {
  const { x1: xStart, y1: yStart, x2: xEnd, y2: yEnd } = coordinates;
  const { x, y } = ratio;

  let x1 = 0,
    y1 = 0,
    x2 = 0,
    y2 = 0;

  switch (type) {
    case 'standard':
      x1 = (xStart - offsetX) / x;
      x2 = (xEnd - offsetX) / x;
      y1 = (yStart - offsetY) / y;
      y2 = (yEnd - offsetY) / y;
      break;
    case 'real':
      x1 = xStart * x + offsetX;
      x2 = xEnd * x + offsetX;
      y1 = yStart * y + offsetY;
      y2 = yEnd * y + offsetY;
      break;
    default:
      return { x1, y1, x2, y2 };
  }

  return { x1, y1, x2, y2 };
};
