import React, { FC } from 'react';

import { TRANSLUCENT_DIM_GREY } from '../../../consts';

interface IProps {
  fill: string;
  disabled?: boolean;
}

export const MathSum: FC<IProps> = ({ fill, disabled = false }) => {
  return (
    <svg width="14px" height="20px" viewBox="0 0 14 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-303.000000, -8159.000000)" fill={disabled ? TRANSLUCENT_DIM_GREY : fill}>
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M261,8001 L250.498,8001 C252.227,8002.729 256.454,8006.956 258.456,8008.958 C256.569,8010.846 252.044,8015.371 250.414,8017 L261,8017 L261,8019 L247,8019 L247,8017 L247.586,8017 L255.627,8008.958 L247.669,8001 L247,8001 L247,7999 L261,7999 L261,8001 L261,8001 Z"
              id="sum-[#2]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
