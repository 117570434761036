import { DeepPartial } from '@reduxjs/toolkit';
import { Dispatch, SetStateAction } from 'react';

import { BLUE_PRIMARY, FILTER_RANGE, GREEN_PRIMARY, ORANGE_PRIMARY, RED_PRIMARY } from '../consts';
import {
  DatabaseFiltersResponse,
  DatabaseSnapshots,
  DatabaseSnapshotsResponse,
  DBHeightAnalysisTransformedResponse,
  MergedFiltersResult,
  TransformedDBAdditionalFiltersResponse,
} from '../store/api/database/types';
import {
  IAdditionalFilters,
  ReactKeys,
  ResultsTableState,
  ResultTableEntries,
  ResultTableState,
  typedEntries,
} from '../types';
import { getRangeOfTime } from './getFormattedCurrentDateAndTime';
import { getRandomColor, isObject } from './shared';

export const updateCheckedKeys = (stringKeys: string, cb: Dispatch<SetStateAction<ReactKeys>>): void => {
  if (stringKeys.includes('segregationsFilter-gostSlabFilter')) {
    cb((prevKeys) => prevKeys.filter((key) => !key.toString().includes('segregationsFilter-gostBilletFilter')));
  }

  if (stringKeys.includes('segregationsFilter-gostBilletFilter')) {
    cb((prevKeys) => prevKeys.filter((key) => !key.toString().includes('segregationsFilter-gostSlabFilter')));
  }

  if (stringKeys.includes('segregationsFilter-astmSlabFilter')) {
    cb((prevKeys) => prevKeys.filter((key) => !key.toString().includes('segregationsFilter-astmBilletFilter')));
  }

  if (stringKeys.includes('segregationsFilter-astmBilletFilter')) {
    cb((prevKeys) => prevKeys.filter((key) => !key.toString().includes('segregationsFilter-astmSlabFilter')));
  }

  if (stringKeys.includes('flawsFilter-gostSlabFilter')) {
    cb((prevKeys) => prevKeys.filter((key) => !key.toString().includes('flawsFilter-gostBilletFilter')));
  }

  if (stringKeys.includes('flawsFilter-gostBilletFilter')) {
    cb((prevKeys) => prevKeys.filter((key) => !key.toString().includes('flawsFilter-gostSlabFilter')));
  }
};

export const isFilterDisabled = (stringKeys: string, additionalFilters: DeepPartial<IAdditionalFilters>): boolean => {
  if (
    stringKeys.includes('segregationsFilter-gostSlabFilter') &&
    additionalFilters?.segregationsFilter?.gostBilletFilter
  )
    return true;
  if (
    stringKeys.includes('segregationsFilter-gostBilletFilter') &&
    additionalFilters?.segregationsFilter?.gostSlabFilter
  )
    return true;
  if (
    stringKeys.includes('segregationsFilter-astmSlabFilter') &&
    additionalFilters?.segregationsFilter?.astmBilletFilter
  )
    return true;
  if (
    stringKeys.includes('segregationsFilter-astmBilletFilter') &&
    additionalFilters?.segregationsFilter?.astmSlabFilter
  )
    return true;
  if (stringKeys.includes('flawsFilter-gostSlabFilter') && additionalFilters?.flawsFilter?.gostBilletFilter)
    return true;

  return !!(stringKeys.includes('flawsFilter-gostBilletFilter') && additionalFilters?.flawsFilter?.gostSlabFilter);
};

export function transformAdditionalFiltersBody(obj: Record<string, unknown>) {
  for (const [key, value] of Object.entries(obj)) {
    if (isObject(value)) {
      if (key === 'singularCracksFilters') {
        obj[key] = Array.from(Object.entries(value), ([, v]) => v);
      }

      if (key === 'zoneFilters') {
        obj[key] = Array.from(Object.entries(value), ([, v]) => v);
      }

      if (key === 'productionDate') {
        if (!Object.prototype.hasOwnProperty.call(value, 'start')) {
          Object.assign(value, { start: '2000-01-01T00:00:00.000Z' });
        }
        if (!Object.prototype.hasOwnProperty.call(value, 'end')) {
          Object.assign(value, { end: getRangeOfTime().end });
        }
        continue;
      }

      if (!Object.values(value).length) {
        Object.assign(value, FILTER_RANGE);
        continue;
      }

      if (Object.prototype.hasOwnProperty.call(value, 'start')) {
        Object.assign(value, { end: FILTER_RANGE.end });
        continue;
      }

      if (Object.prototype.hasOwnProperty.call(value, 'end')) {
        Object.assign(value, { start: FILTER_RANGE.start });
        continue;
      }

      transformAdditionalFiltersBody(value as Record<string, unknown>);
    }
  }

  return obj;
}

export function DeepObjectEntries(object: ResultsTableState, prefix = ''): ResultTableEntries {
  const res: ResultTableEntries = [];

  for (const [key, value] of typedEntries(object)) {
    const newPrefix = `${prefix}${prefix ? '-' : ''}${key}`;
    if ('visible' in value) {
      res.push([newPrefix, value as ResultTableState]);
    } else {
      res.push(...DeepObjectEntries(value, newPrefix));
    }
  }

  return res;
}

export const getDbActiveEntries = (state: ResultsTableState) => {
  return DeepObjectEntries(state).filter(([, value]) => value.active);
};

export const getDbVisibleEntries = (entries: [string, ResultTableState][]) => {
  return entries.filter(([, value]) => value.visible).map(([key]) => key);
};

export const getMergedFiltersResults = (
  primaryResults: DatabaseFiltersResponse[] | undefined,
  additionalResults: TransformedDBAdditionalFiltersResponse[] | null | undefined,
): (DatabaseFiltersResponse | MergedFiltersResult)[] => {
  if (!primaryResults) return [];

  let results = primaryResults;

  if (additionalResults) {
    results = primaryResults
      .filter(({ analysisResultId }) => additionalResults.map((i) => i.id).includes(analysisResultId))
      .map((dbResults) => ({
        ...dbResults,
        ...additionalResults.find((afResult) => dbResults.analysisResultId === afResult.id),
      }));
  }

  return results;
};

export const checkHeightAnalysisActiveIds = (
  heightAnalysisData: DBHeightAnalysisTransformedResponse[] | undefined,
  heightAnalysisActiveIds: string[],
) => {
  if (!heightAnalysisData || heightAnalysisData.length !== heightAnalysisActiveIds.length) return true;

  const filteredIds = heightAnalysisData.filter((i) => !heightAnalysisActiveIds.includes(i.analysisResultId));

  return !!filteredIds.length;
};

export const checkSelectedSnapshotIds = (
  snapshotsData: DatabaseSnapshotsResponse | undefined,
  selectedSnapshotsIds: string[],
) => {
  if (!snapshotsData || snapshotsData.length !== selectedSnapshotsIds.length) return true;

  const filteredIds = snapshotsData.filter((i) => !selectedSnapshotsIds.includes(i.analysisResultId));

  return !!filteredIds.length;
};

export const getHeightAnalysisColor = (index: number) => {
  if (index === 0) return RED_PRIMARY;
  if (index === 1) return GREEN_PRIMARY;
  if (index === 2) return BLUE_PRIMARY;
  if (index === 3) return ORANGE_PRIMARY;
  if (index > 3) return getRandomColor();
};

export const getActiveSnapshotsData = (
  data: DatabaseSnapshotsResponse | undefined,
  selectedSnapshotNames: string[],
) => {
  if (!data) return [];

  // return data
  //   .map((i) => i.snapshots)
  //   .flat()
  //   .filter((i) => selectedSnapshotNames.includes(i.snapshotName));

  return data.reduce((acc, item) => {
    item.snapshots.forEach((i) => {
      if (selectedSnapshotNames.includes(i.snapshotName)) {
        acc.push(i);
      }
    });

    return acc;
  }, [] as DatabaseSnapshots);
};
