import './styles.scss';

import React from 'react';

import { GeometryGraphWindow } from '../../components/GeometryGraphWindow';
import { GeometrySideMenu } from '../../components/GeometrySideMenu';
import { DefaultLayout } from '../../layouts/DefaultLayout';

export const Geometry = () => {
  return (
    <DefaultLayout>
      <div className="page-container geometry">
        <GeometrySideMenu />
        <GeometryGraphWindow />
      </div>
    </DefaultLayout>
  );
};
