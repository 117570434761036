import './styles.scss';

import React, { useLayoutEffect } from 'react';

import { PaletteBar } from '../../components/PaletteBar';
import { ProjectionList } from '../../components/ProjectionList';
import { useActionCreators, useAppSelector } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { selectScanDataParameters, selectVolumeId } from '../../store/selectors';

export const BCDA = () => {
  const { setMaxProjection } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));

  useLayoutEffect(() => {
    setMaxProjection(false);

    return () => { setMaxProjection(true); };
  }, []);

  return (
    <DefaultLayout>
      <div className="bcda__container">
        {scanDataParameters && (
          <>
            <ProjectionList scanDataParameters={scanDataParameters} />
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
