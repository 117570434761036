import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { CRACKS_ANALYSIS, SLICE_TYPES } from '../../../consts';
import { useActionCreators, useAppSelector, useCracks } from '../../../hooks';
import { selectCracksAnalysis, selectSliceType } from '../../../store/selectors';
import { RadioGroupData, TCracksAnalysis } from '../../../types';
import { CustomRadioGroup } from '../../UI/CustomRadioGroup';

export const CracksSettings = () => {
  const { setCracksAnalysis, forceAnalysisChange } = useActionCreators();
  const { getCracksStatistics, handleTakeCracksReportScreenshots } = useCracks();
  const analysis = useAppSelector(selectCracksAnalysis);
  const sliceType = useAppSelector(selectSliceType);

  const isSMSAnalysisDisabled =
    sliceType === SLICE_TYPES.rect || sliceType === SLICE_TYPES.round || sliceType === SLICE_TYPES.cut;
  const isGOSTAnalysisDisabled = false;
  const isASTMAnalysisDisabled = sliceType !== SLICE_TYPES.rect;

  const cracksAnalysisData: RadioGroupData[] = useMemo(
    () => [
      { value: CRACKS_ANALYSIS.sms, label: CRACKS_ANALYSIS.sms, disabled: isSMSAnalysisDisabled },
      { value: CRACKS_ANALYSIS.gost, label: CRACKS_ANALYSIS.gost, disabled: isGOSTAnalysisDisabled },
      { value: CRACKS_ANALYSIS.astm, label: CRACKS_ANALYSIS.astm, disabled: isASTMAnalysisDisabled },
    ],
    [sliceType],
  );

  const radioGroupChangeHandler = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const analysis = e.target.value as TCracksAnalysis;
      forceAnalysisChange(true);
      await handleTakeCracksReportScreenshots();
      setCracksAnalysis(analysis);
      await getCracksStatistics(analysis);
      forceAnalysisChange(false);
    },
    [handleTakeCracksReportScreenshots],
  );

  return (
    <div className="cracks__menu__settings">
      <h3 className="side-menu__title">
        <TranslatedText textKey="pages.cracks" />
      </h3>
      <div className="cracks__menu__settings__analysis">
        <CustomRadioGroup data={cracksAnalysisData} radioGroupValue={analysis} onChange={radioGroupChangeHandler} />
      </div>
    </div>
  );
};
