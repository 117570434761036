import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { REMARKS_NAMES } from '../../consts';
import {
  BackwallRemarksNames,
  CracksRemarksNames,
  FlawsRemarksNames,
  SegRemarksNames,
  TBackwallAnalysis,
  TCracksAnalysis,
  TFlawsAnalysis,
  TSegAnalysis,
} from '../../types';

interface IInitialState {
  titleRemarks: string;
  geometry: string;
  segregations: Record<SegRemarksNames, string>;
  flaws: Record<FlawsRemarksNames, string>;
  cracks: Record<CracksRemarksNames, string>;
  backwall: Record<BackwallRemarksNames, string>;
  backwallDistance: string;
  backwallFlaws: string;
  forbiddenZones: string;
}

interface RemarksData<T> {
  analysis: T;
  data: string;
}

const initialState: IInitialState = {
  titleRemarks: '',
  geometry: '',
  segregations: {
    mannesmannRemarks: '',
    sep100Remarks: '',
    gostRemarks: '',
    astmRemarks: '',
  },
  flaws: {
    smsRemarks: '',
    gostRemarks: '',
    astmRemarks: '',
  },
  cracks: {
    smsRemarks: '',
    gostRemarks: '',
    astmRemarks: '',
  },
  backwall: {
    smsRemarks: '',
    gostRemarks: '',
    astmRemarks: '',
  },
  backwallDistance: '',
  backwallFlaws: '',
  forbiddenZones: '',
};

const {
  segregations: segRemarksNames,
  flaws: flawsRemarksNames,
  cracks: cracksRemarksNames,
  backwall: backwallRemarksNames,
} = REMARKS_NAMES;

export const sliceRemarks = createSlice({
  name: 'remarks',
  initialState,
  reducers: {
    setTitleRemarks: (state, action: PayloadAction<string>) => {
      state.titleRemarks = action.payload;
    },
    setGeometryRemarks: (state, action: PayloadAction<string>) => {
      state.geometry = action.payload;
    },
    setSegRemarks: (
      state,
      action: PayloadAction<RemarksData<TSegAnalysis>>
    ) => {
      const { analysis, data } = action.payload;
      state.segregations[segRemarksNames[analysis]] = data;
    },
    setFlawRemarks: (
      state,
      action: PayloadAction<RemarksData<TFlawsAnalysis>>
    ) => {
      const { analysis, data } = action.payload;
      state.flaws[flawsRemarksNames[analysis]] = data;
    },
    setCracksRemarks: (state, action: PayloadAction<RemarksData<TCracksAnalysis>>) => {
      const { analysis, data } = action.payload;
      state.cracks[cracksRemarksNames[analysis]] = data;
    },
    setBackwallRemarks: (state, action: PayloadAction<RemarksData<TBackwallAnalysis>>) => {
      const { analysis, data } = action.payload;
      state.backwall[backwallRemarksNames[analysis]] = data;
    },
    setBackwallDistanceRemarks: (state, action: PayloadAction<string>) => {
      state.backwallDistance = action.payload;
    },
     setBackwallFlawsRemarks: (state, action: PayloadAction<string>) => {
      state.backwallFlaws = action.payload;
    },
    setZonesRemarks: (state, action: PayloadAction<string>) => {
      state.forbiddenZones = action.payload;
    },
  },
});

export const { actions: remarksActions, reducer: remarks } = sliceRemarks;
