import './styles.scss';

import { Modal, navigate, TranslatedText, useTranslation } from '@sms/plasma-ui';
import { Pagination } from '@sms/plasma-ui/lib/components/Pagination/Pagination';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import {
  CLOUD_MESSAGES,
  DEFAULT_ERROR_MESSAGE,
  FILE_TYPES,
  FILES_TABLE_HEIGHT,
  fileTableColumns,
  isCloudHostEnv,
  LOCALSTORAGE_KEYS,
  MODAL_STATUS,
  MODAL_TITLE,
} from '../../../consts';
import { useActionCreators, useAppDispatch, useAppSelector } from '../../../hooks';
import { useFilesSearch } from '../../../hooks/useFilesSearch';
import {
  useCheckInstanceMutation,
  useInitSampleDataLoadingMutation,
  useLazyCancelSampleDownloadQuery,
  useLazyClearInstanceQuery,
  useLazyGetInstanceStatusQuery,
  useLazyGetSampleDataInfoQuery,
  useStartInstanceMutation,
  useStopInstanceMutation,
} from '../../../store/api/scandata';
import { IScanData, IScanDataProd, IVolumeInfo, LambdaResponse } from '../../../store/api/scandata/types';
import { useLazyGetBackendStateQuery } from '../../../store/api/shared';
import { selectScanDataFilters, selectVolumeId } from '../../../store/selectors';
import {
  checkFileType,
  createModalMessageBody,
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../../../utils';
import { FilesFilters } from './FilesFilters';
import { FilesTable } from './FilesTable';

const {
  volumeInfo: volumeInfoKey,
  scanInfo,
  ec2InstanceType,
  ec2InstanceId,
  ec2InstanceState,
  backendState,
} = LOCALSTORAGE_KEYS;

interface IProps {
  isVisible: boolean;
}

export const FileLoaderModal: FC<IProps> = memo(function FileLoaderModal({ isVisible }) {
  const {
    setVolumeInfo,
    setGeometryAnalysisVisible,
    setFileLoaderVisible,
    setSpinnerVisible,
    setSampleFileName,
    setPage,
    setMessageModal,
  } = useActionCreators();
  const dispatch = useAppDispatch();
  const { handleSearchFiles, isLoading, items, totalItems } = useFilesSearch();
  const [selectedFile, setSelectedFile] = useState<IScanData | IScanDataProd | null>(null);
  const volumeId = useAppSelector(selectVolumeId);
  const [initSampleDataLoadingTrigger] = useInitSampleDataLoadingMutation();
  const [getSampleDataInfoTrigger] = useLazyGetSampleDataInfoQuery();
  const [startInstanceTrigger] = useStartInstanceMutation();
  const [getInstanceStatusTrigger] = useLazyGetInstanceStatusQuery();
  const [checkInstanceTrigger] = useCheckInstanceMutation();
  const [stopInstanceTrigger] = useStopInstanceMutation();
  const [checkBackendStateTrigger] = useLazyGetBackendStateQuery();
  const [cancelSampleDownloadTrigger] = useLazyCancelSampleDownloadQuery();
  const [clearInstanceTrigger] = useLazyClearInstanceQuery();
  const filters = useAppSelector(selectScanDataFilters);
  const { t } = useTranslation();

  const clearScanData = () => {
    removeFromLocalStorage(volumeInfoKey);
    removeFromLocalStorage(scanInfo);
    dispatch({ type: 'logout' });
    setSampleFileName('');
    setVolumeInfo({
      volumeId: '',
      fileType: FILE_TYPES.volume,
    });
    navigate('/');
  };

  const onOk = async () => {
    if (!selectedFile) return;
    let isCancelled = false;

    setFileLoaderVisible(false);
    navigate('/');
    setSpinnerVisible({
      isLoading: true,
      hasCancellation: true,
      cancelCb: () => {
        isCancelled = true;
      },
    });

    let volumeInfo: IVolumeInfo | undefined;

    if (isCloudHostEnv && 'originalFileSize' in selectedFile) {
      const ec2InstanceTypeValue = getFromLocalStorage(ec2InstanceType);

      if (ec2InstanceTypeValue) {
        setMessageModal(
          createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, CLOUD_MESSAGES.checkingInstance),
        );

        const { data } = await checkBackendStateTrigger();

        const checkInstanceResult = await checkInstanceTrigger({
          RequiredScanDataFileInfoSize: selectedFile.originalFileSize,
          CurrentEc2InstanceType: ec2InstanceTypeValue,
          RebootRequired: data ? data.rebootRequired : false,
        }).unwrap();

        if (isCancelled) return;

        setMessageModal(false);

        if (checkInstanceResult.OperationSuccesfull && checkInstanceResult.NewInstanceRequired) {
          setMessageModal(
            createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, CLOUD_MESSAGES.stopingInstance),
          );
          await stopInstanceTrigger();
          removeFromLocalStorage(ec2InstanceType);
          removeFromLocalStorage(ec2InstanceId);
          removeFromLocalStorage(ec2InstanceState);
          removeFromLocalStorage(backendState);

          setSpinnerVisible({
            cancelCb: () => {
              isCancelled = true;
              clearScanData();
            },
          });
        }
      }

      if (isCancelled) {
        clearScanData();
        return;
      }

      const instanceState = getFromLocalStorage(ec2InstanceState) as string;
      const serverState = getFromLocalStorage(backendState) as string;

      if (!getFromLocalStorage(ec2InstanceType) || instanceState !== 'Running' || serverState !== 'Running') {
        setMessageModal(
          createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, CLOUD_MESSAGES.startingInstance),
        );

        let instanceData: LambdaResponse;

        if (!instanceState) {
          instanceData = await startInstanceTrigger({
            ScanDataFileInfoId: selectedFile.id,
            ScanDataFileInfoSize: selectedFile.originalFileSize,
          }).unwrap();
        }

        if (isCancelled) return;

        if (!instanceState) {
          // @ts-ignore
          if (instanceData?.InstanceId) {
            await getInstanceStatusTrigger(instanceData.InstanceId);

            if (isCancelled) return;
          } else {
            setSpinnerVisible(false);
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.operation, 'modals.no-machines-avalilable'),
            );

            return;
          }
        }

        setMessageModal(false);

        let timer: ReturnType<typeof setInterval>;

        setSpinnerVisible({
          cancelCb: async () => {
            isCancelled = true;

            if (!serverState) {
              clearScanData();
            }

            clearInterval(timer);
          },
        });

        if (isCancelled) return;

        // @ts-ignore
        if (instanceData?.OperationSuccesfull || instanceState) {
          setMessageModal(
            createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, CLOUD_MESSAGES.checkingStatus),
          );

          await new Promise<void>((resolve) => {
            timer = setInterval(async () => {
              const status = await getInstanceStatusTrigger(
                instanceState ? getFromLocalStorage(ec2InstanceId) : instanceData.InstanceId,
              ).unwrap();

              if (status?.State === 'Running') {
                clearInterval(timer);
                setMessageModal(false);
                resolve();
              }
            }, 15 * 1000);
          });
        } else {
          setSpinnerVisible(false);
          return;
        }

        if (isCancelled) return;

        setMessageModal(
          createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, CLOUD_MESSAGES.startingBackend),
        );

        let timerForBackendCheck: ReturnType<typeof setInterval>;

        setSpinnerVisible({
          cancelCb: async () => {
            isCancelled = true;

            if (!serverState) {
              clearScanData();
            }

            clearInterval(timerForBackendCheck);
          },
        });

        if (isCancelled) return;

        await new Promise<void>((resolve) => {
          timerForBackendCheck = setInterval(async () => {
            const { data } = await checkBackendStateTrigger();

            if (data) {
              clearInterval(timerForBackendCheck);
              setMessageModal(false);
              resolve();
            }
          }, 1000);
        });
      }
    }

    if (isCancelled) return;

    setMessageModal(
      createModalMessageBody(
        MODAL_STATUS.WARNING,
        MODAL_TITLE.operation,
        t('modals.message.file-loading', { filename: selectedFile.name }),
      ),
    );

    setSpinnerVisible({
      cancelCb: async () => {
        isCancelled = true;

        if (isCloudHostEnv) {
          clearScanData();
        }
      },
    });

    if (isCancelled) return;

    let sampleDataLoadingResponse = '';

    try {
      sampleDataLoadingResponse = await initSampleDataLoadingTrigger(selectedFile).unwrap();
    } catch (e) {
      if (volumeId) {
        try {
          await clearInstanceTrigger(volumeId);
        } catch (error) {
          console.log(error);
        }
      }

      clearScanData();
    }

    if (sampleDataLoadingResponse) {
      let timerForCheckingSampleDataInfo: ReturnType<typeof setInterval>;

      setSpinnerVisible({
        cancelCb: async () => {
          isCancelled = true;
          clearInterval(timerForCheckingSampleDataInfo);
          await cancelSampleDownloadTrigger(sampleDataLoadingResponse);

          if (isCloudHostEnv) {
            clearScanData();
          }
        },
      });

      await new Promise<void>((resolve, reject) => {
        timerForCheckingSampleDataInfo = setInterval(async () => {
          const { data, error } = await getSampleDataInfoTrigger();

          if (error) {
            clearInterval(timerForCheckingSampleDataInfo);
            setSpinnerVisible(false);
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
            reject(error);
            return;
          }

          if (data) {
            if (data.samplePreparationProcessState === 'Error') {
              setSpinnerVisible(false);
              setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, 'modals.cant-load-file'));
              clearInterval(timerForCheckingSampleDataInfo);
              reject();
              return;
            }

            if (data.samplePreparationProcessState === 'Ready' && data.sampleData) {
              clearInterval(timerForCheckingSampleDataInfo);
              volumeInfo = data.sampleData;
              resolve();
            }
          }
        }, 10 * 1000);
      });
    } else {
      setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
      return;
    }

    if (isCancelled) return;

    setMessageModal(false);

    const sampleFileName = selectedFile.name;
    setToLocalStorage(scanInfo, {
      name: sampleFileName,
      id: selectedFile.id,
    });

    dispatch({ type: 'logout' });
    setSampleFileName(sampleFileName);

    if (volumeInfo) {
      setVolumeInfo(volumeInfo);
      setToLocalStorage(volumeInfoKey, { ...volumeInfo });
      if (checkFileType(volumeInfo.fileType).isIDVFileType) {
        navigate('/bcdplus');
        setGeometryAnalysisVisible(true);
      } else {
        navigate('/adapt-image');
      }
    }

    setSpinnerVisible(false);
  };

  const onCancel = useCallback(() => {
    setSelectedFile(null);
    setFileLoaderVisible(false);
  }, []);

  const onFileSelect = useCallback((e, rowData: IScanData | null) => {
    setSelectedFile(rowData);
  }, []);

  const onPageChange = (page: number) => {
    setPage(page);

    handleSearchFiles();
  };

  const modalContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="file-loader__loading" style={{ height: `${FILES_TABLE_HEIGHT}px` }}>
          <TranslatedText textKey="common.searching" />
          ...
        </div>
      );
    }

    return (
      <>
        {items && items.length > 0 ? (
          <>
            <FilesTable columns={fileTableColumns} data={items} selectedRow={selectedFile} onRowClick={onFileSelect} />
            <Pagination
              simple
              defaultCurrent={1}
              defaultPageSize={filters.pageSize}
              pageSize={filters.pageSize}
              total={totalItems}
              onChange={onPageChange}
              current={filters.page}
            />
          </>
        ) : (
          <TranslatedText textKey="modals.no-files-found" />
        )}
      </>
    );
  }, [items, filters.page, filters.pageSize, isLoading, selectedFile]);

  if (!isVisible) return null;

  return (
    <Modal
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      //@ts-ignore
      okText={<TranslatedText textKey="common.load" />}
      okType="primary"
      onCancel={onCancel}
      onOk={onOk}
      //@ts-ignore
      title={<TranslatedText textKey="modals.file-loader" />}
      visible={isVisible}
      width={900}
      zIndex={10}
      className="file-loader__modal"
    >
      <FilesFilters />
      {modalContent}
    </Modal>
  );
});
