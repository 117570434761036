import { navigate } from '@sms/plasma-ui';
import { useMemo } from 'react';

import { selectAutomaticAnalysisResult } from '../store/selectors';
import { useAppSelector } from './useAppSelector';

export const useAutoAnalysisNavigate = () => {
  const autoAnalysisResult = useAppSelector(selectAutomaticAnalysisResult);
  const path = window.location.pathname;

  const autoAnalysisPages = useMemo(() => {
    if (!autoAnalysisResult) return [];

    const { segregationsRange, flawsRange, cracksRange, backwallRange } = autoAnalysisResult;

    return [
      {
        page: '/segregation',
        isData: segregationsRange,
      },
      { page: '/flaws', isData: flawsRange },
      {
        page: '/geometry',
        isData: true,
      },
      {
        page: '/cracks',
        isData: cracksRange,
      },
      {
        page: '/backwall',
        isData: backwallRange,
      },
    ];
  }, [autoAnalysisResult]);

  const currentPage = autoAnalysisPages.findIndex((pageData) => pageData.page === path);

  const navigateToNextPage = () => {
    for (let i = currentPage + 1; i <= autoAnalysisPages.length; i++) {
      if (autoAnalysisPages[i].isData) {
        navigate(autoAnalysisPages[i].page);

        return;
      }
    }
  };

  return navigateToNextPage;
};
