import { Checkbox, RadioGroup, TranslatedText } from '@sms/plasma-ui';
import React, { Fragment, useMemo } from 'react';

import {
  RECIPE_ANALYSIS_ANALYSIS_TYPES,
  RECIPE_ANALYSIS_TYPES_KEYS,
  RECIPE_ANALYSIS_TYPES_NUM,
  SEP1_NUM,
  TYPE_DISCRIMINATOR,
} from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectRecipeSEPIncluded } from '../../../store/selectors';
import { RadioGroupData, TRecipeAnalysisTypes, TTypeDiscriminator, typedEntries } from '../../../types';

interface IProps {
  typeDiscriminator: TTypeDiscriminator;
  disabled: boolean;
  analysisTypeData: number[];
  isBilletSliceType: boolean;
}

export const RecipeAnalysisTypes = ({ typeDiscriminator, disabled, analysisTypeData, isBilletSliceType }: IProps) => {
  const isSepIncluded = useAppSelector(selectRecipeSEPIncluded);

  const { setSelectedAnalysisTypes, setRecipeSEPIncluded, setRecipeBackwallFlawsAnalysis } = useActionCreators();

  const handleAnalysisTypeChange = (e: any, typeDiscriminator: TTypeDiscriminator, analysis: TRecipeAnalysisTypes) => {
    setSelectedAnalysisTypes({
      typeDiscriminator,
      value: RECIPE_ANALYSIS_TYPES_NUM[analysis],
    });

    if (
      typeDiscriminator === TYPE_DISCRIMINATOR.segregationsAnalysisStageModel ||
      typeDiscriminator === TYPE_DISCRIMINATOR.cracksAnalysisStageModel
    ) {
      setRecipeBackwallFlawsAnalysis();
    }
  };

  const isAnalysisDisabled: Record<TTypeDiscriminator, { [key in TRecipeAnalysisTypes]?: boolean }> = useMemo(
    () => ({
      [TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]: {
        [RECIPE_ANALYSIS_TYPES_KEYS.SEP1]: isBilletSliceType,
        [RECIPE_ANALYSIS_TYPES_KEYS.SEP100]: isBilletSliceType,
      },
      [TYPE_DISCRIMINATOR.cracksAnalysisStageModel]: {
        [RECIPE_ANALYSIS_TYPES_KEYS.SMS]: isBilletSliceType,
        [RECIPE_ANALYSIS_TYPES_KEYS.GOST]: false,
        [RECIPE_ANALYSIS_TYPES_KEYS.ASTM]: !isBilletSliceType,
      },
      [TYPE_DISCRIMINATOR.flawsAnalysisStageModel]: {
        [RECIPE_ANALYSIS_TYPES_KEYS.SMS]: isBilletSliceType,
        [RECIPE_ANALYSIS_TYPES_KEYS.GOST]: false,
        [RECIPE_ANALYSIS_TYPES_KEYS.ASTM]: !isBilletSliceType,
      },
      [TYPE_DISCRIMINATOR.backwallAnalysisStageModel]: {},
    }),
    [isBilletSliceType],
  );

  return (
    <div className="recipe__analysis-types">
      <p>
        <TranslatedText textKey="recipes.analysis-types" />
      </p>
      {RECIPE_ANALYSIS_ANALYSIS_TYPES[typeDiscriminator].map((analysis) => {
        if (typeof analysis === 'object') {
          const [key, values] = typedEntries(analysis)[0];
          const radioValue = analysisTypeData.includes(SEP1_NUM)
            ? RECIPE_ANALYSIS_TYPES_KEYS.SEP1
            : RECIPE_ANALYSIS_TYPES_KEYS.SEP100;

          return (
            <Fragment key={`SEP${typeDiscriminator}`}>
              <Checkbox
                name={key}
                title={key}
                disabled={disabled || isBilletSliceType}
                checked={isSepIncluded}
                onChange={() => setRecipeSEPIncluded()}
              />
              <RadioGroup<RadioGroupData>
                name="SEPAnalysisRadioGroup"
                dataSource={values}
                disabled={disabled || !isSepIncluded}
                value={radioValue}
                onChange={(e: any) => handleAnalysisTypeChange(e, typeDiscriminator, e.target.value)}
              />
            </Fragment>
          );
        }

        return (
          <Checkbox
            key={`${analysis}${typeDiscriminator}`}
            name={analysis}
            title={analysis}
            disabled={disabled || isAnalysisDisabled[typeDiscriminator]?.[analysis]}
            checked={analysisTypeData.includes(RECIPE_ANALYSIS_TYPES_NUM[analysis])}
            onChange={(e: any) => handleAnalysisTypeChange(e, typeDiscriminator, analysis)}
          />
        );
      })}
    </div>
  );
};
