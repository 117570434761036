import './styles.scss';

import { Checkbox, TranslatedText, useTranslation } from '@sms/plasma-ui';
import React, { ReactNode } from 'react';

import { DATABASE_SLICE_TYPES_VALUES } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { useSaveDatabaseMutation, useUpdateDBAdditionalFiltersMutation } from '../../store/api/database';
import { DatabaseFiltersResponse, MergedFiltersResult } from '../../store/api/database/types';
import {
  selectDbGraphsSelectedColumns,
  selectDBResultTable,
  selectDbSelectedReportIds,
  selectHeightAnalysisActiveIds,
  selectSelectedSnapshotsIds,
} from '../../store/selectors';
import { DBSliceType, NestedKeyOf } from '../../types';
import {
  DeepObjectEntries,
  getFormatedDateAndTimeString,
  getMergedFiltersResults,
  getToFixedValue,
  getValueOfNestedObject,
  isNumber,
} from '../../utils';
import { ResultTable } from './ResultTable';

export const DatabaseResultsTable = () => {
  const { t } = useTranslation();
  const { updateHeightAnalysisIds, updateSnapshotIds, updateDBReportIds } = useActionCreators();
  const tableData = useAppSelector(selectDBResultTable);
  const heightAnalysisIds = useAppSelector(selectHeightAnalysisActiveIds);
  const snapshotsIds = useAppSelector(selectSelectedSnapshotsIds);
  const selectedReportIds = useAppSelector(selectDbSelectedReportIds);
  const selectedColumns = useAppSelector(selectDbGraphsSelectedColumns);
  const [, { data: databaseResults }] = useSaveDatabaseMutation({ fixedCacheKey: 'databaseResults' });
  const [, { data: additionalFiltersResults }] = useUpdateDBAdditionalFiltersMutation({
    fixedCacheKey: 'additionalFiltersResults',
  });
  const tableDataEntries = DeepObjectEntries(tableData);

  const renderValue = (
    row: DatabaseFiltersResponse,
    key: NestedKeyOf<MergedFiltersResult> | 'heightAnalysis' | 'snapshots' | 'report',
  ): ReactNode => {
    if (key === 'heightAnalysis') {
      return (
        <Checkbox
          name={key}
          checked={heightAnalysisIds.includes(row.analysisResultId)}
          onChange={() => updateHeightAnalysisIds(row.analysisResultId)}
        />
      );
    }

    if (key === 'snapshots') {
      return (
        <Checkbox
          name={key}
          checked={snapshotsIds.includes(row.analysisResultId)}
          onChange={() => updateSnapshotIds(row.analysisResultId)}
        />
      );
    }

    if (key === 'report') {
      return (
        <Checkbox
          name={key}
          checked={selectedReportIds.includes(row.analysisResultId)}
          onChange={() => updateDBReportIds(row.analysisResultId)}
        />
      );
    }

    const value = getValueOfNestedObject(row, key);

    if (value === null || value === '') return '-';

    if (key.includes('fileLoadDate') || key.includes('analysisDate')) return getFormatedDateAndTimeString(value);
    if (key.includes('sliceType')) return t(DATABASE_SLICE_TYPES_VALUES[(value as unknown) as DBSliceType]);
    if (key.includes('remarks')) return <div className="remarks__wrapper">{value}</div>;

    if (key.includes('cracksAnalysisCracksCount')) {
      return value ? (
        <div className="wrapper">
          {Object.entries(value).map(([k, v], index) => {
            return <div key={index}>{`${k}: ${v}`}</div>;
          })}
        </div>
      ) : null;
    }

    if (Array.isArray(value) && value.length > 0) {
      if (key.includes('resultsByRegions')) {
        return (
          <div className="wrapper">
            {value.map(
              (
                {
                  regionNumber,
                  regionX1,
                  regionX2,
                  regionY1,
                  regionY2,
                  lessThanOne,
                  oneToThree,
                  threeToFive,
                  fiveToTen,
                  moreThanTen,
                },
                index,
              ) => {
                return (
                  <div key={index}>
                    {regionNumber !== null && <div>{`Region ${regionNumber}`}</div>}
                    {regionX1 !== null && <div>{`x1: ${regionX1}`}</div>}
                    {regionX2 !== null && <div>{`x2: ${regionX2}`}</div>}
                    {regionY1 !== null && <div>{`y1: ${regionY1}`}</div>}
                    {regionY2 !== null && <div>{`y2: ${regionY2}`}</div>}
                    {lessThanOne !== null && <div>{`<1 mm: ${regionY2}`}</div>}
                    {oneToThree !== null && <div>{`1-3 mm: ${oneToThree}`}</div>}
                    {threeToFive !== null && <div>{`3-5 mm: ${threeToFive}`}</div>}
                    {fiveToTen !== null && <div>{`5-10 mm: ${fiveToTen}`}</div>}
                    {moreThanTen !== null && <div>{`>10 mm: ${moreThanTen}`}</div>}
                  </div>
                );
              },
            )}
          </div>
        );
      }

      if (key.includes('cracksAnalysisSingularCracks')) {
        return (
          <div className="wrapper">
            {value.map(({ begin, end, length, classSms, crackType, position, confidenceScore }, index) => {
              return (
                <div className="inner__wrapper" key={index}>
                  {crackType !== null && <div>{`Crack type ${crackType}`}</div>}
                  {begin !== null && <div>{`Begin: ${begin}`}</div>}
                  {end !== null && <div>{`End: ${end}`}</div>}
                  {length !== null && <div>{`Length: ${length}`}</div>}
                  {position !== null && <div>{`Position: ${position}`}</div>}
                  {classSms !== null && <div>{`SMS class: ${classSms}`}</div>}
                  {confidenceScore !== null && <div>{`Confidence score: ${confidenceScore}`}</div>}
                </div>
              );
            })}
          </div>
        );
      }

      if (key.includes('zones')) {
        return (
          <div className="wrapper">
            {value.map(({ zoneId, height, sizeParameter, sizePercent, width, zoneType }, index) => {
              return (
                <div className="inner__wrapper" key={index}>
                  {zoneId !== null && <div>{`Id ${zoneId}`}</div>}
                  {zoneType !== null && <div>{`Type: ${zoneType}`}</div>}
                  {width !== null && <div>{`Width: ${width}`}</div>}
                  {height !== null && <div>{`Height: ${height}`}</div>}
                  {sizeParameter !== null && <div>{`Size: ${sizeParameter}`}</div>}
                  {sizePercent !== null && <div>{`Size, %: ${sizePercent}`}</div>}
                </div>
              );
            })}
          </div>
        );
      }
    }

    if (isNumber(value)) return getToFixedValue(value);

    return value;
  };

  const renderDBResults = () => {
    if (!databaseResults?.length || (additionalFiltersResults && !additionalFiltersResults.length))
      return <TranslatedText textKey="db.no-db-results" />;

    const results = getMergedFiltersResults(databaseResults, additionalFiltersResults);

    return (
      <ResultTable data={tableDataEntries}>
        {results.map((row) => (
          <tr key={row.analysisResultId}>
            {tableDataEntries.map(([key, { visible }]) => {
              return visible ? (
                <td
                  key={`${row.analysisResultId}-${key}`}
                  className={`${selectedColumns.includes(key) ? 'active' : ''}`}
                >
                  {renderValue(row, key as NestedKeyOf<MergedFiltersResult>)}
                </td>
              ) : null;
            })}
          </tr>
        ))}
      </ResultTable>
    );
  };

  return <div className="database-results__content">{renderDBResults()}</div>;
};
