import { useTranslation } from '@sms/plasma-ui';
import React, { ReactNode } from 'react';

import { DATABASE_SLICE_TYPES_VALUES } from '../../../../../consts';
import { IRecipeResponse } from '../../../../../store/api/recipes/types';
import { DBSliceType } from '../../../../../types';
import { genericMemo, getFormatedDateAndTimeString } from '../../../../../utils';
import { RecipeRowItem } from './RecipeRowItem';

interface IProps<T> {
  width: string;
  field: keyof T;
  value: any;
  iconEnd?: ReactNode;
}

const TableCellComponent = <T,>({ field, width, iconEnd = null, value }: IProps<T>) => {
  const { t } = useTranslation();

  const getFieldValue = (field: keyof T) => {
    if (field === 'createdAt' || field === 'modifiedAt' || field === 'fileLoadDate' || field === 'analysisDate') {
      return getFormatedDateAndTimeString((value as unknown) as string);
    }

    if (field === 'sliceType') {
      return t(DATABASE_SLICE_TYPES_VALUES[(value as unknown) as DBSliceType]);
    }

    if (field === 'recipes') {
      return (
        <div className="recipe-row">
          {/* @ts-ignore */}
          {[...value].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((recipe: IRecipeResponse) => (
              <RecipeRowItem key={recipe.id} recipe={recipe} />
            ))}
        </div>
      );
    }

    return value;
  };

  return (
    <div className="table-cell" style={{ width }}>
      {getFieldValue(field)}
      {iconEnd}
    </div>
  );
};

export const TableCell = genericMemo(TableCellComponent);
