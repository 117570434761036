import React, { useEffect } from 'react';

import { LOCALSTORAGE_KEYS } from '../../consts';
import { useAppSelector } from '../../hooks';
import { useAutoAnalysisNavigate } from '../../hooks/useAutoAnalysisNavigate';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useLazyGetAutomaticAnalysisStatusQuery } from '../../store/api/automatic-analysis';
import { selectAutomaticAnalysisResult } from '../../store/selectors';
import { setToLocalStorage } from '../../utils';

export const AutoAnalysisCredentials = () => {
  const [getAutoAnalysisStatusTrigger, { data }] = useLazyGetAutomaticAnalysisStatusQuery();
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('accessToken');
  const autoAnalysisResult = useAppSelector(selectAutomaticAnalysisResult);
  const autoAnalysisNavigate = useAutoAnalysisNavigate();

  useEffect(() => {
    if (accessToken) {
      setToLocalStorage(LOCALSTORAGE_KEYS.autoAnalysisAccessToken, accessToken);
      getAutoAnalysisStatusTrigger();
    }
  }, [accessToken]);

  useEffect(() => {
    if (autoAnalysisResult && data?.analysesComplete) {
      autoAnalysisNavigate();
    }
  }, [autoAnalysisResult, data]);

  return (
    <DefaultLayout>
      <div className="bcdplus__container"></div>
    </DefaultLayout>
  );
};
