import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { v4 as uuidv4 } from 'uuid';

import {
  AUTO_ANALYSIS_API_URL,
  AWS_EXPORTS,
  BACKEND_SLICE_TYPES_VALUES,
  DEFAULT_ERROR_MESSAGE,
  ec2AutoInstanceHeader,
  LOCALSTORAGE_KEYS,
  MODAL_STATUS,
  MODAL_TITLE,
} from '../../../consts';
import { checkAppRoute, createModalMessageBody, getFromLocalStorage } from '../../../utils';
import { allActions } from '../../actions';
import { api } from '../index';
import { IAutoAnalysisStatus, IAutomaticAnalysisResult } from './types';

const { setMessageModal, setSpinnerVisible, setAnalysisStatus, setSliceType, setVolumeId } = allActions;

export const automaticAnalysisApi = api.injectEndpoints({
  endpoints: (build) => ({
    setAutomaticAnalysisTask: build.mutation<void, string[]>({
      async queryFn(recipeIds, { dispatch }, _extraOptions, fetchWithBQ) {
        const userId = getFromLocalStorage(
          `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${getFromLocalStorage(
            LOCALSTORAGE_KEYS.lastAuthUser,
          )}.userData`,
        )?.UserAttributes?.find((attr: { Name: string; Value: string }) => attr.Name === 'sub')?.Value;

        const result = await fetchWithBQ({
          url: 'automaticanalysis/task',
          method: 'POST',
          headers: {
            ...ec2AutoInstanceHeader,
          },
          body: recipeIds.map((recipeId) => ({
            id: uuidv4(),
            userId,
            recipeId,
          })),
        });

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    getAutomaticAnalysisStatus: build.query<IAutoAnalysisStatus, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const { isAutoAnalysisCredentials } = checkAppRoute(window.location.pathname);
        const url = isAutoAnalysisCredentials ? AUTO_ANALYSIS_API_URL : process.env.REACT_APP_API_URL;
        const result = await fetchWithBQ({
          url: `${url}/automaticanalysis/status`,
          headers: {
            ...ec2AutoInstanceHeader,
            Authorization: `Bearer ${localStorage.getItem(LOCALSTORAGE_KEYS.autoAnalysisAccessToken)}`,
          },
        });

        const data = result?.data as IAutoAnalysisStatus;

        dispatch(setAnalysisStatus(data));

        if (data?.analysesComplete && isAutoAnalysisCredentials) {
          await dispatch(automaticAnalysisApi.endpoints.getAutomaticAnalysisResult.initiate());
        }

        return { data };
      },
    }),
    getAutomaticAnalysisResult: build.query<IAutomaticAnalysisResult, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));
        const result = await fetchWithBQ({
          url: `${AUTO_ANALYSIS_API_URL}/automaticanalysis/result`,
          method: 'GET',
          headers: ec2AutoInstanceHeader,
        });

        const data = result.data as IAutomaticAnalysisResult;

        dispatch(setSliceType(BACKEND_SLICE_TYPES_VALUES[data.sliceType]));
        dispatch(setVolumeId(data.sampleParameters.volumeId));

        dispatch(setSpinnerVisible(false));

        return { data };
      },
    }),
    cancelAutomaticAnalysisTask: build.mutation<void, string[]>({
      async queryFn(recipeIds, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          // url: `automaticanalysis/cancel?recipeId=${recipeId}`,
          url: 'automaticanalysis/cancel',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: recipeIds,
        });

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    setAutoAnalysisMode: build.query<void, boolean>({
      async queryFn(isAutoAnalysis, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `automaticanalysis/mode?enable=${isAutoAnalysis}`,
          method: 'GET',
          headers: ec2AutoInstanceHeader,
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useSetAutomaticAnalysisTaskMutation,
  useGetAutomaticAnalysisStatusQuery,
  useLazyGetAutomaticAnalysisStatusQuery,
  useCancelAutomaticAnalysisTaskMutation,
  useLazySetAutoAnalysisModeQuery,
} = automaticAnalysisApi;
