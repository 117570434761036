import { Button, NumberInput, TranslatedText, useTranslation } from '@sms/plasma-ui';
import React, { MouseEvent, useCallback } from 'react';

import { BLACK_PRIMARY, BLUE_PRIMARY, GREY_SECONDARY_EXTRA_LIGHT, INPUT_NAMES, WHITE_PRIMARY } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectIsAdaptImageScaleInserted, selectIsAdaptImageScaleLength } from '../../../store/selectors';
import { NumberInputValue } from '../../RangeBar';

export const Scale = () => {
  const { t } = useTranslation();

  const { setAdaptImageScaleInsert, setAdaptImageScaleLength } = useActionCreators();
  const isScaleInserted = useAppSelector(selectIsAdaptImageScaleInserted);
  const scaleLength = useAppSelector(selectIsAdaptImageScaleLength);

  const handleInsertClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (isScaleInserted) {
        e.currentTarget.blur();
      }
      setAdaptImageScaleInsert();
    },
    [isScaleInserted]
  );

  const handleNumberInputChange = useCallback((value: NumberInputValue) => {
    if (typeof value !== 'number') return;
    if (value === 0) return;

    setAdaptImageScaleLength(value);
  }, []);

  return (
    <div className="adapt__menu__scale">
      <h4 className="adapt__menu__subtitle">
        <TranslatedText textKey="adapt-image.scale" />
      </h4>
      <NumberInput
        value={scaleLength}
        name={INPUT_NAMES.base}
        label={t('geometry.base', { unit: '[mm]' })}
        onChange={handleNumberInputChange}
      />
      <Button
        title={'Insert'}
        onClick={handleInsertClick}
        style={{
          backgroundColor: isScaleInserted ? BLUE_PRIMARY : 'transparent',
          borderColor: isScaleInserted ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: isScaleInserted ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
    </div>
  );
};
