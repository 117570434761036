import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

export const sliceTypeSlice = createSlice({
  name: 'sliceType',
  initialState,
  reducers: {
    setSliceType: (state, action: PayloadAction<string>) => action.payload,
  },
});

export const { actions: sliceTypeActions, reducer: sliceType } = sliceTypeSlice;
