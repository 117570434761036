import './styles.scss';

import { Button, Icon, navigate, Radio, TranslatedText, useRouter } from '@sms/plasma-ui';
import React, { FC, useCallback, useMemo, useState } from 'react';

import {
  GREY_PRIMARY,
  IDs,
  IMAGE_BLOB_TYPE,
  toolbarAnalysisData,
  toolbarAnalysisDataValues,
  toolbarProjectionData,
} from '../../../consts';
import { useActionCreators, useAppSelector, useSnapshots } from '../../../hooks';
import { useLazyGetAllSnapshotsQuery, useTakeSnapshotMutation } from '../../../store/api/snaphot';
import { selectFileType, selectScanDataParameters, selectSliceType, selectVolumeId } from '../../../store/selectors';
import { selectGeometry } from '../../../store/selectors/geometry.selector';
import { checkAppRoute, checkFileType, copyToClipboard, getBase64Snapshot } from '../../../utils';
import { Icons } from '../../svg';
import { CustomRadioGroup } from '../../UI/CustomRadioGroup';
import { ToolbarOptions } from './ToolbarOptions';

interface IProps {
  collapseAction: () => void;
  isPanelCollapsed: boolean;
}

export const PrimaryPanel: FC<IProps> = ({ collapseAction, isPanelCollapsed }) => {
  const { setGeometryAnalysisVisible, setFileLoaderVisible, forceAnalysisChange } = useActionCreators();
  const {
    route: { path },
  } = useRouter('route', 'pages');
  const { isImages } = checkAppRoute(path);
  const takeSnapshot = useSnapshots();
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const fileType = useAppSelector(selectFileType);
  const { isImageFileType } = useMemo(() => checkFileType(fileType), [fileType]);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();
  const [getAllSnapshotsTrigger] = useLazyGetAllSnapshotsQuery();
  const geometry = useAppSelector(selectGeometry);
  const toolbarProjData = toolbarProjectionData.map((data) => ({
    ...data,
    disabled: isImageFileType && data.value !== 'c',
  }));
  const toolbarAnalysisValues = toolbarAnalysisData.map((data) => ({
    ...data,
    disabled: !geometry && data.value !== 'geometry',
  }));
  const [radioGroupValue, setRadioGroupValue] = useState<string>(path.slice(1));

  const handleFolderClick = useCallback(() => {
    setFileLoaderVisible();
  }, []);

  // RadioChangeEvent isn't exported from Plasma UI
  const handleRadioGroupChange = useCallback(
    async (e: any) => {
      if (!sliceType && toolbarAnalysisDataValues.includes(e.target.value) && scanDataParameters) {
        setGeometryAnalysisVisible(true);
        return;
      }

      if (takeSnapshot) {
        forceAnalysisChange(true);
        await takeSnapshot();
        forceAnalysisChange(false);
      }

      setRadioGroupValue(e.target.value);
      navigate(`${e.target.value}`);
    },
    [sliceType, scanDataParameters, takeSnapshot],
  );

  const handleClipClick = async () => {
    const imageBase64String = await getBase64Snapshot(`#${IDs.main}`, {
      backgroundColor: GREY_PRIMARY,
    });
    if (!imageBase64String) return;

    const imageWidth = document.getElementById(IDs.main)?.offsetWidth ?? 0;
    const imageHeight = document.getElementById(IDs.main)?.offsetHeight ?? 0;

    await takeSnapshotTrigger({
      name: 'Snapshot_',
      imageBase64String,
      description: path.slice(1),
      imageWidth,
      imageHeight,
    });

    if (isImages) {
      getAllSnapshotsTrigger(volumeId);
    }
  };

  const handleScreenshotClick = async () => {
    const imageBase64String = await getBase64Snapshot('#main', {
      backgroundColor: GREY_PRIMARY,
    });
    if (!imageBase64String) return;

    await copyToClipboard(imageBase64String, IMAGE_BLOB_TYPE);
  };

  return (
    <div className="toolbar__wrapper__primary">
      <div className="toolbar__button-group">
        <Button icon={<Icons.Folder />} onClick={handleFolderClick} />
        <Button icon={<Icon name="paperclip" />} onClick={handleClipClick} />
        <Button icon={<Icons.Screenshot />} onClick={handleScreenshotClick} />
      </div>
      {isImageFileType && (
        <Radio value={'adapt-image'} checked={radioGroupValue === 'adapt-image'} onChange={handleRadioGroupChange}>
          <TranslatedText textKey="pages.adapt-image" />
        </Radio>
      )}
      <CustomRadioGroup data={toolbarProjData} onChange={handleRadioGroupChange} radioGroupValue={radioGroupValue} />
      <ToolbarOptions />
      <CustomRadioGroup
        data={toolbarAnalysisValues}
        onChange={handleRadioGroupChange}
        radioGroupValue={radioGroupValue}
      />
      <div className="collapseWrapper">
        <Icon
          name="angle-left"
          onClick={collapseAction}
          style={{
            transform: `rotate(${isPanelCollapsed ? '-90deg' : '0'})`,
            transition: 'transform .3s linear',
          }}
        />
      </div>
    </div>
  );
};
