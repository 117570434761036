import { Checkbox } from '@sms/plasma-ui';
import React from 'react';

import { useFileTableColumnsCheck } from '../../../../../hooks';
import { IScanData, IScanDataProd } from '../../../../../store/api/scandata/types';

interface Props {
  id: string;
  data: IScanData[];
  selectedRows: (IScanData | IScanDataProd)[] | null;
}

export const TableHeadCheckbox = ({ id, selectedRows, data }: Props) => {
  const { handleCheckboxClick, isColumnsChecked, isFilesSelected } = useFileTableColumnsCheck({ data, selectedRows });

  return (
    <Checkbox
      name={id}
      checked={isColumnsChecked[id as keyof typeof isColumnsChecked]}
      onClick={() => handleCheckboxClick(id)}
      disabled={id === 'Recipes' && !isFilesSelected}
    />
  );
};
