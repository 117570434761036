import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SETTINGS_SECTIONS } from '../../consts';
import { ISettings } from '../api/settings/types';

const DEFAULT_NUMBER_SETTING = {
  parameter: 0,
  description: '',
};

const DEFAULT_BOOL_SETTING = {
  parameter: false,
  description: '',
};

const initialState: ISettings = {
  [SETTINGS_SECTIONS.generalSettings]: {
    rangeStart: { ...DEFAULT_NUMBER_SETTING },
    rangeEnd: { ...DEFAULT_NUMBER_SETTING },
    paletteRangeStart: { ...DEFAULT_NUMBER_SETTING },
    paletteRangeEnd: { ...DEFAULT_NUMBER_SETTING },
    heightsAnalysisOffset: { ...DEFAULT_NUMBER_SETTING },
    leftRightMargin: { ...DEFAULT_NUMBER_SETTING },
    thresholdCoefficient: { ...DEFAULT_NUMBER_SETTING },
    medianBorderPixelSize: { ...DEFAULT_NUMBER_SETTING },
    haFactor: { ...DEFAULT_NUMBER_SETTING },
    monochrome: { ...DEFAULT_BOOL_SETTING },
    inverse: { ...DEFAULT_BOOL_SETTING },
    cut: { ...DEFAULT_BOOL_SETTING },
  },
  [SETTINGS_SECTIONS.segregationsSettings]: {
    rangeStart: { ...DEFAULT_NUMBER_SETTING },
    rangeEnd: { ...DEFAULT_NUMBER_SETTING },
    minPoreArea: { ...DEFAULT_NUMBER_SETTING },
    poresThreshold: { ...DEFAULT_NUMBER_SETTING },
    zoneFraction: { ...DEFAULT_NUMBER_SETTING },
    weightByArea: { ...DEFAULT_BOOL_SETTING },
    mmZoneHeight: { ...DEFAULT_NUMBER_SETTING },
    sepZoneWidth: { ...DEFAULT_NUMBER_SETTING },
  },
  [SETTINGS_SECTIONS.flawsSettings]: {
    rangeStart: { ...DEFAULT_NUMBER_SETTING },
    rangeEnd: { ...DEFAULT_NUMBER_SETTING },
    smsZoneOffsetFromBorderCoefficient: { ...DEFAULT_NUMBER_SETTING },
    smsZoneHeightCoefficient: { ...DEFAULT_NUMBER_SETTING },
  },
  [SETTINGS_SECTIONS.cracksSettings]: {
    rangeStart: { ...DEFAULT_NUMBER_SETTING },
    rangeEnd: { ...DEFAULT_NUMBER_SETTING },
    minCrackLength: { ...DEFAULT_NUMBER_SETTING },
    centerZoneHeight: { ...DEFAULT_NUMBER_SETTING },
    tripplePointZoneWidth: { ...DEFAULT_NUMBER_SETTING },
    billetInnerZonePercent: { ...DEFAULT_NUMBER_SETTING },
    billetMiddleZonePercent: { ...DEFAULT_NUMBER_SETTING },
  },
  [SETTINGS_SECTIONS.backwallSettings]: {
    rangeStart: { ...DEFAULT_NUMBER_SETTING },
    rangeEnd: { ...DEFAULT_NUMBER_SETTING },
    rangeDifference: { ...DEFAULT_NUMBER_SETTING },
    autoRangeStart: { ...DEFAULT_BOOL_SETTING },
    autoRangeEnd: { ...DEFAULT_BOOL_SETTING },
  },
  [SETTINGS_SECTIONS.gostSettings]: {
    innerZonePercents: { ...DEFAULT_NUMBER_SETTING },
    flawBilletZonePercentX: { ...DEFAULT_NUMBER_SETTING },
    flawBilletZonePercentY: { ...DEFAULT_NUMBER_SETTING },
    flawsZonePercent: { ...DEFAULT_NUMBER_SETTING },
    widthLengthRatio: { ...DEFAULT_NUMBER_SETTING },
  },
  [SETTINGS_SECTIONS.astmSettings]: {
    c: { ...DEFAULT_NUMBER_SETTING },
    r: { ...DEFAULT_NUMBER_SETTING },
    s: { ...DEFAULT_NUMBER_SETTING },
    cDefectPercent: { ...DEFAULT_NUMBER_SETTING },
    rDefectPercent: { ...DEFAULT_NUMBER_SETTING },
    sDefectPercent: { ...DEFAULT_NUMBER_SETTING },
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setInitialGeneralSettings: (state, action: PayloadAction<ISettings>) => {
      Object.assign(state, action.payload);
    },
    setAnalysisSetting: (
      state,
      action: PayloadAction<{ section: string; id: string; value: boolean | string | number }>,
    ) => {
      const { section, id, value } = action.payload;
      //@ts-ignore
      state[section][id].parameter = value;
    },
  },
});

export const { actions: settingsActions, reducer: settings } = settingsSlice;
