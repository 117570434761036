import { convertFileToBase64 } from './convertToBase64';

export const copyToClipboard = async (
  base64string: string | undefined,
  type: string
) => {
  if (!base64string) return;

  if (navigator.clipboard && window.isSecureContext) {
    const blob = base64toBlob(base64string, type);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const data = [new ClipboardItem({ [type]: blob })];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await navigator.clipboard.write(data);
  }
};

export const pasteFromClipboard = async (
  type: string,
  successCb: (base64string: string) => void
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      const blob = await item.getType(type);
      convertFileToBase64(blob, successCb);
    }
  } catch (err) {
    console.error(err);
  }
};

function base64toBlob(dataURI: string, type: string) {
  const byteString = atob(dataURI.split(',')[1]);

  const data = new ArrayBuffer(byteString.length);
  const uintArr = new Uint8Array(data);

  for (let i = 0; i < byteString.length; i++) {
    uintArr[i] = byteString.charCodeAt(i);
  }

  return new Blob([data], { type });
}
