import './styles.scss';

import React from 'react';

import { SCAN_VIEWS } from '../../consts';
import { useAppSelector } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { selectScanDataParameters, selectVolumeId } from '../../store/selectors';
import { PaletteBar } from '../PaletteBar';
import { ProjectionItem } from '../ProjectionList/ProjectionItem';
import { Pseudo3d } from '../Pseudo3d';

interface IProps {
  scanView: string;
}

export const SlicePage = ({ scanView }: IProps) => {
  const volumeId = useAppSelector(selectVolumeId);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));

  return (
    <DefaultLayout>
      <div className="slice__container">
        {scanDataParameters && (
          <>
            {/*<ProjectionItem scanDataParameters={scanDataParameters} scanView={scanView} tooltipHidden />*/}
            {scanView !== SCAN_VIEWS.threeD && (
              <ProjectionItem scanDataParameters={scanDataParameters} scanView={scanView} tooltipHidden />
            )}
            {scanView === SCAN_VIEWS.threeD && <Pseudo3d scanDataParameters={scanDataParameters} scanView={scanView} />}
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
