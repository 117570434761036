import './styles.scss';

import { navigate } from '@sms/plasma-ui';
import { Button, TranslatedText } from '@sms/plasma-ui/lib/components';
import React from 'react';

import { ReportAnalysisList } from '../../components/ReportAnalysisList';
import { ReportClientSettings } from '../../components/ReportClientSettings';
import { ReportForm } from '../../components/ReportForm';
import { BACKWALL_TABS } from '../../consts';
import { useActionCreators, useAppSelector, useBackwall } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import {
  useCreateReportMutation,
  useSaveResultsInDatabaseMutation,
  useSetRemarksMutation,
} from '../../store/api/report';
import { useSetClientSettingsMutation } from '../../store/api/settings';
import { selectIsBackwallFlawsCompleted, selectReportAnalysis } from '../../store/selectors';

export const Report = () => {
  const { setSpinnerVisible, setBackwallTab } = useActionCreators();
  const isBackwallFlawsCompleted = useAppSelector(selectIsBackwallFlawsCompleted);
  const { backwall } = useAppSelector(selectReportAnalysis);
  const [createReportTrigger] = useCreateReportMutation();
  const [saveResultsTrigger] = useSaveResultsInDatabaseMutation();
  const [setRemarksTrigger] = useSetRemarksMutation();
  const [setClientSettingsTrigger] = useSetClientSettingsMutation();
  const { handleTakeBackwallReportScreenshots } = useBackwall();
  const { setIsReportMode } = useActionCreators();

  const takeNeededReportScreenshots = async () => {
    setIsReportMode(true);

    if (Object.values(backwall).some(Boolean)) {
      for (const analysis in backwall) {
        if (analysis === BACKWALL_TABS.flaws && !isBackwallFlawsCompleted) {
          setBackwallTab(analysis);
          navigate('/backwall');
          await handleTakeBackwallReportScreenshots(analysis, true);
          navigate('/report');
        }
      }
    }

    setIsReportMode(false);
  };

  const saveResultsHandler = async () => {
    setSpinnerVisible(true);

    await takeNeededReportScreenshots();
    await setRemarksTrigger();
    await saveResultsTrigger();

    setSpinnerVisible(false);
  };

  const createReportHandler = async () => {
    setSpinnerVisible(true);

    await takeNeededReportScreenshots();
    await setRemarksTrigger();
    await setClientSettingsTrigger();
    await createReportTrigger();

    setSpinnerVisible(false);
  };

  return (
    <DefaultLayout>
      <div className="page-container report">
        <h3 className="side-menu__title">
          <TranslatedText textKey="Report" />
        </h3>
        <div className="report__content">
          <ReportForm />
          <ReportAnalysisList />
          <ReportClientSettings />
          <div style={{ display: 'flex' }}>
            <Button title="report.save-results" onClick={saveResultsHandler} />
            <Button title="report.create-report" onClick={createReportHandler} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
