import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React, { memo } from 'react';

import { useActionCreators } from '../../../hooks';
import { TReportAnalysis } from '../../../types';

interface Props {
  checked: boolean;
  disabled: boolean;
  title: string;
  id: string;
  analysis: TReportAnalysis;
}

export const ReportAnalysisListItem = memo(function ReportAnalysisListItem({
  analysis,
  id,
  checked,
  title,
  disabled,
}: Props) {
  const { setCheckedAnalysis } = useActionCreators();

  const handleChange = () => {
    setCheckedAnalysis({ analysis, id });
  };

  return (
    <li>
      <Checkbox
        hint="Hint"
        hintMode="hidden"
        name={`${analysis}-${id}`}
        onChange={handleChange}
        title={title}
        checked={checked}
        disabled={disabled}
      />
    </li>
  );
});
