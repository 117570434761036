import { Tabs } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText';
import React, { ChangeEvent, useCallback, useState } from 'react';

import { REMARKS_NAMES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectRemarks } from '../../../store/selectors';
import { Remarks } from '../../Remarks';
import { DistanceModify } from './DistanceModify';
import { DistanceStatistics } from './DistanceStatistics';

export const BackwallDistance = () => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const { backwallDistance } = useAppSelector(selectRemarks);
  const { setBackwallDistanceRemarks } = useActionCreators();

  const handleRemarksChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setBackwallDistanceRemarks(e.target.value);
  }, []);

  return (
    <div className="backwall__menu__inner">
      <DistanceModify />
      <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
        <TabPane tab={<TranslatedText textKey="side-menu.statistics" />} key={'0'}>
          <DistanceStatistics />
        </TabPane>
        <TabPane tab={<TranslatedText textKey="Remarks" />} key={'1'}>
          <Remarks
            value={backwallDistance}
            name={REMARKS_NAMES.backwallDistance}
            label={''}
            onChange={handleRemarksChange}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
