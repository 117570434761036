import { Box, useTexture } from '@react-three/drei';
import React, { FC } from 'react';

import { BuildTuple } from '../../../types';
import { IPseudo3DProjectionViewProps } from '../types';

export const MaxProjectionView: FC<IPseudo3DProjectionViewProps> = ({
  scale,
  sizeX,
  sizeY,
  sizeZ,
  sliceX,
  sliceY,
  sliceZ,
  xSliceImage,
  ySliceImage,
  zSliceImage,
  x1SliceImage,
  y1SliceImage,
  z1SliceImage,
  prevXSliceImage,
  prevYSliceImage,
  prevZSliceImage,
  isVisible,
}) => {
  const args: BuildTuple<3, number> = [sliceZ.end - sliceZ.start, sliceY.end - sliceY.start, sliceX.end - sliceX.start];

  const position: BuildTuple<3, number> = [
    ((sliceZ.end + sliceZ.start - sizeX) / 2) * scale,
    ((sizeY - sliceY.end - sliceY.start) / 2) * scale,
    ((sizeZ - sliceX.end - sliceX.start) / 2) * scale,
  ];

  const getCheckedTextures = () => {
    const x = xSliceImage ?? prevXSliceImage;
    const y = ySliceImage ?? prevYSliceImage;
    const z = zSliceImage ?? prevZSliceImage;
    const x1 = x1SliceImage ?? prevXSliceImage;
    const y1 = y1SliceImage ?? prevYSliceImage;
    const z1 = z1SliceImage ?? prevZSliceImage;

    if (!x || !y || !z || !x1 || !y1 || !z1) return [];

    return [x, x1, y, y1, z, z1];
  };

  const textures = useTexture(getCheckedTextures().map((item) => `data:image/gif;base64,${item}`));

  return (
    <group position={position} rotation={[0, 0, 0]} scale={scale} visible={isVisible}>
      <Box args={args}>
        {textures.map((texture, idx) => (
          <meshStandardMaterial
            key={idx}
            color={'white'}
            attachArray="material"
            map={texture}
            depthWrite={false}
            toneMapped={false}
          />
        ))}
      </Box>
    </group>
  );
};
