import { MouseEvent, useCallback, useState } from 'react';

import { DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE, FILE_TYPES } from '../consts';
import { IScanData, IScanDataProd } from '../store/api/scandata/types';
import { useActionCreators } from '.';

export const useFileSelectWithShift = (
  allFiles: (IScanData | IScanDataProd)[] | undefined,
  selectedFiles: (IScanData | IScanDataProd)[],
) => {
  const [previousSelected, setPreviousSelected] = useState<(IScanData | IScanDataProd) | null>(null);
  const [previousChecked, setPreviousChecked] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<(IScanData | IScanDataProd) | null>(null);

  const { setRecipeSelectedFile, setRecipeSelectedFilesArray } = useActionCreators();
  const availableFiles = allFiles?.filter(
    (item) => item.s3Key !== DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE && item.fileType !== FILE_TYPES.image,
  );

  const handleFileSelect = useCallback((rowData: IScanData | IScanDataProd) => {
    setRecipeSelectedFile(rowData);
  }, []);

  const selectMultipleFiles = useCallback((addOrRemove: boolean, items: (IScanData | IScanDataProd)[]) => {
    setRecipeSelectedFilesArray({ items, actionType: addOrRemove ? 'add' : 'remove' });
  }, []);

  const onFileClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, item: IScanData | IScanDataProd) => {
      if (!availableFiles) return;

      if (event.nativeEvent.shiftKey) {
        const previous = availableFiles.findIndex((x) => x.id === previousSelected?.id);
        const current = availableFiles.findIndex((x) => x.id === item.id);
        const previousCurrent = availableFiles.findIndex((x) => x.id === currentSelected?.id);

        const start = Math.min(current, previous);
        const end = Math.max(current, previous);

        if (start > -1 && end > -1) {
          selectMultipleFiles(previousChecked, availableFiles.slice(start, end + 1));
          if (current < previous) {
            selectMultipleFiles(previousChecked, availableFiles.slice(start, previous));

            return;
          }
          if (previousCurrent > end) {
            selectMultipleFiles(!previousChecked, availableFiles.slice(end + 1, previousCurrent + 1));

            return;
          }
          if (previousCurrent < start && previousCurrent > -1) {
            selectMultipleFiles(!previousChecked, availableFiles.slice(previousCurrent, start));
          }
          setCurrentSelected(item);

          return;
        }
      } else {
        setPreviousSelected(item);
        setCurrentSelected(null);
        setPreviousChecked(!selectedFiles.find((file) => file.id === item.id));
      }

      handleFileSelect(item);
    },
    [availableFiles, previousSelected, previousChecked, currentSelected, selectedFiles],
  );

  return onFileClick;
};
