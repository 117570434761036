import { TranslatedText } from '@sms/plasma-ui';
import React, { FC, ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

export const TitleWithControl: FC<Props> = ({ title, children }) => {
  return (
    <div className="title-wrapper" onClick={(e) => e.stopPropagation()}>
      <div>
        <TranslatedText textKey={title} />
      </div>
      {children}
    </div>
  );
};
