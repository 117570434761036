import { useCallback } from 'react';

import { IDENTIFIED_SNAPSHOTS, IDs, SEG_ANALYSIS, SHAPES, SLICE_TYPES } from '../consts';
import {
  useSetASTMParamsMutation,
  useSetGOSTParamsMutation,
  useSetMMParamsMutation,
  useSetSegASTMBilletRectZoneChangedMutation,
  useSetSegASTMBilletRoundZoneChangedMutation,
  useSetSegASTMSampleLongZoneChangedMutation,
  useSetSegGOSTBilletRectZoneChangedMutation,
  useSetSegGOSTBilletRoundZoneChangedMutation,
  useSetSegGOSTSlabZoneChangedMutation,
  useSetSegMMBilletRectangleZoneChangedMutation,
  useSetSegMMBilletRoundZoneChangedMutation,
  useSetSegMMSlabZoneChangedMutation,
  useSetSegSEPZoneChangedMutation,
  useSetSEPParamsMutation,
} from '../store/api/segregation';
import {
  IBilletRectangleZoneChangedBody,
  IBilletRoundZoneChangedBody,
  ISlabZoneChangedBody,
} from '../store/api/segregation/types';
import { useTakeSnapshotMutation } from '../store/api/snaphot';
import {
  selectIsPoresIncluded,
  selectSegAnalysis,
  selectSegAutomaticAnalysisResult,
  selectSegCenterPoints,
  selectSegInnerRectangleX0,
  selectSegInnerRectangleX1,
  selectSegLineY,
  selectSegSliderAndPaletteAutoAnalysis,
  selectSegThreshold,
  selectSegZoneDiameter,
  selectSegZoneHeight,
  selectSegZoneWidth,
  selectShapeBilletCutParam,
  selectSliceType,
  selectVolumeId,
} from '../store/selectors';
import { TSegAnalysis } from '../types';
import { asyncSetTimeout, getBase64Snapshot, getShortBase64String } from '../utils';
import { useActionCreators, useAppSelector } from '.';
import { useAutoAnalysisNavigate } from './useAutoAnalysisNavigate';

interface IParamsChangeParams {
  newThreshold?: number;
  newAnalysis?: TSegAnalysis;
}

export const useSegregation = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const zoneHeight = useAppSelector(selectSegZoneHeight);
  const shape = useAppSelector(selectShapeBilletCutParam);
  const zoneWidth = useAppSelector(selectSegZoneWidth);
  const zoneDiameter = useAppSelector(selectSegZoneDiameter);
  const segLineY = useAppSelector(selectSegLineY);
  const centerPoints = useAppSelector(selectSegCenterPoints);
  const segAnalysis = useAppSelector(selectSegAnalysis);
  const segZoneX0 = useAppSelector(selectSegInnerRectangleX0);
  const segZoneX1 = useAppSelector(selectSegInnerRectangleX1);
  const sliceType = useAppSelector(selectSliceType);
  const isPoresIncluded = useAppSelector(selectIsPoresIncluded);
  const threshold = useAppSelector(selectSegThreshold);
  const autoAnalysisMode = false;
  const segAutoAnalysisResults = useAppSelector(selectSegAutomaticAnalysisResult);
  const segAutoAnalysisPaletteAndRange = useAppSelector(selectSegSliderAndPaletteAutoAnalysis);
  const { setSegAnalysis, setSegregation, setPaletteData, setMaxProjectionSliderRange } = useActionCreators();

  const [setMannesmannParamsTrigger] = useSetMMParamsMutation({
    fixedCacheKey: 'mannesmannParams',
  });
  const [setSegGOSTParamsTrigger] = useSetGOSTParamsMutation();
  const [setASTMParamsTrigger] = useSetASTMParamsMutation();
  const [setSegSEPParamsTrigger] = useSetSEPParamsMutation();
  const [setSegSlabZoneChangeTrigger] = useSetSegMMSlabZoneChangedMutation();
  const [setSegBilletRectangleZoneChangeTrigger] = useSetSegMMBilletRectangleZoneChangedMutation();
  const [setSegBilletRoundZoneChangeTrigger] = useSetSegMMBilletRoundZoneChangedMutation();
  const [setSegASTMBilletRectZoneChangeTrigger] = useSetSegASTMBilletRectZoneChangedMutation();
  const [setSegASTMBilletRoundZoneChangeTrigger] = useSetSegASTMBilletRoundZoneChangedMutation();
  const [setSegASTMSampleLongZoneChangeTrigger] = useSetSegASTMSampleLongZoneChangedMutation();
  const [setSegGOSTBilletRectZoneChangeTrigger] = useSetSegGOSTBilletRectZoneChangedMutation();
  const [setSegGOSTBilletRoundZoneChangeTrigger] = useSetSegGOSTBilletRoundZoneChangedMutation();
  const [setSegGOSTSlabZoneChangeTrigger] = useSetSegGOSTSlabZoneChangedMutation();
  const [setSegSEPZoneChangeTrigger] = useSetSegSEPZoneChangedMutation();
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();
  const autoAnalysisNavigate = useAutoAnalysisNavigate();

  const isRect = sliceType === SLICE_TYPES.rect || shape === SHAPES.rect;
  const isRound = sliceType === SLICE_TYPES.round || shape === SHAPES.round;

  const isMMAnalysis = segAnalysis === SEG_ANALYSIS.mm;
  const isGOSTAnalysis = segAnalysis === SEG_ANALYSIS.gost;
  const isASTMAnalysis = segAnalysis === SEG_ANALYSIS.astm;
  const isSEPAnalysis = segAnalysis === SEG_ANALYSIS.sep;

  const takeReportScreenshot = useCallback(
    async (analysis: TSegAnalysis) => {
      const imageBase64String = await getBase64Snapshot(`#${IDs.commonGraphInnerWrapper}`);
      let name = '';

      const imageWidth = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetWidth ?? 0;
      const imageHeight = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetHeight ?? 0;

      const body = {
        volumeId,
        name,
        imageBase64String: getShortBase64String(imageBase64String),
        description: '',
        imageWidth,
        imageHeight,
      };

      switch (analysis) {
        case SEG_ANALYSIS.mm:
          name = IDENTIFIED_SNAPSHOTS.segMm;
          break;
        case SEG_ANALYSIS.gost:
          name = IDENTIFIED_SNAPSHOTS.segGost;
          break;
        case SEG_ANALYSIS.astm:
          name = IDENTIFIED_SNAPSHOTS.segAstm;
          break;
        case SEG_ANALYSIS.sep:
          name = IDENTIFIED_SNAPSHOTS.segSep;
          break;
        default:
          name = '';
      }

      body.name = name;

      await takeSnapshotTrigger(body);
    },
    [volumeId],
  );

  const handleSegParamsChange = useCallback(
    async ({ newThreshold, newAnalysis }: IParamsChangeParams = {}) => {
      switch (newAnalysis ?? segAnalysis) {
        case SEG_ANALYSIS.mm:
          await setMannesmannParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            isPoresIncluded,
            autoAnalysisMode,
          });
          break;
        case SEG_ANALYSIS.gost:
          await setSegGOSTParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            autoAnalysisMode,
          });
          break;
        case SEG_ANALYSIS.astm:
          await setASTMParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            autoAnalysisMode,
          });
          break;
        case SEG_ANALYSIS.sep:
          await setSegSEPParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            stepSize: 100,
            isPoresIncluded,
            autoAnalysisMode,
          });
          break;
        default:
          return;
      }
    },
    [isPoresIncluded, segAnalysis, volumeId, autoAnalysisMode],
  );

  const handleTakeSegAutoAnalysisScreenshots = useCallback(async () => {
    if (!segAutoAnalysisResults?.length || !segAutoAnalysisPaletteAndRange) return;

    setPaletteData(segAutoAnalysisPaletteAndRange.palette);
    setMaxProjectionSliderRange({ sliceC: segAutoAnalysisPaletteAndRange.sliderRange });

    for (const { analysis, data } of segAutoAnalysisResults) {
      if (!data) return;

      setSegAnalysis(analysis);
      setSegregation(data);

      await asyncSetTimeout(async () => await takeReportScreenshot(analysis), 300);
    }

    autoAnalysisNavigate();
  }, [segAutoAnalysisResults, segAutoAnalysisPaletteAndRange, takeReportScreenshot]);

  const handleTakeSegReportScreenshots = useCallback(async () => {
    await takeReportScreenshot(segAnalysis);
  }, [segAnalysis]);

  const handleSlabChange = useCallback(
    async (changedParams: Partial<ISlabZoneChangedBody>) => {
      const slabZoneChangedBody = {
        volumeId,
        zoneHeight: zoneHeight || -1,
        segLineY: segLineY || -1,
        segZoneX0: segZoneX0 || -1,
        segZoneX1: segZoneX1 || -1,
        ...changedParams,
      };

      if (isMMAnalysis) {
        await setSegSlabZoneChangeTrigger(slabZoneChangedBody);
      }

      if (isASTMAnalysis) {
        await setSegASTMSampleLongZoneChangeTrigger(slabZoneChangedBody);
      }

      if (isGOSTAnalysis) {
        await setSegGOSTSlabZoneChangeTrigger(slabZoneChangedBody);
      }

      if (isSEPAnalysis) await setSegSEPZoneChangeTrigger(slabZoneChangedBody);
    },
    [segAnalysis, volumeId, zoneHeight, segLineY, segZoneX0, segZoneX1, segAnalysis],
  );

  const handleBilletRectChange = useCallback(
    async (changedParams: Partial<IBilletRectangleZoneChangedBody>) => {
      const billetRectZoneChangedBody = {
        volumeId,
        zoneHeight: zoneHeight || -1,
        zoneWidth: zoneWidth || -1,
        centerPointX: centerPoints.x || -1,
        centerPointY: centerPoints.y || -1,
        ...changedParams,
      };

      if (isMMAnalysis) {
        await setSegBilletRectangleZoneChangeTrigger(billetRectZoneChangedBody);
      }

      if (isASTMAnalysis) {
        await setSegASTMBilletRectZoneChangeTrigger(billetRectZoneChangedBody);
      }

      if (isGOSTAnalysis) {
        await setSegGOSTBilletRectZoneChangeTrigger(billetRectZoneChangedBody);
      }
    },
    [segAnalysis, volumeId, zoneHeight, zoneWidth, centerPoints.x, centerPoints.y, segAnalysis],
  );

  const handleBilletRoundChange = useCallback(
    async (changedParams: Partial<IBilletRoundZoneChangedBody>) => {
      const billetRoundZoneChangedBody = {
        volumeId,
        zoneDiameter: zoneDiameter || -1,
        centerPointX: centerPoints.x || -1,
        centerPointY: centerPoints.y || -1,
        ...changedParams,
      };

      if (isMMAnalysis) {
        await setSegBilletRoundZoneChangeTrigger(billetRoundZoneChangedBody);
      }

      if (isASTMAnalysis) {
        await setSegASTMBilletRoundZoneChangeTrigger(billetRoundZoneChangedBody);
      }

      if (isGOSTAnalysis) {
        await setSegGOSTBilletRoundZoneChangeTrigger(billetRoundZoneChangedBody);
      }
    },
    [segAnalysis, volumeId, zoneDiameter, centerPoints.x, centerPoints.y, segAnalysis],
  );

  const handlePoresChange = async () => {
    if (isMMAnalysis) {
      await setMannesmannParamsTrigger({
        volumeId,
        threshold,
        isPoresIncluded: !isPoresIncluded,
        autoAnalysisMode,
      });
    }

    if (isSEPAnalysis) {
      await setSegSEPParamsTrigger({
        volumeId,
        threshold,
        stepSize: 100,
        isPoresIncluded: !isPoresIncluded,
        autoAnalysisMode,
      });
    }
  };

  const handleZoneHeightChange = (zoneHeight: number) => {
    if (isRect) {
      return handleBilletRectChange({ zoneHeight });
    }

    handleSlabChange({ zoneHeight });
  };

  const handleCenterPointChange = (
    changedCoords: Pick<IBilletRectangleZoneChangedBody, 'centerPointX' | 'centerPointY'>,
  ) => {
    if (isRect) handleBilletRectChange(changedCoords);
    if (isRound) handleBilletRoundChange(changedCoords);
  };

  return {
    handleSlabChange,
    handleBilletRectChange,
    handleBilletRoundChange,
    handleSegParamsChange,
    handleZoneHeightChange,
    handlePoresChange,
    handleCenterPointChange,
    isMMAnalysis,
    isSEPAnalysis,
    isRound,
    isRect,
    handleTakeSegAutoAnalysisScreenshots,
    handleTakeSegReportScreenshots,
  };
};
