import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components/Button/Button';
import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React from 'react';

import {
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  CRACKS_MODIFICATION_MODES,
  CRACKS_MODIFICATION_OPTIONS,
  CRACKS_MODIFICATION_OPTIONS_TITLES,
  CRACKS_TYPES,
  GREY_SECONDARY_EXTRA_LIGHT,
  WHITE_PRIMARY,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { useDeleteCrackMutation, useModifyCrackMutation } from '../../../../store/api/cracks';
import { TCrackType } from '../../../../store/api/cracks/types';
import {
  selectCracksAllCracks,
  selectCracksModificationMode,
  selectCracksSelectedCrackId,
  selectCracksSelectedTypes,
  selectShapeBilletCutParam,
  selectSliceType,
} from '../../../../store/selectors';
import { getCrackTypesLabelsEntries } from '../../../../utils';
import { getColorForMatchedCrackType } from '../../../../utils/cracks';

export const ModifyCracks = () => {
  const { setModificationMode, setSelectedCrackTypes, resetCracksSelectedCrackIds, setCracksSelectedCrackIds } =
    useActionCreators();
  const sliceType = useAppSelector(selectSliceType);
  const shape = useAppSelector(selectShapeBilletCutParam);
  const selectedCrackIds = useAppSelector(selectCracksSelectedCrackId);
  const allCracks = useAppSelector(selectCracksAllCracks);
  const modificationMode = useAppSelector(selectCracksModificationMode);
  const selectedCrackTypes = useAppSelector(selectCracksSelectedTypes);
  const [modifyCrackTrigger] = useModifyCrackMutation();
  const [deleteCrackTrigger] = useDeleteCrackMutation();

  const switchModificationMode = (e: any) => {
    const mode = e.target.name;

    setModificationMode(mode);
    resetCracksSelectedCrackIds();
    setSelectedCrackTypes();

    if (mode === CRACKS_MODIFICATION_MODES.insert) {
      setSelectedCrackTypes([CRACKS_TYPES.other]);
    }
  };

  const handleCrackTypeChange = async (e: any, type: TCrackType) => {
    if (modificationMode === CRACKS_MODIFICATION_MODES.insert) {
      setSelectedCrackTypes([type]);
    }

    if (modificationMode === CRACKS_MODIFICATION_MODES.modify) {
      if (selectedCrackIds.length === 0) return;

      setSelectedCrackTypes([type]);
      const crack = allCracks?.yoloCracks.find((i) => i.id === selectedCrackIds[0]);

      if (!crack) return;

      const {
        id,
        prediction: {
          rectangle: { x, y, width, height },
        },
      } = crack;

      await modifyCrackTrigger({
        id,
        crackBox: {
          x,
          y,
          width,
          height,
        },
        crackType: type,
      });
    }

    if (modificationMode === CRACKS_MODIFICATION_MODES.delete || modificationMode === CRACKS_MODIFICATION_MODES.info) {
      if (!allCracks) return;

      const matchedIds = allCracks.yoloCracks
        .filter(
          ({
            prediction: {
              label: { name },
            },
          }) => name === type,
        )
        .map((i) => i.id);

      if (selectedCrackTypes.includes(type)) {
        const types = selectedCrackTypes.filter((i) => i !== type);
        const filtredIds = selectedCrackIds.filter((i) => !matchedIds.includes(i));

        setSelectedCrackTypes(types);
        resetCracksSelectedCrackIds();
        setCracksSelectedCrackIds(filtredIds);

        return;
      }

      setSelectedCrackTypes([...selectedCrackTypes, type]);
      setCracksSelectedCrackIds([...selectedCrackIds, ...matchedIds]);
    }
  };

  const deleteSelectedCracksHandler = () => {
    deleteCrackTrigger(selectedCrackIds);
  };

  return (
    <div className="cracks__statistics__modify-cracks">
      <div className="cracks__statistics__modification">
        <h3 className="sub-menu__title">
          <TranslatedText text-key="cracks.cracks-modification" />
        </h3>
        <div className="statistics__modification__buttons">
          {CRACKS_MODIFICATION_OPTIONS.map((option, idx) => (
            <Button
              key={idx}
              name={option}
              title={CRACKS_MODIFICATION_OPTIONS_TITLES[option]}
              style={{
                backgroundColor: modificationMode === option ? BLUE_PRIMARY : 'transparent',
                borderColor: modificationMode === option ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
                color: modificationMode === option ? WHITE_PRIMARY : BLACK_PRIMARY,
              }}
              onClick={switchModificationMode}
            />
          ))}
          <Button
            title={'common.delete-selected'}
            disabled={modificationMode !== CRACKS_MODIFICATION_MODES.delete}
            onClick={deleteSelectedCracksHandler}
          />
        </div>
      </div>
      <div className="cracks__statistics__type">
        <h3 className="sub-menu__title">
          <TranslatedText textKey="cracks.cracktype" />
        </h3>
        <ul className="cracks__statistics__type__types">
          {
            // @ts-ignore
            getCrackTypesLabelsEntries(sliceType, shape).map(([type, title]) => (
              <li key={type}>
                <Checkbox
                  hint="Hint"
                  hintMode="hidden"
                  title={title}
                  disabled={
                    !modificationMode ||
                    (modificationMode === CRACKS_MODIFICATION_MODES.modify && selectedCrackIds.length === 0)
                  }
                  name={type}
                  checked={selectedCrackTypes?.includes(type)}
                  onChange={(e) => handleCrackTypeChange(e, type)}
                />
                <div
                  className={`label ${type}`}
                  style={{
                    backgroundColor: getColorForMatchedCrackType(type),
                  }}
                />
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};
