// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { IDs, RED_PRIMARY, TRANSPARENT } from '../../../../../../consts';
import { useActionCreators, useAppSelector, useSegregation } from '../../../../../../hooks';
import { selectSegAllowAreaModification } from '../../../../../../store/selectors';
import { ILineCoordinates, IRectCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import {
  getBaseSvgElemStyles,
  getToFixedValue,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../../../../../utils';

interface IProps {
  coordinates: IRectCoordinates;
  domains: TTupleOfTwoNumbers;
  graphParams: ICartesianModeParams;
  children?: React.ReactNode;
}

export const InnerRectangle: FC<IProps> = ({ coordinates, domains, graphParams, children }) => {
  const allowAreaModification = useAppSelector(selectSegAllowAreaModification);
  const { setSegInnerRectangle } = useActionCreators();
  const { handleSlabChange } = useSegregation();

  const getXOffset = getXOffsetFunc(graphParams, domains);
  const getYOffset = getYOffsetFunc(graphParams, domains);

  const x = getXOffset(coordinates.x0);
  const y = getYOffset(coordinates.y1);
  const width = ((coordinates.x1 - coordinates.x0) * graphParams.realSampleWidth) / domains[0];
  const height = ((coordinates.y1 - coordinates.y0) * graphParams.realSampleHeight) / domains[1];

  useEffect(() => {
    const segInnerRect = d3.select(`#${IDs.segInnerRect}`);
    const firstAuxiliaryLine = d3.select(`#${IDs.firstAuxiliaryLine}`);
    const secondAuxiliaryLine = d3.select(`#${IDs.secondAuxiliaryLine}`);

    if (!allowAreaModification) {
      firstAuxiliaryLine.attr('cursor', 'auto').call(d3.drag());

      secondAuxiliaryLine.attr('cursor', 'auto').call(d3.drag());

      return;
    }

    firstAuxiliaryLine.datum({ x1: x, y1: y, x2: x, y2: y + height }).attr('cursor', 'grab');

    secondAuxiliaryLine.datum({ x1: x + width, y1: y, x2: x + width, y2: y + height }).attr('cursor', 'grab');

    function dragstarted(this: SVGLineElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGLineElement, event: MouseEvent, d: ILineCoordinates) {
      const firstAuxiliaryLineDx = firstAuxiliaryLine.node().x1.baseVal.value;
      const secondAuxiliaryLineDx = secondAuxiliaryLine.node().x1.baseVal.value;

      if (d3.select(this).node().id === IDs.firstAuxiliaryLine) {
        d3.select(this)
          .attr('x1', (d.x1 = event.x))
          .attr('x2', (d.x2 = event.x));

        segInnerRect.attr('x', event.x).attr('width', secondAuxiliaryLineDx - event.x);
      }

      if (d3.select(this).node().id === IDs.secondAuxiliaryLine) {
        d3.select(this)
          .attr('x1', (d.x1 = event.x))
          .attr('x2', (d.x2 = event.x));

        segInnerRect.attr('width', event.x - firstAuxiliaryLineDx);
      }
    }

    function dragended(this: SVGLineElement, event: MouseEvent, d: ILineCoordinates) {
      d3.select(this).attr('cursor', 'grab');

      const dx = ((d.x1 - graphParams.x) * domains[0]) / graphParams.realSampleWidth;

      if (d3.select(this).node().id === IDs.firstAuxiliaryLine) {
        setSegInnerRectangle({
          x0: getToFixedValue(dx),
        });
        handleSlabChange({ segZoneX0: getToFixedValue(dx) });
      }

      if (d3.select(this).node().id === IDs.secondAuxiliaryLine) {
        setSegInnerRectangle({
          x1: getToFixedValue(dx),
        });
        handleSlabChange({ segZoneX1: getToFixedValue(dx) });
      }
    }

    firstAuxiliaryLine.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));

    secondAuxiliaryLine.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
  }, [domains, graphParams, coordinates, x, y, width, height, allowAreaModification]);

  return (
    <>
      <rect
        id={IDs.segInnerRect}
        x={x}
        y={y}
        width={width}
        height={height}
        style={getBaseSvgElemStyles(RED_PRIMARY, 1)}
      />
      {children}
      <line
        id={IDs.firstAuxiliaryLine}
        x1={x}
        y1={y}
        x2={x}
        y2={y + height}
        style={getBaseSvgElemStyles(TRANSPARENT, 1)}
      />
      <line
        id={IDs.secondAuxiliaryLine}
        x1={x + width}
        y1={y}
        x2={x + width}
        y2={y + height}
        style={getBaseSvgElemStyles(TRANSPARENT, 1)}
      />
    </>
  );
};
