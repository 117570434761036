import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import React, { FC } from 'react';

import { IMMStatistics, TSegStatistics } from '../../../../../store/api/segregation/types';

interface IProps {
  statistics: TSegStatistics;
  mmStatistics: IMMStatistics | null | undefined;
}

export const SpotsNumberList: FC<IProps> = ({ statistics, mmStatistics }) => {
  return (
    <>
      {statistics && (
        <ul className="seg__statistics__list">
          <li className="seg__statistics__item">
            <p>
              <TranslatedText textKey="side-menu.spots-number" />:
            </p>
          </li>
          {'defectsCounts' in statistics &&
            'sizes' in statistics &&
            statistics.sizes.map((item, idx) => (
              <li key={idx} className="seg__statistics__item">
                <p>{item}:</p>
                <p>
                  {statistics?.defectsCounts[idx] + `${mmStatistics ? ` (${mmStatistics?.defectsCounts[idx]})` : ''}`}
                </p>
              </li>
            ))}
          {'segregationsSizes' in statistics &&
            !('segregationsCounts' in statistics) &&
            statistics.segregationsSizes.map((item, idx) => (
              <li key={idx} className="seg__statistics__item">
                <p>{item}:</p>
              </li>
            ))}
          {'segregationsSizes' in statistics &&
            'segregationsCounts' in statistics &&
            statistics.segregationsSizes.map((item, idx) => (
              <li key={idx} className="seg__statistics__item">
                <p>{item}:</p>
                <p>{statistics.segregationsCounts[idx]}</p>
              </li>
            ))}
        </ul>
      )}
    </>
  );
};
