import './styles.scss';

import { Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { useState } from 'react';

import { FindCracks } from './FindsCracks';
import { ModifyCracks } from './ModifyCracks';

export const CracksStatistics = () => {
  const [activeKey, setActiveKey] = useState<string>('0');

  return (
    <div className="cracks__menu__statistics">
      <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
        <TabPane tab={<TranslatedText textKey="cracks.find-cracks" />} key={'0'}>
          <FindCracks />
        </TabPane>
        <TabPane tab={<TranslatedText textKey="cracks.modify-cracks" />} key={'1'}>
          <ModifyCracks />
        </TabPane>
      </Tabs>
    </div>
  );
};
