import React from 'react';

export const Cross = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179830_172487)">
        <path
          d="M15.2574 7.14122C15.2574 7.03319 15.169 6.94479 15.0609 6.94479L13.4404 6.95216L10.9998 9.86176L8.56161 6.95462L6.93862 6.94725C6.83058 6.94725 6.74219 7.03319 6.74219 7.14368C6.74219 7.19033 6.75937 7.23453 6.78884 7.27136L9.98326 11.0772L6.78884 14.8805C6.75917 14.9165 6.74271 14.9615 6.74219 15.0082C6.74219 15.1162 6.83058 15.2046 6.93862 15.2046L8.56161 15.1973L10.9998 12.2877L13.4379 15.1948L15.0585 15.2022C15.1665 15.2022 15.2549 15.1162 15.2549 15.0057C15.2549 14.9591 15.2377 14.9149 15.2083 14.8781L12.0187 11.0747L15.2132 7.2689C15.2426 7.23453 15.2574 7.18787 15.2574 7.14122Z"
          fill="#E3000F"
        />
        <path
          d="M11 0.0241814C4.92545 0.0241814 0 4.94963 0 11.0242C0 17.0987 4.92545 22.0242 11 22.0242C17.0746 22.0242 22 17.0987 22 11.0242C22 4.94963 17.0746 0.0241814 11 0.0241814ZM11 20.1581C5.9567 20.1581 1.86607 16.0675 1.86607 11.0242C1.86607 5.98088 5.9567 1.89025 11 1.89025C16.0433 1.89025 20.1339 5.98088 20.1339 11.0242C20.1339 16.0675 16.0433 20.1581 11 20.1581Z"
          fill="#E3000F"
        />
      </g>
      <defs>
        <clipPath id="clip0_179830_172487">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
