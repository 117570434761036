import { ISliceDataBody } from '../store/api/slice/types';
import { ISliderRange } from '../types/scanData';

export const createProjectionDataBody = (
  scanView: string,
  sliderRange: ISliderRange,
): ISliceDataBody => {
  return {
    projections: [
      {
        scanView,
        sliderRange,
      }
    ],
  };
};
