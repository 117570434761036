import { FLAWS_ANALYSIS, REPORT_TABS, SCAN_VIEWS } from '../../../consts';
import { createProjectionDataBody } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { IHeightAnalysisData } from '../segregation/types';
import { settingsApi } from '../settings';
import { sliceApi } from '../slice';
import {
  IFlawsAddZoneBody,
  IFlawsASTMRoundZoneFormChangedBody,
  IFlawsData,
  IFlawsGOSTRoundZoneFormChangedBody,
  IFlawsRectZoneChangedBody,
  IFlawsRectZoneFormChangedBody,
  IFlawsRemoveZoneBody,
  IFlawsRoundZoneChangedBody,
  IFlawsSMSParamsBody,
  TAnalysisParamsBody,
} from './types';

const {
  setFlaws,
  resetFlawsZones,
  setFlawsZonesReset,
  setMadeAnalysis,
  setMaxProjectionSliderRange,
  setSingleLayerSliderRange,
} = allActions;

export const flawsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFlawsHeightAnalysisData: build.query<IHeightAnalysisData, string>({
      query: (volumeId) => `flaws/heightAnalysis?volumeId=${volumeId}`,
    }),
    setFlawsSMSParams: build.mutation<IFlawsData, IFlawsSMSParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/sms',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as IFlawsData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              flawsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        dispatch(resetFlawsZones());
        dispatch(setFlawsZonesReset(false));
        dispatch(setFlaws(data));
        dispatch(
          setMadeAnalysis({
            analysis: REPORT_TABS.flaws,
            id: FLAWS_ANALYSIS.sms,
          }),
        );
        return { data };
      },
    }),
    setSMSZoneChanged: build.mutation<IFlawsData, IFlawsRectZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/sms/zoneChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setSMSZoneFormChanged: build.mutation<IFlawsData, IFlawsRectZoneFormChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/sms/zoneFormChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    addSMSZone: build.mutation<IFlawsData, IFlawsAddZoneBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/sms/addZone',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    removeSMSZone: build.mutation<IFlawsData, IFlawsRemoveZoneBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/sms/removeZone',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setFlawsGOSTParams: build.mutation<IFlawsData, TAnalysisParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/gost',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as IFlawsData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              flawsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        dispatch(resetFlawsZones());
        dispatch(setFlawsZonesReset(false));
        dispatch(setFlaws(data));
        dispatch(
          setMadeAnalysis({
            analysis: REPORT_TABS.flaws,
            id: FLAWS_ANALYSIS.gost,
          }),
        );

        return { data };
      },
    }),
    setGOSTZoneChanged: build.mutation<IFlawsData, IFlawsRectZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/gost/zoneChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setGOSTZoneFormChanged: build.mutation<IFlawsData, IFlawsRectZoneFormChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/gost/zoneFormChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setGOSTZoneRoundChanged: build.mutation<IFlawsData, IFlawsRoundZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/gost/zoneRoundChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setGOSTZoneRoundFormChanged: build.mutation<IFlawsData, IFlawsGOSTRoundZoneFormChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/gost/zoneRoundFormChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setFlawsASTMParams: build.mutation<IFlawsData, TAnalysisParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/astm',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as IFlawsData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              flawsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        dispatch(resetFlawsZones());
        dispatch(setFlawsZonesReset(false));
        dispatch(setFlaws(data));
        dispatch(
          setMadeAnalysis({
            analysis: REPORT_TABS.flaws,
            id: FLAWS_ANALYSIS.astm,
          }),
        );

        return { data };
      },
    }),
    setASTMZoneChanged: build.mutation<IFlawsData, IFlawsRectZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/astm/zoneChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setASTMZoneRoundChanged: build.mutation<IFlawsData, IFlawsRoundZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/astm/zoneRoundChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setASTMZoneFormChanged: build.mutation<IFlawsData, IFlawsRectZoneFormChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/astm/zoneFormChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
    setASTMZoneRoundFormChanged: build.mutation<IFlawsData, IFlawsASTMRoundZoneFormChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'flaws/astm/zoneRoundFormChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as IFlawsData;

        dispatch(resetFlawsZones());
        dispatch(setFlaws(data));

        return { data };
      },
    }),
  }),
});

export const {
  useSetFlawsSMSParamsMutation,
  useAddSMSZoneMutation,
  useRemoveSMSZoneMutation,
  useSetSMSZoneChangedMutation,
  useSetSMSZoneFormChangedMutation,
  useSetGOSTZoneChangedMutation,
  useSetGOSTZoneFormChangedMutation,
  useSetGOSTZoneRoundChangedMutation,
  useSetGOSTZoneRoundFormChangedMutation,
  useSetASTMZoneChangedMutation,
  useSetASTMZoneFormChangedMutation,
  useSetASTMZoneRoundChangedMutation,
  useSetASTMZoneRoundFormChangedMutation,
  useSetFlawsGOSTParamsMutation,
  useSetFlawsASTMParamsMutation,
  useLazyGetFlawsHeightAnalysisDataQuery,
} = flawsApi;
