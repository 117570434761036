import { LocalStorageKeys } from '../types';

export const getFromLocalStorage = (key: LocalStorageKeys | string) => {
  const value = localStorage.getItem(key);

  if (!value) return null;

  try {
    if (typeof JSON.parse(value) === 'object') {
      return JSON.parse(value);
    }
  } catch {
    return value;
  }
};

export const setToLocalStorage = (key: LocalStorageKeys, value: string | Record<string, unknown>) => {
  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const removeFromLocalStorage = (key: LocalStorageKeys) => {
  localStorage.removeItem(key);
};

export const setToExistingLocalStorageObject = (key: LocalStorageKeys, partialObject: Record<string, unknown>) => {
  const obj = getFromLocalStorage(key) || {};

  for (const property in partialObject) {
    obj[property] = partialObject[property];
  }

  setToLocalStorage(key, obj);
};
