import { INITIAL_COORDINATES } from '../consts';
import { TCoordinates, TTupleOfTwoNumbers } from '../types';
import { ICartesianModeParams } from './getCartesianModeParams';
import { IGraphParams } from './getGraphParams';
import { IMaxBoundaryValues } from './getMaxBoundaryValues';

export interface IConvertedBoundaryValues {
  convertedLeft: number,
  convertedTop: number,
  convertedRight: number,
  convertedBottom: number,
}

export const convertedBoundaryValuesToPx = (
  values: IMaxBoundaryValues,
  isCartesianMode: boolean,
  cartesianModeParams: ICartesianModeParams,
  graphParams: IGraphParams,
  domains: TTupleOfTwoNumbers,
  offsets: TCoordinates = INITIAL_COORDINATES
): IConvertedBoundaryValues => {
  const { left, top, right, bottom } = values;

  const [convertedLeft, convertedRight] = [left, right].map((value) => (
    isCartesianMode
      ? cartesianModeParams.x + value / domains[0] * cartesianModeParams.realSampleWidth + offsets.x
      : value / domains[0] * graphParams.innerSvgWidth + offsets.x
  ));

  const [convertedTop, convertedBottom] = [top, bottom].map((value) => (
    isCartesianMode
      ? cartesianModeParams.y + cartesianModeParams.realSampleHeight * (1 - value / domains[1]) + offsets.y
      : graphParams.innerSvgHeight - value / domains[1] * graphParams.innerSvgHeight + offsets.y
  ));

  return {
    convertedLeft,
    convertedTop,
    convertedRight,
    convertedBottom,
  };
};
