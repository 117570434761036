import 'react-range-slider-input/dist/style.css';
import './styles.scss';

import classNames from 'classnames';
import React, { useMemo } from 'react';

import { INPUT_NAMES, MAX_DEFECTIVE_VALUE, MIN_DEFECTIVE_VALUE, SCAN_VIEWS, SWITCH_NAMES } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { useGetSliceImageQuery } from '../../store/api/adapt-image';
import { useSetDistanceDataMutation } from '../../store/api/backwall';
import { useGetThreeDQuery } from '../../store/api/glb';
import { useSetPaletteMutation, useSetProjectionsDataMutation } from '../../store/api/slice';
import { TPaletteData } from '../../store/api/slice/types';
import {
  selectAutomaticAnalysisStatus,
  selectFileType,
  selectIsMaxProjection,
  selectIsVolume,
  selectMaxProjectionSliderRanges,
  selectModals,
  selectPaletteData,
  selectSingleLayerSliderRanges,
  selectVolumeId,
} from '../../store/selectors';
import { TTupleOfTwoNumbers } from '../../types';
import { checkAppRoute, checkFileType, createProjectionDataBody, debounce } from '../../utils';
import { NumberInputValue, RangeBar } from '../RangeBar';
import { SwitchGroup } from './SwitchGroup';

export const PaletteBar = () => {
  const { setPaletteMonochrome, setPaletteInverse, setPaletteCut, setPaletteColorRange } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const path = window.location.pathname;
  const { is3D, isBCDPlus, isBackwall } = checkAppRoute(path);
  const isVolume = useAppSelector(selectIsVolume);
  const {
    spinner: { isLoading },
  } = useAppSelector(selectModals);
  const isMaxProjection = useAppSelector(selectIsMaxProjection);
  const fileType = useAppSelector(selectFileType);
  const paletteData = useAppSelector(selectPaletteData);
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const {
    isMonochrome,
    isInverse,
    isScale,
    colorRange: { start, end },
  } = paletteData;
  const { sliceB, sliceC, sliceD } = useAppSelector(
    isMaxProjection ? selectMaxProjectionSliderRanges : selectSingleLayerSliderRanges,
  );
  const [paletteTrigger] = useSetPaletteMutation();
  const { isIDVFileType } = useMemo(() => checkFileType(fileType), [fileType]);
  const { refetch } = useGetSliceImageQuery(volumeId, {
    skip: isIDVFileType || isAutoAnalysis,
  });
  const [setBProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceB',
  });
  const [setCProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceC',
  });
  const [setDProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceD',
  });
  const [setBackwallDataTrigger] = useSetDistanceDataMutation({
    fixedCacheKey: 'backwallDistance',
  });
  const { refetch: refetch3D } = useGetThreeDQuery(undefined, { skip: !isVolume && (!isBCDPlus || !is3D) });

  const sliderRangeValue: TTupleOfTwoNumbers = useMemo(
    () => [MAX_DEFECTIVE_VALUE - end || MIN_DEFECTIVE_VALUE, MAX_DEFECTIVE_VALUE - start],
    [start, end],
  );

  const rangeSliderClasses = classNames({
    cropped: isScale,
    monochrome: isMonochrome,
    inverted: isInverse,
  });

  const handlePaletteColorRangeChange = useMemo(
    () =>
      debounce(async (params: TPaletteData, trigger: 'switch' | 'range' = 'range') => {
        await paletteTrigger({
          volumeId,
          ...params,
        }).then(() => {
          if (!isIDVFileType) {
            refetch();
          }
        });

        const promises: (() => any)[] = [
          setCProjectionDataTrigger.bind(null, createProjectionDataBody(SCAN_VIEWS.C, sliceC)),
        ];

        if (isIDVFileType) {
          promises.push(
            setBProjectionDataTrigger.bind(null, createProjectionDataBody(SCAN_VIEWS.B, sliceB)),
            setDProjectionDataTrigger.bind(null, createProjectionDataBody(SCAN_VIEWS.D, sliceD)),
          );

          if (isVolume && (is3D || isBCDPlus) && trigger === 'range') {
            promises.push(refetch3D);
          }
        }

        if (isBackwall) {
          promises.push(setBackwallDataTrigger);
        }

        await Promise.all(
          promises.map((cb) => {
            cb();
          }),
        );
      }, 300),
    [volumeId, sliceB, sliceC, sliceD, isIDVFileType, isBackwall, is3D, isBCDPlus, isVolume],
  );

  const handleSliderRangeChange = (params: TTupleOfTwoNumbers) => {
    const colorRange = {
      start: MAX_DEFECTIVE_VALUE - params[1] || MIN_DEFECTIVE_VALUE,
      end: MAX_DEFECTIVE_VALUE - params[0],
    };

    setPaletteColorRange(colorRange);
    handlePaletteColorRangeChange({
      ...paletteData,
      colorRange,
    });
  };

  const handleNumberInputChange = async (value: NumberInputValue, name: string) => {
    if (typeof value !== 'number' || isLoading) return;

    if (name === INPUT_NAMES.upper) {
      if (value > MAX_DEFECTIVE_VALUE) {
        setPaletteColorRange({ start, end: MAX_DEFECTIVE_VALUE - 1 });
        return;
      }

      if (value < start) return;

      setPaletteColorRange({ end: value });

      handlePaletteColorRangeChange({
        ...paletteData,
        colorRange: { start, end: value },
      });
    }
    if (name === INPUT_NAMES.lower) {
      if (value < MIN_DEFECTIVE_VALUE) return;
      if (value > end) return;

      setPaletteColorRange({ start: value });

      handlePaletteColorRangeChange({
        ...paletteData,
        colorRange: { start: value, end },
      });
    }
  };

  const handleSwitchClick = (name: string) => {
    if (name === SWITCH_NAMES.color) {
      setPaletteMonochrome();
      handlePaletteColorRangeChange(
        {
          ...paletteData,
          isMonochrome: !isMonochrome,
        },
        'switch',
      );
    }

    if (name === SWITCH_NAMES.invert) {
      setPaletteInverse();
      handlePaletteColorRangeChange(
        {
          ...paletteData,
          isInverse: !isInverse,
        },
        'switch',
      );
    }

    if (name === SWITCH_NAMES.cut) {
      setPaletteCut();
      handlePaletteColorRangeChange(
        {
          ...paletteData,
          isScale: !isScale,
        },
        'switch',
      );
    }
  };

  return (
    <div className="palette-bar">
      <SwitchGroup
        isScale={isScale}
        isMonochrome={isMonochrome}
        isInverse={isInverse}
        isDisabled={is3D && isVolume}
        handleSwitchClick={handleSwitchClick}
      />
      <RangeBar
        rangeSliderId={'rangeSliderPalette'}
        size={MAX_DEFECTIVE_VALUE}
        upperValue={end}
        lowerValue={start}
        value={sliderRangeValue}
        rangeSliderClasses={rangeSliderClasses}
        handleSliderRangeChange={handleSliderRangeChange}
        handleNumberInputChange={handleNumberInputChange}
        rangeSliderMinValue={MIN_DEFECTIVE_VALUE}
        decimalSeparator={'.'}
        rangeSlideDisabled
      />
    </div>
  );
};
