import { Button } from '@sms/plasma-ui';
import React from 'react';

import { useActionCreators, useAppSelector } from '../../../hooks';
import { useScaleSliceImageMutation } from '../../../store/api/adapt-image';
import { useLazyGetScanDataParametersQuery } from '../../../store/api/scandata';
import {
  selectAdaptImageScaleParams,
  selectIsAdaptImageScaleInserted,
  selectVolumeId,
} from '../../../store/selectors';

export const SetAdaptImageParams = () => {
  const {
    setGeometryAnalysisVisible,
    setAdaptImageCrop,
    setAdaptImageScaleInsert,
    setAdaptImageAccepted,
  } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const scaleParams = useAppSelector(selectAdaptImageScaleParams);
  const isScaleInserted = useAppSelector(selectIsAdaptImageScaleInserted);
  const [scaleTrigger] = useScaleSliceImageMutation();
  const [getScanDataParametersTrigger] = useLazyGetScanDataParametersQuery();

  const acceptHandler = async () => {
    await scaleTrigger({
      volumeId,
      ...scaleParams,
    }).then(() => setAdaptImageAccepted());

    await getScanDataParametersTrigger(volumeId);

    setGeometryAnalysisVisible(true);
    setAdaptImageCrop(false);
    setAdaptImageScaleInsert(false);
  };

  return (
    <div className="adapt__image__accept">
      <Button title={'adapt-image.set-accept'} disabled={!isScaleInserted} onClick={acceptHandler} />
    </div>
  );
};
