import { useMemo } from 'react';

import { DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE, FILE_TYPES, RECIPE_ANALYSIS_STATUS } from '../consts';
import { IScanData, IScanDataProd } from '../store/api/scandata/types';
import { selectRecipesSelectedRecipes } from '../store/selectors';
import { useActionCreators } from './useActionCreators';
import { useAppSelector } from './useAppSelector';

type IProps = {
  data: IScanData[];
  selectedRows: (IScanData | IScanDataProd)[] | null;
};

export const useFileTableColumnsCheck = ({ data, selectedRows }: IProps) => {
  const { setRecipeSelectedFilesArray, setRecipeSelectedRecipesArray } = useActionCreators();
  const selectedRecipes = useAppSelector(selectRecipesSelectedRecipes);
  const isFilesSelected = selectedRows?.length;

  const allAvailableFiles = useMemo(
    () =>
      data.filter((item) => item.s3Key !== DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE && item.fileType !== FILE_TYPES.image),
    [data],
  );
  const isNameChecked = selectedRows?.length === allAvailableFiles.length;

  const allRecipesInSelectedFiles = useMemo(() => {
    if (!selectedRows) return [];

    const allRecipes = [];
    for (const { recipes } of selectedRows) {
      allRecipes.push(
        ...recipes.filter(
          ({ analysisStatus }) =>
            analysisStatus === RECIPE_ANALYSIS_STATUS.new || analysisStatus === RECIPE_ANALYSIS_STATUS.inQueue,
        ),
      );
    }

    return allRecipes;
  }, [selectedRows]);

  const isRecipesChecked = useMemo(() => {
    if (!selectedRecipes.length || !selectedRows?.length) return false;

    const selectedIds = selectedRecipes.map((item) => item.id);
    return allRecipesInSelectedFiles.every((item) => selectedIds.includes(item.id));
  }, [selectedRecipes, allRecipesInSelectedFiles, selectedRows]);

  const handleNameChecked = () => {
    setRecipeSelectedFilesArray({
      items: allAvailableFiles,
      actionType: isNameChecked ? 'remove' : 'add',
    });
  };

  const handleRecipesChecked = () => {
    setRecipeSelectedRecipesArray({
      items: allRecipesInSelectedFiles,
      actionType: isRecipesChecked ? 'remove' : 'add',
    });
  };

  const handleCheckboxClick = (key: string) => {
    if (key === 'Name') {
      handleNameChecked();
    } else {
      handleRecipesChecked();
    }
  };

  return {
    handleCheckboxClick,
    isColumnsChecked: { Name: isNameChecked, Recipes: isRecipesChecked },
    isFilesSelected,
  };
};
