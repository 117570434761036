// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { BLUE_PRIMARY, GREEN_PRIMARY, IDs, INITIAL_COORDINATES, LIGHT_BLUE_PRIMARY, SHAPES } from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { selectBilletCutParams, selectBorderData } from '../../../../store/selectors';
import { TCoordinates, TTupleOfTwoNumbers } from '../../../../types';
import {
  convertedBoundaryValuesToPx,
  getBaseSvgElemStyles,
  getInnerSliceBorderOffsets,
  getMaxBoundaryValues,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { Path } from '../../../Path';

interface IProps {
  volumeId: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  cutParamsDomains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
  shape: string;
}

export const GeometryBilletCut: FC<IProps> = ({
  volumeId,
  graphParams,
  cartesianModeParams,
  domains,
  cutParamsDomains,
  shape,
}) => {
  const { setOffsetsCutParams } = useActionCreators();
  const { outerBorder } = useAppSelector((state) => selectBorderData(state, volumeId));
  const billetCutParams = useAppSelector(selectBilletCutParams);
  const outerRectWidth = cartesianModeParams.realSampleWidth;
  const outerRectHeight = cartesianModeParams.realSampleHeight;
  const innerRectWidth = (outerRectWidth * domains[0]) / cutParamsDomains[0];
  const innerRectHeight = (outerRectHeight * domains[1]) / cutParamsDomains[1];
  const rectXOffset = cartesianModeParams.x;
  const [sliceOffsets, setSliceOffsets] = useState<TCoordinates>(INITIAL_COORDINATES);
  const innerRectYOffset = outerRectHeight - innerRectHeight;
  const maxBoundaryValues = useMemo(() => getMaxBoundaryValues(outerBorder), [outerBorder]);

  const convertedValues = convertedBoundaryValuesToPx(
    maxBoundaryValues,
    true,
    cartesianModeParams,
    graphParams,
    cutParamsDomains
  );

  const { innerLeftOffset, innerTopOffset, innerRightOffset, innerBottomOffset } = getInnerSliceBorderOffsets(
    innerRectWidth,
    innerRectHeight,
    rectXOffset,
    innerRectYOffset,
    convertedValues
  );

  useLayoutEffect(() => {
    setSliceOffsets({
      x: billetCutParams.dx / cutParamsDomains[0] * outerRectWidth,
      y: -billetCutParams.dy / cutParamsDomains[1] * outerRectHeight,
    });
  }, [billetCutParams.dx, billetCutParams.dy, cutParamsDomains, outerRectWidth, outerRectHeight]);

  useEffect(() => {
    const sliceBorder = d3.select(`#${IDs.sliceBorder}`);

    sliceBorder
      .datum({ x: rectXOffset + sliceOffsets.x, y: innerRectYOffset + sliceOffsets.y })
      .attr('x', ({ x }: TCoordinates) => x)
      .attr('y', ({ y }: TCoordinates) => y);

    function dragstarted(this: SVGRectElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGRectElement, event: MouseEvent, d: TCoordinates) {
      d.x = event.x;
      d.y = event.y;

      const { convertedLeft, convertedTop, convertedRight, convertedBottom } = convertedBoundaryValuesToPx(
        maxBoundaryValues,
        true,
        cartesianModeParams,
        graphParams,
        cutParamsDomains,
        {
          x: d.x - rectXOffset,
          y: d.y - innerRectYOffset,
        }
      );

      if (convertedLeft <= rectXOffset) {
        d.x = rectXOffset - innerLeftOffset;
      }

      if (convertedRight >= rectXOffset + outerRectWidth) {
        d.x = rectXOffset + outerRectWidth - innerRectWidth + innerRightOffset;
      }

      if (convertedTop <= 0) {
        d.y = -innerTopOffset;
      }

      if (convertedBottom >= outerRectHeight) {
        d.y = outerRectHeight - innerRectHeight + innerBottomOffset;
      }

      d3.select(this).attr('x', d.x).attr('y', d.y);
      setSliceOffsets({
        x: d.x - rectXOffset,
        y: d.y - innerRectYOffset,
      });

      // conversion from px to mm
      const dx = ((d.x - rectXOffset) * cutParamsDomains[0]) / outerRectWidth;
      // conversion from px to mm
      const dy = ((-d.y + innerRectYOffset) * cutParamsDomains[1]) / outerRectHeight;
      setOffsetsCutParams({ dx, dy });
    }

    function dragended(this: SVGRectElement, event: MouseEvent, d: TCoordinates) {
      // conversion from px to mm
      const dx = ((d.x - rectXOffset) * cutParamsDomains[0]) / outerRectWidth;
      // conversion from px to mm
      const dy = ((-d.y + innerRectYOffset) * cutParamsDomains[1]) / outerRectHeight;

      d3.select(this).attr('cursor', 'auto');

      setOffsetsCutParams({ dx, dy });
    }

    sliceBorder.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
  }, [
    outerRectWidth,
    outerRectHeight,
    innerRectWidth,
    innerRectHeight,
    rectXOffset,
    innerRectYOffset,
    innerBottomOffset,
    innerLeftOffset,
    innerRightOffset,
    innerTopOffset,
    sliceOffsets.x,
    sliceOffsets.y,
    maxBoundaryValues,
    cartesianModeParams,
    graphParams,
    cutParamsDomains,
    setOffsetsCutParams,
  ]);

  return (
    <>
      {shape === SHAPES.rect ? (
        <rect
          width={outerRectWidth}
          height={outerRectHeight}
          x={rectXOffset}
          y={cartesianModeParams.y}
          fill={LIGHT_BLUE_PRIMARY}
        />
      ) : (
        <ellipse
          cx={rectXOffset + outerRectWidth / 2}
          cy={cartesianModeParams.y + outerRectHeight / 2}
          rx={outerRectWidth / 2}
          ry={outerRectHeight / 2}
          fill={LIGHT_BLUE_PRIMARY}
        />
      )}
      <circle cx={outerRectWidth / 2 + rectXOffset} cy={outerRectHeight / 2} r={5} />
      <Path
        cartesianModeParams={cartesianModeParams}
        data={outerBorder}
        domains={cutParamsDomains}
        graphParams={graphParams}
        isCartesianMode={true}
        pathOffsets={sliceOffsets}
        style={getBaseSvgElemStyles(GREEN_PRIMARY, 1, BLUE_PRIMARY)}
      />
      <rect
        id={IDs.sliceBorder}
        width={innerRectWidth}
        height={innerRectHeight}
        x={rectXOffset + sliceOffsets.x}
        y={innerRectYOffset + sliceOffsets.y}
        fill={'transparent'}
      />
    </>
  );
};
