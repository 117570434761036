import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { SHAPES } from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { selectBilletCutParams } from '../../../../store/selectors';

export const CutRow = () => {
  const { sizeX, sizeY, shape } = useAppSelector(selectBilletCutParams);
  const content = [
    { title: `${shape === SHAPES.rect ? 'dimensions.width' : 'dimensions.diameterX'}`, value: sizeX },
    { title: `${shape === SHAPES.rect ? 'dimensions.height' : 'dimensions.diameterY'}`, value: sizeY },
    { title: 'side-menu.area', value: shape === SHAPES.rect ? sizeX * sizeY : (((Math.PI * sizeX) / 2) * sizeY) / 2 },
  ];

  return (
    <>
      {content.map(({ title, value }, idx) => (
        <li key={idx} className="geometry__statistic__item">
          <p className="geometry__statistic__item__title">
            <TranslatedText textKey={title} />
            {idx === 2 ? (
              <span>
                {' '}
                [mm<sup>2</sup>]:
              </span>
            ) : (
              ' [mm]:'
            )}
          </p>
          <p className="geometry__statistic__item__content">{value.toFixed(1)}</p>
        </li>
      ))}
    </>
  );
};
