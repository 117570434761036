import './styles.scss';

import { Button, Modal, TranslatedText } from '@sms/plasma-ui';
import React, { MouseEvent, useMemo, useState } from 'react';

import { RECIPE_MODAL_MODES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useFilesSearch } from '../../../hooks/useFilesSearch';
import { useSaveRecipeFromEditorMutation, useSaveRecipeMutation } from '../../../store/api/recipes';
import { IRecipeTemplateInfo } from '../../../store/api/recipes/types';
import { useDeleteRecipeTemplateMutation, useLazyGetRecipeTemplateQuery } from '../../../store/api/recipes-template';
import { selectAllRecipeTemplates, selectRecipesSelectedFiles } from '../../../store/selectors';
import { TRecipesTemplatesModalMode } from '../../../types';
import { RecipesTemplatesTable } from '../../RecipesTemplatesTable';

interface IProps {
  mode: TRecipesTemplatesModalMode;
}

export const RecipesTemplatesListModal = ({ mode }: IProps) => {
  const selectedFiles = useAppSelector(selectRecipesSelectedFiles);
  const [selectedTemplate, setSelectedTemplate] = useState<IRecipeTemplateInfo | null>(null);
  const { setRecipesListVisible, resetRecipeSelectedFile } = useActionCreators();
  const [getRecipeTrigger] = useLazyGetRecipeTemplateQuery();
  const allRecipeTemplates = useAppSelector(selectAllRecipeTemplates);
  const [saveRecipeMutation] = useSaveRecipeMutation();
  const [saveRecipeFromEditorMutation] = useSaveRecipeFromEditorMutation();
  const [deleteRecipeTemplateTrigger] = useDeleteRecipeTemplateMutation();
  const { handleSearchFiles } = useFilesSearch();

  const handleClose = () => {
    setRecipesListVisible(null);
  };

  const handleRowClick = (e: MouseEvent<HTMLDivElement>, recipeData: IRecipeTemplateInfo) => {
    setSelectedTemplate(recipeData);
  };

  const handleDeleteClick = () => {
    if (!selectedTemplate) return;

    deleteRecipeTemplateTrigger(selectedTemplate?.id);
  };

  const renderButton = useMemo(() => {
    if (mode === RECIPE_MODAL_MODES.loadRecipe) {
      const onLoadClick = async () => {
        if (!selectedTemplate?.id) return;

        await getRecipeTrigger({ id: selectedTemplate.id, showResults: true });
        handleClose();
      };

      return <Button title="common.load" disabled={!selectedTemplate} onClick={onLoadClick} />;
    }

    if (mode === RECIPE_MODAL_MODES.assignRecipe) {
      const selectedFilesIds = selectedFiles.map((file) => file.id);

      const handleRefetchSearch = async () => {
        await handleSearchFiles();
        handleClose();
      };

      const onAssignClick = async () => {
        if (!selectedTemplate?.id) return;

        await saveRecipeMutation({ fileIds: selectedFilesIds, templateId: selectedTemplate.id })
          .then(handleRefetchSearch)
          .then(() => resetRecipeSelectedFile());
      };

      const onFromEditorClick = async () => {
        await saveRecipeFromEditorMutation(selectedFilesIds).then(handleRefetchSearch);
      };

      return (
        <>
          <Button title="recipes.from-editor" onClick={onFromEditorClick} />
          <Button title="recipes.assign-recipe" disabled={!selectedTemplate} onClick={onAssignClick} />
        </>
      );
    }
  }, [mode, selectedTemplate, selectedFiles]);

  if (!mode) return null;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destrohandleClose
      //@ts-ignore
      title={<TranslatedText textKey="Methods" />}
      onCancel={handleClose}
      visible={!!mode}
      width={900}
      zIndex={15}
      footer={null}
      className={'recipes-list__modal'}
    >
      {allRecipeTemplates?.length ? (
        <>
          <RecipesTemplatesTable
            selectedRecipe={selectedTemplate}
            onRowClick={handleRowClick}
            data={allRecipeTemplates}
          />
          <div className="recipes-list__modal__buttons">
            <Button title="recipes.delete-template" onClick={handleDeleteClick} disabled={!selectedTemplate} />
            {renderButton}
          </div>
        </>
      ) : (
        <TranslatedText textKey="recipes.no-recipes-found" />
      )}
    </Modal>
  );
};
