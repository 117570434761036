import './styles.scss';

import { Button } from '@sms/plasma-ui/lib/components';
import React, { useMemo } from 'react';

import { IDs, MODAL_STATUS, MODAL_TITLE } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { selectModals } from '../../store/selectors';
import { asyncSetTimeout, createModalMessageBody } from '../../utils';
import { Progress } from './Progress';
import { Spinner } from './Spinner';

export const Loader = () => {
  const { setSpinnerVisible, setMessageModal } = useActionCreators();
  const {
    spinner: { total, fullfilled, isLoading, hasCancellation, cancelCb },
  } = useAppSelector(selectModals);

  const percent = useMemo(() => {
    if (isLoading) {
      const res = Math.round((fullfilled / total) * 100);

      if (res >= 100) {
        return 99;
      }

      return res || 0;
    }
  }, [total, fullfilled, isLoading]);

  const handler = async () => {
    if (!cancelCb) return;
    setSpinnerVisible(true);
    //@ts-ignore
    await asyncSetTimeout(cancelCb, 0);
    setSpinnerVisible(false);
    setMessageModal(
      createModalMessageBody(
        MODAL_STATUS.NOTIFICATION,
        MODAL_TITLE.notification,
        'The current process has been stopped.',
      ),
    );
  };

  return isLoading ? (
    <>
      <Spinner />
      {hasCancellation && (
        <Button id={IDs.cancelProcess} title="Cancel process" disabled={!cancelCb} onClick={handler} />
      )}
      <Progress percent={percent} />
    </>
  ) : null;
};
