import { Checkbox } from '@sms/plasma-ui/lib/components';
import React, { FC } from 'react';

import { useActionCreators, useAppSelector } from '../../../../../hooks';
import { DBHeightAnalysisTransformedResponse } from '../../../../../store/api/database/types';
import { selectHeightAnalysisVisibleIds } from '../../../../../store/selectors';

interface Props {
  data: DBHeightAnalysisTransformedResponse[];
}

export const LegendList: FC<Props> = ({ data }) => {
  const { updateHeightAnalysisVisibleIds } = useActionCreators();
  const visibleIds = useAppSelector(selectHeightAnalysisVisibleIds);

  return (
    <div className="item__list">
      {data.map((d) => (
        <div key={d.analysisResultId} className="item__wrapper">
          <Checkbox
            name={d.analysisResultId}
            checked={visibleIds.includes(d.analysisResultId)}
            onChange={() => updateHeightAnalysisVisibleIds(d.analysisResultId)}
            title={d.title}
          />
          <div className="item__legend" style={{ backgroundColor: d.color }} />
        </div>
      ))}
    </div>
  );
};
