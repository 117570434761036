import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import { useGetDistanceStatisticsZonesQuery } from '../../../../store/api/backwall';
import { selectAutomaticAnalysisStatus, selectDistanceStatistics } from '../../../../store/selectors';
import { StatisticsTableRow } from './StatisticsTableRow';

export const DistanceStatistics = () => {
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  useGetDistanceStatisticsZonesQuery(undefined, { skip: isAutoAnalysis });
  const statistics = useAppSelector(selectDistanceStatistics);

  return (
    <div className="distance__statistics__table">
      <StatisticsTableRow
        zoneType={<TranslatedText textKey="side-menu.type" />}
        zoneId={'№'}
        width={
          <>
            <TranslatedText textKey="dimensions.width" />/<TranslatedText textKey="dimensions.height" /> (mm)
          </>
        }
        height={<></>}
        // lengthHeight={
        //   <>
        //     <TranslatedText textKey="dimensions.length" />/ <TranslatedText textKey="dimensions.height" /> (mm)
        //   </>
        // }
        sizePrecent={
          <>
            % (<TranslatedText textKey="from sample..." />)
          </>
        }
      />

      {statistics.map((stats) => (
        <StatisticsTableRow key={stats.zoneId} {...stats} />
      ))}
    </div>
  );
};
