import {
  BACKWALL_ANALYSIS,
  MODAL_STATUS,
  MODAL_TITLE,
  REPORT_TABS,
  SCAN_VIEWS,
  SEG_ANALYSIS,
  WARNING_MESSAGES,
} from '../../../consts';
import { createModalMessageBody, createProjectionDataBody } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { settingsApi } from '../settings';
import { sliceApi } from '../slice';
import {
  IASTMParamsBody,
  IBilletRectangleZoneChangedBody,
  IBilletRoundZoneChangedBody,
  IHeightAnalysisData,
  IMannesmannParamsBody,
  ISegData,
  ISEPParamsBody,
  ISlabZoneChangedBody,
} from './types';

const {
  setSegregation,
  setMadeAnalysis,
  setMessageModal,
  setMaxProjectionSliderRange,
  setSingleLayerSliderRange,
} = allActions;

export const segregationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSegHeightAnalysisData: build.query<IHeightAnalysisData, string>({
      query: (volumeId) => `segregations/heightAnalysis?volumeId=${volumeId}`,
    }),
    setMMParams: build.mutation<ISegData, IMannesmannParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/mannesmann',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as ISegData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              segregationsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        if (data?.backwallFlawsMessage) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, data.backwallFlawsMessage),
            ),
          );
        }

        if (data?.backwallFlawsCompleted) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
        }

        dispatch(setSegregation(data));

        if (!data.message) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.segregations,
              id: SEG_ANALYSIS.mm,
            }),
          );
        }

        return { data };
      },
    }),
    setSegMMSlabZoneChanged: build.mutation<ISegData, ISlabZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/mannesmannSlab/zoneChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSegMMBilletRectangleZoneChanged: build.mutation<ISegData, IBilletRectangleZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/mannesmannBilletRectangle/zoneChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSegMMBilletRoundZoneChanged: build.mutation<ISegData, IBilletRoundZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/mannesmannBilletRound/zoneChanged',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setASTMParams: build.mutation<ISegData, IASTMParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/astm',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as ISegData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              segregationsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        if (data?.backwallFlawsMessage) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, data.backwallFlawsMessage),
            ),
          );
        }

        if (data?.backwallFlawsCompleted) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
        }

        dispatch(setSegregation(data));

        if (!data.message) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.segregations,
              id: SEG_ANALYSIS.astm,
            }),
          );
        }

        return { data };
      },
    }),
    setSegASTMSampleLongZoneChanged: build.mutation<ISegData, ISlabZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/astmSampleLong/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSegASTMBilletRectZoneChanged: build.mutation<ISegData, IBilletRectangleZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/astmBilletRectangle/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSegASTMBilletRoundZoneChanged: build.mutation<ISegData, IBilletRoundZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/astmBilletRound/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setGOSTParams: build.mutation<ISegData, IASTMParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/gost',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as ISegData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              segregationsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        if (data?.backwallFlawsMessage) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, data.backwallFlawsMessage),
            ),
          );
        }

        if (data?.backwallFlawsCompleted) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
        }

        dispatch(setSegregation(data));

        if (!data.message) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.segregations,
              id: SEG_ANALYSIS.gost,
            }),
          );
        }

        return { data };
      },
    }),
    setSegGOSTSlabZoneChanged: build.mutation<ISegData, ISlabZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/gostSlab/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSegGOSTBilletRectZoneChanged: build.mutation<ISegData, IBilletRectangleZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/gostBilletRectangle/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSegGOSTBilletRoundZoneChanged: build.mutation<ISegData, IBilletRoundZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/gostBilletRound/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
    setSEPParams: build.mutation<ISegData, ISEPParamsBody>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/sep',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const data = result.data as ISegData;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.isRangeFromSettings && settings) {
          const {
            generalAnalysisSettings: {
              segregationsSettings: { rangeStart, rangeEnd },
            },
          } = settings;

          if (
            rangeStart.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
            rangeEnd.parameter !==
              (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
          ) {
            const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
            dispatch(setMaxProjectionSliderRange({ sliceC }));
            dispatch(setSingleLayerSliderRange({ sliceC }));
            await dispatch(
              sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                fixedCacheKey: 'sliceC',
              }),
            );
          }
        }

        if (data?.backwallFlawsMessage) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, data.backwallFlawsMessage),
            ),
          );
        }

        if (data?.backwallFlawsCompleted) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.backwall,
              id: BACKWALL_ANALYSIS.flaws,
            }),
          );
        }

        dispatch(setSegregation(data));

        if (data.message === WARNING_MESSAGES.sep || !data.message) {
          dispatch(
            setMadeAnalysis({
              analysis: REPORT_TABS.segregations,
              id: SEG_ANALYSIS.sep,
            }),
          );
        }

        return { data };
      },
    }),
    setSegSEPZoneChanged: build.mutation<ISegData, ISlabZoneChangedBody>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'segregations/sep/zoneChange',
          method: 'POST',
          body,
        });

        if (result?.error) {
          return { error: result.error };
        }

        const data = result.data as ISegData;

        dispatch(setSegregation(data));

        return { data };
      },
    }),
  }),
});

export const {
  useSetMMParamsMutation,
  useSetASTMParamsMutation,
  useSetGOSTParamsMutation,
  useSetSEPParamsMutation,
  useSetSegSEPZoneChangedMutation,
  useSetSegMMSlabZoneChangedMutation,
  useSetSegMMBilletRectangleZoneChangedMutation,
  useSetSegMMBilletRoundZoneChangedMutation,
  useSetSegASTMSampleLongZoneChangedMutation,
  useSetSegASTMBilletRectZoneChangedMutation,
  useSetSegASTMBilletRoundZoneChangedMutation,
  useSetSegGOSTSlabZoneChangedMutation,
  useSetSegGOSTBilletRectZoneChangedMutation,
  useSetSegGOSTBilletRoundZoneChangedMutation,
  useLazyGetSegHeightAnalysisDataQuery,
} = segregationApi;
