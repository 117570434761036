import { createSelector } from '@reduxjs/toolkit';

import { recipesTemplateApi } from '../api/recipes-template';
import { TypedRootState } from '../types';

export const selectRecipeName = (state: TypedRootState) => state.recipes.name;
export const selectRecipeDescription = (state: TypedRootState) => state.recipes.description;
export const selectRecipeSliceType = (state: TypedRootState) => state.recipes.sliceType;
export const selectRecipeData = (state: TypedRootState) => state.recipes.analysisStages;
export const selectRecipeSEPIncluded = (state: TypedRootState) => state.recipes.isSEPIncluded;
export const selectRecipePerformAnalysis = (state: TypedRootState) => state.recipes.performAnalysis;
export const selectRecipesSelectedFiles = (state: TypedRootState) => state.recipes.selectedFiles;
export const selectRecipesSelectedRecipes = (state: TypedRootState) => state.recipes.selectedRecipes;
export const selectRecipesId = (state: TypedRootState) => state.recipes.id;
export const selectAllRecipeTemplates = (state: TypedRootState) =>
  recipesTemplateApi.endpoints.getAllRecipeTemplates.select()(state)?.data;
// export const selectRecipeInitialName = createSelector(
//   [selectAllRecipeTemplates, (state: TypedRootState, id: string) => id],
//   (allTemplates, id) => {
//     if (!id || allTemplates?.length === 0) return;

//     const initialRecipe = allTemplates?.find((template) => template.id === id);

//     return initialRecipe?.name;
//   }
// );
export const selectIsSelectedAnalysisTypes = createSelector(selectRecipeData, (analysisStages) =>
  Object.entries(analysisStages).some(([_, data]) => data.SelectedAnalysisTypes.length > 0)
);
