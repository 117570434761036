import { IDatabaseState } from '../../../types';
import { getDateWithTime, getISODateWithTimezone, getRangeOfTime } from '../../../utils';

export const RESULT_TABLE_INIT_STATE = {
  name: {
    visible: true,
    active: true,
  },
  fileType: {
    visible: true,
    active: true,
  },
  analysisDate: {
    visible: true,
    active: true,
  },
  fileLoadDate: {
    visible: true,
    active: true,
  },
  controllerName: {
    visible: true,
    active: true,
  },
  sliceType: {
    visible: true,
    active: true,
  },
  sampleHeight: {
    visible: true,
    active: true,
  },
  geometryAnalysis: {
    sampleWidth: {
      visible: false,
      active: false,
    },
    aLinesLeft: {
      visible: false,
      active: false,
    },
    aLinesRight: {
      visible: false,
      active: false,
    },
    bLinesLeftDeviationMax: {
      visible: false,
      active: false,
    },
    bLinesLeftDeviationMin: {
      visible: false,
      active: false,
    },
    bLinesRightDeviationMax: {
      visible: false,
      active: false,
    },
    bLinesRightDeviationMin: {
      visible: false,
      active: false,
    },
    cLinesLeft: {
      visible: false,
      active: false,
    },
    cLinesRight: {
      visible: false,
      active: false,
    },
    diagonalsRatio: {
      visible: false,
      active: false,
    },
    dimensionsWidthAvg: {
      visible: false,
      active: false,
    },
    dimensionsWidthRSD: {
      visible: false,
      active: false,
    },
    dimensionsHeightAvg: {
      visible: false,
      active: false,
    },
    dimensionsHeightRSD: {
      visible: false,
      active: false,
    },
    billetCutShape: {
      visible: false,
      active: false,
    },
    motherSampleArea: {
      visible: false,
      active: false,
    },
    motherSampleSizeX: {
      visible: false,
      active: false,
    },
    motherSampleSizeY: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsMmClass: {
    visible: true,
    active: true,
  },
  segregationsAnalysisMM: {
    classDecimal: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    threshold: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    area: {
      visible: false,
      active: false,
    },
    normArea: {
      visible: false,
      active: false,
    },
    lessThanOne: {
      visible: false,
      active: false,
    },
    oneToThree: {
      visible: false,
      active: false,
    },
    threeToFive: {
      visible: false,
      active: false,
    },
    fiveToTen: {
      visible: false,
      active: false,
    },
    moreThanTen: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsSep1Class: {
    visible: true,
    active: true,
  },
  segregationsAnalysisSEP1: {
    classDecimal: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    threshold: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    area: {
      visible: false,
      active: false,
    },
    normArea: {
      visible: false,
      active: false,
    },
    lessThanOne: {
      visible: false,
      active: false,
    },
    oneToThree: {
      visible: false,
      active: false,
    },
    threeToFive: {
      visible: false,
      active: false,
    },
    fiveToTen: {
      visible: false,
      active: false,
    },
    moreThanTen: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsSep100Class: {
    visible: true,
    active: true,
  },
  segregationsAnalysisSEP100: {
    classDecimal: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    threshold: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    area: {
      visible: false,
      active: false,
    },
    normArea: {
      visible: false,
      active: false,
    },
    lessThanOne: {
      visible: false,
      active: false,
    },
    oneToThree: {
      visible: false,
      active: false,
    },
    threeToFive: {
      visible: false,
      active: false,
    },
    fiveToTen: {
      visible: false,
      active: false,
    },
    moreThanTen: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsAnalysisASTM: {
    classDecimal: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    threshold: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    area: {
      visible: false,
      active: false,
    },
    normArea: {
      visible: false,
      active: false,
    },
    lessThanOne: {
      visible: false,
      active: false,
    },
    oneToThree: {
      visible: false,
      active: false,
    },
    threeToFive: {
      visible: false,
      active: false,
    },
    fiveToTen: {
      visible: false,
      active: false,
    },
    moreThanTen: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsGostClass: {
    visible: true,
    active: true,
  },
  segregationsAnalysisGostSlabResult: {
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    poresClass: {
      visible: false,
      active: false,
    },
    poresLessThanPointFive: {
      visible: false,
      active: false,
    },
    poresFromPointFiveToOne: {
      visible: false,
      active: false,
    },
    poresOneToThree: {
      visible: false,
      active: false,
    },
    poresMoreThanThree: {
      visible: false,
      active: false,
    },
    lessThanThree: {
      visible: false,
      active: false,
    },
    moreThanThree: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsAnalysisGostBilletResult: {
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    zoneCenterX: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    poresClass: {
      visible: false,
      active: false,
    },
    poresLessThanPointFive: {
      visible: false,
      active: false,
    },
    poresFromPointFiveToOne: {
      visible: false,
      active: false,
    },
    poresOneToThree: {
      visible: false,
      active: false,
    },
    poresMoreThanThree: {
      visible: false,
      active: false,
    },
    lessThanOne: {
      visible: false,
      active: false,
    },
    oneToThree: {
      visible: false,
      active: false,
    },
    threeToX1: {
      visible: false,
      active: false,
    },
    x1: {
      visible: false,
      active: false,
    },
    x1ToX2: {
      visible: false,
      active: false,
    },
    x2: {
      visible: false,
      active: false,
    },
    moreThanX2: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  segregationsAstmBilletClass: {
    visible: true,
    active: true,
  },
  segregationsAnalysisAstmBilletResult: {
    zoneCenterX: {
      visible: false,
      active: false,
    },
    zoneCenterY: {
      visible: false,
      active: false,
    },
    zoneWidth: {
      visible: false,
      active: false,
    },
    zoneHeight: {
      visible: false,
      active: false,
    },
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    lessThanOneC: {
      visible: false,
      active: false,
    },
    lessThanOneR: {
      visible: false,
      active: false,
    },
    lessThanOneS: {
      visible: false,
      active: false,
    },
    moreThanX2C: {
      visible: false,
      active: false,
    },
    moreThanX2R: {
      visible: false,
      active: false,
    },
    moreThanX2S: {
      visible: false,
      active: false,
    },
    oneToThreeC: {
      visible: false,
      active: false,
    },
    oneToThreeR: {
      visible: false,
      active: false,
    },
    oneToThreeS: {
      visible: false,
      active: false,
    },
    threeToX1C: {
      visible: false,
      active: false,
    },
    threeToX1R: {
      visible: false,
      active: false,
    },
    threeToX1S: {
      visible: false,
      active: false,
    },
    x1: {
      visible: false,
      active: false,
    },
    x1ToX2C: {
      visible: false,
      active: false,
    },
    x1ToX2R: {
      visible: false,
      active: false,
    },
    x1ToX2S: {
      visible: false,
      active: false,
    },
    x2: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  flawsSmsClass: {
    visible: true,
    active: true,
  },
  flawsAnalysisSmsResult: {
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    regionsCount: {
      visible: false,
      active: false,
    },
    resultsByRegions: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  flawsGostClass: {
    visible: true,
    active: true,
  },
  flawsAnalysisGostSlabResult: {
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    class1: {
      visible: false,
      active: false,
    },
    class2: {
      visible: false,
      active: false,
    },
    region1X1: {
      visible: false,
      active: false,
    },
    region1X2: {
      visible: false,
      active: false,
    },
    region1Y1: {
      visible: false,
      active: false,
    },
    region1Y2: {
      visible: false,
      active: false,
    },
    region2X1: {
      visible: false,
      active: false,
    },
    region2X2: {
      visible: false,
      active: false,
    },
    region2Y1: {
      visible: false,
      active: false,
    },
    region2Y2: {
      visible: false,
      active: false,
    },
    lessThanOneReg1: {
      visible: false,
      active: false,
    },
    oneToTwoReg1: {
      visible: false,
      active: false,
    },
    moreThanTwoReg1: {
      visible: false,
      active: false,
    },
    lessThanOneReg2: {
      visible: false,
      active: false,
    },
    oneToTwoReg2: {
      visible: false,
      active: false,
    },
    moreThanTwoReg2: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  flawsAnalysisGostBilletResult: {
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    region1Radius: {
      visible: false,
      active: false,
    },
    region2Radius: {
      visible: false,
      active: false,
    },
    regionCenterX: {
      visible: false,
      active: false,
    },
    regionCenterY: {
      visible: false,
      active: false,
    },
    regionInnerX1: {
      visible: false,
      active: false,
    },
    regionInnerX2: {
      visible: false,
      active: false,
    },
    regionInnerY1: {
      visible: false,
      active: false,
    },
    regionInnerY2: {
      visible: false,
      active: false,
    },
    regionOuterX1: {
      visible: false,
      active: false,
    },
    regionOuterX2: {
      visible: false,
      active: false,
    },
    regionOuterY1: {
      visible: false,
      active: false,
    },
    regionOuterY2: {
      visible: false,
      active: false,
    },
    lessThanPointFive: {
      visible: false,
      active: false,
    },
    oneToThree: {
      visible: false,
      active: false,
    },
    moreThanThree: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  flawsAstmClass: {
    visible: true,
    active: true,
  },
  flawsAnalysisAstmResult: {
    threshold: {
      visible: false,
      active: false,
    },
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    regionCenterX: {
      visible: false,
      active: false,
    },
    regionCenterY: {
      visible: false,
      active: false,
    },
    regionRRadius: {
      visible: false,
      active: false,
    },
    regionRX1: {
      visible: false,
      active: false,
    },
    regionRX2: {
      visible: false,
      active: false,
    },
    regionRY1: {
      visible: false,
      active: false,
    },
    regionRY2: {
      visible: false,
      active: false,
    },
    regionSRadius: {
      visible: false,
      active: false,
    },
    regionSX1: {
      visible: false,
      active: false,
    },
    regionSX2: {
      visible: false,
      active: false,
    },
    regionSY1: {
      visible: false,
      active: false,
    },
    regionSY2: {
      visible: false,
      active: false,
    },
    lessThanOneR: {
      visible: false,
      active: false,
    },
    lessThanOneS: {
      visible: false,
      active: false,
    },
    oneToThreeR: {
      visible: false,
      active: false,
    },
    oneToThreeS: {
      visible: false,
      active: false,
    },
    threeToX1R: {
      visible: false,
      active: false,
    },
    threeToX1S: {
      visible: false,
      active: false,
    },
    x1: {
      visible: false,
      active: false,
    },
    x1ToX2R: {
      visible: false,
      active: false,
    },
    x1ToX2S: {
      visible: false,
      active: false,
    },
    x2: {
      visible: false,
      active: false,
    },
    moreThanX2R: {
      visible: false,
      active: false,
    },
    moreThanX2S: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  cracksSmsClass: {
    visible: true,
    active: true,
  },
  cracksAnalysisSmsResult: {
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    centerClass: {
      visible: false,
      active: false,
    },
    longitudinalClass: {
      visible: false,
      active: false,
    },
    nearSurfaceClass: {
      visible: false,
      active: false,
    },
    nfN_SClass: {
      visible: false,
      active: false,
    },
    nfN_TClass: {
      visible: false,
      active: false,
    },
    offCornerClass: {
      visible: false,
      active: false,
    },
    otherClass: {
      visible: false,
      active: false,
    },
    transversalClass: {
      visible: false,
      active: false,
    },
    tripplePointClass: {
      visible: false,
      active: false,
    },
    zeroToFive: {
      visible: false,
      active: false,
    },
    sixToTwenty: {
      visible: false,
      active: false,
    },
    twentyOneToForty: {
      visible: false,
      active: false,
    },
    fortyOneToSixty: {
      visible: false,
      active: false,
    },
    moreThanSixty: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  cracksGostClass: {
    visible: true,
    active: true,
  },
  cracksAnalysisGostResult: {
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    centerClass: {
      visible: false,
      active: false,
    },
    longitudinalClass: {
      visible: false,
      active: false,
    },
    nearSurfaceClass: {
      visible: false,
      active: false,
    },
    nearSurfaceClassNs: {
      visible: false,
      active: false,
    },
    crossSectionClass: {
      visible: false,
      active: false,
    },
    nfN_SClass: {
      visible: false,
      active: false,
    },
    nfN_SClassNs: {
      visible: false,
      active: false,
    },
    nfN_TClass: {
      visible: false,
      active: false,
    },
    nfN_TClassNs: {
      visible: false,
      active: false,
    },
    offCornerClass: {
      visible: false,
      active: false,
    },
    offCornerClassNs: {
      visible: false,
      active: false,
    },
    otherClass: {
      visible: false,
      active: false,
    },
    transversalClass: {
      visible: false,
      active: false,
    },
    tripplePointClass: {
      visible: false,
      active: false,
    },
    tripplePointClassNs: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  cracksAstmClass: {
    visible: true,
    active: true,
  },
  cracksAnalysisAstmResult: {
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    centerClass: {
      visible: false,
      active: false,
    },
    crossSectionClass: {
      visible: false,
      active: false,
    },
    offCornerClass: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  cracksAnalysisCracksCount: {
    visible: false,
    active: false,
  },
  backwallDistanceAnalysis: {
    rangeStart: {
      visible: false,
      active: false,
    },
    rangeEnd: {
      visible: false,
      active: false,
    },
    zonesCount: {
      visible: false,
      active: false,
    },
    zones: {
      visible: false,
      active: false,
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  scanInfo: {
    scanningSpeed: {
      visible: false,
      active: false,
    },
    shortDistance: {
      visible: false,
      active: false,
    },
    amplificationFlawSensor: {
      visible: false,
      active: false,
    },
    amplificationBackSensor: {
      visible: false,
      active: false,
    },
    preAmplificationFlawSensor: {
      visible: false,
      active: false,
    },
    preAmplificationBackSensor: {
      visible: false,
      active: false,
    },
  },
  sampleInfo: {
    manufacturerData: {
      producer: {
        visible: false,
        active: false,
      },
      site: {
        visible: false,
        active: false,
      },
      plant: {
        visible: false,
        active: false,
      },
      productionDate: {
        visible: false,
        active: false,
      },
      qualityCode: {
        visible: false,
        active: false,
      },
      steelGrade: {
        visible: false,
        active: false,
      },
    },
    materialAnalysis: {
      c: {
        visible: false,
        active: false,
      },
      s: {
        visible: false,
        active: false,
      },
      si: {
        visible: false,
        active: false,
      },
      mn: {
        visible: false,
        active: false,
      },
      p: {
        visible: false,
        active: false,
      },
      n: {
        visible: false,
        active: false,
      },
      al: {
        visible: false,
        active: false,
      },
      cu: {
        visible: false,
        active: false,
      },
      cr: {
        visible: false,
        active: false,
      },
      ni: {
        visible: false,
        active: false,
      },
      v: {
        visible: false,
        active: false,
      },
      ti: {
        visible: false,
        active: false,
      },
      nb: {
        visible: false,
        active: false,
      },
      b: {
        visible: false,
        active: false,
      },
      mo: {
        visible: false,
        active: false,
      },
      as: {
        visible: false,
        active: false,
      },
      sn: {
        visible: false,
        active: false,
      },
      ca: {
        visible: false,
        active: false,
      },
      h2: {
        visible: false,
        active: false,
      },
    },
    productionData: {
      slabId: {
        visible: false,
        active: false,
      },
      slabSampleId: {
        visible: false,
        active: false,
      },
      castingMachine: {
        visible: false,
        active: false,
      },
      castingSpeed: {
        visible: false,
        active: false,
      },
      heatNumber: {
        visible: false,
        active: false,
      },
      moldOscillationFrequency: {
        visible: false,
        active: false,
      },
      negativeStrip: {
        visible: false,
        active: false,
      },
      numberOfSRSegments: {
        visible: false,
        active: false,
      },
      numberOfSamples: {
        visible: false,
        active: false,
      },
      sequence: {
        visible: false,
        active: false,
      },
      slabThickness: {
        visible: false,
        active: false,
      },
      slabWidth: {
        visible: false,
        active: false,
      },
      softReductionParameters: {
        visible: false,
        active: false,
      },
      softReductionRate: {
        visible: false,
        active: false,
      },
      specificSprayCoolingCurve: {
        visible: false,
        active: false,
      },
      thermalTaper: {
        visible: false,
        active: false,
      },
      thermalTaperParameters: {
        visible: false,
        active: false,
      },
      tundishSuperheat: {
        visible: false,
        active: false,
      },
    },
    remarks: {
      visible: false,
      active: false,
    },
  },
  snapshots: {
    visible: true,
    active: true,
  },
  heightAnalysis: {
    visible: true,
    active: true,
  },
  report: {
    visible: true,
    active: true,
  },
};

const defaultStartDateForDB = getDateWithTime(getRangeOfTime(4).start, 'start');
const defaultEndDateForDB = getDateWithTime(getISODateWithTimezone(new Date()), 'end');

export const dBInitialState: IDatabaseState = {
  scanDataFileFilter: {
    createdAt: {
      end: defaultEndDateForDB,
      start: defaultStartDateForDB,
    },
    name: {
      contains: true,
      value: '',
    },
    fileType: {
      value: '',
    },
  },
  analysisResultDateFilter: {
    createdAt: {
      end: defaultEndDateForDB,
      start: defaultStartDateForDB,
    },
    controllerName: {
      value: '',
    },
  },
  geometryFilter: {
    sliceTypes: [],
    sampleHeight: {
      end: 0,
      start: 0,
    },
  },
  segregationsFilter: {
    mmanFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    sep1Filter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    sep100Filter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    astmSlabFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    gostFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    astmBilletFilter: {
      classC: null,
      classR: null,
      classS: null,
    },
  },
  flawsFilter: {
    smsFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    gostFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    astmFilter: {
      classC: null,
      classR: null,
      classS: null,
    },
  },
  cracksFilter: {
    cracksDetectionFilter: {
      longitudinalCracksFilter: {
        isUsed: false,
      },
      nearSurfaceCracksFilter: {
        isUsed: false,
      },
      centerCracksFilter: {
        isUsed: false,
      },
      transversalCracksFilter: {
        isUsed: false,
      },
      offCornerCracksFilter: {
        isUsed: false,
      },
      nfN_TCracksFilter: {
        isUsed: false,
      },
      nfN_SCracksFilter: {
        isUsed: false,
      },
      tripplePointCracksFilter: {
        isUsed: false,
      },
      crossSectionCracksFilter: {
        isUsed: false,
      },
      otherCracksFilter: {
        isUsed: false,
      },
    },
    smsFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    gostFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
    astmFilter: {
      class: {
        end: 0,
        start: 0,
      },
    },
  },
  backwallDistanceFilter: {
    dendriticZoneFilter: {
      isUsed: false,
    },
    equaxedZoneFilter: {
      isUsed: false,
    },
    otherZoneFilter: {
      isUsed: false,
    },
  },
  additionalFilters: {},
  resultTable: RESULT_TABLE_INIT_STATE,
  heightAnalysis: {
    activeIds: [],
    visibleIds: [],
  },
  snapshots: {
    analysisResultsIds: [],
    activeSnapshots: { checked: [], halfChecked: [] },
    selectedSnapshotNames: [],
    selectedSnapshotTypes: {
      geometryA: true,
      geometryB: true,
      geometryC: true,
      geometryDimensions: true,
      geometryDiagonals: true,
      geometryBase: true,
      segregationsMman: true,
      segregationsSep100: true,
      segregationsSep1: true,
      segregationsGost: true,
      segregationsAstm: true,
      flawsSms: true,
      flawsGost: true,
      flawsAstm: true,
      cracks: true,
      backwallDistance: true,
      zones: true,
      userSnapshots: true,
    },
  },
  selectedReportIds: [],
  graphs: {
    selectedColumns: [],
  },
};

export type TDbResultTable = typeof RESULT_TABLE_INIT_STATE;
