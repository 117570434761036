// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { IDs, ORANGE_PRIMARY, TRANSPARENT } from '../../consts';
import { ILineCoordinates, IRectCoordinates, TTupleOfTwoNumbers } from '../../types';
import {
  getBaseSvgElemStyles,
  getToFixedValue,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../utils';

interface IProps {
  id?: string;
  graphParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  coordinates: IRectCoordinates;
  stroke?: string;
  billetCutDx?: number;
  billetCutDy?: number;
  zoneNumber?: number;
  allowModification?: boolean;
  fill?: boolean;
  children?: React.ReactNode;
  setParams?: (id: string, params: Partial<IRectCoordinates>) => void;
}

export const RectangularZone: FC<IProps> = ({
  id = '',
  allowModification = false,
  setParams,
  graphParams,
  domains,
  coordinates,
  billetCutDx = 0,
  billetCutDy = 0,
  stroke = ORANGE_PRIMARY,
  zoneNumber,
  children,
}) => {
  const getXOffset = getXOffsetFunc(graphParams, domains);
  const getYOffset = getYOffsetFunc(graphParams, domains);

  const x = getXOffset(coordinates.x0 + billetCutDx);
  const y = getYOffset(coordinates.y1 + billetCutDy);

  const width = ((coordinates.x1 - coordinates.x0) * graphParams.realSampleWidth) / domains[0];
  const height = ((coordinates.y1 - coordinates.y0) * graphParams.realSampleHeight) / domains[1];

  useEffect(() => {
    const rect = d3.select(`#${id} rect`);
    const text = d3.select(`#${id} text`);
    const firstAuxiliaryLine = d3.select(`#${id} .${IDs.firstAuxiliaryLine}`);
    const secondAuxiliaryLine = d3.select(`#${id} .${IDs.secondAuxiliaryLine}`);
    const thirdAuxiliaryLine = d3.select(`#${id} .${IDs.thirdAuxiliaryLine}`);
    const fourthAuxiliaryLine = d3.select(`#${id} .${IDs.fourthAuxiliaryLine}`);
    const lines = [firstAuxiliaryLine, secondAuxiliaryLine, thirdAuxiliaryLine, fourthAuxiliaryLine];

    if (!allowModification) {
      lines.forEach(line => {
        line.attr('cursor', 'auto').call(d3.drag());
      });

      return;
    }

    firstAuxiliaryLine
      .datum({ x1: x, y1: y, x2: x, y2: y + height })
      .attr('cursor', 'grab');

    secondAuxiliaryLine
      .datum({ x1: x + width, y1: y, x2: x + width, y2: y + height })
      .attr('cursor', 'grab');

    thirdAuxiliaryLine
      .datum({ x1: x, y1: y, x2: x + width, y2: y })
      .attr('cursor', 'grab');

    fourthAuxiliaryLine
      .datum({ x1: x, y1: y + height, x2: x + width, y2: y + height })
      .attr('cursor', 'grab');

    text
      .datum({ x: x + width + 5, y: y + height });

    function dragstarted(this: SVGLineElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGLineElement, event: MouseEvent, d: ILineCoordinates) {
      const firstAuxiliaryLineDx = firstAuxiliaryLine.node().x1.baseVal.value;
      const secondAuxiliaryLineDx = secondAuxiliaryLine.node().x1.baseVal.value;
      const thirdAuxiliaryLineDy = thirdAuxiliaryLine.node().y1.baseVal.value;
      const fourthAuxiliaryLineDy = fourthAuxiliaryLine.node().y1.baseVal.value;

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryLine) {
        d3.select(this)
          .attr('x1', (d.x1 = event.x))
          .attr('x2', (d.x2 = event.x));

        rect
          .attr('x', event.x)
          .attr('width', secondAuxiliaryLineDx - event.x);
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryLine) {
        d3.select(this)
          .attr('x1', (d.x1 = event.x))
          .attr('x2', (d.x2 = event.x));

        const width = event.x - firstAuxiliaryLineDx;

        rect
          .attr('width', width);

        text
          .attr('x', event.x + 5);
      }

      if (d3.select(this).node().classList.value === IDs.thirdAuxiliaryLine) {
        d3.select(this)
          .attr('y1', (d.y1 = event.y))
          .attr('y2', (d.y2 = event.y));

        rect
          .attr('y', event.y)
          .attr('height', fourthAuxiliaryLineDy - event.y);
      }

      if (d3.select(this).node().classList.value === IDs.fourthAuxiliaryLine) {
        d3.select(this)
          .attr('y1', (d.y1 = event.y))
          .attr('y2', (d.y2 = event.y));

        rect
          .attr('height', event.y - thirdAuxiliaryLineDy);
      }
    }

    function dragended(this: SVGLineElement, event: MouseEvent, d: ILineCoordinates) {
      if (!setParams) return;

      d3.select(this).attr('cursor', 'grab');

      const dx = (d.x1 - graphParams.x) * domains[0] / graphParams.realSampleWidth;
      const dy = domains[1] - (d.y1 - graphParams.y) * domains[1] / graphParams.realSampleHeight;

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryLine) {
        setParams(id, {
          x0: getToFixedValue(dx),
        });
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryLine) {
        setParams(id, {
          x1: getToFixedValue(dx),
        });
      }

      if (d3.select(this).node().classList.value === IDs.thirdAuxiliaryLine) {
        setParams(id, {
          y1: getToFixedValue(dy),
        });
      }

      if (d3.select(this).node().classList.value === IDs.fourthAuxiliaryLine) {
        setParams(id, {
          y0: getToFixedValue(dy),
        });
      }
    }

    lines.forEach(line => {
      line.call(
        d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );
    });
  }, [id, domains, graphParams, coordinates, x, y, width, height, allowModification, setParams]);

  return (
    <g id={id}>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={getBaseSvgElemStyles(stroke, 1)}
      />
      {children}
      <line
        className={IDs.firstAuxiliaryLine}
        x1={x}
        y1={y}
        x2={x}
        y2={y + height}
        style={getBaseSvgElemStyles(TRANSPARENT, 1)}
      />
      <line
        className={IDs.secondAuxiliaryLine}
        x1={x + width}
        y1={y}
        x2={x + width}
        y2={y + height}
        style={getBaseSvgElemStyles(TRANSPARENT, 1)}
      />
      <line
        className={IDs.thirdAuxiliaryLine}
        x1={x}
        y1={y}
        x2={x + width}
        y2={y}
        style={getBaseSvgElemStyles(TRANSPARENT, 1)}
      />
      <line
        className={IDs.fourthAuxiliaryLine}
        x1={x}
        y1={y + height}
        x2={x + width}
        y2={y + height}
        style={getBaseSvgElemStyles(TRANSPARENT, 1)}
      />
      {zoneNumber && (
        <text className="rect-zones__text" x={x + width + 5} y={y + height}>{zoneNumber}</text>
      )}
    </g>
  );
};

