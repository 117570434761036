import { useEffect, useState } from 'react';

interface IWindowSize {
  width: number,
  height: number,
}

export const useWindowSize = (): IWindowSize => {
  const [size, setSize] = useState<IWindowSize>({ width: 0, height: 0 });

  useEffect(() => {
    const onResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return size;
};
