import { BLACK_PRIMARY } from '../consts';

export const getBaseSvgElemStyles = (
  stroke: string = BLACK_PRIMARY,
  strokeWidth = 1,
  fill = 'transparent'
) => ({
  fill,
  strokeWidth: `${strokeWidth}px`,
  stroke,
});
