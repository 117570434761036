import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
// @ts-ignore
import * as d3 from 'd3';
import React, { useEffect, useMemo, useRef } from 'react';

import {
  FILE_TYPES,
  GREEN_LIGHT,
  SCAN_VIEWS,
  SLICE_MARGIN,
  STROKE_GREY,
  TICK_SIZE,
  X_TICK_AMOUNT,
  Y_TICK_AMOUNT,
} from '../../../../consts';
import { useAppSelector, useWindowSize } from '../../../../hooks';
import { ISampleDataParameters, ISlice, TFileType } from '../../../../store/api/scandata/types';
import {
  selectAdaptImageParams,
  selectBorderData,
  selectMaxProjectionSliderRanges,
  selectPrevSliceImages,
  selectSingleLayerSliderRanges,
  selectToolbarOptions,
  selectVolumeId,
} from '../../../../store/selectors';
import { TPrevSliceImages } from '../../../../store/slices';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  checkFileType,
  get2dSliceSizes,
  getBaseSvgElemStyles,
  getCartesianModeParams,
  getGraphParams,
  getRectParams,
  getSingleLayerLinesCoords,
} from '../../../../utils';
import { Border } from './Border';
import { DistanceLines } from './DistanceLines';

interface IProps {
  sliceData: ISlice | null;
  scanDataParameters: ISampleDataParameters;
  scanView: string;
  fileType: TFileType;
  isMaxProjection: boolean;
}

export const SliceGraph: React.FC<IProps> = ({
  sliceData,
  scanView,
  scanDataParameters,
  fileType,
  isMaxProjection,
}) => {
  const graphId = `slice${scanView}`;
  const sliderRanges = useAppSelector(
    isMaxProjection ? selectMaxProjectionSliderRanges : selectSingleLayerSliderRanges,
  );
  const { isCartesianMode, isBorder } = useAppSelector(selectToolbarOptions);
  const volumeId = useAppSelector(selectVolumeId);
  const { [scanView as keyof TPrevSliceImages]: prevSliceImage } = useAppSelector(selectPrevSliceImages);
  const borderData = useAppSelector((state) => selectBorderData(state, volumeId));
  const { imageMmHeight, imageMmWidth } = useAppSelector(selectAdaptImageParams);
  const outerSvgRef = useRef<SVGSVGElement>(null!);
  const resize = useWindowSize();
  const { isIDVFileType } = useMemo(() => checkFileType(fileType), [fileType]);
  const domains: TTupleOfTwoNumbers = useMemo(
    () => (isIDVFileType ? get2dSliceSizes(scanDataParameters, scanView) : [imageMmWidth, imageMmHeight]),
    [imageMmHeight, imageMmWidth, scanDataParameters, scanView, fileType],
  );

  const graphParams = useMemo(() => getGraphParams(outerSvgRef.current, SLICE_MARGIN), [outerSvgRef.current, resize]);

  const cartesianModeParams = useMemo(
    () => getCartesianModeParams(domains, graphParams.innerSvgWidth, graphParams.innerSvgHeight),
    [domains, graphParams.innerSvgWidth, graphParams.innerSvgHeight],
  );

  const [x, y, w, h] = useMemo(
    () =>
      getRectParams(
        scanView,
        isCartesianMode ? cartesianModeParams.realSampleWidth : graphParams.innerSvgWidth,
        isCartesianMode ? cartesianModeParams.realSampleHeight : graphParams.innerSvgHeight,
        sliderRanges,
        domains,
      ),
    [
      scanView,
      graphParams.innerSvgWidth,
      graphParams.innerSvgHeight,
      sliderRanges,
      domains,
      isCartesianMode,
      cartesianModeParams,
    ],
  );

  const { l1, l2 } = useMemo(
    () => getSingleLayerLinesCoords(scanView, sliderRanges, domains, isCartesianMode, cartesianModeParams, graphParams),
    [cartesianModeParams, domains, graphParams, isCartesianMode, scanView, sliderRanges],
  );

  const renderBottomSensorStartLine = () => {
    if ((scanView === SCAN_VIEWS.B || scanView === SCAN_VIEWS.D) && scanDataParameters.bottomSensorStartLine) {
      const ratio = scanDataParameters.bottomSensorStartLine / scanDataParameters.sizeY;

      const y = isCartesianMode
        ? cartesianModeParams.y + cartesianModeParams.realSampleHeight * ratio
        : graphParams.innerSvgHeight * ratio;

      return <line x1={0} y1={y} x2={graphParams.innerSvgWidth} y2={y} strokeWidth={2} stroke={GREEN_LIGHT} />;
    }

    return null;
  };

  useEffect(() => {
    const innerSvg = d3.select(`#${graphId} svg`);
    const wrapper = d3.select(`#${graphId} svg g`);
    const rect = d3.select(`#${graphId} svg rect`);
    const zoomTransform = d3.zoomTransform(innerSvg.node());

    const xDomain = isCartesianMode ? cartesianModeParams.xDomain : [0, domains[0]];
    const yDomain = isCartesianMode ? cartesianModeParams.yDomain.slice() : [0, domains[1]];

    const x = d3.scaleLinear().domain(xDomain).range(graphParams.xRange);

    const y = d3
      .scaleLinear()
      .domain(scanView === SCAN_VIEWS.C ? yDomain.reverse() : yDomain)
      .range(graphParams.yRange);

    const xAxis = d3.axisBottom(x).ticks(X_TICK_AMOUNT).tickSize(TICK_SIZE);
    const yAxis = d3.axisLeft(y).ticks(Y_TICK_AMOUNT).tickSize(TICK_SIZE);

    const gX = d3.select(`#${graphId} .axis--x`).call(xAxis.scale(zoomTransform.rescaleX(x)));
    const gY = d3.select(`#${graphId} .axis--y`).call(yAxis.scale(zoomTransform.rescaleY(y)));

    const zoom = d3
      .zoom()
      .scaleExtent([1, 40])
      .translateExtent([
        [0, 0],
        [graphParams.width, graphParams.height],
      ])
      .on('zoom', (event: any) => {
        // d3 library doesn't have ZoomEvent type
        const zoomState = event.transform;

        gX.call(xAxis.scale(zoomState.rescaleX(x)));
        gY.call(yAxis.scale(zoomState.rescaleY(y)));

        rect.attr('transform', zoomState);
        wrapper.attr('transform', zoomState);
      });

    innerSvg.transition().call(zoom.transform, d3.zoomIdentity);
    innerSvg.call(zoom);
  }, [graphParams, cartesianModeParams, isCartesianMode, domains, isBorder, sliceData]);

  return (
    <div className="projection-graph">
      <div className="legend--y">
        <TranslatedText textKey={scanView === SCAN_VIEWS.C ? 'Sample height' : 'dimensions.sample-thickness'} /> [mm]
      </div>
      <svg id={graphId} ref={outerSvgRef} width={graphParams.width} height={graphParams.height}>
        <svg
          x={SLICE_MARGIN.left}
          y={SLICE_MARGIN.top}
          width={graphParams.innerSvgWidth}
          height={graphParams.innerSvgHeight}
        >
          <rect width={graphParams.innerSvgWidth} height={graphParams.innerSvgHeight} fill="transparent" />
          <g>
            <image
              x={isCartesianMode ? cartesianModeParams.x : 0}
              y={isCartesianMode ? cartesianModeParams.y : 0}
              href={`data:image/gif;base64,${prevSliceImage}`}
              width={isCartesianMode ? cartesianModeParams.realSampleWidth : '100%'}
              height={isCartesianMode ? cartesianModeParams.realSampleHeight : '100%'}
              preserveAspectRatio="none"
            />
            {sliceData?.imageBase64 && (
              <image
                x={isCartesianMode ? cartesianModeParams.x : 0}
                y={isCartesianMode ? cartesianModeParams.y : 0}
                href={`data:image/gif;base64,${sliceData.imageBase64}`}
                width={isCartesianMode ? cartesianModeParams.realSampleWidth : '100%'}
                height={isCartesianMode ? cartesianModeParams.realSampleHeight : '100%'}
                preserveAspectRatio="none"
              />
            )}
            {renderBottomSensorStartLine()}
            {isBorder && scanView === SCAN_VIEWS.C && borderData && sliceData?.imageBase64 && (
              <Border
                borderData={borderData}
                image={sliceData.imageBase64}
                domains={domains}
                graphParams={graphParams}
                cartesianModeParams={cartesianModeParams}
                isCartesianMode={isCartesianMode}
              />
            )}
            {fileType === FILE_TYPES.volume && isMaxProjection && (
              <rect
                x={isCartesianMode ? x + cartesianModeParams.x : x}
                y={isCartesianMode ? y + cartesianModeParams.y : y}
                width={w}
                height={h}
                style={getBaseSvgElemStyles(STROKE_GREY, 2)}
              />
            )}
            {fileType === FILE_TYPES.volume && !isMaxProjection && (
              <>
                <line x1={l1.x1} y1={l1.y1} x2={l1.x2} y2={l1.y2} style={getBaseSvgElemStyles(STROKE_GREY, 2)} />
                <line x1={l2.x1} y1={l2.y1} x2={l2.x2} y2={l2.y2} style={getBaseSvgElemStyles(STROKE_GREY, 2)} />
              </>
            )}
            {(scanView === SCAN_VIEWS.C || scanView === SCAN_VIEWS.B || scanView === SCAN_VIEWS.D) && (
              <DistanceLines
                scanView={scanView}
                domains={domains}
                graphParams={graphParams}
                cartesianModeParams={cartesianModeParams}
                isCartesianMode={isCartesianMode}
                graphId={graphId}
              />
            )}
          </g>
        </svg>
        <g
          className="axis axis--x"
          transform={`translate(${SLICE_MARGIN.left}, ${graphParams.height - SLICE_MARGIN.bottom})`}
        />
        <g className="axis axis--y" transform={`translate(${SLICE_MARGIN.left}, ${SLICE_MARGIN.top})`} />
      </svg>
      <div className="legend--x">
        <TranslatedText textKey={scanView === SCAN_VIEWS.D ? 'Sample height' : 'Sample width'} /> [mm]
      </div>
    </div>
  );
};
