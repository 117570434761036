import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { BACKWALL_TABS, DEFAULT_BACKWALL_RANGE } from '../../consts';
import { TBackwallTabs } from '../../types';
import { ISliderRange } from '../../types/scanData';
import {
  IDistanceStatistics,
  TDistanceModificationMode,
  TDistanceSelectionType,
  TDistanceZoneType,
} from '../api/backwall/types';

interface IInitialState {
  distanceModificationMode: TDistanceModificationMode | null;
  distanceZoneTypes: TDistanceZoneType[];
  distanceSelectionTypes: TDistanceSelectionType[];
  sliderRanges: {
    maxProjection: ISliderRange;
    singleLayer: ISliderRange;
  };
  selectedDistanceIds: number[];
  distanceStatistics: IDistanceStatistics[];
  backwallTab: TBackwallTabs;
}

const initialState: IInitialState = {
  distanceModificationMode: null,
  distanceZoneTypes: [],
  distanceSelectionTypes: [],
  selectedDistanceIds: [],
  sliderRanges: {
    maxProjection: {
      ...DEFAULT_BACKWALL_RANGE,
    },
    singleLayer: {
      ...DEFAULT_BACKWALL_RANGE,
    },
  },
  distanceStatistics: [],
  backwallTab: BACKWALL_TABS.distance,
};

export const backwallSlice = createSlice({
  name: 'backwall',
  initialState,
  reducers: {
    setBackwallMaxProjectionSliderRange: (state, action: PayloadAction<ISliderRange>) => {
      state.sliderRanges.maxProjection = action.payload;
    },
    setBackwallSingleLayerSliderRange: (state, action: PayloadAction<ISliderRange>) => {
      state.sliderRanges.singleLayer = action.payload;
    },
    setSelectedDistanceZoneTypes: (state, action: PayloadAction<TDistanceZoneType[] | undefined>) => {
      if (!action.payload) {
        state.distanceZoneTypes = [];
        return;
      }

      state.distanceZoneTypes = action.payload;
    },
    setSelectedDistanceSelectionTypes: (state, action: PayloadAction<TDistanceSelectionType[] | undefined>) => {
      if (!action.payload) {
        state.distanceSelectionTypes = [];
        return;
      }

      state.distanceSelectionTypes = action.payload;
    },
    setDistanceModificationMode: (state, action: PayloadAction<TDistanceModificationMode | null>) => {
      if (state.distanceModificationMode === action.payload) {
        state.distanceModificationMode = null;
        return;
      }

      state.distanceModificationMode = action.payload;
    },
    setBackwallSelectedDistanceIds: (state, action: PayloadAction<number | number[]>) => {
      if (Array.isArray(action.payload)) {
        state.selectedDistanceIds = action.payload;
        return;
      }
      state.selectedDistanceIds.push(action.payload);
    },
    removeBackwallSelectedDistanceIds: (state, action: PayloadAction<number | number[]>) => {
      if (Array.isArray(action.payload)) {
        return;
      }
      state.selectedDistanceIds = state.selectedDistanceIds.filter((i) => i !== action.payload);
    },
    resetBackwallSelectedDistanceIds: (state) => {
      state.selectedDistanceIds = [];
    },
    setBackwallDistanceStatistics: (state, action: PayloadAction<IDistanceStatistics[]>) => {
      state.distanceStatistics = action.payload;
    },
    setBackwallTab: (state, action: PayloadAction<TBackwallTabs>) => {
      state.backwallTab = action.payload;
    },
  },
});

export const { actions: backwallActions, reducer: backwall } = backwallSlice;
