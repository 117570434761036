import { TCoordinates } from '../types';

export interface IMaxBoundaryValues {
  left: number,
  top: number,
  right: number,
  bottom: number,
}

export const getMaxBoundaryValues = (points: TCoordinates[]): IMaxBoundaryValues => {
  const [firstPoint] = points;
  let left = firstPoint?.x,
    top = firstPoint?.y,
    right = firstPoint?.x,
    bottom = firstPoint?.y;

  for (let i = 1; i < points.length; i++) {
    if (points[i].x < left) {
      left = points[i].x;
    }

    if (points[i].x > right) {
      right = points[i].x;
    }

    if (points[i].y > top) {
      top = points[i].y;
    }

    if (points[i].y < bottom) {
      bottom = points[i].y;
    }
  }

  return {
    left,
    top,
    right,
    bottom,
  };
};
