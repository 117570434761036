import { TranslatedText } from '@sms/plasma-ui';
//@ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import {
  A_SCAN_VIEW_MARGIN,
  BLUE_PRIMARY,
  IDs,
  MAX_DEFECTIVE_VALUE,
  SCAN_VIEWS,
  TICK_SIZE,
  X_TICK_AMOUNT,
  Y_TICK_AMOUNT,
} from '../../../consts';
import { useWindowSize } from '../../../hooks';
import { ISampleDataParameters } from '../../../store/api/scandata/types';
import { TAScanValues } from '../../../store/api/slice/types';
import { getBaseSvgElemStyles, getGraphParams, getSliceSize } from '../../../utils';

interface IProps {
  intensityValues: TAScanValues | undefined;
  prevIntensityValues: TAScanValues | undefined;
  scanDataParameters: ISampleDataParameters;
}

export const AScanViewGraph: FC<IProps> = ({ intensityValues, scanDataParameters, prevIntensityValues }) => {
  const outerSvgRef = useRef<SVGSVGElement>(null!);
  const resize = useWindowSize();
  const size = useMemo(() => getSliceSize(scanDataParameters, SCAN_VIEWS.C), [scanDataParameters]);
  const domains = useMemo(() => [size, MAX_DEFECTIVE_VALUE], [size]);
  const graphParams = useMemo(() => getGraphParams(outerSvgRef.current, A_SCAN_VIEW_MARGIN), [
    outerSvgRef.current,
    resize,
  ]);

  const getPolylinePoints = (intensityValues: TAScanValues | undefined) => {
    if (!intensityValues) return;

    const tick = graphParams.innerSvgWidth / intensityValues.length;

    return intensityValues.reduce((acc, value, idx) => {
      // converting intensity to px
      return (
        acc +
        ` ${idx * tick}, ${graphParams.innerSvgHeight - (value * graphParams.innerSvgHeight) / MAX_DEFECTIVE_VALUE}`
      );
    }, '');
  };

  useEffect(() => {
    const innerSvg = d3.select(`#${IDs.aScanView} svg`);
    const wrapper = d3.select(`#${IDs.aScanView} svg g`);
    const rect = d3.select(`#${IDs.aScanView} svg rect`);
    const zoomTransform = d3.zoomTransform(innerSvg.node());

    const xDomain = [0, domains[0]];
    const yDomain = [domains[1], 0];

    const x = d3.scaleLinear().domain(xDomain).range(graphParams.xRange);

    const y = d3.scaleLinear().domain(yDomain).range(graphParams.yRange);

    const xAxis = d3.axisBottom(x).ticks(X_TICK_AMOUNT).tickSize(TICK_SIZE);
    const yAxis = d3.axisLeft(y).ticks(Y_TICK_AMOUNT).tickSize(TICK_SIZE);

    const gX = d3.select(`#${IDs.aScanView} .axis--x`).call(xAxis.scale(zoomTransform.rescaleX(x)));
    const gY = d3.select(`#${IDs.aScanView} .axis--y`).call(yAxis.scale(zoomTransform.rescaleY(y)));

    const zoom = d3
      .zoom()
      .scaleExtent([1, 40])
      .translateExtent([
        [0, 0],
        [graphParams.width, graphParams.height],
      ])
      .on('zoom', (event: any) => {
        // d3 library doesn't have ZoomEvent type
        const zoomState = event.transform;

        gX.call(xAxis.scale(zoomState.rescaleX(x)));
        gY.call(yAxis.scale(zoomState.rescaleY(y)));

        rect.attr('transform', zoomState);
        wrapper.attr('transform', zoomState);
      });

    innerSvg.transition().call(zoom.transform, d3.zoomIdentity);
    innerSvg.call(zoom);
  }, [graphParams, domains]);

  return (
    <div className="projection-graph">
      <div className="legend--y">
        <TranslatedText textKey="dimensions.amplitude" />
      </div>
      <svg id={IDs.aScanView} ref={outerSvgRef} width={graphParams.width} height={graphParams.height}>
        <svg
          x={A_SCAN_VIEW_MARGIN.left}
          y={A_SCAN_VIEW_MARGIN.top}
          width={graphParams.innerSvgWidth}
          height={graphParams.innerSvgHeight}
        >
          <rect width={graphParams.innerSvgWidth} height={graphParams.innerSvgHeight} fill="transparent" />
          <g>
            {prevIntensityValues && (
              <polyline points={getPolylinePoints(prevIntensityValues)} style={getBaseSvgElemStyles(BLUE_PRIMARY, 2)} />
            )}
            {intensityValues && (
              <polyline points={getPolylinePoints(intensityValues)} style={getBaseSvgElemStyles(BLUE_PRIMARY, 2)} />
            )}
          </g>
        </svg>
        <g
          className="axis axis--x"
          transform={`translate(${A_SCAN_VIEW_MARGIN.left}, ${graphParams.height - A_SCAN_VIEW_MARGIN.bottom})`}
        />
        <g className="axis axis--y" transform={`translate(${A_SCAN_VIEW_MARGIN.left}, ${A_SCAN_VIEW_MARGIN.top})`} />
      </svg>
      <div className="legend--x">
        <TranslatedText textKey="dimensions.sample-thickness" /> [mm]
      </div>
    </div>
  );
};
