import { FILE_TYPES, SCAN_VIEWS } from '../consts';
import { TFileType } from '../store/api/scandata/types';
import { TTupleOfTwoNumbers } from '../types';
import { ILoadedSampleData } from '../types/loadedSampleData';

export const getSliceSize = (scanDataParameters: ILoadedSampleData, scanView: string): number => {
  if (scanDataParameters) {
    const { sizeX, sizeY, sizeZ } = scanDataParameters;

    switch (scanView) {
      case SCAN_VIEWS.B:
        return sizeZ;
      case SCAN_VIEWS.C:
        return sizeY;
      case SCAN_VIEWS.D:
        return sizeX;
    }
  }

  return 0;
};

export const get2dSliceSizes = (
  scanDataParameters: ILoadedSampleData,
  scanView: string,
  fileType: TFileType = FILE_TYPES.volume,
): TTupleOfTwoNumbers => {
  if (scanDataParameters) {
    const { sizeX, sizeY, sizeZ } = scanDataParameters;

    if (fileType === FILE_TYPES.image) {
      return [sizeX, sizeZ];
    }

    switch (scanView) {
      case SCAN_VIEWS.B:
        return [sizeX, sizeY];
      case SCAN_VIEWS.C:
        return [sizeX, sizeZ];
      case SCAN_VIEWS.D:
        return [sizeZ, sizeY];
    }
  }

  return [0, 0];
};
