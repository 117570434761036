import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  B: '',
  C: '',
  D: '',
  backwall: '',
};

export type TPrevSliceImages = typeof initialState;

export const prevSliceImagesSlice = createSlice({
  name: 'prevSliceImages',
  initialState,
  reducers: {
    setPrevSliceImage: (state, action: PayloadAction<Partial<TPrevSliceImages>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: prevSliceImagesActions, reducer: prevSliceImages } = prevSliceImagesSlice;
