import './styles.scss';

import { Button, useTranslation } from '@sms/plasma-ui';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import {
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  FILE_TYPES,
  GREY_SECONDARY_EXTRA_LIGHT,
  SEG_ANALYSIS,
  SLICE_TYPES,
  WHITE_PRIMARY,
} from '../../../consts';
import { useActionCreators, useAppSelector, useSegregation } from '../../../hooks';
import { useLazyGetSegHeightAnalysisDataQuery } from '../../../store/api/segregation';
import {
  selectFileType,
  selectIsPoresIncluded,
  selectIsSegHeightAnalysis,
  selectSegAllowAreaModification,
  selectSegAnalysis,
  selectSliceType,
  selectVolumeId,
} from '../../../store/selectors';
import { RadioGroupData, TSegAnalysis } from '../../../types';
import { CustomRadioGroup } from '../../UI/CustomRadioGroup';

export const SegAnalysis = () => {
  const {
    setSegHeightAnalysis,
    setSegAreaModification,
    setSegAnalysis,
    setIsPoreIncluded,
    forceAnalysisChange,
  } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const segAnalysis = useAppSelector(selectSegAnalysis);
  const sliceType = useAppSelector(selectSliceType);
  const fileType = useAppSelector(selectFileType);
  const isPoresIncluded = useAppSelector(selectIsPoresIncluded);
  const allowAreaModification = useAppSelector(selectSegAllowAreaModification);
  const isHeightAnalysis = useAppSelector(selectIsSegHeightAnalysis);
  const [heightAnalysisTrigger] = useLazyGetSegHeightAnalysisDataQuery();
  const {
    handleSegParamsChange,
    handlePoresChange,
    isMMAnalysis,
    isSEPAnalysis,
    handleTakeSegReportScreenshots,
  } = useSegregation();
  const { t } = useTranslation();

  // const isASTMAnalysisDisabled =
  //   sliceType === SLICE_TYPES.slabLeft || sliceType === SLICE_TYPES.slabRight || sliceType === SLICE_TYPES.slabMiddle;
  const isSEPAnalysisDisabled =
    sliceType === SLICE_TYPES.cut || sliceType === SLICE_TYPES.rect || sliceType === SLICE_TYPES.round;

  const heightAnalysisHandler = useCallback(() => {
    setSegHeightAnalysis();
  }, []);

  const areaModificationHandler = useCallback(() => {
    setSegAreaModification();
  }, []);

  const segregationAnalysisData: RadioGroupData[] = useMemo(
    () => [
      {
        value: SEG_ANALYSIS.mm,
        label: SEG_ANALYSIS.mm,
      },
      {
        value: SEG_ANALYSIS.sep,
        label: SEG_ANALYSIS.sep,
        disabled: isSEPAnalysisDisabled,
      },
      {
        value: SEG_ANALYSIS.gost,
        label: SEG_ANALYSIS.gost,
      },
      {
        value: SEG_ANALYSIS.astm,
        label: SEG_ANALYSIS.astm,
        disabled: false,
      },
    ],
    [sliceType],
  );

  const radioGroupChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const newAnalysis = e.target.value as TSegAnalysis;

    forceAnalysisChange(true);
    await handleTakeSegReportScreenshots();
    setSegAnalysis(newAnalysis);
    await handleSegParamsChange({ newAnalysis });
    forceAnalysisChange(false);
  };

  const handlePoresIncludedChange = () => {
    setIsPoreIncluded();
    handlePoresChange();
  };

  useEffect(() => {
    if (fileType === FILE_TYPES.image) return;

    heightAnalysisTrigger(volumeId);
  }, [volumeId, fileType]);

  return (
    <div className="segregation__menu__analysis">
      <h3 className="segregation__menu__title side-menu__title">{t('Analysis')}</h3>
      <div className="segregation__menu__analysis__controls">
        <CustomRadioGroup
          data={segregationAnalysisData}
          radioGroupValue={segAnalysis}
          onChange={radioGroupChangeHandler}
        />
        <Button
          title={'Height analysis'}
          onClick={heightAnalysisHandler}
          disabled={fileType === FILE_TYPES.image}
          style={{
            backgroundColor: isHeightAnalysis ? BLUE_PRIMARY : 'transparent',
            borderColor: isHeightAnalysis ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
            color: isHeightAnalysis ? WHITE_PRIMARY : BLACK_PRIMARY,
          }}
        />
        <Button
          title={'side-menu.allow-area-modification'}
          onClick={areaModificationHandler}
          style={{
            backgroundColor: allowAreaModification ? BLUE_PRIMARY : 'transparent',
            borderColor: allowAreaModification ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
            color: allowAreaModification ? WHITE_PRIMARY : BLACK_PRIMARY,
          }}
        />
        {(isMMAnalysis || isSEPAnalysis) && (
          <Button
            title={isPoresIncluded ? 'side-menu.exclude-pores' : 'side-menu.include-pores'}
            onClick={handlePoresIncludedChange}
            style={{
              backgroundColor: isPoresIncluded ? BLUE_PRIMARY : 'transparent',
              borderColor: isPoresIncluded ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
              color: isPoresIncluded ? WHITE_PRIMARY : BLACK_PRIMARY,
            }}
          />
        )}
      </div>
    </div>
  );
};
