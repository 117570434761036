import './styles.scss';

import { Modal } from '@sms/plasma-ui';
import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React, { FC, useState } from 'react';

import { MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { useActionCreators } from '../../../hooks';
import { useExportFilterResultsMutation, useGetDbSnapshotsMutation } from '../../../store/api/database';
import { createModalMessageBody } from '../../../utils';

interface Props {
  isVisible: boolean;
}

const ExportResultsModal: FC<Props> = ({ isVisible }) => {
  const { setDbExportResultsVisible, setMessageModal } = useActionCreators();
  const [selectedSnapshots, setSelectedSnapshots] = useState<string[]>([]);
  const [exportTrigger] = useExportFilterResultsMutation();
  const [, { data: dbSnapshots }] = useGetDbSnapshotsMutation({ fixedCacheKey: 'dbSnapshots' });

  if (!isVisible || !dbSnapshots) return null;

  const onCancel = () => {
    setDbExportResultsVisible(false);
  };

  const onOk = async () => {
    await exportTrigger(selectedSnapshots).then(() => {
      setDbExportResultsVisible(false);
      setMessageModal(
        createModalMessageBody(
          MODAL_STATUS.SUCCESS,
          MODAL_TITLE.notification,
          'The database results have been successfully exported.',
        ),
      );
    });
  };

  const selectSnapshotHandler = (snapshotName: string) => {
    setSelectedSnapshots((prev) => {
      if (prev.includes(snapshotName)) {
        return prev.filter((item) => item !== snapshotName);
      }

      return [...prev, snapshotName];
    });
  };

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      okText="Export"
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      title={'Snapshots'}
      visible={isVisible}
      width={'35%'}
      zIndex={15}
      className={'export-results__modal'}
    >
      <div className="export-results__wrapper">
        {dbSnapshots.map((item) => {
          return item?.snapshots.map(({ snapshotName }) => (
            <Checkbox
              key={snapshotName}
              name={snapshotName}
              title={snapshotName}
              checked={selectedSnapshots.includes(snapshotName)}
              onChange={(e) => {
                console.log('e', e);
                selectSnapshotHandler(snapshotName);
              }}
            />
          ));
        })}
      </div>
    </Modal>
  );
};

export default ExportResultsModal;
