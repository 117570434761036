import { TranslatedText } from '@sms/plasma-ui';
import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { BACKWALL_FLAWS_STATISTICS_SEGREGATION_SIZES, REMARKS_NAMES } from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { IFlawsSMSSlabStatistics } from '../../../../store/api/flaws/types';
import {
  selectBackwallFlawsSelectedPolygon,
  selectBackwallFlawsStatistics,
  selectRemarks,
} from '../../../../store/selectors';
import { Statistics } from '../../../FlawsSideMenu/FlawStatistics/Statistics';
import { Remarks } from '../../../Remarks';
import { SelectedArea } from '../../../SelectedArea';

const regionId = 0;

export const BackwallFlawsRestMenu = () => {
  const { setBackwallFlawsRemarks } = useActionCreators();
  const { backwallFlaws } = useAppSelector(selectRemarks);
  const statistics = useAppSelector(selectBackwallFlawsStatistics);
  const selectedPolygon = useAppSelector(selectBackwallFlawsSelectedPolygon);

  const backwallStatistics = useMemo(() => {
    return {
      statistics: [{ ...statistics, regionId }],
      segregationsSizes: BACKWALL_FLAWS_STATISTICS_SEGREGATION_SIZES,
    } as IFlawsSMSSlabStatistics;
  }, [statistics]);

  const handleRemarksChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setBackwallFlawsRemarks(e.target.value);
  }, []);

  return (
    <>
      {statistics && (
        <>
          <h3 className="side-menu__title">
            <TranslatedText textKey="side-menu.statistics" />
          </h3>
          <Statistics statistics={backwallStatistics} selectedRegionId={regionId} />
        </>
      )}
      <h3 className="side-menu__title">
        <TranslatedText textKey="side-menu.selected-area" />
      </h3>
      <SelectedArea selectedPolygon={selectedPolygon} />
      <h3 className="side-menu__title">
        <TranslatedText textKey="Remarks" />
      </h3>
      <Remarks value={backwallFlaws} name={REMARKS_NAMES.backwallFlaws} label={''} onChange={handleRemarksChange} />
    </>
  );
};
