import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { ICracksBilletRectZones } from '../../../../../../store/api/cracks/types';
import { TTupleOfTwoNumbers } from '../../../../../../types';
import { ICartesianModeParams } from '../../../../../../utils';
import { Circle } from '../Circle';
import { Line } from '../Line';
import { Rect } from '../Rect';

interface IProps {
  data: ICracksBilletRectZones;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
}

export const BilletRectZones: FC<IProps> = ({ data, domains, cartesianModeParams }) => {
  const { centerPoint, cornerZones, innerZone, middleZone, outerZone, trippleLeftLine, trippleRightLine } = data;

  return (
    <>
      {cornerZones.map((z, idx) => {
        return <Rect key={idx} data={z} domains={domains} cartesianModeParams={cartesianModeParams} />;
      })}
      <Rect data={innerZone} domains={domains} cartesianModeParams={cartesianModeParams} />
      <Rect data={middleZone} domains={domains} cartesianModeParams={cartesianModeParams} />
      <Rect data={outerZone} domains={domains} cartesianModeParams={cartesianModeParams} />
      <Line data={trippleLeftLine} domains={domains} cartesianModeParams={cartesianModeParams} />
      <Line data={trippleRightLine} domains={domains} cartesianModeParams={cartesianModeParams} />
      <Circle
        data={centerPoint}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={1}
        fill={ORANGE_PRIMARY}
      />
    </>
  );
};
