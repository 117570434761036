import './styles.scss';

import { TextArea, TranslatedText } from '@sms/plasma-ui';
import React, { ChangeEvent, FC } from 'react';

interface IProps {
  value: string;
  label: string;
  placeholder?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const Remarks: FC<IProps> = ({ value, onChange, name, label, placeholder = 'side-menu.text-area-text' }) => {
  return (
    <div className="remarks">
      <TextArea
        bordered
        hintMode="hidden"
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        // placeholder={placeholder}
      />
    </div>
  );
};
