import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { getToFixedValue } from '../../../../../../utils';

interface Props {
  width: number;
  height: number;
  id: number;
  color: string;
  x: number;
  y: number;
}

export const BackwallFlawsMainInfo = ({ width, height, id, color, x, y }: Props) => {
  return (
    <>
      <text
        x={x}
        y={y}
        style={{
          fontSize: '12px',
          fill: color,
        }}
      >
        <TranslatedText textKey="dimensions.width" /> : {getToFixedValue(width)} mm
      </text>
      <text
        x={x}
        y={y + 15}
        style={{
          fontSize: '12px',
          fill: color,
        }}
      >
        <TranslatedText textKey="dimensions.height" />: {getToFixedValue(height)} mm
      </text>
      <text
        x={x}
        y={y + 30}
        style={{
          fontSize: '12px',
          fill: color,
        }}
      >
        Id: {id}
      </text>
    </>
  );
};
