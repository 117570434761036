import { createSelector } from '@reduxjs/toolkit';

import { scanDataApi } from '../api/scandata';
import { ISliderRanges } from '../slices/types';
import { TypedRootState } from '../types';
import { selectAutomaticAnalysisResult } from './automaticAnalysis.selector';
import { selectDistanceMaxProjectionSliderRange, selectDistanceSingleLayerSliderRange } from './backwall.selector';
import { selectIsMaxProjection } from './toolbarOptions.selector';

export const selectScanDataParameters = createSelector(
  [
    (state: TypedRootState, volumeId: string) =>
      scanDataApi.endpoints.getScanDataParameters.select(volumeId)(state)?.data,
    selectAutomaticAnalysisResult,
  ],
  (scanData, analysisResult) => scanData || analysisResult?.sampleParameters,
);
export const selectScanDataFilters = (state: TypedRootState) => state.scanDataFilters;

export const selectSliderRange = createSelector(
  [
    (state: TypedRootState, scanView: string, isBackwall: boolean) =>
      state.sliderRanges.maxProjection[`slice${scanView}` as keyof ISliderRanges],
    (state: TypedRootState, scanView: string) =>
      state.sliderRanges.singleLayer[`slice${scanView}` as keyof ISliderRanges],
    selectDistanceMaxProjectionSliderRange,
    selectDistanceSingleLayerSliderRange,
    selectIsMaxProjection,
    (state: TypedRootState, scanView: string, isBackwall: boolean) => isBackwall,
  ],
  (
    maxProjectionRange,
    singleLayerRange,
    backwallMaxProjectionDistanceRange,
    backwallSingleLayerDistanceRange,
    isMaxProjection,
    isBackwall,
  ) => {
    if (isMaxProjection) {
      if (isBackwall) return backwallMaxProjectionDistanceRange;
      return maxProjectionRange;
    }

    if (isBackwall) return backwallSingleLayerDistanceRange;

    return singleLayerRange;
  },
);
