import React, { FC } from 'react';

interface IProps {
  classNames: string;
}

export const DoubleArrow: FC<IProps> = ({ classNames }) => {
  return (
    <svg className={classNames} width="57px" height="20px" viewBox="0 0 57 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon stroke="#000000" points="0.0689444697 9.92518087 13.316839 0.932925788 13.316839 6.40974925 42.7604336 6.40974925 42.7604336 0.932925788 56.0689445 9.92518087 42.7604336 18.9329258 42.7604336 13.4001917 13.316839 13.4001917 13.316839 18.9329258"></polygon>
      </g>
    </svg>
  );
};

