import { PercentCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

export const getCroppedImg = (
  image: HTMLImageElement | null,
  crop: PercentCrop,
  scale = 1,
  rotate = 0
) => {
  if (!image) return null;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = 1;

  canvas.width = Math.floor(
    (image.width * crop.width * scaleX * pixelRatio) / 100
  );
  canvas.height = Math.floor(
    (image.height * crop.height * scaleY * pixelRatio) / 100
  );

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = (image.width * crop.x * scaleX) / 100;
  const cropY = (image.height * crop.y * scaleY) / 100;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  const base64Image = canvas.toDataURL('image/png');
  return base64Image;
};
