import { createSelector } from '@reduxjs/toolkit';

import { TFlawsAnalysis } from '../../types';
import { flawsApi } from '../api/flaws';
import { TFlawsRectZones, TFlawsRoundZones, TFlawsStatistics } from '../api/flaws/types';
import { IZonesNewZoneParams } from '../api/zones/types';
import { IPolygonCoordinates } from '../slices/types';
import { TypedRootState } from '../types';

export const selectFlawsThreshold = (state: TypedRootState): number => state.flaws.threshold;
export const selectIsFlawsZonesReset = (state: TypedRootState): boolean => state.flaws.isZonesReset;
export const selectIsFlawsFilterActive = (state: TypedRootState): boolean => state.flaws.isFilterActive;
export const selectIsFlawsHeightAnalysis = (state: TypedRootState): boolean => state.flaws.isHeightAnalysis;
export const selectIsFlawsZoneDeletion = (state: TypedRootState): boolean => state.flaws.isZoneDeletion;
export const selectFlawsForbiddenZones = (state: TypedRootState): IZonesNewZoneParams[] => state.flaws.forbiddenZones;
export const selectFlawsAllowAreaModification = (state: TypedRootState): boolean => state.flaws.allowAreaModification;
export const selectFlawsRectZones = (state: TypedRootState): TFlawsRectZones | null => state.flaws.zonesRectangular;
export const selectFlawsRoundZones = (state: TypedRootState): TFlawsRoundZones | null => state.flaws.zonesRound;
export const selectFlawsAnalysis = (state: TypedRootState): TFlawsAnalysis => state.flaws.analysis;
export const selectFlawsPolygonsCoords = (state: TypedRootState): IPolygonCoordinates[] =>
  state.flaws.polygonsCoordinates;
export const selectFlawsSelectedPolygon = (state: TypedRootState): IPolygonCoordinates | null =>
  state.flaws.selectedPolygon;
export const selectFlawsSelectedRegionId = (state: TypedRootState): number => state.flaws.selectedRegionId;
export const selectFlawsStatistics = (state: TypedRootState): TFlawsStatistics | null => state.flaws.statistics;
export const selectFlawsIsZoneInFocus = (state: TypedRootState): boolean => state.flaws.isZoneInFocus;

const selectFlawsHeightAnalysis = (state: TypedRootState, volumeId: string) =>
  flawsApi.endpoints.getFlawsHeightAnalysisData.select(volumeId)(state)?.data;
export const selectFlawsHeightAnalysisData = createSelector(
  selectFlawsHeightAnalysis,
  (flawsHeightAnalysisData) =>
    flawsHeightAnalysisData ?? {
      heightAnalysisVertical: [],
      heightAnalysisHorizontal: [],
      firstHorizontalIndex: 0,
      lastHorizontalIndex: 0,
      factor: 1,
    }
);
