import { Arrow } from './Arrow';
import { Cartesian } from './Cartesian';
import { Check } from './Check';
import { Cross } from './Cross';
import { Cube } from './Cube';
import { DoubleArrow } from './DoubleArrow';
import { EntryDirection } from './EntryDirection';
import { Folder } from './Folder';
import { MathSum } from './MathSum';
import { Ruler } from './Ruler';
import { Screenshot } from './Screenshot';
import { Square } from './Square';
import { Warning } from './Warning';

export const Icons = {
  DoubleArrow,
  EntryDirection,
  Folder,
  Screenshot,
  Cartesian,
  MathSum,
  Square,
  Cube,
  Ruler,
  Warning,
  Cross,
  Check,
  Arrow,
};
