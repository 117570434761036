import React from 'react';

interface Props {
  color: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

export const Arrow = ({ color, startX, startY, endX, endY }: Props) => {
  return (
    <svg>
      <defs>
        <marker id="head" orient="auto" markerWidth="3" markerHeight="4" refX="0.1" refY="2">
          <path d="M0,0 V4 L2,2 Z" fill={color} />
        </marker>
      </defs>
      <path
        id="arrow-line"
        markerEnd="url(#head)"
        strokeWidth="2"
        fill="none"
        stroke={color}
        d={`M${startX},${startY}, ${endX} ${endY}`}
      />
    </svg>
  );
};
