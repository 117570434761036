import { isHostEnvDevelopment, isHostEnvProduction, isHostEnvStaging } from './shared';

const getUserPoolId = () => {
  if (isHostEnvProduction) return 'eu-central-1_asZABlCGz';
  if (isHostEnvStaging || isHostEnvDevelopment) return 'eu-central-1_etiuDpo2A';
  return '';
};

const getUserPoolWebClientId = () => {
  if (isHostEnvProduction) return '2l0tucce18ifpd4ue6tqqcicbj';
  if (isHostEnvStaging || isHostEnvDevelopment) return '3o4341sq4pldhfql7llu87ijkl';
  return '';
};

export const AWS_EXPORTS = {
  identityPoolId: 'eu-central-1:1c30b2aa-a6cb-4560-b94f-ebdeace81b89',
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_asZABlCGz',
  userPoolAppClientId: '2l0tucce18ifpd4ue6tqqcicbj',
  appId: '585104f7d5114e47a01984af41076dc2',
  projectName: 'HDscanWebAnalytics',
};

const { region, userPoolId, userPoolAppClientId, appId, identityPoolId } = AWS_EXPORTS;

export const AWS_CONFIG = {
  Auth: {
    region,
    userPoolId,
    identityPoolId,
    userPoolWebClientId: userPoolAppClientId,
  },
  Analytics: {
    AWSPinpoint: {
      appId,
      region,
    },
  },
};
