import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
//@ts-ignore
import * as d3 from 'd3';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { ADAPT_IMAGE_MARGIN, IDs, TICK_SIZE, X_TICK_AMOUNT, Y_TICK_AMOUNT } from '../../consts';
import { useActionCreators, useAppSelector, useWindowSize } from '../../hooks';
import { IAdaptImageScaleCoords, ISliceImageCroppingCoords } from '../../store/api/adapt-image/types';
import {
  selectAdaptImage,
  selectAdaptImageCropCoords,
  selectAdaptImageParams,
  selectAdaptImageScaleCoords,
  selectIsAdaptImageCropping,
  selectIsAdaptImageScaleInserted,
  selectIsAdaptImageScaleLength,
} from '../../store/selectors';
import { TTupleOfTwoNumbers } from '../../types';
import { getGraphParams, getRescaledMmDomains } from '../../utils';
import { CroppingArea } from './CroppingArea';
import { Scale } from './Scale';

interface IProps {
  id: string;
}

export const AdaptImageGraph: FC<IProps> = ({ id }) => {
  const { setAdaptImageScaleCoords, setAdaptImageCropCoords } = useActionCreators();
  const adaptImage = useAppSelector(selectAdaptImage);
  const isScaleInserted = useAppSelector(selectIsAdaptImageScaleInserted);
  const isCropping = useAppSelector(selectIsAdaptImageCropping);
  const scaleLengthInMm = useAppSelector(selectIsAdaptImageScaleLength);
  const scaleCoords = useAppSelector(selectAdaptImageScaleCoords);
  const cropCoords = useAppSelector(selectAdaptImageCropCoords);
  const { imageMmHeight, imageMmWidth, imagePxHeight, imagePxWidth } = useAppSelector(selectAdaptImageParams);
  const resize = useWindowSize();
  const outerSvgRef = useRef<SVGSVGElement>(null!);
  const domains = useMemo(
    () => ({
      mmDomains: [imageMmWidth, imageMmHeight] as TTupleOfTwoNumbers,
      pxDomains: [imagePxWidth, imagePxHeight] as TTupleOfTwoNumbers,
    }),
    [imageMmHeight, imageMmWidth, imagePxHeight, imagePxWidth]
  );
  const graphParams = useMemo(() => getGraphParams(outerSvgRef.current, ADAPT_IMAGE_MARGIN), [resize]);
  const rescaledMmDomains = getRescaledMmDomains(scaleCoords, scaleLengthInMm, domains.pxDomains);

  const setAdaptImageScaleCoordsHandler = useCallback((params: Partial<IAdaptImageScaleCoords>) => {
    setAdaptImageScaleCoords(params);
  }, []);

  const setAdaptImageCropCoordsHandler = useCallback((params: Partial<ISliceImageCroppingCoords>) => {
    setAdaptImageCropCoords(params);
  }, []);

  useEffect(() => {
    const innerSvg = d3.select(`#${id} svg`);
    const wrapper = d3.select(`#${id} svg #${IDs.groupWrapper}`);
    const zoomTransform = d3.zoomTransform(innerSvg.node());

    const xBottomDomain = [0, imagePxWidth];
    const xTopDomain = [0, isScaleInserted ? rescaledMmDomains[0] : imageMmWidth];
    const yLeftDomain = [0, imagePxHeight];
    const yRightDomain = [0, isScaleInserted ? rescaledMmDomains[1] : imageMmHeight];

    const bottomX = d3.scaleLinear().domain(xBottomDomain).range(graphParams.xRange);

    const topX = d3.scaleLinear().domain(xTopDomain).range(graphParams.xRange);

    const leftY = d3.scaleLinear().domain(yLeftDomain.reverse()).range(graphParams.yRange);

    const rightY = d3.scaleLinear().domain(yRightDomain.reverse()).range(graphParams.yRange);

    const xBottomAxis = d3.axisBottom(bottomX).ticks(X_TICK_AMOUNT).tickSize(TICK_SIZE);
    const xTopAxis = d3.axisTop(topX).ticks(X_TICK_AMOUNT).tickSize(TICK_SIZE);

    const yLeftAxis = d3.axisLeft(leftY).ticks(Y_TICK_AMOUNT).tickSize(TICK_SIZE);

    const yRightAxis = d3.axisRight(rightY).ticks(Y_TICK_AMOUNT).tickSize(TICK_SIZE);

    const gBottomX = d3.select(`#${id} .axis--x--bottom`).call(xBottomAxis.scale(zoomTransform.rescaleX(bottomX)));

    const gTopX = d3.select(`#${id} .axis--x--top`).call(xTopAxis.scale(zoomTransform.rescaleX(topX)));

    const gLeftY = d3.select(`#${id} .axis--y--left`).call(yLeftAxis.scale(zoomTransform.rescaleY(leftY)));

    const gRightY = d3.select(`#${id} .axis--y--right`).call(yRightAxis.scale(zoomTransform.rescaleY(rightY)));

    const zoom = d3
      .zoom()
      .scaleExtent([1, 40])
      .translateExtent([
        [0, 0],
        [graphParams.width, graphParams.height],
      ])
      .on('zoom', (event: any) => {
        // d3 library doesn't have ZoomEvent type
        const zoomState = event.transform;

        gBottomX.call(xBottomAxis.scale(zoomState.rescaleX(bottomX)));
        gTopX.call(xTopAxis.scale(zoomState.rescaleX(topX)));
        gLeftY.call(yLeftAxis.scale(zoomState.rescaleY(leftY)));
        gRightY.call(yRightAxis.scale(zoomState.rescaleY(rightY)));

        wrapper.attr('transform', zoomState);
      });

    innerSvg.call(zoom);
  }, [graphParams, id, imagePxWidth, imageMmWidth, imagePxHeight, imageMmHeight, isScaleInserted, rescaledMmDomains]);

  return (
    <div className="adapt__image__graph__wrapper">
      <h3>
        <TranslatedText textKey="Image" />
      </h3>
      <div className="common__graph">
        <div className="legend--x legend--x--top">
          <TranslatedText textKey="dimensions.width" /> [mm]
        </div>
        <div className="common__graph__main-axis">
          <div className="legend--y legend--y--left">
            <TranslatedText textKey="dimensions.height" /> [px]
          </div>
          <svg id={id} ref={outerSvgRef} width={graphParams.width} height={graphParams.height}>
            <svg
              x={ADAPT_IMAGE_MARGIN.left}
              y={ADAPT_IMAGE_MARGIN.top}
              width={graphParams.innerSvgWidth}
              height={graphParams.innerSvgHeight}
            >
              <g id={IDs.groupWrapper}>
                <rect width={graphParams.innerSvgWidth} height={graphParams.innerSvgHeight} fill="transparent" />
                {adaptImage && (
                  <image
                    x={0}
                    y={0}
                    href={`data:image/gif;base64,${adaptImage}`}
                    width={graphParams.innerSvgWidth}
                    height={graphParams.innerSvgHeight}
                    preserveAspectRatio="none"
                  />
                )}
                <Scale
                  coords={scaleCoords}
                  isVisible={isScaleInserted}
                  pxDomains={domains.pxDomains}
                  mmDomains={domains.mmDomains}
                  graphParams={graphParams}
                  setCoords={setAdaptImageScaleCoordsHandler}
                />
                <CroppingArea
                  coords={cropCoords}
                  isVisible={isCropping}
                  graphParams={graphParams}
                  pxDomains={domains.pxDomains}
                  setCoords={setAdaptImageCropCoordsHandler}
                />
              </g>
            </svg>
            <g
              className="axis axis--x axis--x--bottom"
              transform={`translate(${ADAPT_IMAGE_MARGIN.left}, ${graphParams.height - ADAPT_IMAGE_MARGIN.bottom})`}
            />
            <g
              className="axis axis--x axis--x--top"
              transform={`translate(${ADAPT_IMAGE_MARGIN.left}, ${ADAPT_IMAGE_MARGIN.top})`}
            />
            <g
              className="axis axis--y axis--y--left"
              transform={`translate(${ADAPT_IMAGE_MARGIN.left}, ${ADAPT_IMAGE_MARGIN.top})`}
            />
            <g
              className="axis axis--y axis--y--right"
              transform={`translate(${graphParams.width - ADAPT_IMAGE_MARGIN.right}, ${ADAPT_IMAGE_MARGIN.top})`}
            />
          </svg>
          <div className="legend--y legend--y--right">
            <TranslatedText textKey="dimensions.height" /> [mm]
          </div>
        </div>
        <div className="legend--x legend--x--bottom">
          <TranslatedText textKey="dimensions.width" /> [px]
        </div>
      </div>
    </div>
  );
};
