import { TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { getToFixedValue } from '../../../../../utils';
import { IProps } from '../index';

export const ZoneHeightRow: FC<IProps> = ({ statistics }) => {
  return (
    <>
      {statistics && 'zoneHeight' in statistics && (
        <li className="seg__statistics__item">
          <p>
            <TranslatedText textKey="Zone height" />:
          </p>
          <p>{getToFixedValue(statistics.zoneHeight)}</p>
        </li>
      )}
    </>
  );
};
