import { Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { ChangeEvent, useCallback, useState } from 'react';

import { REMARKS_NAMES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectFlawsAnalysis, selectFlawsSelectedRegionId, selectFlawsStatistics } from '../../../store/selectors';
import { selectRemarks } from '../../../store/selectors/remarks.selector';
import { Remarks } from '../../Remarks';
import { Statistics } from './Statistics';

const { flaws: flawsRemarksNames } = REMARKS_NAMES;

export const FlawStatistics = () => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const { flaws } = useAppSelector(selectRemarks);
  const { setFlawRemarks } = useActionCreators();
  const analysis = useAppSelector(selectFlawsAnalysis);
  const statistics = useAppSelector(selectFlawsStatistics);
  const selectedRegionId = useAppSelector(selectFlawsSelectedRegionId);

  const handleRemarksChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setFlawRemarks({
        analysis,
        data: e.target.value,
      });
    },
    [analysis]
  );

  return (
    <div className="flaws__statistics__area">
      <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
        <TabPane tab={<TranslatedText textKey="side-menu.statistics" />} key={'0'}>
          <Statistics statistics={statistics} selectedRegionId={selectedRegionId} />
        </TabPane>
        <TabPane tab={<TranslatedText textKey="Remarks" />} key={'1'}>
          <Remarks
            value={flaws[flawsRemarksNames[analysis]]}
            name={flawsRemarksNames[analysis]}
            label={''}
            onChange={handleRemarksChange}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
