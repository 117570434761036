import { Key } from 'react';

import { LOCALSTORAGE_KEYS } from '../consts';

export type ReactKeys = Key[];

export type BuildTuple<L extends number, K, T extends any[] = []> = T extends {
  length: L;
}
  ? T
  : BuildTuple<L, K, [...T, K]>;

export type TTupleOfTwoNumbers = BuildTuple<2, number>;

export type TCoordinates = { x: number; y: number };
export type TCoordinatesTuple = BuildTuple<2, TCoordinates>;
export type TDirection = 'horizontal' | 'vertical';

export interface ICircleParams {
  center: TCoordinates;
  radius: number;
}

export interface ISVGCircleParams {
  cx: number;
  cy: number;
  r: number;
}

export interface ISVGLineParams {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export interface IMargin {
  top: number;
  right: number;
  left: number;
  bottom: number;
}

export interface ILocalCutParams {
  Rectangular: {
    sizeX: number;
    sizeY: number;
  };
  Round: {
    sizeX: number;
    sizeY: number;
  };
}

export interface ICircleCoordinates {
  cx: number;
  cy: number;
}

export interface IRectCoordinates {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
}

export interface ILineCoordinates {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export type TLineCoordinates = {
  start: TCoordinates;
  end: TCoordinates;
};

export type RectParams = TCoordinates & Record<'width', number> & Record<'height', number>;

export type ValueOf<T> = T[keyof T];

export type MenuListItem = {
  id: string;
  name: string;
  onClick: (id: string) => void;
};

type ObjectEntry<T> = T extends Record<string, unknown>
  ? { [K in keyof T]: [K, Required<T>[K]] }[keyof T] extends infer E
    ? E extends [infer K, infer V]
      ? K extends string | number
        ? [`${K}`, V]
        : never
      : never
    : never
  : never;

export function typedEntries<T extends Record<string, unknown>>(object: T): ReadonlyArray<ObjectEntry<T>> {
  return (Object.entries(object) as unknown) as ReadonlyArray<ObjectEntry<T>>;
}

export type LocalStorageKeys = ValueOf<typeof LOCALSTORAGE_KEYS>;

export type InputHintMode = 'visible' | 'hidden';

export type TableColumn<T> = { key: string; field: keyof T; title: string; width: string };

export type PartialDeep<T> = {
  [K in keyof T]?: T[K] extends Record<string, unknown> ? PartialDeep<T[K]> : T[K];
};

export type NestedKeyOf<T extends Record<string, unknown>> = {
  [K in keyof T & (string | number)]: T[K] extends Record<string, unknown>
    ? `${K}` | `${K}-${NestedKeyOf<T[K]>}`
    : `${K}`;
}[keyof T & (string | number)];

export type ValueOfNestedObject<T extends Record<string, unknown>, U extends string> = {
  [K in keyof T]: K extends U ? T[K] : T[K] extends Record<string, unknown> ? ValueOfNestedObject<T[K], U> : string;
}[keyof T];

export interface INumberInputBlurHandlerArgs {
  section: string;
  range: 'start' | 'end';
  startKey: string;
  endKey: string;
}

export interface RadioGroupData {
  value: number | string;
  label: string;
  disabled?: boolean;
}

export type AnalysisState = 'NotPerformed' | 'Performing' | 'Completed' | 'Error';
