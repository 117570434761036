import React, { useEffect, useLayoutEffect } from 'react';

import { MainGraphWindow } from '../../components/CommonGraphWindow';
import { PaletteBar } from '../../components/PaletteBar';
import { SegSideMenu } from '../../components/SegSideMenu';
import { IDs, SLICE_TYPES } from '../../consts';
import { useActionCreators, useAppSelector, useSegregation } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import {
  selectAutomaticAnalysisStatus,
  selectIsReportMode,
  selectScanDataParameters,
  selectSliceType,
  selectVolumeId,
} from '../../store/selectors';

export const Segregation = () => {
  const { setBorder } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const isReportMode = useAppSelector(selectIsReportMode);

  const { handleSegParamsChange, handleTakeSegAutoAnalysisScreenshots } = useSegregation();

  useLayoutEffect(() => {
    if (sliceType !== SLICE_TYPES.cut) return;
    setBorder(false);
  }, [sliceType]);

  useEffect(() => {
    if (isAutoAnalysis) {
      handleTakeSegAutoAnalysisScreenshots();
      return;
    }

    if (isReportMode) return;

    handleSegParamsChange();
  }, [isAutoAnalysis, handleTakeSegAutoAnalysisScreenshots, isReportMode]);

  return (
    <DefaultLayout>
      <div className="page-container">
        {scanDataParameters && (
          <>
            <SegSideMenu />
            <MainGraphWindow id={IDs.segGraph} scanDataParameters={scanDataParameters} />
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
