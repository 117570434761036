import './styles.scss';

import React, { FC } from 'react';

import { useAppSelector } from '../../hooks';
import { ISampleDataParameters } from '../../store/api/scandata/types';
import { useGetAScanMutation } from '../../store/api/slice';
import { selectPrevAScanData } from '../../store/selectors';
import { AScanViewGraph } from './AScanViewGraph';

interface IProps {
  scanView: string;
  scanDataParameters: ISampleDataParameters;
}

export const AScanView: FC<IProps> = ({ scanView, scanDataParameters }) => {
  const [, { data: aScanData }] = useGetAScanMutation({ fixedCacheKey: 'AScan' });
  const prevAScanData = useAppSelector(selectPrevAScanData);

  return (
    <div className="projection-item aScanView">
      <div className="projection-item__container">
        <div className="projection-item__wrapper aScanView">
          <div className="projection-item__title">{scanView}</div>
        </div>
        <AScanViewGraph scanDataParameters={scanDataParameters} prevIntensityValues={prevAScanData} intensityValues={aScanData}/>
      </div>
    </div>
  );
};
