import './styles.scss';

import React from 'react';

import { MainGraphWindow } from '../../components/CommonGraphWindow';
import { ZonesSideMenu } from '../../components/ZonesSideMenu';
import { IDs } from '../../consts';
import { useAppSelector } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { selectScanDataParameters, selectVolumeId } from '../../store/selectors';

export const Zones = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));

  return (
    <DefaultLayout>
      <div className="page-container zones__container">
        {scanDataParameters && (
          <>
            <ZonesSideMenu />
            <MainGraphWindow id={IDs.zonesGraph} scanDataParameters={scanDataParameters} />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};

