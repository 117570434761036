import './styles.scss';

// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import { DB_HEIGHT_ANALYSIS_MARGIN, IDs } from '../../consts';
import { useAppSelector, useWindowSize } from '../../hooks';
import { DBHeightAnalysisTransformedResponse } from '../../store/api/database/types';
import { selectHeightAnalysisVisibleIds } from '../../store/selectors';
import { getBaseSvgElemStyles, getGraphParams } from '../../utils';

interface Props {
  data: DBHeightAnalysisTransformedResponse[];
  xDomain: number;
  yDomain: number;
  type: 'horizontal' | 'vertical';
}

export const HeightAnalysisGraph: FC<Props> = ({ data, type, xDomain, yDomain }) => {
  const visibleIds = useAppSelector(selectHeightAnalysisVisibleIds);
  const outerSvgRef = useRef<SVGSVGElement>(null);
  const resize = useWindowSize();
  const graphParams = useMemo(
    () => getGraphParams(outerSvgRef.current, DB_HEIGHT_ANALYSIS_MARGIN),
    [outerSvgRef.current, resize],
  );

  const getCoordinates = (
    intensityValues: number[],
    graphWidth: number,
    xDomain: number,
    yDomain: number,
    xMaxDomain: number,
  ) => {
    const tick = ((graphWidth / intensityValues.length) * xDomain) / xMaxDomain;
    const offset = ((xMaxDomain - xDomain) / 2 / xMaxDomain) * graphWidth;

    return intensityValues.reduce((acc, value, idx) => {
      // converting intensity to px
      return (
        acc + ` ${idx * tick + offset}, ${graphParams.innerSvgHeight - (value * graphParams.innerSvgHeight) / yDomain}`
      );
    }, '');
  };

  useEffect(() => {
    const innerSvg = d3.select(`#${IDs.heightAnalysis}-${type} svg`);
    const wrapper = d3.select(`#${IDs.heightAnalysis}-${type} svg g`);

    const zoomTransform = d3.zoomTransform(innerSvg.node());

    const xDomains = [0 - xDomain / 2, xDomain / 2];
    const yDomains = [0, yDomain];

    const x = d3.scaleLinear().domain(xDomains).range(graphParams.xRange);
    const y = d3.scaleLinear().domain(yDomains.reverse()).range(graphParams.yRange);

    const xAxis = d3.axisBottom(x).ticks(25).tickSize(4);
    const yAxis = d3.axisLeft(y).ticks(15).tickSize(4);

    const gX = d3.select(`#${IDs.heightAnalysis}-${type} .axis--x`).call(xAxis.scale(zoomTransform.rescaleX(x)));
    const gY = d3.select(`#${IDs.heightAnalysis}-${type} .axis--y`).call(yAxis.scale(zoomTransform.rescaleY(y)));

    const zoom = d3
      .zoom()
      .scaleExtent([0, 40])
      .on('zoom', (event: any) => {
        const zoomState = event.transform;
        gX.call(xAxis.scale(zoomState.rescaleX(x)));
        gY.call(yAxis.scale(zoomState.rescaleY(y)));

        wrapper.attr('transform', zoomState);
      });

    innerSvg.call(zoom);
  }, [graphParams, xDomain, yDomain, type]);

  return (
    <svg id={`${IDs.heightAnalysis}-${type}`} ref={outerSvgRef} width={graphParams.width} height={graphParams.height}>
      <svg
        x={DB_HEIGHT_ANALYSIS_MARGIN.left}
        y={DB_HEIGHT_ANALYSIS_MARGIN.top}
        width={graphParams.innerSvgWidth}
        height={graphParams.innerSvgHeight}
      >
        <rect width={graphParams.innerSvgWidth} height={graphParams.innerSvgHeight} fill="transparent" />
        <g>
          {data.map(
            ({
              analysisResultId,
              axisXMm,
              axisYMm,
              color,
              // heightsAnalysisData: { heightAnalysisHorizontal, heightAnalysisVertical },
              heightsAnalysisData,
            }) =>
              visibleIds.includes(analysisResultId) &&
              heightsAnalysisData && (
                <polyline
                  key={analysisResultId}
                  points={getCoordinates(
                    type === 'horizontal'
                      ? heightsAnalysisData.heightAnalysisHorizontal
                      : heightsAnalysisData.heightAnalysisVertical,
                    graphParams.innerSvgWidth,
                    type === 'horizontal' ? axisXMm : axisYMm,
                    yDomain,
                    xDomain,
                  )}
                  style={getBaseSvgElemStyles(color, 2)}
                />
              ),
          )}
        </g>
      </svg>
      <g
        className="axis axis--x"
        transform={`translate(${DB_HEIGHT_ANALYSIS_MARGIN.left}, ${
          graphParams.height - DB_HEIGHT_ANALYSIS_MARGIN.bottom
        })`}
      />
      <g
        className="axis axis--y"
        transform={`translate(${DB_HEIGHT_ANALYSIS_MARGIN.left}, ${DB_HEIGHT_ANALYSIS_MARGIN.top})`}
      />
    </svg>
  );
};
