import React, { FC } from 'react';

import { BLACK_PRIMARY, TRANSLUCENT_DIM_GREY } from '../../../consts';

interface IProps {
  fill?: string;
  disabled?: boolean;
}

export const Square: FC<IProps> = ({ fill = BLACK_PRIMARY, disabled = false }) => {
  return (
    <div style={{ width: '20px', height: '20px', border: `2px solid ${disabled ? TRANSLUCENT_DIM_GREY : fill}` }} />
  );
};
