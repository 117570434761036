import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { LOCALSTORAGE_KEYS } from '../../consts';
import { getFromLocalStorage } from '../../utils';

const initialState = getFromLocalStorage(LOCALSTORAGE_KEYS.scanInfo)?.name || '';

export const sampleFileNameSlice = createSlice({
  name: 'sampleFileName',
  initialState,
  reducers: {
    setSampleFileName: (state, action: PayloadAction<string>) => action.payload,
  },
});

export const {
  actions: sampleFileNameActions,
  reducer: sampleFileName,
} = sampleFileNameSlice;
