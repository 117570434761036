import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { AUTO_ANALYSIS_API_URL } from '../../../consts';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { ISnapshotDataBody } from './types';

const { setSelectedSnapshotId } = allActions;

export const snapshotApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSnapshots: build.query({
      async queryFn(volumeId, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ(`snapshot/getAll?volumeId=${volumeId}`);

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        const data = response.data as Record<string, string>;

        const {
          snapshots: { selectedSnapshotId },
        } = getState() as TypedRootState;
        if (!selectedSnapshotId) {
          dispatch(setSelectedSnapshotId(Object.keys(data)[0]));
        }

        return { data };
      },
      providesTags: ['Snapshots'],
    }),
    takeSnapshot: build.mutation<void, ISnapshotDataBody>({
      async queryFn(body, { getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          analysisStatus: {analysesComplete},
        } = getState() as TypedRootState;

        const url = analysesComplete
          ? `${AUTO_ANALYSIS_API_URL}/snapshot/take`
          : `${process.env.REACT_APP_API_URL}/snapshot/take`;

        const result = await fetchWithBQ({
          url,
          method: 'POST',
          body: {
            volumeId,
            ...body,
          },
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    removeSnapshot: build.mutation<void, string>({
      async queryFn(name, { getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'snapshot/remove',
          method: 'POST',
          body: {
            volumeId,
            name,
          },
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Snapshots'],
    }),
    pasteSnapshot: build.mutation<void, Omit<ISnapshotDataBody, 'description'>>({
      async queryFn(body, { getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'snapshot/paste',
          method: 'PUT',
          body: {
            volumeId,
            ...body,
          },
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Snapshots'],
    }),
  }),
});

export const {
  useGetAllSnapshotsQuery,
  useLazyGetAllSnapshotsQuery,
  useTakeSnapshotMutation,
  useRemoveSnapshotMutation,
  usePasteSnapshotMutation,
} = snapshotApi;
