import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_SLICE_C_RANGE } from '../../consts';
import { ISliderRange } from '../../types/scanData';
import { ISliderRanges } from './types';

export const initialSliceRange: ISliderRange = { start: 0, end: 0 };

const initialState = {
  maxProjection: {
    sliceB: initialSliceRange,
    sliceC: DEFAULT_SLICE_C_RANGE,
    sliceD: initialSliceRange,
  },
  singleLayer: {
    sliceB: initialSliceRange,
    sliceC: DEFAULT_SLICE_C_RANGE,
    sliceD: initialSliceRange,
  },
};

export const sliderRangesSlice = createSlice({
  name: 'sliderRanges',
  initialState,
  reducers: {
    setMaxProjectionSliderRange: (state, action: PayloadAction<Partial<ISliderRanges>>) => {
      Object.assign(state.maxProjection, action.payload);
    },
    setSingleLayerSliderRange: (state, action: PayloadAction<Partial<ISliderRanges>>) => {
      Object.assign(state.singleLayer, action.payload);
    },
  },
});

export const { actions: sliderRangesActions, reducer: sliderRanges } = sliderRangesSlice;
