import './styles.scss';

import React from 'react';

interface Props {
  percent: number | undefined;
}

export const Progress = ({ percent }: Props) => {
  return (
    <div className="progress">
      <div className="progress-container">
        <div
          className="progress-indicator"
          style={{ width: `${percent}%` }}
        ></div>
      </div>
      <span>{percent}%</span>
    </div>
  );
};
