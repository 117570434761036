import { useCallback } from 'react';

import {
  CRACKS_ANALYSIS,
  CRACKS_MODIFICATION_MODES,
  DEFAULT_ERROR_MESSAGE,
  IDENTIFIED_SNAPSHOTS,
  IDs,
  MODAL_STATUS,
  MODAL_TITLE,
  SHAPES,
  SLICE_TYPES,
} from '../consts';
import {
  useDeleteCrackMutation,
  useFindCracksMutation,
  useGetCracksBilletCutRectZonesQuery,
  useGetCracksBilletCutRoundZonesQuery,
  useGetCracksBilletRectZonesQuery,
  useGetCracksBilletRoundZonesQuery,
  useGetCracksSlabZonesQuery,
  useLazyGetAllCracksQuery,
  useLazyGetBilletStatisticsQuery,
  useLazyGetCracksASTMStatisticsQuery,
  useLazyGetCracksGOSTStatisticsQuery,
  useLazyGetCracksSMSStatisticsQuery,
} from '../store/api/cracks';
import { useTakeSnapshotMutation } from '../store/api/snaphot';
import {
  selectAreCracksZonesShown,
  selectCracksAnalysis,
  selectCracksModificationMode,
  selectCracksSliderAndPaletteAutoAnalysis,
  selectShapeBilletCutParam,
  selectSliceType,
  selectVolumeId,
} from '../store/selectors';
import { TCracksAnalysis } from '../types';
import { asyncSetTimeout, createModalMessageBody, getBase64Snapshot, getShortBase64String } from '../utils';
import { useActionCreators } from './useActionCreators';
import { useAppSelector } from './useAppSelector';
import { useAutoAnalysisNavigate } from './useAutoAnalysisNavigate';

export const useCracks = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const cracksAnalysis = useAppSelector(selectCracksAnalysis);
  const areZonesShown = useAppSelector(selectAreCracksZonesShown);
  const modificationMode = useAppSelector(selectCracksModificationMode);
  const sliceType = useAppSelector(selectSliceType);
  const shape = useAppSelector(selectShapeBilletCutParam);
  const cracksAutoAnalysisPaletteAndRange = useAppSelector(selectCracksSliderAndPaletteAutoAnalysis);
  const { setPaletteData, setMaxProjectionSliderRange, setSpinnerVisible, setMessageModal } = useActionCreators();

  const autoAnalysisNavigate = useAutoAnalysisNavigate();

  const { data: slabZones } = useGetCracksSlabZonesQuery(undefined, {
    skip:
      !areZonesShown ||
      sliceType === SLICE_TYPES.rect ||
      sliceType === SLICE_TYPES.round ||
      sliceType === SLICE_TYPES.cut,
  });
  const { data: billetRectZones } = useGetCracksBilletRectZonesQuery(undefined, {
    skip: !areZonesShown || sliceType !== SLICE_TYPES.rect,
  });
  const { data: billetRoundZones } = useGetCracksBilletRoundZonesQuery(undefined, {
    skip: !areZonesShown || sliceType !== SLICE_TYPES.round,
  });
  const { data: billetCutRectZones } = useGetCracksBilletCutRectZonesQuery(undefined, {
    skip: !areZonesShown || shape !== SHAPES.rect,
  });
  const { data: billetCutRoundZones } = useGetCracksBilletCutRoundZonesQuery(undefined, {
    skip: !areZonesShown || shape !== SHAPES.round,
  });
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();

  const [findCracksTrigger] = useFindCracksMutation();
  const [getAllCracksTrigger, { data: allCracks }] = useLazyGetAllCracksQuery();
  const [deleteCrackTrigger] = useDeleteCrackMutation();
  const [getSMSStatistics] = useLazyGetCracksSMSStatisticsQuery();
  const [getGOSTStatistics] = useLazyGetCracksGOSTStatisticsQuery();
  const [getASTMStatistics] = useLazyGetCracksASTMStatisticsQuery();
  const [getBilletStatistics] = useLazyGetBilletStatisticsQuery();

  const isInfoModificationMode = modificationMode === CRACKS_MODIFICATION_MODES.info;
  const isDeletionModificationMode = modificationMode === CRACKS_MODIFICATION_MODES.delete;
  const isModifyModificationMode = modificationMode === CRACKS_MODIFICATION_MODES.modify;

  const takeCracksReportScreenshot = useCallback(async () => {
    const imageBase64String = await getBase64Snapshot(`#${IDs.commonGraphInnerWrapper}`);

    const imageWidth = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetWidth ?? 0;
    const imageHeight = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetHeight ?? 0;

    const body = {
      volumeId,
      name: IDENTIFIED_SNAPSHOTS.cracks,
      imageBase64String: getShortBase64String(imageBase64String),
      description: '',
      imageWidth,
      imageHeight,
    };

    await takeSnapshotTrigger(body);
  }, [volumeId]);

  const getCracksStatistics = useCallback(
    async (newAnalysis: TCracksAnalysis | null) => {
      setSpinnerVisible(true);

      switch (newAnalysis ?? cracksAnalysis) {
        case CRACKS_ANALYSIS.sms:
          await getSMSStatistics();
          break;
        case CRACKS_ANALYSIS.gost:
          if (sliceType === SLICE_TYPES.round || sliceType === SLICE_TYPES.cut) {
            await getBilletStatistics();
            return;
          }
          await getGOSTStatistics();
          break;
        case CRACKS_ANALYSIS.astm:
          await getASTMStatistics();
          break;
        default:
      }

      setSpinnerVisible(false);
    },
    [cracksAnalysis],
  );

  const handleGetAllCracks = useCallback(async () => {
    if (sliceType !== SLICE_TYPES.round && sliceType !== SLICE_TYPES.cut) {
      await findCracksTrigger();

      let timer: ReturnType<typeof setInterval>;

      await new Promise<void>((resolve, reject) => {
        timer = setInterval(async () => {
          const { data, error } = await getAllCracksTrigger();

          const errorHandler = () => {
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
            setSpinnerVisible(false);
            clearInterval(timer);
            reject();
          };

          if (error) {
            errorHandler();
            return;
          }

          if (data) {
            if (data?.analysisState === 'Error' || data?.analysisState === 'NotPerformed') {
              errorHandler();
              return;
            }

            if (data?.analysisState === 'Completed' && data?.cracksDetectionData) {
              clearInterval(timer);
              resolve();
            }
          }
        }, 3 * 1000);
      });
    }
  }, [getAllCracksTrigger, sliceType]);

  const handleCracksAnalysisChange = useCallback(async () => {
    setSpinnerVisible(true);

    await handleGetAllCracks();
    await getCracksStatistics(cracksAnalysis);

    setSpinnerVisible(false);
  }, [handleGetAllCracks, cracksAnalysis]);

  const handleTakeCracksAutoAnalysisScreenshots = useCallback(async () => {
    if (!cracksAutoAnalysisPaletteAndRange) return;

    setPaletteData(cracksAutoAnalysisPaletteAndRange.palette);
    setMaxProjectionSliderRange({ sliceC: cracksAutoAnalysisPaletteAndRange.sliderRange });

    await asyncSetTimeout(async () => takeCracksReportScreenshot(), 300);
    autoAnalysisNavigate();
  }, [cracksAutoAnalysisPaletteAndRange, takeCracksReportScreenshot]);

  const handleTakeCracksReportScreenshots = useCallback(async () => {
    await takeCracksReportScreenshot();
  }, []);

  return {
    handleCracksAnalysisChange,
    deleteCrackTrigger,
    allCracks: allCracks?.cracksDetectionData,
    cracksAnalysis,
    areZonesShown,
    slabZones,
    billetRectZones,
    billetRoundZones,
    billetCutRectZones,
    billetCutRoundZones,
    isInfoModificationMode,
    isDeletionModificationMode,
    isModifyModificationMode,
    modificationMode,
    getCracksStatistics,
    handleTakeCracksAutoAnalysisScreenshots,
    handleTakeCracksReportScreenshots,
  };
};
