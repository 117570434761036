// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { BLACK_PRIMARY, CENTER_POINT_RADIUS, IDs } from '../../../../../../consts';
import { useActionCreators, useAppSelector, useSegregation } from '../../../../../../hooks';
import { selectSegAllowAreaModification } from '../../../../../../store/selectors';
import { ICircleCoordinates, TCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import { getToFixedValue, getXOffsetFunc, getYOffsetFunc, ICartesianModeParams } from '../../../../../../utils';

interface IProps {
  domains: TTupleOfTwoNumbers;
  graphParams: ICartesianModeParams;
  coordinates: TCoordinates;
  outerGraphHeight: number;
  outerGraphWidth: number;
  billetCutDx: number;
  billetCutDy: number;
}

export const CenterPoint: FC<IProps> = ({
  graphParams,
  domains,
  coordinates,
  outerGraphHeight,
  outerGraphWidth,
  billetCutDx,
  billetCutDy,
}) => {
  const { setSegCenterPoint } = useActionCreators();
  const allowAreaModification = useAppSelector(selectSegAllowAreaModification);
  const getXOffset = getXOffsetFunc(graphParams, domains);
  const getYOffset = getYOffsetFunc(graphParams, domains);
  const cx = coordinates.x + billetCutDx;
  const cy = coordinates.y + billetCutDy;
  const { handleCenterPointChange } = useSegregation();

  useEffect(() => {
    const centerPoint = d3.select(`#${IDs.centerPoint}`);

    if (!allowAreaModification) {
      centerPoint.attr('cursor', 'auto').call(d3.drag());

      return;
    }

    centerPoint.datum({ cx, cy }).attr('cursor', 'grab');

    function dragstarted(this: SVGLineElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGLineElement, event: MouseEvent, d: ICircleCoordinates) {
      d3.select(this)
        .attr('cx', (d.cx = event.x))
        .attr('cy', (d.cy = event.y));
    }

    function dragended(this: SVGLineElement, event: MouseEvent, d: ICircleCoordinates) {
      d3.select(this).attr('cursor', 'grab');

      const xRatio = outerGraphWidth / graphParams.realSampleWidth;

      const x = getToFixedValue(((d.cx - graphParams.x) * domains[0] * xRatio) / outerGraphWidth - billetCutDx);
      const y = getToFixedValue(
        domains[1] - ((d.cy - graphParams.y) * domains[1]) / graphParams.realSampleHeight - billetCutDy
      );

      setSegCenterPoint({ x, y });
      handleCenterPointChange({ centerPointX: x, centerPointY: y });
    }

    centerPoint.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
  }, [
    coordinates,
    allowAreaModification,
    outerGraphWidth,
    domains,
    graphParams,
    outerGraphHeight,
    billetCutDx,
    billetCutDy,
    cx,
    cy,
  ]);

  return (
    <circle id={IDs.centerPoint} cx={getXOffset(cx)} cy={getYOffset(cy)} r={CENTER_POINT_RADIUS} fill={BLACK_PRIMARY} />
  );
};
