import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { LOCALSTORAGE_KEYS } from '../../../consts';
import { IInfoData } from '../../../types';
import { getFromLocalStorage } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';

const { setInitialInfo } = allActions;

export const infoApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInfo: build.query<IInfoData, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const id = getFromLocalStorage(LOCALSTORAGE_KEYS.scanInfo)?.id || '';
        const result = await fetchWithBQ(`info?scanId=${id}`);

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IInfoData;
        dispatch(setInitialInfo(data));

        return { data };
      },
      providesTags: ['Info'],
    }),
    saveInfo: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const { info } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'info/save',
          method: 'POST',
          body: info,
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Info'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetInfoQuery, useSaveInfoMutation } = infoApi;
