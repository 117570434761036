import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDistance, IDistances, IToolbarOptions } from './types';

export interface IToolbarState extends IToolbarOptions {
  distances: IDistances;
}

const initialState: IToolbarState = {
  isCartesianMode: false,
  isBorder: false,
  isMaxProjection: true,
  isVolume: false,
  isDistanceMode: false,
  distances: {
    B: [],
    C: [],
    D: [],
  },
};

export const toolbarOptionsSlice = createSlice({
  name: 'toolbarOptions',
  initialState,
  reducers: {
    setCartesianMode: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isCartesianMode = !state.isCartesianMode;
      } else {
        state.isCartesianMode = action.payload;
      }
    },
    setBorder: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isBorder = !state.isBorder;
      } else {
        state.isBorder = action.payload;
      }
    },
    setMaxProjection: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isMaxProjection = !state.isMaxProjection;
      } else {
        state.isMaxProjection = action.payload;
      }
    },
    setVolume: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isVolume = !state.isVolume;
      } else {
        state.isVolume = action.payload;
      }
    },
    setDistanceMode: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isDistanceMode = !state.isDistanceMode;
      } else {
        state.isDistanceMode = action.payload;
      }
    },
    setDistances: (state, action: PayloadAction<Partial<Record<keyof IDistances, IDistance>>>) => {
      let key: keyof IDistances;
      for (key in action.payload) {
        //@ts-ignore
        state.distances[key].push(action.payload[key]);
      }
    },
    setActiveDistance: (state, action: PayloadAction<Partial<Record<keyof IDistances, string>>>) => {
      let key: keyof IDistances;

      for (key in action.payload) {
        const selectedDistance = state.distances[key].find((distance) => distance.id === action.payload[key]);
        if (selectedDistance) selectedDistance.active = true;
      }
    },
    removeDistance: (state, action: PayloadAction<Partial<Record<keyof IDistances, string>>>) => {
      let key: keyof IDistances;

      for (key in action.payload) {
        state.distances[key] = state.distances[key].filter((distance) => distance.id !== action.payload[key]);
      }
    },
  },
});

export const { actions: toolbarOptionsActions, reducer: toolbarOptions } = toolbarOptionsSlice;
