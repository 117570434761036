import './styles.scss';

import { navigate, TranslatedText } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components/Button/Button';
import React from 'react';

import { Modals } from '../../components/Modals';
import { useActionCreators, useAppSelector } from '../../hooks';
import { selectFileType, selectVolumeId } from '../../store/selectors';
import { checkFileType } from '../../utils';

export const Home = () => {
  const { setFileLoaderVisible } = useActionCreators();
  // const fileName = useAppSelector(selectSampleFileName);
  const volumeId = useAppSelector(selectVolumeId);
  const fileType = useAppSelector(selectFileType);
  const { isImageFileType, isIDVFileType } = checkFileType(fileType);

  const handleManualClick = () => {
    if (!volumeId) {
      setFileLoaderVisible(true);
      return;
    }

    if (isIDVFileType) {
      navigate('/bcdplus');
      return;
    }

    if (isImageFileType) {
      navigate('/adapt-image');
      return;
    }
  };

  const handleAutomaticAnalysisClick = () => {
    navigate('/recipes');
  };

  const handleDatabaseClick = () => {
    navigate('/database');
  };

  return (
    <>
      <div className="home-page__layout">
        <div className="home-page__welcome-card">
          <h1 className="home-page__title">
            <TranslatedText textKey="Welcome" />
          </h1>
          <div className="home-page__buttons">
            <Button title="home.sutomatic-analysis" onClick={handleAutomaticAnalysisClick} />
            <Button title="home.manual-analysis" onClick={handleManualClick} />
            <Button title="home.db" onClick={handleDatabaseClick} />
          </div>
        </div>
      </div>
      <Modals />
    </>
  );
};
