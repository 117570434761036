import { navigate } from '@sms/plasma-ui';
import { Button, TranslatedText } from '@sms/plasma-ui/lib/components';
import React, { ChangeEvent, FC, memo, MouseEvent } from 'react';

import { CustomRadioGroup } from '../../../../../components/UI/CustomRadioGroup';
import { RadioGroupData } from '../../../../../types';

interface Props {
  chartType: string;
  dataSource: RadioGroupData[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onExport: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const DatabaseChartsSideMenu: FC<Props> = memo(function DatabaseChartsSideMenu({
  chartType,
  dataSource,
  onChange,
  onExport,
}) {
  return (
    <div className="graphs-side-menu">
      <div className="graphs-side-menu__content">
        <h3 className="side-menu__title">
          <TranslatedText textKey="Chart types" />
        </h3>
        <CustomRadioGroup data={dataSource} onChange={onChange} radioGroupValue={chartType} />
      </div>
      <div className="controls__wrapper">
        <Button title={'Export chart data'} onClick={onExport} />
        <Button title={'Back to the results table'} onClick={() => navigate('/database/results')} />
      </div>
    </div>
  );
});
