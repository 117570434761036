import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components/Button/Button';
import React, { ChangeEvent } from 'react';

import { typedInfoInputsEntries } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { useGetInfoQuery, useSaveInfoMutation } from '../../store/api/info';
import { selectInfo } from '../../store/selectors';
import { typedEntries } from '../../types';
import { FormInput } from '../FormInput';
import { Remarks } from '../Remarks';

export const InfoForm = () => {
  const info = useAppSelector(selectInfo);
  const { setInfoField, setInfoRemarks } = useActionCreators();

  useGetInfoQuery();
  const [saveInfoTrigger] = useSaveInfoMutation();

  const handleRemarksChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInfoRemarks(e.target.value);
  };

  return (
    <div className="info__form">
      <div className="info__form__fields">
        {typedInfoInputsEntries.map(([section, options]) => (
          <div key={section} className="info__section">
            <h3 className="side-menu__title">
              <TranslatedText textKey={`info.${section}`} />
            </h3>
            <div className="section__inputs">
              {typedEntries(options).map(([id, label]) => {
                if (id === 'remarks') {
                  return (
                    <Remarks key={id} value={info.remarks} onChange={handleRemarksChange} label={label} name={id} />
                  );
                }

                return (
                  <FormInput
                    key={id}
                    id={id}
                    label={label}
                    // @ts-ignore
                    value={info[section][id]}
                    section={section}
                    onChange={setInfoField}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <Button title="info.save-sample-info" onClick={() => saveInfoTrigger()} />
    </div>
  );
};
