export const INPUT_NAMES = {
  lower: 'lower',
  upper: 'upper',
  base: 'base',
} as const;

export const BUTTON_NAMES = {
  heightAnalysis: 'heightAnalysis',
  allowAreaModification: 'allowAreaModification',
  zonesReset: 'zonesReset',
  activeFilter: 'activeFilter',
  addZone: 'addZone',
  deleteZone: 'deleteZone',
  prev: 'prev',
  next: 'next',
  x: 'x',
  y: 'y',
  x1: 'x1',
  y1: 'y1',
  width: 'width',
  height: 'height',
  innerDiameter: 'innerDiameter',
  outerDiameter: 'outerDiameter',
  rectZoneInsertion: 'rectZoneInsertion',
  rectZoneRemoving: 'rectZoneRemoving',
  rectZoneModifying: 'rectZoneModifying',
} as const;
