import React, { FC } from 'react';

import { BASE_RECT_SIDE_SIZE, BASE_STROKE_THICK, GREEN_PRIMARY, ORANGE_PRIMARY, RED_PRIMARY } from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { selectBorderData } from '../../../../store/selectors';
import { selectGeometryCLines } from '../../../../store/selectors/geometry.selector';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  getBaseSvgElemStyles,
  getRectOffset,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { Path } from '../../../Path';

interface IProps {
  volumeId: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
}

export const GeometryCLines: FC<IProps> = ({ volumeId, cartesianModeParams, domains, graphParams }) => {
  const { outerBorder } = useAppSelector((state) => selectBorderData(state, volumeId));
  const { cLinesPointsLeft, cLinesPointsRight } = useAppSelector((state) => selectGeometryCLines(state, volumeId));

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);
  const rectOffset = getRectOffset(BASE_RECT_SIDE_SIZE, BASE_STROKE_THICK);

  return (
    <>
      <Path
        cartesianModeParams={cartesianModeParams}
        data={outerBorder}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
        style={getBaseSvgElemStyles(GREEN_PRIMARY, 1.5)}
      />
      <rect
        x={getXOffset(cLinesPointsLeft.endCornerPoint.x) - rectOffset}
        y={getYOffset(cLinesPointsLeft.endCornerPoint.y) - rectOffset}
        width={BASE_RECT_SIDE_SIZE}
        height={BASE_RECT_SIDE_SIZE}
        fill={RED_PRIMARY}
      />
      <rect
        x={getXOffset(cLinesPointsLeft.straightLineEndPoint.x) - rectOffset}
        y={getYOffset(cLinesPointsLeft.straightLineEndPoint.y) - rectOffset}
        width={BASE_RECT_SIDE_SIZE}
        height={BASE_RECT_SIDE_SIZE}
        fill={RED_PRIMARY}
      />
      <Path
        cartesianModeParams={cartesianModeParams}
        data={Object.values(cLinesPointsLeft)}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
        style={getBaseSvgElemStyles()}
      />
      <rect
        x={getXOffset(cLinesPointsRight.endCornerPoint.x) - rectOffset}
        y={getYOffset(cLinesPointsRight.endCornerPoint.y) - rectOffset}
        width={BASE_RECT_SIDE_SIZE}
        height={BASE_RECT_SIDE_SIZE}
        fill={ORANGE_PRIMARY}
      />
      <rect
        x={getXOffset(cLinesPointsRight.straightLineEndPoint.x) - rectOffset}
        y={getYOffset(cLinesPointsRight.straightLineEndPoint.y) - rectOffset}
        width={BASE_RECT_SIDE_SIZE}
        height={BASE_RECT_SIDE_SIZE}
        fill={ORANGE_PRIMARY}
      />
      <Path
        cartesianModeParams={cartesianModeParams}
        data={Object.values(cLinesPointsRight)}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
        style={getBaseSvgElemStyles()}
      />
    </>
  );
};
