import './styles.scss';

import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import { useAppSelector } from '../../../hooks';
import { selectFileType } from '../../../store/selectors';
import { MenuListItem } from '../../../types';
import { checkFileType } from '../../../utils';
import { DropdownMenu } from './DropdownMenu';

interface IDropdownProps {
  title: string;
  list: MenuListItem[];
}

export function Dropdown({ title, list }: IDropdownProps) {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const fileType = useAppSelector(selectFileType);
  const { isImageFileType } = useMemo(() => checkFileType(fileType), [fileType]);

  const dropdownClasses = classNames('dropdown', {
    active: isMenuActive,
    disabled: isImageFileType,
  });

  const handleDropdownClick = () => {
    if (isImageFileType) return;

    setIsMenuActive((prev) => !prev);
  };

  return (
    <div onClick={handleDropdownClick} onMouseLeave={() => setIsMenuActive(false)}>
      <div className={dropdownClasses}>
        <TranslatedText textKey={title} />
        <div className="dropdown__menu">
          <DropdownMenu list={list} />
        </div>
      </div>
    </div>
  );
}
