import React, { useEffect, useLayoutEffect } from 'react';

import { BackwallSideMenu } from '../../components/BackwallSideMenu';
import { MainGraphWindow } from '../../components/CommonGraphWindow';
import { PaletteBar } from '../../components/PaletteBar';
import { IDs } from '../../consts';
import { useActionCreators, useAppSelector, useBackwall } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { selectAutomaticAnalysisStatus, selectScanDataParameters, selectVolumeId } from '../../store/selectors';

export const Backwall = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const { setBorder } = useActionCreators();
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const { handleTakeBackwallAutoAnalysisScreenshots, setDistanceImageTrigger } = useBackwall();

  useLayoutEffect(() => {
    setBorder(false);
  }, []);

  useEffect(() => {
    if (isAutoAnalysis) {
      handleTakeBackwallAutoAnalysisScreenshots();
      return;
    }

    setDistanceImageTrigger();
  }, [isAutoAnalysis, handleTakeBackwallAutoAnalysisScreenshots]);

  return (
    <DefaultLayout>
      <div className="page-container">
        {scanDataParameters && (
          <>
            <BackwallSideMenu />
            <MainGraphWindow id={IDs.backwallGraph} scanDataParameters={scanDataParameters} />
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
