import React, { Fragment } from 'react';

import { IPolygonCoordinates } from '../../store/slices/types';
import { TCoordinates, TTupleOfTwoNumbers } from '../../types';
import {
  genericMemo,
  getRadiusInPx,
  getToFixedValue,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../utils';

interface IProps<T extends IPolygonCoordinates> {
  polygonsCoordinates: IPolygonCoordinates[];
  xOffset: number;
  yOffset: number;
  domains: TTupleOfTwoNumbers;
  graphWidth: number;
  graphHeight: number;
  graphParams: ICartesianModeParams;
  billetCutDx: number;
  billetCutDy: number;
  selectedPolygon: T | null;
  selectPolygon: (polygon: T) => void;
}

const PolygonsComponent = <T extends IPolygonCoordinates>({
  polygonsCoordinates,
  selectedPolygon,
  xOffset,
  yOffset,
  domains,
  graphWidth,
  graphHeight,
  graphParams,
  billetCutDx,
  billetCutDy,
  selectPolygon,
}: IProps<T>) => {
  const getXOffset = getXOffsetFunc(graphParams, domains);
  const getYOffset = getYOffsetFunc(graphParams, domains);

  const getPolygonPoints = (polygonCoords: TCoordinates[]) => {
    return polygonCoords
      .map((point) => {
        const x = xOffset + ((point.x + billetCutDx) / domains[0]) * graphWidth;
        const y = yOffset + graphHeight * (1 - (point.y + billetCutDy) / domains[1]);

        return `${x},${y}`;
      })
      .join(' ');
  };

  return (
    <g className="polygons__group">
      {polygonsCoordinates.map((polygon) => {
        const {
          id,
          polygonCoordinates,
          innerCenter,
          equivalentCenter,
          outerCenter,
          diameterMin,
          diameterEquivalent,
          diameterMax,
        } = polygon;
        const minRadius = getRadiusInPx(diameterMin, graphWidth, domains);
        const equivalentRadius = getRadiusInPx(diameterEquivalent, graphWidth, domains);
        const maxRadius = getRadiusInPx(diameterMax, graphWidth, domains);

        return (
          <Fragment key={id}>
            {selectedPolygon?.id === id && (
              <>
                <circle
                  cx={getXOffset(innerCenter.x + billetCutDx)}
                  cy={getYOffset(innerCenter.y + billetCutDy)}
                  r={minRadius}
                  fill="none"
                  stroke={'white'}
                  strokeWidth={0.1}
                />
                <circle
                  cx={getXOffset(equivalentCenter.x + billetCutDx)}
                  cy={getYOffset(equivalentCenter.y + billetCutDy)}
                  r={equivalentRadius}
                  fill="none"
                  stroke={'black'}
                  strokeWidth={0.1}
                />
                <circle
                  cx={getXOffset(outerCenter.x + billetCutDx)}
                  cy={getYOffset(outerCenter.y + billetCutDy)}
                  r={maxRadius}
                  fill="none"
                  stroke={'rgba(255, 0, 0, 0.5)'}
                  strokeWidth={0.1}
                />
              </>
            )}
            <polygon
              key={id}
              points={getPolygonPoints(polygonCoordinates)}
              onClick={() => selectPolygon(polygon as T)}
              stroke={selectedPolygon?.id === id ? 'rgba(255, 0, 0, 0.8)' : 'none'}
              strokeWidth={selectedPolygon?.id === id ? 0.1 : 0}
              fill={'rgba(200, 0, 0, 0.5)'}
            />
            {selectedPolygon?.id === id && (
              <>
                <text
                  x={getXOffset(innerCenter.x + billetCutDx)}
                  y={getYOffset(innerCenter.y + billetCutDy)}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                >
                  {getToFixedValue(diameterMin)} mm
                </text>
                <text
                  x={getXOffset(outerCenter.x + billetCutDx)}
                  y={getYOffset(outerCenter.y + billetCutDy) + maxRadius}
                  textAnchor="middle"
                  alignmentBaseline="hanging"
                >
                  {getToFixedValue(diameterMax)} mm
                </text>
              </>
            )}
          </Fragment>
        );
      })}
    </g>
  );
};

export const Polygons = genericMemo(PolygonsComponent);
