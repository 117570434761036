import './styles.scss';

import React from 'react';

import { AreaStatistics } from './AreaStatistics';
import { DefectsStatistics } from './DefectsStatistics';
import { SegStatisticsTitle } from './SegStatisticsTitle';

export const SegStatistics = () => {
  return (
    <div className='segregation__menu__statistics'>
      <SegStatisticsTitle />
      <DefectsStatistics />
      <AreaStatistics />
    </div>
  );
};
