import './styles.scss';

import React, { useMemo } from 'react';

import { useAppSelector, useScanViewRangeSlider } from '../../../hooks';
import { ISampleDataParameters } from '../../../store/api/scandata/types';
import { useSetProjectionsDataMutation } from '../../../store/api/slice';
import { selectFileType, selectSliceType } from '../../../store/selectors';
import { checkFileType, getScanDataResolution } from '../../../utils';
import { RangeBar } from '../../RangeBar';
import { CastingDirections } from './CastingDirections';
import { SliceGraph } from './SliceGraph';

interface IProjectionItemProps {
  key?: string | number;
  scanView: string;
  scanDataParameters: ISampleDataParameters;
  tooltipHidden?: boolean;
}

export const ProjectionItem: React.FC<IProjectionItemProps> = ({
  scanView,
  scanDataParameters,
  tooltipHidden = false,
}) => {
  const sliceType = useAppSelector(selectSliceType);
  const fileType = useAppSelector(selectFileType);
  const {
    size,
    range: { start, end },
    isMaxProjection,
    handleNumberInputChange,
    handleSliderRangeChange,
  } = useScanViewRangeSlider(scanView, scanDataParameters);
  const [_, { data }] = useSetProjectionsDataMutation({ fixedCacheKey: `slice${scanView}` });

  const { isIDVFileType } = useMemo(() => checkFileType(fileType), [fileType]);

  return (
    <div className="projection-item">
      {!tooltipHidden && <CastingDirections sliceType={sliceType} scanView={scanView} />}
      <div className="projection-item__container">
        <div className="projection-item__wrapper">
          <div className="projection-item__title">{scanView}</div>
          {isIDVFileType && (
            <RangeBar
              rangeSliderId={`rangeSlider${scanView}`}
              size={size}
              isMaxProjection={isMaxProjection}
              rangeSlideDisabled={!isMaxProjection}
              upperValue={end}
              lowerValue={start}
              value={[start, end]}
              handleSliderRangeChange={handleSliderRangeChange}
              handleNumberInputChange={handleNumberInputChange}
              precision={2}
              decimalSeparator={'.'}
              isReversed
              step={getScanDataResolution(scanDataParameters, scanView)}
            />
          )}
        </div>
        {scanDataParameters && (
          <SliceGraph
            fileType={fileType}
            scanView={scanView}
            isMaxProjection={isMaxProjection}
            scanDataParameters={scanDataParameters}
            // @ts-ignore
            sliceData={data ? data[`slice${scanView}` as keyof typeof data] : null}
          />
        )}
      </div>
    </div>
  );
};
