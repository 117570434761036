import { createSelector } from '@reduxjs/toolkit';

import { TCracksAnalysis } from '../../types';
import { cracksApi } from '../api/cracks';
import { ICracksStatistics, TCrackType, TModificationMode } from '../api/cracks/types';
import { TypedRootState } from '../types';
import { selectAutomaticAnalysisResult } from './automaticAnalysis.selector';

export const selectCracksAnalysis = (state: TypedRootState): TCracksAnalysis => state.cracks.analysis;

export const selectAreCracksZonesShown = (state: TypedRootState): boolean => state.cracks.areZonesShown;

export const selectCracksSelectedCrackId = (state: TypedRootState): number[] => state.cracks.selectedCrackIds;

export const selectCracksModificationMode = (state: TypedRootState): TModificationMode | null =>
  state.cracks.modificationMode;

export const selectCracksSelectedTypes = (state: TypedRootState): TCrackType[] => state.cracks.selectedCrackTypes;
export const selectCracksStatistics = (state: TypedRootState): ICracksStatistics => state.cracks.statistics;

export const selectCracksSMSStatistics = (state: TypedRootState): ICracksStatistics | undefined =>
  cracksApi.endpoints.getCracksSMSStatistics.select()(state)?.data;
export const selectCracksGOSTStatistics = (state: TypedRootState): ICracksStatistics | undefined =>
  cracksApi.endpoints.getCracksGOSTStatistics.select()(state)?.data;
export const selectCracksASTMStatistics = (state: TypedRootState): ICracksStatistics | undefined =>
  cracksApi.endpoints.getCracksASTMStatistics.select()(state)?.data;
export const selectCracksBilletStatistics = (state: TypedRootState): ICracksStatistics | undefined =>
  cracksApi.endpoints.getBilletStatistics.select()(state)?.data;

export const selectCracksAllCracks = createSelector(
  [(state: TypedRootState) => cracksApi.endpoints.getAllCracks.select()(state)?.data, selectAutomaticAnalysisResult],
  (allCracks, analysisResult) => allCracks?.cracksDetectionData || analysisResult?.cracksResponse,
);
