import { Button } from '@sms/plasma-ui';
import React, { useEffect, useRef, useState } from 'react';

import { DEFAULT_ERROR_MESSAGE, LOGOS_IDS, MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useGetLogosQuery, useSetClientSettingsMutation } from '../../../store/api/settings';
import { selectLogoImage } from '../../../store/selectors';
import { TLogo } from '../../../types';
import { createModalMessageBody, getBase64Snapshot, getShortBase64String } from '../../../utils';
import { LogoUse } from '../LogoUse';

interface IProps {
  handleLogoChange: (id: TLogo) => void;
  smsLogo: boolean;
  hdScanLogo: boolean;
  clientLogo: boolean;
}

export const ReportLogo = ({ smsLogo, hdScanLogo, clientLogo, handleLogoChange }: IProps) => {
  useGetLogosQuery();
  const { setMessageModal } = useActionCreators();
  const [clientLogoState, setClientLogoState] = useState<string | null>(null);
  const clientLogoImage = useAppSelector((state) => selectLogoImage(state, LOGOS_IDS.clientLogo));
  const [setClientSettingsTrigger] = useSetClientSettingsMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleLogoChangeClick = () => {
    fileInputRef.current?.click();
  };

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = !!event.target.files && event.target.files[0];
    event.target.value = '';

    if (!fileUploaded) {
      setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));

      return;
    }

    setClientLogoState(URL.createObjectURL(fileUploaded));
  };

  useEffect(() => {
    const getAdditionalLogoString = async () => {
      const clientLogo = await getBase64Snapshot(`#${LOGOS_IDS.clientLogo}`);
      setClientSettingsTrigger({ clientLogo: getShortBase64String(clientLogo) });
    };

    if (clientLogoState) getAdditionalLogoString();
  }, [clientLogoState]);

  const logosInfo = [
    {
      id: LOGOS_IDS.smsLogo,
      title: 'report.use-sms-logo',
      checked: smsLogo,
      disabled: smsLogo && !hdScanLogo && !clientLogo,
    },
    {
      id: LOGOS_IDS.hdScanLogo,
      title: 'report.use-hdscan-logo',
      checked: hdScanLogo,
      disabled: !smsLogo && hdScanLogo && !clientLogo,
    },
    {
      id: LOGOS_IDS.clientLogo,
      title: 'report.use-client-logo',
      checked: clientLogo,
      disabled: !smsLogo && !hdScanLogo && clientLogo,
      src: clientLogoState,
    },
  ];

  return (
    <div className="report__logo__section">
      <div className="report__logo__change">
        {logosInfo.map((logoInfo) => (
          <LogoUse key={logoInfo.id} {...logoInfo} onChange={handleLogoChange} />
        ))}
      </div>
      <input type="file" ref={fileInputRef} onChange={handleLogoUpload} style={{ display: 'none' }} accept="image/*" />
      <Button title={clientLogoImage ? 'report.change-logo' : 'report.add-logo'} onClick={handleLogoChangeClick} />
    </div>
  );
};
