import './styles.scss';

import classNames from 'classnames';
import React, { memo } from 'react';

import { useActionCreators } from '../../../hooks';
import { useRemoveSnapshotMutation } from '../../../store/api/snaphot';
import { ContextMenu } from '../../UI/ContextMenu';
import { useContextMenu } from '../../UI/ContextMenu/useContextMenu';

interface IProps {
  name: string;
  active: boolean;
  description: string;
}

export const ImagesFileItem = memo(function ImagesFileItem({ name, description, active }: IProps) {
  const [removeSnapshot] = useRemoveSnapshotMutation();
  const { clicked, setClicked, coords, setCoords } = useContextMenu();
  const { setSelectedSnapshotId } = useActionCreators();

  const classes = classNames('images-list__item', active && 'active');

  const handleClick = () => {
    setSelectedSnapshotId(name);
  };

  const handleRightClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();

    setClicked(true);
    setCoords({ x: e.pageX, y: e.pageY });
  };

  const handleDelete = () => {
    removeSnapshot(name);
  };

  return (
    <li className={classes} onClick={handleClick} onContextMenu={handleRightClick}>
      <span>{name}</span>
      <span>{description}</span>
      {clicked && <ContextMenu {...coords} menuList={[{ name: 'common.remove', onClick: handleDelete }]} />}
    </li>
  );
});
