import { IScanData } from '../store/api/scandata/types';

type IFileTableFields = Pick<IScanData, 'name' | 'description' | 'createdAt' | 'fileType'>;
export type IFileTableSortedFields = Pick<IFileTableFields, 'name' | 'createdAt'>;

export const FILE_TYPES = {
  image: 'Image',
  volume: 'IDV',
} as const;

export const FILE_TYPE_VALUES = Object.values(FILE_TYPES);

export const fileTableColumns = [
  {
    key: 'Name',
    field: 'name' as keyof IFileTableFields,
    title: 'Name',
    width: '30%',
  },
  {
    key: 'FileType',
    field: 'fileType' as keyof IFileTableFields,
    title: 'File type',
    width: '15%',
  },
  {
    key: 'CreatedAt',
    field: 'createdAt' as keyof IFileTableFields,
    title: 'common.created-at',
    width: '20%',
  },
  {
    key: 'Description',
    field: 'description' as keyof IFileTableFields,
    title: 'common.description',
    width: '35%',
  },
];

export const fileTableColumnsWithRecipes = [
  fileTableColumns[0],
  fileTableColumns[1],
  {
    key: 'Recipes',
    field: 'recipes' as keyof IFileTableFields,
    title: 'Methods',
    width: '40%',
  },
  fileTableColumns[2],
];

export const DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE = null;
