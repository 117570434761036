import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { FLAWS_ANALYSIS } from '../../consts';
import { ICircleParams, IRectCoordinates, TFlawsAnalysis } from '../../types';
import { IFlawsData } from '../api/flaws/types';
import { IPolygonCoordinates } from './types';

interface IInitialState extends IFlawsData {
  allowAreaModification: boolean;
  isHeightAnalysis: boolean;
  isZonesReset: boolean;
  isFilterActive: boolean;
  analysis: TFlawsAnalysis;
  selectedPolygon: IPolygonCoordinates | null;
  selectedRegionId: number;
  isZoneInFocus: boolean;
  isZoneDeletion: boolean;
}

export interface IFlawsRectParamsPayload {
  index: number;
  params: Partial<IRectCoordinates>;
}

const initialState: IInitialState = {
  analysis: FLAWS_ANALYSIS.sms,
  allowAreaModification: false,
  isHeightAnalysis: false,
  isZonesReset: false,
  isFilterActive: false,
  isZoneDeletion: false,
  threshold: 0,
  polygonsCoordinates: [],
  forbiddenZones: [],
  zonesRectangular: null,
  zonesRound: null,
  statistics: null,
  selectedPolygon: null,
  selectedRegionId: 0,
  isZoneInFocus: false,
};

export const flawsSlice = createSlice({
  name: 'flaws',
  initialState,
  reducers: {
    setFlaws: (state, action: PayloadAction<IFlawsData>) => {
      Object.assign(state, action.payload);
    },
    setFlawsThreshold: (state, action: PayloadAction<number>) => {
      state.threshold = action.payload;
    },
    setFlawsHeightAnalysis: (state) => {
      state.isHeightAnalysis = !state.isHeightAnalysis;
    },
    setFlawsAreaModification: (state) => {
      state.allowAreaModification = !state.allowAreaModification;
    },
    setFlawsSelectedPolygon: (state, action: PayloadAction<IPolygonCoordinates | null>) => {
      state.selectedPolygon = action.payload;
    },
    setFlawsSelectedRegionId: (state, action: PayloadAction<number>) => {
      state.selectedRegionId = action.payload;
    },
    setFlawsZonesReset: (state, action: PayloadAction<boolean>) => {
      state.isZonesReset = action.payload;
    },
    setFlawsActiveFilter: (state) => {
      state.isFilterActive = !state.isFilterActive;
    },
    setFlawsAnalysis: (state, action: PayloadAction<TFlawsAnalysis>) => {
      state.analysis = action.payload;
    },
    addFlawsZone: (state, action: PayloadAction<IRectCoordinates>) => {
      if (state.zonesRectangular && 'zones' in state.zonesRectangular) {
        state.zonesRectangular.zones.push(action.payload);
      }
    },
    setFlawsRectOuterRegion: (state, action: PayloadAction<Partial<IRectCoordinates>>) => {
      if (state.zonesRectangular && 'outerRegion' in state.zonesRectangular) {
        Object.assign(state.zonesRectangular.outerRegion, action.payload);
      }
    },
    setFlawsRoundOuterRegion: (state, action: PayloadAction<Pick<ICircleParams, 'radius'>>) => {
      if (state.zonesRound && 'outerRegion' in state.zonesRound) {
        Object.assign(state.zonesRound.outerRegion, action.payload);
      }
    },
    setFlawsRectInnerRegion: (state, action: PayloadAction<Partial<IRectCoordinates>>) => {
      if (state.zonesRectangular && 'innerRegion' in state.zonesRectangular) {
        Object.assign(state.zonesRectangular.innerRegion, action.payload);
      }
    },
    setFlawsRoundInnerRegion: (state, action: PayloadAction<Pick<ICircleParams, 'radius'>>) => {
      if (state.zonesRound && 'innerRegion' in state.zonesRound) {
        Object.assign(state.zonesRound.innerRegion, action.payload);
      }
    },
    setFlawsRectCRegion: (state, action: PayloadAction<Partial<IRectCoordinates>>) => {
      if (state.zonesRectangular && 'regionC' in state.zonesRectangular) {
        Object.assign(state.zonesRectangular.regionC, action.payload);
      }
    },
    setFlawsRoundCRegion: (state, action: PayloadAction<Pick<ICircleParams, 'radius'>>) => {
      if (state.zonesRound && 'regionC' in state.zonesRound) {
        Object.assign(state.zonesRound.regionC, action.payload);
      }
    },
    setFlawsRectRRegion: (state, action: PayloadAction<Partial<IRectCoordinates>>) => {
      if (state.zonesRectangular && 'regionR' in state.zonesRectangular) {
        Object.assign(state.zonesRectangular.regionR, action.payload);
      }
    },
    setFlawsRoundRRegion: (state, action: PayloadAction<Pick<ICircleParams, 'radius'>>) => {
      if (state.zonesRound && 'regionR' in state.zonesRound) {
        Object.assign(state.zonesRound.regionR, action.payload);
      }
    },
    setFlawsRectSRegion: (state, action: PayloadAction<Partial<IRectCoordinates>>) => {
      if (state.zonesRectangular && 'regionS' in state.zonesRectangular) {
        Object.assign(state.zonesRectangular.regionS, action.payload);
      }
    },
    setFlawsRoundSRegion: (state, action: PayloadAction<Pick<ICircleParams, 'radius'>>) => {
      if (state.zonesRound && 'regionS' in state.zonesRound) {
        Object.assign(state.zonesRound.regionS, action.payload);
      }
    },
    setFlawsRectZones: (state, action: PayloadAction<IFlawsRectParamsPayload>) => {
      if (state.zonesRectangular && 'zones' in state.zonesRectangular) {
        Object.assign(state.zonesRectangular.zones[action.payload.index], action.payload.params);
      }
    },
    resetFlawsZones: (state) => {
      state.zonesRectangular = null;
      state.zonesRound = null;
    },
    setFlawsZoneInFocus: (state, action: PayloadAction<boolean>) => {
      state.isZoneInFocus = action.payload;
    },
    setFlawsZoneDeletion: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isZoneDeletion = !state.isZoneDeletion;
        return;
      }

      state.isZoneDeletion = action.payload;
    },
  },
});

export const { actions: flawsActions, reducer: flaws } = flawsSlice;
