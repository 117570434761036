import React, { FC } from 'react';

import { useCracks } from '../../../../../hooks';
import { TTupleOfTwoNumbers } from '../../../../../types';
import { ICartesianModeParams } from '../../../../../utils';
import { BilletCutRectZones } from './BilletCutRectZones';
import { BilletCutRoundZones } from './BilletCutRoundZones';
import { BilletRectZones } from './BilletRectZones';
import { BilletRoundZones } from './BilletRoundZones';
import { SlabZones } from './SlabZones';

interface IProps {
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx: number;
  billetCutDy: number;
}

export const CracksZones: FC<IProps> = ({ domains, cartesianModeParams, billetCutDx, billetCutDy }) => {
  const { slabZones, billetRectZones, billetRoundZones, billetCutRectZones, billetCutRoundZones } = useCracks();

  return (
    <>
      {slabZones && <SlabZones data={slabZones} domains={domains} cartesianModeParams={cartesianModeParams} />}
      {billetRectZones && (
        <BilletRectZones data={billetRectZones} domains={domains} cartesianModeParams={cartesianModeParams} />
      )}
      {billetRoundZones && (
        <BilletRoundZones data={billetRoundZones} domains={domains} cartesianModeParams={cartesianModeParams} />
      )}
      {billetCutRectZones && (
        <BilletCutRectZones
          data={billetCutRectZones}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
          domains={domains}
          cartesianModeParams={cartesianModeParams}
        />
      )}
      {billetCutRoundZones && (
        <BilletCutRoundZones
          data={billetCutRoundZones}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
          domains={domains}
          cartesianModeParams={cartesianModeParams}
        />
      )}
    </>
  );
};
