import './styles.scss';
import 'react-image-crop/dist/ReactCrop.css';

import { TranslatedText } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components/Button/Button';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { PercentCrop } from 'react-image-crop';

import { IMAGE_BLOB_TYPE } from '../../consts';
import { useAppSelector } from '../../hooks';
import { usePasteSnapshotMutation } from '../../store/api/snaphot';
import { selectedSnapshotId, selectSnapshots, selectVolumeId } from '../../store/selectors';
import { convertFileToBase64, copyToClipboard, getCroppedImg, pasteFromClipboard } from '../../utils';

const initialCropState: PercentCrop = {
  unit: '%',
  width: 90,
  height: 90,
  x: 5,
  y: 5,
};

export const ImagesSnapshot = () => {
  const [isCancelDisabled, setIsCancelDisabled] = useState(true);
  const [firstCropClick, setFirstCropClick] = useState(true);
  const [crop, setCrop] = useState(initialCropState);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const volumeId = useAppSelector(selectVolumeId);
  const imagesList = useAppSelector((state) => selectSnapshots(state, volumeId));
  const activeSnapshotId = useAppSelector(selectedSnapshotId);
  const [pasteSnapshot] = usePasteSnapshotMutation();

  const originalImg = useRef<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const saveFileAnchorRef = useRef<HTMLAnchorElement>(null);
  const imgCropRef = useRef<HTMLImageElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const shownImage = imagesList && activeSnapshotId && imagesList[activeSnapshotId];

  useEffect(() => {
    originalImg.current = imagesList?.[activeSnapshotId];
    setIsCancelDisabled(true);
  }, [activeSnapshotId]);

  useEffect(() => {
    setFirstCropClick(true);
    setCrop(initialCropState);
  }, [activeSnapshotId, imagesList]);

  useEffect(() => {
    if (imgRef.current) {
      setImgSize({
        width: imgRef.current.naturalWidth,
        height: imgRef.current.naturalHeight,
      });
    }
  }, [activeSnapshotId, imgRef.current]);

  const handleCopyToClipboardClick = async () => {
    await copyToClipboard(shownImage, IMAGE_BLOB_TYPE);
  };

  const updateSnapshot = (originalImageForceUpdate = false) => async (imageBase64String: string) => {
    await pasteSnapshot({
      name: activeSnapshotId,
      imageBase64String,
    });

    if (!originalImageForceUpdate) return;

    originalImg.current = imageBase64String;
  };

  const handlePasteFromClipboardClick = async () => {
    await pasteFromClipboard(IMAGE_BLOB_TYPE, updateSnapshot(true));
  };

  const handlePasteFromFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = !!event.target.files && event.target.files[0];
    event.target.value = '';

    if (!fileUploaded) return;

    convertFileToBase64(fileUploaded, updateSnapshot(true));
  };

  const handleSaveToFileClick = () => {
    if (!shownImage || !saveFileAnchorRef.current) return;

    saveFileAnchorRef.current.href = shownImage;
    saveFileAnchorRef.current.download = `${activeSnapshotId}.png`;
    saveFileAnchorRef.current.click();
  };

  const handleCropClick = async () => {
    if (firstCropClick) {
      setFirstCropClick(false);
      return;
    }

    const imageBase64String = getCroppedImg(imgCropRef.current, crop);
    if (!imageBase64String) return;

    await updateSnapshot()(imageBase64String);
    setIsCancelDisabled(false);
    setFirstCropClick(true);
  };

  const handleCancelClick = async () => {
    if (originalImg.current) {
      await updateSnapshot()(originalImg.current);
    }
    setIsCancelDisabled(true);
  };

  return (
    <div className="images-snapshot__container">
      <h3 className="images-snapshot__title">
        <TranslatedText textKey="Image" />
      </h3>
      {shownImage && (
        <>
          <div className="images-snapshot__actions">
            <div className="images-snapshot__buttons">
              <Button title="images.paste-from-clipboard" onClick={handlePasteFromClipboardClick} />
              <Button title="images.copy-to-clipboard" onClick={handleCopyToClipboardClick} />
              <Button title="images.paste-from-file" onClick={handlePasteFromFileClick} />
              <Button title="images.save-to-file" onClick={handleSaveToFileClick} />
              <Button title="common.crop" onClick={handleCropClick} />
              <Button title="common.cancel" onClick={handleCancelClick} disabled={isCancelDisabled} />
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
              <a ref={saveFileAnchorRef} style={{ display: 'none' }} />
            </div>
            <span>
              {imgSize.width} x {imgSize.height}, PNG
            </span>
          </div>
          <div className="images-snapshot__snapshot">
            {firstCropClick ? (
              <img src={shownImage} alt="Screenshot" ref={imgRef} />
            ) : (
              <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}>
                <img src={shownImage} ref={imgCropRef} alt="Crop" />
              </ReactCrop>
            )}
          </div>
        </>
      )}
    </div>
  );
};
