import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { ICracksBilletCutRoundZones } from '../../../../../../store/api/cracks/types';
import { TTupleOfTwoNumbers } from '../../../../../../types';
import { ICartesianModeParams } from '../../../../../../utils';
import { Circle } from '../Circle';

interface IProps {
  data: ICracksBilletCutRoundZones;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx: number;
  billetCutDy: number;
}

export const BilletCutRoundZones: FC<IProps> = ({ data, domains, cartesianModeParams, billetCutDx, billetCutDy }) => {
  const { centerPoint, innerZone, middleZone, outerZone, fullSampleCircle } = data;

  return (
    <>
      <Circle
        data={fullSampleCircle.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={fullSampleCircle.radius}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Circle
        data={innerZone.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={innerZone.radius}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Circle
        data={middleZone.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={middleZone.radius}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Circle
        data={outerZone.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={outerZone.radius}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Circle
        data={centerPoint}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={1}
        fill={ORANGE_PRIMARY}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
    </>
  );
};
