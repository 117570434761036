interface IGeometryTabs {
  base: string;
  aLines: string;
  bLines: string;
  cLines: string;
  diagonals: string;
  dimensions: string;
  cut: string;
}

export const GEOMETRY_RECT_TABS: Omit<IGeometryTabs, 'cut'> = {
  dimensions: 'Geometry dimensions',
  aLines: 'A Lines',
  bLines: 'B Lines',
  cLines: 'C Lines',
  diagonals: 'Diagonals',
  base: 'Base',
};

export const AUTO_ANALYSIS_GEOMETRY_RECT_TABS: Omit<IGeometryTabs, 'cut' | 'base' | 'diagonals'> = {
  dimensions: 'Geometry dimensions',
  aLines: 'A Lines',
  bLines: 'B Lines',
  cLines: 'C Lines',
};

export const GEOMETRY_ROUND_TABS: Pick<IGeometryTabs, 'base' | 'diagonals'> = {
  diagonals: 'Diagonals',
  base: 'Base',
};

export const GEOMETRY_CUT_TABS: Pick<IGeometryTabs, 'base' | 'cut'> = {
  cut: 'Billet Cut',
  base: 'Base',
};

export const GEOMETRY_TABS_TITLES = {
  [GEOMETRY_RECT_TABS.dimensions]: 'geometry.geometry-dimensions',
  [GEOMETRY_RECT_TABS.aLines]: 'geometry.a-lines',
  [GEOMETRY_RECT_TABS.bLines]: 'geometry.b-lines',
  [GEOMETRY_RECT_TABS.cLines]: 'geometry.c-lines',
  [GEOMETRY_RECT_TABS.diagonals]: 'geometry.diagonals',
  [GEOMETRY_RECT_TABS.base]: 'geometry.base',
  [GEOMETRY_CUT_TABS.cut]: 'slice-type.billet-cut',
};
