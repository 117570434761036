import './styles.scss';

import { Modal, TranslatedText } from '@sms/plasma-ui';
import React, { useMemo } from 'react';

import { autoAnalysisStartedColumns } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useFilesSearch } from '../../../hooks/useFilesSearch';
import { selectRecipesSelectedRecipes } from '../../../store/selectors';
import { IRecipeForFiles } from '../../../types';
import { Table } from '../../UI/Table';
import { TableRow } from '../../UI/Table/TableRow';
import { TableCell } from '../../UI/Table/TableRow/TableCell';

interface IProps {
  isVisible: boolean;
}

export const AutomaticAnalysisStartedModal = ({ isVisible }: IProps) => {
  const { setAutomaticAnalysisStartedVisible, resetRecipeSelectedRecipe } = useActionCreators();
  const { items, handleSearchFiles } = useFilesSearch();
  const selectedRecipes = useAppSelector(selectRecipesSelectedRecipes);

  const recipesForFiles = useMemo(() => {
    if (!items?.length) return;

    const selectedRecipesIds = selectedRecipes.map(({ id }) => id);

    return items.reduce((acc, item) => {
      const { recipes, name } = item;
      const selectedRecipesInFile = recipes.filter((recipe) => selectedRecipesIds.includes(recipe.id));

      for (const recipe of selectedRecipesInFile) {
        acc.push({ file: name, recipe: recipe.name });
      }

      return acc;
    }, [] as IRecipeForFiles[]);
  }, [selectedRecipes, items]);

  const onOk = () => {
    setAutomaticAnalysisStartedVisible(false);
    handleSearchFiles();
    resetRecipeSelectedRecipe();
  };

  if (!isVisible) return null;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      okText="OK"
      okType="primary"
      onOk={onOk}
      onClose={onOk}
      //@ts-ignore
      title={<TranslatedText textKey={'modals.notification'} />}
      onCancel={onOk}
      visible={isVisible}
      width={700}
      zIndex={15}
      className={'autoanalysis-start__modal'}
    >
      <p>
        <TranslatedText textKey="modals.message.start-analysis" />
      </p>
      {recipesForFiles && recipesForFiles.length > 0 && (
        <Table columns={autoAnalysisStartedColumns}>
          {recipesForFiles.map((row, index) => (
            <TableRow key={index} rowData={row}>
              {autoAnalysisStartedColumns.map(({ field, width, title }) => (
                <TableCell key={title} field={field} width={width} value={row[field]} />
              ))}
            </TableRow>
          ))}
        </Table>
      )}
    </Modal>
  );
};
