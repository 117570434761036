import './styles.scss';

import { Button, navigate } from '@sms/plasma-ui';
import React from 'react';

import { AdditionalFilters } from '../../../components/DatabaseResults/AdditionalFilters';
import { TableController } from '../../../components/DatabaseResults/TableController';
import { DatabaseResultsTable } from '../../../components/DatabaseResultsTable';
import { Modals } from '../../../components/Modals';
import { MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import {
  useExportFilterResultsMutation,
  useGetDbHeightAnalysisMutation,
  useGetDbSnapshotsMutation,
  useSaveDatabaseMutation,
  useUpdateDBAdditionalFiltersMutation,
} from '../../../store/api/database';
import {
  selectDbGraphsSelectedColumns,
  selectDbSelectedReportIds,
  selectHeightAnalysisActiveIds,
  selectSelectedSnapshotsIds,
} from '../../../store/selectors';
import { checkHeightAnalysisActiveIds, checkSelectedSnapshotIds, createModalMessageBody } from '../../../utils';

export const DatabaseResults = () => {
  const {
    updateHeightAnalysisVisibleIds,
    setSnapshotsViewControllerVisible,
    setDbReportVisible,
    setDbExportResultsVisible,
    setMessageModal,
  } = useActionCreators();
  const heightAnalysisActiveIds = useAppSelector(selectHeightAnalysisActiveIds);
  const selectedSnapshotsIds = useAppSelector(selectSelectedSnapshotsIds);
  const selectedColumns = useAppSelector(selectDbGraphsSelectedColumns);
  const selectedReportIds = useAppSelector(selectDbSelectedReportIds);
  const [getDbHeightAnalysisTrigger, { data: heightAnalysisData }] = useGetDbHeightAnalysisMutation({
    fixedCacheKey: 'dbHeightAnalysis',
  });
  const [, { data: databaseResults }] = useSaveDatabaseMutation({ fixedCacheKey: 'databaseResults' });
  const [, { data: dbSnapshots }] = useGetDbSnapshotsMutation({ fixedCacheKey: 'dbSnapshots' });
  const [, { data: additionalFiltersResults }] = useUpdateDBAdditionalFiltersMutation({
    fixedCacheKey: 'additionalFiltersResults',
  });
  const [exportTrigger] = useExportFilterResultsMutation();

  const checkExportButtonState = () => {
    if (!databaseResults || !databaseResults.length) return true;
    return !!(additionalFiltersResults && !additionalFiltersResults.length);
  };

  const makeChartHandler = async () => {
    if (selectedColumns.length) {
      navigate('/database/results/charts');
      return;
    }

    if (checkHeightAnalysisActiveIds(heightAnalysisData, heightAnalysisActiveIds)) {
      updateHeightAnalysisVisibleIds([]);
      await getDbHeightAnalysisTrigger();
    }

    navigate('/database/results/height-analysis');
  };

  const viewSnapshotsHandler = () => {
    if (checkSelectedSnapshotIds(dbSnapshots, selectedSnapshotsIds)) {
      setSnapshotsViewControllerVisible(true);
      return;
    }

    navigate('/database/results/snapshots');
  };

  const exportResultsHandler = async () => {
    if (!dbSnapshots || !dbSnapshots.length) {
      await exportTrigger().then(() => {
        setDbExportResultsVisible(false);
        setMessageModal(
          createModalMessageBody(
            MODAL_STATUS.SUCCESS,
            MODAL_TITLE.notification,
            'The database results have been successfully exported.',
          ),
        );
      });
      return;
    }

    setDbExportResultsVisible(true);
  };

  const setGenerateReportVisible = () => setDbReportVisible(true);

  return (
    <div className="page-container db-container db-results">
      <div className="database-results__controls">
        <AdditionalFilters />
        <TableController />
      </div>
      <DatabaseResultsTable />
      <div style={{ display: 'flex', gap: '8px' }}>
        <Button title={'Back to the primary filters'} onClick={() => navigate('/database')} />
        <Button title={'Export results'} disabled={checkExportButtonState()} onClick={exportResultsHandler} />
        <Button
          title={'Make a chart'}
          disabled={selectedColumns.length ? false : !heightAnalysisActiveIds.length}
          onClick={makeChartHandler}
        />
        <Button title={'Snapshots'} disabled={!selectedSnapshotsIds.length} onClick={viewSnapshotsHandler} />
        <Button
          title={'report.generate-report'}
          disabled={!selectedReportIds.length}
          onClick={setGenerateReportVisible}
        />
      </div>
      <Modals />
    </div>
  );
};
