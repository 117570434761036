import { useRouter } from '@sms/plasma-ui';
import { useCallback, useMemo } from 'react';

import { NumberInputValue } from '../components/RangeBar';
import { BACKWALL_TABS, INPUT_NAMES, SCAN_VIEWS } from '../consts';
import { useSetDistanceDataMutation } from '../store/api/backwall';
import { ISampleDataParameters } from '../store/api/scandata/types';
import { useGetAScanMutation, useSetProjectionsDataMutation } from '../store/api/slice';
import { IAScanParams } from '../store/api/slice/types';
import { selectBackwallTab, selectIsMaxProjection, selectSliderRange } from '../store/selectors';
import { TypedRootState } from '../store/types';
import { TTupleOfTwoNumbers } from '../types';
import { ISliderRange } from '../types/scanData';
import { checkAppRoute, createProjectionDataBody, debounce, getSliceSize, getToFixedValue } from '../utils';
import { useActionCreators } from './useActionCreators';
import { useAppSelector } from './useAppSelector';
import { useBackwall } from './useBackwall';
import { useCracks } from './useCracks';
import { useFlaw } from './useFlaw';
import { useSegregation } from './useSegregation';

export const useScanViewRangeSlider = (scanView: string, scanDataParameters: ISampleDataParameters) => {
  const {
    setMaxProjectionSliderRange,
    setSingleLayerSliderRange,
    setBackwallMaxProjectionSliderRange,
    setBackwallSingleLayerSliderRange,
  } = useActionCreators();

  const isMaxProjection = useAppSelector(selectIsMaxProjection);
  const backwallTab = useAppSelector(selectBackwallTab);
  const size = useMemo(() => getSliceSize(scanDataParameters, scanView), [scanDataParameters, scanView]);
  // const { start, end } = useAppSelector((state: TypedRootState) =>
  //   isMaxProjection
  //     ? state.sliderRanges.maxProjection[`slice${scanView}` as keyof ISliderRanges]
  //     : state.sliderRanges.singleLayer[`slice${scanView}` as keyof ISliderRanges],
  // );
  const {
    route: { path },
  } = useRouter('route');
  const { isCracks, isBackwall, isSegregation, isFlaws } = checkAppRoute(path);
  const { start, end } = useAppSelector((state: TypedRootState) => selectSliderRange(state, scanView, isBackwall));
  const { handleCracksAnalysisChange } = useCracks();
  const { handleFlawAnalysisChange } = useFlaw();
  const { handleSegParamsChange } = useSegregation();
  const { handleBackwallFlawsChange } = useBackwall();
  const [getAScanTrigger] = useGetAScanMutation({ fixedCacheKey: 'AScan' });
  const [setProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: `slice${scanView}`,
  });
  const [setDistanceImageTrigger] = useSetDistanceDataMutation({
    fixedCacheKey: 'backwallDistance',
  });

  const setProjectionData = useMemo(
    () =>
      debounce(async (ranges: ISliderRange) => {
        if (ranges.start > ranges.end) return;

        if (isBackwall) {
          await setDistanceImageTrigger();

          if (backwallTab === BACKWALL_TABS.flaws) {
            await handleBackwallFlawsChange();
          }

          return;
        }

        await setProjectionDataTrigger(createProjectionDataBody(scanView, ranges));

        if (isSegregation) {
          await handleSegParamsChange();
        }

        if (isFlaws) {
          await handleFlawAnalysisChange();
        }

        if (isCracks) {
          await handleCracksAnalysisChange();
        }
      }, 300),
    [
      scanView,
      isBackwall,
      isCracks,
      isFlaws,
      isSegregation,
      backwallTab,
      handleSegParamsChange,
      handleFlawAnalysisChange,
      handleCracksAnalysisChange,
    ],
  );

  const setSliderRange = useCallback(
    (start: number, end: number) => {
      if (isMaxProjection) {
        if (isBackwall) {
          setBackwallMaxProjectionSliderRange({
            start: getToFixedValue(start),
            end: getToFixedValue(end),
          });

          return;
        }
        setMaxProjectionSliderRange({
          [`slice${scanView}`]: {
            start: getToFixedValue(start),
            end: getToFixedValue(end),
          },
        });

        return;
      }

      if (isBackwall) {
        setBackwallSingleLayerSliderRange({
          start: getToFixedValue(start),
          end: getToFixedValue(size),
        });

        return;
      }

      setSingleLayerSliderRange({
        [`slice${scanView}`]: {
          start: getToFixedValue(start),
          end: getToFixedValue(size),
        },
      });
    },
    [isMaxProjection, isBackwall],
  );

  const aScanTriggerHandler = useMemo(
    () =>
      debounce((param: Partial<IAScanParams>) => {
        getAScanTrigger({
          ...param,
        });
      }, 200),
    [],
  );

  const fetchAScanData = useCallback(
    (param: number) => {
      if (isMaxProjection || scanView === SCAN_VIEWS.C) return;

      aScanTriggerHandler({
        [`range${scanView}`]: param,
      });
    },
    [isMaxProjection, scanView],
  );

  const handleNumberInputChange = useCallback(
    async (value: NumberInputValue, name: string): Promise<void> => {
      if (typeof value !== 'number') return;

      if (name === INPUT_NAMES.upper) {
        if (value < start) return;

        if (value > size) {
          setSliderRange(start, size);
          setProjectionData({ start, end: size });
          fetchAScanData(start);
          return;
        }

        setSliderRange(start, value);
        setProjectionData({ start, end: value });
        fetchAScanData(start);
      }

      if (name === INPUT_NAMES.lower && size) {
        if (value > end) return;
        if (value < 0) return;

        setSliderRange(value, end);
        setProjectionData({ start: value, end });
        fetchAScanData(value);
      }
    },
    [size, setSliderRange, start, end, setProjectionData, fetchAScanData],
  );

  const handleSliderRangeChange = useCallback(
    (params: TTupleOfTwoNumbers): void => {
      setSliderRange(...params);
      setProjectionData({ start: params[0], end: params[1] });
      fetchAScanData(params[0]);
    },
    [setSliderRange, scanView, isMaxProjection, setProjectionData, fetchAScanData],
  );

  return {
    size,
    range: { start, end },
    isMaxProjection,
    handleNumberInputChange,
    handleSliderRangeChange,
  };
};
