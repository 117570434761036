import { Checkbox, NumberInput, TextInput, TranslatedText } from '@sms/plasma-ui';
import React, { ChangeEvent, Fragment } from 'react';

import {
  DATABASE_BACKWALL_TYPES_ENTRIES,
  DATABASE_CRACK_TYPES_ENTRIES,
  DATABASE_FIELDS_TITLES,
  DATABASE_FIELDS_WITH_CLASS,
  DATABASE_RESULT_PARAMETERS_FIELDS,
  DATABASE_SECTIONS_TITLE,
} from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectDBAnalysisFilters } from '../../../store/selectors';
import { DBSliceType, typedEntries } from '../../../types';
import { isStartEndNumberInputValid } from '../../../utils';
import { BDDateRangeInput } from '../DBDateRangeInput';
import { DBCheckboxList } from './DBCheckboxList';

export const DBResultParameters = () => {
  const {
    setDatabaseRangeValues,
    setDatabaseSliceTypes,
    setDatabaseASTMClasses,
    setDatabaseControllerName,
  } = useActionCreators();
  const db = useAppSelector(selectDBAnalysisFilters);

  const handleControllerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDatabaseControllerName(e.target.value);
  };

  const handleSliceTypeChange = (sliceType: DBSliceType) => {
    setDatabaseSliceTypes(sliceType);
  };

  const handleNumberInputChange = (
    value: number | string | undefined,
    section: string,
    key: string,
    range: 'start' | 'end',
  ) => {
    if (value === undefined || typeof value === 'string' || +value < 0) return;

    setDatabaseRangeValues({ section, key, range, value });
  };

  const handleNumberInputBlur = (section: string, key: string, range: 'start' | 'end') => {
    //@ts-ignore
    const endValue = DATABASE_FIELDS_WITH_CLASS.includes(key) ? db[section][key].class.end : db[section][key].end;
    const startValue = DATABASE_FIELDS_WITH_CLASS.includes(key)
      ? //@ts-ignore
        db[section][key].class.start
      : //@ts-ignore
        db[section][key].start;
    const isInputsValid = isStartEndNumberInputValid(startValue, endValue);

    if (isInputsValid) return;

    if (range === 'start') {
      setDatabaseRangeValues({ section, key, range: 'end', value: startValue });
    }

    if (range === 'end') {
      setDatabaseRangeValues({ section, key, range: 'start', value: endValue });
    }
  };

  const handleASTMNumberInputChange = (
    value: number | string | undefined,
    section: string,
    key: string,
    className: string,
    range: 'start' | 'end',
  ) => {
    if (value === undefined || typeof value === 'string' || +value < 0) return;

    setDatabaseRangeValues({ section, key: `${key}.${className}`, range, value });
  };

  const handleASTMNumberInputBlur = (section: string, key: string, className: string, range: 'start' | 'end') => {
    //@ts-ignore
    const endValue = db[section][key][className].end;
    //@ts-ignore
    const startValue = db[section][key][className].start;
    const isInputsValid = isStartEndNumberInputValid(startValue, endValue);

    if (isInputsValid) return;

    if (range === 'start') {
      setDatabaseRangeValues({ section, key: `${key}.${className}`, range: 'end', value: startValue });
    }

    if (range === 'end') {
      setDatabaseRangeValues({ section, key: `${key}.${className}`, range: 'start', value: endValue });
    }
  };

  const handleASTMClassCheckboxChange = (section: string, key: string, astmClass: string) => {
    setDatabaseASTMClasses({ section, key, astmClass });
  };

  return (
    <div className="db__form-section">
      <h3 className="side-menu__title">
        <TranslatedText textKey="db.search-parameters" />
      </h3>
      <div className="db__form">
        <BDDateRangeInput
          section="analysisResultDateFilter"
          values={db.analysisResultDateFilter.createdAt}
          label="Analysis date"
        />
        <TextInput
          label={'Controller'}
          name={'controllerName'}
          value={db.analysisResultDateFilter.controllerName.value}
          onChange={handleControllerChange}
        />
        {typedEntries(DATABASE_RESULT_PARAMETERS_FIELDS).map(([section, values]) => (
          <Fragment key={section}>
            <h3 className="sub-menu__title">
              <TranslatedText textKey={DATABASE_SECTIONS_TITLE[section]} />
            </h3>
            {section === 'backwallDistanceFilter' ? (
              <div className="db__form-choices">
                <DBCheckboxList
                  list={DATABASE_BACKWALL_TYPES_ENTRIES}
                  values={db.backwallDistanceFilter}
                  section={section}
                />
              </div>
            ) : (
              <>
                {typedEntries(values).map(([key, inputsObj]) => {
                  if (key === 'sliceTypes') {
                    return (
                      <div className="db__form-input" key={key}>
                        <p>
                          <TranslatedText textKey={DATABASE_FIELDS_TITLES[key]} />
                        </p>
                        <div className="db__form-choices">
                          {typedEntries(inputsObj as Record<DBSliceType, string>).map(([sliceType, label]) => (
                            <Checkbox
                              key={sliceType}
                              name={sliceType}
                              checked={db.geometryFilter.sliceTypes.includes(sliceType)}
                              title={label}
                              onChange={() => handleSliceTypeChange(sliceType)}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  }

                  if (key === 'cracksDetectionFilter') {
                    return (
                      <div className="db__form-input" key={key}>
                        <p>
                          <TranslatedText textKey={DATABASE_FIELDS_TITLES[key]} />
                        </p>
                        <DBCheckboxList
                          list={DATABASE_CRACK_TYPES_ENTRIES}
                          values={db.cracksFilter.cracksDetectionFilter}
                          section={section}
                        />
                      </div>
                    );
                  }

                  if (typeof inputsObj === 'object' && 'classR' in inputsObj) {
                    return (
                      <div className="db__form-input" key={key}>
                        <p>
                          <TranslatedText
                            textKey={DATABASE_FIELDS_TITLES[key as keyof typeof DATABASE_FIELDS_TITLES]}
                          />
                        </p>
                        <div className="db__form-input">
                          {typedEntries(inputsObj).map(([astmClass, rangesObj]) => (
                            <div key={astmClass}>
                              <Checkbox
                                name={astmClass}
                                //@ts-ignore
                                checked={!!db[section][key][astmClass]}
                                title={DATABASE_FIELDS_TITLES[astmClass]}
                                onChange={() => handleASTMClassCheckboxChange(section, key, astmClass)}
                              />
                              <div className="db__form-choices">
                                {typedEntries(rangesObj).map(([range, label]) => (
                                  <NumberInput
                                    key={`${key}${astmClass}${range}`}
                                    //@ts-ignore
                                    value={db[section][key][astmClass]?.[range] || 0}
                                    name={`${key}${range}`}
                                    label={label}
                                    //@ts-ignore
                                    disabled={!db[section][key][astmClass]}
                                    onChange={(value) =>
                                      handleASTMNumberInputChange(value, section, key, astmClass, range)
                                    }
                                    onBlur={() => handleASTMNumberInputBlur(section, key, astmClass, range)}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }

                  if (typeof inputsObj === 'object' && 'start' in inputsObj && 'end' in inputsObj) {
                    return (
                      <div className="db__form-input" key={key}>
                        <p>
                          <TranslatedText
                            textKey={DATABASE_FIELDS_TITLES[key as keyof typeof DATABASE_FIELDS_TITLES]}
                          />
                        </p>
                        <div className="db__form-choices">
                          {typedEntries(inputsObj).map(([range, label]) => (
                            <NumberInput
                              key={`${key}${range}`}
                              value={
                                DATABASE_FIELDS_WITH_CLASS.includes(key)
                                  ? //@ts-ignore
                                    db[section][key].class[range]
                                  : //@ts-ignore
                                    db[section][key][range]
                              }
                              name={`${key}${range}`}
                              label={label}
                              onChange={(value) => handleNumberInputChange(value, section, key, range)}
                              onBlur={() => handleNumberInputBlur(section, key, range)}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
