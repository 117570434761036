import './styles.scss';

import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { MouseEvent, useEffect } from 'react';

import {
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  BUTTON_NAMES,
  FILE_TYPES,
  FLAWS_ANALYSIS,
  GREY_SECONDARY_EXTRA_LIGHT,
  WHITE_PRIMARY,
} from '../../../consts';
import { useActionCreators, useAppSelector, useFlaw } from '../../../hooks';
import {
  useAddSMSZoneMutation,
  useLazyGetFlawsHeightAnalysisDataQuery,
  useRemoveSMSZoneMutation,
} from '../../../store/api/flaws';
import {
  selectFileType,
  selectFlawsAllowAreaModification,
  selectFlawsAnalysis,
  selectFlawsRectZones,
  selectFlawsSelectedRegionId,
  selectIsFlawsFilterActive,
  selectIsFlawsHeightAnalysis,
  selectIsFlawsZoneDeletion,
  selectIsFlawsZonesReset,
  selectVolumeId,
} from '../../../store/selectors';

export const FlawsZoneControl = () => {
  const {
    setFlawsHeightAnalysis,
    setFlawsAreaModification,
    setFlawsZonesReset,
    setFlawsActiveFilter,
    setFlawsSelectedRegionId,
    setFlawsZoneInFocus,
  } = useActionCreators();
  const { handleFlawAnalysisChange } = useFlaw();
  const volumeId = useAppSelector(selectVolumeId);
  const fileType = useAppSelector(selectFileType);
  const flawsAnalysis = useAppSelector(selectFlawsAnalysis);
  const rectZones = useAppSelector(selectFlawsRectZones);
  const allowAreaModification = useAppSelector(selectFlawsAllowAreaModification);
  const selectedRegionId = useAppSelector(selectFlawsSelectedRegionId);
  const isHeightAnalysis = useAppSelector(selectIsFlawsHeightAnalysis);
  const isZoneDeletion = useAppSelector(selectIsFlawsZoneDeletion);
  const isZonesReset = useAppSelector(selectIsFlawsZonesReset);
  const isActiveFilter = useAppSelector(selectIsFlawsFilterActive);
  const [addSMSZoneTrigger] = useAddSMSZoneMutation();
  const [removeSMSZoneTrigger] = useRemoveSMSZoneMutation();
  const [heightAnalysisTrigger] = useLazyGetFlawsHeightAnalysisDataQuery();

  const isSMSFlawsAnalysis = flawsAnalysis === FLAWS_ANALYSIS.sms;
  // eslint-disable-next-line no-nested-ternary
  const zoneAmount = rectZones ? ('zones' in rectZones ? rectZones.zones.length : 0) : 0;

  const buttonClickHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.name === BUTTON_NAMES.heightAnalysis) {
      if (isHeightAnalysis) {
        e.currentTarget.blur();
      }

      setFlawsHeightAnalysis();

      return;
    }

    if (e.currentTarget.name === BUTTON_NAMES.allowAreaModification) {
      if (allowAreaModification) {
        e.currentTarget.blur();
      }

      setFlawsAreaModification();

      return;
    }

    if (e.currentTarget.name === BUTTON_NAMES.zonesReset) {
      if (isZonesReset) {
        e.currentTarget.blur();
      }

      setFlawsZonesReset(true);
      await handleFlawAnalysisChange({ forcedZonesReset: true });

      return;
    }

    if (e.currentTarget.name === BUTTON_NAMES.activeFilter) {
      if (!isSMSFlawsAnalysis) return;

      if (isActiveFilter) {
        e.currentTarget.blur();
      }

      setFlawsActiveFilter();
      await handleFlawAnalysisChange({ forcedFilterActive: !isActiveFilter });

      return;
    }

    if (e.currentTarget.name === BUTTON_NAMES.addZone) {
      if (!isSMSFlawsAnalysis) return;
      if (!rectZones) return;

      const zoneAmount = 'zones' in rectZones ? rectZones.zones.length : 0;

      addSMSZoneTrigger({
        volumeId,
        newRegion: {
          x0: 20 + zoneAmount * 10,
          x1: 120 + zoneAmount * 10,
          y0: 20 + zoneAmount * 10,
          y1: 120 + zoneAmount * 10,
        },
      });

      return;
    }

    if (e.currentTarget.name === BUTTON_NAMES.deleteZone) {
      if (!isSMSFlawsAnalysis) return;
      if (!rectZones) return;

      const zoneAmount = 'zones' in rectZones ? rectZones.zones.length : 0;

      if (zoneAmount === 1) return;

      removeSMSZoneTrigger({
        volumeId,
        id: selectedRegionId,
      })
        .unwrap()
        .then(() => setFlawsSelectedRegionId(0));

      setFlawsZoneInFocus(false);
      setFlawsSelectedRegionId(selectedRegionId === 0 ? 1 : 0);

      return;
    }
  };

  const data = [
    {
      title: 'side-menu.allow-area-modification',
      name: BUTTON_NAMES.allowAreaModification,
      trigger: allowAreaModification,
    },
    {
      title: 'side-menu.add-zone',
      name: BUTTON_NAMES.addZone,
      trigger: false,
      disabled: !isSMSFlawsAnalysis,
    },
    {
      title: 'side-menu.delete-zone',
      name: BUTTON_NAMES.deleteZone,
      trigger: isZoneDeletion,
      disabled:
        // eslint-disable-next-line no-nested-ternary
        !isSMSFlawsAnalysis || zoneAmount <= 1,
    },
    {
      title: 'side-menu.reset-all-zones',
      name: BUTTON_NAMES.zonesReset,
      trigger: false,
    },
    {
      title: 'Height analysis',
      name: BUTTON_NAMES.heightAnalysis,
      trigger: isHeightAnalysis,
      disabled: fileType === FILE_TYPES.image,
    },
    {
      title: 'side-menu.sep-filter-active',
      name: BUTTON_NAMES.activeFilter,
      trigger: false,
      disabled: !isSMSFlawsAnalysis,
    },
  ];

  useEffect(() => {
    if (fileType === FILE_TYPES.image) return;

    heightAnalysisTrigger(volumeId);
  }, [volumeId, fileType]);

  return (
    <div className="flaws__menu__zone-control">
      <h3 className="flaws__menu__title side-menu__title">
        <TranslatedText textKey="side-menu.zone-control" />
      </h3>
      <div className="flaws__menu__zone-control__controls">
        {data.map(({ title, name, trigger, disabled }, idx) => (
          <Button
            key={idx}
            title={title}
            name={name}
            disabled={disabled}
            onClick={buttonClickHandler}
            style={{
              backgroundColor: trigger ? BLUE_PRIMARY : 'transparent',
              borderColor: trigger ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
              color: trigger ? WHITE_PRIMARY : BLACK_PRIMARY,
            }}
          />
        ))}
      </div>
    </div>
  );
};
