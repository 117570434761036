import './styles.scss';

import React, { useEffect, useLayoutEffect } from 'react';

import { PaletteBar } from '../../components/PaletteBar';
import { ProjectionList } from '../../components/ProjectionList';
import { useActionCreators, useAppSelector } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useGetCroppedImagesQuery } from '../../store/api/slice';
import {
  selectBcdplusPrevState,
  selectIsVolume,
  selectMaxProjectionSliderRanges,
  selectPaletteData,
  selectScanDataParameters,
  selectVolumeId,
} from '../../store/selectors';

export const BCDPlus = () => {
  const { setMaxProjection, setBcdplusPrevState } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const sliderRanges = useAppSelector(selectMaxProjectionSliderRanges);
  const palette = useAppSelector(selectPaletteData);
  const bcdplusPrevState = useAppSelector(selectBcdplusPrevState);
  const isVolume = useAppSelector(selectIsVolume);
  useGetCroppedImagesQuery(undefined, {
    skip: !volumeId,
    refetchOnMountOrArgChange:
      !isVolume &&
      (JSON.stringify(sliderRanges) !== JSON.stringify(bcdplusPrevState.sliderRanges) ||
        JSON.stringify(palette) !== JSON.stringify(bcdplusPrevState.palette)),
  });

  useLayoutEffect(() => {
    setMaxProjection(true);
  }, []);

  useEffect(() => {
    return () => {
      setBcdplusPrevState({
        sliderRanges,
        palette,
      });
    };
  }, [sliderRanges, palette]);

  return (
    <DefaultLayout>
      <div className="bcdplus__container">
        {scanDataParameters && (
          <>
            <ProjectionList scanDataParameters={scanDataParameters} />
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
