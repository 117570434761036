import { DEFAULT_ZONES_PARAMS, ORANGE_PRIMARY, RED_PRIMARY } from '../consts';
import {
  IFlawsGOSTRoundZoneFormChangedBody,
  IFlawsRectZoneFormChangedBody,
  IFlawsRoundRegion,
  TFlawsRectZones,
  TFlawsRoundZones,
} from '../store/api/flaws/types';
import { TFlawsDefaultZonesParams } from '../types';

export const getDefaultFlawsRectZoneChangeBody = (
  volumeId: string,
  regionId: number
): IFlawsRectZoneFormChangedBody => ({
  volumeId,
  regionId,
  x1: -1,
  y1: -1,
  width: -1,
  height: -1,
});

export const getDefaultFlawsRoundZoneChangeBody = (
  volumeId: string
): IFlawsGOSTRoundZoneFormChangedBody => ({
  volumeId,
  centerPointX: -1,
  centerPointY: -1,
  outerDiameter: -1,
  innerDiameter: -1,
});

export const setFlawsZoneFill = (
  regionId: number,
  selectedRegionId: number,
  condition: boolean
): string => {
  if (!condition) return ORANGE_PRIMARY;
  if (regionId !== selectedRegionId) return ORANGE_PRIMARY;

  return RED_PRIMARY;
};

export const getFlawsRegionDiameter = (region: IFlawsRoundRegion): number =>
  region.circle.radius * 2;

export const getFlawsRegionParamsById = (
  regionId: number,
  rectZones: TFlawsRectZones | null,
  roundZones: TFlawsRoundZones | null
): TFlawsDefaultZonesParams => {
  const defaultParams = { ...DEFAULT_ZONES_PARAMS };

  if (rectZones) {
    if ('zones' in rectZones) {
      return {
        ...defaultParams,
        ...rectZones.zones[regionId],
      };
    }

    return {
      ...defaultParams,
      ...Object.values(rectZones).find((z) => z.id === regionId).rectangle,
    };
  }

  if (roundZones) {
    const regionParams = Object.entries(roundZones).find(
      (z) => z[1].id === regionId
    );

    let innerDiameter = 0,
      outerDiameter = 0;

    if ('innerRegion' in roundZones) {
      innerDiameter = getFlawsRegionDiameter(roundZones.innerRegion);
    }

    if ('outerRegion' in roundZones) {
      outerDiameter = getFlawsRegionDiameter(roundZones.outerRegion);
    }

    if (
      'regionS' in roundZones &&
      'regionR' in roundZones &&
      'regionC' in roundZones
    ) {
      if (regionParams && regionParams[0] === 'regionS') {
        outerDiameter = getFlawsRegionDiameter(roundZones.regionS);
        innerDiameter = getFlawsRegionDiameter(roundZones.regionR);
      }

      if (regionParams && regionParams[0] === 'regionR') {
        outerDiameter = getFlawsRegionDiameter(roundZones.regionR);
        innerDiameter = getFlawsRegionDiameter(roundZones.regionC);
      }
    }

    return {
      ...defaultParams,
      x: regionParams ? regionParams[1].circle.center.x : 0,
      y: regionParams ? regionParams[1].circle.center.y : 0,
      innerDiameter,
      outerDiameter,
    };
  }

  return defaultParams;
};
