import './styles.scss';

import { navigate } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components';
import React, { FC } from 'react';

import { DBHeightAnalysisTransformedResponse } from '../../../../../store/api/database/types';
import { LegendList } from '../LegendList';

interface Props {
  data?: DBHeightAnalysisTransformedResponse[];
}

export const HeightAnalysisController: FC<Props> = ({ data }) => {
  return (
    <div className="height-analysis-controller">
      {data && <LegendList data={data} />}
      <Button title={'Back to the results table'} onClick={() => navigate('/database/results')} />
    </div>
  );
};
