import { IZonesZone } from '../api/zones/types';
import { TypedRootState } from '../types';

export const selectZonesZoneInsertion = (state: TypedRootState): boolean => state.zones.allowZoneInsertion;
export const selectZonesZoneRemoving = (state: TypedRootState): boolean => state.zones.allowZoneRemoving;
export const selectZonesZoneModifying = (state: TypedRootState): boolean => state.zones.allowZoneModifying;
export const selectZonesAxesRotation = (state: TypedRootState): boolean => state.zones.allowAxesRotation;
export const selectZonesSelectedZoneId = (state: TypedRootState): number | null => state.zones.selectedZoneId;
export const selectZonesUsedInFlaws = (state: TypedRootState): boolean => state.zones.useIn.useInFlaws;
export const selectZonesRotationAngle = (state: TypedRootState): number => state.zones.rotationAngle;
export const selectZonesPrevRotationAngle = (state: TypedRootState): number => state.zones.prevRotationAngle;
export const selectZonesUsedInSegregations = (state: TypedRootState): boolean => state.zones.useIn.useInSegregations;
export const selectZonesUsedInCracks = (state: TypedRootState): boolean => state.zones.useIn.useInCracks;
export const selectZonesUsedInBackwall = (state: TypedRootState): boolean => state.zones.useIn.useInBackwall;
export const selectZonesZones = (state: TypedRootState): IZonesZone[] => state.zones.zones;
