import './styles.scss';

import { useTranslation } from '@sms/plasma-ui';
import React from 'react';

import { CRACKS_TYPES } from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { selectCracksAnalysis, selectCracksStatistics } from '../../../../../store/selectors';
import { StatisticsTableRow } from './StatisticsTableRow';

export const EntireStatistics = () => {
  const { t } = useTranslation();
  const analysis = useAppSelector(selectCracksAnalysis);
  const statistics = useAppSelector(selectCracksStatistics);

  return (
    <div className="entire__statistics">
      <h3 className="sub-menu__title">{t('cracks.statistics', { analysis })}</h3>
      <div className="entire__statistics__table">
        <StatisticsTableRow
          title={t('cracks.cracktype')}
          count={t('side-menu.count')}
          cracksClass={t('side-menu.class')}
          cracksNSClass={statistics?.cracksNsStatistics ? t('Total NS') : null}
        />
        {statistics.statistics.map(({ crackType, cracksClass, cracksCount }, idx) => (
          <StatisticsTableRow
            key={idx}
            title={t(crackType)}
            count={cracksCount}
            cracksClass={cracksClass}
            cracksNSClass={
              statistics.cracksNsStatistics
                ? crackType === CRACKS_TYPES.nearSurface
                  ? statistics.cracksNsStatistics.NearSurface
                  : '-'
                : null
            }
          />
        ))}
        <StatisticsTableRow
          title={t('common.all')}
          count={statistics.allCracksCount}
          cracksClass={statistics.worstCracksClass}
          cracksNSClass={statistics.cracksNsStatistics ? ' ' : null}
        />
      </div>
    </div>
  );
};
