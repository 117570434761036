import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { TableColumn } from '../../../types';

interface IProps<T> {
  children: React.ReactNode;
  columns: TableColumn<T>[];
}

export const Table = <T, >({ children, columns }: IProps<T>) => {
  return (
    <div className="dynamic-table">
      <div className="table-header">
        {columns.map(({ title, width }) => (
          <div className="table-cell" key={title} style={{ width }}>
            <TranslatedText textKey={title} />
          </div>
        ))}
      </div>
      <div className="table-body">{children}</div>
    </div>
  );
};
