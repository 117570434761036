import {
  adaptImageActions,
  analysisStatusActions,
  backwallActions,
  backwallFlawsActions,
  bcdplusPrevStateActions,
  billetCutParamsActions,
  cracksActions,
  cRangeForLoadedGeometryActions,
  croppedImagesActions,
  databaseActions,
  flawsActions,
  flippedActions,
  forceAnalysisChangeActions,
  infoActions,
  modalsActions,
  paletteActions,
  prevAScanDataActions,
  prevSliceImagesActions,
  recipesActions,
  remarksActions,
  reportActions,
  sampleFileNameActions,
  scanDataFiltersActions,
  segregationActions,
  settingsActions,
  sliceTypeActions,
  sliderRangesActions,
  snapshotActions,
  toolbarOptionsActions,
  userActions,
  volumeIdActions,
  zonesActions,
} from '../slices';

export const allActions = {
  ...adaptImageActions,
  ...analysisStatusActions,
  ...cRangeForLoadedGeometryActions,
  ...volumeIdActions,
  ...sliceTypeActions,
  ...sampleFileNameActions,
  ...flippedActions,
  ...remarksActions,
  ...reportActions,
  ...infoActions,
  ...flawsActions,
  ...toolbarOptionsActions,
  ...billetCutParamsActions,
  ...paletteActions,
  ...sliderRangesActions,
  ...modalsActions,
  ...segregationActions,
  ...settingsActions,
  ...scanDataFiltersActions,
  ...zonesActions,
  ...snapshotActions,
  ...prevSliceImagesActions,
  ...prevAScanDataActions,
  ...recipesActions,
  ...cracksActions,
  ...backwallActions,
  ...backwallFlawsActions,
  ...databaseActions,
  ...userActions,
  ...croppedImagesActions,
  ...bcdplusPrevStateActions,
  ...forceAnalysisChangeActions,
};
