import { TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { SEG_ANALYSIS } from '../../../../../consts';
import { TSegZone } from '../../../../../store/api/segregation/types';
import { TSegAnalysis } from '../../../../../types';
import { getToFixedValue } from '../../../../../utils';

interface IProps {
  segZone: TSegZone | null;
  segAnalysis: TSegAnalysis;
}

export const CenterPointRow: FC<IProps> = ({ segZone, segAnalysis }) => {
  return (
    <>
      {segZone && segAnalysis === SEG_ANALYSIS.astm && 'centerPoint' in segZone && (
        <li className="seg__statistics__item">
          <p>
            <TranslatedText textKey="side-menu.center-point" /> [mm]:
          </p>
          <p>{getToFixedValue(segZone.centerPoint.x)}</p>
          <p>{getToFixedValue(segZone.centerPoint.y)}</p>
        </li>
      )}
    </>
  );
};
