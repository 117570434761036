import { SCAN_VIEWS } from '../consts';
import { ISliderRanges } from '../store/slices/types';
import { TTupleOfTwoNumbers } from '../types';

export const getRectParams = (
  scanView: string,
  width: number,
  height: number,
  sliderRanges: ISliderRanges,
  domains: TTupleOfTwoNumbers
): number[] => {
  let w = 0,
    h = 0,
    x = 0,
    y = 0;

  if (sliderRanges) {
    const { sliceB, sliceC, sliceD } = sliderRanges;

    switch (scanView) {
    case SCAN_VIEWS.B:
      x = width / domains[0] * sliceD!.start;
      y = height / domains[1] * sliceC!.start;
      w = width / domains[0] * (sliceD!.end - sliceD!.start);
      h = height / domains[1] * (sliceC!.end - sliceC!.start);
      break;
    case SCAN_VIEWS.C:
      x = width / domains[0] * sliceD!.start;
      y = height / domains[1] * (domains[1] - sliceB!.end);
      w = width / domains[0] * (sliceD!.end - sliceD!.start);
      h = height / domains[1] * (sliceB!.end - sliceB!.start);
      break;
    case SCAN_VIEWS.D:
      x = width / domains[0] * sliceB!.start;
      y = height / domains[1] * sliceC!.start;
      w = width / domains[0] * (sliceB!.end - sliceB!.start);
      h = height / domains[1] * (sliceC!.end - sliceC!.start);
      break;
    default:
      return [x, y, w, h];
    }
  }
  return [x, y, w, h];
};
