import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import classNames from 'classnames';
import React from 'react';

import { FLAWS_ANALYSIS } from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { TFlawsStatistics } from '../../../../store/api/flaws/types';
import { selectFlawsAnalysis } from '../../../../store/selectors';
import { checkAppRoute, getToFixedValue } from '../../../../utils';

interface IProps {
  selectedRegionId: number;
  statistics: TFlawsStatistics | null;
}

export const Statistics = ({ statistics, selectedRegionId }: IProps) => {
  const flawAnalysis = useAppSelector(selectFlawsAnalysis);
  const path = window.location.pathname;
  const { isFlaws } = checkAppRoute(path);
  const isFlawsASTM = flawAnalysis === FLAWS_ANALYSIS.astm && isFlaws;

  const classes = classNames('flaws__statistics', {
    astm__spots: isFlawsASTM,
  });

  return (
    <>
      <div className={classes}>
        <div className="column first-column">
          <ul className="flaws__statistics__list">
            <li className="flaws__statistics__item">
              <p>
                <TranslatedText textKey="side-menu.spots-number" />:
              </p>
            </li>
            {statistics &&
              'segregationsSizes' in statistics &&
              'statistics' in statistics &&
              statistics.segregationsSizes.map((item, idx) => (
                <li key={idx} className="flaws__statistics__item">
                  <p>{item}:</p>
                  <p>{statistics?.statistics[selectedRegionId]?.defectsCounts[idx]}</p>
                </li>
              ))}
            {statistics &&
              'defectsCounts' in statistics &&
              'segregationsSizes' in statistics &&
              statistics.segregationsSizes.map((item, idx) => (
                <li key={idx} className="flaws__statistics__item">
                  <p>{item}:</p>
                  <p>{statistics.defectsCounts[idx]}</p>
                </li>
              ))}
          </ul>
          {statistics && 'statistics' in statistics && isFlawsASTM && (
            <ul className="flaws__statistics__list">
              <li className="flaws__statistics__item">
                <p>
                  <TranslatedText textKey="side-menu.zone" />
                </p>
              </li>
              {statistics.statistics[selectedRegionId].defectsCounts.map((item, idx) => (
                <li key={idx} className="flaws__statistics__item">
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          )}
          {statistics && 'totalDefectsCounts' in statistics && flawAnalysis === FLAWS_ANALYSIS.astm && (
            <ul className="flaws__statistics__list">
              <li className="flaws__statistics__item">
                <p>
                  <TranslatedText textKey="common.total" />
                </p>
              </li>
              {statistics.totalDefectsCounts.map((item, idx) => (
                <li key={idx} className="flaws__statistics__item">
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="column second-column">
          <ul className="flaws__statistics__list">
            {statistics && 'statistics' in statistics && statistics.statistics[selectedRegionId] && (
              <>
                {'segClass' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.segregation-class" />:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        statistics.statistics[selectedRegionId].segClass
                      }
                    </p>
                  </li>
                )}
                {'distribution' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.distribution" />:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        statistics.statistics[selectedRegionId].distribution
                      }
                    </p>
                  </li>
                )}
                {'density' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.density" /> [%]:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        statistics.statistics[selectedRegionId].density
                      }
                    </p>
                  </li>
                )}
                {'zoneClass' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.zone-class" />:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        statistics.statistics[selectedRegionId].zoneClass
                      }
                    </p>
                  </li>
                )}
                {'piClass' in statistics && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.pi-class" />:
                    </p>
                    <p>{statistics.piClass}</p>
                  </li>
                )}
                {'zoneHeight' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="Zone height" /> [mm]:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        getToFixedValue(statistics.statistics[selectedRegionId].zoneHeight)
                      }
                    </p>
                  </li>
                )}
                {'area' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.area" /> [mm<sup>2</sup>]:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        getToFixedValue(statistics.statistics[selectedRegionId].area)
                      }
                    </p>
                  </li>
                )}
                {'normArea' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="side-menu.norm-area" /> [%]:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        getToFixedValue(statistics.statistics[selectedRegionId].normArea)
                      }
                    </p>
                  </li>
                )}
                {'zoneWidth' in statistics.statistics[selectedRegionId] && (
                  <li className="flaws__statistics__item">
                    <p>
                      <TranslatedText textKey="Zone width" /> [mm]:
                    </p>
                    <p>
                      {
                        // @ts-ignore
                        getToFixedValue(statistics.statistics[selectedRegionId].zoneWidth)
                      }
                    </p>
                  </li>
                )}
              </>
            )}
            {statistics && 'defectsFromPerimeterPercent' in statistics && (
              <li className="flaws__statistics__item">
                <p>
                  <TranslatedText textKey="side-menu.perimeter" /> [%]:
                </p>
                <p>
                  {
                    // @ts-ignore
                    getToFixedValue(statistics.defectsFromPerimeterPercent)
                  }
                </p>
              </li>
            )}
            {statistics && 'distribution' in statistics && (
              <li className="flaws__statistics__item">
                <p>
                  <TranslatedText textKey="side-menu.distribution" />:
                </p>
                <p>{statistics.distribution}</p>
              </li>
            )}
            {statistics && 'zoneClass' in statistics && (
              <li className="flaws__statistics__item">
                <p>
                  <TranslatedText textKey="side-menu.class" />:
                </p>
                <p>{statistics.zoneClass}</p>
              </li>
            )}
            {statistics && 'class' in statistics && (
              <li className="flaws__statistics__item">
                <p>
                  <TranslatedText textKey="side-menu.zone-class" />:
                </p>
                <p>{statistics.class}</p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
