import React from 'react';

export const Folder = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6_1700)">
        <path d="M19.1721 6.77654H16.6314V5.09166C16.6314 4.87014 16.4307 4.6905 16.1831 4.6905H10.1064L9.03191 2.64457C8.95801 2.50391 8.79991 2.41406 8.6261 2.41406H1.06921C0.821625 2.41406 0.62085 2.5937 0.62085 2.81522V14.1847C0.62085 14.4035 0.820226 14.5859 1.06946 14.5859C1.06972 14.5859 1.06997 14.5858 1.07022 14.5858H16.1831C16.369 14.5858 16.5356 14.4831 16.602 14.3277L19.591 7.32076C19.703 7.05838 19.4859 6.77654 19.1721 6.77654ZM1.51756 3.21644H8.34144L9.41597 5.26237C9.48987 5.40303 9.64797 5.49288 9.82178 5.49288H15.7347V6.7766H4.05826C3.87236 6.7766 3.70568 6.87931 3.63935 7.03471L1.51756 12.0087V3.21644ZM15.8743 13.7835H1.72025L4.36702 7.57887C5.01304 7.57887 17.8752 7.57887 18.5211 7.57887L15.8743 13.7835Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_6_1700">
          <rect width="19" height="17" fill="white" transform="translate(0.62085)"/>
        </clipPath>
      </defs>
    </svg>
  );
};
