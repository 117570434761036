import { APP_ROUTES } from '../consts';

export const checkAppRoute = (path: string) => {
  const route = path.slice(1);

  return {
    isAdaptImage: route === APP_ROUTES.adaptImage,
    isDb: route === APP_ROUTES.db,
    isDbResults: route === APP_ROUTES.dbResults,
    isSegregation: route === APP_ROUTES.segregation,
    isFlaws: route === APP_ROUTES.flaws,
    isGeometry: route === APP_ROUTES.geometry,
    isCracks: route === APP_ROUTES.cracks,
    isBackwall: route === APP_ROUTES.backwall,
    isZones: route === APP_ROUTES.zones,
    isReport: route === APP_ROUTES.report,
    isImages: route === APP_ROUTES.images,
    isInfo: route === APP_ROUTES.info,
    isBCDPlus: route === APP_ROUTES.bcdplus,
    isBCDA: route === APP_ROUTES.bcda,
    isB: route === APP_ROUTES.b,
    isC: route === APP_ROUTES.c,
    isD: route === APP_ROUTES.d,
    is3D: route === APP_ROUTES.threeD,
    isHome: route === APP_ROUTES.home,
    isAutoAnalysisCredentials: route === APP_ROUTES.autoAnalysisCredentials,
    isRecipes: route === APP_ROUTES.recipes,
  };
};

export const isExceptionalRoute = (path: string) => {
  const { isHome, isRecipes, isDb } = checkAppRoute(path);
  return isDb || isRecipes || isHome;
};
