import classNames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';

import { genericMemo } from '../../../../utils';

interface IProps<T> {
  isCurrent?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  rowData: T;
  onClick?: (e: MouseEvent<HTMLDivElement>, value: T) => void;
  iconEnd?: ReactNode;
  children: ReactNode;
}

export const TableRowComponent = <T,>({
  rowData,
  isCurrent = false,
  isSelected = false,
  disabled = false,
  onClick,
  children,
}: IProps<T>) => {
  const classes = classNames('table-row', {
    'table-row__current': isCurrent,
    'table-row__selected': isSelected,
    'table-row__disabled': disabled,
  });

  return (
    <div className={classes} onClick={onClick && ((e) => onClick(e, rowData))}>
      {children}
    </div>
  );
};

export const TableRow = genericMemo(TableRowComponent);
