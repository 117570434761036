import React, { FC, useEffect, useRef } from 'react';

import { useActionCreators, useAppSelector } from '../../../../hooks';
import { useGetZonesZonesQuery, useSetZonesRotateAxesMutation } from '../../../../store/api/zones';
import { IZonesZone } from '../../../../store/api/zones/types';
import { selectVolumeId } from '../../../../store/selectors';
import {
  selectZonesAxesRotation,
  selectZonesPrevRotationAngle,
  selectZonesRotationAngle,
  selectZonesZoneInsertion,
  selectZonesZones,
} from '../../../../store/selectors/zones.selector';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  getRotationRectCoordsOffsetsInMm,
  getShuffledRectPointsCoordsViaRotation,
  ICartesianModeParams,
} from '../../../../utils';
import { CoordinateSystem } from './CoordinateSystem';
import { ZoneInsertion } from './ZoneInsertion';

interface IProps {
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
}

export const ZonesSvgElements: FC<IProps> = ({ domains, cartesianModeParams }) => {
  useGetZonesZonesQuery();
  const { setRotationAngle, modifyZonesZones, setPrevRotationAngle } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const allowZoneInsertion = useAppSelector(selectZonesZoneInsertion);
  const zones = useAppSelector(selectZonesZones);
  const rotationAngle = useAppSelector(selectZonesRotationAngle);
  const prevRotationAngle = useAppSelector(selectZonesPrevRotationAngle);
  const allowAxesRotation = useAppSelector(selectZonesAxesRotation);
  const [setRotateAxesTrigger] = useSetZonesRotateAxesMutation();
  const zonesRef = useRef<IZonesZone[]>(null!);

  useEffect(() => {
    zonesRef.current = zones;
  }, [zones]);

  const dragAction = (angle: number, prevAngle: number) => {
    modifyZonesZones(
      zones.map((z) => ({
        ...z,
        newZoneRectangle: getRotationRectCoordsOffsetsInMm(z.newZoneRectangle, angle - prevAngle),
      }))
    );
  };

  const dragEndAction = (angle: number) => {
    setPrevRotationAngle(angle);

    setRotateAxesTrigger({
      volumeId,
      newZones: getShuffledRectPointsCoordsViaRotation(zonesRef.current),
    });
  };

  return (
    <>
      <ZoneInsertion
        zones={zones}
        allowZoneInsertion={allowZoneInsertion}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        rotationAngle={rotationAngle}
      />
      <CoordinateSystem
        cartesianModeParams={cartesianModeParams}
        rotationAngle={rotationAngle}
        prevRotationAngle={prevRotationAngle}
        setRotationAngle={setRotationAngle}
        dragendAction={dragEndAction}
        dragAction={dragAction}
        allowAxesRotation={allowAxesRotation}
      />
    </>
  );
};
