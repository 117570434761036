import './styles.scss';

import { TextArea, TextInput } from '@sms/plasma-ui';
import React, { ChangeEvent, useCallback, useEffect, useLayoutEffect } from 'react';

import { LOCALSTORAGE_KEYS } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { selectRemarks, selectReportController, selectReportFileName } from '../../store/selectors';
import { getFromLocalStorage } from '../../utils';

export const ReportForm = () => {
  const { setController, setFileName, setTitleRemarks } = useActionCreators();
  const controller = useAppSelector(selectReportController);
  const fileName = getFromLocalStorage(LOCALSTORAGE_KEYS.scanInfo)?.name || '';
  const reportFileName = useAppSelector(selectReportFileName);
  const { titleRemarks } = useAppSelector(selectRemarks);

  useLayoutEffect(() => {
    if (!controller) {
      setController(getFromLocalStorage(LOCALSTORAGE_KEYS.lastAuthUser));
    }
  }, [controller]);

  const controllerInputHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setController(e.target.value);
  }, []);

  const fileNameInputHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  }, []);

  const remarksHandler = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setTitleRemarks(e.target.value);
  }, []);

  useEffect(() => {
    setFileName(fileName);
  }, [fileName]);

  return (
    <div className="report__form">
      <div className="report__form__inputs">
        <TextInput label="Controller" name="controller" value={controller} onChange={controllerInputHandler} />
        {/*<TextInput label="Directory" name="directory" />*/}
        <TextInput label="report.filename" name="filename" value={reportFileName} onChange={fileNameInputHandler} />
      </div>
      <TextArea
        label="Remarks"
        name="remarks"
        // placeholder={<TranslatedText textKey="side-menu.text-area-text" />}
        value={titleRemarks}
        onChange={remarksHandler}
      />
    </div>
  );
};
