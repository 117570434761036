import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import { TabPane } from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import Tabs from '@sms/plasma-ui/lib/components/Tabs/Tabs';
import React, { useState } from 'react';

import { GeneralSettings } from './GeneralSettings';
import { StandardsSettings } from './StandardsSettings';

export const SettingsForm = () => {
  const [activeKey, setActiveKey] = useState<string>('0');

  return (
    <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
      <TabPane tab={<TranslatedText textKey="common.general" />} key={'0'}>
        <GeneralSettings />
      </TabPane>
      <TabPane tab={<TranslatedText textKey="settings.standards" />} key={'1'}>
        <StandardsSettings />
      </TabPane>
    </Tabs>
  );
};
