import './styles.scss';

import { Modal, navigate, TranslatedText } from '@sms/plasma-ui';
import { Checkbox } from '@sms/plasma-ui/lib/components';
import React, { FC } from 'react';

import { DB_SNAPSHOT_TITLES_ARRAY } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useGetDbSnapshotsMutation } from '../../../store/api/database';
import { selectDbSelectedSnapshotTypes } from '../../../store/selectors';

interface Props {
  isVisible: boolean;
}

export const SnapshotViewControllerModal: FC<Props> = ({ isVisible }) => {
  const { setSnapshotsViewControllerVisible, setSelectedSnapshotType } = useActionCreators();
  const selectedSnapshots = useAppSelector(selectDbSelectedSnapshotTypes);
  const [snapshotsTrigger] = useGetDbSnapshotsMutation({ fixedCacheKey: 'dbSnapshots' });

  const onCancel = () => {
    setSnapshotsViewControllerVisible(false);
  };

  const onOk = async () => {
    await snapshotsTrigger();
    setSnapshotsViewControllerVisible(false);
    navigate('/database/results/snapshots');
  };

  if (!isVisible) return null;

  return (
    <Modal
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      okText="OK"
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      //@ts-ignore
      title={<TranslatedText textKey="Snapshots" />}
      visible={isVisible}
      width={460}
      zIndex={15}
      className={'snapshots__modal'}
    >
      <div className="titles__wrapper">
        {DB_SNAPSHOT_TITLES_ARRAY.map(({ key, title }) => (
          <Checkbox
            key={key}
            value={selectedSnapshots[key]}
            name={key}
            title={title}
            onChange={() => setSelectedSnapshotType(key)}
          />
        ))}
      </div>
    </Modal>
  );
};
