import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { navigate } from '@sms/plasma-ui';

import { DEFAULT_ERROR_MESSAGE, MODAL_STATUS, MODAL_TITLE, REPORT_TABS, SLICE_TYPES } from '../../../consts';
import { checkFileType, createModalMessageBody } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { flawsApi } from '../flaws';
import { api } from '../index';
import { segregationApi } from '../segregation';
import { sliceApi } from '../slice';
import {
  IDiagonals,
  IDimensions,
  IGeometryALines,
  IGeometryBLines,
  IGeometryCLines,
  IGeometryResponse,
  OmittedGeometryBase,
} from './types';

const { setSpinnerVisible, setCartesianMode, setBorder, setMadeAnalysis, setMessageModal } = allActions;

export const geometryApi = api.injectEndpoints({
  endpoints: (build) => ({
    initGeometry: build.mutation<OmittedGeometryBase, string>({
      async queryFn(sliceType, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const {
          volumeInfo: { fileType, volumeId },
          billetCutParams,
        } = getState() as TypedRootState;

        const { isIDVFileType } = checkFileType(fileType);

        const result = await fetchWithBQ({
          url: 'geometry/base',
          method: 'POST',
          body: {
            volumeId,
            sliceType: sliceType.split(' ').join(''),
            scanDataFileType: fileType,
            billetCutData:
              sliceType === SLICE_TYPES.cut
                ? {
                    ...billetCutParams,
                    dx: -billetCutParams.dx,
                    dy: -billetCutParams.dy,
                  }
                : null,
          },
        });

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        let timer: ReturnType<typeof setInterval>;

        await new Promise<void>((resolve, reject) => {
          timer = setInterval(async () => {
            let response;

            switch (sliceType) {
              case SLICE_TYPES.round:
                response = await dispatch(
                  geometryApi.endpoints.getGeometryBaseRound.initiate(undefined, { forceRefetch: true }),
                );
                break;
              case SLICE_TYPES.cut:
                response = await dispatch(
                  geometryApi.endpoints.getGeometryBaseCut.initiate(undefined, { forceRefetch: true }),
                );
                break;
              default:
                response = await dispatch(
                  geometryApi.endpoints.getGeometryBase.initiate(undefined, { forceRefetch: true }),
                );
            }

            const { data, error } = response;
            const errorHandler = () => {
              setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
              clearInterval(timer);
              reject();
            };

            if (error) {
              errorHandler();
              return;
            }

            if (data) {
              if (data?.analysisState === 'Error' || data?.analysisState === 'NotPerformed') {
                errorHandler();
                return;
              }

              if (data?.analysisState === 'Completed' && data?.geometryBaseResult) {
                clearInterval(timer);
                resolve();
              }
            }
          }, 3 * 1000);
        });

        await dispatch(sliceApi.endpoints.getBorderData.initiate(volumeId, { forceRefetch: true }));
        dispatch(setBorder(true));

        if (isIDVFileType) {
          await dispatch(segregationApi.endpoints.getSegHeightAnalysisData.initiate(volumeId, { forceRefetch: true }));
          await dispatch(flawsApi.endpoints.getFlawsHeightAnalysisData.initiate(volumeId, { forceRefetch: true }));
        }

        if (sliceType === SLICE_TYPES.round) {
          await dispatch(geometryApi.endpoints.getRoundDiagonals.initiate(volumeId, { forceRefetch: true }));
        }

        if (sliceType !== SLICE_TYPES.round && sliceType !== SLICE_TYPES.cut) {
          await dispatch(geometryApi.endpoints.getALines.initiate(volumeId, { forceRefetch: true }));
          await dispatch(geometryApi.endpoints.getBLines.initiate(volumeId, { forceRefetch: true }));
          await dispatch(geometryApi.endpoints.getCLines.initiate(volumeId, { forceRefetch: true }));
          await dispatch(geometryApi.endpoints.getDiagonals.initiate(volumeId, { forceRefetch: true }));
          await dispatch(geometryApi.endpoints.getDimensions.initiate(volumeId, { forceRefetch: true }));
        }

        dispatch(setCartesianMode(true));
        navigate('/geometry');
        dispatch(setMadeAnalysis({ analysis: REPORT_TABS.other, id: 'geometry' }));

        return { data: result.data as OmittedGeometryBase };
      },
    }),
    getGeometryBase: build.query<IGeometryResponse, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ('geometry/base');

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IGeometryResponse;

        return { data };
      },
      keepUnusedDataFor: 360000,
    }),
    getGeometryBaseRound: build.query<IGeometryResponse, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ('geometry/base/round');

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IGeometryResponse;

        return { data };
      },
      keepUnusedDataFor: 360000,
    }),
    getGeometryBaseCut: build.query<IGeometryResponse, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ('geometry/base/cut');

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IGeometryResponse;

        return { data };
      },
      keepUnusedDataFor: 360000,
    }),
    getALines: build.query<IGeometryALines, string>({
      query: (volumeId) => `geometry/aLines?volumeId=${volumeId}`,
    }),
    getBLines: build.query<IGeometryBLines, string>({
      query: (volumeId) => `geometry/bLines?volumeId=${volumeId}`,
    }),
    getCLines: build.query<IGeometryCLines, string>({
      query: (volumeId) => `geometry/cLines?volumeId=${volumeId}`,
    }),
    getDiagonals: build.query<IDiagonals, string>({
      query: (volumeId) => `geometry/diagonals?volumeId=${volumeId}`,
    }),
    getRoundDiagonals: build.query<IDiagonals, string>({
      query: (volumeId) => `geometry/diagonalsRound?volumeId=${volumeId}`,
    }),
    getDimensions: build.query<IDimensions, string>({
      query: (volumeId) => `geometry/dimensions?volumeId=${volumeId}`,
    }),
    setSliceRangeChanged: build.query<void, string>({
      query: (volumeId) => `geometry/sliceRangeChanged?volumeId=${volumeId}`,
    }),
  }),
});

export const {
  useLazyGetGeometryBaseQuery,
  useLazyGetGeometryBaseRoundQuery,
  useLazyGetGeometryBaseCutQuery,
  useLazySetSliceRangeChangedQuery,
  useInitGeometryMutation,
} = geometryApi;
