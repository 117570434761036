import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ICroppedImages } from '../api/slice/types';

const initialState = {
  imageBase64CutSliceC: '',
  imageBase64CutMirrorSliceC: '',
  imageBase64CutSliceB: '',
  imageBase64CutMirrorSliceB: '',
  imageBase64CutSliceD: '',
  imageBase64CutMirrorSliceD: '',
};

export const croppedImagesSlice = createSlice({
  name: 'croppedImages',
  initialState,
  reducers: {
    setCroppedImages: (state, action: PayloadAction<ICroppedImages>) => action.payload,
  },
});

export const { actions: croppedImagesActions, reducer: croppedImages } = croppedImagesSlice;
