import { TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { TSegZone } from '../../../../../store/api/segregation/types';
import { getToFixedValue } from '../../../../../utils';

interface IProps {
  zone: TSegZone | null;
}

export const CenterLineRow: FC<IProps> = ({ zone }) => {
  return (
    <>
      {zone && 'segLineY' in zone && (
        <li className="seg__statistics__item">
          <p>
            <TranslatedText textKey="side-menu.center-line" /> [mm]:
          </p>
          <p>{getToFixedValue(zone.segLineY)}</p>
        </li>
      )}
    </>
  );
};
