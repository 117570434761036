import './styles.scss';

import React from 'react';

import { ImagesFiles } from '../../components/ImagesFiles';
import { ImagesInfo } from '../../components/ImagesInfo';
import { ImagesSnapshot } from '../../components/ImagesSnapshot';
import { useAppSelector } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useGetAllSnapshotsQuery } from '../../store/api/snaphot';
import { selectVolumeId } from '../../store/selectors';

export const Images = () => {
  const volumeId = useAppSelector(selectVolumeId);
  useGetAllSnapshotsQuery(volumeId, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <DefaultLayout>
      <div className="page-container images__container">
        <div className="images__menu">
          <ImagesFiles />
          <ImagesInfo />
        </div>
        <ImagesSnapshot />
      </div>
    </DefaultLayout>
  );
};
