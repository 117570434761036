import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import { selectSegAnalysis } from '../../../../store/selectors';

export const SegStatisticsTitle = () => {
  const segAnalysis = useAppSelector(selectSegAnalysis);

  return (
    <h3 className="segregation__statistics__title">
      <TranslatedText textKey="side-menu.class-by" /> {segAnalysis}
    </h3>
  );
};
