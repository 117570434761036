import React, { useEffect, useLayoutEffect } from 'react';

import { MainGraphWindow } from '../../components/CommonGraphWindow';
import { CracksSideMenu } from '../../components/CracksSideMenu';
import { PaletteBar } from '../../components/PaletteBar';
import { IDs } from '../../consts';
import { useActionCreators, useAppSelector, useCracks } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import {
  selectAutomaticAnalysisStatus,
  selectIsReportMode,
  selectScanDataParameters,
  selectVolumeId,
} from '../../store/selectors';

export const Cracks = () => {
  const { setBorder } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const isReportMode = useAppSelector(selectIsReportMode);
  const { handleTakeCracksAutoAnalysisScreenshots, handleCracksAnalysisChange } = useCracks();

  useLayoutEffect(() => {
    setBorder(false);
  }, []);

  useEffect(() => {
    if (isAutoAnalysis) {
      handleTakeCracksAutoAnalysisScreenshots();
      return;
    }

    if (isReportMode) return;

    handleCracksAnalysisChange();
  }, [isAutoAnalysis, isReportMode, handleTakeCracksAutoAnalysisScreenshots]);

  return (
    <DefaultLayout>
      <div className="page-container">
        {scanDataParameters && (
          <>
            <CracksSideMenu />
            <MainGraphWindow id={IDs.cracksGraph} scanDataParameters={scanDataParameters} />
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
