import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { SCAN_VIEWS, SLICE_LAYER_TYPES } from '../../../consts';
import { checkAppRoute } from '../../../utils';
import { allActions } from '../../actions';
import { ISliderRanges } from '../../slices/types';
import { TypedRootState } from '../../types';
import { glbApi } from '../glb';
import { api } from '../index';
import {
  IAScanParams,
  IAScanResponse,
  IBorderData,
  ICroppedImages,
  IPaletteDataResponse,
  ISliceData,
  ISliceDataBody,
  ISlicesRangesBody,
  ISlicesRangesData,
  TAScanValues,
  TPaletteData,
} from './types';

const { setPaletteData, setPrevSliceImage, setAScanData, setCroppedImages } = allActions;

export const sliceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaletteData: build.query<TPaletteData, string>({
      async queryFn(volumeId, { dispatch }, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ(`slice/palette?volumeId=${volumeId}`);

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        const data = response.data as IPaletteDataResponse;
        const { isInverse, isScale, isMonochrome, colorRange } = data;

        const result = {
          isInverse,
          isScale,
          isMonochrome,
          colorRange,
        };

        dispatch(setPaletteData(result));

        return { data: result };
      },
    }),
    getCroppedImages: build.query<ICroppedImages, void>({
      async queryFn(volumeId, { dispatch }, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ('slice/image/cut');

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        const data = response.data as ICroppedImages;

        dispatch(setCroppedImages(data));

        return { data };
      },
    }),
    getBorderData: build.query<IBorderData, string>({
      query: (volumeId) => `slice/border?volumeId=${volumeId}`,
    }),
    getSlicesRanges: build.mutation<ISlicesRangesData, ISlicesRangesBody>({
      query: (body) => ({
        url: 'slice/data/ranges',
        method: 'POST',
        body,
      }),
    }),
    getAScan: build.mutation<TAScanValues, Partial<IAScanParams> | void>({
      async queryFn(args, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          sliderRanges: {
            singleLayer: { sliceB, sliceD },
          },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'slice/aScan',
          method: 'POST',
          body: {
            volumeId,
            rangeB: sliceB.start,
            rangeD: sliceD.start,
            ...args,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IAScanResponse;

        dispatch(setAScanData(data.aScanValues));

        return { data: data.aScanValues };
      },
    }),
    setProjectionsData: build.mutation<ISliceData, ISliceDataBody | string>({
      async queryFn(args, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          sliderRanges: { singleLayer, maxProjection },
          toolbarOptions: { isMaxProjection, isVolume },
        } = getState() as TypedRootState;

        const body = {
          volumeId,
          projections: [
            {
              scanView: args,
              sliderRange: isMaxProjection
                ? maxProjection[`slice${args}` as keyof ISliderRanges]
                : singleLayer[`slice${args}` as keyof ISliderRanges],
            },
          ],
          sliceLayerType: isMaxProjection ? SLICE_LAYER_TYPES.range : SLICE_LAYER_TYPES.singleLayer,
        };

        if (typeof args !== 'string') {
          Object.assign(body, args);
        }

        const result = await fetchWithBQ({
          url: 'slice/data',
          method: 'POST',
          body,
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ISliceData;

        const scanView = body.projections[0].scanView as string;

        dispatch(
          setPrevSliceImage({
            [scanView]: data[`slice${scanView}` as keyof ISliderRanges]?.imageBase64,
          }),
        );

        const { isBCDPlus, is3D } = checkAppRoute(window.location.pathname);

        if ((isBCDPlus || is3D) && !isVolume) {
          dispatch(sliceApi.endpoints.getCroppedImages.initiate(undefined, { forceRefetch: true }));
        }

        if (isBCDPlus && isVolume && scanView === SCAN_VIEWS.C) {
          dispatch(glbApi.endpoints.getThreeD.initiate(undefined, { forceRefetch: true }));
        }

        return { data };
      },
    }),
    setPalette: build.mutation<void, IPaletteDataResponse>({
      query: (body) => ({
        url: 'slice/palette',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetPaletteDataQuery,
  useGetSlicesRangesMutation,
  useGetCroppedImagesQuery,
  useGetAScanMutation,
  useSetProjectionsDataMutation,
  useSetPaletteMutation,
} = sliceApi;
