import { Icon, TranslatedText } from '@sms/plasma-ui';
import React, { FC, MouseEvent } from 'react';

import { DB_TABLE_COLUMN_TITLES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectDbGraphsSelectedColumns } from '../../../store/selectors';
import { ResultTableEntries } from '../../../types';

interface IProps {
  children?: React.ReactNode;
  data?: ResultTableEntries;
}

export const ResultTable: FC<IProps> = ({ children, data }) => {
  const { updateResultTable, setGraphSelectedColumn } = useActionCreators();
  const selectedColumns = useAppSelector(selectDbGraphsSelectedColumns);

  return (
    <table>
      <tbody>
        <tr>
          {data?.map(
            ([key, { visible }]) =>
              visible && (
                <th
                  key={key}
                  onClick={() => setGraphSelectedColumn(key)}
                  className={`${selectedColumns.includes(key) ? 'active' : ''}`}
                >
                  <TranslatedText textKey={DB_TABLE_COLUMN_TITLES[key as keyof typeof DB_TABLE_COLUMN_TITLES]} />
                  <div
                    className="icon-wrapper"
                    onClick={(e: MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      updateResultTable({ stringKeys: key, value: { visible: !visible } });
                    }}
                  >
                    <Icon name={'times'} />
                  </div>
                </th>
              ),
          )}
        </tr>
        {children}
      </tbody>
    </table>
  );
};
