import React, { FC } from 'react';

import { TTupleOfTwoNumbers } from '../../../../types';
import { ICartesianModeParams } from '../../../../utils';

interface IProps {
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  filledDomains: TTupleOfTwoNumbers;
  image: string;
}

export const GeometryImage: FC<IProps> = ({ cartesianModeParams, domains, filledDomains, image }) => {
  const width = (cartesianModeParams.realSampleWidth * filledDomains[0]) / domains[0];
  const height = (cartesianModeParams.realSampleHeight * filledDomains[1]) / domains[1];
  return (
    <>
      <image
        x={
          -(
            (cartesianModeParams.realSampleWidth * filledDomains[0]) / domains[0] -
            cartesianModeParams.realSampleWidth
          ) / 2
        }
        y={
          -(
            (cartesianModeParams.realSampleHeight * filledDomains[1]) / domains[1] -
            cartesianModeParams.realSampleHeight
          ) / 2
        }
        href={`data:image/gif;base64,${image}`}
        width={width}
        height={height}
        preserveAspectRatio="none"
      />
    </>
  );
};
