export const IMAGE_BLOB_TYPE = 'image/png';

export const IDENTIFIED_SNAPSHOTS = {
  cracks: 'Cracks',
  geometryA: 'GeometryA',
  geometryB: 'GeometryB',
  geometryC: 'GeometryC',
  geometryDimensions: 'GeometryDimensions',
  geometryDiagonals: 'GeometryDiagonals',
  geometryBase: 'GeometryBase',
  geometryCut: 'GeometryCut',
  flawsSms: 'FlawsSms',
  flawsGost: 'FlawsGost',
  flawsAstm: 'FlawsAstm',
  segMm: 'SegregationsMman',
  segGost: 'SegregationsGost',
  segAstm: 'SegregationsAstm',
  segSep: 'SegregationsSep100',
  zones: 'Zones',
  backwallDistance: 'BackwallDistance',
  backwallFlaws: 'BackwallFlaws',
};
