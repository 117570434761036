import { IConvertedBoundaryValues } from './convertedBoundaryValuesToPx';

export const getInnerSliceBorderOffsets = (
  width: number,
  height: number,
  widthOffset: number,
  heightOffset: number,
  convertedBoundaryValues: IConvertedBoundaryValues
) => {
  const { convertedLeft, convertedTop, convertedRight, convertedBottom } = convertedBoundaryValues;
  const innerLeftOffset = convertedLeft - widthOffset;
  const innerTopOffset = convertedTop - heightOffset;
  const innerRightOffset = width + widthOffset - convertedRight;
  const innerBottomOffset = height + heightOffset - convertedBottom;

  return {
    innerLeftOffset,
    innerTopOffset,
    innerRightOffset,
    innerBottomOffset,
  };
};
