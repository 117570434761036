import { Tooltip, TranslatedText } from '@sms/plasma-ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { GREY_EXTRA_LIGHT, SCAN_VIEWS } from '../../../../consts';
import { SLICE_TYPES } from '../../../../consts/geometry';
import { Icons } from '../../../svg';

interface IProps {
  scanView: string;
  sliceType: string;
}

export const CastingDirections: FC<IProps> = ({ scanView, sliceType }) => {
  const classes = classNames(
    'double-arrow',
    sliceType === SLICE_TYPES.sampleLong ? 'horizontal' : 'vertical'
  );

  return (
    <>
      {sliceType && (
        <>
          {scanView === SCAN_VIEWS.B && (
            <Tooltip
              className="casting-direction__tooltip"
              autoAdjustOverflow
              color={GREY_EXTRA_LIGHT}
              mouseEnterDelay={0.3}
              mouseLeaveDelay={0.1}
              placement="top"
              title={<TranslatedText textKey="direction.casting" />}
              trigger="hover"
            >
              <span>
                <Icons.DoubleArrow classNames={classes} />
              </span>
            </Tooltip>
          )}
          {scanView === SCAN_VIEWS.D && sliceType === SLICE_TYPES.sampleLong && (
            <Tooltip
              className="casting-direction__tooltip entry"
              autoAdjustOverflow
              color={GREY_EXTRA_LIGHT}
              mouseEnterDelay={0.3}
              mouseLeaveDelay={0.1}
              placement="top"
              title={<TranslatedText textKey="direction.casting-through-surface" />}
              trigger="hover"
            >
              <span>
                <Icons.EntryDirection />
              </span>
            </Tooltip>
          )}
          {scanView === SCAN_VIEWS.D && sliceType !== SLICE_TYPES.sampleLong && (
            <Tooltip
              className="casting-direction__tooltip"
              autoAdjustOverflow
              color={GREY_EXTRA_LIGHT}
              mouseEnterDelay={0.3}
              mouseLeaveDelay={0.1}
              placement="top"
              title={<TranslatedText textKey="direction.casting" />}
              trigger="hover"
            >
              <span>
                <Icons.DoubleArrow classNames={classes} />
              </span>
            </Tooltip>
          )}
          {scanView === SCAN_VIEWS.C && sliceType !== SLICE_TYPES.sampleLong && (
            <Tooltip
              className="casting-direction__tooltip entry"
              autoAdjustOverflow
              color={GREY_EXTRA_LIGHT}
              mouseEnterDelay={0.3}
              mouseLeaveDelay={0.1}
              placement="top"
              title={<TranslatedText textKey="direction.casting-through-surface" />}
              trigger="hover"
            >
              <span>
                <Icons.EntryDirection />
              </span>
            </Tooltip>
          )}
          {scanView === SCAN_VIEWS.C && sliceType === SLICE_TYPES.sampleLong && (
            <Tooltip
              className="casting-direction__tooltip"
              autoAdjustOverflow
              color={GREY_EXTRA_LIGHT}
              mouseEnterDelay={0.3}
              mouseLeaveDelay={0.1}
              placement="top"
              title={<TranslatedText textKey="direction.casting" />}
              trigger="hover"
            >
              <span>
                <Icons.DoubleArrow classNames={classes} />
              </span>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

