import { IZonesNewZoneParams, IZonesZone } from '../store/api/zones/types';
import { getRectCenterPointCoords } from './getRectCenterPointCoords';

export const getShuffledRectPointsCoordsViaRotation = (zones: IZonesZone[]): IZonesNewZoneParams[] => {
  return zones.map(({ newZoneRectangle }) => {
    const { leftTop, rightBottom } = newZoneRectangle;
    const { x: centerX } = getRectCenterPointCoords(leftTop, rightBottom);
    const coords = Object.values(newZoneRectangle);

    const xArray = coords.map(c => c.x);
    const yArray = coords.map(c => c.y);

    const maxX = Math.max(...xArray);
    const maxY = Math.max(...yArray);
    const minX = Math.min(...xArray);
    const minY = Math.min(...yArray);

    const pointWithHighestX = coords.find(v => v.x === maxX);
    const pointWithHighestY = coords.find(v => v.y === maxY);
    const pointWithLowestX = coords.find(v => v.x === minX);
    const pointWithLowestY = coords.find(v => v.y === minY);

    if (pointWithHighestY.x <= centerX) {
      return {
        leftTop: pointWithHighestY,
        rightTop: pointWithHighestX,
        rightBottom: pointWithLowestY,
        leftBottom: pointWithLowestX,
      };
    }

    return {
      leftTop: pointWithLowestX,
      rightTop: pointWithHighestY,
      rightBottom: pointWithHighestX,
      leftBottom: pointWithLowestY,
    };
  });
};
