import 'react-datepicker/dist/react-datepicker.css';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { NumberInput, TextInput } from '@sms/plasma-ui';
import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React, { ChangeEvent, memo } from 'react';

import { MAX_DEFECTIVE_VALUE } from '../../consts';
import { InputHintMode, INumberInputBlurHandlerArgs } from '../../types';
import {
  getNumberInputPrecision,
  getRangeAndStartEndInputsKeys,
  isMaxDefensiveValueInput,
  isStartEndNumberInput,
} from '../../utils';
import { NumberInputValue } from '../RangeBar';
import { Remarks } from '../Remarks';
import { DateInput } from '../UI/DateInput';

interface Props {
  section: string;
  id: string;
  label: string;
  value: string | number | boolean;
  hint?: string;
  hintMode?: InputHintMode;
  disabled?: boolean;
  onChange: ActionCreatorWithPayload<
    {
      section: string;
      id: string;
      value: string | number | boolean;
    },
    string
  >;
  onBlur?: (args: INumberInputBlurHandlerArgs) => void;
}

export const FormInput = memo(function InfoFormInput({
  section,
  id,
  label,
  value,
  onChange,
  onBlur,
  disabled = false,
  hintMode = 'hidden',
  hint = '',
}: Props) {
  const handleTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ section, id, value: e.target.value });
  };

  const handleNumberInputChange = (value: NumberInputValue) => {
    if (value === undefined || value === null || typeof value === 'string' || +value < 0) {
      onChange({ section, id, value: 0 });
      return;
    }

    if (isMaxDefensiveValueInput(id) && value > MAX_DEFECTIVE_VALUE) {
      onChange({ section, id, value: MAX_DEFECTIVE_VALUE - 1 });
      return;
    }

    onChange({ section, id, value });
  };

  const handleBlur = () => {
    if (!onBlur || !isStartEndNumberInput(id)) return;

    const { range, startKey, endKey } = getRangeAndStartEndInputsKeys(id);
    onBlur({ section, range, startKey, endKey });
  };

  const handleCheckboxChange = () => {
    onChange({ section, id, value: !value });
  };

  const handleDateChange = (value: string) => {
    onChange({ section, id, value });
  };

  const handleClear = () => {
    onChange({ section, id, value: '' });
  };

  if (typeof value === 'number') {
    return (
      <NumberInput
        name={id}
        //@ts-ignore
        value={value}
        label={label}
        hint={hint}
        hintMode={hintMode}
        decimalSeparator={'.'}
        disabled={disabled}
        precision={getNumberInputPrecision(section, id)}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
      />
    );
  }

  if (typeof value === 'boolean') {
    return (
      <Checkbox
        hint={hint}
        label={label}
        hintMode={hintMode}
        name={id}
        value={value}
        disabled={disabled}
        title={value === true ? 'On' : 'Off'}
        onChange={handleCheckboxChange}
      />
    );
  }

  if (id === 'remarks' && typeof value === 'string') {
    return <Remarks label={label} value={value} name={id} onChange={handleTextChange} />;
  }

  if (id === 'productionDate') {
    return (
      <DateInput label={label} value={value as string} name={id} onChange={handleDateChange} onClear={handleClear} />
    );
  }

  return (
    <TextInput
      key={id}
      hint={hint}
      hintMode={hintMode}
      label={label}
      name={id}
      disabled={disabled}
      value={value}
      onChange={handleTextChange}
    />
  );
});
