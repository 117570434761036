import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  BACKWALL_ANALYSIS,
  CRACKS_ANALYSIS,
  FLAWS_ANALYSIS,
  isHostEnvStandalone,
  LOCALSTORAGE_KEYS,
  SEG_ANALYSIS,
} from '../../consts';
import { ReportAnalysisData, TLogo, TLogosUsed, TReportAnalysis } from '../../types';
import { getFromLocalStorage } from '../../utils';

export interface IReportData {
  analysis: ReportAnalysisData<boolean>;
  isAnalysisAvailable: ReportAnalysisData<boolean>;
  controller: string;
  fileName: string;
  addLogo: TLogosUsed;
  sendEmail: boolean;
  email: string;
  isReportMode: boolean;
}

const initialState: IReportData = {
  analysis: {
    segregations: {
      [SEG_ANALYSIS.mm]: false,
      [SEG_ANALYSIS.sep]: false,
      [SEG_ANALYSIS.gost]: false,
      [SEG_ANALYSIS.astm]: false,
    },
    flaws: {
      [FLAWS_ANALYSIS.sms]: false,
      [FLAWS_ANALYSIS.gost]: false,
      [FLAWS_ANALYSIS.astm]: false,
    },
    cracks: {
      [CRACKS_ANALYSIS.sms]: false,
      [CRACKS_ANALYSIS.gost]: false,
      [CRACKS_ANALYSIS.astm]: false,
    },
    backwall: {
      [BACKWALL_ANALYSIS.distance]: false,
      [BACKWALL_ANALYSIS.flaws]: false,
    },
    other: {
      geometry: false,
      data: false,
      csv: false,
      zones: false,
    },
  },
  isAnalysisAvailable: {
    segregations: {
      [SEG_ANALYSIS.mm]: false,
      [SEG_ANALYSIS.sep]: false,
      [SEG_ANALYSIS.gost]: false,
      [SEG_ANALYSIS.astm]: false,
    },
    flaws: {
      [FLAWS_ANALYSIS.sms]: false,
      [FLAWS_ANALYSIS.gost]: false,
      [FLAWS_ANALYSIS.astm]: false,
    },
    cracks: {
      [CRACKS_ANALYSIS.sms]: false,
      [CRACKS_ANALYSIS.gost]: false,
      [CRACKS_ANALYSIS.astm]: false,
    },
    backwall: {
      [BACKWALL_ANALYSIS.distance]: false,
      [BACKWALL_ANALYSIS.flaws]: false,
    },
    other: {
      geometry: false,
      data: true,
      csv: true,
      zones: false,
    },
  },
  controller: isHostEnvStandalone ? 'Admin' : getFromLocalStorage(LOCALSTORAGE_KEYS.lastAuthUser),
  fileName: '',
  addLogo: {
    smsLogo: true,
    hdScanLogo: true,
    clientLogo: false,
  },
  sendEmail: false,
  email: '',
  isReportMode: false,
};

export const reportRemarks = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setMadeAnalysis: (
      state,
      action: PayloadAction<{
        analysis: TReportAnalysis;
        id: string;
      }>,
    ) => {
      const { analysis, id } = action.payload;
      state.analysis[analysis][id] = true;
      state.isAnalysisAvailable[analysis][id] = true;
    },
    setCheckedAnalysis: (
      state,
      action: PayloadAction<{
        analysis: TReportAnalysis;
        id: string;
      }>,
    ) => {
      const { analysis, id } = action.payload;
      state.analysis[analysis][id] = !state.analysis[analysis][id];
    },
    setController: (state, action: PayloadAction<string>) => {
      state.controller = action.payload;
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    setUsedLogo: (state, action: PayloadAction<TLogo>) => {
      const logoId = action.payload;
      state.addLogo[logoId] = !state.addLogo[logoId];
    },
    setReportEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setSendReportEmail: (state) => {
      state.sendEmail = !state.sendEmail;
    },
    setIsReportMode: (state, action: PayloadAction<boolean>) => {
      state.isReportMode = action.payload;
    },
    resetReportAnalysis: (state) => {
      state.analysis = initialState.analysis;
      state.isAnalysisAvailable = initialState.isAnalysisAvailable;
    },
  },
});

export const { actions: reportActions, reducer: report } = reportRemarks;
