import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import { selectVolumeId } from '../../../../store/selectors';
import { selectGeometryDimensions } from '../../../../store/selectors/geometry.selector';
import { getAverageValue } from '../../../../utils/getAverageValue';
import { getRSD } from '../../../../utils/getRSD';

export const DimensionsRow = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const { horizontalLengths, verticalLengths } = useAppSelector((state) => selectGeometryDimensions(state, volumeId));
  const diagonalsInfo = [
    {
      title: 'geometry.avg-width',
      value: `${getAverageValue(horizontalLengths).toFixed(1)} (${getRSD(horizontalLengths)}%)`,
    },
    {
      title: 'geometry.avg-height',
      value: `${getAverageValue(verticalLengths).toFixed(1)} (${getRSD(verticalLengths)}%)`,
    },
  ];

  return (
    <li className="geometry__statistic__item dimensions">
      <p className="geometry__statistic__item__title">
        <TranslatedText textKey="dimensions.dimensions" />:
      </p>
      <ul className="geometry__statistic__inner__list">
        {diagonalsInfo.map(({ title, value }, idx) => (
          <li key={idx} className="geometry__statistic__inner__item">
            <p className="geometry__statistic__inner__title">
              <TranslatedText textKey={title} />:
            </p>
            <p className="geometry__statistic__item__content">{value}</p>
          </li>
        ))}
      </ul>
    </li>
  );
};
