import { IAdaptImageScaleCoords } from '../store/api/adapt-image/types';
import { TTupleOfTwoNumbers } from '../types';
import { getLineLengthByCoords } from './getLineLengthByCoords';

export const getRescaledMmDomains = (scaleCoords: IAdaptImageScaleCoords, scaleLengthInMm: number, pxDomains: TTupleOfTwoNumbers): TTupleOfTwoNumbers => {
  const scaleLengthInRealPx = getLineLengthByCoords(scaleCoords.startPoint.x, scaleCoords.startPoint.y, scaleCoords.endPoint.x, scaleCoords.endPoint.y);
  const rescaledWidthInMm = pxDomains[0] * scaleLengthInMm / scaleLengthInRealPx;
  const rescaledHeightInMm = pxDomains[1] * scaleLengthInMm / scaleLengthInRealPx;

  return [rescaledWidthInMm, rescaledHeightInMm];
};
