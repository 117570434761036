import React from 'react';

import { AdaptImageGraph } from '../../components/AdaptImageGraph';
import { AdaptSideMenu } from '../../components/AdaptSideMenu';
import { IDs } from '../../consts';
import { DefaultLayout } from '../../layouts/DefaultLayout';

export const Adapt = () => {
  return (
    <DefaultLayout>
      <div className='page-container'>
        <AdaptSideMenu />
        <AdaptImageGraph id={IDs.adaptImageGraph} />
      </div>
    </DefaultLayout>
  );
};
