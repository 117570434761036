import './styles.scss';

import { useTranslation } from '@sms/plasma-ui';
import * as echarts from 'echarts';
// @ts-ignore
import { transform } from 'echarts-stat';
import React, { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';

import { Modals } from '../../../components/Modals';
import {
  CHART_TYPES,
  DB_TABLE_COLUMN_TITLES,
  DEFAULT_ERROR_MESSAGE,
  IDs,
  MODAL_STATUS,
  MODAL_TITLE,
} from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import {
  useSaveDatabaseChartDataMutation,
  useSaveDatabaseMutation,
  useSaveDatabaseSingularChartDataMutation,
  useUpdateDBAdditionalFiltersMutation,
} from '../../../store/api/database';
import { selectDbGraphsSelectedColumns } from '../../../store/selectors';
import { ChartConfig, ChartTypes, TTupleOfTwoNumbers } from '../../../types';
import {
  checkChartsOriginData,
  createModalMessageBody,
  getBase64Snapshot,
  getMergedFiltersResults,
  getShortBase64String,
  getValueOfNestedObject,
  makeOptions,
} from '../../../utils';
import { DatabaseCharts } from './components/DatabaseCharts';
import { DatabaseChartsSideMenu } from './components/DatabaseChartsSideMenu';

echarts.registerTransform(transform.regression);

const useChart = ({ originData, keys, chartType, isNumberTuple, checkedOriginDataLength }: ChartConfig) => {
  const { t } = useTranslation();
  const [xKey, yKey] = keys;
  const areLengthsEqual = keys.length === checkedOriginDataLength;

  const handledOriginData = originData.map((row) =>
    keys.reduce((acc, key) => {
      const value = getValueOfNestedObject(row, key);

      if (value === null || value === '') {
        acc[key] = '-';
        return acc;
      }
      acc[key] = value;

      return acc;
    }, {} as Record<string, string | number>),
  );

  const numberTupleData: TTupleOfTwoNumbers[] = [];

  handledOriginData.forEach((d) => {
    if (d[xKey] && d[yKey] !== '-') {
      let y = d[yKey];

      if (isNumberTuple && yKey === 'flawsGostClass') {
        y = d[yKey] === '>4' ? 4.1 : +d[yKey];
      }

      numberTupleData.push([d[xKey], y] as TTupleOfTwoNumbers);
    }
  });

  const categories = Array.from(new Set(handledOriginData.map((v) => v[xKey])));
  const subcategories = yKey ? Array.from(new Set(handledOriginData.map((v) => v[yKey]))) : [];
  const initQty: any = {};

  if (yKey && areLengthsEqual) {
    for (let i = 0; i <= categories.length - 1; i++) {
      initQty[categories[i]] = {};

      for (let j = 0; j <= subcategories.length - 1; j++) {
        initQty[categories[i]][subcategories[j]] = 0;
      }
    }
  } else {
    for (let i = 0; i <= categories.length - 1; i++) {
      initQty[categories[i]] = 0;
    }
  }

  const qty = handledOriginData.reduce((acc, v) => {
    if (yKey && areLengthsEqual) {
      acc[v[xKey]][v[yKey]] += 1;
      return acc;
    }

    if (v[(areLengthsEqual ? yKey : null) ?? xKey] in acc) {
      acc[v[(areLengthsEqual ? yKey : null) ?? xKey]] += 1;
    }

    return acc;
  }, initQty);

  const options = makeOptions({
    chartType,
    numberTupleData,
    qty,
    keys,
    categories,
    t,
  });

  return {
    options,
    categories,
    qty,
    numberTupleData,
  };
};

export const Charts = () => {
  const { setMessageModal } = useActionCreators();
  const keys = useAppSelector(selectDbGraphsSelectedColumns);
  const [saveChartDataTrigger] = useSaveDatabaseChartDataMutation();
  const [saveSingularChartDataTrigger] = useSaveDatabaseSingularChartDataMutation();
  const [, { data: databaseResults }] = useSaveDatabaseMutation({ fixedCacheKey: 'databaseResults' });
  const [, { data: additionalFiltersResults }] = useUpdateDBAdditionalFiltersMutation({
    fixedCacheKey: 'additionalFiltersResults',
  });

  const results = useMemo(() => getMergedFiltersResults(databaseResults, additionalFiltersResults), [
    databaseResults,
    additionalFiltersResults,
  ]);

  const checkedOriginData = useMemo(() => checkChartsOriginData(results, keys), [results, keys]);
  const checkedOriginDataLength = checkedOriginData.length;
  const isNumberTuple = checkedOriginData.every((i) => i);
  const [chartType, setChartType] = useState<ChartTypes>(
    checkedOriginDataLength === 1 ? CHART_TYPES.bar : CHART_TYPES.multibar,
  );
  const { options, categories, qty, numberTupleData } = useChart({
    originData: results,
    keys,
    chartType,
    isNumberTuple,
    checkedOriginDataLength,
  });

  const dataSource = useMemo(
    () => [
      {
        value: 'bar',
        label: 'Bar',
        disabled: checkedOriginDataLength !== 1,
      },
      {
        value: 'multibar',
        label: 'Multibar',
        disabled: checkedOriginDataLength !== 2,
      },
      {
        value: 'pie',
        label: 'Pie',
        disabled: checkedOriginDataLength !== 1,
      },
      {
        value: 'scatter',
        label: 'Scatter',
        disabled: checkedOriginDataLength !== 2 || !isNumberTuple,
      },
      {
        value: 'linearRegression',
        label: 'Linear Regression',
        disabled: checkedOriginDataLength !== 2 || !isNumberTuple,
      },
      {
        value: 'exponentialRegression',
        label: 'Exponential Regression',
        disabled: checkedOriginDataLength !== 2 || !isNumberTuple,
      },
      {
        value: 'polynomialRegression',
        label: 'Polynomial Regression',
        disabled: checkedOriginDataLength !== 2 || !isNumberTuple,
      },
    ],
    [checkedOriginDataLength, isNumberTuple],
  );

  const radioGroupChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setChartType(e.target.value as ChartTypes);
  }, []);

  const exportChartHandler = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      const imageBase64String = await getBase64Snapshot(`#${IDs.databaseCharts}`);

      if (!imageBase64String) {
        setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
        return;
      }

      const snapshotBase64 = getShortBase64String(imageBase64String);
      const [x, y] = keys;
      const xAxisName = `${DB_TABLE_COLUMN_TITLES[x as keyof typeof DB_TABLE_COLUMN_TITLES]}`;
      const yAxisName = `${DB_TABLE_COLUMN_TITLES[y as keyof typeof DB_TABLE_COLUMN_TITLES]}`;

      if (chartType === CHART_TYPES.multibar) {
        const chartData = Object.entries(qty).map(([key, values]) => {
          return {
            xAxisValue: key,
            values: values as Record<string, number>,
          };
        });

        await saveChartDataTrigger({
          snapshotBase64: getShortBase64String(snapshotBase64),
          chartName: chartType,
          chartModel: {
            countParameterName: 'Amount',
            xAxisName,
            chartData,
          },
        });

        return;
      }

      if (chartType === CHART_TYPES.bar || chartType === CHART_TYPES.pie) {
        await saveSingularChartDataTrigger({
          snapshotBase64,
          chartName: chartType,
          chartModel: {
            xAxisName,
            yAxisName: 'Amount',
            xAxisValues: Object.keys(qty),
            yAxisValues: (Object.values(qty) as number[]).map((i) => i.toString()),
          },
        });

        return;
      }

      const xAxisValues = [] as string[];
      const yAxisValues = [] as string[];

      numberTupleData.forEach(([v1, v2]) => {
        xAxisValues.push(v1.toString());
        yAxisValues.push(v2.toString());
      });

      await saveSingularChartDataTrigger({
        snapshotBase64,
        chartName: chartType,
        chartModel: {
          xAxisName,
          yAxisName: yAxisName ?? 'Amount',
          xAxisValues,
          yAxisValues,
        },
      });
    },
    [categories, qty, keys, numberTupleData],
  );

  return (
    <>
      <div className="graphs">
        <DatabaseChartsSideMenu
          chartType={chartType}
          dataSource={dataSource}
          onChange={radioGroupChangeHandler}
          onExport={exportChartHandler}
        />
        {keys.length && <DatabaseCharts chartType={chartType} options={options} />}
      </div>
      <Modals />
    </>
  );
};
