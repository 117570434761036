import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { v4 as uuidv4 } from 'uuid';

import { ec2AutoInstanceHeader } from '../../../consts';
import { getFilteredAnalysisStagesJSON, getISODateWithTimezone } from '../../../utils';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { recipesTemplateApi } from '../recipes-template';
import { IRecipeInfoFull } from './types';

export const recipesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRecipe: build.query<IRecipeInfoFull, string>({
      async queryFn(id, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `recipe/?recipeId=${id}`,
          method: 'GET',
          headers: ec2AutoInstanceHeader,
        });

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IRecipeInfoFull;
        return { data };
      },
    }),
    saveRecipe: build.mutation<void, { fileIds: string[]; templateId: string }>({
      async queryFn({ fileIds, templateId }, { dispatch }, _extraOptions, fetchWithBQ) {
        const { data } = await dispatch(
          recipesTemplateApi.endpoints.getRecipeTemplate.initiate({ id: templateId, showResults: false }),
        );

        const result = await fetchWithBQ({
          url: 'recipe',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: fileIds.map((scanDataFileInfoId) => ({
            ...data,
            id: uuidv4(),
            scanDataFileInfoId,
            modifiedAt: getISODateWithTimezone(new Date()),
            createdAt: getISODateWithTimezone(new Date()),
          })),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    saveRecipeFromEditor: build.mutation<void, string[]>({
      async queryFn(fileIds, { getState }, _extraOptions, fetchWithBQ) {
        const {
          recipes: { sliceType, performAnalysis, analysisStages },
        } = getState() as TypedRootState;

        const analysisStagesString = getFilteredAnalysisStagesJSON(analysisStages, performAnalysis, sliceType);

        const result = await fetchWithBQ({
          url: 'recipe',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: fileIds.map((scanDataFileInfoId) => ({
            id: uuidv4(),
            name: 'Anonymous',
            scanDataFileInfoId,
            modifiedAt: getISODateWithTimezone(new Date()),
            createdAt: getISODateWithTimezone(new Date()),
            analysisStages: analysisStagesString,
          })),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    deleteRecipes: build.mutation<void, string[]>({
      async queryFn(recipeIds, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'recipe/batchremove',
          method: 'POST',
          headers: {
            ...ec2AutoInstanceHeader,
          },
          body: recipeIds,
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
  }),
});

export const { useSaveRecipeMutation, useSaveRecipeFromEditorMutation, useDeleteRecipesMutation } = recipesApi;
