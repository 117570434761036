import React, { FC } from 'react';

import { useAppSelector } from '../../../../../hooks';
import { TFlawsRectZones, TFlawsRoundZones } from '../../../../../store/api/flaws/types';
import { selectFlawsAllowAreaModification, selectFlawsForbiddenZones } from '../../../../../store/selectors';
import { IBilletCutParams } from '../../../../../store/slices/types';
import { TTupleOfTwoNumbers } from '../../../../../types';
import { ICartesianModeParams, IGraphParams } from '../../../../../utils';
import { ForbiddenZones } from '../../../../ForbiddenZones';
import { RectZones } from './RectZones';
import { RoundZones } from './RoundZones';

interface IProps {
  rectZones: TFlawsRectZones | null;
  roundZones: TFlawsRoundZones | null;
  graphParams: IGraphParams;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  billetCutParams: IBilletCutParams;
}

export const FlawsZones: FC<IProps> = ({ rectZones, roundZones, graphParams, domains, billetCutParams, cartesianModeParams }) => {
  const allowModification = useAppSelector(selectFlawsAllowAreaModification);
  const forbiddenZones = useAppSelector(selectFlawsForbiddenZones);

  return (
    <>
      <ForbiddenZones
        zones={forbiddenZones}
        cartesianModeParams={cartesianModeParams}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
      />
      {rectZones && (
        <RectZones
          zones={rectZones}
          graphParams={cartesianModeParams}
          billetCutParams={billetCutParams}
          domains={domains}
          allowModification={allowModification}
        />
      )}
      {roundZones && (
        <RoundZones
          zones={roundZones}
          graphParams={cartesianModeParams}
          billetCutParams={billetCutParams}
          domains={domains}
          allowModification={allowModification}
        />
      )}
    </>
  );
};

