import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import { Tabs } from '@sms/plasma-ui/lib/components/Tabs/Tabs';
import React, { useState } from 'react';

import { Modals } from '../../components/Modals';
import { RecipeEditor } from '../../components/RecipeEditor';
import { RecipesIDVFiles } from '../../components/RecipesIDVFiles';
import { useGetAllRecipeTemplatesQuery } from '../../store/api/recipes-template';

export const Recipes = () => {
  const [activeKey, setActiveKey] = useState('0');
  useGetAllRecipeTemplatesQuery();

  return (
    <>
      <div className="recipe__container">
        <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
          <TabPane tab={<TranslatedText textKey="recipes.idv-files" />} key={'0'}>
            <RecipesIDVFiles />
          </TabPane>
          <TabPane tab={<TranslatedText textKey="recipes.recipe-editor" />} key={'1'}>
            <RecipeEditor />
          </TabPane>
        </Tabs>
      </div>
      <Modals />
    </>
  );
};
