import './styles.scss';

import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import React from 'react';

import { TSelectedPolygon } from '../../store/slices/types';

interface Props {
  selectedPolygon?: TSelectedPolygon;
}

export const SelectedArea = ({ selectedPolygon }: Props) => {
  return (
    <div className="statistics__selected-area">
      <div className="column first-column">
        <ul className="statistics__list">
          <li className="statistics__item">
            <p>
              <TranslatedText textKey="side-menu.area" /> [mm<sup>2</sup>]:
            </p>
            <p>{selectedPolygon?.area.toFixed(2) ?? 0}</p>
          </li>
          <li className="statistics__item">
            <p>
              <TranslatedText textKey="side-menu.center" /> X [mm]:
            </p>
            <p>{selectedPolygon?.equivalentCenter.x.toFixed(2) ?? 0}</p>
          </li>
          <li className="statistics__item">
            <p>
              <TranslatedText textKey="side-menu.center" /> Y [mm]:
            </p>
            <p>{selectedPolygon?.equivalentCenter.y.toFixed(2) ?? 0}</p>
          </li>
        </ul>
      </div>
      <div className="column second-column">
        <ul className="statistics__list">
          <li className="statistics__item">
            <p>
              <TranslatedText textKey="dimensions.width" /> [mm]:
            </p>
            <p>{selectedPolygon?.width.toFixed(2) ?? 0}</p>
          </li>
          <li className="statistics__item">
            <p>
              <TranslatedText textKey="dimensions.height" /> [mm]:
            </p>
            <p>{selectedPolygon?.height.toFixed(2) ?? 0}</p>
          </li>
          <li className="statistics__item">
            <p>
              <TranslatedText textKey="dimensions.equiv" />
              ⌀ [mm]:
            </p>
            <p>{selectedPolygon?.diameterEquivalent.toFixed(2) ?? 0}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
