import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { FILES_DIRECTIONS_ORDER, FILES_PAGE_SIZE, LOCALSTORAGE_KEYS } from '../../consts';
import { TFilesOrderBy } from '../../types';
import { getFromLocalStorage, getRangeOfTime } from '../../utils';

export interface IScanDataFilters {
  page: number;
  pageSize: number;
  orderBy: TFilesOrderBy;
  searchTerm: string;
  includeRecipes: boolean;
  startDate: string;
  endDate: string;
  showDeletedFromCloud: boolean;
}

const initialState: IScanDataFilters = {
  page: 1,
  pageSize: FILES_PAGE_SIZE,
  orderBy: FILES_DIRECTIONS_ORDER,
  searchTerm: '',
  includeRecipes: true,
  startDate: getFromLocalStorage(LOCALSTORAGE_KEYS.scanDataFiltersDates)?.startDate ?? getRangeOfTime(4).start,
  endDate: getFromLocalStorage(LOCALSTORAGE_KEYS.scanDataFiltersDates)?.endDate ?? getRangeOfTime().end,
  showDeletedFromCloud: true,
};

export const scanDataFiltersSlice = createSlice({
  name: 'scanData',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setScanDataFilters: (state, action: PayloadAction<Partial<IScanDataFilters>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: scanDataFiltersActions, reducer: scanDataFilters } = scanDataFiltersSlice;
