import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

import { isHostEnvStandalone, isProduction, LAMBDA_EXCLUDED_ENDPOINTS, LOCALSTORAGE_KEYS } from '../../consts';
import { getFromLocalStorage, isString } from '../../utils';
import { allActions } from '../actions';
import { TypedRootState } from '../types';

const { setDisableScreenVisible, setSpinnerVisible, increaseTotal, increaseFullfilled } = allActions;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  mode: 'cors',
  prepareHeaders: async (headers, { endpoint }) => {
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Content-Type', 'application/json');

    if (isHostEnvStandalone) return headers;

    let token = '';

    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
      const refreshToken = session.getRefreshToken();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      const accessTokenExpire = accessToken.getExpiration();

      token = accessToken.getJwtToken();

      if (accessTokenExpire <= currentTimeSeconds) {
        await new Promise<void>((resolve) => {
          setTimeout(async () => {
            (await Auth.currentAuthenticatedUser()).refreshSession(refreshToken, (error: unknown, data: any) => {
              if (error) {
                // TODO: clear volume info and localStorage; init Auth signOut
                // Auth.signOut();
                console.log('Error refreshing current session:', error);
              } else {
                token = data.getAccessToken().getJwtToken();
                resolve();
              }
            });
          }, 0);
        });
      }
    } catch (error) {
      console.log(error);
    }

    if (getFromLocalStorage(LOCALSTORAGE_KEYS.autoAnalysisAccessToken)) {
      token = getFromLocalStorage(LOCALSTORAGE_KEYS.autoAnalysisAccessToken);
    }

    if (!headers.has('Authorization') && token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (isProduction) {
      if (headers.has('hdsw-ec2-auto-instance')) return headers;

      headers.set('hdsw-ec2-instance-type', getFromLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceType));

      if (!LAMBDA_EXCLUDED_ENDPOINTS.includes(endpoint)) {
        headers.set('hdsw-ec2-instance-id', getFromLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceId));
      }
    }

    return headers;
  },
  credentials: 'include',
});

const baseQueryWithDisableScreen: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const dispatch = api.dispatch;
  const {
    modals: {
      spinner: { isLoading },
    },
  } = api.getState() as TypedRootState;

  api.dispatch(setDisableScreenVisible(true));

  if (isLoading) {
    dispatch(increaseTotal());
    dispatch(increaseFullfilled());
  }

  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (!isString(args) && args.url === 'machine/state') return result;
    api.dispatch(setSpinnerVisible(false));
  }

  api.dispatch(setDisableScreenVisible(false));

  return result;
};

export const api = createApi({
  keepUnusedDataFor: 3600,
  baseQuery: baseQueryWithDisableScreen,
  tagTypes: [
    'Snapshots',
    'Info',
    'ReportLogo',
    'Cracks',
    'Backwall Distance',
    'Analysis settings',
    'Recipe Templates',
    'Cracks Zones',
  ],
  endpoints: () => ({}),
});

export const { endpoints, reducerPath, reducer, middleware } = api;
