import { Box, useTexture } from '@react-three/drei';
import React, { FC } from 'react';

import { BuildTuple } from '../../../types';
import { IPseudo3DProjectionViewProps } from '../types';

export const SingleLayersView: FC<IPseudo3DProjectionViewProps> = ({
  scale,
  sizeX,
  sizeY,
  sizeZ,
  sliceX,
  sliceY,
  sliceZ,
  xSliceImage,
  ySliceImage,
  zSliceImage,
  x1SliceImage,
  y1SliceImage,
  z1SliceImage,
  prevXSliceImage,
  prevYSliceImage,
  prevZSliceImage,
  isVisible,
}) => {
  const gXPosition: BuildTuple<3, number> = [(-sizeX / 2 + sliceX.start) * scale, 0, 0];
  const gYPosition: BuildTuple<3, number> = [0, (sizeY / 2 - sliceY.start) * scale, 0];
  const gZPosition: BuildTuple<3, number> = [0, 0, (sizeZ / 2 - sliceZ.start) * scale];

  const getCheckedTextures = () => {
    const x = xSliceImage ?? prevXSliceImage;
    const y = ySliceImage ?? prevYSliceImage;
    const z = zSliceImage ?? prevZSliceImage;
    const x1 = x1SliceImage ?? prevXSliceImage;
    const y1 = y1SliceImage ?? prevYSliceImage;
    const z1 = z1SliceImage ?? prevZSliceImage;

    if (!x || !y || !z || !x1 || !y1 || !z1) return [];

    return [x, x1, y, y1, z, z1];
  };

  const textures = useTexture(getCheckedTextures().map((item) => `data:image/gif;base64,${item}`));

  return (
    <>
      <group position={gXPosition} rotation={[0, 0, 0]} scale={scale} visible={isVisible}>
        <Box args={[0.1, sizeY, sizeZ]}>
          {textures.map((texture, idx) => (
            <meshStandardMaterial key={idx} color={'white'} attachArray="material" map={texture} transparent />
          ))}
        </Box>
      </group>
      <group position={gYPosition} rotation={[0, 0, 0]} scale={scale} visible={isVisible}>
        <Box args={[sizeX, 0.1, sizeZ]}>
          {textures.map((texture, idx) => (
            <meshStandardMaterial key={idx} color={'white'} attachArray="material" map={texture} transparent />
          ))}
        </Box>
      </group>
      <group position={gZPosition} rotation={[0, 0, 0]} scale={scale} visible={isVisible}>
        <Box args={[sizeX, sizeY, 0.1]}>
          {textures.map((texture, idx) => (
            <meshStandardMaterial key={idx} color={'white'} attachArray="material" map={texture} transparent />
          ))}
        </Box>
      </group>
    </>
  );
};
