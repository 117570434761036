export const getLineAngle = (dy: number, dx: number): number => {
  let angle = Math.atan(dy / dx) * 180 / Math.PI;

  if (dx > 0 && dy < 0) {
    angle += 360 ;
  } else if (dx < 0) {
    angle += 180;
  } else if (dx === 0) {
    if (dy > 0) {
      angle = 90;
    } else if (dy < 0) {
      angle = 270;
    } else {
      angle = 0;
    }
  }
  return angle;
};
