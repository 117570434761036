import './styles.scss';

import { Checkbox, Modal, TranslatedText } from '@sms/plasma-ui';
import React, { useState } from 'react';

import { useActionCreators } from '../../../hooks';
import { useGenerateDatabaseReportMutation } from '../../../store/api/database';
import { TLogo, TLogosUsed } from '../../../types';
import { ReportLogo } from '../../ReportClientSettings/ReportLogo';

interface IProps {
  isVisible: boolean;
}

const initialLogoUseState = {
  smsLogo: true,
  hdScanLogo: true,
  clientLogo: false,
};

export const DatabaseReportModal = ({ isVisible }: IProps) => {
  const [useLogos, setUseLogos] = useState<TLogosUsed>(initialLogoUseState);
  const [generateCsvTables, setGenerateCsvTables] = useState<boolean>(false);
  const { setDbReportVisible } = useActionCreators();
  const [generateReportTrigger] = useGenerateDatabaseReportMutation();

  const onCancel = () => {
    setDbReportVisible(false);
  };

  const onOk = async () => {
    const { smsLogo, hdScanLogo, clientLogo } = useLogos;
    await generateReportTrigger({
      addSmsLogo: smsLogo,
      addHdScanLogo: hdScanLogo,
      addClientLogo: clientLogo,
      generateCsvTables,
    });
  };

  const handleLogoChange = (id: TLogo) => {
    setUseLogos((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleGenerateCsvTablesChange = () => {
    setGenerateCsvTables((prev) => !prev);
  };

  if (!isVisible) return null;

  return (
    <Modal
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      //@ts-ignore
      okText={<TranslatedText textKey="report.generate-report" />}
      okType="primary"
      onCancel={onCancel}
      onOk={onOk}
      //@ts-ignore
      title={<TranslatedText textKey="report.generate-report" />}
      visible={isVisible}
      width={800}
      zIndex={10}
      className="db-report__modal"
    >
      <ReportLogo {...useLogos} handleLogoChange={handleLogoChange} />
      <Checkbox
        hint="Hint"
        hintMode="hidden"
        name="generateCsvTables"
        onChange={handleGenerateCsvTablesChange}
        title="report.generate-csv-tables"
        checked={generateCsvTables}
      />
    </Modal>
  );
};
