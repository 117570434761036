import { FLAWS_ANALYSIS, SLICE_TYPES } from '../consts';
import { TFlawsAnalysis } from '../types';

export const getCheckedFlawsAnalysis = (currentAnalysis: TFlawsAnalysis, sliceType: string) => {
  if (
    (sliceType === SLICE_TYPES.sampleLong ||
      sliceType === SLICE_TYPES.slabMiddle ||
      sliceType === SLICE_TYPES.slabRight ||
      sliceType === SLICE_TYPES.slabLeft) &&
    currentAnalysis === FLAWS_ANALYSIS.astm
  ) {
    return FLAWS_ANALYSIS.sms;
  }
  if (
    (sliceType === SLICE_TYPES.rect || sliceType === SLICE_TYPES.round || sliceType === SLICE_TYPES.cut) &&
    currentAnalysis === FLAWS_ANALYSIS.sms
  ) {
    return FLAWS_ANALYSIS.gost;
  }

  return currentAnalysis;
};
