import { Checkbox, TextInput, useTranslation } from '@sms/plasma-ui';
import React, { ChangeEvent } from 'react';

import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectReportEmail, selectSendReportEmail } from '../../../store/selectors';
import { isEmailValid } from '../../../utils';

export const ReportEmail = () => {
  const { setReportEmail, setSendReportEmail } = useActionCreators();
  const { t } = useTranslation();
  const sendEmail = useAppSelector(selectSendReportEmail);
  const email = useAppSelector(selectReportEmail);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReportEmail(e.target.value);
  };

  const handleSendEmailChange = () => {
    setSendReportEmail();
  };

  return (
    <div className="report__email-wrapper">
      <TextInput
        label={'common.email'}
        name={'reportEmail'}
        placeholder={t('Type email')}
        value={email}
        onChange={handleEmailChange}
      />
      <Checkbox
        name="sendEmail"
        disabled={!isEmailValid(email)}
        checked={sendEmail}
        title="report.send-email"
        onChange={handleSendEmailChange}
      />
    </div>
  );
};
