import React, { FC, useCallback, useEffect } from 'react';

import {
  MODAL_STATUS,
  MODAL_TITLE,
  RED_PRIMARY,
  SEG_ANALYSIS,
  SHAPES,
  SLICE_TYPES,
  WARNING_MESSAGES,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import {
  selectIsSegHeightAnalysis,
  selectSegAnalysis,
  selectSegForbiddenZones,
  selectSegHeightAnalysisData,
  selectSegMessage,
  selectSegPolygonsCoordinates,
  selectSegSelectedPolygon,
  selectSegZones,
  selectSliceType,
  selectVolumeId,
} from '../../../../store/selectors';
import { IBilletCutParams, IPolygonCoordinates } from '../../../../store/slices/types';
import { RectParams, TTupleOfTwoNumbers } from '../../../../types';
import { getBaseSvgElemStyles, ICartesianModeParams, IGraphParams } from '../../../../utils';
import { HeightAnalysis } from '../../../HeightAnalysis';
import { Polygons } from '../../../Polygons';
import { SegZones } from './SegZones';

interface IProps {
  billetCutParams: IBilletCutParams;
  cartesianModeParams: ICartesianModeParams;
  innerShapeCartesianModeParams: RectParams;
  domains: TTupleOfTwoNumbers;
  billetCutDomains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
}

export const SegSvgElements: FC<IProps> = ({
  billetCutParams,
  cartesianModeParams,
  innerShapeCartesianModeParams,
  domains,
  billetCutDomains,
  graphParams,
}) => {
  const { setSegSelectedPolygon, setMessageModal } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const polygonsCoordinates = useAppSelector(selectSegPolygonsCoordinates);
  const selectedPolygon = useAppSelector(selectSegSelectedPolygon);
  const segAnalysis = useAppSelector(selectSegAnalysis);
  const isHeightAnalysis = useAppSelector(selectIsSegHeightAnalysis);
  const message = useAppSelector(selectSegMessage);
  const zone = useAppSelector(selectSegZones);
  const forbiddenZones = useAppSelector(selectSegForbiddenZones);
  const { heightAnalysisVertical, heightAnalysisHorizontal, firstHorizontalIndex, lastHorizontalIndex, factor } =
    useAppSelector((state) => selectSegHeightAnalysisData(state, volumeId));

  const handlePolygonClick = useCallback(
    (polygon: IPolygonCoordinates) => {
      if (selectedPolygon?.id === polygon.id) {
        setSegSelectedPolygon(null);
      } else {
        setSegSelectedPolygon(polygon);
      }
    },
    [selectedPolygon],
  );

  useEffect(() => {
    if (!message) return;

    setMessageModal({
      type: MODAL_STATUS.WARNING,
      title: MODAL_TITLE.warning,
      description: message,
    });
  }, [message]);

  return (
    <>
      {sliceType === SLICE_TYPES.cut && billetCutParams.shape === SHAPES.rect && segAnalysis !== SEG_ANALYSIS.astm && (
        <rect
          x={cartesianModeParams.x}
          y={cartesianModeParams.y}
          width={cartesianModeParams.realSampleWidth}
          height={cartesianModeParams.realSampleHeight}
          style={getBaseSvgElemStyles(RED_PRIMARY, 1)}
        />
      )}
      {sliceType === SLICE_TYPES.cut && billetCutParams.shape === SHAPES.round && (
        <ellipse
          cx={cartesianModeParams.x + cartesianModeParams.realSampleWidth / 2}
          cy={cartesianModeParams.y + cartesianModeParams.realSampleHeight / 2}
          rx={cartesianModeParams.realSampleWidth / 2}
          ry={cartesianModeParams.realSampleHeight / 2}
          style={getBaseSvgElemStyles(RED_PRIMARY, 1)}
        />
      )}
      {isHeightAnalysis && (
        <HeightAnalysis
          sliceType={sliceType}
          factor={factor}
          heightAnalysisVertical={heightAnalysisVertical}
          heightAnalysisHorizontal={heightAnalysisHorizontal}
          firstHorizontalIndex={firstHorizontalIndex}
          lastHorizontalIndex={lastHorizontalIndex}
          graphHeight={
            sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.height : cartesianModeParams.realSampleHeight
          }
          graphWidth={
            sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.width : cartesianModeParams.realSampleWidth
          }
          domains={domains}
          xOffset={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.x : cartesianModeParams.x}
          yOffset={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.y : cartesianModeParams.y}
        />
      )}
      {zone && (message === null || message === WARNING_MESSAGES.sep) && (
        <SegZones
          graphParams={graphParams}
          cartesianModeParams={cartesianModeParams}
          domains={sliceType === SLICE_TYPES.cut ? billetCutDomains : domains}
          zone={zone}
          forbiddenZones={forbiddenZones}
          message={message}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
        />
      )}
      {polygonsCoordinates && (
        <Polygons
          selectedPolygon={selectedPolygon}
          polygonsCoordinates={polygonsCoordinates}
          domains={sliceType === SLICE_TYPES.cut ? billetCutDomains : domains}
          graphWidth={cartesianModeParams.realSampleWidth}
          graphHeight={cartesianModeParams.realSampleHeight}
          xOffset={cartesianModeParams.x}
          yOffset={cartesianModeParams.y}
          graphParams={cartesianModeParams}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          selectPolygon={handlePolygonClick}
        />
      )}
    </>
  );
};
