import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React, { memo } from 'react';

import { useAppSelector } from '../../../hooks';
import { selectLogoImage } from '../../../store/selectors';
import { TLogo } from '../../../types';

interface Props {
  id: TLogo;
  title: string;
  checked: boolean;
  disabled: boolean;
  src?: string | null;
  onChange: (id: TLogo) => void;
}

export const LogoUse = memo(function LogoUse({ id, title, checked, disabled, src, onChange }: Props) {
  const logoImage = useAppSelector((state) => selectLogoImage(state, id));

  if (!src && !logoImage) return null;

  return (
    <div className="report__logo">
      <img src={src || `data:image/gif;base64,${logoImage}`} alt={title} id={id} />
      <Checkbox
        hint="Hint"
        hintMode="hidden"
        name="logo"
        onChange={() => onChange(id)}
        title={title}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
});
