import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { ICracksBilletRoundZones } from '../../../../../../store/api/cracks/types';
import { TTupleOfTwoNumbers } from '../../../../../../types';
import { ICartesianModeParams } from '../../../../../../utils';
import { Circle } from '../Circle';

interface IProps {
  data: ICracksBilletRoundZones;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
}

export const BilletRoundZones: FC<IProps> = ({ data, domains, cartesianModeParams }) => {
  const { centerPoint, innerZone, middleZone, outerZone } = data;

  return (
    <>
      <Circle
        data={innerZone.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={innerZone.radius}
      />
      <Circle
        data={middleZone.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={middleZone.radius}
      />
      <Circle
        data={outerZone.center}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={outerZone.radius}
      />
      <Circle
        data={centerPoint}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        radius={1}
        fill={ORANGE_PRIMARY}
      />
    </>
  );
};
