import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TBackwallThreshold } from '../../types';
import { IBackwallFlawsData, IBackwallFlawsPolygonCoordinates } from '../api/backwall-flaws/types';

interface IInitialState extends IBackwallFlawsData {
  allowAreaModification: boolean;
  selectedPolygon: IBackwallFlawsPolygonCoordinates | null;
  infoShownPolygons: IBackwallFlawsPolygonCoordinates[];
  isCompleted: boolean;
}

const initialState: IInitialState = {
  allowAreaModification: false,
  lowerThreshold: 0,
  upperThreshold: 0,
  forbiddenZones: [],
  zones: null,
  statistics: null,
  analysisSettingsChanged: false,
  analyzedManually: true,
  containsBigPolygons: false,
  polygonsData: [],
  selectedPolygon: null,
  infoShownPolygons: [],
  isCompleted: false,
};

export const backwallFlawsSlice = createSlice({
  name: 'backwallFlaws',
  initialState,
  reducers: {
    setBackwallFlaws: (state, action: PayloadAction<IBackwallFlawsData>) => {
      const selectedPolygon = action.payload.polygonsData.find(
        (polygon) => polygon.isSelectedPolygon && polygon.isMaxPolygon,
      );
      const infoShownPolygons = action.payload.polygonsData.filter((polygon) => polygon.isSelectedPolygon);

      Object.assign(state, action.payload, {
        infoShownPolygons,
        selectedPolygon: selectedPolygon || null,
      });
    },
    setBackwallFlawsThreshold: (state, action: PayloadAction<{ value: number; threshold: TBackwallThreshold }>) => {
      const { value, threshold } = action.payload;
      state[threshold] = value;
    },
    setBackwallFlawsAreaModification: (state) => {
      state.allowAreaModification = !state.allowAreaModification;
    },
    setBackwallFlawsCompleted: (state) => {
      state.isCompleted = true;
    },
    setBackwallFlawsSelectedPolygon: (state, action: PayloadAction<IBackwallFlawsPolygonCoordinates>) => {
      const polygon = action.payload;
      if (state.selectedPolygon?.id === polygon.id) {
        state.selectedPolygon = null;
      } else {
        state.selectedPolygon = polygon;
      }
    },
    setBackwallFlawsInfoShownPolygons: (state, action: PayloadAction<IBackwallFlawsPolygonCoordinates>) => {
      const polygon = action.payload;
      if (state.infoShownPolygons.find((p) => p.id === polygon.id)) {
        state.infoShownPolygons = state.infoShownPolygons.filter((p) => p.id !== polygon.id);
      } else {
        state.infoShownPolygons.push(polygon);
      }
    },
    resetBackwallFlawsZones: (state) => {
      state.selectedPolygon = null;
      state.infoShownPolygons = [];
    },
  },
});

export const { actions: backwallFlawsActions, reducer: backwallFlaws } = backwallFlawsSlice;
