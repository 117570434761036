import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { TCracksSlabZones } from '../../../../../../store/api/cracks/types';
import { TTupleOfTwoNumbers } from '../../../../../../types';
import { getBaseSvgElemStyles, getXOffsetFunc, getYOffsetFunc, ICartesianModeParams } from '../../../../../../utils';

interface IProps {
  data: TCracksSlabZones;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
}

export const SlabZones: FC<IProps> = ({ data, domains, cartesianModeParams }) => {
  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  return (
    <>
      {data.map(({ start, end }, idx) => (
        <line
          key={idx}
          x1={getXOffset(start.x)}
          y1={getYOffset(start.y)}
          x2={getXOffset(end.x)}
          y2={getYOffset(end.y)}
          style={getBaseSvgElemStyles(ORANGE_PRIMARY, 1)}
        />
      ))}
    </>
  );
};
