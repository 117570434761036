import './styles.scss';

import { NumberInput } from '@sms/plasma-ui';
import classNames from 'classnames';
import React, { memo } from 'react';
// @ts-ignore
import RangeSlider from 'react-range-slider-input';

import { INPUT_NAMES } from '../../consts';
import { TTupleOfTwoNumbers } from '../../types';
import { ISliderRange } from '../../types/scanData';

export type NumberInputValue = number | string | undefined;

interface IRangeBar {
  handleSliderRangeChange: (params: TTupleOfTwoNumbers) => void;
  handleNumberInputChange: (value: NumberInputValue, name: string) => void;
  handleSliderRangeDragEnd?: () => void;
  size: number;
  rangeSliderClasses?: string;
  rangeSlideDisabled?: boolean;
  lowerValue?: number;
  upperValue?: number;
  sliderRange?: ISliderRange;
  precision?: number;
  rangeSliderMinValue?: number;
  step?: number | string;
  value?: TTupleOfTwoNumbers;
  rangeSliderId: string;
  isReversed?: boolean;
  isMaxProjection?: boolean;
  decimalSeparator: string;
}

export const RangeBar: React.FC<IRangeBar> = memo(function RangeBar({
  handleSliderRangeChange,
  handleSliderRangeDragEnd,
  size,
  lowerValue,
  upperValue,
  rangeSlideDisabled = false,
  precision = 0,
  step = 1,
  handleNumberInputChange,
  value,
  rangeSliderMinValue,
  rangeSliderClasses,
  rangeSliderId,
  isReversed,
  isMaxProjection = true,
  decimalSeparator,
}) {
  const topInputName = isReversed ? INPUT_NAMES.lower : INPUT_NAMES.upper;
  const bottomInputName = isReversed ? INPUT_NAMES.upper : INPUT_NAMES.lower;

  const rangeBarClasses = classNames('range-bar', !isMaxProjection && 'single-layer');

  return (
    <div className={rangeBarClasses}>
      <NumberInput
        decimalSeparator={decimalSeparator}
        value={isReversed ? lowerValue : upperValue}
        hintMode="hidden"
        name={topInputName}
        onChange={(value: NumberInputValue) => handleNumberInputChange(value, topInputName)}
        maxWidth={72}
        precision={precision}
        step={step}
      />
      <RangeSlider
        id={rangeSliderId}
        min={rangeSliderMinValue}
        max={size}
        step={step}
        value={value}
        orientation={'vertical'}
        rangeSlideDisabled={rangeSlideDisabled}
        defaultValue={[size, 0]}
        thumbsDisabled={[false, !isMaxProjection]}
        className={rangeSliderClasses}
        onInput={handleSliderRangeChange}
        onThumbDragEnd={handleSliderRangeDragEnd}
        onRangeDragEnd={handleSliderRangeDragEnd}
      />
      <NumberInput
        decimalSeparator={decimalSeparator}
        value={isReversed ? upperValue : lowerValue}
        hintMode="hidden"
        name={bottomInputName}
        onChange={(value: NumberInputValue) => handleNumberInputChange(value, bottomInputName)}
        maxWidth={72}
        disabled={!isMaxProjection}
        precision={precision}
        step={step}
      />
    </div>
  );
});
