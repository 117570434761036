import { createSelector } from '@reduxjs/toolkit';

import { sliceApi } from '../api/slice';
import { TypedRootState } from '../types';
import { selectAutomaticAnalysisResult } from './automaticAnalysis.selector';

const selectBorder = (state: TypedRootState, volumeId: string) =>
  sliceApi.endpoints.getBorderData.select(volumeId)(state)?.data;
export const selectBorderData = createSelector(
  [selectBorder, selectAutomaticAnalysisResult],
  (borderData, automaticAnalysisData) =>
    (borderData || automaticAnalysisData?.borderResponse) || {
      sliceCoordinates: [],
      innerBorder: [],
      innerBorderPx: [],
      outerBorder: [],
      outerBorderPx: [],
      innerBackwallBorderPx: [],
      outerBackwallBorderPx: [],
    }
);
