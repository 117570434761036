import { createSelector } from '@reduxjs/toolkit';

import { TypedRootState } from '../types';

export const selectInfo = createSelector(
  (state: TypedRootState) => state.info,
  ({ manufacturerData, materialAnalysis, productionData, remarks }) => ({
    manufacturerData,
    materialAnalysis,
    productionData,
    remarks,
  })
);
