import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import { selectVolumeId } from '../../../../store/selectors';
import { selectGeometryALines } from '../../../../store/selectors/geometry.selector';

export const ALinesRow = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const { aLeft, aRight } = useAppSelector((state) => selectGeometryALines(state, volumeId));
  return (
    <li className="geometry__statistic__item">
      <p className="geometry__statistic__item__title">
        <TranslatedText textKey="geometry.a-lines" /> [mm]:
      </p>
      <p className="geometry__statistic__item__content">{`${aLeft.replace(',', '.')} / ${aRight.replace(',', '.')}`}</p>
    </li>
  );
};
