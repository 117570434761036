import React from 'react';

import {
  BACKWALL_MODIFICATION_MODES,
  BACKWALL_ZONES_SELECTION_TYPE,
  BLACK_PRIMARY,
  IDs,
  ORANGE_PRIMARY,
  TRANSPARENT,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { useDeleteDistanceZoneMutation, useGetDistanceZonesQuery } from '../../../../store/api/backwall';
import {
  selectAutomaticAnalysisStatus,
  selectDistanceModificationMode,
  selectDistanceSelectionTypes,
  selectPaletteData,
  selectSelectedDistanceIds,
} from '../../../../store/selectors';
import { TTupleOfTwoNumbers } from '../../../../types';
import { ICartesianModeParams } from '../../../../utils';
import { BackwallLine } from './BackwallLine';
import { BackwallLineInsertion } from './BackwallLineInsertion';
import { BackwallRectangle } from './BackwallRectangle';
import { BackwallRectInsertion } from './BackwallRectInsertion';

interface Props {
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
}

export const BackwallSvgElements = ({ cartesianModeParams, domains }: Props) => {
  const {
    resetBackwallSelectedDistanceIds,
    setBackwallSelectedDistanceIds,
    removeBackwallSelectedDistanceIds,
    setSelectedDistanceZoneTypes,
    setSelectedDistanceSelectionTypes,
  } = useActionCreators();
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const { data } = useGetDistanceZonesQuery(undefined, { skip: isAutoAnalysis });
  const { realSampleHeight, realSampleWidth, x: originX, y: originY } = cartesianModeParams;
  const { isMonochrome } = useAppSelector(selectPaletteData);
  const selectedSelectionTypes = useAppSelector(selectDistanceSelectionTypes);
  const modificationMode = useAppSelector(selectDistanceModificationMode);
  const selectedZoneIds = useAppSelector(selectSelectedDistanceIds);
  const [removeZoneTrigger] = useDeleteDistanceZoneMutation();

  const xOffset = (-cartesianModeParams.x * domains[0]) / cartesianModeParams.realSampleWidth;
  const yOffset = (-cartesianModeParams.y * domains[1]) / cartesianModeParams.realSampleHeight;

  const zoneStroke = isMonochrome ? ORANGE_PRIMARY : BLACK_PRIMARY;

  const handleZoneSelection = async (id: number) => {
    if (!modificationMode) return;

    const isIdMatched = selectedZoneIds.includes(id);

    if (modificationMode === BACKWALL_MODIFICATION_MODES.delete) {
      if (isIdMatched) {
        if (selectedZoneIds.length > 1) {
          removeBackwallSelectedDistanceIds(id);
          return;
        }

        await removeZoneTrigger([id]);
        removeBackwallSelectedDistanceIds(id);

        return;
      }
    }

    if (
      modificationMode === BACKWALL_MODIFICATION_MODES.modify ||
      modificationMode === BACKWALL_MODIFICATION_MODES.info
    ) {
      resetBackwallSelectedDistanceIds();

      if (!data?.rectangularZones && !data?.linearZones) return;

      const zone = [...data.linearZones, ...data.rectangularZones].find((z) => z.id === id);

      if (!zone) return;

      setSelectedDistanceZoneTypes([zone.zoneType]);
      if (modificationMode === BACKWALL_MODIFICATION_MODES.info) {
        setSelectedDistanceSelectionTypes(
          'rectangle' in zone ? [BACKWALL_ZONES_SELECTION_TYPE.rectangular] : [BACKWALL_ZONES_SELECTION_TYPE.lines]
        );
      }

      if (isIdMatched) {
        setSelectedDistanceZoneTypes([]);
        setSelectedDistanceSelectionTypes([]);
      }
    }

    if (isIdMatched) {
      removeBackwallSelectedDistanceIds(id);
      return;
    }

    setBackwallSelectedDistanceIds(id);
  };

  return (
    <>
      <g id={IDs.backwallGroupWrapper} transform={`translate(${originX}, ${originY})`}>
        <rect x={0} y={0} width={realSampleWidth} height={realSampleHeight} style={{ fill: TRANSPARENT }} />
        {selectedSelectionTypes.includes(BACKWALL_ZONES_SELECTION_TYPE.rectangular) && (
          <BackwallRectInsertion stroke={zoneStroke} cartesianModeParams={cartesianModeParams} domains={domains} />
        )}
        {selectedSelectionTypes.includes(BACKWALL_ZONES_SELECTION_TYPE.lines) && (
          <BackwallLineInsertion stroke={zoneStroke} cartesianModeParams={cartesianModeParams} domains={domains} />
        )}
        {data?.rectangularZones.map(({ id, ...rect }) => (
          <BackwallRectangle
            key={id}
            id={id}
            isSelected={selectedZoneIds.includes(id)}
            xOffset={xOffset}
            yOffset={yOffset}
            domains={domains}
            cartesianModeParams={cartesianModeParams}
            onZoneSelection={() => handleZoneSelection(id)}
            color={zoneStroke}
            {...rect}
          />
        ))}
        {data?.linearZones.map(({ id, start, end, zoneType }) => (
          <BackwallLine
            key={id}
            id={id}
            domains={domains}
            cartesianModeParams={cartesianModeParams}
            startCoords={start}
            endCoords={end}
            xOffset={xOffset}
            yOffset={yOffset}
            zoneType={zoneType}
            isSelected={selectedZoneIds.includes(id)}
            onZoneSelection={() => handleZoneSelection(id)}
            color={zoneStroke}
          />
        ))}
      </g>
    </>
  );
};
