import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SEG_ANALYSIS } from '../../consts/segregations';
import { TCoordinates, TSegAnalysis } from '../../types';
import {
  ISegData,
  TSegStatistics,
  TSegZone,
} from '../api/segregation/types';
import { IPolygonCoordinates, TRectPayload, TSelectedPolygon } from './types';

interface IInitialState extends ISegData {
  isPoresIncluded: boolean;
  allowAreaModification: boolean;
  isHeightAnalysis: boolean;
  analysis: TSegAnalysis;
  statistics: TSegStatistics;
  selectedPolygon: TSelectedPolygon;
}

const initialState: IInitialState = {
  analysis: SEG_ANALYSIS.mm,
  allowAreaModification: false,
  isHeightAnalysis: false,
  forbiddenZones: [],
  threshold: 0,
  isPoresIncluded: true,
  polygonsCoordinates: [],
  message: null,
  zones: null,
  statistics: null,
  selectedPolygon: null,
};

export const segregationSlice = createSlice({
  name: 'segregation',
  initialState,
  reducers: {
    setSegregation: (state, action: PayloadAction<ISegData>) => {
      state.mmanStatistics = null;
      Object.assign(state, action.payload);
    },
    setSegSelectedPolygon: (state, action: PayloadAction<IPolygonCoordinates | null>) => {
      state.selectedPolygon = action.payload;
    },
    resetSegregation: (state) => {
      Object.assign(state, {
        polygonsCoordinates: initialState.polygonsCoordinates,
        message: initialState.message,
        zones: initialState.zones,
        statistics: initialState.statistics,
      });
    },
    setSegThreshold: (state, action: PayloadAction<number>) => {
      state.threshold = action.payload;
    },
    setSegAnalysis: (state, action: PayloadAction<TSegAnalysis>) => {
      state.analysis = action.payload;
    },
    setSegAreaModification: (state) => {
      state.allowAreaModification = !state.allowAreaModification;
    },
    setSegHeightAnalysis: (state) => {
      state.isHeightAnalysis = !state.isHeightAnalysis;
    },
    setSegZone: (state, action: PayloadAction<TSegZone>) => {
      state.zones = action.payload;
    },
    setSegZoneHeight: (state, action: PayloadAction<number>) => {
      if (state.zones && 'zoneHeight' in state.zones) {
        state.zones.zoneHeight = action.payload;
      }
    },
    setSegZoneWidth: (state, action: PayloadAction<number>) => {
      if (state.zones && 'zoneWidth' in state.zones) {
        state.zones.zoneWidth = action.payload;
      }
    },
    setSegZoneDiameter: (state, action: PayloadAction<number>) => {
      if (state.zones && 'zoneDiameter' in state.zones) {
        state.zones.zoneDiameter = action.payload;
      }
    },
    setSegLineY: (state, action: PayloadAction<number>) => {
      if (state.zones && 'segLineY' in state.zones) {
        state.zones.segLineY = action.payload;
      }
    },
    setSegInnerRectangle: (state, action: PayloadAction<TRectPayload>) => {
      if (state.zones && 'innerRectangle' in state.zones) {
        Object.assign(state.zones.innerRectangle, action.payload);
      }
    },
    setSegCenterPoint: (state, action: PayloadAction<TCoordinates>) => {
      if (state.zones && 'centerPoint' in state.zones) {
        Object.assign(state.zones.centerPoint, action.payload);
      }
    },
    setIsPoreIncluded: (state) => {
      state.isPoresIncluded = !state.isPoresIncluded;
    },
  },
});

export const { actions: segregationActions, reducer: segregation } = segregationSlice;
