import { IMargin } from '../types';

export interface IGraphParams {
  width: number,
  height: number,
  innerSvgHeight: number,
  innerSvgWidth: number,
  xRange: number[],
  yRange: number[],
}

export const getGraphParams = (ref: SVGSVGElement | null, margin: IMargin): IGraphParams => {
  if (ref) {
    const width = ref.clientWidth;
    const height = ref.clientHeight;
    const innerSvgWidth = width - margin.left - margin.right;
    const innerSvgHeight = height - margin.bottom - margin.top;
    const xRange = [0, innerSvgWidth];
    const yRange = [0, innerSvgHeight];

    return { width, height, innerSvgHeight, innerSvgWidth, xRange, yRange };
  }
  return {
    width: 0,
    height: 0,
    innerSvgHeight: 0,
    innerSvgWidth: 0,
    xRange: [0, 0],
    yRange: [0, 0],
  };
};
