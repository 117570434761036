import { Button, Tooltip, TranslatedText, useRouter } from '@sms/plasma-ui';
import React from 'react';

import {
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  GREY_EXTRA_LIGHT,
  GREY_SECONDARY_EXTRA_LIGHT,
  SLICE_TYPES,
  toolbarAnalysisDataValues,
  WHITE_PRIMARY,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { selectFileType, selectSliceType, selectToolbarOptions } from '../../../../store/selectors';
import { checkAppRoute, checkFileType } from '../../../../utils';
import { Icons } from '../../../svg';

interface IToolbarOption {
  title: string;
  icon: JSX.Element;
  active?: boolean;
  disabled?: boolean;
  action?: () => void;
}

export const ToolbarOptions = () => {
  const {
    route: { path },
  } = useRouter('route');
  const { setCartesianMode, setBorder, setDistanceMode, setMaxProjection, setVolume } = useActionCreators();
  const { isCartesianMode, isBorder, isDistanceMode, isMaxProjection, isVolume } = useAppSelector(selectToolbarOptions);
  const fileType = useAppSelector(selectFileType);
  const sliceType = useAppSelector(selectSliceType);
  const { isImageFileType } = checkFileType(fileType);
  const {
    isAdaptImage,
    isImages,
    isInfo,
    isGeometry,
    isReport,
    isD,
    isB,
    isCracks,
    isFlaws,
    isSegregation,
    isBackwall,
    is3D,
    isBCDPlus,
  } = checkAppRoute(path);

  const isProportinalDisabled =
    toolbarAnalysisDataValues.includes(path.split('/')[1]) || isImages || isInfo || isReport || is3D || isAdaptImage;
  const isSumOfLayersDisabled = isImageFileType || isImages || isReport || isInfo || is3D || isGeometry;
  const isDistanceDisabled = isImageFileType || isReport || isGeometry || isImages || isInfo || is3D;
  const isMarginsDisabled =
    isImageFileType ||
    isGeometry ||
    isImages ||
    isInfo ||
    isReport ||
    isCracks ||
    isBackwall ||
    isB ||
    isD ||
    is3D ||
    ((isSegregation || isFlaws) && sliceType === SLICE_TYPES.cut) ||
    !sliceType;
  const isVolumeDisabled = !is3D && !isBCDPlus;

  const cartesianModeHandler = () => {
    setCartesianMode();
  };
  const bordersHandler = () => {
    setBorder();
  };
  const distanceModeHandler = () => {
    setDistanceMode();
  };
  const maxProjectionHandler = () => {
    setMaxProjection();
  };
  const volumeHandler = () => {
    setVolume();
  };

  const toolbarOptions: IToolbarOption[] = [
    {
      title: 'toolbar.options.proportional',
      icon: <Icons.Cartesian fill={isCartesianMode ? WHITE_PRIMARY : BLACK_PRIMARY} disabled={isProportinalDisabled} />,
      active: isCartesianMode,
      action: cartesianModeHandler,
      disabled: isProportinalDisabled,
    },
    {
      title: 'toolbar.options.sum-of-layers',
      icon: <Icons.MathSum fill={isMaxProjection ? WHITE_PRIMARY : BLACK_PRIMARY} disabled={isSumOfLayersDisabled} />,
      active: isMaxProjection,
      action: maxProjectionHandler,
      disabled: isSumOfLayersDisabled,
    },
    {
      title: 'toolbar.options.margins',
      icon: <Icons.Square fill={isBorder ? WHITE_PRIMARY : BLACK_PRIMARY} disabled={isMarginsDisabled} />,
      active: isBorder,
      action: bordersHandler,
      disabled: isMarginsDisabled,
    },
    {
      title: 'toolbar.options.volume',
      icon: <Icons.Cube fill={isVolume ? WHITE_PRIMARY : BLACK_PRIMARY} disabled={isVolumeDisabled} />,
      active: isVolume,
      action: volumeHandler,
      disabled: isVolumeDisabled,
    },
    {
      title: 'toolbar.options.distance',
      icon: <Icons.Ruler fill={isDistanceMode ? WHITE_PRIMARY : BLACK_PRIMARY} disabled={isDistanceDisabled} />,
      active: isDistanceMode,
      action: distanceModeHandler,
      disabled: isDistanceDisabled,
    },
  ];

  return (
    <div className="toolbar__options">
      {toolbarOptions.map(({ title, icon, action, active, disabled }) => (
        <Tooltip
          key={title}
          autoAdjustOverflow
          color={GREY_EXTRA_LIGHT}
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0.1}
          placement="top"
          title={<TranslatedText textKey={title} />}
          trigger="hover"
        >
          <Button
            name={title}
            icon={icon}
            disabled={disabled}
            onClick={action}
            style={{
              backgroundColor: active ? BLUE_PRIMARY : 'transparent',
              borderColor: active ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
            }}
          />
        </Tooltip>
      ))}
    </div>
  );
};
