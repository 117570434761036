import { Button, RadioGroup, TextArea, TextInput, Tooltip, TranslatedText, useTranslation } from '@sms/plasma-ui';
import { Checkbox } from '@sms/plasma-ui/lib/components/Checkbox/Checkbox';
import React, { ChangeEvent } from 'react';

import {
  MODAL_STATUS,
  MODAL_TITLE,
  RECIPE_ANALYSIS_SECTIONS_TITLE,
  RECIPE_MODAL_MODES,
  RECIPE_SLICE_TYPE_DATA,
  SLICE_TYPE_VALUE_FROM_NUM,
  WHITE_PRIMARY,
} from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useSaveRecipeTemplateMutation, useUpdateRecipeTemplateMutation } from '../../../store/api/recipes-template';
import {
  selectAllRecipeTemplates,
  selectIsSelectedAnalysisTypes,
  selectRecipeDescription,
  selectRecipeName,
  selectRecipePerformAnalysis,
  selectRecipeSliceType,
} from '../../../store/selectors';
import { RadioGroupData, typedEntries } from '../../../types';
import { createModalMessageBody } from '../../../utils';

export const RecipeMainDataForm = () => {
  const name = useAppSelector(selectRecipeName);
  const sliceType = useAppSelector(selectRecipeSliceType) as keyof typeof SLICE_TYPE_VALUE_FROM_NUM;
  const description = useAppSelector(selectRecipeDescription);
  const performAnalysis = useAppSelector(selectRecipePerformAnalysis);
  const isAnalysisTypesSelected = useAppSelector(selectIsSelectedAnalysisTypes);
  const allRecipeTemplates = useAppSelector(selectAllRecipeTemplates);
  const { setRecipeMainData, setRecipeSliceType, setRecipePerformAnalysis, setRecipesListVisible, setMessageModal } =
    useActionCreators();
  const { t } = useTranslation();
  const [saveRecipeTrigger] = useSaveRecipeTemplateMutation();
  const [updateRecipeTrigger] = useUpdateRecipeTemplateMutation();

  const handleTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    const { value } = e.target;
    setRecipeMainData({ field, value });
  };

  const handleSliceTypeChange = (e: any) => {
    setRecipeSliceType(e.target.value);
  };

  const handleLoadRecipeClick = () => {
    setRecipesListVisible(RECIPE_MODAL_MODES.loadRecipe);
  };

  const handleSaveRecipeClick = () => {
    const templateWithSameName = allRecipeTemplates?.find((template) => template.name === name);

    if (templateWithSameName) {
      updateRecipeTrigger(templateWithSameName.id).then(() =>
        setMessageModal(
          createModalMessageBody(
            MODAL_STATUS.SUCCESS,
            MODAL_TITLE.notification,
            t('modals.message.update-recipe', { name }),
          ),
        ),
      );

      return;
    }

    saveRecipeTrigger().then(() =>
      setMessageModal(
        createModalMessageBody(
          MODAL_STATUS.SUCCESS,
          MODAL_TITLE.notification,
          t('modals.message.save-recipe', { name }),
        ),
      ),
    );
  };

  return (
    <div className="recipe__form-section">
      <TextInput
        hint=""
        hintMode="hidden"
        label={'report.filename'}
        name="name"
        value={name}
        onChange={(e) => handleTextChange(e, 'name')}
      />
      <TextArea
        bordered
        hintMode="hidden"
        label={'common.description'}
        value={description}
        name="description"
        onChange={(e) => handleTextChange(e, 'description')}
      />
      <Button title={'recipes.load-recipe'} onClick={handleLoadRecipeClick} />
      <Tooltip
        title={<TranslatedText textKey="recipes.save-recipe-tooltip" />}
        trigger="hover"
        placement="top"
        color={WHITE_PRIMARY}
        overlayStyle={{ display: name && isAnalysisTypesSelected ? 'none' : '' }}
      >
        <div className="button-tooltip-container">
          <Button
            title={'recipes.save-recipe'}
            onClick={handleSaveRecipeClick}
            disabled={!name || !isAnalysisTypesSelected}
          />
        </div>
      </Tooltip>
      <div>
        <h3 className="sub-menu__title">
          <TranslatedText textKey="Slice type" />
        </h3>
        <RadioGroup<RadioGroupData>
          name="geometryAnalysisRadioGroup"
          dataSource={RECIPE_SLICE_TYPE_DATA}
          value={SLICE_TYPE_VALUE_FROM_NUM[sliceType]}
          onChange={handleSliceTypeChange}
        />
      </div>
      <div>
        <h3 className="sub-menu__title">
          <TranslatedText textKey="recipes.analysis-types" />
        </h3>
        {typedEntries(RECIPE_ANALYSIS_SECTIONS_TITLE).map(([typeDiscriminator, title]) => (
          <Checkbox
            key={typeDiscriminator}
            name={typeDiscriminator}
            title={title}
            checked={performAnalysis[typeDiscriminator]}
            onChange={() => setRecipePerformAnalysis(typeDiscriminator)}
          />
        ))}
      </div>
    </div>
  );
};
