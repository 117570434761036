import './styles.scss';

import React from 'react';

import { InfoForm } from '../../components/InfoForm';
import { DefaultLayout } from '../../layouts/DefaultLayout';

export const Info = () => {
  return (
    <DefaultLayout>
      <div className="page-container info__container">
        <InfoForm />
      </div>
    </DefaultLayout>
  );
};
