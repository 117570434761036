//@ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { BLUE_PRIMARY, IDs } from '../../../../../consts';
import { ISVGLineParams } from '../../../../../types';
import { getBaseSvgElemStyles, getLineAngle, ICartesianModeParams } from '../../../../../utils';

interface IProps {
  cartesianModeParams: ICartesianModeParams;
  rotationAngle: number;
  prevRotationAngle: number;
  allowAxesRotation: boolean;
  setRotationAngle: (angle: number) => void;
  dragendAction: (angle: number) => void;
  dragAction: (angle: number, prevAngle: number) => void;
}

export const CoordinateSystem: FC<IProps> = ({
  cartesianModeParams,
  rotationAngle,
  prevRotationAngle,
  setRotationAngle,
  dragendAction,
  dragAction,
  allowAxesRotation,
}) => {
  const { x, y, realSampleHeight, realSampleWidth } = cartesianModeParams;

  const l1Y1 = y - 0.1 * realSampleHeight;
  const l1Y2 = y + 1.1 * realSampleHeight;
  const l2X1 = x - 0.1 * realSampleWidth;
  const l2X2 = x + 1.1 * realSampleWidth;
  const l2Y = y + realSampleHeight;

  useEffect(() => {
    const thirdAuxiliaryLine = d3.select('.thirdAuxiliaryLine');
    const fourthAuxiliaryLine = d3.select('.fourthAuxiliaryLine');
    const lines = [thirdAuxiliaryLine, fourthAuxiliaryLine];

    thirdAuxiliaryLine
      .datum({ x1: x, y1: l1Y1, x2: x, y2: l1Y2 })
      .attr('cursor', 'grab');

    fourthAuxiliaryLine
      .datum({ x1: l2X1, y1: l2Y, x2: l2X2, y2: l2Y })
      .attr('cursor', 'grab');

    if (!allowAxesRotation) {
      thirdAuxiliaryLine.attr('cursor', 'auto');
      fourthAuxiliaryLine.attr('cursor', 'auto');

      return;
    }

    function dragstarted(this: SVGLineElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGLineElement, event: MouseEvent, d: ISVGLineParams) {
      if (d3.select(this).node().classList.value === IDs.thirdAuxiliaryLine) {
        const angle = 90 - getLineAngle(d.y2 - event.y, event.x - d.x2);

        dragAction(angle, prevRotationAngle);
        setRotationAngle(angle);
      }

      if (d3.select(this).node().classList.value === IDs.fourthAuxiliaryLine) {
        const angle = -getLineAngle(d.y1 - event.y, event.x - d.x1);

        dragAction(angle, prevRotationAngle);
        setRotationAngle(angle);
      }
    }

    function dragended(this: SVGLineElement, event: MouseEvent, d: ISVGLineParams) {
      d3.select(this).attr('cursor', 'grab');

      if (d3.select(this).node().classList.value === IDs.thirdAuxiliaryLine) {
        const angle = 90 - getLineAngle(d.y2 - event.y, event.x - d.x2);

        dragendAction(angle);

      }

      if (d3.select(this).node().classList.value === IDs.fourthAuxiliaryLine) {
        const angle = -getLineAngle(d.y1 - event.y, event.x - d.x1);

        dragendAction(angle);
      }

    }

    lines.forEach(line => {
      line.call(
        d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );
    });

    return () => {
      lines.forEach(line => {
        line.call(
          d3.drag()
            .on('start', null)
            .on('drag', null)
            .on('end', null)
        );
      });
    };
  }, [l1Y1, l1Y2, l2X1, l2X2, l2Y, x, dragendAction, dragAction, allowAxesRotation, rotationAngle, prevRotationAngle]);

  return (
    <>
      <line
        className={IDs.thirdAuxiliaryLine}
        x1={x}
        y1={l1Y1}
        x2={x}
        y2={l1Y2}
        style={{
          ...getBaseSvgElemStyles(BLUE_PRIMARY, 2),
          transformBox: 'fill-box',
          transformOrigin: `50% ${1.1 * realSampleHeight}px`,
          rotate: `${rotationAngle}deg`,
        }}
      />
      <line
        className={IDs.fourthAuxiliaryLine}
        x1={l2X1}
        y1={l2Y}
        x2={l2X2}
        y2={l2Y}
        style={{
          ...getBaseSvgElemStyles(BLUE_PRIMARY, 2),
          transformBox: 'fill-box',
          transformOrigin: `${0.1 * realSampleWidth}px 50%`,
          rotate: `${rotationAngle}deg`,
        }}
      />
    </>
  );
};

