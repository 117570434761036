import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import React, { FC } from 'react';

import { IMMStatistics, TSegStatistics } from '../../../../../store/api/segregation/types';

interface IProps {
  statistics: TSegStatistics;
  mmStatistics: IMMStatistics | null | undefined;
}

export const ZoneWidthRow: FC<IProps> = ({ statistics, mmStatistics }) => {
  return (
    <>
      {statistics && 'zoneWidth' in statistics && (
        <li className="seg__statistics__item">
          <p>
            <TranslatedText textKey="Zone width" /> [mm]:
          </p>
          <p>{statistics.zoneWidth + `${mmStatistics ? ` (${mmStatistics?.zoneWidth})` : ''}`}</p>
        </li>
      )}
    </>
  );
};
