import './styles.scss';

import { Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import classNames from 'classnames';
import React, { useState } from 'react';

import { SLICE_TYPES } from '../../../../consts/geometry';
import { SEG_ANALYSIS } from '../../../../consts/segregations';
import { useAppSelector } from '../../../../hooks';
import { TSegStatistics } from '../../../../store/api/segregation/types';
import {
  selectMMStatistics,
  selectSegAnalysis,
  selectSegStatistics,
  selectSegZones,
  selectSliceType,
} from '../../../../store/selectors';
import { AreaRow } from './AreaRow';
import { CenterLineRow } from './CenterLineRow';
import { CenterPointRow } from './CenterPointRow';
import { DecimalClassRow } from './DecimalClassRow';
import { DefectsAreaPercentRow } from './DefectsAreaPercentRow';
import { DefectsCZoneList } from './DefectsCZoneList';
import { DefectsRZoneList } from './DefectsRZoneList';
import { DistributionRow } from './DistributionRow';
import { NormAreaRow } from './NormAreaRow';
import { PoresClassRow } from './PoresClassRow';
import { PoresDistributionRow } from './PoresDistributionRow';
import { PoresSpotsNumberList } from './PoresSpotsNumberList';
import { SegClassRow } from './SegClassRow';
import { SegSpotsNumberList } from './SegSpotsNumberList';
import { SepClassRow } from './SepClassRow';
import { SpotsNumberList } from './SpotsNumberList';
import { ZoneHeightRow } from './ZoneHeightRow';
import { ZoneWidthRow } from './ZoneWidthRow';

export interface IProps {
  statistics: TSegStatistics;
}

export const DefectsStatistics = () => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const segStatistics = useAppSelector(selectSegStatistics);
  const mmStatistics = useAppSelector(selectMMStatistics);
  const segAnalysis = useAppSelector(selectSegAnalysis);
  const segZone = useAppSelector(selectSegZones);
  const sliceType = useAppSelector(selectSliceType);

  const classes = classNames('seg__statistics__spots', {
    astm__spots:
      segAnalysis === SEG_ANALYSIS.astm &&
      sliceType !== SLICE_TYPES.sampleLong &&
      sliceType !== SLICE_TYPES.slabLeft &&
      sliceType !== SLICE_TYPES.slabMiddle &&
      sliceType !== SLICE_TYPES.slabRight,
    gost__spots: segAnalysis === SEG_ANALYSIS.gost,
  });

  return (
    <>
      {segAnalysis === SEG_ANALYSIS.gost ? (
        <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
          <TabPane tab={<TranslatedText textKey="pages.segregation" />} key={'0'}>
            <div className={classes}>
              <div className="column first-column">
                <SegSpotsNumberList statistics={segStatistics} />
              </div>
              <div className="column second-column">
                <ul className="seg__statistics__list second-column">
                  <DistributionRow statistics={segStatistics} />
                  <SegClassRow statistics={segStatistics} />
                  <CenterLineRow zone={segZone} />
                  <ZoneHeightRow statistics={segStatistics} />
                </ul>
              </div>
            </div>
          </TabPane>
          <TabPane tab={<TranslatedText textKey="side-menu.pores" />} key={'1'}>
            <div className={classes}>
              <div className="column first-column">
                <PoresSpotsNumberList statistics={segStatistics} />
              </div>
              <div className="column second-column">
                <ul className="seg__statistics__list second-column">
                  <PoresDistributionRow statistics={segStatistics} />
                  <PoresClassRow statistics={segStatistics} />
                  <CenterLineRow zone={segZone} />
                  <ZoneHeightRow statistics={segStatistics} />
                </ul>
              </div>
            </div>
          </TabPane>
        </Tabs>
      ) : (
        <div className={classes}>
          <div className="column first-column">
            <SpotsNumberList statistics={segStatistics} mmStatistics={mmStatistics} />
            <DefectsCZoneList statistics={segStatistics} />
            <DefectsRZoneList statistics={segStatistics} />
            <DefectsRZoneList statistics={segStatistics} />
          </div>
          <div className="column second-column">
            <ul className="seg__statistics__list second-column">
              <DefectsAreaPercentRow statistics={segStatistics} />
              <SegClassRow statistics={segStatistics} />
              <CenterPointRow segZone={segZone} segAnalysis={segAnalysis} />
              <SepClassRow statistics={segStatistics} mmStatistics={mmStatistics} />
              <DecimalClassRow statistics={segStatistics} />
              <CenterLineRow zone={segZone} />
              <ZoneHeightRow statistics={segStatistics} />
              <AreaRow statistics={segStatistics} mmStatistics={mmStatistics} />
              <NormAreaRow statistics={segStatistics} mmStatistics={mmStatistics} />
              <ZoneWidthRow statistics={segStatistics} mmStatistics={mmStatistics} />
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
