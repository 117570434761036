import React, { ChangeEvent, useCallback } from 'react';

import { REMARKS_NAMES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectRemarks } from '../../../store/selectors/remarks.selector';
import { Remarks } from '../../Remarks';

export const WrappedRemarks = () => {
  const { setZonesRemarks } = useActionCreators();
  const { forbiddenZones } = useAppSelector(selectRemarks);

  const handleRemarksChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setZonesRemarks(e.target.value);
  }, []);

  return <Remarks value={forbiddenZones} label={'Remarks'} name={REMARKS_NAMES.zones} onChange={handleRemarksChange} />;
};
