import { IToolbarState } from '../slices';
import { IDistances } from '../slices/types';
import { TypedRootState } from '../types';

export const selectToolbarOptions = (state: TypedRootState): IToolbarState => state.toolbarOptions;
export const selectIsCartesianMode = (state: TypedRootState): boolean => state.toolbarOptions.isCartesianMode;
export const selectIsBorder = (state: TypedRootState): boolean => state.toolbarOptions.isBorder;
export const selectIsDistanceMode = (state: TypedRootState): boolean => state.toolbarOptions.isDistanceMode;
export const selectIsMaxProjection = (state: TypedRootState): boolean => state.toolbarOptions.isMaxProjection;
export const selectIsVolume = (state: TypedRootState): boolean => state.toolbarOptions.isVolume;
export const selectDistances = (state: TypedRootState): IDistances => state.toolbarOptions.distances;
