import { INFO_SECTIONS } from '../consts';

const INPUT_KEYS_WITH_PRECISION_TWO = [
  'heightAnalysisOffest',
  'thresholdCoefficient',
  'maxPolygonCutOffRatio',
  'minPolygonArea',
  'minPoreArea',
  'minInnerDiameter',
  'zoneFraction',
  'leftRightMargin',
  'mmZoneHeight',
  'sepZoneWidth',
  'smsZoneOffsetFromBorderCoefficient',
  'smsZoneHeightCoefficient',
  'flawsCutOff',
  'haFactor',
  'rangeStartSliceC',
  'rangeEndSliceC',
  'backwallStartRange',
  'backwallEndRange',
  'backwallRangeDifference',
  'minDeviationMm',
  'dimensionsOffest',
  'ZoneFraction',
  'StartRange',
  'EndRange',
  'rangeStart',
  'rangeEnd',
];

export const getNumberInputPrecision = (section: string, id: string) => {
  if (section === INFO_SECTIONS.materialAnalysis) return 3;

  if (INPUT_KEYS_WITH_PRECISION_TWO.includes(id)) return 2;

  return 0;
};

export const isStartEndNumberInputValid = (startValue: number | string | null, endValue: number | string | null) => {
  const isEmpty = startValue === '' || endValue === '';

  return !isEmpty && endValue !== null && startValue !== null && endValue > startValue;
};

export const OPPOSITE_NUMBER_INPUT_IDS = {
  StartRange: 'EndRange',
  EndRange: 'StartRange',
  'Palette.RangeStart': 'Palette.RangeEnd',
  'Palette.RangeEnd': 'Palette.RangeStart',
  paletteRangeStart: 'paletteRangeEnd',
  paletteRangeEnd: 'paletteRangeStart',
  LowerThreshold: 'UpperThreshold',
  UpperThreshold: 'LowerThreshold',
  rangeStart: 'rangeEnd',
  rangeEnd: 'rangeStart',
};
export const START_INPUT_IDS = [
  'StartRange',
  'Palette.RangeStart',
  'LowerThreshold',
  'paletteRangeStart',
  'rangeStart',
];
const PALETTE_KEYS = [
  'Palette.RangeStart',
  'Palette.RangeEnd',
  'UpperThreshold',
  'paletteRangeStart',
  'paletteRangeEnd',
];

export const isStartEndNumberInput = (id: string) => Object.keys(OPPOSITE_NUMBER_INPUT_IDS).includes(id);
export const getNumberInputRange = (id: string): 'start' | 'end' => (START_INPUT_IDS.includes(id) ? 'start' : 'end');

export const getStartEndKeys = (id: string, range: 'start' | 'end') => {
  if (range === 'start') {
    return { startKey: id, endKey: OPPOSITE_NUMBER_INPUT_IDS[id as keyof typeof OPPOSITE_NUMBER_INPUT_IDS] };
  }

  return { startKey: OPPOSITE_NUMBER_INPUT_IDS[id as keyof typeof OPPOSITE_NUMBER_INPUT_IDS], endKey: id };
};

export const getRangeAndStartEndInputsKeys = (id: string) => {
  const range = getNumberInputRange(id);
  const { startKey, endKey } = getStartEndKeys(id, range);

  return { range, startKey, endKey };
};
export const isPaletteKey = (id: string) => PALETTE_KEYS.includes(id);

export const isMaxDefensiveValueInput = (id: string) =>
  isPaletteKey(id) ||
  id === 'poresThreshold' ||
  id === 'ManualThreshold' ||
  id === 'LowerThreshold' ||
  id === 'UpperThreshold';
