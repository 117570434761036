import { TranslatedText } from '@sms/plasma-ui';
import React, { FC, Fragment } from 'react';

import { BLACK_PRIMARY, GREEN_PRIMARY } from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { selectBorderData } from '../../../../store/selectors';
import { selectGeometryALines } from '../../../../store/selectors/geometry.selector';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  getBaseSvgElemStyles,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { Path } from '../../../Path';

interface IProps {
  volumeId: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
}

export const GeometryALines: FC<IProps> = ({ volumeId, cartesianModeParams, domains, graphParams }) => {
  const { outerBorder } = useAppSelector((state) => selectBorderData(state, volumeId));
  const {
    aLeft,
    aRight,
    leftALinePoints,
    rightALinePoints,
    leftMedianPoints,
    rightMedianPoints,
  } = useAppSelector((state) => selectGeometryALines(state, volumeId));

  const lines = [
    { line: 'geometry.left-median', coords: leftMedianPoints, isEmpty: false },
    { line: 'geometry.right-median', coords: rightMedianPoints, isEmpty: false },
    { line: 'geometry.left-a-line', coords: leftALinePoints, isEmpty: isNaN(+aLeft) },
    { line: 'geometry.right-a-line', coords: rightALinePoints, isEmpty: isNaN(+aRight) },
  ];

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  return (
    <>
      <Path
        cartesianModeParams={cartesianModeParams}
        data={outerBorder}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
        style={getBaseSvgElemStyles(GREEN_PRIMARY, 1.5)}
      />
      {lines.map(({ line, coords, isEmpty }) => {
        if (isEmpty || coords.length === 0) return null;

        return (
          <Fragment key={line}>
            <line
              x1={getXOffset(coords[0].x)}
              y1={getYOffset(coords[0].y)}
              x2={getXOffset(coords[1].x)}
              y2={getYOffset(coords[1].y)}
              stroke={BLACK_PRIMARY}
            />
            <text
              x={getXOffset(Math.min(coords[0].x, coords[1].x) + 5)}
              y={getYOffset(Math.max(coords[0].y, coords[1].y) - 10)}
            >
              <TranslatedText textKey={line} />
            </text>
          </Fragment>
        );
      })}
    </>
  );
};
