import './styles.scss';

import { navigate, Tabs, TranslatedText } from '@sms/plasma-ui';
import { TabPane } from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GEOMETRY_TABS_TITLES } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { useAutoAnalysisNavigate } from '../../hooks/useAutoAnalysisNavigate';
import {
  selectAutomaticAnalysisStatus,
  selectFileType,
  selectScanDataParameters,
  selectSliceType,
  selectVolumeId,
} from '../../store/selectors';
import { checkFileType, getGeometryTabs } from '../../utils';
import { GeometryGraph } from './GeometryGraph';

export const GeometryGraphWindow = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const { isLoading } = useAppSelector((state) => state.modals.spinner);
  const { setSpinnerVisible } = useActionCreators();
  const [activeKey, setActiveKey] = useState<string>('0');
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const tabs = getGeometryTabs(sliceType, isAutoAnalysis);
  const autoAnalysisNavigate = useAutoAnalysisNavigate();
  const fileType = useAppSelector(selectFileType);
  const { isIDVFileType } = useMemo(() => checkFileType(fileType), [fileType]);

  const handleAutoTabSwitching = useCallback(() => {
    if (!isLoading && !isAutoAnalysis) return;

    setActiveKey((prev) => (+prev + 1).toString());

    if (+activeKey === tabs.length - 1) {
      if (isAutoAnalysis) {
        autoAnalysisNavigate();
      } else {
        setActiveKey('0');
        navigate(isIDVFileType ? '/bcdplus' : '/adapt-image');
        setSpinnerVisible(false);
      }
    }
  }, [isLoading, activeKey, isAutoAnalysis, tabs, isIDVFileType]);

  useEffect(() => {
    if (+activeKey > tabs.length - 1) {
      setActiveKey('0');
    }
  }, [activeKey, sliceType, tabs]);

  return (
    <div className="geometry__result">
      <div>
        <b>
          <TranslatedText textKey="side-menu.result" />
        </b>
      </div>
      {sliceType && (
        <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
          {scanDataParameters &&
            tabs.map((tab, idx) => (
              <TabPane tab={<TranslatedText textKey={GEOMETRY_TABS_TITLES[tab]} />} key={idx.toString()}>
                <GeometryGraph
                  volumeId={volumeId}
                  tab={tab}
                  scanDataParameters={scanDataParameters}
                  handleAutoTabSwitching={handleAutoTabSwitching}
                />
              </TabPane>
            ))}
        </Tabs>
      )}
    </div>
  );
};
