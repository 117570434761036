import { IRecipeTemplateInfo } from '../store/api/recipes/types';
import { IRecipeFlawZone, IRecipeForFiles, RadioGroupData, TRecipeAnalysisTypes, TTypeDiscriminator } from '../types';
import { SLICE_TYPES } from './geometry';

export const SLICE_TYPE_NUM = {
  [SLICE_TYPES.slabMiddle]: 1,
  [SLICE_TYPES.slabRight]: 2,
  [SLICE_TYPES.slabLeft]: 3,
  [SLICE_TYPES.rect]: 4,
  [SLICE_TYPES.round]: 5,
  [SLICE_TYPES.sampleLong]: 6,
};

export const SLICE_TYPE_VALUE_FROM_NUM = {
  1: SLICE_TYPES.slabMiddle,
  2: SLICE_TYPES.slabRight,
  3: SLICE_TYPES.slabLeft,
  4: SLICE_TYPES.rect,
  5: SLICE_TYPES.round,
  6: SLICE_TYPES.sampleLong,
};

export const RECIPE_SLICE_TYPE_DATA = Object.values(SLICE_TYPE_VALUE_FROM_NUM).map((side) => ({
  value: side,
  label: side,
}));

export const TYPE_DISCRIMINATOR = {
  cracksAnalysisStageModel: ' 0',
  backwallAnalysisStageModel: ' 1',
  segregationsAnalysisStageModel: ' 2',
  flawsAnalysisStageModel: ' 3',
} as const;

export const RECIPE_ANALYSIS_TYPES_KEYS = {
  Mannesmann: 'Mannesmann',
  SMS: 'SMS',
  GOST: 'GOST',
  ASTM: 'ASTM',
  SEP1: 'SEP (1mm)',
  SEP100: 'SEP (100mm)',
  Flaws: 'Flaws',
  Distance: 'Distance',
} as const;

export const RECIPE_ANALYSIS_TYPES_NUM = {
  [RECIPE_ANALYSIS_TYPES_KEYS.Mannesmann]: 1,
  [RECIPE_ANALYSIS_TYPES_KEYS.SMS]: 2,
  [RECIPE_ANALYSIS_TYPES_KEYS.GOST]: 3,
  [RECIPE_ANALYSIS_TYPES_KEYS.ASTM]: 4,
  [RECIPE_ANALYSIS_TYPES_KEYS.SEP1]: 5,
  [RECIPE_ANALYSIS_TYPES_KEYS.SEP100]: 6,
  [RECIPE_ANALYSIS_TYPES_KEYS.Flaws]: 7,
  [RECIPE_ANALYSIS_TYPES_KEYS.Distance]: 8,
};

export const SEP1_NUM = RECIPE_ANALYSIS_TYPES_NUM[RECIPE_ANALYSIS_TYPES_KEYS.SEP1];
export const SEP100_NUM = RECIPE_ANALYSIS_TYPES_NUM[RECIPE_ANALYSIS_TYPES_KEYS.SEP100];

export const RECIPE_ANALYSIS_SECTIONS_TITLE = {
  [TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]: 'zones.seg-analysis',
  [TYPE_DISCRIMINATOR.flawsAnalysisStageModel]: 'zones.flaw-analysis',
  [TYPE_DISCRIMINATOR.cracksAnalysisStageModel]: 'zones.crack-analysis',
  [TYPE_DISCRIMINATOR.backwallAnalysisStageModel]: 'zones.backwall-analysis',
};

// export const RECIPE_PERFORM_ANALYSIS_TITLE = {
//   [TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]: 'zones.seg-analysis',
//   [TYPE_DISCRIMINATOR.cracksAnalysisStageModel]: 'zones.crack-analysis',
//   [TYPE_DISCRIMINATOR.flawsAnalysisStageModel]: 'zones.flaw-analysis',
//   [TYPE_DISCRIMINATOR.backwallAnalysisStageModel]: 'zones.backwall-analysis',
// };

export const RECIPE_ANALYSIS_ANALYSIS_TYPES: Record<
  TTypeDiscriminator,
  (TRecipeAnalysisTypes | Record<'SEP', RadioGroupData[]>)[]
> = {
  [TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]: [
    RECIPE_ANALYSIS_TYPES_KEYS.Mannesmann,
    RECIPE_ANALYSIS_TYPES_KEYS.GOST,
    {
      SEP: [
        { label: RECIPE_ANALYSIS_TYPES_KEYS.SEP100, value: RECIPE_ANALYSIS_TYPES_KEYS.SEP100 },
        { label: RECIPE_ANALYSIS_TYPES_KEYS.SEP1, value: RECIPE_ANALYSIS_TYPES_KEYS.SEP1 },
      ],
    },
    RECIPE_ANALYSIS_TYPES_KEYS.ASTM,
  ],
  [TYPE_DISCRIMINATOR.cracksAnalysisStageModel]: [
    RECIPE_ANALYSIS_TYPES_KEYS.SMS,
    RECIPE_ANALYSIS_TYPES_KEYS.GOST,
    RECIPE_ANALYSIS_TYPES_KEYS.ASTM,
  ],
  [TYPE_DISCRIMINATOR.flawsAnalysisStageModel]: [
    RECIPE_ANALYSIS_TYPES_KEYS.SMS,
    RECIPE_ANALYSIS_TYPES_KEYS.GOST,
    RECIPE_ANALYSIS_TYPES_KEYS.ASTM,
  ],
  [TYPE_DISCRIMINATOR.backwallAnalysisStageModel]: [
    RECIPE_ANALYSIS_TYPES_KEYS.Distance,
    RECIPE_ANALYSIS_TYPES_KEYS.Flaws,
  ],
};

export const RECIPE_ANALYSIS_FIELDS = {
  [TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]: {
    StartRange: 'settings.segregationsSettings.rangeStart',
    EndRange: 'settings.segregationsSettings.rangeEnd',
    ManualThreshold: 'Threshold',
    IsAutoThreshold: 'side-menu.auto-threshold',
    SelectedAnalysisTypes: 'recipes.analysis-types',
    ZoneHeight: 'Zone height',
    ZoneFraction: 'settings.segregationsSettings.zoneFraction',
    IsPoresIncluded: 'side-menu.include-pores',
    LeftRightMargin: 'recipes.rl-margin',
    IsCfgMargin: 'recipes.use-config-value',
    Palette: '',
  },
  [TYPE_DISCRIMINATOR.flawsAnalysisStageModel]: {
    StartRange: 'settings.flawsSettings.rangeStart',
    EndRange: 'settings.flawsSettings.rangeEnd',
    ManualThreshold: 'Threshold',
    IsAutoThreshold: 'side-menu.auto-threshold',
    SelectedAnalysisTypes: 'recipes.analysis-types',
    IsAutoZones: 'recipes.auto-zones',
    SetZones: '',
    LeftRightMargin: 'recipes.rl-margin',
    IsCfgMargin: 'recipes.use-config-value',
    Zones: 'Zones',
    Palette: '',
  },
  [TYPE_DISCRIMINATOR.cracksAnalysisStageModel]: {
    StartRange: 'settings.cracksSettings.rangeStart',
    EndRange: 'settings.cracksSettings.rangeEnd',
    ManualThreshold: 'Threshold',
    IsAutoThreshold: 'side-menu.auto-threshold',
    SelectedAnalysisTypes: 'recipes.analysis-types',
    LeftRightMargin: 'recipes.rl-margin',
    IsCfgMargin: 'recipes.use-config-value',
    Palette: '',
  },
  [TYPE_DISCRIMINATOR.backwallAnalysisStageModel]: {
    StartRange: 'settings.backwallSettings.rangeStart',
    EndRange: 'settings.backwallSettings.rangeEnd',
    LowerThreshold: 'Lower threshold',
    UpperThreshold: 'Upper threshold',
    IsAutoThreshold: 'side-menu.auto-threshold',
    SelectedAnalysisTypes: 'recipes.analysis-types',
    LeftRightMargin: 'recipes.rl-margin',
    IsCfgMargin: 'recipes.use-config-value',
    Palette: '',
  },
};

export const RECIPE_PALETTE_FIELDS = {
  RangeStart: 'settings.generalSettings.paletteRangeStart',
  RangeEnd: 'settings.generalSettings.paletteRangeEnd',
  Clipped: 'settings.generalSettings.cut',
  Monochrome: 'settings.generalSettings.monochrome',
  Inverse: 'palette.invert-color',
};

export const RECIPE_FLAWS_ZONES_FIELDS = {
  SmsZone1: ['X1', 'X2', 'Y1', 'Y2'],
  SmsZone2: ['X1', 'X2', 'Y1', 'Y2'],
};

export const RECIPE_FLAWS_ZONES_UNITS = [
  { value: 'mm', label: 'mm' },
  { value: 'perc', label: 'perc' },
];

export const DEFAULT_FLAWS_ZONE_VALUE: IRecipeFlawZone = {
  X1: 0,
  X2: 0,
  Y1: 0,
  Y2: 0,
  Unit: 'mm',
};

export const recipeTableColumns = [
  {
    key: 'Name',
    field: 'name' as keyof IRecipeTemplateInfo,
    title: 'Name',
    width: '35%',
  },
  {
    key: 'Description',
    field: 'description' as keyof IRecipeTemplateInfo,
    title: 'common.description',
    width: '45%',
  },
  {
    key: 'ModifiedAt',
    field: 'modifiedAt' as keyof IRecipeTemplateInfo,
    title: 'common.modified-at',
    width: '20%',
  },
];

export const RECIPE_ANALYSIS_STATUS = {
  new: 'New',
  inQueue: 'InQueue',
};

export const RECIPE_MODAL_MODES = {
  loadRecipe: 'loadRecipe',
  assignRecipe: 'assignRecipe',
} as const;

export const SEARCH_SCANDATA_POLLING_TIME = 60 * 1000;

export const autoAnalysisStartedColumns = [
  {
    key: 'File',
    field: 'file' as keyof IRecipeForFiles,
    title: 'File',
    width: '50%',
  },
  {
    key: 'Method',
    field: 'recipe' as keyof IRecipeForFiles,
    title: 'Method',
    width: '50%',
  },
];
