import './styles.scss';

import { navigate, RadioGroup, useRouter } from '@sms/plasma-ui';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { DEFAULT_ERROR_MESSAGE, MODAL_STATUS, MODAL_TITLE, toolbarSubmenuData } from '../../../consts';
import { useActionCreators, useSnapshots } from '../../../hooks';
import {
  useLazyCancelRotationQuery,
  useLazyGetFlipCDQuery,
  useLazyGetRotatedCounterClockwiseInfoQuery,
  useSetRotateCounterClockwiseMutation,
} from '../../../store/api/expert-operations';
import {
  useInitSampleDataUploadMutation,
  useLazyCancelSampleUploadQuery,
  useLazyGetSampleDataUploadInfoQuery,
} from '../../../store/api/scandata';
import { RadioGroupData } from '../../../types';
import { createModalMessageBody } from '../../../utils';
import { Dropdown } from '../../UI/Dropdown';

interface ISecondaryPanelProps {
  isPanelActive: boolean;
}

export const SecondaryPanel: React.FC<ISecondaryPanelProps> = ({ isPanelActive }) => {
  const { route, pages } = useRouter('route', 'pages');
  const { setMessageModal, setSpinnerVisible, forceAnalysisChange } = useActionCreators();
  const takeSnapshot = useSnapshots();
  const [radioGroupValue, setRadioGroupValue] = useState<string>(
    () => pages.find((item) => item.path === route.path)?.key ?? '',
  );
  const [setRotateCounterClockwiseTrigger] = useSetRotateCounterClockwiseMutation();
  const [getRotatedCounterClockwiseInfoTrigger] = useLazyGetRotatedCounterClockwiseInfoQuery();
  const [initSampleDataUploadTrigger] = useInitSampleDataUploadMutation();
  const [getSampleDataUploadInfoTrigger] = useLazyGetSampleDataUploadInfoQuery();
  const [cancelRotationTrigger] = useLazyCancelRotationQuery();
  const [cancelUploadTrigger] = useLazyCancelSampleUploadQuery();
  const [getFlipCDTrigger] = useLazyGetFlipCDQuery();

  const secondaryToolbarClasses = classNames('toolbar__wrapper__secondary', {
    shown: isPanelActive,
  });

  const handleRadioGroupChange = async (e: any) => {
    if (takeSnapshot) {
      forceAnalysisChange(true);
      await takeSnapshot();
      forceAnalysisChange(false);
    }

    setRadioGroupValue(e.target.value);
    navigate(`${e.target.value}`);
  };

  const rotateHandler = useCallback(async (isCounterClockwiseRotation: boolean) => {
    setSpinnerVisible({
      isLoading: true,
      hasCancellation: true,
    });

    setMessageModal(
      createModalMessageBody(
        MODAL_STATUS.NOTIFICATION,
        MODAL_TITLE.notification,
        isCounterClockwiseRotation ? 'Initializing counter clockwise rotation.' : 'Initializing clockwise rotation.',
      ),
    );
    const response = await setRotateCounterClockwiseTrigger(isCounterClockwiseRotation);

    let taskId = '';

    if (!('error' in response)) {
      let timer: ReturnType<typeof setInterval>;

      setSpinnerVisible({
        cancelCb: async () => {
          clearInterval(timer);
          await cancelRotationTrigger(response.data);
        },
      });

      await new Promise<void>((resolve, reject) => {
        timer = setInterval(async () => {
          const { data, error } = await getRotatedCounterClockwiseInfoTrigger();

          if (error) {
            clearInterval(timer);
            setSpinnerVisible(false);
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
            reject(error);
            return;
          }

          if (data) {
            if (data.sampleRotationProcessState === 'Error') {
              setSpinnerVisible(false);
              setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
              clearInterval(timer);
              reject();
              return;
            }

            if (data.sampleRotationProcessState === 'Ready' && data.sampleLocalPath && data.scanDataFileInfo) {
              if (!data.sampleUploadRequired) {
                setSpinnerVisible(false);
                clearInterval(timer);
                resolve();
                return;
              }

              taskId = await initSampleDataUploadTrigger({
                sampleLocalPath: data.sampleLocalPath,
                scanDataFileInfo: data.scanDataFileInfo,
              }).unwrap();

              clearInterval(timer);
              resolve();
            }
          }
        }, 10 * 1000);
      });
    }

    if (taskId) {
      let sampleDataUploadInfoTimer: ReturnType<typeof setInterval>;

      setSpinnerVisible({
        cancelCb: async () => {
          clearInterval(sampleDataUploadInfoTimer);
          await cancelUploadTrigger(taskId);
        },
      });

      await new Promise<void>((resolve, reject) => {
        sampleDataUploadInfoTimer = setInterval(async () => {
          const { data, error } = await getSampleDataUploadInfoTrigger();

          if (error) {
            clearInterval(sampleDataUploadInfoTimer);
            setSpinnerVisible(false);
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
            reject(error);
            return;
          }

          if (data) {
            if (data.sampleUploadProcessState === 'Error') {
              setSpinnerVisible(false);
              setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
              clearInterval(sampleDataUploadInfoTimer);
              reject();
              return;
            }

            if (data.sampleUploadProcessState === 'Ready') {
              clearInterval(sampleDataUploadInfoTimer);
              setSpinnerVisible(false);
              resolve();
            }
          }
        }, 10 * 1000);
      });
    }
  }, []);

  const handleRotateClockwiseClick = async () => {
    await rotateHandler(false);
  };

  const handleRotateCounterClockwiseClick = async () => {
    await rotateHandler(true);
  };

  const handleFlipCDClick = () => getFlipCDTrigger();

  const expertOperationsList = [
    { name: 'toolbar.expert.rotate-clockwise', id: 'Rotate clockwise', onClick: handleRotateClockwiseClick },
    {
      name: 'toolbar.expert.rotate-counter',
      id: 'Rotate counter-clockwise',
      onClick: handleRotateCounterClockwiseClick,
    },
    { name: 'toolbar.expert.flip-c-d', id: 'Flip C and D', onClick: handleFlipCDClick },
    // { name: 'Exact volume rendering', onClick: () => {} },
    // { name: 'Preferences', onClick: () => {} },
  ];

  return (
    <div className={secondaryToolbarClasses}>
      <RadioGroup<RadioGroupData>
        name="secondaryToolbarRadioGroup"
        dataSource={toolbarSubmenuData}
        buttonStyle="solid"
        value={radioGroupValue}
        onChange={handleRadioGroupChange}
      />
      <Dropdown title={'toolbar.expert.expert-operations'} list={expertOperationsList} />
    </div>
  );
};
