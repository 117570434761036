import { IMargin } from '../types';

export const GEOMETRY_MARGIN: IMargin = { top: 10, right: 10, left: 40, bottom: 20 };
export const ADAPT_IMAGE_MARGIN: IMargin = { top: 30, right: 50, left: 40, bottom: 20 };
export const BASE_RECT_SIDE_SIZE = 9;
export const BASE_STROKE_THICK = 1;

export const SLICE_TYPES = {
  slabMiddle: 'Slab Middle',
  rect: 'Billet Rectangular',
  slabRight: 'Slab Right',
  round: 'Billet Round',
  slabLeft: 'Slab Left',
  cut: 'Billet Cut',
  sampleLong: 'Sample Long',
} as const;

export const SHAPES = {
  rect: 'Rectangular',
  round: 'Round',
} as const;

export const GEOMETRY_ANALYSIS_DATA = Object.values(SLICE_TYPES).map((side) => ({
  value: side,
  label: side,
}));
export const GEOMETRY_CHANGE_PARAMETERS_DATA = [
  {
    label: SLICE_TYPES.rect,
    value: SHAPES.rect,
  },
  {
    label: SLICE_TYPES.round,
    value: SHAPES.round,
  },
];

export const INIT_CHANGE_CUT_PARAMS = {
  Rectangular: {
    sizeX: 0,
    sizeY: 0,
  },
  Round: {
    sizeX: 0,
    sizeY: 0,
  },
};
