// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { RED_PRIMARY } from '../../consts';
import { ICircleParams, ISVGCircleParams, TTupleOfTwoNumbers } from '../../types';
import {
  getBaseSvgElemStyles,
  getLineLengthByCoords, getToFixedValue,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../utils';

interface IProps {
  id?: string;
  allowModification?: boolean;
  graphParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  params: ICircleParams;
  billetCutDx?: number;
  billetCutDy?: number;
  zoneNumber?: number;
  stroke?: string;
  setParams?: (id: string, params: Pick<ICircleParams, 'radius'>) => void;
}

export const CircularZone: FC<IProps> = ({
  id = '',
  zoneNumber,
  graphParams,
  domains,
  params,
  billetCutDx = 0,
  billetCutDy = 0,
  allowModification = false,
  setParams,
  stroke = RED_PRIMARY,
}) => {
  const getXOffset = getXOffsetFunc(graphParams, domains);
  const getYOffset = getYOffsetFunc(graphParams, domains);

  const cx = getXOffset(params.center.x + billetCutDx);
  const cy = getYOffset(params.center.y + billetCutDy);
  const r = (params.radius * graphParams.realSampleWidth) / domains[0];

  useEffect(() => {
    if (!setParams) return;

    const circle = d3.select(`#${id} circle`);
    const text = d3.select(`#${id} text`);

    if (!allowModification) {
      circle.attr('cursor', 'auto').call(d3.drag());

      return;
    }

    circle
      .datum({ cx, cy, r })
      .attr('cursor', 'grab');

    text
      .datum({ x: cx + r + 5, y: cy });

    function dragstarted(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      if (getLineLengthByCoords(event.x, event.y, d.cx, d.cy) < d.r - 10) return;

      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      if (getLineLengthByCoords(event.x, event.y, d.cx, d.cy) < d.r - 10) return;

      d3.select(this)
        .attr('r', d.r = getLineLengthByCoords(event.x, event.y, d.cx, d.cy));

      text
        .attr('x', d.cx + d.r + 5);
    }

    function dragended(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      if (!setParams) return;

      d3.select(this).attr('cursor', 'grab');

      const radius = getToFixedValue(d.r / graphParams.realSampleWidth * domains[0]);

      setParams(id, {
        radius,
      });
    }

    circle.call(
      d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );
  }, [id, allowModification, cx, cy, r, setParams, graphParams.realSampleWidth, domains]);

  return (
    <g id={id}>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        style={getBaseSvgElemStyles(stroke, 1)}
      />
      {zoneNumber && (
        <text className="round-zones__text" x={cx + r + 5} y={cy}>{zoneNumber}</text>
      )}
    </g>
  );
};
