import { backwallApi } from '../api/backwall';
import { TypedRootState } from '../types';

export const selectDistanceModificationMode = (state: TypedRootState) => state.backwall.distanceModificationMode;
export const selectDistanceZoneTypes = (state: TypedRootState) => state.backwall.distanceZoneTypes;
export const selectDistanceSelectionTypes = (state: TypedRootState) => state.backwall.distanceSelectionTypes;
export const selectDistanceSingleLayerSliderRange = (state: TypedRootState) => state.backwall.sliderRanges.singleLayer;
export const selectSelectedDistanceIds = (state: TypedRootState) => state.backwall.selectedDistanceIds;
export const selectDistanceStatistics = (state: TypedRootState) => state.backwall.distanceStatistics;
export const selectDistanceMaxProjectionSliderRange = (state: TypedRootState) =>
  state.backwall.sliderRanges.maxProjection;
export const selectBackwallTab = (state: TypedRootState) =>
  state.backwall.backwallTab;
export const selectAllDistanceZones = (state: TypedRootState) =>
  backwallApi.endpoints.getDistanceZones.select()(state)?.data;
