import './styles.scss';

import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { ChangeEvent, useCallback } from 'react';

import { REMARKS_NAMES, SLICE_TYPES } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { useInitGeometryMutation } from '../../store/api/geometry';
import { selectRemarks, selectSliceType } from '../../store/selectors';
import { Remarks } from '../Remarks';
import { GeometryStatistic } from './GeometryStatistic';

export const GeometrySideMenu = () => {
  const {
    setGeometryRemarks,
    setGeometryAnalysisVisible,
    setChangeCutParametersVisible,
    setSpinnerVisible,
  } = useActionCreators();
  const { geometry } = useAppSelector(selectRemarks);
  const sliceType = useAppSelector(selectSliceType);
  const [initGeometryBaseTrigger] = useInitGeometryMutation();

  const handleChangeSide = useCallback(() => {
    setGeometryAnalysisVisible(true);
  }, []);

  const handleChangeParameters = useCallback(() => {
    setChangeCutParametersVisible(true);
  }, []);

  const handleRemarksChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setGeometryRemarks(e.target.value);
  }, []);

  const initGeometryHandler = useCallback(async () => {
    await initGeometryBaseTrigger(sliceType);
    setSpinnerVisible(false);
  }, [sliceType]);

  return (
    <div className="geometry__control">
      <div>
        <h3 className="geometry__control__title">
          <TranslatedText textKey="side-menu.control" />
        </h3>
        <Button title={'geometry.geometry-analysis'} onClick={initGeometryHandler} />
        <Button title={'geometry.change-side'} onClick={handleChangeSide} />
        <GeometryStatistic />
        {sliceType === SLICE_TYPES.cut && (
          <>
            <h3 className="geometry__control__title">
              <TranslatedText textKey="side-menu.change-cut-parameters" />
            </h3>
            <Button title={'side-menu.change-parameters'} onClick={handleChangeParameters} />
            <Button title={'common.confirm'} onClick={initGeometryHandler} />
          </>
        )}
      </div>
      <Remarks value={geometry} label={'Remarks'} name={REMARKS_NAMES.geometry} onChange={handleRemarksChange} />
    </div>
  );
};
