import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { MouseEvent } from 'react';

import { BLACK_PRIMARY, BLUE_PRIMARY, GREY_SECONDARY_EXTRA_LIGHT, SCAN_VIEWS, WHITE_PRIMARY } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import {
  useGetSliceImageQuery,
  useLazyRestoreSliceImageQuery,
} from '../../../store/api/adapt-image';
import { useSetPaletteMutation, useSetProjectionsDataMutation } from '../../../store/api/slice';
import { selectPaletteData, selectVolumeId } from '../../../store/selectors';

export const General = () => {
  const { setPaletteInverse } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const paletteData = useAppSelector(selectPaletteData);
  const [restoreImageTrigger] = useLazyRestoreSliceImageQuery();
  const [paletteTrigger] = useSetPaletteMutation();
  const { refetch } = useGetSliceImageQuery(volumeId);
  const [setCProjectionDataTrigger] = useSetProjectionsDataMutation({ fixedCacheKey: 'sliceC' });
  const undoHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    await restoreImageTrigger();
    await setCProjectionDataTrigger(SCAN_VIEWS.C);
  };

  const inverseHandler = () => {
    setPaletteInverse();
    paletteTrigger({
      volumeId,
      ...paletteData,
      isInverse: !paletteData.isInverse,
    }).then(() => refetch());
  };

  return (
    <div className="adapt__menu__general">
      <h4 className="adapt__menu__subtitle">
        <TranslatedText textKey="common.general" />
      </h4>
      <Button
        title={'adapt-image.inverse'}
        onClick={inverseHandler}
        style={{
          backgroundColor: paletteData.isInverse ? BLUE_PRIMARY : 'transparent',
          borderColor: paletteData.isInverse ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: paletteData.isInverse ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
      <Button title={'adapt-image.undo'} onClick={undoHandler} />
    </div>
  );
};

