import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { createModalMessageBody } from '../../../utils';
import { allActions } from '../../actions';
import { api } from '../index';
import { GLBResponse } from './types';

const { setSpinnerVisible, setMessageModal } = allActions;

export const glbApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThreeD: build.query<string | null, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ({ url: 'glb' });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as GLBResponse;

        if (data.warningMessage) {
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.NOTIFICATION, MODAL_TITLE.notification, data.warningMessage),
            ),
          );
        }

        dispatch(setSpinnerVisible(false));

        return { data: data.modelPath };
      },
    }),
  }),
});

export const { useGetThreeDQuery } = glbApi;
