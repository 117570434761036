import { Icon, TranslatedText } from '@sms/plasma-ui';
import React, { MouseEvent } from 'react';

import {
  DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE,
  FILE_TYPES,
  FILES_DIRECTION_ORDER,
  IFileTableSortedFields,
  isHostEnvStandalone,
  LOCALSTORAGE_KEYS,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { IScanData, IScanDataProd } from '../../../../store/api/scandata/types';
import { selectSampleFileName, selectScanDataFilters } from '../../../../store/selectors';
import { TableColumn, TFilesOrderBy } from '../../../../types';
import { checkAppRoute, getFromLocalStorage } from '../../../../utils';
import { TableRow } from '../../../UI/Table/TableRow';
import { TableCell } from '../../../UI/Table/TableRow/TableCell';
import { TableHeadCheckbox } from './TableHeadCheckbox';

type TSelectedFiles =
  | {
      selectedRow: IScanData | IScanDataProd | null;
    }
  | {
      multiple: true;
      selectedRows: (IScanData | IScanDataProd)[] | null;
    };

type IProps = {
  columns: TableColumn<IScanData>[];
  data: IScanData[];
  onRowClick: (e: MouseEvent<HTMLDivElement>, value: IScanData | IScanDataProd) => void;
} & TSelectedFiles;

export const FilesTable = ({ columns, data, onRowClick, ...props }: IProps) => {
  const { setScanDataFilters } = useActionCreators();
  const { orderBy } = useAppSelector(selectScanDataFilters);
  const sampleFileName = useAppSelector(selectSampleFileName);
  const path = window.location.pathname;
  const { isRecipes } = checkAppRoute(path);

  const handleOrderByChange = (orderBy: TFilesOrderBy) => {
    setScanDataFilters({ orderBy });
  };

  return (
    <div className="dynamic-table">
      <div className="table-header">
        {columns.map(({ key, field, title, width }) => (
          <div className="table-cell" key={field} style={{ width }}>
            {'selectedRows' in props && (key === 'Name' || key === 'Recipes') && (
              <TableHeadCheckbox id={key} selectedRows={props.selectedRows} data={data} />
            )}
            <TranslatedText textKey={title} />
            {(key === 'Name' || key === 'CreatedAt') && (
              <Icon
                name={
                  orderBy[field as keyof IFileTableSortedFields] === FILES_DIRECTION_ORDER.asc
                    ? 'arrow-alt-square-up'
                    : 'arrow-alt-square-down'
                }
                className={`${field}`}
                onClick={() =>
                  handleOrderByChange({
                    ...orderBy,
                    [field]:
                      orderBy[field as keyof IFileTableSortedFields] === FILES_DIRECTION_ORDER.asc
                        ? FILES_DIRECTION_ORDER.desc
                        : FILES_DIRECTION_ORDER.asc,
                  })
                }
              />
            )}
          </div>
        ))}
      </div>
      <div className="table-body">
        {data.map((row) => {
          const isDeletedFile = !isHostEnvStandalone && row.s3Key === DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE;
          const isImageFileOnAutoAnalysis = isRecipes && row.fileType === FILE_TYPES.image;
          const isRowDisabled = isDeletedFile || isImageFileOnAutoAnalysis;

          return (
            <TableRow
              key={row.id}
              rowData={row}
              onClick={onRowClick}
              disabled={isRowDisabled}
              isCurrent={sampleFileName === row.name && getFromLocalStorage(LOCALSTORAGE_KEYS.scanInfo)?.id === row.id}
              isSelected={
                'multiple' in props
                  ? !!props.selectedRows?.find((file) => file.id === row.id)
                  : props.selectedRow?.name === row.name && props.selectedRow?.id === row.id
              }
            >
              {columns.map(({ field, width, title }) => (
                <TableCell
                  key={title}
                  field={field}
                  width={width}
                  value={row[field]}
                  iconEnd={field === 'name' && isDeletedFile && <Icon name="cloud-upload" />}
                />
              ))}
            </TableRow>
          );
        })}
      </div>
    </div>
  );
};
