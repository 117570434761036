import './assets/css/main.scss';
import './styles/index.scss';
import '@aws-amplify/ui-react/styles.css';

import { Authenticator } from '@aws-amplify/ui-react';
import { App, navigate, ThemeDimensions } from '@sms/plasma-ui';
import { Amplify, Auth } from 'aws-amplify';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import HDScanLogo from './assets/img/HDscan-logo.svg';
import { AppWrapper } from './components/AppWrapper';
import { LogoutWhenInactive } from './components/LogoutWhenInactive';
import app from './config/app.json';
import appTranslations from './config/i18n';
import { AWS_CONFIG, FILE_TYPES, isCloudHostEnv, isHostEnvStandalone, LOCALSTORAGE_KEYS } from './consts';
import pages from './pages';
import { store } from './store';
import { allActions } from './store/actions';
import { scanDataApi } from './store/api/scandata';
import { getFromLocalStorage, getTokenData, removeFromLocalStorage, setToLocalStorage } from './utils';

const { setSpinnerVisible, setSampleFileName, setVolumeInfo } = allActions;

Amplify.configure(AWS_CONFIG);

// Analytics.autoTrack('session', {
//   enable: true,
//   attributes: {
//     attr: 'attr',
//   },
//   provider: 'AWSPinpoint',
// });

const {
  SMSLanguage,
  SMSApplicationCode,
  SMSTheme,
  SMSToken,
  volumeInfo,
  scanInfo,
  ec2InstanceId,
  ec2InstanceType,
  ec2InstanceState,
  lastAuthUser,
  unloadTime,
  expandedKeys,
  filtersTitles,
  checkedKeys,
  backendState,
} = LOCALSTORAGE_KEYS;

const MainApp: FunctionComponent = () => {
  const [forceAppRender, setForceAppRender] = useState<boolean>(false);
  const isAutoAnalysisMode = window.location.host === 'localhost';

  const handleForcingAppRender = useCallback(() => {
    setForceAppRender((prev) => !prev);
  }, []);

  useEffect(() => {
    fetchJwtToken();
  }, []);

  const fetchJwtToken = async () => {
    try {
      const session = await Auth.currentSession();
      session.getIdToken().getJwtToken();
    } catch (error) {
      console.log('Error fetching JWT token:', error);
    }
  };

  const handleLanguageChange = useCallback((language: string) => {
    setToLocalStorage(SMSLanguage, language);
  }, []);

  const handleLogout = useCallback(async (signOutCb) => {
    navigate('/');

    try {
      store.dispatch(setSpinnerVisible(true));

      const volumeId = (getFromLocalStorage(volumeInfo)?.volumeId || '') as string;
      const ec2InstanceTypeValue = getFromLocalStorage(ec2InstanceType);

      if (volumeId) {
        try {
          await store.dispatch(scanDataApi.endpoints.clearInstance.initiate(volumeId));
        } catch (err) {
          console.log(err);
        }
      }

      if (isCloudHostEnv && ec2InstanceTypeValue) {
        try {
          await store.dispatch(scanDataApi.endpoints.stopInstance.initiate());
        } catch (err) {
          console.log(err);
        }
      }

      signOutCb();

      removeFromLocalStorage(SMSToken);
      removeFromLocalStorage(SMSApplicationCode);
      removeFromLocalStorage(volumeInfo);
      removeFromLocalStorage(scanInfo);
      removeFromLocalStorage(ec2InstanceId);
      removeFromLocalStorage(ec2InstanceType);
      removeFromLocalStorage(ec2InstanceState);
      removeFromLocalStorage(unloadTime);
      removeFromLocalStorage(backendState);

      store.dispatch({ type: 'logout' });
      store.dispatch(setSampleFileName(''));
      store.dispatch(
        setVolumeInfo({
          volumeId: '',
          fileType: FILE_TYPES.volume,
        }),
      );
      store.dispatch(setSpinnerVisible(false));
    } catch (error) {
      console.log('Error logging out', error);
      store.dispatch(setSpinnerVisible(false));
    }
  }, []);

  const language = getFromLocalStorage(SMSLanguage) || undefined;
  const firstName = getFromLocalStorage(lastAuthUser);

  const handleThemeChange = useCallback((theme: ThemeDimensions) => {
    setToLocalStorage(SMSTheme, theme);
  }, []);

  const tokenData = useMemo(() => getTokenData(app.code), []);

  const smsUser = useMemo(
    () => ({
      id: tokenData?.id,
      firstName,
      lastName: '',
      email: tokenData?.email,
    }),
    [tokenData, firstName, forceAppRender],
  );

  const theme = useMemo(() => {
    const themeString = getFromLocalStorage(SMSTheme);
    return themeString ? (themeString as ThemeDimensions) : undefined;
  }, []);

  useEffect(() => {
    const beforeUnloadHandler = () => {
      setToLocalStorage(unloadTime, new Date().toString());
      removeFromLocalStorage(expandedKeys);
      removeFromLocalStorage(filtersTitles);
      removeFromLocalStorage(checkedKeys);
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, []);

  const renderWrappedApp = (user: any, signOut: any) => (
    <Provider store={store}>
      <AppWrapper user={user} forceAppRender={handleForcingAppRender}>
        <App
          code={app.code}
          isAuthenticated
          hasFullPermission={tokenData?.is_admin}
          user={smsUser}
          permissions={tokenData?.permissions}
          routerConfig={{ pages }}
          headerConfig={{ logo: HDScanLogo, title: '' }}
          profileAvatarConfig={{
            onLogout: () => handleLogout(signOut),
          }}
          language={language}
          onLanguageChange={handleLanguageChange}
          theme={theme}
          onThemeChange={handleThemeChange}
          translations={appTranslations}
        />
        <LogoutWhenInactive logout={() => handleLogout(signOut)} />
      </AppWrapper>
    </Provider>
  );

  return (
    <>
      {!isHostEnvStandalone && !isAutoAnalysisMode ? (
        <Authenticator initialState="signIn">{({ signOut, user }) => renderWrappedApp(user, signOut)}</Authenticator>
      ) : (
        renderWrappedApp(null, () => {})
      )}
    </>
  );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
