import { TFunction } from 'i18next';

import {
  BLUE_PRIMARY,
  CHART_TYPES,
  DB_TABLE_COLUMN_TITLES,
  REGRESSION_TITLES,
  REGRESSION_TYPES,
  STROKE_GREY_SECONDARY_DEEP,
} from '../consts';
import { DatabaseFiltersResponse, MergedFiltersResult } from '../store/api/database/types';
import { BarLabelOption, ChartTypes, TTupleOfTwoNumbers, ValueOf } from '../types';
import { getValueOfNestedObject, isNumber } from './shared';

const chartTitleCreator = (text: string, subtext = '', t: TFunction) => {
  return {
    text: t(text),
    subtext: t(subtext),
    left: 'center',
    padding: 15,
    textStyle: {
      color: BLUE_PRIMARY,
    },
    subtextStyle: {
      fontSize: 14,
    },
  };
};

const axisLabelsCreator = (keys: string[], t: TFunction) => {
  const keysCopy = [...keys];

  if (!keys.length) return { xAxis: {}, yAxis: {} };
  if (keys.length === 1) {
    keysCopy.push('Amount');
  }
  const [x, y] = keysCopy;

  return {
    xAxis: {
      name: t(`${DB_TABLE_COLUMN_TITLES[x as keyof typeof DB_TABLE_COLUMN_TITLES]}`),
      nameLocation: 'middle',
      nameGap: 25,
      nameTextStyle: {
        color: STROKE_GREY_SECONDARY_DEEP,
        fontSize: 16,
      },
    },
    yAxis: {
      name: keys.length === 1 ? t(y) : t(`${DB_TABLE_COLUMN_TITLES[y as keyof typeof DB_TABLE_COLUMN_TITLES]}`),
      nameLocation: 'middle',
      nameGap: 35,
      nameTextStyle: {
        color: STROKE_GREY_SECONDARY_DEEP,
        fontSize: 16,
      },
    },
  };
};

export const makeSingleBarOptions = (
  categories: (string | number)[],
  data: Record<string, number>,
  keys: string[],
  t: TFunction,
) => {
  const { xAxis, yAxis } = axisLabelsCreator(keys, t);
  const orderedData = categories.reduce((acc, category, idx) => {
    acc[idx] = data[category];
    return acc;
  }, [] as number[]);

  return {
    title: chartTitleCreator('Bar', '', t),
    xAxis: {
      ...xAxis,
      type: 'category',
      data: categories,
    },
    yAxis: {
      ...yAxis,
      name: t('Amount'),
      type: 'value',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      show: true,
    },
    series: [
      {
        data: orderedData,
        smooth: true,
        type: CHART_TYPES.bar,
      },
    ],
  };
};

export const makeMultiBarOptions = (
  categories: (string | number)[],
  data: Record<string, Record<string, number>>,
  selectedKeys: string[],
  t: TFunction,
) => {
  const keys = Object.keys(data);
  if (!keys.length) return {};
  const obj: Record<string, number[]> = {};
  const { xAxis, yAxis } = axisLabelsCreator(selectedKeys, t);

  Object.keys(data[keys[0]])?.forEach((i) => {
    obj[i] = [];
  });

  const d = Object.values(data).reduce((acc, item) => {
    for (const [key, value] of Object.entries(item)) {
      acc[key].push(value);
    }

    return acc;
  }, obj);

  const labelOption: BarLabelOption = {
    show: true,
    position: 'insideBottom',
    distance: 15,
    align: 'left',
    verticalAlign: 'middle',
    rotate: 90,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
      name: {},
    },
  };

  const series = Object.entries(d).map(([key, values]) => {
    return {
      name: key,
      type: CHART_TYPES.bar,
      barGap: 0,
      label: labelOption,
      emphasis: {
        focus: 'series',
      },
      data: values,
    };
  });

  return {
    title: chartTitleCreator('Multibar', '', t),
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: Object.keys(data[keys[0]]),
      padding: 40,
    },
    xAxis: [
      {
        ...xAxis,
        type: 'category',
        axisTick: { show: false },
        data: categories,
      },
    ],
    yAxis: [
      {
        ...yAxis,
        type: 'value',
      },
    ],
    series,
  };
};

export const makePieOptions = (
  categories: (string | number)[],
  data: Record<string, number>,
  key: string,
  t: TFunction,
) => {
  const pieData = categories.reduce((acc, category) => {
    acc.push({ name: category, value: data[category] });
    return acc;
  }, [] as { name: string | number; value: number }[]);

  return {
    title: chartTitleCreator('Pie', DB_TABLE_COLUMN_TITLES[key as keyof typeof DB_TABLE_COLUMN_TITLES], t),
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c} ({d}%)',
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 50,
      top: 'middle',
      data: categories.forEach((category) => category.toString()),
      textStyle: {
        fontSize: 14,
      },
    },
    series: [
      {
        type: 'pie',
        radius: '65%',
        center: ['40%', '50%'],
        data: pieData,
      },
    ],
  };
};

export const makeScatterOptions = (data: TTupleOfTwoNumbers[], keys: string[], t: TFunction) => {
  const { xAxis, yAxis } = axisLabelsCreator(keys, t);
  return {
    title: chartTitleCreator('Scatter', '', t),
    xAxis,
    yAxis,
    series: [
      {
        symbolSize: 20,
        data,
        type: CHART_TYPES.scatter,
      },
    ],
  };
};

export const makeRegressionOptions = ({
  data,
  keys,
  type,
  t,
}: {
  data: TTupleOfTwoNumbers[];
  keys: string[];
  type: ValueOf<typeof REGRESSION_TYPES>;
  t: TFunction;
}) => {
  const { xAxis, yAxis } = axisLabelsCreator(keys, t);

  return {
    dataset: [
      {
        source: data,
      },
      {
        transform: {
          type: 'ecStat:regression',
          config: {
            method: type,
            order: 3,
          },
        },
      },
    ],
    title: chartTitleCreator(`${REGRESSION_TITLES[type]} Regression`, '', t),
    legend: {
      bottom: 5,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    xAxis: {
      ...xAxis,
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    yAxis: {
      ...yAxis,
      // min: type === REGRESSION_TYPES.polynomial ? -1 : 0,
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [
      {
        name: 'scatter',
        type: 'scatter',
      },
      {
        name: 'line',
        type: 'line',
        datasetIndex: 1,
        symbolSize: 0.1,
        symbol: 'circle',
        label: { show: true, fontSize: 16 },
        labelLayout: { dx: -20 },
        encode: { label: 2, tooltip: 1 },
      },
    ],
  };
};

export const makeOptions = ({
  chartType,
  numberTupleData,
  qty,
  keys,
  categories,
  t,
}: {
  chartType: ChartTypes;
  numberTupleData: TTupleOfTwoNumbers[];
  qty: any;
  keys: string[];
  categories: (string | number)[];
  t: TFunction;
}) => {
  switch (chartType) {
    case CHART_TYPES.bar: {
      return makeSingleBarOptions(categories, qty, keys, t);
    }
    case CHART_TYPES.multibar: {
      return makeMultiBarOptions(categories, qty, [keys[0]], t);
    }
    case CHART_TYPES.pie: {
      return makePieOptions(categories, qty, keys[0], t);
    }
    case CHART_TYPES.scatter: {
      return makeScatterOptions(numberTupleData, keys, t);
    }
    case CHART_TYPES.linearRegression: {
      return makeRegressionOptions({ data: numberTupleData, keys, type: REGRESSION_TYPES.linear, t });
    }
    case CHART_TYPES.exponentialRegression: {
      return makeRegressionOptions({ data: numberTupleData, keys, type: REGRESSION_TYPES.exponential, t });
    }
    case CHART_TYPES.polynomialRegression: {
      return makeRegressionOptions({ data: numberTupleData, keys, type: REGRESSION_TYPES.polynomial, t });
    }
    default:
      return {};
  }
};

export const checkChartsOriginData = (
  originData: (DatabaseFiltersResponse | MergedFiltersResult)[],
  keys: string[],
) => {
  if (!keys.length) return [];
  const [xKey, yKey] = keys;
  const result: boolean[] = [];

  for (let i = 0; i <= originData.length - 1; i++) {
    if (result.length === keys.length) break;
    const x = getValueOfNestedObject(originData[i], xKey);

    if (x !== null) {
      result[0] = isNumber(x);
    }

    if (yKey) {
      const y = getValueOfNestedObject(originData[i], yKey);

      if (y !== null) {
        if (result[0] && yKey === 'flawsGostClass') {
          result[1] = isNumber(y === '>4' ? 4.1 : +y);
        } else {
          result[1] = isNumber(y);
        }
      }
    }
  }

  return result;
};
