import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { Cropping } from './Cropping';
import { General } from './General';
import { Scale } from './Scale';
import { SetAdaptImageParams } from './SetAdaptImageParams';

export const AdaptSideMenu = () => {
  return (
    <div className="adapt__menu">
      <h3 className="adapt__menu__title side-menu__title">
        <TranslatedText textKey="side-menu.control" />
      </h3>
      <General />
      <Cropping />
      <Scale />
      <SetAdaptImageParams />
    </div>
  );
};
