import {
  BLACK_PRIMARY,
  CRACKS_TYPES,
  GREEN_GRASS,
  GREEN_LIGHT,
  GREEN_PRIMARY,
  GREEN_SKY,
  GREY_DARK,
  INIT_CRACK_INFO_INTERSECTIONS,
  ORANGE_PRIMARY,
  PINK_DARK,
  PINK_PRIMARY,
  RED_BROWN_ORANGE,
  RED_PRIMARY,
  SKY_PRIMARY,
  YELLOW_ORANGE,
  YELLOW_PRIMARY,
} from '../consts';
import { IPxCoordinates } from '../store/api/slice/types';
import {
  I2DIntersection,
  IIntersections,
  IIntersectionsCoords,
  TIntersectionDirections,
  TIntersectionPoints,
} from '../types';

export const getColorForMatchedCrackType = (crackType: string): string => {
  switch (crackType) {
    case CRACKS_TYPES.longitudinal:
      return RED_PRIMARY;
    case CRACKS_TYPES.nearSurface:
      return YELLOW_PRIMARY;
    case CRACKS_TYPES.transversal:
      return ORANGE_PRIMARY;
    case CRACKS_TYPES.center:
      return RED_BROWN_ORANGE;
    case CRACKS_TYPES.offCorner:
      return GREEN_LIGHT;
    case CRACKS_TYPES.crossSection:
      return GREEN_PRIMARY;
    case CRACKS_TYPES.distortion:
      return PINK_DARK;
    case CRACKS_TYPES.halfWay:
      return PINK_PRIMARY;
    case CRACKS_TYPES.heatInfluence:
      return SKY_PRIMARY;
    case CRACKS_TYPES.nfns:
      return GREY_DARK;
    case CRACKS_TYPES.nfnt:
      return GREEN_SKY;
    case CRACKS_TYPES.segregation:
      return YELLOW_ORANGE;
    case CRACKS_TYPES.tripplePoint:
      return GREEN_GRASS;
    default:
      return BLACK_PRIMARY;
  }
};

export const assignDeltas = (acc: IIntersections, point: TIntersectionPoints, direction: TIntersectionDirections) => {
  const pointKey = `${point}` as keyof IIntersections;
  const directionKey = `${direction}` as keyof I2DIntersection;

  acc[pointKey][directionKey].minDelta = Math.min(...acc[pointKey][directionKey].deltas.map((i) => Math.abs(i)));
  acc[pointKey][directionKey].delta =
    acc[pointKey][directionKey].deltas.find((i) => Math.abs(i) === acc[pointKey][directionKey].minDelta) || 0;
};

export const getIntersectionsData = (borderData: IPxCoordinates[], intersections: IIntersectionsCoords) => {
  const { leftTop, rightBottom } = intersections;

  return borderData.reduce((acc, item) => {
    if (item.i === Math.round(leftTop.x)) {
      acc.leftTop.x.deltas.push(item.j - leftTop.y);
      assignDeltas(acc, 'leftTop', 'x');
    }
    if (item.j === Math.round(leftTop.y)) {
      acc.leftTop.y.deltas.push(item.i - leftTop.x);
      assignDeltas(acc, 'leftTop', 'y');
    }
    if (item.i === Math.round(rightBottom.x)) {
      acc.rightBottom.x.deltas.push(item.j - rightBottom.y);
      assignDeltas(acc, 'rightBottom', 'x');
    }
    if (item.j === Math.round(rightBottom.y)) {
      acc.rightBottom.y.deltas.push(item.i - rightBottom.x);
      assignDeltas(acc, 'rightBottom', 'y');
    }

    return acc;
  }, JSON.parse(JSON.stringify(INIT_CRACK_INFO_INTERSECTIONS)));
};
