import React, { FC } from 'react';

import { getToFixedValue, isNumber } from '../../../../../utils';

interface IProps {
  zoneType: string | React.ReactNode;
  zoneId: string;
  // lengthHeight: string | React.ReactNode;
  sizePrecent: number | string | React.ReactNode;
  width: string | number | React.ReactNode;
  height: string | number | null | React.ReactNode;
  sizeParameter?: string;
}

export const StatisticsTableRow: FC<IProps> = ({ zoneType, zoneId, width, height, sizePrecent, sizeParameter }) => {
  const roundedPercent = typeof sizePrecent === 'number' ? sizePrecent.toFixed(2) : sizePrecent;
  const widthHeight =
    typeof width === 'object'
      ? width
      : `${isNumber(width) ? getToFixedValue(width) : width === null ? '-' : width}/${
          isNumber(height) ? getToFixedValue(height) : height === null ? '-' : height
        }`;
  const sizePrecentValue =
    typeof sizePrecent === 'object'
      ? sizePrecent
      : `${roundedPercent} ${sizeParameter ? sizeParameter.toLowerCase() : ''}`;

  const tableRow = [zoneType, zoneId, widthHeight, sizePrecentValue];

  return (
    <div className="distance__statistics__table__row">
      {tableRow.map((row, idx) => (
        <div className="distance__statistics__table__cell" key={idx}>
          <p>{row}</p>
        </div>
      ))}
    </div>
  );
};
