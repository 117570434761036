import React, { FC } from 'react';

import { FLAWS_ANALYSIS } from '../../../../../../consts';
import { useAppSelector, useFlaw } from '../../../../../../hooks';
import {
  useSetASTMZoneChangedMutation,
  useSetGOSTZoneChangedMutation,
  useSetSMSZoneChangedMutation,
} from '../../../../../../store/api/flaws';
import { TFlawsRectZones } from '../../../../../../store/api/flaws/types';
import {
  selectFlawsAnalysis,
  selectFlawsIsZoneInFocus,
  selectFlawsSelectedRegionId,
  selectVolumeId,
} from '../../../../../../store/selectors';
import { IBilletCutParams } from '../../../../../../store/slices/types';
import { IRectCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import { ICartesianModeParams, setFlawsZoneFill } from '../../../../../../utils';
import { RectangularZone } from '../../../../../RectangularZone';

interface IProps {
  zones: TFlawsRectZones;
  graphParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  billetCutParams: IBilletCutParams;
  allowModification: boolean;
}

export const RectZones: FC<IProps> = ({ zones, graphParams, domains, billetCutParams, allowModification }) => {
  const volumeId = useAppSelector(selectVolumeId);
  const isZoneInFocus = useAppSelector(selectFlawsIsZoneInFocus);
  const selectedRegionId = useAppSelector(selectFlawsSelectedRegionId);
  const flawsAnalysis = useAppSelector(selectFlawsAnalysis);
  const [setSMSZoneChangedTrigger] = useSetSMSZoneChangedMutation();
  const [setGOSTZoneChangedTrigger] = useSetGOSTZoneChangedMutation();
  const [setASTMZoneChangedTrigger] = useSetASTMZoneChangedMutation();

  const setParamsHandler = async (id: string, params: Partial<IRectCoordinates>) => {
    const baseBody = {
      volumeId,
      region: {
        x0: -1,
        x1: -1,
        y0: -1,
        y1: -1,
        ...params,
      },
    };

    if (id.includes('zones')) {
      const index = +id.split('zones')[1];

      if (flawsAnalysis === FLAWS_ANALYSIS.sms) {
        await setSMSZoneChangedTrigger({
          ...baseBody,
          regionId: index,
        });
      }

      if (flawsAnalysis === FLAWS_ANALYSIS.gost) {
        await setGOSTZoneChangedTrigger({
          ...baseBody,
          regionId: index,
        });
      }
    }

    if (id.includes('outerRegion')) {
      const index = +id.split('outerRegion')[1];

      await setGOSTZoneChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('innerRegion')) {
      const index = +id.split('innerRegion')[1];

      await setGOSTZoneChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('regionS')) {
      const index = +id.split('regionS')[1];

      await setASTMZoneChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('regionR')) {
      const index = +id.split('regionR')[1];

      await setASTMZoneChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }

    if (id.includes('regionC')) {
      const index = +id.split('regionC')[1];

      await setASTMZoneChangedTrigger({
        ...baseBody,
        regionId: index,
      });
    }
  };

  return (
    <>
      {'zones' in zones &&
        zones.zones.map((zone, idx) => (
          <RectangularZone
            key={idx}
            id={`zones${idx}`}
            graphParams={graphParams}
            domains={domains}
            coordinates={zone}
            allowModification={allowModification}
            setParams={setParamsHandler}
            stroke={setFlawsZoneFill(idx, selectedRegionId, isZoneInFocus)}
            zoneNumber={idx + 1}
          />
        ))}
      {'outerRegion' in zones && (
        <RectangularZone
          id={`outerRegion${zones.outerRegion.id}`}
          allowModification={allowModification}
          graphParams={graphParams}
          domains={domains}
          coordinates={zones.outerRegion.rectangle}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          stroke={setFlawsZoneFill(zones.outerRegion.id, selectedRegionId, isZoneInFocus)}
          setParams={setParamsHandler}
          zoneNumber={zones.outerRegion.id + 1}
        />
      )}
      {'innerRegion' in zones && (
        <RectangularZone
          id={`innerRegion${zones.innerRegion.id}`}
          allowModification={allowModification}
          graphParams={graphParams}
          domains={domains}
          coordinates={zones.innerRegion.rectangle}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          stroke={setFlawsZoneFill(zones.innerRegion.id, selectedRegionId, isZoneInFocus)}
          setParams={setParamsHandler}
          zoneNumber={zones.innerRegion.id + 1}
        />
      )}
      {'regionS' in zones && (
        <RectangularZone
          id={`regionS${zones.regionS.id}`}
          allowModification={allowModification}
          graphParams={graphParams}
          domains={domains}
          coordinates={zones.regionS.rectangle}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          stroke={setFlawsZoneFill(zones.regionS.id, selectedRegionId, isZoneInFocus)}
          setParams={setParamsHandler}
          zoneNumber={zones.regionS.id + 1}
        />
      )}
      {'regionR' in zones && (
        <RectangularZone
          id={`regionR${zones.regionR.id}`}
          allowModification={allowModification}
          graphParams={graphParams}
          domains={domains}
          coordinates={zones.regionR.rectangle}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          stroke={setFlawsZoneFill(
            selectedRegionId === 0 ? zones.regionS.id : zones.regionR.id,
            selectedRegionId,
            isZoneInFocus,
          )}
          setParams={setParamsHandler}
          zoneNumber={zones.regionR.id + 1}
        />
      )}
      {'regionC' in zones && (
        <RectangularZone
          id={`regionC${zones.regionC.id}`}
          allowModification={allowModification}
          graphParams={graphParams}
          domains={domains}
          coordinates={zones.regionC.rectangle}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          stroke={setFlawsZoneFill(zones.regionR.id, selectedRegionId, isZoneInFocus)}
          setParams={setParamsHandler}
          zoneNumber={zones.regionC.id + 1}
        />
      )}
    </>
  );
};
