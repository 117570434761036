import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useActionCreators, useAppSelector } from '../../hooks';
import { useGetClientSettingsQuery } from '../../store/api/settings';
import { selectIsUsedLogo } from '../../store/selectors';
import { TLogo } from '../../types';
import { ReportEmail } from './ReportEmail';
import { ReportLogo } from './ReportLogo';

export const ReportClientSettings = () => {
  useGetClientSettingsQuery();
  const { setUsedLogo } = useActionCreators();

  const handleLogoChange = (id: TLogo) => {
    setUsedLogo(id);
  };

  const reportLogos = useAppSelector(selectIsUsedLogo);

  return (
    <div className="report__logo">
      <h4 className="sub-menu__title">
        <TranslatedText textKey="report.logo" />
      </h4>
      <ReportEmail />
      <ReportLogo {...reportLogos} handleLogoChange={handleLogoChange} />
    </div>
  );
};
