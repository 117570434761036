import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IBilletCutParams, TBaseBilletCutParams, TOffsetsBilletCutParams } from './types';

const initialState: IBilletCutParams = {
  shape: '',
  sizeX: 0,
  sizeY: 0,
  dx: 0,
  dy: 0,
};

export const billetCutParamsSlice = createSlice({
  name: 'billetCutParams',
  initialState,
  reducers: {
    setBaseCutParams: (state, action: PayloadAction<TBaseBilletCutParams>) => {
      Object.assign(state, { ...action.payload });
    },
    setDefaultCutParams: () => initialState,
    setOffsetsCutParams: (state, action: PayloadAction<TOffsetsBilletCutParams>) => {
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { actions: billetCutParamsActions, reducer: billetCutParams } = billetCutParamsSlice;
