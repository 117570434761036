// @ts-ignore
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

import {
  BACKWALL_MODIFICATION_MODES,
  CRACKS_MODIFICATION_MODES,
  IDs,
  TRANSLUCENT_DIM_GREY,
  TRANSPARENT,
} from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { useModifyDistanceRectMutation } from '../../../../../store/api/backwall';
import { TDistanceZoneType } from '../../../../../store/api/backwall/types';
import { selectDistanceModificationMode } from '../../../../../store/selectors';
import { IRectCoordinates, TTupleOfTwoNumbers } from '../../../../../types';
import {
  convertCartesianModeCoordsInPxToMm,
  getBaseSvgElemStyles,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../../../../utils';

interface IProps {
  id: number;
  rectangle: IRectCoordinates;
  isSelected: boolean;
  xOffset: number;
  yOffset: number;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  onZoneSelection: () => void;
  zoneType: TDistanceZoneType;
  color: string;
}

export const BackwallRectangle = ({
  id,
  rectangle,
  isSelected,
  xOffset,
  yOffset,
  cartesianModeParams,
  domains,
  zoneType,
  onZoneSelection,
  color,
}: IProps) => {
  const modificationMode = useAppSelector(selectDistanceModificationMode);
  const [modifyDictanseRect] = useModifyDistanceRectMutation();

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  const x0 = getXOffset(rectangle.x0 + xOffset);
  const y0 = getYOffset(rectangle.y0 - yOffset);
  const x1 = getXOffset(rectangle.x1 + xOffset);
  const y1 = getYOffset(rectangle.y1 - yOffset);
  const width = Math.abs(x1 - x0);
  const height = Math.abs(y1 - y0);

  const currentRect = useRef<IRectCoordinates>({
    x0,
    y0,
    x1,
    y1,
  });

  useEffect(() => {
    if (modificationMode !== BACKWALL_MODIFICATION_MODES.modify || !isSelected) {
      return;
    }

    const rect = d3.select(`#${IDs.backwallDistanceRect}${id} rect`);
    const firstAuxiliaryPoint = d3.select(`#${IDs.backwallDistanceRect}${id} .${IDs.firstAuxiliaryPoint}`);
    const secondAuxiliaryPoint = d3.select(`#${IDs.backwallDistanceRect}${id} .${IDs.secondAuxiliaryPoint}`);

    const points = [firstAuxiliaryPoint, secondAuxiliaryPoint];
    points.forEach((point) => {
      point.attr('cursor', 'grab');
    });

    function dragstarted(this: SVGCircleElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent) {
      const [x, y] = d3.pointer(event, this);
      d3.select(this).attr('cx', x).attr('cy', y);

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        currentRect.current = {
          x0: Math.min(x, x1),
          y0: Math.max(y, y0),
          x1: Math.max(x, x1),
          y1: Math.min(y, y0),
        };
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        currentRect.current = {
          x0: Math.min(x, x0),
          y0: Math.max(y, y1),
          x1: Math.max(x, x0),
          y1: Math.min(y, y1),
        };
      }

      const width = Math.abs(currentRect.current.x0 - currentRect.current.x1);
      const height = Math.abs(currentRect.current.y0 - currentRect.current.y1);

      rect
        .attr('x', currentRect.current.x0)
        .attr('y', currentRect.current.y1)
        .attr('width', width)
        .attr('height', height);
    }

    function dragended(this: SVGCircleElement) {
      d3.select(this).attr('cursor', 'grab');

      // const { x0, x1, y0, y1 } = currentRect.current;
      const { x: x0, y: y0 } = convertCartesianModeCoordsInPxToMm(
        { x: currentRect.current.x0, y: currentRect.current.y0 },
        cartesianModeParams,
        domains
      );
      const { x: x1, y: y1 } = convertCartesianModeCoordsInPxToMm(
        { x: currentRect.current.x1, y: currentRect.current.y1 },
        cartesianModeParams,
        domains
      );

      modifyDictanseRect({
        id,
        zoneType,
        rectangle: {
          x0,
          y0,
          x1,
          y1,
        },
      });
    }

    points.forEach((point) => {
      point.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
    });

    return () => {
      points.forEach((point) => {
        point.call(d3.drag().on('start', null).on('drag', null).on('end', null));
      });
    };
  }, [
    id,
    x0,
    y0,
    x1,
    y1,
    modificationMode,
    isSelected,
    domains,
    zoneType,
    cartesianModeParams.realSampleWidth,
    cartesianModeParams.realSampleHeight,
  ]);

  return (
    <>
      <g id={`${IDs.backwallDistanceRect}${id}`}>
        <text
          x={x0 + width / 2}
          y={y1 + height / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: '16px',
            fill: color,
          }}
        >
          {id}
        </text>
        <rect
          x={x0}
          y={y1}
          width={width}
          height={height}
          style={getBaseSvgElemStyles(color, isSelected ? 1.5 : 1, isSelected ? TRANSLUCENT_DIM_GREY : TRANSPARENT)}
          onClick={onZoneSelection}
        />
        {modificationMode === CRACKS_MODIFICATION_MODES.modify && isSelected && (
          <>
            <circle
              className={IDs.firstAuxiliaryPoint}
              cx={x0}
              cy={y1}
              r={3}
              style={getBaseSvgElemStyles(TRANSPARENT, 0, color)}
            />
            <circle
              className={IDs.secondAuxiliaryPoint}
              cx={x1}
              cy={y0}
              r={3}
              style={getBaseSvgElemStyles(TRANSPARENT, 0, color)}
            />
          </>
        )}
      </g>
    </>
  );
};
