import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  BASE_DB_KEYS,
  DB_TABLE_COLUMN_TITLES,
  DEFAULT_ERROR_MESSAGE,
  ec2AutoInstanceHeader,
  MODAL_STATUS,
  MODAL_TITLE,
} from '../../../consts';
import { ResultsTableState, ResultTableState } from '../../../types';
import {
  createModalMessageBody,
  downloadArchive,
  getDbActiveEntries,
  getDbVisibleEntries,
  getFormatedDateAndTimeString,
  getHeightAnalysisColor,
  getMergedFiltersResults,
  getValueOfNestedObject,
  isNumber,
  isString,
  replaceFalsyObjectsWithNull,
  transformAdditionalFiltersBody,
} from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import {
  DatabaseFiltersResponse,
  DatabaseSnapshotsResponse,
  DBChartDataPayload,
  DBHeightAnalysisResponse,
  DBHeightAnalysisTransformedResponse,
  DBReportParams,
  DBSingularChartDataPayload,
  MergedFiltersResultInfo,
  TDatabaseAdditionalFiltersResponse,
  TransformedDBAdditionalFiltersResponse,
  TSegAdditionalFiltersDefaultResultMethod,
} from './types';

const {
  updateResultTable,
  semiResetDatabaseResultTable,
  updateHeightAnalysisVisibleIds,
  setActiveSnapshots,
  setSpinnerVisible,
  setMessageModal,
  setDbReportVisible,
} = allActions;

export const databaseApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveDatabase: build.mutation<DatabaseFiltersResponse[], void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const { database } = getState() as TypedRootState;
        const dbCopy = JSON.parse(JSON.stringify(database));

        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ({
          url: 'db',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: replaceFalsyObjectsWithNull(dbCopy),
        });

        dispatch(setSpinnerVisible(false));

        return result as QueryReturnValue<DatabaseFiltersResponse[], FetchBaseQueryError, unknown>;
      },
    }),
    getDbSnapshots: build.mutation<DatabaseSnapshotsResponse, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          database: {
            snapshots: { analysisResultsIds, selectedSnapshotTypes },
          },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'db/snapshots',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: {
            analysisResultsIds,
            ...selectedSnapshotTypes,
          },
        });

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as DatabaseSnapshotsResponse;

        const activeSnapshots = data.reduce((acc, { snapshots, analysisResultId }) => {
          acc.push(analysisResultId);

          snapshots.forEach((i) => {
            acc.push(i.snapshotName);
          });

          return acc;
        }, [] as string[]);

        dispatch(
          setActiveSnapshots({
            checked: activeSnapshots.concat('snapshots'),
            halfChecked: [],
          }),
        );

        return { data };
      },
    }),
    exportDbComparisonSnapshots: build.mutation<void, string>({
      async queryFn(arg, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'db/snapshots/compare',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body: {
            snapshotBase64: arg,
          },
        });

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(
          setMessageModal(
            createModalMessageBody(MODAL_STATUS.SUCCESS, MODAL_TITLE.notification, 'modals.snapshot-exported'),
          ),
        );

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    getDbHeightAnalysis: build.mutation<DBHeightAnalysisTransformedResponse[], void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          database: {
            heightAnalysis: { activeIds },
          },
          api,
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'db/heights',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body: activeIds,
        });

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const heightAnalysis = result.data as DBHeightAnalysisResponse[];
        const dbApiResults = api.mutations?.databaseResults?.data as DatabaseFiltersResponse[] | undefined;
        const additionalFiltersApiResults = api.mutations?.additionalFiltersResults?.data as
          | TransformedDBAdditionalFiltersResponse[]
          | undefined;

        const dbResults = getMergedFiltersResults(dbApiResults, additionalFiltersApiResults);

        const transformedHeightAnalysisData = heightAnalysis.map((d, idx) => {
          const intersection = dbResults.find((i) => i.analysisResultId === d.analysisResultId);
          if (intersection) {
            dispatch(updateHeightAnalysisVisibleIds(intersection.analysisResultId));

            return {
              ...d,
              color: getHeightAnalysisColor(idx),
              title: `${intersection.name}_${getFormatedDateAndTimeString(intersection.analysisDate)}`,
            };
          }

          return d;
        });

        return { data: transformedHeightAnalysisData as DBHeightAnalysisTransformedResponse[] };
      },
    }),
    exportFilterResults: build.mutation<void, string[] | void>({
      async queryFn(args, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          database: { resultTable },
          api,
        } = getState() as TypedRootState;
        const dbApiResults = api.mutations?.databaseResults?.data as DatabaseFiltersResponse[] | undefined;
        const additionalFiltersApiResults = api.mutations?.additionalFiltersResults?.data as
          | TransformedDBAdditionalFiltersResponse[]
          | null
          | undefined;

        const visibleEntries = getDbVisibleEntries(getDbActiveEntries(resultTable));
        const results = getMergedFiltersResults(dbApiResults, additionalFiltersApiResults);
        const columnNames = visibleEntries.map((i) => DB_TABLE_COLUMN_TITLES[i as keyof typeof DB_TABLE_COLUMN_TITLES]);
        const resultsValues = results.map((res) =>
          visibleEntries
            .map((key) => getValueOfNestedObject((res as unknown) as Record<string, unknown>, key) ?? '-')
            .map((value) =>
              Array.isArray(value)
                ? value.map((item) => Object.fromEntries(Object.entries(item).filter(([, v]) => v !== null)))
                : value,
            )
            .map((value) => (isString(value) ? value : JSON.stringify(value))),
        );

        const result = await fetchWithBQ({
          url: 'db/export',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body: {
            columnNames,
            resultsValues,
            snapshotsNames: args ?? [],
          },
          responseHandler: async (response) => await downloadArchive(response, 'FilterResult'),
          cache: 'no-cache',
        });

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    updateDBAdditionalFilters: build.mutation<TransformedDBAdditionalFiltersResponse[] | null, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          database: { additionalFilters, resultTable },
        } = getState() as TypedRootState;

        const copyFilters = JSON.parse(JSON.stringify(additionalFilters));

        const result = await fetchWithBQ({
          url: 'db/additionalFilters',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body: transformAdditionalFiltersBody(copyFilters),
        });

        if (result?.data === null) {
          dispatch(semiResetDatabaseResultTable());
          return { data: result.data };
        }

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as TDatabaseAdditionalFiltersResponse;

        const transformedResponse: MergedFiltersResultInfo[] = [];

        data.forEach((d) => {
          const copy = {} as MergedFiltersResultInfo;

          Object.entries(d).forEach(([key, value]) => {
            if (key === 'segregationsAnalysis') {
              if (value) {
                const { defaultResults, astmBilletResult, gostBilletResult, gostSlabResult } = value;

                if (defaultResults.length) {
                  defaultResults.forEach((res: { method: TSegAdditionalFiltersDefaultResultMethod }) => {
                    //@ts-ignore
                    copy[
                      `segregationsAnalysis${
                        res.method as TSegAdditionalFiltersDefaultResultMethod
                      }` as keyof MergedFiltersResultInfo
                    ] = res;
                  });
                }

                copy.segregationsAnalysisAstmBilletResult = astmBilletResult;
                copy.segregationsAnalysisGostBilletResult = gostBilletResult;
                copy.segregationsAnalysisGostSlabResult = gostSlabResult;
              }
            } else if (key === 'flawsAnalysis') {
              if (value) {
                const { smsResult, gostSlabResult, gostBilletResult, astmResult } = value;

                copy.flawsAnalysisSmsResult = smsResult;
                copy.flawsAnalysisGostSlabResult = gostSlabResult;
                copy.flawsAnalysisGostBilletResult = gostBilletResult;
                copy.flawsAnalysisAstmResult = astmResult;
              }
            } else if (key === 'cracksAnalysis') {
              if (value) {
                const { smsResult, gostResult, astmResult, cracksCount, singularCracks } = value;

                copy.cracksAnalysisSmsResult = smsResult;
                copy.cracksAnalysisGostResult = gostResult;
                copy.cracksAnalysisAstmResult = astmResult;
                copy.cracksAnalysisCracksCount = cracksCount;

                if (singularCracks && singularCracks.length) {
                  copy.cracksAnalysisSingularCracks = singularCracks;
                }
              }
            } else {
              // @ts-ignore
              copy[key] = value;
            }
          });

          transformedResponse.push(copy);
        });

        const activeFields = getDbActiveEntries((resultTable as unknown) as ResultsTableState);

        if (transformedResponse.length) {
          updateResultTableStateRecursively(transformedResponse[0], '', activeFields);
        }

        function updateResultTableStateRecursively(
          obj: MergedFiltersResultInfo,
          prefix = '',
          activeFields: [string, ResultTableState][],
        ) {
          for (const [key, value] of Object.entries(obj)) {
            if (key === 'id') {
              continue;
            }

            const stringKeys = `${prefix}${prefix ? '-' : ''}${key}`;
            let isExist = false;

            for (const [k] of activeFields) {
              if (BASE_DB_KEYS.includes(k)) {
                continue;
              }

              if (k === stringKeys) {
                isExist = true;
              }
            }

            const updateResultTableState = () => {
              dispatch(
                updateResultTable({
                  stringKeys,
                  value: isExist ? Object.fromEntries(activeFields)[stringKeys] : { visible: true, active: true },
                }),
              );
            };

            if (value !== null) {
              if (isNumber(value) || isString(value) || (Array.isArray(value) && value.length)) {
                updateResultTableState();
              } else if (key === 'cracksAnalysisCracksCount') {
                updateResultTableState();
              } else {
                //@ts-ignore
                updateResultTableStateRecursively(value, stringKeys, activeFields);
              }
            }
          }

          return;
        }

        return { data: transformedResponse };
      },
    }),
    generateDatabaseReport: build.mutation<void, DBReportParams>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));
        const {
          report: { controller },
          database: { selectedReportIds },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'db/report',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body: {
            controller,
            analysisResultsIds: selectedReportIds,
            filename: 'Default',
            ...body,
          },
          responseHandler: async (response) => await downloadArchive(response, 'Reports'),
          cache: 'no-cache',
        });

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setSpinnerVisible(false));
        dispatch(setDbReportVisible(false));
        dispatch(
          setMessageModal(createModalMessageBody(MODAL_STATUS.SUCCESS, MODAL_TITLE.notification, 'modals.report-sent')),
        );

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    saveDatabaseChartData: build.mutation<void, DBChartDataPayload>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ({
          url: 'db/chart/save',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body,
        });

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setSpinnerVisible(false));

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    saveDatabaseSingularChartData: build.mutation<void, DBSingularChartDataPayload>({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ({
          url: 'db/singularChart/save',
          headers: ec2AutoInstanceHeader,
          method: 'POST',
          body,
        });

        if (result?.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setSpinnerVisible(false));

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    clearDatabase: build.query<void, void>({
      query: () => ({
        url: 'db/clear',
        method: 'GET',
        headers: ec2AutoInstanceHeader,
      }),
    }),
  }),
});

export const {
  useSaveDatabaseMutation,
  useUpdateDBAdditionalFiltersMutation,
  useExportFilterResultsMutation,
  useGetDbHeightAnalysisMutation,
  useGetDbSnapshotsMutation,
  useGenerateDatabaseReportMutation,
  useExportDbComparisonSnapshotsMutation,
  useSaveDatabaseChartDataMutation,
  useSaveDatabaseSingularChartDataMutation,
  useLazyClearDatabaseQuery,
} = databaseApi;
