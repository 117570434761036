import { BackwallRemarksNames, RemarksNames } from '../types';
import { CRACKS_ANALYSIS } from './cracks';
import { FLAWS_ANALYSIS } from './flaws';
import { SEG_ANALYSIS } from './segregations';

export const REMARKS_NAMES: RemarksNames = {
  titleRemarks: 'titleRemarks',
  geometry: 'geometry',
  segregations: {
    [SEG_ANALYSIS.astm]: 'astmRemarks',
    [SEG_ANALYSIS.gost]: 'gostRemarks',
    [SEG_ANALYSIS.mm]: 'mannesmannRemarks',
    [SEG_ANALYSIS.sep]: 'sep100Remarks',
  },
  flaws: {
    [FLAWS_ANALYSIS.astm]: 'astmRemarks',
    [FLAWS_ANALYSIS.gost]: 'gostRemarks',
    [FLAWS_ANALYSIS.sms]: 'smsRemarks',
  },
  cracks: {
    [CRACKS_ANALYSIS.sms]: 'smsRemarks',
    [CRACKS_ANALYSIS.gost]: 'gostRemarks',
    [CRACKS_ANALYSIS.astm]: 'astmRemarks',
  },
  backwall: {} as Record<any, BackwallRemarksNames>,
  backwallDistance: 'backwallDistance',
  backwallFlaws: 'backwallFlaws',
  zones: 'zones',
};
