import React from 'react';

export const EntryDirection = () => {
  return (
    <svg className="entry-direction" width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke="#000000">
          <circle id="Oval" fill="#FFFFFF" cx="12.5" cy="12.5" r="12.0833333"></circle>
          <circle id="Oval" fill="#000000" cx="12.5" cy="12.5" r="2.41666667"></circle>
        </g>
      </g>
    </svg>
  );
};

