import { TranslatedText } from '@sms/plasma-ui';
import Button from '@sms/plasma-ui/lib/components/Button';
import React, { useCallback } from 'react';

import { SETTINGS_SECTIONS_TITLE, typedGeneralSettingsEntries } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import {
  useLazyGetSettingsQuery,
  useUpdateAnalysisSettingsMutation,
  useUpdateBackwallSettingsMutation,
  useUpdateCracksSettingsMutation,
  useUpdateFlawsSettingsMutation,
  useUpdateSegregationsSettingsMutation,
} from '../../../store/api/settings';
import { TSettingsSection } from '../../../store/api/settings/types';
import { selectInitialSettings, selectSettings } from '../../../store/selectors';
import { INumberInputBlurHandlerArgs } from '../../../types';
import { isStartEndNumberInputValid, shallowCompareObjects } from '../../../utils';
import { FormInput } from '../../FormInput';

export const GeneralSettings = () => {
  const storeSettings = useAppSelector(selectSettings);
  const initialSettings = useAppSelector(selectInitialSettings);
  const { setAnalysisSetting } = useActionCreators();
  const [getSettingsTrigger] = useLazyGetSettingsQuery();
  const [updateAnalysisSettingsTrigger] = useUpdateAnalysisSettingsMutation();
  const [updateSegregationsSettingsTrigger] = useUpdateSegregationsSettingsMutation();
  const [updateFlawsSettingsTrigger] = useUpdateFlawsSettingsMutation();
  const [updateCracksSettingsTrigger] = useUpdateCracksSettingsMutation();
  const [updateBackwallSettingsTrigger] = useUpdateBackwallSettingsMutation();

  const isFieldDisabled = useCallback(
    (section: TSettingsSection, key: string) => {
      if (section === 'backwallSettings' && key === 'rangeStart') {
        return storeSettings.backwallSettings.autoRangeStart.parameter;
      }

      if (section === 'backwallSettings' && key === 'rangeEnd') {
        return storeSettings.backwallSettings.autoRangeEnd.parameter;
      }
    },
    [storeSettings.backwallSettings.autoRangeEnd.parameter, storeSettings.backwallSettings.autoRangeStart.parameter]
  );

  const updateSettingsHandler = async () => {
    if (!initialSettings) return;

    if (!shallowCompareObjects(initialSettings.generalSettings, storeSettings.generalSettings)) {
      await updateAnalysisSettingsTrigger();
    }

    if (!shallowCompareObjects(initialSettings.segregationsSettings, storeSettings.segregationsSettings)) {
      await updateSegregationsSettingsTrigger();
    }

    if (!shallowCompareObjects(initialSettings.flawsSettings, storeSettings.flawsSettings)) {
      await updateFlawsSettingsTrigger();
    }

    if (!shallowCompareObjects(initialSettings.cracksSettings, storeSettings.cracksSettings)) {
      await updateCracksSettingsTrigger();
    }

    if (!shallowCompareObjects(initialSettings.backwallSettings, storeSettings.backwallSettings)) {
      await updateBackwallSettingsTrigger();
    }

    getSettingsTrigger();
  };

  const handleNumberInputBlur = ({ section, range, startKey, endKey }: INumberInputBlurHandlerArgs) => {
    //@ts-ignore
    const start = storeSettings[section][startKey].parameter;
    //@ts-ignore
    const end = storeSettings[section][endKey].parameter;

    const isInputsValid = isStartEndNumberInputValid(start, end);

    if (isInputsValid) return;

    if (range === 'start') {
      setAnalysisSetting({ section, id: endKey, value: start });
    }
    if (range === 'end') {
      setAnalysisSetting({ section, id: startKey, value: end });
    }
  };

  return (
    <>
      <div className="settings__section">
        {typedGeneralSettingsEntries.map(([section, options]) => (
          <div key={section} className={section === 'backwallSettings' ? 'backwall-settings' : ''}>
            <h3 className="side-menu__title">
              <TranslatedText textKey={SETTINGS_SECTIONS_TITLE[section]} />
            </h3>
            <div className="settings__options">
              {options.map((key) => (
                <FormInput
                  key={key}
                  id={key}
                  label={`settings.${section}.${key}`}
                  disabled={isFieldDisabled(section, key)}
                  //@ts-ignore
                  value={storeSettings[section][key]?.parameter}
                  hintMode={'hidden'}
                  //@ts-ignore
                  hint={`settings.hint.${storeSettings[section][key]?.description}`}
                  section={section}
                  onChange={setAnalysisSetting}
                  onBlur={handleNumberInputBlur}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <Button title="common.save" onClick={updateSettingsHandler} />
    </>
  );
};
