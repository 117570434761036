import { LOCALSTORAGE_KEYS } from '../consts';
import { getFromLocalStorage } from './localStorage';

interface TokenApplication {
  is_admin: boolean;
  roles?: string[];
  permissions?: string[];
}

interface TokenPayload {
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  is_admin: boolean;
  is_global_admin: boolean;
  security: Record<string, TokenApplication>;
}

type TokenConfig = Omit<TokenPayload, 'security'> & TokenApplication;

export const getTokenData = (appCode: string): TokenConfig | undefined => {
  const token = getFromLocalStorage(LOCALSTORAGE_KEYS.SMSToken);

  if (!token) return;

  const [, payload] = token.split('.');
  const { is_admin, security, ...userConfig } = JSON.parse(
    window.atob(payload)
  ) as TokenPayload;
  const appConfig = security[appCode] || {};

  return {
    ...userConfig,
    ...appConfig,
    is_global_admin: is_admin,
    is_admin: !!(is_admin || appConfig.is_admin),
  };
};
