import './styles.scss';

import { Button, navigate } from '@sms/plasma-ui';
import React from 'react';

import { useSaveDatabaseMutation } from '../../store/api/database';
import { DBResultParameters } from './DBResultParameters';
import { DBSampleParameters } from './DBSampleParameters';

export const DatabaseForm = () => {
  const [saveFiltersTrigger, { data }] = useSaveDatabaseMutation({ fixedCacheKey: 'databaseResults' });

  const handleResultsNavigate = () => {
    navigate('database/results');
  };

  const handleSaveFiltersClick = async () => {
    await saveFiltersTrigger();
    handleResultsNavigate();
  };

  return (
    <div className="db__container">
      <DBSampleParameters />
      <DBResultParameters />
      <Button title={'Filter'} onClick={handleSaveFiltersClick} />
      <Button title={'db.go-to-results'} onClick={handleResultsNavigate} disabled={!data} />
    </div>
  );
};
