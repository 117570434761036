import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_SLICE_C_RANGE } from '../../consts';
import { ISliderRange } from '../../types/scanData';

const initialState = {
  start: DEFAULT_SLICE_C_RANGE.start,
  end: DEFAULT_SLICE_C_RANGE.end,
};

export const cRangeForLoadedGeometrySlice = createSlice({
  name: 'cRangeForLoadedGeometry',
  initialState,
  reducers: {
    setCRangeForLoadedGeometry: (state, action: PayloadAction<ISliderRange>) => action.payload,
  },
});

export const { actions: cRangeForLoadedGeometryActions, reducer: cRangeForLoadedGeometry } = cRangeForLoadedGeometrySlice;
