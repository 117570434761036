import React, { FC } from 'react';

import { GREEN_PRIMARY, RED_PRIMARY, YELLOW_PRIMARY } from '../../../../../consts';
import { TSegZone } from '../../../../../store/api/segregation/types';
import { IZonesNewZoneParams } from '../../../../../store/api/zones/types';
import { TTupleOfTwoNumbers } from '../../../../../types';
import { ICartesianModeParams, IGraphParams } from '../../../../../utils';
import { CircularZone } from '../../../../CircularZone';
import { ForbiddenZones } from '../../../../ForbiddenZones';
import { RectangularZone } from '../../../../RectangularZone';
import { CenterPoint } from './CenterPoint';
import { InnerRectangle } from './InnerRectangle';
import { SegLineY } from './SegLineY';

interface IProps {
  zone: TSegZone;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx: number;
  billetCutDy: number;
  message: string | null;
  forbiddenZones: IZonesNewZoneParams[];
}

export const SegZones: FC<IProps> = ({
  forbiddenZones,
  zone,
  domains,
  graphParams,
  billetCutDx,
  billetCutDy,
  message,
  cartesianModeParams,
}) => {

  return (
    <>
      <ForbiddenZones
        zones={forbiddenZones}
        cartesianModeParams={cartesianModeParams}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
      />
      {'innerRectangle' in zone && 'fullSampleRectangle' in zone && (
        <RectangularZone
          id={'innerRect'}
          domains={domains}
          graphParams={cartesianModeParams}
          coordinates={zone.innerRectangle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
          stroke={RED_PRIMARY}
        />
      )}
      {'zoneRRectangle' in zone && (
        <RectangularZone
          id={'zoneRRectangle'}
          domains={domains}
          graphParams={cartesianModeParams}
          coordinates={zone.zoneRRectangle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'zoneCRectangle' in zone && (
        <RectangularZone
          id={'zoneCRectangle'}
          domains={domains}
          graphParams={cartesianModeParams}
          coordinates={zone.zoneCRectangle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'zoneSRectangle' in zone && (
        <RectangularZone
          id={'zoneSRectangle'}
          domains={domains}
          graphParams={cartesianModeParams}
          coordinates={zone.zoneSRectangle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'outerRectangle' in zone && (
        <RectangularZone id={'outerRect'} domains={domains} graphParams={cartesianModeParams} coordinates={zone.outerRectangle} />
      )}
      {'innerRectangle' in zone && !('fullSampleRectangle' in zone) && !('sepZoneRectangle' in zone) && (
        <InnerRectangle
          domains={domains}
          graphParams={cartesianModeParams}
          coordinates={zone.innerRectangle}
        />
      )}
      {'innerRectangle' in zone && !('fullSampleRectangle' in zone) && 'sepZoneRectangle' in zone && (
        <InnerRectangle
          domains={domains}
          graphParams={cartesianModeParams}
          coordinates={zone.innerRectangle}
        >
          <RectangularZone
            id={'sepZoneRectangle'}
            domains={domains}
            graphParams={cartesianModeParams}
            coordinates={zone.sepZoneRectangle}
            stroke={message ? YELLOW_PRIMARY : GREEN_PRIMARY}
          />
        </InnerRectangle>
      )}
      {'zoneCCircle' in zone && (
        <CircularZone
          domains={domains}
          graphParams={cartesianModeParams}
          params={zone.zoneCCircle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'zoneRCircle' in zone && (
        <CircularZone
          domains={domains}
          graphParams={cartesianModeParams}
          params={zone.zoneRCircle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'zoneSCircle' in zone && (
        <CircularZone
          domains={domains}
          graphParams={cartesianModeParams}
          params={zone.zoneSCircle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'innerCircle' in zone && (
        <CircularZone
          domains={domains}
          graphParams={cartesianModeParams}
          params={zone.innerCircle}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'centerPoint' in zone && (
        <CenterPoint
          graphParams={cartesianModeParams}
          domains={domains}
          coordinates={zone.centerPoint}
          outerGraphHeight={graphParams.innerSvgHeight}
          outerGraphWidth={graphParams.innerSvgWidth}
          billetCutDx={billetCutDx}
          billetCutDy={billetCutDy}
        />
      )}
      {'segLineY' in zone && (
        <SegLineY
          domains={domains}
          yCoordinate={zone.segLineY}
          length={graphParams.innerSvgWidth}
          graphParams={cartesianModeParams}
          outerGraphHeight={graphParams.innerSvgHeight}
        />
      )}
    </>
  );
};
