import { Image, Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { FC, useState } from 'react';

import { HeightAnalysisGraph } from '../../../../../components/HeightAnalysisGraph';
import { DBHeightAnalysisTransformedResponse } from '../../../../../store/api/database/types';

interface Props {
  data: DBHeightAnalysisTransformedResponse[];
}

export const HeightAnalysisWindow: FC<Props> = ({ data }) => {
  const [activeKey, setActiveKey] = useState<string>('0');

  const xHorizontalDomain = Math.max(...data.map((d) => d.axisXMm));
  const xVerticalDomain = Math.max(...data.map((d) => d.axisYMm));
  const yHorizontalDomain = Math.max(
    ...data.map((d) => Math.max(...(d.heightsAnalysisData?.heightAnalysisHorizontal ?? [1]))),
  );
  const yVerticalDomain = Math.max(
    ...data.map((d) => Math.max(...(d.heightsAnalysisData?.heightAnalysisVertical ?? [1]))),
  );

  return (
    <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
      <TabPane tab={<TranslatedText textKey="Horizontal" />} key={'0'}>
        <HeightAnalysisGraph data={data} type={'horizontal'} xDomain={xHorizontalDomain} yDomain={yHorizontalDomain} />
        <Image />
      </TabPane>
      <TabPane tab={<TranslatedText textKey="Vertical" />} key={'1'}>
        <HeightAnalysisGraph data={data} type={'vertical'} xDomain={xVerticalDomain} yDomain={yVerticalDomain} />
      </TabPane>
    </Tabs>
  );
};
