import './styles.scss';

import { NumberInput, RadioGroup, TranslatedText } from '@sms/plasma-ui';
import { Modal } from '@sms/plasma-ui/lib/components/Modal/Modal';
import React, { useState } from 'react';

import { RECIPE_FLAWS_ZONES_FIELDS, RECIPE_FLAWS_ZONES_UNITS, TYPE_DISCRIMINATOR } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectRecipeData } from '../../../store/selectors';
import { IRecipeFlawZone, IRecipeZoneInputs, RadioGroupData, typedEntries } from '../../../types';
import { NumberInputValue } from '../../RangeBar';

interface IProps {
  isVisible: boolean;
}

const initialState: IRecipeFlawZone = {
  X1: 0,
  X2: 0,
  Y1: 0,
  Y2: 0,
  Unit: 'mm',
};

export const RecipeSetFlawsZonesModal = ({ isVisible }: IProps) => {
  const { SmsZone1, SmsZone2 } = useAppSelector(selectRecipeData)[TYPE_DISCRIMINATOR.flawsAnalysisStageModel];
  const [zones, setZones] = useState({ SmsZone1, SmsZone2 });
  const { setRecipeSetZonesVisible, setRecipeFlawsZones } = useActionCreators();

  const handleInputChange = (value: number | string | undefined, zone: 'SmsZone1' | 'SmsZone2', field: string) => {
    if (!value) return;

    setZones((prev) => ({ ...prev, [zone]: { ...(prev[zone] ? prev[zone] : initialState), [field]: value } }));
  };

  const handleRadioChange = (e: any, zone: 'SmsZone1' | 'SmsZone2') => {
    setZones((prev) => ({ ...prev, [zone]: { ...(prev[zone] ? prev[zone] : initialState), Unit: e.target.value } }));
  };

  const onOk = () => {
    setRecipeFlawsZones(zones);
    setRecipeSetZonesVisible(false);
  };

  const onCancel = () => {
    setRecipeSetZonesVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      okText="OK"
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      //@ts-ignore
      title={<TranslatedText textKey="recipes.flaws-sms-zones" />}
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      visible={isVisible}
      width={460}
      zIndex={15}
      className={'recipe-flaws-zones__modal'}
    >
      {typedEntries(RECIPE_FLAWS_ZONES_FIELDS).map(([zone, fields]) => (
        <div key={zone} className="recipe__set-flaws-zone">
          <h3 className="sub-menu__title">
            <TranslatedText textKey="side-menu.zone" />
            {zone === 'SmsZone1' ? '1' : '2'}
          </h3>
          <RadioGroup<RadioGroupData>
            name="FlawsZoneRadioGroup"
            dataSource={RECIPE_FLAWS_ZONES_UNITS}
            value={zones[zone]?.Unit}
            onChange={(e: any) => handleRadioChange(e, zone)}
          />
          {fields.map((field) => (
            <NumberInput
              key={field}
              name={field}
              value={zones[zone]?.[field as IRecipeZoneInputs] || 0}
              label={field}
              hint=""
              hintMode="hidden"
              decimalSeparator={'.'}
              precision={2}
              onChange={(value: NumberInputValue) => handleInputChange(value, zone, field)}
            />
          ))}
        </div>
      ))}
    </Modal>
  );
};
