import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import React, { FC } from 'react';

import { IProps } from '../index';

export const PoresSpotsNumberList: FC<IProps> = ({ statistics }) => {
  return (
    <>
      {statistics && 'poresSizes' in statistics && 'poresDefectsCounts' in statistics && (
        <ul className="seg__statistics__list">
          <li className="seg__statistics__item">
            <p>
              <TranslatedText textKey="side-menu.spots-number" />:
            </p>
          </li>
          {statistics.poresSizes.map((item, idx) => (
            <li key={idx} className="seg__statistics__item">
              <p>{item}:</p>
              <p>{statistics.poresDefectsCounts[idx]}</p>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
