import { IZonesNewZoneParams } from '../store/api/zones/types';
import { TCoordinates, TTupleOfTwoNumbers } from '../types';
import { getXOffsetFunc, getYOffsetFunc } from './geometry';
import { ICartesianModeParams } from './getCartesianModeParams';
import { getRectCenterPointCoords } from './getRectCenterPointCoords';
import { getToFixedValue } from './getToFixedValue';

export const convertDegToRadians = (angle: number): number => {
  return angle * Math.PI / 180;
};

export const getRectPointRotationOffsets = (x: number, y: number, centerX: number, centerY: number, angle: number): TCoordinates => {
  const xOffset = centerX + (x - centerX) * Math.cos(convertDegToRadians(angle)) - (y - centerY) * Math.sin(convertDegToRadians(angle));
  const yOffset = centerY + (x - centerX) * Math.sin(convertDegToRadians(angle)) + (y - centerY) * Math.cos(convertDegToRadians(angle));

  return {
    x: getToFixedValue(Math.abs(xOffset)),
    y: getToFixedValue(Math.abs(yOffset)),
  };
};

export const getRotationRectCoordsOffsetsInPx = (
  coords: IZonesNewZoneParams,
  params: ICartesianModeParams,
  domains: TTupleOfTwoNumbers,
  billetCutDx = 0,
  billetCutDy = 0,
): IZonesNewZoneParams => {
  const getXOffset = getXOffsetFunc(params, domains);
  const getYOffset = getYOffsetFunc(params, domains);

  const { leftTop, rightTop, rightBottom, leftBottom } = coords;

  return {
    leftTop: { x: getXOffset(leftTop.x + billetCutDx), y: getYOffset(leftTop.y + billetCutDy) },
    rightTop: { x: getXOffset(rightTop.x + billetCutDx), y: getYOffset(rightTop.y + billetCutDy) },
    rightBottom: { x: getXOffset(rightBottom.x + billetCutDx), y: getYOffset(rightBottom.y + billetCutDy) },
    leftBottom: { x: getXOffset(leftBottom.x + billetCutDx), y: getYOffset(leftBottom.y + billetCutDy) },
  };
};

export const getRotationRectCoordsOffsetsInMm = (coords: IZonesNewZoneParams, angle: number): IZonesNewZoneParams => {
  const { leftTop, rightTop, rightBottom, leftBottom } = coords;
  const { x: centerX, y: centerY } = getRectCenterPointCoords(leftTop, rightBottom);

  const newLeftTop = getRectPointRotationOffsets(leftTop.x, leftTop.y, centerX, centerY, -angle);
  const newRightTop = getRectPointRotationOffsets(rightTop.x, rightTop.y, centerX, centerY, -angle);
  const newRightBottom = getRectPointRotationOffsets(rightBottom.x, rightBottom.y, centerX, centerY, -angle);
  const newLeftBottom = getRectPointRotationOffsets(leftBottom.x, leftBottom.y, centerX, centerY, -angle);

  return {
    leftTop: newLeftTop,
    rightTop: newRightTop,
    rightBottom: newRightBottom,
    leftBottom: newLeftBottom,
  };
};
