import { getAverageValue } from './getAverageValue';

export const getRSD = (values: number[]): string => {
  const avgValue = getAverageValue(values);
  const sumOfSquaresOfTheDifference = values.reduce((acc, item) => {
    return acc + Math.pow(item - avgValue, 2);
  }, 0);
  const SD = Math.sqrt(sumOfSquaresOfTheDifference / (values.length - 1));

  return (SD / avgValue * 100).toFixed(2);
};
