import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  BACKWALL_ANALYSIS,
  CRACKS_ANALYSIS,
  DEFAULT_ERROR_MESSAGE,
  MODAL_STATUS,
  MODAL_TITLE,
  REPORT_TABS,
  SCAN_VIEWS,
} from '../../../consts';
import { createModalMessageBody, createProjectionDataBody } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { settingsApi } from '../settings';
import { sliceApi } from '../slice';
import {
  ICrackInsertion,
  ICrackModification,
  ICracksBilletCutRectZones,
  ICracksBilletCutRoundZones,
  ICracksBilletRectZones,
  ICracksBilletRoundZones,
  ICracksCrackInfo,
  ICracksResponse,
  ICracksStatistics,
  TCracksSlabZones,
} from './types';

const {
  setMadeAnalysis,
  setCracksStatistics,
  setMaxProjectionSliderRange,
  setSingleLayerSliderRange,
  setMessageModal,
} = allActions;

export const cracksApi = api.injectEndpoints({
  endpoints: (build) => ({
    findCracks: build.mutation<void, void>({
      async queryFn(args, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'cracks',
          method: 'POST',
          body: {
            volumeId,
            autoAnalysisMode: false,
          },
        });

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError>;
      },
    }),
    getAllCracks: build.query<ICracksResponse, void>({
      async queryFn(args, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/all');

        if (result.error) {
          dispatch(
            setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE)),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksResponse;
        const state = getState() as TypedRootState;
        const settings = settingsApi.endpoints.getSettings.select(undefined)(state)?.data;
        const {
          sliderRanges,
          toolbarOptions: { isMaxProjection },
        } = state;

        if (data?.analysisState === 'Completed' && data?.cracksDetectionData) {
          const {
            cracksDetectionData: { backwallFlawsMessage, backwallFlawsCompleted, isRangeFromSettings },
          } = data;

          if (isRangeFromSettings && settings) {
            const {
              generalAnalysisSettings: {
                cracksSettings: { rangeStart, rangeEnd },
              },
            } = settings;

            if (
              rangeStart.parameter !==
                (isMaxProjection ? sliderRanges.maxProjection.sliceC.start : sliderRanges.singleLayer.sliceC.start) ||
              rangeEnd.parameter !==
                (isMaxProjection ? sliderRanges.maxProjection.sliceC.end : sliderRanges.singleLayer.sliceC.end)
            ) {
              const sliceC = { start: rangeStart.parameter, end: rangeEnd.parameter };
              dispatch(setMaxProjectionSliderRange({ sliceC }));
              dispatch(setSingleLayerSliderRange({ sliceC }));
              await dispatch(
                sliceApi.endpoints.setProjectionsData.initiate(createProjectionDataBody(SCAN_VIEWS.C, sliceC), {
                  fixedCacheKey: 'sliceC',
                }),
              );
            }
          }

          if (backwallFlawsCompleted) {
            dispatch(
              setMadeAnalysis({
                analysis: REPORT_TABS.backwall,
                id: BACKWALL_ANALYSIS.flaws,
              }),
            );
          }

          if (backwallFlawsMessage) {
            dispatch(
              setMadeAnalysis({
                analysis: REPORT_TABS.backwall,
                id: BACKWALL_ANALYSIS.flaws,
              }),
            );
            dispatch(
              setMessageModal(
                createModalMessageBody(MODAL_STATUS.WARNING, MODAL_TITLE.operation, backwallFlawsMessage),
              ),
            );
          }
        }

        return { data };
      },
      providesTags: ['Cracks'],
    }),
    getCracksSlabZones: build.query<TCracksSlabZones, void>({
      async queryFn(args, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/zones/slab');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as TCracksSlabZones;

        return { data };
      },
      providesTags: ['Cracks Zones'],
    }),
    getCracksBilletRectZones: build.query<ICracksBilletRectZones, void>({
      async queryFn(_, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/zones/billetRect');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksBilletRectZones;

        return { data };
      },
      providesTags: ['Cracks Zones'],
    }),
    getCracksBilletCutRectZones: build.query<ICracksBilletCutRectZones, void>({
      async queryFn(_, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/zones/billetCutRect');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksBilletCutRectZones;

        return { data };
      },
      providesTags: ['Cracks Zones'],
    }),
    getCracksBilletRoundZones: build.query<ICracksBilletRoundZones, void>({
      async queryFn(_, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/zones/billetRound');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksBilletRoundZones;

        return { data };
      },
      providesTags: ['Cracks Zones'],
    }),
    getCracksBilletCutRoundZones: build.query<ICracksBilletCutRoundZones, void>({
      async queryFn(args, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/zones/billetCutRound');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksBilletCutRoundZones;

        return { data };
      },
      providesTags: ['Cracks Zones'],
    }),
    insertCrack: build.mutation<ICracksStatistics, ICrackInsertion>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          cracks: { analysis: analysisType },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'cracks/insert',
          method: 'POST',
          body: {
            ...body,
            analysisType,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksStatistics;

        dispatch(setCracksStatistics(data));

        return { data };
      },
      invalidatesTags: ['Cracks'],
    }),
    modifyCrack: build.mutation<ICracksStatistics, ICrackModification>({
      async queryFn(body, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          cracks: { analysis: analysisType },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'cracks/modify',
          method: 'POST',
          body: {
            ...body,
            analysisType,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksStatistics;

        dispatch(setCracksStatistics(data));

        return { data };
      },
      invalidatesTags: ['Cracks'],
    }),
    deleteCrack: build.mutation<ICracksStatistics, number[]>({
      async queryFn(ids, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          cracks: { analysis: analysisType },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'cracks/delete',
          method: 'POST',
          body: {
            ids,
            analysisType,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksStatistics;

        dispatch(setCracksStatistics(data));

        return { data };
      },
      invalidatesTags: ['Cracks'],
    }),
    getCrackInfo: build.mutation<ICracksCrackInfo, number>({
      async queryFn(id, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'cracks/info',
          method: 'POST',
          body: {
            id,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksCrackInfo;

        return { data };
      },
    }),
    getCracksSMSStatistics: build.query<ICracksStatistics, void>({
      async queryFn(args, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/smsStatistics');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(
          setMadeAnalysis({
            analysis: REPORT_TABS.cracks,
            id: CRACKS_ANALYSIS.sms,
          }),
        );

        const data = result.data as ICracksStatistics;

        dispatch(setCracksStatistics(data));

        return { data };
      },
    }),
    getCracksGOSTStatistics: build.query<ICracksStatistics, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/gostStatistics');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(
          setMadeAnalysis({
            analysis: REPORT_TABS.cracks,
            id: CRACKS_ANALYSIS.gost,
          }),
        );

        const data = result.data as ICracksStatistics;

        dispatch(setCracksStatistics(data));

        return { data };
      },
    }),
    getCracksASTMStatistics: build.query<ICracksStatistics, void>({
      async queryFn(args, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/astmStatistics');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksStatistics;

        dispatch(
          setMadeAnalysis({
            analysis: REPORT_TABS.cracks,
            id: CRACKS_ANALYSIS.astm,
          }),
        );

        dispatch(setCracksStatistics(data));

        return { data };
      },
    }),
    getBilletStatistics: build.query<ICracksStatistics, void>({
      async queryFn(args, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('cracks/billetCracks?analysisType=ASTM');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ICracksStatistics;

        dispatch(setCracksStatistics(data));

        return { data };
      },
    }),
  }),
});

export const {
  useFindCracksMutation,
  useInsertCrackMutation,
  useModifyCrackMutation,
  useDeleteCrackMutation,
  useGetCrackInfoMutation,
  useLazyGetCracksSMSStatisticsQuery,
  useLazyGetCracksGOSTStatisticsQuery,
  useLazyGetCracksASTMStatisticsQuery,
  useLazyGetAllCracksQuery,
  useLazyGetBilletStatisticsQuery,
  useGetAllCracksQuery,
  useGetCracksSlabZonesQuery,
  useGetCracksBilletRectZonesQuery,
  useGetCracksBilletRoundZonesQuery,
  useGetCracksBilletCutRectZonesQuery,
  useGetCracksBilletCutRoundZonesQuery,
} = cracksApi;
