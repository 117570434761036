import './styles.scss';

import React, { useEffect } from 'react';

import { useAppSelector } from '../../../hooks';
import { useBackwall } from '../../../hooks/useBackwall';
import { selectIsReportMode } from '../../../store/selectors';
import { BackwallFlawsRestMenu } from './BackwallFlawsRestMenu';
import { BackwallFlawsThresholds } from './BackwallFlawsThresholds';

export const BackwallFlaws = () => {
  const { handleBackwallFlawsChange } = useBackwall();
  const isReportMode = useAppSelector(selectIsReportMode);

  useEffect(() => {
    if (isReportMode) return;

    handleBackwallFlawsChange();
  }, [isReportMode]);

  return (
    <div className="backwall__menu__inner">
      <BackwallFlawsThresholds />
      <BackwallFlawsRestMenu />
    </div>
  );
};
