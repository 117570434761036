import React, { FC, useCallback } from 'react';

import { SLICE_TYPES } from '../../../../consts/geometry';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import {
  selectFlawsHeightAnalysisData,
  selectFlawsPolygonsCoords,
  selectFlawsRectZones,
  selectFlawsRoundZones,
  selectFlawsSelectedPolygon,
  selectIsFlawsHeightAnalysis,
  selectSliceType,
  selectVolumeId,
} from '../../../../store/selectors';
import { IBilletCutParams, IPolygonCoordinates } from '../../../../store/slices/types';
import { RectParams, TTupleOfTwoNumbers } from '../../../../types';
import { ICartesianModeParams, IGraphParams } from '../../../../utils';
import { HeightAnalysis } from '../../../HeightAnalysis';
import { Polygons } from '../../../Polygons';
import { FlawsZones } from './FlawsZones';

interface IProps {
  billetCutParams: IBilletCutParams;
  cartesianModeParams: ICartesianModeParams;
  innerShapeCartesianModeParams: RectParams;
  domains: TTupleOfTwoNumbers;
  billetCutDomains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;

}

export const FlawsSvgElements: FC<IProps> = ({
  billetCutParams,
  cartesianModeParams,
  innerShapeCartesianModeParams,
  domains,
  billetCutDomains,
  graphParams,
}) => {
  const { setFlawsSelectedPolygon } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const polygonsCoordinates = useAppSelector(selectFlawsPolygonsCoords);
  const selectedPolygon = useAppSelector(selectFlawsSelectedPolygon);
  const isHeightAnalysis = useAppSelector(selectIsFlawsHeightAnalysis);
  const rectZones = useAppSelector(selectFlawsRectZones);
  const roundZones = useAppSelector(selectFlawsRoundZones);
  const { heightAnalysisVertical, heightAnalysisHorizontal, firstHorizontalIndex, lastHorizontalIndex, factor } = useAppSelector((state) =>
    selectFlawsHeightAnalysisData(state, volumeId)
  );

  const handlePolygonClick = useCallback((polygon: IPolygonCoordinates) => {
    if (selectedPolygon?.id === polygon.id) {
      setFlawsSelectedPolygon(null);
    } else {
      setFlawsSelectedPolygon(polygon);
    }
  }, [selectedPolygon]);

  return (
    <>
      {isHeightAnalysis && (
        <HeightAnalysis
          sliceType={sliceType}
          factor={factor}
          heightAnalysisVertical={heightAnalysisVertical}
          heightAnalysisHorizontal={heightAnalysisHorizontal}
          firstHorizontalIndex={firstHorizontalIndex}
          lastHorizontalIndex={lastHorizontalIndex}
          graphHeight={
            sliceType === SLICE_TYPES.cut
              ? innerShapeCartesianModeParams.height
              : cartesianModeParams.realSampleHeight
          }
          graphWidth={
            sliceType === SLICE_TYPES.cut
              ? innerShapeCartesianModeParams.width
              : cartesianModeParams.realSampleWidth
          }
          domains={domains}
          xOffset={
            sliceType === SLICE_TYPES.cut
              ? innerShapeCartesianModeParams.x
              : cartesianModeParams.x
          }
          yOffset={
            sliceType === SLICE_TYPES.cut
              ? innerShapeCartesianModeParams.y
              : cartesianModeParams.y
          }
        />
      )}
      <FlawsZones
        rectZones={rectZones}
        roundZones={roundZones}
        billetCutParams={billetCutParams}
        cartesianModeParams={cartesianModeParams}
        graphParams={graphParams}
        domains={sliceType === SLICE_TYPES.cut ? billetCutDomains : domains}
      />
      {polygonsCoordinates && (
        <Polygons
          selectedPolygon={selectedPolygon}
          polygonsCoordinates={polygonsCoordinates}
          domains={sliceType === SLICE_TYPES.cut ? billetCutDomains : domains}
          graphWidth={cartesianModeParams.realSampleWidth}
          graphHeight={cartesianModeParams.realSampleHeight}
          xOffset={cartesianModeParams.x}
          yOffset={cartesianModeParams.y}
          graphParams={cartesianModeParams}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
          selectPolygon={handlePolygonClick}
        />
      )}
    </>
  );
};
