export const IDs = {
  main: 'main',
  adaptImageGraph: 'adaptImageGraph',
  segGraph: 'segGraph',
  cracksGraph: 'cracksGraph',
  zonesGraph: 'zonesGraph',
  flawsGraph: 'flawsGraph',
  backwallGraph: 'backwallGraph',
  centerPoint: 'centerPoint',
  sliceBorder: 'sliceBorder',
  scale: 'scale',
  aScanView: 'aScanView',
  cropArea: 'cropArea',
  segLineY: 'segLineY',
  segInnerRect: 'segInnerRect',
  firstAuxiliaryLine: 'firstAuxiliaryLine',
  firstAuxiliaryPoint: 'firstAuxiliaryPoint',
  secondAuxiliaryLine: 'secondAuxiliaryLine',
  secondAuxiliaryPoint: 'secondAuxiliaryPoint',
  thirdAuxiliaryLine: 'thirdAuxiliaryLine',
  thirdAuxiliaryPoint: 'thirdAuxiliaryPoint',
  fourthAuxiliaryLine: 'fourthAuxiliaryLine',
  fourthAuxiliaryPoint: 'fourthAuxiliaryPoint',
  groupWrapper: 'groupWrapper',
  commonGraphWrapper: 'commonGraphWrapper',
  commonGraphInnerWrapper: 'commonGraphInnerWrapper',
  cracksGroupWrapper: 'cracksGroupWrapper',
  backwallGroupWrapper: 'backwallGroupWrapper',
  backwallFlawsGroupWrapper: 'backwallFlawsGroupWrapper',
  crack: 'crack',
  backwallDistanceRect: 'backwallDistanceRect',
  backwallDistanceLine: 'backwallDistanceLine',
  heightAnalysis: 'heightAnalysis',
  snapshotComparison: 'snapshotComparison',
  cancelProcess: 'cancelProcess',
  databaseCharts: 'databaseCharts',
};
