import { CRACKS_ANALYSIS, SLICE_TYPES } from '../consts';
import { TCracksAnalysis } from '../types';

export const getCheckedCracksAnalysis = (currentAnalysis: TCracksAnalysis, sliceType: string) => {
  if (
    (sliceType === SLICE_TYPES.slabLeft ||
      sliceType === SLICE_TYPES.slabMiddle ||
      sliceType === SLICE_TYPES.slabRight ||
      sliceType === SLICE_TYPES.sampleLong) &&
    currentAnalysis === CRACKS_ANALYSIS.astm
  ) {
    return CRACKS_ANALYSIS.sms;
  }

  if (
    (sliceType === SLICE_TYPES.rect && currentAnalysis === CRACKS_ANALYSIS.sms) ||
    sliceType === SLICE_TYPES.round ||
    sliceType === SLICE_TYPES.cut
  ) {
    return CRACKS_ANALYSIS.gost;
  }

  return currentAnalysis;
};
