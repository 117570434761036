// @ts-ignore
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import {
  BLACK_PRIMARY,
  CRACKS_MODIFICATION_MODES,
  IDs,
  INITIAL_COORDINATES,
  INITIAL_RECT_PARAMS,
  TRANSPARENT,
} from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { useInsertCrackMutation } from '../../../../../store/api/cracks';
import { selectCracksModificationMode, selectCracksSelectedTypes } from '../../../../../store/selectors';
import { RectParams, TCoordinates } from '../../../../../types';
import { getBaseSvgElemStyles, ICartesianModeParams } from '../../../../../utils';

interface Props {
  cartesianModeParams: ICartesianModeParams;
  imageWidth?: number;
  imageHeight?: number;
}

export const CrackInsertion = ({ cartesianModeParams, imageHeight, imageWidth }: Props) => {
  const modificationMode = useAppSelector(selectCracksModificationMode);
  const [addCrack] = useInsertCrackMutation();
  const { realSampleHeight, realSampleWidth, x: originX, y: originY } = cartesianModeParams;
  const selectedCrackType = useAppSelector(selectCracksSelectedTypes);

  const startDrawingCoords = useRef<TCoordinates>({ ...INITIAL_COORDINATES });

  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [currentRect, setCurrentRect] = useState<RectParams>(INITIAL_RECT_PARAMS);

  useEffect(() => {
    if (modificationMode !== CRACKS_MODIFICATION_MODES.insert) return;

    const drawingArea = d3.select(`#${IDs.cracksGroupWrapper}`);

    function onMouseDown(this: SVGGElement, e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      startDrawingCoords.current = { x, y };

      setIsDrawing(true);
    }

    function onMouseMove(this: SVGGElement, e: MouseEvent) {
      if (!isDrawing) return;

      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);
      const { x: initX, y: initY } = startDrawingCoords.current;

      setCurrentRect({
        x: Math.min(initX, x),
        y: Math.min(initY, y),
        width: Math.abs(initX - x),
        height: Math.abs(initY - y),
      });
    }

    function onMouseUp() {
      if (!imageHeight || !imageWidth || !selectedCrackType[0]) return;

      const wRatio = imageWidth / realSampleWidth;
      const hRatio = imageHeight / realSampleHeight;

      const { x, y, width, height } = currentRect;

      if (!x || !y || !width || !height) {
        setIsDrawing(false);
        setCurrentRect(INITIAL_RECT_PARAMS);

        return;
      }

      addCrack({
        crackBox: {
          x: wRatio * x,
          y: hRatio * y,
          width: wRatio * width,
          height: hRatio * height,
        },
        crackType: selectedCrackType[0],
      });

      setIsDrawing(false);
      setCurrentRect(INITIAL_RECT_PARAMS);
    }

    drawingArea.on('mousedown', onMouseDown).on('mousemove', onMouseMove).on('mouseup', onMouseUp);

    return () => drawingArea.on('mousedown', null).on('mousemove', null).on('mouseup', null);
  }, [
    modificationMode,
    realSampleWidth,
    realSampleHeight,
    originX,
    originY,
    currentRect,
    isDrawing,
    imageHeight,
    imageWidth,
    selectedCrackType,
  ]);

  return (
    <rect
      x={currentRect.x}
      y={currentRect.y}
      width={currentRect.width}
      height={currentRect.height}
      style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSPARENT)}
    />
  );
};
