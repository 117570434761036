export const TRANSPARENT = 'transparent';
export const GREY_PRIMARY = '#e0e0e0';
export const GREY_EXTRA_LIGHT = '#f4f4f4';
export const GREY_SECONDARY_EXTRA_LIGHT = '#d9d9d9';
export const WHITE_PRIMARY = '#ffffff';
export const BLACK_PRIMARY = '#000000';
export const RED_PRIMARY = '#ff0000';
export const ORANGE_PRIMARY = '#ffa500';
export const RED_BROWN_ORANGE = '#cd5700';
export const BLUE_PRIMARY = '#0074bb';
export const LIGHT_BLUE_PRIMARY = '#add8e6';
export const GREEN_PRIMARY = '#008000';
export const GREEN_LIGHT = '#9eff00';
export const YELLOW_PRIMARY = '#ffff00';
export const PINK_PRIMARY = '#ff4dff';
export const PINK_DARK = '#c100c4';
export const GREY_DARK = '#828282';
export const SKY_PRIMARY = '#02d1f5';
export const GREEN_SKY = '#02f5c8';
export const YELLOW_ORANGE = '#ffd000';
export const GREEN_GRASS = '#2ed93c';

export const TRANSLUCENT_DIM_GREY = 'rgba(105, 105, 105, .5)';
export const STROKE_GREY = '#bfbfbf';
export const STROKE_BLACK_SECONDARY = '#333333';
export const STROKE_GREY_SECONDARY_DEEP = '#8e8e8e';
