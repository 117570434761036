import './styles.scss';

import { Checkbox, TextInput, useRouter } from '@sms/plasma-ui';
import React, { ChangeEvent, useEffect, useMemo } from 'react';

import { isHostEnvStandalone, LOCALSTORAGE_KEYS } from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { useFilesSearch } from '../../../../hooks/useFilesSearch';
import {
  useLazyGetAutomaticAnalysisStatusQuery,
  useLazySetAutoAnalysisModeQuery,
} from '../../../../store/api/automatic-analysis';
import { selectIsAutomaticAnalysisModeEnabled, selectScanDataFilters } from '../../../../store/selectors';
import { checkAppRoute, debounce, getDateWithTime, setToExistingLocalStorageObject } from '../../../../utils';
import { DateInput } from '../../../UI/DateInput';

export const FilesFilters = () => {
  const { searchTerm, includeRecipes, orderBy, startDate, endDate, showDeletedFromCloud } = useAppSelector(
    selectScanDataFilters,
  );
  const autoAnalysisModeEnabled = useAppSelector(selectIsAutomaticAnalysisModeEnabled);
  const { setScanDataFilters, setPage } = useActionCreators();
  const { handleSearchFiles } = useFilesSearch();
  const debouncedSearchChange = useMemo(() => debounce(async () => await handleSearchFiles(), 1000), []);
  const [setAutoAnalysisModeTrigger] = useLazySetAutoAnalysisModeQuery();
  const [getAutoAnalysisStatusTrigger] = useLazyGetAutomaticAnalysisStatusQuery();
  const {
    route: { path },
  } = useRouter('route');
  const { isRecipes } = checkAppRoute(path);

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setScanDataFilters({ searchTerm: e.target.value });
    debouncedSearchChange();
  };

  const handleDateChange = (value: string, name: 'startDate' | 'endDate') => {
    const newValue = getDateWithTime(value, name === 'startDate' ? 'start' : 'end');
    const newDateObj = { [name]: newValue };

    setScanDataFilters(newDateObj);
    setToExistingLocalStorageObject(LOCALSTORAGE_KEYS.scanDataFiltersDates, newDateObj);
  };

  const handleClearDateChange = (name: 'startDate' | 'endDate') => {
    const newDateObj = { [name]: '' };

    setScanDataFilters(newDateObj);
    setToExistingLocalStorageObject(LOCALSTORAGE_KEYS.scanDataFiltersDates, newDateObj);
  };

  const handleShowDeletedChange = () => {
    setPage(1);
    setScanDataFilters({ showDeletedFromCloud: !showDeletedFromCloud });
  };

  const handleEnableAutomaticAnalysis = (e: any) => {
    setAutoAnalysisModeTrigger(e.target.checked).then(() => getAutoAnalysisStatusTrigger());
  };

  useEffect(() => {
    handleSearchFiles();
  }, [orderBy, startDate, endDate, includeRecipes, showDeletedFromCloud]);

  return (
    <div className="file-loader__filters">
      <div className="filters__search">
        <TextInput value={searchTerm} name="searchTerm" label="common.search" onChange={handleSearchTermChange} />
        <DateInput
          label={'modals.file-loader.start-date'}
          value={startDate}
          name={'startDate'}
          onChange={(value) => handleDateChange(value, 'startDate')}
          onClear={() => handleClearDateChange('startDate')}
        />
        <DateInput
          label={'modals.file-loader.end-date'}
          value={endDate}
          name={'endDate'}
          onChange={(value) => handleDateChange(value, 'endDate')}
          onClear={() => handleClearDateChange('endDate')}
        />
      </div>
      {!isHostEnvStandalone && (
        <Checkbox
          name={'showDeletedFromCloud'}
          checked={showDeletedFromCloud}
          title="modals.file-loader.show-deleted"
          onChange={handleShowDeletedChange}
        />
      )}
      {isHostEnvStandalone && isRecipes && (
        <Checkbox
          name={'enableAutomaticAnalysis'}
          checked={autoAnalysisModeEnabled}
          title="Enable automatic analysis"
          onChange={handleEnableAutomaticAnalysis}
        />
      )}
    </div>
  );
};
