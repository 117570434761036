import './styles.scss';

import { Switch } from '@sms/plasma-ui';
import React, { FC, memo } from 'react';

import { SWITCH_DATA, SWITCH_NAMES } from '../../../consts';

interface ISwitchGroupProps {
  isScale?: boolean;
  isMonochrome?: boolean;
  isInverse?: boolean;
  isDisabled?: boolean;
  handleSwitchClick: (name: string) => void;
}

export const SwitchGroup: FC<ISwitchGroupProps> = memo(function SwitchGroup({
  isScale = false,
  isMonochrome = false,
  isInverse = false,
  isDisabled = false,
  handleSwitchClick,
}) {
  return (
    <div className="switch-group">
      {SWITCH_DATA.map(({ label, name }, index) => {
        let checked = false;
        switch (name) {
          case SWITCH_NAMES.invert:
            checked = isInverse;
            break;
          case SWITCH_NAMES.color:
            checked = !isMonochrome;
            break;
          case SWITCH_NAMES.cut:
            checked = isScale;
            break;
          default:
            return checked;
        }
        return (
          <Switch
            key={index}
            hintMode="hidden"
            label={label}
            disabled={isDisabled}
            name={`app.palette.${name}`}
            checked={checked}
            onClick={() => handleSwitchClick(name)}
          />
        );
      })}
    </div>
  );
});
