import {
  BILLET_RECT_CRACK_TYPES_LABELS,
  BILLET_ROUND_CRACK_TYPES_LABELS,
  SLAB_MIDDLE_CRACK_TYPES_LABELS,
  SLAB_SIDES_CRACK_TYPES_LABELS,
  SLICE_TYPES,
} from '../consts';
import { TSliceType, typedEntries } from '../types';

export const getCrackTypesLabelsEntries = (sliceType: TSliceType | string, shape: string) => {
  switch (sliceType) {
    case SLICE_TYPES.slabMiddle:
      return typedEntries(SLAB_MIDDLE_CRACK_TYPES_LABELS);
    case SLICE_TYPES.slabLeft:
      return typedEntries(SLAB_SIDES_CRACK_TYPES_LABELS);
    case SLICE_TYPES.slabRight:
      return typedEntries(SLAB_SIDES_CRACK_TYPES_LABELS);
    case SLICE_TYPES.sampleLong:
      return typedEntries(SLAB_MIDDLE_CRACK_TYPES_LABELS);
    case SLICE_TYPES.rect:
      return typedEntries(BILLET_RECT_CRACK_TYPES_LABELS);
    case SLICE_TYPES.round:
      return typedEntries(BILLET_ROUND_CRACK_TYPES_LABELS);
    case SLICE_TYPES.cut:
      return typedEntries(BILLET_ROUND_CRACK_TYPES_LABELS);
    default:
      return [];
  }
};
