import { useRouter } from '@sms/plasma-ui';
import React, { useEffect } from 'react';

import { FILE_TYPES } from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { selectFileType, selectMaxProjectionSliderRanges, selectModals } from '../../store/selectors';
import { selectCRangeForLoadedGeometry } from '../../store/selectors/cRangeForLoadedGeometry.selector';
import { checkAppRoute } from '../../utils';
import { Loader } from '../Loader';
import { AutomaticAnalysisStartedModal } from './AutomaticAnalysisStartedModal';
import { ChangeCutParametersModal } from './ChangeCutParametersModal';
import { CRangeChangeWarningModal } from './CRangeChangeWarningModal';
import { DatabaseReportModal } from './DatabaseReportModal';
import { DisableScreenModal } from './DisableScreenModal';
import ExportResultsModal from './ExportResultsModal';
import { FileLoaderModal } from './FileLoaderModal';
import { GeometryAnalysisModal } from './GeometryAnalysisModal';
import { MessageModal } from './MessageModal';
import { RecipeSetFlawsZonesModal } from './RecipeSetFlawsZonesModal';
import { RecipesTemplatesListModal } from './RecipesTemplatesListModal';
import { SnapshotViewControllerModal } from './SnapshotViewControllerModal';
import { SnapshotViewerModal } from './SnapshotViewerModal';

export const Modals = () => {
  const { setCRangesChangedVisible } = useActionCreators();
  const {
    sliceC: { start, end },
  } = useAppSelector(selectMaxProjectionSliderRanges);
  const fileType = useAppSelector(selectFileType);
  const loadedCRange = useAppSelector(selectCRangeForLoadedGeometry);
  const {
    route: { path },
  } = useRouter('route');

  const { isGeometry } = checkAppRoute(path);
  const {
    geometryAnalysis,
    fileLoader,
    changeCutParameters,
    cRangeChanged,
    recipeSetZones,
    recipesList,
    snapshotsViewController,
    snapshotsViewer,
    automaticAnalysisStarted,
    disableScreen,
    databaseReportModal,
    databaseExportResultsModal,
  } = useAppSelector(selectModals);

  useEffect(() => {
    if (isGeometry && fileType === FILE_TYPES.volume && (start !== loadedCRange.start || end !== loadedCRange.end)) {
      setCRangesChangedVisible(true);
    }
  }, [path, start, end, loadedCRange]);

  return (
    <>
      <GeometryAnalysisModal isVisible={geometryAnalysis} />
      <FileLoaderModal isVisible={fileLoader} />
      <SnapshotViewControllerModal isVisible={snapshotsViewController} />
      <SnapshotViewerModal isVisible={snapshotsViewer} />
      <ChangeCutParametersModal isVisible={changeCutParameters} />
      <CRangeChangeWarningModal isVisible={cRangeChanged} />
      <RecipeSetFlawsZonesModal isVisible={recipeSetZones} />
      <RecipesTemplatesListModal mode={recipesList} />
      <AutomaticAnalysisStartedModal isVisible={automaticAnalysisStarted} />
      <DatabaseReportModal isVisible={databaseReportModal} />
      <ExportResultsModal isVisible={databaseExportResultsModal} />
      <MessageModal />
      <Loader />
      <DisableScreenModal isVisible={disableScreen} />
    </>
  );
};
