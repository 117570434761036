import { toPng } from 'html-to-image';

import { WHITE_PRIMARY } from '../consts';

interface Options {
  width?: number;
  height?: number;
  backgroundColor?: string;
  canvasWidth?: number;
  canvasHeight?: number;
  style?: Partial<CSSStyleDeclaration>;
  filter?: (domNode: HTMLElement) => boolean;
  quality?: number;
  cacheBust?: boolean;
  includeQueryParams?: boolean;
  imagePlaceholder?: string;
  pixelRatio?: number;
  skipFonts?: boolean;
  preferredFontFormat?: 'woff' | 'woff2' | 'truetype' | 'opentype' | 'embedded-opentype' | 'svg' | string;
  fontEmbedCSS?: string;
  skipAutoScale?: boolean;
  type?: string;
}

export const convertFileToBase64 = (file: File | Blob, cb: (base: string) => void) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = () => {
    cb(reader.result as string);
  };

  reader.onerror = (error) => {
    console.log(error);
  };
};

export const getBase64Snapshot = async (
  selector: string,
  // { backgroundColor = WHITE_PRIMARY, pixelRatio = 1 } = {}
  options: Options = {}
) => {
  const { pixelRatio, backgroundColor } = options;
  const node = document.querySelector(selector) as HTMLElement;

  if (!node) return '';

  let imageBase64String = '';

  try {
    imageBase64String = await toPng(node, {
      skipFonts: true,
      backgroundColor: backgroundColor ?? WHITE_PRIMARY,
      pixelRatio: pixelRatio ?? 1,
      ...options,
    });
  } catch (err) {
    console.log('getBase64Snapshot error', err);
  }

  return imageBase64String;
};

export const getShortBase64String = (base64Full: string) => {
  if (!base64Full) return '';
  return base64Full.slice(base64Full.indexOf(',') + 1);
};
