import { TInfoFields, typedEntries } from '../types';

export const INFO_SECTIONS = {
  manufacturerData: 'manufacturerData',
  materialAnalysis: 'materialAnalysis',
  productionData: 'productionData',
} as const;

export const INFO_FIELDS: TInfoFields = {
  [INFO_SECTIONS.manufacturerData]: {
    producer: 'Producer',
    site: 'Site',
    plant: 'Plant',
    productionDate: 'Production date',
    qualityCode: 'Quality code',
    steelGrade: 'Steel grade',
    remarks: 'Remarks',
  },
  [INFO_SECTIONS.materialAnalysis]: {
    c: 'C[%]',
    si: 'Si[%]',
    mn: 'Mn[%]',
    p: 'P[%]',
    s: 'S[%]',
    n: 'N[%]',
    al: 'Al[%]',
    cu: 'Cu[%]',
    cr: 'Cr[%]',
    ni: 'Ni[%]',
    v: 'V[%]',
    ti: 'Ti[%]',
    nb: 'Nb[%]',
    b: 'B[%]',
    mo: 'Mo[%]',
    as: 'As[%]',
    sn: 'Sn[%]',
    ca: 'Ca[%]',
    h2: 'H2[%]',
  },
  [INFO_SECTIONS.productionData]: {
    heatNumber: 'info.productionData.heatNumber',
    sequence: 'info.productionData.sequence',
    slabId: 'info.productionData.slabId',
    slabSampleId: 'info.productionData.slabSampleId',
    numberOfSamples: 'info.productionData.numberOfSamples',
    castingMachine: 'info.productionData.castingMachine',
    tundishSuperheat: 'info.productionData.tundishSuperheat',
    slabWidth: 'info.productionData.slabWidth',
    slabThickness: 'info.productionData.slabThickness',
    castingSpeed: 'info.productionData.castingSpeed',
    specificSprayCoolingCurve: 'info.productionData.specificSprayCoolingCurve',
    thermalTaper: 'info.productionData.thermalTaper',
    thermalTaperParameters: 'info.productionData.thermalTaperParameters',
    numberOfSRSegments: 'info.productionData.numberOfSRSegments',
    softReductionRate: 'info.productionData.softReductionRate',
    softReductionParameters: 'info.productionData.softReductionParameters',
    moldOscillationFrequency: 'info.productionData.moldOscillationFrequency',
    negativeStrip: 'info.productionData.negativeStrip',
  },
};

export const typedInfoInputsEntries = typedEntries(INFO_FIELDS);
