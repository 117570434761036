import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { SLICE_TYPES } from '../../../../consts/geometry';
import { useAppSelector } from '../../../../hooks';
import { selectSliceType, selectVolumeId } from '../../../../store/selectors';
import { selectGeometryDiagonals, selectGeometryRoundDiagonals } from '../../../../store/selectors/geometry.selector';

export const DiagonalsRow = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const { firstDiameter, secondDiameter } = useAppSelector((state) =>
    sliceType === SLICE_TYPES.round
      ? selectGeometryRoundDiagonals(state, volumeId)
      : selectGeometryDiagonals(state, volumeId)
  );

  const diagonalsInfo = [
    {
      title: 'geometry.d1',
      value: firstDiameter.toFixed(1),
    },
    {
      title: 'geometry.d2',
      value: secondDiameter.toFixed(1),
    },
    {
      title: 'geometry.ratio',
      value: (firstDiameter / secondDiameter).toFixed(3),
    },
  ];

  return (
    <li className="geometry__statistic__item diagonals">
      <p className="geometry__statistic__item__title">
        <TranslatedText textKey="geometry.diagonals" />:
      </p>
      <ul className="geometry__statistic__inner__list">
        {diagonalsInfo.map(({ title, value }, idx) => (
          <li key={idx} className="geometry__statistic__inner__item">
            <p className="geometry__statistic__inner__title">
              <TranslatedText textKey={title} />:
            </p>
            <p className="geometry__statistic__item__content">{value}</p>
          </li>
        ))}
      </ul>
    </li>
  );
};
