import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

interface IProps {
  menuList: { name: string; onClick: () => void }[];
  x: number;
  y: number;
}

export const ContextMenu = ({ menuList, x, y }: IProps) => {
  return (
    <ul
      className="context"
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      {menuList.map(({ name, onClick }) => (
        <li className="context__item" key={name} onClick={onClick}>
          <TranslatedText textKey={name} />
        </li>
      ))}
    </ul>
  );
};
