import React, { FC, Fragment } from 'react';

import { BASE_RECT_SIDE_SIZE, BASE_STROKE_THICK, BLACK_PRIMARY, GREEN_PRIMARY, ORANGE_PRIMARY, RED_PRIMARY  } from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { selectBorderData } from '../../../../store/selectors';
import { selectGeometryDimensions } from '../../../../store/selectors/geometry.selector';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  getBaseSvgElemStyles,
  getRectOffset,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { Path } from '../../../Path';

interface IProps {
  volumeId: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
}

export const GeometryDimensions: FC<IProps> = ({ volumeId, cartesianModeParams, domains, graphParams }) => {
  const { outerBorder } = useAppSelector((state) => selectBorderData(state, volumeId));
  const { horizontalLengths, verticalLengths, horizontalLines, verticalLines } = useAppSelector((state) =>
    selectGeometryDimensions(state, volumeId)
  );

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);
  const rectOffset = getRectOffset(BASE_RECT_SIDE_SIZE, BASE_STROKE_THICK);

  return (
    <>
      <Path
        cartesianModeParams={cartesianModeParams}
        data={outerBorder}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
        style={getBaseSvgElemStyles(GREEN_PRIMARY, 1.5)}
      />
      {horizontalLines.map(([start, end], idx) => (
        <Fragment key={idx}>
          <rect
            x={getXOffset(start.x) - rectOffset}
            y={getYOffset(start.y) - rectOffset}
            width={BASE_RECT_SIDE_SIZE}
            height={BASE_RECT_SIDE_SIZE}
            fill={RED_PRIMARY}
          />
          <line
            x1={getXOffset(start.x)}
            y1={getYOffset(start.y)}
            x2={getXOffset(end.x)}
            y2={getYOffset(end.y)}
            stroke={BLACK_PRIMARY}
            strokeWidth={BASE_STROKE_THICK}
          />
          <text x={getXOffset(start.x)} y={getYOffset(start.y - 10)}>
            {`L${idx}: ${horizontalLengths[idx].toFixed(1)} mm`}
          </text>
        </Fragment>
      ))}
      {verticalLines.map(([start, end], idx) => (
        <Fragment key={idx}>
          <rect
            x={getXOffset(start.x) - rectOffset}
            y={getYOffset(start.y) - rectOffset}
            width={BASE_RECT_SIDE_SIZE}
            height={BASE_RECT_SIDE_SIZE}
            fill={ORANGE_PRIMARY}
          />
          <line
            x1={getXOffset(start.x)}
            y1={getYOffset(start.y)}
            x2={getXOffset(end.x)}
            y2={getYOffset(end.y)}
            stroke={BLACK_PRIMARY}
            strokeWidth={BASE_STROKE_THICK}
          />
          <text x={getXOffset(start.x) + 10} y={getYOffset(start.y)}>
            {`H${idx}: ${verticalLengths[idx].toFixed(1)} mm`}
          </text>
        </Fragment>
      ))}
    </>
  );
};
