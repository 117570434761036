import { SEP1_NUM, SEP100_NUM, SLICE_TYPE_NUM, TYPE_DISCRIMINATOR } from '../consts';
import {
  IAnalysisStageAnalysis,
  IAnalysisStagesResponse,
  IRecipeTemplateInfoResponse,
} from '../store/api/recipes/types';
import { IAnalysisStages, TTypeDiscriminator } from '../types';

export const formatRecipeData = (data: IRecipeTemplateInfoResponse) => {
  const analysisStagesArray: IAnalysisStagesResponse = JSON.parse(data.analysisStages);
  const sliceType = analysisStagesArray[0].TypeValue.SliceType || SLICE_TYPE_NUM['Slab Middle'];
  const analysisStages = analysisStagesArray.reduce((acc, prev: IAnalysisStageAnalysis) => {
    if (prev) {
      //@ts-ignore
      acc[` ${prev.TypeDiscriminator}`] = prev.TypeValue;
      //@ts-ignore
      delete acc[` ${prev.TypeDiscriminator}`].SliceType;
    }

    return acc;
  }, {} as IAnalysisStages);

  const recipeData = {
    ...data,
    analysisStages,
    sliceType,
    performAnalysis: {
      [TYPE_DISCRIMINATOR.cracksAnalysisStageModel]: !!analysisStages?.[TYPE_DISCRIMINATOR.cracksAnalysisStageModel],
      [TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]: !!analysisStages?.[
        TYPE_DISCRIMINATOR.segregationsAnalysisStageModel
      ],
      [TYPE_DISCRIMINATOR.flawsAnalysisStageModel]: !!analysisStages?.[TYPE_DISCRIMINATOR.flawsAnalysisStageModel],
      [TYPE_DISCRIMINATOR.backwallAnalysisStageModel]: !!analysisStages?.[
        TYPE_DISCRIMINATOR.backwallAnalysisStageModel
      ],
    },
    isSEPIncluded:
      analysisStages?.[TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]?.SelectedAnalysisTypes.includes(SEP1_NUM) ||
      analysisStages?.[TYPE_DISCRIMINATOR.segregationsAnalysisStageModel]?.SelectedAnalysisTypes.includes(SEP100_NUM),
  };

  return recipeData;
};

export const getFilteredAnalysisStagesJSON = (
  analysisStages: IAnalysisStages,
  performAnalysis: Record<TTypeDiscriminator, boolean>,
  sliceType: number
) => {
  const analysisStagesArr = Object.entries(analysisStages).reduce((acc, [typeDiscriminator, data]) => {
    if (performAnalysis[typeDiscriminator as TTypeDiscriminator] && data.SelectedAnalysisTypes.length > 0) {
      acc.push({
        TypeDiscriminator: +typeDiscriminator.trim(),
        TypeValue: { ...data, SliceType: sliceType },
      });
    }

    return acc;
  }, [] as { TypeDiscriminator: number; TypeValue: Record<string, unknown> }[]);

  return JSON.stringify(analysisStagesArr);
};
