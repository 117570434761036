import './styles.scss';

import { TranslatedText, useRouter } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../hooks';
import {
  selectDistanceMaxProjectionSliderRange,
  selectDistanceSingleLayerSliderRange,
  selectIsMaxProjection,
  selectMaxProjectionSliderRanges,
  selectPaletteData,
  selectSingleLayerSliderRanges,
} from '../../../store/selectors';
import { checkAppRoute } from '../../../utils';

export const MainGraphTitle = ({ hasRange }: { hasRange: boolean }) => {
  const {
    route: { path },
  } = useRouter('route');
  const { isBackwall } = checkAppRoute(path);
  const isMaxProjection = useAppSelector(selectIsMaxProjection);
  const {
    colorRange: { start: startPaletteRange, end: endPaletteRange },
  } = useAppSelector(selectPaletteData);
  const { sliceC } = useAppSelector(isMaxProjection ? selectMaxProjectionSliderRanges : selectSingleLayerSliderRanges);
  const { start, end } = useAppSelector(
    isMaxProjection ? selectDistanceMaxProjectionSliderRange : selectDistanceSingleLayerSliderRange,
  );

  return (
    <h3 className="segregation__graph__title">
      {hasRange ? (
        <b>
          C: {isBackwall ? start.toFixed(2) : sliceC.start.toFixed(2)} mm{' '}
          {isMaxProjection ? `- ${isBackwall ? end.toFixed(2) : sliceC.end.toFixed(2)} mm` : ''} (
          <TranslatedText textKey="adapt-image.scale" />: {startPaletteRange} - {endPaletteRange})
        </b>
      ) : (
        <b>
          <TranslatedText textKey="adapt-image.scale" />: {startPaletteRange} - {endPaletteRange}
        </b>
      )}
    </h3>
  );
};
