import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ModalMessageState, ModalSpinnnerState, TRecipesTemplatesModalMode } from '../../types';

interface IInitialState {
  geometryAnalysis: boolean;
  fileLoader: boolean;
  changeCutParameters: boolean;
  cRangeChanged: boolean;
  spinner: ModalSpinnnerState;
  warningMessage: boolean;
  rotate: boolean;
  message: ModalMessageState;
  snapshotsViewController: boolean;
  snapshotsViewer: boolean;
  recipeSetZones: boolean;
  recipesList: TRecipesTemplatesModalMode;
  automaticAnalysisStarted: boolean;
  disableScreen: boolean;
  databaseReportModal: boolean;
  databaseExportResultsModal: boolean;
}

const initialState: IInitialState = {
  geometryAnalysis: false,
  fileLoader: false,
  changeCutParameters: false,
  cRangeChanged: false,
  spinner: {
    isLoading: false,
    hasCancellation: false,
    isCancelling: false,
    cancelCb: null,
    total: 5,
    fullfilled: 0,
  },
  warningMessage: false,
  rotate: false,
  message: false,
  snapshotsViewController: false,
  snapshotsViewer: false,
  recipeSetZones: false,
  recipesList: null,
  automaticAnalysisStarted: false,
  disableScreen: false,
  databaseReportModal: false,
  databaseExportResultsModal: false,
};

export const modalsVisibleSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setGeometryAnalysisVisible: (state, action: PayloadAction<boolean>) => {
      state.geometryAnalysis = action.payload;
    },
    setFileLoaderVisible: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.fileLoader = !state.fileLoader;
      } else {
        state.fileLoader = action.payload;
      }
    },
    setChangeCutParametersVisible: (state, action: PayloadAction<boolean>) => {
      state.changeCutParameters = action.payload;
    },
    setCRangesChangedVisible: (state, action: PayloadAction<boolean>) => {
      state.cRangeChanged = action.payload;
    },
    setSnapshotsViewControllerVisible: (state, action: PayloadAction<boolean>) => {
      state.snapshotsViewController = action.payload;
    },
    setSnapshotsViewerVisible: (state, action: PayloadAction<boolean>) => {
      state.snapshotsViewer = action.payload;
    },
    setSpinnerVisible: (state, action: PayloadAction<boolean | Partial<ModalSpinnnerState>>) => {
      if (typeof action.payload === 'boolean') {
        if (action.payload) {
          state.spinner.isLoading = true;
          state.disableScreen = false;
        } else {
          state.spinner = initialState.spinner;
        }
        return;
      }

      Object.assign(state.spinner, action.payload);
    },
    setProcessCancellation: (state, action: PayloadAction<boolean>) => {
      state.spinner.isCancelling = action.payload;
    },
    increaseTotal: (state) => {
      state.spinner.total += 1;
    },
    increaseFullfilled: (state) => {
      state.spinner.fullfilled += 1;
    },
    setMessageModal: (state, action: PayloadAction<ModalMessageState>) => {
      state.message = action.payload;
    },
    setRecipeSetZonesVisible: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.recipeSetZones = !state.recipeSetZones;
      } else {
        state.recipeSetZones = action.payload;
      }
    },
    setRecipesListVisible: (state, action: PayloadAction<TRecipesTemplatesModalMode>) => {
      state.recipesList = action.payload;
    },
    setAutomaticAnalysisStartedVisible: (state, action: PayloadAction<boolean>) => {
      state.automaticAnalysisStarted = action.payload;
    },
    setDisableScreenVisible: (state, action: PayloadAction<boolean>) => {
      if (state.spinner.isLoading) {
        return;
      }

      state.disableScreen = action.payload;
    },
    setDbReportVisible: (state, action: PayloadAction<boolean>) => {
      state.databaseReportModal = action.payload;
    },
    setDbExportResultsVisible: (state, action: PayloadAction<boolean>) => {
      state.databaseExportResultsModal = action.payload;
    },
  },
});

export const { actions: modalsActions, reducer: modals } = modalsVisibleSlice;
