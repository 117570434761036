import React, { FC, memo } from 'react';

import { IBorderData } from '../../../../../store/api/slice/types';
import { TTupleOfTwoNumbers } from '../../../../../types';
import { ICartesianModeParams, IGraphParams } from '../../../../../utils';
import { Path } from '../../../../Path';

interface IBorderProps {
  borderData: IBorderData,
  image: string,
  domains: TTupleOfTwoNumbers,
  graphParams: IGraphParams,
  cartesianModeParams: ICartesianModeParams,
  isCartesianMode: boolean,
}

export const Border: FC<IBorderProps> = memo(function Border({
  borderData,
  image,
  domains,
  graphParams,
  cartesianModeParams,
  isCartesianMode,
}) {
  const x = isCartesianMode ? cartesianModeParams.x : 0;
  const y = isCartesianMode ? cartesianModeParams.y : 0;
  const width = isCartesianMode ? cartesianModeParams.realSampleWidth : '100%';
  const height = isCartesianMode
    ? cartesianModeParams.realSampleHeight
    : '100%';

  return (
    <>
      <defs>
        <clipPath id="borders">
          <Path
            isCartesianMode={isCartesianMode}
            cartesianModeParams={cartesianModeParams}
            data={borderData.innerBorder}
            domains={domains}
            graphParams={graphParams}
          />
        </clipPath>
      </defs>
      <rect
        className={'borders'}
        x={x}
        y={y}
        width={width}
        height={height}
        style={{ fill: 'rgba(255, 255, 255, 0.8)' }}
      />
      <image
        className={'borders'}
        x={x}
        y={y}
        href={`data:image/gif;base64,${image}`}
        width={width}
        height={height}
        preserveAspectRatio="none"
        style={{ clipPath: 'url(#borders)' }}
      />
      <Path
        classNames={'borders'}
        isCartesianMode={isCartesianMode}
        cartesianModeParams={cartesianModeParams}
        data={borderData.outerBorder}
        domains={domains}
        graphParams={graphParams}
      />
    </>
  );
});
