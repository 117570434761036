import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { IRectCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import { getBaseSvgElemStyles, getXOffsetFunc, getYOffsetFunc, ICartesianModeParams } from '../../../../../../utils';

interface IProps {
  data: IRectCoordinates;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx?: number;
  billetCutDy?: number;
}

export const Rect: FC<IProps> = ({ data, cartesianModeParams, domains, billetCutDx = 0, billetCutDy = 0 }) => {
  const { x0, y0, x1, y1 } = data;

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  const x = getXOffset(x0 + billetCutDx);
  const y = getYOffset(y1 + billetCutDy);
  const width = getXOffset(x1 + billetCutDx) - x;
  const height = getYOffset(y0 + billetCutDy) - y;

  return <rect x={x} y={y} width={width} height={height} style={getBaseSvgElemStyles(ORANGE_PRIMARY, 1)} />;
};
