import {
  AUTO_ANALYSIS_GEOMETRY_RECT_TABS,
  FILE_TYPES,
  GEOMETRY_CUT_TABS,
  GEOMETRY_RECT_TABS,
  GEOMETRY_ROUND_TABS,
  SLICE_TYPES,
} from '../consts';
import { TFileType } from '../store/api/scandata/types';
import { TCoordinates, TTupleOfTwoNumbers } from '../types';
import { ICartesianModeParams } from './getCartesianModeParams';
import { IGraphParams } from './getGraphParams';
import { getToFixedValue } from './getToFixedValue';

export const getRectOffset = (sideSize: number, strokeThick: number): number => (sideSize - strokeThick) / 2;

export const getXOffsetFunc = (params: ICartesianModeParams, domains: TTupleOfTwoNumbers) => {
  return (x: number): number => params.x + (x / domains[0]) * params.realSampleWidth;
};

export const getYOffsetFunc = (params: ICartesianModeParams, domains: TTupleOfTwoNumbers) => {
  return (y: number): number => params.y + params.realSampleHeight * (1 - y / domains[1]);
};

export const getPureXOffsetFunc = (params: IGraphParams, domains: TTupleOfTwoNumbers) => {
  return (x: number): number => (x / domains[0]) * params.innerSvgWidth;
};

export const getPureYOffsetFunc = (params: IGraphParams, domains: TTupleOfTwoNumbers) => {
  return (y: number): number => params.innerSvgHeight * (1 - y / domains[1]);
};

export const convertPureCoordsInPxToMm = (
  coords: TCoordinates,
  params: IGraphParams,
  domains: TTupleOfTwoNumbers,
): TCoordinates => {
  return {
    x: getToFixedValue((coords.x * domains[0]) / params.innerSvgWidth),
    y: getToFixedValue(domains[1] * (1 - coords.y / params.innerSvgHeight)),
  };
};

export const convertCartesianModeCoordsInPxToMm = (
  coords: TCoordinates,
  params: ICartesianModeParams,
  domains: TTupleOfTwoNumbers,
): TCoordinates => {
  return {
    x: getToFixedValue((coords.x * domains[0]) / params.realSampleWidth),
    y: getToFixedValue(domains[1] * (1 - coords.y / params.realSampleHeight)),
  };
};

export const getGeometryTabs = (sliceType: string, isAutoAnalysis: boolean) => {
  if (sliceType === SLICE_TYPES.round) return Object.values(GEOMETRY_ROUND_TABS);

  if (sliceType === SLICE_TYPES.cut) return Object.values(GEOMETRY_CUT_TABS);

  return isAutoAnalysis ? Object.values(AUTO_ANALYSIS_GEOMETRY_RECT_TABS) : Object.values(GEOMETRY_RECT_TABS);
};

export const getGeometryAnalysisData = (fileType: TFileType) => {
  return Object.values(SLICE_TYPES).map((side) => ({
    value: side,
    label: side,
    disabled: fileType === FILE_TYPES.image && (side === SLICE_TYPES.cut || side === SLICE_TYPES.round),
  }));
};
