import { IZonesNewZoneParams } from '../store/api/zones/types';
import { TCoordinates, TTupleOfTwoNumbers } from '../types';
import { convertCartesianModeCoordsInPxToMm } from './geometry';
import { ICartesianModeParams } from './getCartesianModeParams';

const getIntersectionCoords = (c1: TCoordinates, c2: TCoordinates, c3: TCoordinates, c4: TCoordinates) => {
  const divider = (c1.x - c2.x) * (c3.y - c4.y) - (c1.y - c2.y) * (c3.x - c4.x);
  const firstMultiplier = c1.x * c2.y - c1.y * c2.x;
  const secondMultiplier = c3.x * c4.y - c3.y * c4.x;

  const x = (firstMultiplier * (c3.x - c4.x) - (c1.x - c2.x) * secondMultiplier) / divider;
  const y = (firstMultiplier * (c3.y - c4.y) - (c1.y - c2.y) * secondMultiplier) / divider;

  return {
    x,
    y,
  };
};

export const getRecalculatedCoordsInPx = (event: MouseEvent, coords: IZonesNewZoneParams, pointPosition: string): IZonesNewZoneParams => {
  const { leftBottom, leftTop, rightTop, rightBottom } = coords;
  const vertXVector = coords.leftTop.x - coords.leftBottom.x;
  const vertYVector = coords.leftTop.y - coords.leftBottom.y;
  const horizontalXVector = -vertYVector;
  const horizontalYVector = vertXVector;

  const xVert = vertXVector * 2 + event.x;
  const yVert = vertYVector * 2 + event.y;
  const xHorizontal = horizontalXVector * 2 + event.x;
  const yHorizontal = horizontalYVector * 2 + event.y;

  const newLeftBottom = getIntersectionCoords(
    { x: event.x, y: event.y },
    { x: pointPosition === 'leftTop' ? xVert : xHorizontal, y: pointPosition === 'leftTop' ? yVert : yHorizontal },
    { x: leftBottom.x, y: leftBottom.y },
    { x: pointPosition === 'leftTop' ? rightBottom.x : leftTop.x, y: pointPosition === 'leftTop' ? rightBottom.y : leftTop.y },
  );

  const newRightTop = getIntersectionCoords(
    { x: event.x, y: event.y },
    { x: pointPosition === 'leftTop' ? xHorizontal : xVert, y: pointPosition === 'leftTop' ? yHorizontal : yVert },
    { x: pointPosition === 'leftTop' ? rightBottom.x : leftTop.x, y: pointPosition === 'leftTop' ? rightBottom.y : leftTop.y },
    { x: rightTop.x, y: rightTop.y },
  );

  return {
    leftBottom: { x: newLeftBottom.x, y: newLeftBottom.y },
    leftTop: { x: pointPosition === 'leftTop' ? event.x : coords.leftTop.x, y: pointPosition === 'leftTop' ? event.y : coords.leftTop.y },
    rightTop: { x: newRightTop.x, y: newRightTop.y },
    rightBottom: { x: pointPosition === 'leftTop' ? coords.rightBottom.x : event.x, y: pointPosition === 'leftTop' ? coords.rightBottom.y : event.y },
  };
};

export const convertRecalculatedCoordsToMm = (coords: IZonesNewZoneParams, params: ICartesianModeParams, domains: TTupleOfTwoNumbers): IZonesNewZoneParams => {
  return {
    leftTop: convertCartesianModeCoordsInPxToMm(coords.leftTop, params, domains),
    rightTop: convertCartesianModeCoordsInPxToMm(coords.rightTop, params, domains),
    rightBottom: convertCartesianModeCoordsInPxToMm(coords.rightBottom, params, domains),
    leftBottom: convertCartesianModeCoordsInPxToMm(coords.leftBottom, params, domains),
  };
};
