import { typedEntries } from '../types';

export const SETTINGS_SECTIONS = {
  generalSettings: 'generalSettings',
  segregationsSettings: 'segregationsSettings',
  flawsSettings: 'flawsSettings',
  cracksSettings: 'cracksSettings',
  backwallSettings: 'backwallSettings',
  gostSettings: 'gostSettings',
  astmSettings: 'astmSettings',
} as const;

export const SETTINGS_SECTIONS_TITLE: Record<keyof typeof SETTINGS_SECTIONS, string> = {
  [SETTINGS_SECTIONS.generalSettings]: 'common.general',
  [SETTINGS_SECTIONS.segregationsSettings]: 'pages.segregation',
  [SETTINGS_SECTIONS.flawsSettings]: 'pages.flaws',
  [SETTINGS_SECTIONS.cracksSettings]: 'pages.cracks',
  [SETTINGS_SECTIONS.backwallSettings]: 'pages.backwall',
  [SETTINGS_SECTIONS.gostSettings]: 'settings.gost-settings',
  [SETTINGS_SECTIONS.astmSettings]: 'settings.astm-settings',
};

export const GENERAL_SETTINGS_FIELDS = [
  'paletteRangeStart',
  'paletteRangeEnd',
  'rangeStart',
  'rangeEnd',
  'leftRightMargin',
  'thresholdCoefficient',
  'heightsAnalysisOffset',
  'medianBorderPixelSize',
  'haFactor',
  'monochrome',
  'inverse',
  'cut',
];

export const SEGREGATIONS_SETTINGS_FIELDS = [
  'rangeStart',
  'rangeEnd',
  'zoneFraction',
  'mmZoneHeight',
  'sepZoneWidth',
  'minPoreArea',
  'poresThreshold',
  'weightByArea',
];

export const FLAWS_SETTINGS_FIELDS = [
  'rangeStart',
  'rangeEnd',
  'smsZoneOffsetFromBorderCoefficient',
  'smsZoneHeightCoefficient',
];

export const CRACKS_SETTINGS_FIELDS = [
  'rangeStart',
  'rangeEnd',
  'minCrackLength',
  'crackMaxWidthCoefficient',
  'crackMaxHeightCoefficient',
  'crackMaxAreaCoefficient',
  'minConfidenceCoefficient',
  'centerZoneHeight',
  'tripplePointZoneWidth',
  'billetInnerZonePercent',
  'billetMiddleZonePercent',
];

export const BACKWALL_SETTINGS_FIELDS = ['rangeStart', 'rangeEnd', 'rangeDifference', 'autoRangeStart', 'autoRangeEnd'];

export const GOST_SETTINGS_FIELDS = [
  'innerZonePercents',
  'flawBilletZonePercentX',
  'flawBilletZonePercentY',
  'flawsZonePercent',
  'widthLengthRatio',
];

export const ASTM_SETTINGS_FIELDS = ['c', 'r', 's', 'cDefectPercent', 'rDefectPercent', 'sDefectPercent'];

export const typedGeneralSettingsEntries = typedEntries({
  [SETTINGS_SECTIONS.generalSettings]: GENERAL_SETTINGS_FIELDS,
  [SETTINGS_SECTIONS.segregationsSettings]: SEGREGATIONS_SETTINGS_FIELDS,
  [SETTINGS_SECTIONS.flawsSettings]: FLAWS_SETTINGS_FIELDS,
  [SETTINGS_SECTIONS.cracksSettings]: CRACKS_SETTINGS_FIELDS,
  [SETTINGS_SECTIONS.backwallSettings]: BACKWALL_SETTINGS_FIELDS,
});

export const typedStandartsSettingsEntries = typedEntries({
  [SETTINGS_SECTIONS.gostSettings]: GOST_SETTINGS_FIELDS,
  [SETTINGS_SECTIONS.astmSettings]: ASTM_SETTINGS_FIELDS,
});
