import React from 'react';

import { CoordinateSystem } from './CoordinateSystem';
import { UseIn } from './UseIn';
import { WrappedRemarks } from './WrappedRemarks';
import { ZonesZones } from './Zones';
import { ZonesControl } from './ZonesControl';

export const ZonesSideMenu = () => {
  return (
    <div className='zones__menu'>
      <div>
        <ZonesControl />
        <ZonesZones />
        <CoordinateSystem />
        <UseIn />
      </div>
      <WrappedRemarks />
    </div>
  );
};

