import './styles.scss';

// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import {
  BACKWALL_TABS,
  CENTER_POINT_RADIUS,
  GEOMETRY_MARGIN,
  IDs,
  SCAN_VIEWS,
  SLICE_TYPES,
  TICK_SIZE,
  X_TICK_AMOUNT,
  Y_TICK_AMOUNT,
} from '../../../consts';
import { useAppSelector, useWindowSize } from '../../../hooks';
import { useSetDistanceDataMutation } from '../../../store/api/backwall';
import { ISampleDataParameters } from '../../../store/api/scandata/types';
import { useSetProjectionsDataMutation } from '../../../store/api/slice';
import {
  selectAutoAnalysisSliceCImage,
  selectAutomaticAnalysisStatus,
  selectBackwallAutoAnalysisImage,
  selectBackwallTab,
  selectBilletCutParams,
  selectBorderData,
  selectFileType,
  selectForceAnalysisChange,
  selectIsBorder,
  selectPrevSliceImages,
  selectSliceType,
  selectVolumeId,
} from '../../../store/selectors';
import { TTupleOfTwoNumbers } from '../../../types';
import {
  get2dSliceSizes,
  getCartesianModeParams,
  getGraphParams,
  getInnerShapeCartesianModeParams,
} from '../../../utils';
import { Border } from '../../ProjectionList/ProjectionItem/SliceGraph/Border';
import { DistanceLines } from '../../ProjectionList/ProjectionItem/SliceGraph/DistanceLines';
import { BackwallFlawsSvgElements } from './BackwallFlawsSvgElements';
import { BackwallSvgElements } from './BackwallSvgElements';
import { CracksSvgElements } from './CracksSvgElements';
import { FlawsSvgElements } from './FlawsSvgElements';
import { SegSvgElements } from './SegSvgElements';
import { ZonesSvgElements } from './ZonesSvgElements';

interface IProps {
  scanDataParameters: ISampleDataParameters;
  id: string;
}

export const MainGraph: FC<IProps> = ({ scanDataParameters, id }) => {
  const [, { sliceC }] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceC',
    selectFromResult: ({ data }) => ({
      sliceC: data?.sliceC,
    }),
  });
  const [, { backwallDistanceImage }] = useSetDistanceDataMutation({
    fixedCacheKey: 'backwallDistance',
    selectFromResult: ({ data }) => ({
      backwallDistanceImage: data?.sliceBackwall?.imageBase64,
    }),
  });

  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const fileType = useAppSelector(selectFileType);
  const billetCutParams = useAppSelector(selectBilletCutParams);
  const hasBorder = useAppSelector(selectIsBorder);
  const isAnalysisChangingForced = useAppSelector(selectForceAnalysisChange);
  const borderData = useAppSelector((state) => selectBorderData(state, volumeId));
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const { C: prevSliceImage, backwall: backwallPrevSliceImage } = useAppSelector(selectPrevSliceImages);
  const sliceCFromAnalysis = useAppSelector(selectAutoAnalysisSliceCImage);
  const backwallImageFromAnalysis = useAppSelector(selectBackwallAutoAnalysisImage);
  const resize = useWindowSize();
  const outerSvgRef = useRef<SVGSVGElement>(null);
  const domains = useMemo(() => get2dSliceSizes(scanDataParameters, 'C', fileType), [scanDataParameters, fileType]);
  const graphParams = useMemo(() => getGraphParams(outerSvgRef.current, GEOMETRY_MARGIN), [resize]);
  const sliceCImage = isAutoAnalysis ? sliceCFromAnalysis : sliceC?.imageBase64;
  const backwallImage = isAutoAnalysis ? backwallImageFromAnalysis : backwallDistanceImage;
  const backwallTab = useAppSelector(selectBackwallTab);

  const billetCutDomains: TTupleOfTwoNumbers = useMemo(() => [billetCutParams.sizeX, billetCutParams.sizeY], [
    billetCutParams.sizeX,
    billetCutParams.sizeY,
  ]);

  const cartesianModeParams = useMemo(
    () =>
      getCartesianModeParams(
        sliceType === SLICE_TYPES.cut ? billetCutDomains : domains,
        graphParams.innerSvgWidth,
        graphParams.innerSvgHeight,
        billetCutParams.dx,
        billetCutParams.dy,
      ),
    [
      sliceType,
      billetCutDomains,
      domains,
      graphParams.innerSvgWidth,
      graphParams.innerSvgHeight,
      billetCutParams.dx,
      billetCutParams.dy,
    ],
  );

  const innerShapeCartesianModeParams = useMemo(
    () =>
      getInnerShapeCartesianModeParams(
        domains,
        billetCutDomains,
        cartesianModeParams,
        billetCutParams.dx,
        billetCutParams.dy,
      ),
    [domains, billetCutDomains, cartesianModeParams, billetCutParams.dx, billetCutParams.dy],
  );

  useEffect(() => {
    const innerSvg = d3.select(`#${id} svg`);
    const wrapper = d3.select(`#${id} svg #${IDs.groupWrapper}`);
    const centerPoint = d3.select(`#${id} svg #${IDs.centerPoint}`);
    const zoomTransform = d3.zoomTransform(innerSvg.node());

    const xDomain = cartesianModeParams.xDomain;
    const yDomain = cartesianModeParams.yDomain.slice();

    const x = d3.scaleLinear().domain(xDomain).range(graphParams.xRange);
    const y = d3.scaleLinear().domain(yDomain.reverse()).range(graphParams.yRange);

    const xAxis = d3.axisBottom(x).ticks(X_TICK_AMOUNT).tickSize(TICK_SIZE);
    const yAxis = d3.axisLeft(y).ticks(Y_TICK_AMOUNT).tickSize(TICK_SIZE);

    const gX = d3.select(`#${id} .axis--x`).call(xAxis.scale(zoomTransform.rescaleX(x)));

    const gY = d3.select(`#${id} .axis--y`).call(yAxis.scale(zoomTransform.rescaleY(y)));

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 40])
      .translateExtent([
        [-100, -100],
        [graphParams.width + 100, graphParams.height + 100],
      ])
      .on('zoom', (event: any) => {
        // d3 library doesn't have ZoomEvent type
        const zoomState = event.transform;

        gX.call(xAxis.scale(zoomState.rescaleX(x)));
        gY.call(yAxis.scale(zoomState.rescaleY(y)));

        wrapper.attr('transform', zoomState);

        // disable circle diameter scaling
        centerPoint.attr('r', CENTER_POINT_RADIUS / zoomState.k);
      });

    innerSvg.call(zoom);

    if (isAnalysisChangingForced) {
      innerSvg.call(zoom.transform, d3.zoomIdentity);
    }
  }, [graphParams, cartesianModeParams, domains, isAnalysisChangingForced]);

  return (
    <div className="common__graph">
      <div className="common__graph__main-axis">
        <div className="legend--y">Y [mm]</div>
        <svg id={id} ref={outerSvgRef} width={graphParams.width} height={graphParams.height}>
          <svg
            x={GEOMETRY_MARGIN.left}
            y={GEOMETRY_MARGIN.top}
            width={graphParams.innerSvgWidth}
            height={graphParams.innerSvgHeight}
          >
            <g id={IDs.groupWrapper}>
              <rect width={graphParams.innerSvgWidth} height={graphParams.innerSvgHeight} fill="transparent" />
              <image
                x={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.x : cartesianModeParams.x}
                y={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.y : cartesianModeParams.y}
                href={`data:image/gif;base64,${id === IDs.backwallGraph ? backwallPrevSliceImage : prevSliceImage}`}
                width={
                  sliceType === SLICE_TYPES.cut
                    ? innerShapeCartesianModeParams.width
                    : cartesianModeParams.realSampleWidth
                }
                height={
                  sliceType === SLICE_TYPES.cut
                    ? innerShapeCartesianModeParams.height
                    : cartesianModeParams.realSampleHeight
                }
                preserveAspectRatio="none"
              />
              {id === IDs.backwallGraph && backwallImage && (
                <image
                  x={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.x : cartesianModeParams.x}
                  y={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.y : cartesianModeParams.y}
                  href={`data:image/gif;base64,${backwallImage}`}
                  width={
                    sliceType === SLICE_TYPES.cut
                      ? innerShapeCartesianModeParams.width
                      : cartesianModeParams.realSampleWidth
                  }
                  height={
                    sliceType === SLICE_TYPES.cut
                      ? innerShapeCartesianModeParams.height
                      : cartesianModeParams.realSampleHeight
                  }
                  preserveAspectRatio="none"
                />
              )}
              {id !== IDs.backwallGraph && sliceCImage && (
                <image
                  x={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.x : cartesianModeParams.x}
                  y={sliceType === SLICE_TYPES.cut ? innerShapeCartesianModeParams.y : cartesianModeParams.y}
                  href={`data:image/gif;base64,${sliceCImage}`}
                  width={
                    sliceType === SLICE_TYPES.cut
                      ? innerShapeCartesianModeParams.width
                      : cartesianModeParams.realSampleWidth
                  }
                  height={
                    sliceType === SLICE_TYPES.cut
                      ? innerShapeCartesianModeParams.height
                      : cartesianModeParams.realSampleHeight
                  }
                  preserveAspectRatio="none"
                />
              )}
              {hasBorder && borderData && sliceCImage && (
                <Border
                  borderData={borderData}
                  image={sliceCImage}
                  domains={domains}
                  graphParams={graphParams}
                  cartesianModeParams={cartesianModeParams}
                  isCartesianMode={true}
                />
              )}
              {id === IDs.segGraph && (
                <SegSvgElements
                  billetCutDomains={billetCutDomains}
                  billetCutParams={billetCutParams}
                  domains={domains}
                  graphParams={graphParams}
                  cartesianModeParams={cartesianModeParams}
                  innerShapeCartesianModeParams={innerShapeCartesianModeParams}
                />
              )}
              {id === IDs.flawsGraph && (
                <FlawsSvgElements
                  billetCutDomains={billetCutDomains}
                  billetCutParams={billetCutParams}
                  domains={domains}
                  graphParams={graphParams}
                  cartesianModeParams={cartesianModeParams}
                  innerShapeCartesianModeParams={innerShapeCartesianModeParams}
                />
              )}
              {id === IDs.cracksGraph && (
                <CracksSvgElements
                  imageForAnalysis={sliceCImage}
                  domains={sliceType === SLICE_TYPES.cut ? billetCutDomains : domains}
                  billetCutParams={billetCutParams}
                  graphParams={graphParams}
                  cartesianModeParams={cartesianModeParams}
                  imageWidth={scanDataParameters.dimensionX}
                  imageHeight={scanDataParameters.dimensionZ}
                />
              )}
              {id === IDs.backwallGraph && backwallTab === BACKWALL_TABS.distance && (
                <BackwallSvgElements domains={domains} cartesianModeParams={cartesianModeParams} />
              )}
              {id === IDs.backwallGraph && backwallTab === BACKWALL_TABS.flaws && (
                <BackwallFlawsSvgElements
                  billetCutDomains={billetCutDomains}
                  billetCutParams={billetCutParams}
                  domains={domains}
                  cartesianModeParams={cartesianModeParams}
                  imageWidth={scanDataParameters.dimensionX}
                  imageHeight={scanDataParameters.dimensionZ}
                  graphParams={graphParams}
                />
              )}
              {id === IDs.zonesGraph && (
                <ZonesSvgElements domains={domains} cartesianModeParams={cartesianModeParams} />
              )}
              <DistanceLines
                scanView={SCAN_VIEWS.C}
                domains={domains}
                graphParams={graphParams}
                cartesianModeParams={cartesianModeParams}
                isCartesianMode={true}
                graphId={id}
              />
            </g>
          </svg>
          <g
            className="axis axis--x"
            transform={`translate(${GEOMETRY_MARGIN.left}, ${graphParams.height - GEOMETRY_MARGIN.bottom})`}
          />
          <g className="axis axis--y" transform={`translate(${GEOMETRY_MARGIN.left}, ${GEOMETRY_MARGIN.top})`} />
        </svg>
      </div>
      <div className="legend--x">X [mm]</div>
    </div>
  );
};
