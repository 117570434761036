import './styles.scss';

import classNames from 'classnames';
// @ts-ignore
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useActionCreators, useAppSelector } from '../../../../../hooks';
import { selectDistances, selectIsDistanceMode } from '../../../../../store/selectors';
import { TScanViews, TTupleOfTwoNumbers } from '../../../../../types';
import {
  checkLineAngle,
  getLineAngle,
  getLineLengthByCoords,
  getToFixedValue,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../../utils';
import { getDistanceCoordinates, getRatio } from '../../../../../utils/getDistanceCoordinates';

type TExcludedScanViews = Exclude<TScanViews, 'A' | '3D'>;

interface Props {
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
  isCartesianMode: boolean;
  graphId: string;
  scanView: TExcludedScanViews;
}

export const DistanceLines = ({
  graphParams,
  domains,
  cartesianModeParams,
  isCartesianMode,
  graphId,
  scanView,
}: Props) => {
  const startCoordinates = useRef({ x1: 0, y1: 0 });
  const isDistanceMode = useAppSelector(selectIsDistanceMode);
  const { setDistances, setActiveDistance, removeDistance } = useActionCreators();
  const distances = useAppSelector(selectDistances);

  const ratio = getRatio(
    isCartesianMode ? cartesianModeParams.realSampleWidth : graphParams.innerSvgWidth,
    isCartesianMode ? cartesianModeParams.realSampleHeight : graphParams.innerSvgHeight,
    domains,
  );

  const offsetX = isCartesianMode ? cartesianModeParams.x : 0;
  const offsetY = isCartesianMode ? cartesianModeParams.y : 0;

  useEffect(() => {
    if (!isDistanceMode) return;

    const xDomain = isCartesianMode ? cartesianModeParams.xDomain : [0, domains[0]];
    const yDomain = isCartesianMode ? cartesianModeParams.yDomain.slice() : [0, domains[1]];

    const x = d3.scaleLinear().domain(graphParams.xRange).range(xDomain);
    const y = d3.scaleLinear().domain(graphParams.yRange).range(yDomain);
    let g: any;

    function dragstarted(this: SVGElement, event: MouseEvent) {
      g = d3.select(this).append('g').attr('class', 'distance-group');

      const [x1, y1] = d3.pointer(event, this);
      startCoordinates.current.x1 = x1;
      startCoordinates.current.y1 = y1;

      d3.select(this)
        .append('line')
        .attr('id', 'line-y')
        .attr('x1', 0)
        .attr('y1', y1)
        .attr('x2', graphParams.innerSvgWidth)
        .attr('y2', y1);
      d3.select(this)
        .append('line')
        .attr('id', 'line-x')
        .attr('x1', x1)
        .attr('y1', 0)
        .attr('x2', x1)
        .attr('y2', graphParams.innerSvgHeight);

      g.append('line');
      g.append('circle').attr('cx', x1).attr('cy', y1).attr('r', 3);
    }

    function dragged(this: SVGElement, event: MouseEvent) {
      const { x1, y1 } = startCoordinates.current;
      const [x2, y2] = d3.pointer(event, this);

      g.select('line').attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2);
    }

    function dragended(this: SVGElement, event: MouseEvent) {
      const { x1, y1 } = startCoordinates.current;
      const [x2, y2] = d3.pointer(event, this);

      d3.selectAll('#line-x, #line-y').remove();
      g.remove();

      if (x1 === x2 && y1 === y2) return;

      const distance = getToFixedValue(getLineLengthByCoords(x(x1), y(y1), x(x2), y(y2)));
      const coordinates = getDistanceCoordinates('standard', { x1, y1, x2, y2 }, offsetX, offsetY, ratio);

      const angle = checkLineAngle(getLineAngle(x2 - x1, y2 - y1));

      setDistances({
        [scanView]: {
          id: uuidv4(),
          coordinates,
          active: false,
          distance,
          angle,
        },
      });
    }

    const wrapper = d3.select(`#${graphId} svg g .distances`);
    wrapper.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));

    return () => {
      wrapper.call(d3.drag().on('start', null).on('drag', null).on('end', null));
    };
  }, [graphParams, cartesianModeParams, domains, isDistanceMode, isCartesianMode]);

  const handleDistanceClick = (e: React.MouseEvent<SVGGElement, MouseEvent>, active: boolean, id: string) => {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      [scanView]: id,
    };

    if (active) return removeDistance(body);

    setActiveDistance(body);
  };

  return (
    <>
      {isDistanceMode && (
        <g className="distances">
          <rect
            x={cartesianModeParams.x}
            y={cartesianModeParams.y}
            width={cartesianModeParams.realSampleWidth}
            height={cartesianModeParams.realSampleWidth}
            fill="transparent"
          />
          {!!distances[scanView].length &&
            distances[scanView].map(({ coordinates, distance, id, active, angle }) => {
              const { x1, x2, y1, y2 } = getDistanceCoordinates('real', coordinates, offsetX, offsetY, ratio);

              return (
                <g
                  className={classNames('distance-group', active && 'active')}
                  key={id}
                  onMouseDownCapture={(e) => handleDistanceClick(e, active, id)}
                >
                  <line x1={x1} x2={x2} y1={y1} y2={y2} style={{ strokeDasharray: 'none' }} />
                  <circle cx={x1} cy={y1} r={3} />
                  <circle cx={x2} cy={y2} r={3} />
                  <text x={x2 + 4} y={y2}>
                    {distance} {` (${getToFixedValue(angle, 1)}\u00b0)`}
                  </text>
                </g>
              );
            })}
        </g>
      )}
    </>
  );
};
