import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sliderRanges: {
    sliceB: {
      start: 0,
      end: 0,
    },
    sliceC: {
      start: 0,
      end: 0,
    },
    sliceD: {
      start: 0,
      end: 0,
    },
  },
  palette: {
    isInverse: false,
    isMonochrome: false,
    isScale: false,
    colorRange: {
      start: 0,
      end: 0,
    },
  },
};

export const bcdplusPrevStateSlice = createSlice({
  name: 'bcdplusPrevState',
  initialState,
  reducers: {
    setBcdplusPrevState: (state, action: PayloadAction<typeof initialState>) => action.payload,
  },
});

export const { actions: bcdplusPrevStateActions, reducer: bcdplusPrevState } = bcdplusPrevStateSlice;
