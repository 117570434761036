//@ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { IDs, RED_PRIMARY, WHITE_PRIMARY } from '../../../consts';
import { IAdaptImageScaleCoords } from '../../../store/api/adapt-image/types';
import { ISVGCircleParams, TTupleOfTwoNumbers } from '../../../types';
import {
  convertPureCoordsInPxToMm,
  getBaseSvgElemStyles,
  getPureXOffsetFunc,
  getPureYOffsetFunc,
  IGraphParams,
} from '../../../utils';

interface IProps {
  coords: IAdaptImageScaleCoords;
  isVisible: boolean;
  pxDomains: TTupleOfTwoNumbers;
  mmDomains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
  setCoords: (coords: Partial<IAdaptImageScaleCoords>) => void;
}

export const Scale: FC<IProps> = ({ coords, isVisible, graphParams, pxDomains, mmDomains, setCoords }) => {
  const { startPoint, endPoint } = coords;

  const getXOffset = getPureXOffsetFunc(graphParams, pxDomains);
  const getYOffset = getPureYOffsetFunc(graphParams, pxDomains);

  const startX = getXOffset(startPoint.x);
  const startY = getYOffset(startPoint.y);
  const endX = getXOffset(endPoint.x);
  const endY = getYOffset(endPoint.y);

  useEffect(() => {
    if (!isVisible) return;
    if (startPoint.x !== 0 && startPoint.y !== 0 && endPoint.x !== 0 && endPoint.y !== 0) return;

    setCoords({
      startPoint: { x: pxDomains[0] / 2 - (pxDomains[0] / mmDomains[0] * 5), y: pxDomains[1] / 2 },
      endPoint: { x: pxDomains[0] / 2 + (pxDomains[0] / mmDomains[0] * 5), y: pxDomains[1] / 2 },
    });
  }, [isVisible, pxDomains, mmDomains, startPoint.x, startPoint.y, endPoint.x, endPoint.y]);

  useEffect(() => {
    if (!isVisible) return;

    const firstAuxiliaryPoint = d3.select(`#${IDs.scale} .${IDs.firstAuxiliaryPoint}`);
    const secondAuxiliaryPoint = d3.select(`#${IDs.scale} .${IDs.secondAuxiliaryPoint}`);
    const line = d3.select(`#${IDs.scale} line`);
    const points = [firstAuxiliaryPoint, secondAuxiliaryPoint];

    firstAuxiliaryPoint
      .datum({ cx: startX, cy: startY })
      .attr('cursor', 'grab');

    secondAuxiliaryPoint
      .datum({ cx: endX, cy: endY })
      .attr('cursor', 'grab');

    line
      .datum({ x1: startX, y1: startY, x2: endX, y2: endY });

    function dragstarted(this: SVGCircleElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      const { x, y } = event;

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = x))
          .attr('cy', (d.cy = y));

        setCoords({
          startPoint: convertPureCoordsInPxToMm({ x, y }, graphParams, pxDomains),
        });
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = x))
          .attr('cy', (d.cy = y));

        setCoords({
          endPoint: convertPureCoordsInPxToMm({ x, y }, graphParams, pxDomains),
        });
      }
    }

    function dragended(this: SVGCircleElement) {
      d3.select(this).attr('cursor', 'grab');
    }

    points.forEach(line => {
      line.call(
        d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );
    });
  }, [endX, endY, isVisible, startX, startY, graphParams, pxDomains]);

  return (
    <>
      {isVisible && (
        <g id={IDs.scale}>
          <line
            x1={startX}
            y1={startY}
            x2={endX}
            y2={endY}
            style={getBaseSvgElemStyles(WHITE_PRIMARY, 2)}
          />
          <circle
            className={IDs.firstAuxiliaryPoint}
            cx={startX}
            cy={startY}
            r={5}
            fill={RED_PRIMARY}
          />
          <circle
            className={IDs.secondAuxiliaryPoint}
            cx={endX}
            cy={endY}
            r={5}
            fill={RED_PRIMARY}
          />
        </g>
      )}
    </>
  );
};

