import { TranslatedText } from '@sms/plasma-ui';
import React, { FC, ReactNode } from 'react';

import { TreeCheckedKeys } from '../../../../types';

interface Props {
  title: string;
  stringKey: string;
  children: ReactNode;
  checkedKeys: TreeCheckedKeys;
}

export const FilterWrapper: FC<Props> = ({ title, stringKey, children, checkedKeys }) => {
  return (
    <div className="db__additional-filter__wrapper" onClick={(e) => e.stopPropagation()}>
      <div className="db__additional-filter__name">
        <TranslatedText textKey={title} />
      </div>
      {checkedKeys.checked.includes(stringKey) && children}
    </div>
  );
};
