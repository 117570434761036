import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { REPORT_TABS, REPORT_TABS_OTHER_KEYS } from '../../../consts';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import {
  IZonesAddZoneBody,
  IZonesModifyZoneBody,
  IZonesRemoveZoneBody,
  IZonesRotateAxesBody,
  IZonesZones,
  TZonesSetZoneApplymentBody,
} from './types';

const { addZonesZones, setMadeAnalysis } = allActions;

export const zonesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getZonesZones: build.query<IZonesZones, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ(`zones?volumeId=${volumeId}`);

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const zones = (result.data as IZonesZones).zones.map((z) => ({
          newZoneRectangle: z.zone,
          zoneApplyment: z.zonesApplyment,
        }));

        dispatch(addZonesZones(zones));
        dispatch(setMadeAnalysis({ analysis: REPORT_TABS.other, id: REPORT_TABS_OTHER_KEYS.zones }));

        return { data: result.data as IZonesZones };
      },
    }),
    addZonesZone: build.mutation<void, IZonesAddZoneBody>({
      query: (body) => ({
        url: 'zones/add',
        method: 'POST',
        body,
      }),
    }),
    removeZonesZone: build.mutation<void, IZonesRemoveZoneBody>({
      query: (body) => ({
        url: 'zones/remove',
        method: 'POST',
        body,
      }),
    }),
    modifyZonesZone: build.mutation<void, IZonesModifyZoneBody | number>({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          zones,
        } = api.getState() as TypedRootState;
        let result;

        if (typeof args === 'object') {
          result = await fetchWithBQ({
            url: 'zones/modify',
            method: 'POST',
            body: args,
          });
        } else {
          result = await fetchWithBQ({
            url: 'zones/modify',
            method: 'POST',
            body: {
              volumeId,
              zoneId: args,
              newZoneRectangle: zones.zones[args].newZoneRectangle,
              zoneApplyment: zones.zones[args].zoneApplyment,
            },
          });
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    changeZonesZoneApplyment: build.mutation<void, void>({
      async queryFn(arg, api, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          zones,
        } = api.getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'zones/changeApplyment',
          method: 'POST',
          body: {
            volumeId,
            zoneId: zones.zones.length - 1,
            zonesApplyment: zones.useIn,
          },
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    setZonesZoneApplyment: build.mutation<void, TZonesSetZoneApplymentBody>({
      query: (body) => ({
        url: 'zones/setApplyment',
        method: 'POST',
        body,
      }),
    }),
    setZonesRotateAxes: build.mutation<void, IZonesRotateAxesBody>({
      query: (body) => ({
        url: 'zones/rotateAxes',
        method: 'POST',
        body,
      }),
    }),
    clearZonesZones: build.query<void, string>({
      query: (volumeId) => `zones/clear?volumeId=${volumeId}`,
    }),
  }),
});

export const {
  useGetZonesZonesQuery,
  useLazyClearZonesZonesQuery,
  useAddZonesZoneMutation,
  useChangeZonesZoneApplymentMutation,
  useModifyZonesZoneMutation,
  useRemoveZonesZoneMutation,
  useSetZonesZoneApplymentMutation,
  useSetZonesRotateAxesMutation,
} = zonesApi;
