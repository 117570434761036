import './styles.scss';

import { Button, Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { ChangeEvent, useCallback, useState } from 'react';

import {
  BLACK_PRIMARY,
  BLUE_PRIMARY,
  GREY_SECONDARY_EXTRA_LIGHT,
  REMARKS_NAMES,
  WHITE_PRIMARY,
} from '../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../hooks';
import { selectAreCracksZonesShown, selectCracksAnalysis, selectRemarks } from '../../../../store/selectors';
import { Remarks } from '../../../Remarks';
import { EntireStatistics } from './EntireStatistics';

const { cracks: cracksRemarksNames } = REMARKS_NAMES;

export const FindCracks = () => {
  const { setCracksRemarks, setCracksShowingZones } = useActionCreators();
  const [activeKey, setActiveKey] = useState<string>('0');
  const analysis = useAppSelector(selectCracksAnalysis);
  const areZonesShown = useAppSelector(selectAreCracksZonesShown);
  const { cracks } = useAppSelector(selectRemarks);

  const handleRemarksChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCracksRemarks({
        analysis,
        data: e.target.value,
      });
    },
    [analysis]
  );

  const handleShowingZones = useCallback(() => {
    setCracksShowingZones();
  }, []);

  return (
    <div className="cracks__statistics__find-cracks">
      <div className="cracks__statistics__find-cracks__buttons">
        {/* <Button title={'Find Cracks'} /> */}
        <Button
          title={areZonesShown ? 'cracks.hide-zones' : 'cracks.show-zones'}
          onClick={handleShowingZones}
          style={{
            backgroundColor: areZonesShown ? BLUE_PRIMARY : 'transparent',
            borderColor: areZonesShown ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
            color: areZonesShown ? WHITE_PRIMARY : BLACK_PRIMARY,
          }}
        />
      </div>
      <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
        <TabPane tab={<TranslatedText textKey="side-menu.results" />} key={'0'}>
          <EntireStatistics />
        </TabPane>
        <TabPane tab={<TranslatedText textKey="Remarks" />} key={'1'}>
          <Remarks
            value={cracks[cracksRemarksNames[analysis]]}
            name={cracksRemarksNames[analysis]}
            label={''}
            onChange={handleRemarksChange}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
