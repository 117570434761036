import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { MAX_DEFECTIVE_VALUE } from '../../consts';
import { ISliderRange } from '../../types/scanData';
import { TPaletteData } from '../api/slice/types';

const initialState = {
  isInverse: false,
  isMonochrome: false,
  isScale: false,
  colorRange: {
    start: 1,
    end: MAX_DEFECTIVE_VALUE,
  },
};

export const paletteSlice = createSlice({
  name: 'palette',
  initialState,
  reducers: {
    setPaletteData: (state, action: PayloadAction<TPaletteData>) => {
      Object.assign(state, action.payload);
    },
    setPaletteMonochrome: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.isMonochrome = !state.isMonochrome;
      } else {
        state.isMonochrome = action.payload;
      }
    },
    setPaletteInverse: (state) => {
      state.isInverse = !state.isInverse;
    },
    setPaletteCut: (state) => {
      state.isScale = !state.isScale;
    },
    setPaletteColorRange: (state, action: PayloadAction<Partial<ISliderRange>>) => {
      Object.assign(state.colorRange, action.payload);
    },
  },
});

export const { actions: paletteActions, reducer: palette } = paletteSlice;
