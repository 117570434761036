
import { createSelector } from '@reduxjs/toolkit';

import {
  IAdaptImageScaleCoords,
  IAdaptImageScaleParams,
  ISliceImageCroppingCoords,
  ISliceImageParams,
} from '../api/adapt-image/types';
import { TypedRootState } from '../types';

export const selectAdaptImage = (state: TypedRootState): string => state.adaptImage.imageBase64;
export const selectAdaptImageParams = (state: TypedRootState): ISliceImageParams => state.adaptImage.imageParams;
export const selectIsAdaptImageCropping = (state: TypedRootState): boolean => state.adaptImage.isCropping;
export const selectIsAdaptImageAccepted = (state: TypedRootState): boolean => state.adaptImage.isAccepted;
export const selectIsAdaptImageScaleInserted = (state: TypedRootState): boolean => state.adaptImage.isScaleInserted;
export const selectAdaptImageScaleParams = (state: TypedRootState): IAdaptImageScaleParams => state.adaptImage.scaleParams;
export const selectAdaptImageCropCoords = (state: TypedRootState): ISliceImageCroppingCoords => state.adaptImage.cropCoords;
export const selectIsAdaptImageScaleLength = (state: TypedRootState): number => selectAdaptImageScaleParams(state).scaleLengthMm;
export const selectAdaptImageScaleCoords = createSelector(selectAdaptImageScaleParams, ({ startPoint, endPoint }): IAdaptImageScaleCoords => ({
  startPoint,
  endPoint,
}));
