import './styles.scss';

import { Button, Icon, NumberInput, TranslatedText } from '@sms/plasma-ui';
import React, { useEffect, useMemo, useState } from 'react';

import { BUTTON_NAMES, DEFAULT_ZONES_PARAMS, FLAWS_ANALYSIS, SHAPES, SLICE_TYPES } from '../../../consts';
import { useActionCreators, useAppSelector, useFlaw } from '../../../hooks';
import {
  useSetASTMZoneFormChangedMutation,
  useSetASTMZoneRoundFormChangedMutation,
  useSetGOSTZoneFormChangedMutation,
  useSetGOSTZoneRoundFormChangedMutation,
  useSetSMSZoneFormChangedMutation,
} from '../../../store/api/flaws';
import {
  selectFlawsAnalysis,
  selectFlawsRectZones,
  selectFlawsRoundZones,
  selectFlawsSelectedRegionId,
  selectShapeBilletCutParam,
  selectSliceType,
  selectVolumeId,
} from '../../../store/selectors';
import { TFlawsDefaultZonesParams } from '../../../types';
import {
  debounce,
  getDefaultFlawsRectZoneChangeBody,
  getDefaultFlawsRoundZoneChangeBody,
  getFlawsRegionParamsById,
  getToFixedValue,
} from '../../../utils';
import { NumberInputValue } from '../../RangeBar';

export const FlawArea = () => {
  const { setFlawsSelectedRegionId, setFlawsZoneInFocus } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const selectedRegionId = useAppSelector(selectFlawsSelectedRegionId);
  const sliceType = useAppSelector(selectSliceType);
  const flawsAnalysis = useAppSelector(selectFlawsAnalysis);
  const shape = useAppSelector(selectShapeBilletCutParam);
  const rectZones = useAppSelector(selectFlawsRectZones);
  const roundZones = useAppSelector(selectFlawsRoundZones);
  const defaultRectBody = useMemo(() => getDefaultFlawsRectZoneChangeBody(volumeId, selectedRegionId), [
    volumeId,
    selectedRegionId,
  ]);
  const defaultRoundBody = useMemo(() => getDefaultFlawsRoundZoneChangeBody(volumeId), [volumeId]);
  const [zonesParams, setZonesParams] = useState<TFlawsDefaultZonesParams>(DEFAULT_ZONES_PARAMS);
  const selectedZone = getFlawsRegionParamsById(selectedRegionId, rectZones, roundZones);

  const [setSMSZoneFormChangedTrigger] = useSetSMSZoneFormChangedMutation();
  const [setGOSTRectZoneFormChangedTrigger] = useSetGOSTZoneFormChangedMutation();
  const [setGOSTRoundZoneFormChangedTrigger] = useSetGOSTZoneRoundFormChangedMutation();
  const [setASTMRectZoneFormChangedTrigger] = useSetASTMZoneFormChangedMutation();
  const [setASTMRoundZoneFormChangedTrigger] = useSetASTMZoneRoundFormChangedMutation();

  const triggerSMSZoneFormChange = useMemo(
    () =>
      debounce((param: Partial<typeof defaultRectBody>) => {
        setSMSZoneFormChangedTrigger({
          ...defaultRectBody,
          ...param,
        });
      }, 300),
    [flawsAnalysis, defaultRectBody],
  );

  const triggerGOSTRectZoneFormChange = useMemo(
    () =>
      debounce((param: Partial<typeof defaultRectBody>) => {
        setGOSTRectZoneFormChangedTrigger({
          ...defaultRectBody,
          ...param,
        });
      }, 300),
    [flawsAnalysis, defaultRectBody],
  );

  const triggerGOSTRoundZoneFormChange = useMemo(
    () =>
      debounce((param: Partial<typeof defaultRoundBody>) => {
        setGOSTRoundZoneFormChangedTrigger({
          ...defaultRoundBody,
          ...param,
        });
      }, 300),
    [flawsAnalysis, defaultRoundBody],
  );

  const triggerASTMRectZoneFormChange = useMemo(
    () =>
      debounce((param: Partial<typeof defaultRectBody>) => {
        setASTMRectZoneFormChangedTrigger({
          ...defaultRectBody,
          ...param,
        });
      }, 300),
    [flawsAnalysis, defaultRectBody],
  );

  const triggerASTMRoundZoneFormChange = useMemo(
    () =>
      debounce((param: Partial<typeof defaultRoundBody>) => {
        setASTMRoundZoneFormChangedTrigger({
          ...defaultRoundBody,
          ...param,
          regionId: selectedRegionId,
        });
      }, 300),
    [flawsAnalysis, defaultRectBody],
  );

  const handleRegionChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    let nextId = selectedRegionId + 1;
    let prevId = selectedRegionId - 1;

    if (rectZones) {
      if ('zones' in rectZones) {
        if (nextId > rectZones.zones.length - 1) {
          nextId = 0;
        }

        if (prevId < 0) {
          prevId = rectZones.zones.length - 1;
        }
      }

      if (!('zones' in rectZones)) {
        const zonesLength = Object.values(rectZones).length;

        if (zonesLength === 2) {
          if (nextId > zonesLength - 1) {
            nextId = 0;
          }

          if (prevId < 0) {
            prevId = zonesLength - 1;
          }
        }

        if (zonesLength === 3) {
          if (nextId > 1) {
            nextId = 0;
          }

          if (prevId < 0) {
            prevId = 1;
          }
        }
      }
    }

    if (roundZones) {
      const zonesLength = Object.values(roundZones).length;

      if (zonesLength === 2) {
        if (nextId > zonesLength - 1) {
          nextId = 0;
        }

        if (prevId < 0) {
          prevId = zonesLength - 1;
        }
      }

      if (zonesLength === 3) {
        if (nextId > 1) {
          nextId = 0;
        }

        if (prevId < 0) {
          prevId = 1;
        }
      }
    }

    if (e.currentTarget.name === BUTTON_NAMES.next) setFlawsSelectedRegionId(nextId);
    if (e.currentTarget.name === BUTTON_NAMES.prev) setFlawsSelectedRegionId(prevId);

    setFlawsZoneInFocus(true);
  };

  const handleX1NumberInputChange = async (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    setZonesParams((prev) => ({
      ...prev,
      x0: +value,
      x1: prev.x1 + (+value - prev.x0),
    }));

    const x1 = getToFixedValue(+value);

    if (
      flawsAnalysis === FLAWS_ANALYSIS.sms &&
      sliceType !== SLICE_TYPES.rect &&
      sliceType !== SLICE_TYPES.round &&
      sliceType !== SLICE_TYPES.cut
    ) {
      triggerSMSZoneFormChange({ x1 });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && sliceType !== SLICE_TYPES.round && shape !== SHAPES.round) {
      triggerGOSTRectZoneFormChange({ x1 });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.rect || shape === SHAPES.rect)) {
      triggerASTMRectZoneFormChange({ x1 });
    }
  };

  const handleY1NumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    setZonesParams((prev) => ({
      ...prev,
      y0: +value,
      y1: prev.y1 + (+value - prev.y0),
    }));

    const y1 = getToFixedValue(+value);

    if (
      flawsAnalysis === FLAWS_ANALYSIS.sms &&
      sliceType !== SLICE_TYPES.rect &&
      sliceType !== SLICE_TYPES.round &&
      sliceType !== SLICE_TYPES.cut
    ) {
      triggerSMSZoneFormChange({ y1 });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && sliceType !== SLICE_TYPES.round && shape !== SHAPES.round) {
      triggerGOSTRectZoneFormChange({ y1 });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.rect || shape === SHAPES.rect)) {
      triggerASTMRectZoneFormChange({ y1 });
    }
  };

  const handleHeightNumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    setZonesParams((prev) => ({
      ...prev,
      y1: +value + zonesParams.y0,
    }));

    const height = getToFixedValue(+value);

    if (
      flawsAnalysis === FLAWS_ANALYSIS.sms &&
      sliceType !== SLICE_TYPES.rect &&
      sliceType !== SLICE_TYPES.round &&
      sliceType !== SLICE_TYPES.cut
    ) {
      triggerSMSZoneFormChange({ height });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && sliceType !== SLICE_TYPES.round && shape !== SHAPES.round) {
      triggerGOSTRectZoneFormChange({ height });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.rect || shape === SHAPES.rect)) {
      triggerASTMRectZoneFormChange({ height });
    }
  };

  const handleWidthNumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    setZonesParams((prev) => ({
      ...prev,
      x1: +value + zonesParams.x0,
    }));

    const width = getToFixedValue(+value);

    if (
      flawsAnalysis === FLAWS_ANALYSIS.sms &&
      sliceType !== SLICE_TYPES.rect &&
      sliceType !== SLICE_TYPES.round &&
      sliceType !== SLICE_TYPES.cut
    ) {
      triggerSMSZoneFormChange({ width });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && sliceType !== SLICE_TYPES.round && shape !== SHAPES.round) {
      triggerGOSTRectZoneFormChange({ width });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.rect || shape === SHAPES.rect)) {
      triggerASTMRectZoneFormChange({ width });
    }
  };

  const handleXNumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    const centerPointX = getToFixedValue(+value);

    setZonesParams((prev) => ({
      ...prev,
      x: centerPointX,
    }));

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerGOSTRoundZoneFormChange({ centerPointX });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerASTMRoundZoneFormChange({ centerPointX });
    }
  };

  const handleYNumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    const centerPointY = getToFixedValue(+value);

    setZonesParams((prev) => ({
      ...prev,
      y: centerPointY,
    }));

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerGOSTRoundZoneFormChange({ centerPointY });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerASTMRoundZoneFormChange({ centerPointY });
    }
  };

  const handleInnerDiameterNumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    const innerDiameter = getToFixedValue(+value);

    setZonesParams((prev) => ({
      ...prev,
      innerDiameter,
    }));

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerGOSTRoundZoneFormChange({ innerDiameter });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerASTMRoundZoneFormChange({ innerDiameter });
    }
  };

  const handleOuterDiameterNumberInputChange = (value: NumberInputValue) => {
    if (!value) return;

    setFlawsZoneInFocus(true);

    const outerDiameter = getToFixedValue(+value);

    setZonesParams((prev) => ({
      ...prev,
      outerDiameter,
    }));

    if (flawsAnalysis === FLAWS_ANALYSIS.gost && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerGOSTRoundZoneFormChange({ outerDiameter });
    }

    if (flawsAnalysis === FLAWS_ANALYSIS.astm && (sliceType === SLICE_TYPES.round || shape === SHAPES.round)) {
      triggerASTMRoundZoneFormChange({ outerDiameter });
    }
  };

  useEffect(() => {
    setZonesParams(selectedZone);
  }, [
    selectedZone.x0,
    selectedZone.x1,
    selectedZone.y0,
    selectedZone.y1,
    selectedZone.x,
    selectedZone.y,
    selectedZone.innerDiameter,
    selectedZone.outerDiameter,
  ]);

  return (
    <div className="flaws__menu__flaw-area">
      <h3 className="flaws__menu__title side-menu__title">
        <TranslatedText textKey="side-menu.flaw-area" />
      </h3>
      <div className="flaws__menu__region">
        <div>REG: {selectedRegionId + 1}</div>
        <div className="flaws__menu__region__controls">
          <Button icon={<Icon name="angle-left" />} name={BUTTON_NAMES.prev} onClick={handleRegionChange} />
          <Button icon={<Icon name="angle-right" />} name={BUTTON_NAMES.next} onClick={handleRegionChange} />
        </div>
      </div>
      <div className="flaws__menu__inputs-wrapper">
        {sliceType !== SLICE_TYPES.round && shape !== SHAPES.round && (
          <>
            <NumberInput
              name={BUTTON_NAMES.x1}
              decimalSeparator={'.'}
              step={1}
              hintMode="hidden"
              label={'X1'}
              precision={2}
              maxWidth={'100%'}
              value={zonesParams.x0}
              onChange={handleX1NumberInputChange}
            />
            <NumberInput
              name={BUTTON_NAMES.y1}
              decimalSeparator={'.'}
              step={1}
              hintMode="hidden"
              label={'Y1'}
              precision={2}
              maxWidth={'100%'}
              value={zonesParams.y0}
              onChange={handleY1NumberInputChange}
            />
            <NumberInput
              name={BUTTON_NAMES.height}
              decimalSeparator={'.'}
              step={1}
              hintMode="hidden"
              label={'dimensions.height'}
              precision={2}
              maxWidth={'100%'}
              value={getToFixedValue(zonesParams.y1 - zonesParams.y0)}
              onChange={handleHeightNumberInputChange}
            />
            <NumberInput
              name={BUTTON_NAMES.width}
              decimalSeparator={'.'}
              step={1}
              hintMode="hidden"
              label={'dimensions.width'}
              precision={2}
              maxWidth={'100%'}
              value={getToFixedValue(zonesParams.x1 - zonesParams.x0)}
              onChange={handleWidthNumberInputChange}
            />
          </>
        )}

        {(sliceType === SLICE_TYPES.round || shape === SHAPES.round) && (
          <>
            <NumberInput
              name={BUTTON_NAMES.x}
              decimalSeparator={'.'}
              precision={2}
              step={1}
              hintMode="hidden"
              label={'X'}
              value={zonesParams.x}
              onChange={handleXNumberInputChange}
            />
            <NumberInput
              name={BUTTON_NAMES.y}
              decimalSeparator={'.'}
              precision={2}
              step={1}
              hintMode="hidden"
              label={'Y'}
              value={zonesParams.y}
              onChange={handleYNumberInputChange}
            />
            <NumberInput
              name={BUTTON_NAMES.outerDiameter}
              decimalSeparator={'.'}
              precision={2}
              step={1}
              hintMode="hidden"
              label={'side-menu.outer'}
              value={zonesParams.outerDiameter}
              onChange={handleOuterDiameterNumberInputChange}
            />
            <NumberInput
              name={BUTTON_NAMES.innerDiameter}
              decimalSeparator={'.'}
              precision={2}
              step={1}
              hintMode="hidden"
              label={'side-menu.inner'}
              value={zonesParams.innerDiameter}
              onChange={handleInnerDiameterNumberInputChange}
            />
          </>
        )}
      </div>
    </div>
  );
};
