import { IPaletteDataFromAnalysis } from '../store/api/automatic-analysis/types';
import { TPaletteData } from '../store/api/slice/types';

export const getFormattedPaletteData = ({
  rangeStart,
  rangeEnd,
  clipped,
  monochrome,
  inverse,
}: IPaletteDataFromAnalysis): TPaletteData => ({
  colorRange: {
    start: rangeStart,
    end: rangeEnd,
  },
  isInverse: inverse,
  isMonochrome: monochrome,
  isScale: clipped,
});
