import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { DEFAULT_ERROR_MESSAGE, MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { RotateCounterClockwiseInfo } from './types';

const { setMessageModal, setSpinnerVisible, setFlipped } = allActions;

export const expertOperationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFlipCD: build.query<void, void>({
      async queryFn(_, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        dispatch(setSpinnerVisible(true));

        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ(`expertOperations/flipCD?volumeId=${volumeId}`);

        if (result?.error) {
          dispatch(setSpinnerVisible(false));
          dispatch(
            setMessageModal({
              type: MODAL_STATUS.ERROR,
              description: DEFAULT_ERROR_MESSAGE,
              title: MODAL_TITLE.error,
            }),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        dispatch(setFlipped());

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    setRotateCounterClockwise: build.mutation<string, boolean>({
      async queryFn(isCounterClockwiseRotation, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId, fileType },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'expertOperations/rotate',
          method: 'POST',
          body: {
            volumeId,
            scanDataFileType: fileType,
            isCounterClockwiseRotation,
          },
        });

        if (result.error) {
          dispatch(setSpinnerVisible(false));
          dispatch(
            setMessageModal({
              type: MODAL_STATUS.ERROR,
              description: DEFAULT_ERROR_MESSAGE,
              title: MODAL_TITLE.error,
            }),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<string, FetchBaseQueryError, unknown>;
      },
    }),
    getRotatedCounterClockwiseInfo: build.query<RotateCounterClockwiseInfo | null, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'expertOperations/rotate',
          method: 'GET',
        });

        if (result?.error) {
          dispatch(setSpinnerVisible(false));
          dispatch(
            setMessageModal({
              type: MODAL_STATUS.ERROR,
              description: DEFAULT_ERROR_MESSAGE,
              title: MODAL_TITLE.error,
            }),
          );
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as RotateCounterClockwiseInfo;

        if (data) {
          if (data.sampleRotationProcessState === 'Waiting') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.NOTIFICATION,
                title: MODAL_TITLE.notification,
                description: 'The rotation process in the queue.',
              }),
            );
          }

          if (data.sampleRotationProcessState === 'Rotating') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.NOTIFICATION,
                title: MODAL_TITLE.notification,
                description: 'Rotating the source file.',
              }),
            );
          }

          if (data.sampleRotationProcessState === 'Compressing') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.NOTIFICATION,
                title: MODAL_TITLE.notification,
                description: 'Compressing the source file.',
              }),
            );
          }

          if (data.sampleRotationProcessState === 'Ready') {
            dispatch(
              setMessageModal({
                type: MODAL_STATUS.SUCCESS,
                title: MODAL_TITLE.notification,
                description: 'The source file has been rotated.',
              }),
            );
          }
        }

        return result as QueryReturnValue<RotateCounterClockwiseInfo | null, FetchBaseQueryError, unknown>;
      },
    }),
    cancelRotation: build.query<void, string>({
      async queryFn(taskId, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'expertOperations/rotate',
          params: {
            taskId,
          },
          method: 'DELETE',
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useSetRotateCounterClockwiseMutation,
  useLazyGetFlipCDQuery,
  useLazyGetRotatedCounterClockwiseInfoQuery,
  useLazyCancelRotationQuery,
} = expertOperationsApi;
