import { useEffect } from 'react';

import { ACTIVE_EVENTS, INACTIVITY_DELAY } from '../../consts';

interface Props {
  logout: () => void;
}

export const LogoutWhenInactive = ({ logout }: Props) => {
  let timer: ReturnType<typeof setTimeout>;

  const restartAutoReset = () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      logout();
    }, INACTIVITY_DELAY);
  };

  useEffect(() => {
    restartAutoReset();

    ACTIVE_EVENTS.forEach((item) => {
      window.addEventListener(item, restartAutoReset);
    });

    return () => {
      if (timer) {
        clearTimeout(timer);
        ACTIVE_EVENTS.forEach((item) => {
          window.removeEventListener(item, restartAutoReset);
        });
      }
    };
  }, []);

  return null;
};
