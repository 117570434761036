import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { ICracksBilletCutRectZones } from '../../../../../../store/api/cracks/types';
import { TTupleOfTwoNumbers } from '../../../../../../types';
import { ICartesianModeParams } from '../../../../../../utils';
import { Circle } from '../Circle';
import { Line } from '../Line';
import { Rect } from '../Rect';

interface IProps {
  data: ICracksBilletCutRectZones;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx: number;
  billetCutDy: number;
}

export const BilletCutRectZones: FC<IProps> = ({ data, domains, cartesianModeParams, billetCutDx, billetCutDy }) => {
  const {
    centerPoint,
    cornerZones,
    innerZone,
    middleZone,
    outerZone,
    trippleLeftLine,
    trippleRightLine,
    fullSampleRectangle,
  } = data;

  return (
    <>
      {cornerZones.map((z, idx) => {
        return (
          <Rect
            key={idx}
            data={z}
            domains={domains}
            cartesianModeParams={cartesianModeParams}
            billetCutDx={billetCutDx}
            billetCutDy={billetCutDy}
          />
        );
      })}
      <Rect
        data={fullSampleRectangle}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Rect
        data={innerZone}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Rect
        data={middleZone}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Rect
        data={outerZone}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Line
        data={trippleLeftLine}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Line
        data={trippleRightLine}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
      />
      <Circle
        data={centerPoint}
        domains={domains}
        cartesianModeParams={cartesianModeParams}
        billetCutDx={billetCutDx}
        billetCutDy={billetCutDy}
        radius={1}
        fill={ORANGE_PRIMARY}
      />
    </>
  );
};
