import { IPxCoordinates } from '../store/api/slice/types';
import { ISVGLineParams, RectParams, TCoordinates, TTupleOfTwoNumbers } from '../types';
import { ISliderRange } from '../types/scanData';
import { HOST_ENVS } from './hostEnvs';

export const MAX_DEFECTIVE_VALUE = 32768;
export const MIN_DEFECTIVE_VALUE = 0;
export const INITIAL_COORDINATES: TCoordinates = { x: 0, y: 0 };
export const INITIAL_RANGE: ISliderRange = { start: 0, end: 0 };
export const PX_INITIAL_COORDINATES: IPxCoordinates = { i: 0, j: 0 };
export const INITIAL_DOMAINS: TTupleOfTwoNumbers = [0, 0];
export const INITIAL_3D_SCALE = 0.02;

export const INITIAL_RECT_PARAMS: RectParams = {
  ...INITIAL_COORDINATES,
  width: 0,
  height: 0,
};

export const INITIAL_LINE_PARAMS: ISVGLineParams = {
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
};

export const paletteSliderRange: ISliderRange = {
  start: 0,
  end: MAX_DEFECTIVE_VALUE,
};

export const isProduction = process.env.NODE_ENV === 'production';
export const isHostEnvProduction = process.env.REACT_APP_HOST_ENV === HOST_ENVS.production;
export const isHostEnvStandalone = process.env.REACT_APP_HOST_ENV === HOST_ENVS.standalone;
export const isHostEnvStaging = process.env.REACT_APP_HOST_ENV === HOST_ENVS.staging;
export const isHostEnvDevelopment = process.env.REACT_APP_HOST_ENV === HOST_ENVS.development;
export const isCloudHostEnv = isHostEnvProduction || isHostEnvStaging;

export const AUTO_ANALYSIS_API_URL = 'http://localhost/api/v1';
