import { Checkbox } from '@sms/plasma-ui';
import React, { memo } from 'react';

import { useActionCreators } from '../../../../hooks';
import { DBIsUsed } from '../../../../types';

interface IProps {
  list: readonly [string, string][];
  section: string;
  values: Record<string, DBIsUsed>;
}

export const DBCheckboxList = memo(function DBCheckboxList({ list, section, values }: IProps) {
  const { setDatabaseCheckboxes } = useActionCreators();

  const handleCheckboxesChange = (section: string, key: string) => {
    setDatabaseCheckboxes({ section, key });
  };

  return (
    <>
      {list.map(([type, title]) => (
        <Checkbox
          key={type}
          name={type}
          checked={values[type].isUsed}
          title={title}
          onChange={() => handleCheckboxesChange(section, type)}
        />
      ))}
    </>
  );
});
