import { TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { IProps } from '../index';

export const DefectsAreaPercentRow: FC<IProps> = ({ statistics }) => {
  return (
    <>
      {statistics &&
        'defectsAreaPercentC' in statistics &&
        'defectsAreaPercentR' in statistics &&
        'defectsAreaPercentS' in statistics && (
          <li className="seg__statistics__item">
            <p>
              <TranslatedText textKey="side-menu.area-percent" /> :
            </p>
            <p>{statistics.defectsAreaPercentC}</p>
            <p>{statistics.defectsAreaPercentR}</p>
            <p>{statistics.defectsAreaPercentS}</p>
          </li>
        )}
    </>
  );
};
