import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { LOCALSTORAGE_KEYS } from '../../../consts';
import { getFromLocalStorage, setToLocalStorage } from '../../../utils';
import { api } from '..';

interface IBackendStartedParams {
  url: string | undefined;
  headers?: Record<string, string>;
}

interface IBackendState {
  instanceId: string | null;
  appWorkMode: string;
  rebootRequired: boolean;
}

export const sharedApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkBackendStarted: build.query<boolean, IBackendStartedParams>({
      async queryFn({ url, headers = {} }, _api, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `${url}/health`,
          headers,
          responseHandler: (response) => response.text(),
        });

        const meta = result?.meta as { request: Request; response: Response };

        return { data: meta?.response?.status === 200 } as QueryReturnValue<boolean, FetchBaseQueryError, unknown>;
      },
    }),
    getBackendState: build.query<IBackendState | null, void>({
      async queryFn(_, _api, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'machine/state',
          headers: {
            'hdsw-ec2-instance-type': getFromLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceType),
          },
        });

        const responseData = result?.data as IBackendState;
        const meta = result?.meta as { request: Request; response: Response };
        const isIdMatched = responseData?.instanceId === getFromLocalStorage(LOCALSTORAGE_KEYS.ec2InstanceId);
        const data = meta?.response?.status === 200 && isIdMatched ? responseData : null;

        if (isIdMatched) {
          setToLocalStorage(LOCALSTORAGE_KEYS.backendState, 'Running');
        }

        return {
          data,
        };
      },
    }),
  }),
});

export const { useLazyCheckBackendStartedQuery, useLazyGetBackendStateQuery } = sharedApi;
