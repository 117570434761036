import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import { Button } from '@sms/plasma-ui/lib/components/Button/Button';
import React, { useRef } from 'react';

import { IMAGE_BLOB_TYPE } from '../../consts';
import { useAppSelector } from '../../hooks';
import { useLazyGetAllSnapshotsQuery, useTakeSnapshotMutation } from '../../store/api/snaphot';
import { selectedSnapshotId, selectSnapshots, selectVolumeId } from '../../store/selectors';
import { convertFileToBase64, pasteFromClipboard } from '../../utils';
import { ImagesFileItem } from './ImagesFileItem';

export const ImagesFiles = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const imagesList = useAppSelector((state) => selectSnapshots(state, volumeId));
  const activeSnapshotId = useAppSelector(selectedSnapshotId);

  const [getAllSnapshotsTrigger] = useLazyGetAllSnapshotsQuery();
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleNewFromFileClick = () => {
    fileInputRef.current?.click();
  };

  const takeImageAndRefetch = (imageBase64String: string) => {
    takeSnapshotTrigger({
      // name: 'Untitled_',
      name: 'Snapshot_',
      imageBase64String,
      description: 'Other',
    }).then(() => getAllSnapshotsTrigger(volumeId));
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = !!event.target.files && event.target.files[0];
    event.target.value = '';

    if (!fileUploaded) return;

    convertFileToBase64(fileUploaded, takeImageAndRefetch);
  };

  const handleNewFromClipboardClick = async () => {
    await pasteFromClipboard(IMAGE_BLOB_TYPE, takeImageAndRefetch);
  };

  return (
    <div className="images-list__container">
      <h3 className="side-menu__title">
        <TranslatedText textKey="images.files" />
      </h3>
      <ul className="images-list">
        {imagesList &&
          Object.keys(imagesList).map((imageName) => (
            <ImagesFileItem
              key={imageName}
              name={imageName}
              description={'Ultrasonic'}
              active={imageName === activeSnapshotId}
            />
          ))}
      </ul>
      <div className="images-list__buttons">
        <Button title={'images.new-from-file'} onClick={handleNewFromFileClick} />
        <Button title={'images.new-from-clipboard'} onClick={handleNewFromClipboardClick} />
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};
