import './styles.scss';

import React from 'react';

import { SLICE_TYPES } from '../../../consts/geometry';
import { useAppSelector } from '../../../hooks';
import { selectSliceType } from '../../../store/selectors';
import { ALinesRow } from './ALinesRow';
import { BLinesRow } from './BLinesRow';
import { CLinesRow } from './CLinesRow';
import { CutRow } from './CutRow';
import { DiagonalsRow } from './DiagonalsRow';
import { DimensionsRow } from './DimensionsRow';

export const GeometryStatistic = () => {
  const sliceType = useAppSelector(selectSliceType);

  return (
    <ul className="geometry__statistic">
      {sliceType !== SLICE_TYPES.cut && sliceType !== SLICE_TYPES.round && (
        <>
          <ALinesRow />
          <BLinesRow />
          <CLinesRow />
          <DiagonalsRow />
          <DimensionsRow />
        </>
      )}
      {sliceType === SLICE_TYPES.round && <DiagonalsRow />}
      {sliceType === SLICE_TYPES.cut && <CutRow />}
    </ul>
  );
};
