import { TranslatedText } from '@sms/plasma-ui';
import React, { memo } from 'react';

import { useActionCreators } from '../../../hooks';
import { DBRangeStrings } from '../../../types';
import { getDateWithTime } from '../../../utils';
import { DateInput } from '../../UI/DateInput';

interface IProps {
  section: string;
  values: DBRangeStrings;
  label: string;
}

export const BDDateRangeInput = memo(function BDDateRangeInput({ section, values, label }: IProps) {
  const { setDatabaseRangeValues } = useActionCreators();

  const handleDateChange = (value: string, range: 'start' | 'end') => {
    const startDateString = values.start;
    const endDateString = values.end;

    if (range === 'start' && (!endDateString || new Date(endDateString) < new Date(value))) {
      setDatabaseRangeValues({
        section,
        key: 'createdAt',
        range: 'end',
        value: getDateWithTime(value, 'end'),
      });
    }

    if (range === 'end' && (!startDateString || new Date(startDateString) > new Date(value))) {
      setDatabaseRangeValues({
        section,
        key: 'createdAt',
        range: 'start',
        value: getDateWithTime(value, 'start'),
      });
    }

    setDatabaseRangeValues({
      section,
      key: 'createdAt',
      range,
      value: getDateWithTime(value, range),
    });
  };

  const handleClear = (range: 'start' | 'end') => {
    setDatabaseRangeValues({ section, key: 'createdAt', range, value: '' });
  };

  return (
    <div className="db__form-input">
      <p>
        <TranslatedText textKey={label} />
      </p>
      <div className="db__form-choices">
        <DateInput
          label={'common.from'}
          value={values.start}
          name={'date_from'}
          onChange={(value: string) => handleDateChange(value, 'start')}
          onClear={() => handleClear('start')}
        />
        <DateInput
          label={'common.to'}
          value={values.end}
          name={'date_to'}
          onChange={(value: string) => handleDateChange(value, 'end')}
          onClear={() => handleClear('end')}
        />
      </div>
    </div>
  );
});
