import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { useCallback } from 'react';

import { BLACK_PRIMARY, BLUE_PRIMARY, GREY_SECONDARY_EXTRA_LIGHT, WHITE_PRIMARY } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectZonesAxesRotation } from '../../../store/selectors/zones.selector';

export const CoordinateSystem = () => {
  const { allowZonesAxesRotation } = useActionCreators();
  const allowAxesRotation = useAppSelector(selectZonesAxesRotation);

  const buttonClickHandler = useCallback(() => {
    allowZonesAxesRotation();
  }, []);

  return (
    <div className="coordinate__system">
      <h3 className="side-menu__title">
        <TranslatedText textKey="side-menu.coordinate-system" />
      </h3>
      <Button
        title={'common.rotate'}
        // name={name}
        onClick={buttonClickHandler}
        style={{
          backgroundColor: allowAxesRotation ? BLUE_PRIMARY : 'transparent',
          borderColor: allowAxesRotation ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: allowAxesRotation ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
    </div>
  );
};
