import React, { FC, Fragment, useMemo } from 'react';

import { IDs, TRANSPARENT } from '../../../../consts';
import { useActionCreators, useAppSelector, useCracks } from '../../../../hooks';
import {
  selectBorderData,
  selectCracksAllCracks,
  selectCracksSelectedCrackId,
  selectVolumeId,
} from '../../../../store/selectors';
import { IBilletCutParams } from '../../../../store/slices/types';
import { TTupleOfTwoNumbers } from '../../../../types';
import { ICartesianModeParams, IGraphParams } from '../../../../utils';
import { ForbiddenZones } from '../../../ForbiddenZones';
import { Path } from '../../../Path';
import { CrackInfo } from './CrackInfo';
import { CrackInsertion } from './CrackInsertion';
import { CrackRectangle } from './CrackRectangle';
import { CracksZones } from './CracksZones';

interface IProps {
  imageForAnalysis?: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
  billetCutParams: IBilletCutParams;
  imageWidth: number;
  imageHeight: number;
}

export const CracksSvgElements: FC<IProps> = ({
  cartesianModeParams,
  domains,
  graphParams,
  billetCutParams,
  imageHeight,
  imageWidth,
}) => {
  const {
    setCracksSelectedCrackIds,
    removeCracksSelectedCrackIds,
    setSelectedCrackTypes,
    resetCracksSelectedCrackIds,
  } = useActionCreators();
  const {
    deleteCrackTrigger,
    areZonesShown,
    isInfoModificationMode,
    isDeletionModificationMode,
    isModifyModificationMode,
    modificationMode,
  } = useCracks();
  const { realSampleHeight, realSampleWidth, x: originX, y: originY } = cartesianModeParams;
  const volumeId = useAppSelector(selectVolumeId);
  const selectedCrackIds = useAppSelector(selectCracksSelectedCrackId);
  const allCracks = useAppSelector(selectCracksAllCracks);
  const borderData = useAppSelector((state) => selectBorderData(state, volumeId));
  const pathOffsets = useMemo(
    () => ({
      x: (billetCutParams.dx / domains[0]) * cartesianModeParams.realSampleWidth,
      y: -(billetCutParams.dy / domains[1]) * cartesianModeParams.realSampleHeight,
    }),
    [
      billetCutParams.dx,
      billetCutParams.dy,
      cartesianModeParams.realSampleHeight,
      cartesianModeParams.realSampleWidth,
      domains,
    ],
  );

  const convertedBorderData = useMemo(
    () =>
      borderData.outerBorderPx.map((i) => ({
        i: i.i,
        j: imageHeight - i.j,
      })),
    [borderData, imageHeight],
  );

  const handleCrackSelection = async (id: number) => {
    if (!modificationMode) return;

    const isIdMatched = selectedCrackIds.includes(id);

    if (isDeletionModificationMode) {
      if (isIdMatched) {
        if (selectedCrackIds.length > 1) {
          removeCracksSelectedCrackIds(id);
          return;
        }

        await deleteCrackTrigger([id]);
        removeCracksSelectedCrackIds(id);

        return;
      }
    }

    if (isModifyModificationMode) {
      resetCracksSelectedCrackIds();
      const type = allCracks?.yoloCracks.find((crack) => crack.id === id)?.prediction.label.name;
      setSelectedCrackTypes(type ? [type] : []);

      if (isIdMatched) {
        setSelectedCrackTypes([]);
      }
    }

    if (isIdMatched) {
      removeCracksSelectedCrackIds(id);
      return;
    }

    setCracksSelectedCrackIds(id);
  };

  return (
    <>
      {borderData && (
        <g>
          <Path
            classNames={'borders'}
            isCartesianMode={true}
            cartesianModeParams={cartesianModeParams}
            data={borderData.outerBorder}
            domains={domains}
            graphParams={graphParams}
            pathOffsets={pathOffsets}
          />
          <Path
            classNames={'borders'}
            isCartesianMode={true}
            cartesianModeParams={cartesianModeParams}
            data={borderData.innerBorder}
            domains={domains}
            graphParams={graphParams}
            pathOffsets={pathOffsets}
          />
        </g>
      )}
      {allCracks?.forbiddenZones && (
        <ForbiddenZones
          zones={allCracks.forbiddenZones}
          cartesianModeParams={cartesianModeParams}
          domains={domains}
          graphParams={graphParams}
          isCartesianMode={true}
        />
      )}
      {areZonesShown && (
        <CracksZones
          cartesianModeParams={cartesianModeParams}
          domains={domains}
          billetCutDx={billetCutParams.dx}
          billetCutDy={billetCutParams.dy}
        />
      )}
      <g id={IDs.cracksGroupWrapper} transform={`translate(${originX}, ${originY})`}>
        <rect x={0} y={0} width={realSampleWidth} height={realSampleHeight} style={{ fill: TRANSPARENT }} />
        <CrackInsertion cartesianModeParams={cartesianModeParams} imageWidth={imageWidth} imageHeight={imageHeight} />
        {allCracks?.yoloCracks?.map(
          ({
            id,
            prediction: {
              rectangle: { x, y, width, height },
              score,
              label,
            },
          }) => {
            const isIdMatched = selectedCrackIds.includes(id);
            const wRatio = imageWidth / realSampleWidth;
            const hRatio = imageHeight / realSampleHeight;

            const realWidth = width / wRatio;
            const realHeight = height / hRatio;
            const x0 = x / wRatio;
            const y0 = y / hRatio;

            return (
              <Fragment key={id}>
                {isInfoModificationMode && isIdMatched && (
                  <CrackInfo
                    id={id}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    wRatio={wRatio}
                    hRatio={hRatio}
                    probability={score}
                    domains={domains}
                    cartesianModeParams={cartesianModeParams}
                    convertedBorderData={convertedBorderData}
                  />
                )}
                <CrackRectangle
                  x0={x0}
                  y0={y0}
                  width={realWidth}
                  height={realHeight}
                  crackType={label.name}
                  isSelected={isIdMatched}
                  onCrackSelection={() => handleCrackSelection(id)}
                  wRatio={wRatio}
                  hRatio={hRatio}
                  id={id}
                />
              </Fragment>
            );
          },
        )}
      </g>
    </>
  );
};
