import { Checkbox, TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useActionCreators, useAppSelector } from '../../../hooks';
import { useChangeZonesZoneApplymentMutation, useModifyZonesZoneMutation } from '../../../store/api/zones';
import {
  selectZonesSelectedZoneId,
  selectZonesUsedInBackwall,
  selectZonesUsedInCracks,
  selectZonesUsedInFlaws,
  selectZonesUsedInSegregations,
  selectZonesZones,
} from '../../../store/selectors/zones.selector';

export const UseIn = () => {
  const { setUsedIn, modifyZonesZone } = useActionCreators();

  const selectedZoneId = useAppSelector(selectZonesSelectedZoneId);
  const zones = useAppSelector(selectZonesZones);
  const isUsedInSegregations = useAppSelector(selectZonesUsedInSegregations);
  const isUsedInCracks = useAppSelector(selectZonesUsedInCracks);
  const isUsedInBackwall = useAppSelector(selectZonesUsedInBackwall);
  const isUsedInFlaws = useAppSelector(selectZonesUsedInFlaws);

  const [changeZoneApplymentTrigger] = useChangeZonesZoneApplymentMutation();
  const [modifyZoneTrigger] = useModifyZonesZoneMutation();

  const handleSegCheckboxChange = () => {
    const getBody = (zoneId: number) => {
      return {
        zoneId,
        zoneApplyment: {
          ...zones[zoneId].zoneApplyment,
          useInSegregations: !zones[zoneId].zoneApplyment.useInSegregations,
        },
      };
    };

    if (selectedZoneId === null) {
      setUsedIn('useInSegregations');

      if (zones.length === 0) return;

      modifyZonesZone(getBody(zones.length - 1));
      changeZoneApplymentTrigger();

      return;
    }

    modifyZonesZone(getBody(selectedZoneId));
    modifyZoneTrigger(selectedZoneId);
  };

  const handleCracksCheckboxChange = () => {
    const getBody = (zoneId: number) => {
      return {
        zoneId,
        zoneApplyment: {
          ...zones[zoneId].zoneApplyment,
          useInCracks: !zones[zoneId].zoneApplyment.useInCracks,
        },
      };
    };

    if (selectedZoneId === null) {
      setUsedIn('useInCracks');

      if (zones.length === 0) return;

      modifyZonesZone(getBody(zones.length - 1));
      changeZoneApplymentTrigger();

      return;
    }

    modifyZonesZone(getBody(selectedZoneId));
    modifyZoneTrigger(selectedZoneId);
  };

  const handleBackwallCheckboxChange = () => {
    const getBody = (zoneId: number) => {
      return {
        zoneId,
        zoneApplyment: {
          ...zones[zoneId].zoneApplyment,
          useInBackwall: !zones[zoneId].zoneApplyment.useInBackwall,
        },
      };
    };

    if (selectedZoneId === null) {
      setUsedIn('useInBackwall');

      if (zones.length === 0) return;

      modifyZonesZone(getBody(zones.length - 1));
      changeZoneApplymentTrigger();

      return;
    }

    modifyZonesZone(getBody(selectedZoneId));
    modifyZoneTrigger(selectedZoneId);
  };

  const handleFlawsCheckboxChange = () => {
    const getBody = (zoneId: number) => {
      return {
        zoneId,
        zoneApplyment: {
          ...zones[zoneId].zoneApplyment,
          useInFlaws: !zones[zoneId].zoneApplyment.useInFlaws,
        },
      };
    };

    if (selectedZoneId === null) {
      setUsedIn('useInFlaws');

      if (zones.length === 0) return;

      modifyZonesZone(getBody(zones.length - 1));
      changeZoneApplymentTrigger();

      return;
    }

    modifyZonesZone(getBody(selectedZoneId));
    modifyZoneTrigger(selectedZoneId);
  };

  return (
    <div className="use-in">
      <h3 className="side-menu__title">
        <TranslatedText textKey="side-menu.block-in" />
      </h3>
      <div className="side-menu__wrapper">
        <Checkbox
          hint="Hint"
          hintMode="hidden"
          name="segAnalysis"
          onChange={handleSegCheckboxChange}
          title="zones.seg-analysis"
          checked={
            selectedZoneId !== null ? zones[selectedZoneId].zoneApplyment.useInSegregations : isUsedInSegregations
          }
        />
        <div className="legend seg" />
      </div>
      <div className="side-menu__wrapper">
        <Checkbox
          hint="Hint"
          hintMode="hidden"
          name="flawAnalysis"
          onChange={handleFlawsCheckboxChange}
          title="zones.flaw-analysis"
          checked={selectedZoneId !== null ? zones[selectedZoneId].zoneApplyment.useInFlaws : isUsedInFlaws}
        />
        <div className="legend flaw" />
      </div>
      <div className="side-menu__wrapper">
        <Checkbox
          hint="Hint"
          hintMode="hidden"
          name="crackAnalysis"
          onChange={handleCracksCheckboxChange}
          title="zones.crack-analysis"
          checked={selectedZoneId !== null ? zones[selectedZoneId].zoneApplyment.useInCracks : isUsedInCracks}
        />
        <div className="legend crack" />
      </div>
      <div className="side-menu__wrapper">
        <Checkbox
          hint="Hint"
          hintMode="hidden"
          name="backwallAnalysis"
          onChange={handleBackwallCheckboxChange}
          title="zones.backwall-analysis"
          checked={selectedZoneId !== null ? zones[selectedZoneId].zoneApplyment.useInBackwall : isUsedInBackwall}
        />
        <div className="legend backwall" />
      </div>
    </div>
  );
};
