import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BASE_DB_KEYS, DATABASE_FIELDS_WITH_CLASS, NON_SELECTABLE_COLUMNS } from '../../consts';
import { DBRangeStrings, DBSliceType, SelectedSnapshots, TreeCheckedKeys } from '../../types';
import { ISliderRange } from '../../types/scanData';
import {
  deleteNestedKeyAndCollapseEmptyProperties,
  getObjectCopyWithoutMethods,
  isObject,
  updateValueOfNestedObject,
} from '../../utils';
import { TFileType } from '../api/scandata/types';
import { dBInitialState, RESULT_TABLE_INIT_STATE } from './consts';

export const databaseSlice = createSlice({
  name: 'database',
  initialState: dBInitialState,
  reducers: {
    setDatabaseName: (state, action: PayloadAction<string>) => {
      state.scanDataFileFilter.name.value = action.payload;
    },
    setDatabaseControllerName: (state, action: PayloadAction<string>) => {
      state.analysisResultDateFilter.controllerName.value = action.payload;
    },
    setDatabaseFileType: (state, action: PayloadAction<TFileType>) => {
      const oldValue = state.scanDataFileFilter.fileType.value;
      const newValue = action.payload;

      if (oldValue === newValue) {
        state.scanDataFileFilter.fileType.value = '';
        return;
      }

      state.scanDataFileFilter.fileType.value = newValue;
    },
    setDatabaseRangeValues: (
      state,
      action: PayloadAction<{ section: string; key: string; range: 'start' | 'end'; value: number | string }>,
    ) => {
      const { section, key, range, value } = action.payload;

      if (DATABASE_FIELDS_WITH_CLASS.includes(key)) {
        //@ts-ignore
        state[section][key].class[range] = value;
        return;
      }
      if (key.includes('.')) {
        const [mainKey, subKey] = key.split('.');
        //@ts-ignore
        state[section][mainKey][subKey][range] = value;
        return;
      }

      //@ts-ignore
      state[section][key][range] = value;
    },
    setDatabaseSliceTypes: (state, action: PayloadAction<DBSliceType>) => {
      if (state.geometryFilter.sliceTypes.includes(action.payload)) {
        state.geometryFilter.sliceTypes = state.geometryFilter.sliceTypes.filter((slice) => slice !== action.payload);
      } else {
        state.geometryFilter.sliceTypes.push(action.payload);
      }
    },
    setDatabaseASTMClasses: (state, action: PayloadAction<{ section: string; key: string; astmClass: string }>) => {
      const { section, key, astmClass } = action.payload;
      //@ts-ignore
      state[section][key][astmClass] = state[section][key][astmClass]
        ? null
        : {
            end: 0,
            start: 0,
          };
    },
    setDatabaseCheckboxes: (state, action: PayloadAction<{ section: string; key: string }>) => {
      const { section, key } = action.payload;

      if (section === 'backwallDistanceFilter') {
        //@ts-ignore
        state.backwallDistanceFilter[key].isUsed = !state.backwallDistanceFilter[key].isUsed;
      }
      if (section === 'cracksFilter') {
        //@ts-ignore
        state.cracksFilter.cracksDetectionFilter[key].isUsed = !state.cracksFilter.cracksDetectionFilter[key].isUsed;
      }
    },
    setDatabaseAdditionalFilters: (
      state,
      action: PayloadAction<{
        stringKeys: string;
        value: Partial<ISliderRange> | Partial<DBRangeStrings> | { value: string } | boolean | string;
      }>,
    ) => {
      const { stringKeys, value } = action.payload;
      const keys = stringKeys.split('-');
      let filterValue = state.additionalFilters;

      keys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(filterValue, key)) {
          //@ts-ignore
          filterValue = filterValue[key];
        } else {
          //@ts-ignore
          filterValue[key] = {};
          //@ts-ignore
          filterValue = filterValue[key];
        }
      });

      // @ts-ignore
      if (isObject(value) && ('start' in value || 'end' in value)) {
        Object.assign(filterValue, value);
        return;
      }

      updateValueOfNestedObject(state.additionalFilters, keys, value);

      // Object.assign(filterValue, value);
    },
    clearDatabaseAdditionalFilters: (state, action: PayloadAction<string>) => {
      const keys = action.payload.split('-');
      state.additionalFilters =
        deleteNestedKeyAndCollapseEmptyProperties(state.additionalFilters, keys) ?? state.additionalFilters;
    },
    resetDatabaseAdditionalFilters: (state) => {
      state.additionalFilters = {};
    },
    resetDatabaseResultTable: (state) => {
      state.resultTable = RESULT_TABLE_INIT_STATE;
    },
    semiResetDatabaseResultTable: (state) => {
      const primaryFiltersState = BASE_DB_KEYS.reduce((acc, key) => {
        // @ts-ignore
        acc[key] = state.resultTable[key];
        return acc;
      }, {});

      state.resultTable = {
        ...RESULT_TABLE_INIT_STATE,
        ...getObjectCopyWithoutMethods(primaryFiltersState),
      };
    },
    updateResultTable: (
      state,
      action: PayloadAction<{
        stringKeys: string;
        value: Partial<{ visible: boolean; disabled: boolean; active: boolean }>;
      }>,
    ) => {
      const { stringKeys, value } = action.payload;
      const keys = stringKeys.split('-');

      let filterValue = state.resultTable;

      keys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(filterValue, key)) {
          //@ts-ignore
          filterValue = filterValue[key];
        } else {
          //@ts-ignore
          filterValue[key] = {};
          //@ts-ignore
          filterValue = filterValue[key];
        }
      });

      Object.assign(filterValue, value);
    },
    updateHeightAnalysisIds: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const { activeIds } = state.heightAnalysis;

      if (activeIds.includes(id)) {
        state.heightAnalysis.activeIds = activeIds.filter((i) => i !== id);
        return;
      }

      activeIds.push(id);
    },
    updateSnapshotIds: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const { analysisResultsIds } = state.snapshots;

      if (analysisResultsIds.includes(id)) {
        state.snapshots.analysisResultsIds = analysisResultsIds.filter((i) => i !== id);
        return;
      }

      analysisResultsIds.push(id);
    },
    setSelectedSnapshotType: (state, action: PayloadAction<keyof SelectedSnapshots>) => {
      const key = action.payload;
      state.snapshots.selectedSnapshotTypes[key] = !state.snapshots.selectedSnapshotTypes[key];
    },
    setActiveSnapshots: (state, action: PayloadAction<TreeCheckedKeys>) => {
      state.snapshots.activeSnapshots = action.payload;
    },
    setSelectedSnapshotName: (state, action: PayloadAction<string | []>) => {
      const names = action.payload;

      if (Array.isArray(names)) {
        state.snapshots.selectedSnapshotNames = names;
        return;
      }

      if (state.snapshots.selectedSnapshotNames.includes(names)) {
        state.snapshots.selectedSnapshotNames = state.snapshots.selectedSnapshotNames.filter((i) => i !== names);
        return;
      }

      state.snapshots.selectedSnapshotNames.push(names);
    },
    updateHeightAnalysisVisibleIds: (state, action: PayloadAction<string | string[]>) => {
      const ids = action.payload;
      const { visibleIds } = state.heightAnalysis;

      if (Array.isArray(ids)) {
        state.heightAnalysis.visibleIds = ids;
        return;
      }

      if (visibleIds.includes(ids)) {
        state.heightAnalysis.visibleIds = visibleIds.filter((i) => i !== ids);
        return;
      }

      visibleIds.push(ids);
    },
    updateDBReportIds: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const selectedReportIds = state.selectedReportIds;

      if (selectedReportIds.includes(id)) {
        state.selectedReportIds = selectedReportIds.filter((i) => i !== id);
        return;
      }

      selectedReportIds.push(id);
    },
    setGraphSelectedColumn: (state, action: PayloadAction<string>) => {
      const selectedColumn = action.payload;
      const selectedColumns = state.graphs.selectedColumns;

      if (NON_SELECTABLE_COLUMNS.includes(selectedColumn)) return;

      if (selectedColumns.includes(selectedColumn)) {
        state.graphs.selectedColumns = selectedColumns.filter((i) => i !== selectedColumn);
        return;
      }

      if (selectedColumns.length === 2) return;

      selectedColumns.push(selectedColumn);
    },
  },
});

export const { actions: databaseActions, reducer: database } = databaseSlice;
