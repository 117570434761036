import './styles.scss';

import React from 'react';

import { FlawArea } from './FlawArea';
import { FlawsControl } from './FlawsControl';
import { FlawStatistics } from './FlawStatistics';
import { FlawsZoneControl } from './FlawsZoneControl';

export const FlawsSideMenu = () => {
  return (
    <div className="flaws__menu">
      <FlawsControl />
      <FlawsZoneControl />
      <FlawArea />
      <FlawStatistics />
    </div>
  );
};
