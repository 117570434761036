import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import { selectVolumeId } from '../../../../store/selectors';
import { selectGeometryCLines } from '../../../../store/selectors/geometry.selector';

export const CLinesRow = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const { leftDeviation, rightDeviation } = useAppSelector((state) => selectGeometryCLines(state, volumeId));

  return (
    <li className="geometry__statistic__item">
      <p className="geometry__statistic__item__title">
        <TranslatedText textKey="geometry.c-lines" /> [mm]:
      </p>
      <p className="geometry__statistic__item__content">
        {`${leftDeviation.toFixed(1)} / ${rightDeviation.toFixed(1)}`}
      </p>
    </li>
  );
};
