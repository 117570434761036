import { AWS_EXPORTS } from './awsExports';

export const LOCALSTORAGE_KEYS = {
  SMSLanguage: '@sms:language',
  SMSTheme: '@sms:theme',
  SMSToken: '@sms:token',
  SMSApplicationCode: '@sms:application_code',
  volumeInfo: 'volumeInfo',
  scanInfo: 'scanInfo',
  expandedKeys: 'expandedKeys',
  checkedKeys: 'checkedKeys',
  filtersTitles: 'filtersTitles',
  lastAuthUser: `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.LastAuthUser`,
  userData: `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.${localStorage.getItem(
    `CognitoIdentityServiceProvider.${AWS_EXPORTS.userPoolAppClientId}.LastAuthUser`,
  )}.userData`,
  ec2InstanceType: 'Ec2InstanceType',
  ec2InstanceId: 'Ec2InstanceId',
  ec2InstanceState: 'Ec2InstanceState',
  backendState: 'backendState',
  unloadTime: 'unloadTime',
  autoAnalysisAccessToken: 'autoAnalysisAccessToken',
  scanDataFiltersDates: 'scanDataFiltersDates',
} as const;
