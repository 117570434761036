import React, { FC } from 'react';

import {
  BASE_RECT_SIDE_SIZE,
  BASE_STROKE_THICK,
  BLACK_PRIMARY,
  RED_PRIMARY,
  SLICE_TYPES,
  WHITE_PRIMARY,
} from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { IGeometryBase } from '../../../../store/api/geometry/types';
import {
  selectGeometryDiagonals,
  selectGeometryRoundDiagonals,
} from '../../../../store/selectors/geometry.selector';
import { TTupleOfTwoNumbers } from '../../../../types';
import {
  getRectOffset,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { GeometryImage } from '../GeometryImage';

interface IProps {
  volumeId: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  filledDomains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
  geometryBase: IGeometryBase;
  sliceType: string;
}

export const GeometryDiagonals: FC<IProps> = ({
  volumeId,
  cartesianModeParams,
  domains,
  geometryBase,
  filledDomains,
  sliceType,
}) => {
  const {
    firstDiagonalPoints,
    firstDiameter,
    secondDiameter,
    secondDiagonalPoints,
  } = useAppSelector((state) =>
    sliceType === SLICE_TYPES.round
      ? selectGeometryRoundDiagonals(state, volumeId)
      : selectGeometryDiagonals(state, volumeId)
  );

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);
  const rectOffset = getRectOffset(BASE_RECT_SIDE_SIZE, BASE_STROKE_THICK);

  return (
    <>
      <GeometryImage
        cartesianModeParams={cartesianModeParams}
        domains={domains}
        filledDomains={filledDomains}
        image={geometryBase.imageBase64}
      />
      {firstDiagonalPoints.map(({ x, y }, idx) => (
        <rect
          key={idx}
          x={getXOffset(x) - rectOffset}
          y={getYOffset(y) - rectOffset}
          width={BASE_RECT_SIDE_SIZE}
          height={BASE_RECT_SIDE_SIZE}
          fill={RED_PRIMARY}
        />
      ))}
      {secondDiagonalPoints.map(({ x, y }, idx) => (
        <rect
          key={idx}
          x={getXOffset(x) - rectOffset}
          y={getYOffset(y) - rectOffset}
          width={BASE_RECT_SIDE_SIZE}
          height={BASE_RECT_SIDE_SIZE}
          fill={RED_PRIMARY}
        />
      ))}
      <line
        x1={getXOffset(firstDiagonalPoints[0]?.x)}
        y1={getYOffset(firstDiagonalPoints[0]?.y)}
        x2={getXOffset(firstDiagonalPoints[1]?.x)}
        y2={getYOffset(firstDiagonalPoints[1]?.y)}
        stroke={BLACK_PRIMARY}
      />
      <line
        x1={getXOffset(secondDiagonalPoints[0]?.x)}
        y1={getYOffset(secondDiagonalPoints[0]?.y)}
        x2={getXOffset(secondDiagonalPoints[1]?.x)}
        y2={getYOffset(secondDiagonalPoints[1]?.y)}
        stroke={BLACK_PRIMARY}
      />
      <text
        x={getXOffset(firstDiagonalPoints[0]?.x) + 50}
        y={getYOffset(firstDiagonalPoints[0]?.y) + 30}
        fill={WHITE_PRIMARY}
      >
        {`D1 = ${firstDiameter.toFixed(2)} mm`}
      </text>
      <text
        x={getXOffset(secondDiagonalPoints[1]?.x) + 50}
        y={getYOffset(secondDiagonalPoints[1]?.y) - 30}
        fill={WHITE_PRIMARY}
      >
        {`D2 = ${secondDiameter.toFixed(2)} mm`}
      </text>
    </>
  );
};
