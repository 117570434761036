import React from 'react';

import { useGetDbHeightAnalysisMutation } from '../../../store/api/database';
import { HeightAnalysisController } from './components/HeightAnalysisContoller';
import { HeightAnalysisWindow } from './components/HeightAnalysisWindow';

export const HeightAnalysisPage = () => {
  const [, { data: heightAnalysisData }] = useGetDbHeightAnalysisMutation({ fixedCacheKey: 'dbHeightAnalysis' });

  return (
    <div className="page-container db-container height-analysis-container">
      <HeightAnalysisController data={heightAnalysisData} />
      {heightAnalysisData && <HeightAnalysisWindow data={heightAnalysisData} />}
    </div>
  );
};
