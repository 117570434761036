import React, { FC } from 'react';

import { TCrackType } from '../../../../../../store/api/cracks/types';

interface IProps {
  title: TCrackType | React.ReactNode;
  count: number | string | React.ReactNode;
  cracksClass: number | string | React.ReactNode;
  cracksNSClass: number | string | React.ReactNode | null;
}

export const StatisticsTableRow: FC<IProps> = ({ title, count, cracksClass, cracksNSClass }) => {
  return (
    <div className="entire__statistics__table__row">
      <p>{title}</p>
      <p>{count}</p>
      <p>{cracksClass === 4.1 ? '>4' : cracksClass}</p>
      {cracksNSClass !== null && <p>{cracksNSClass}</p>}
    </div>
  );
};
