import {
  Button,
  Checkbox,
  CheckInfo,
  DataNode,
  NumberInput,
  Select,
  TextInput,
  TranslatedText,
  Tree,
} from '@sms/plasma-ui';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import {
  BACKWALL_DISTANCE_SIZE_PARAMETER_SELECT_DATA,
  BACKWALL_DISTANCE_ZONE_FILTER,
  BACKWALL_DISTANCE_ZONE_TYPE_SELECT_DATA,
  BACKWALL_ZONES_TYPES,
  BILLET_CUT_SHAPE_SELECT_DATA,
  CRACK_TYPE_SELECT_DATA,
  CRACKS_TYPES,
  DATABASE_ADDITIONAL_FILTERS_TITLES,
  LOCALSTORAGE_KEYS,
  SHAPES,
  SINGULAR_CRACK_FILTER,
  SINGULAR_CRACK_POSITION_SELECT_DATA,
} from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useSaveDatabaseMutation, useUpdateDBAdditionalFiltersMutation } from '../../../store/api/database';
import { selectDBAdditionalFilters } from '../../../store/selectors';
import { DBAdditionalFilter, ReactKeys, TreeCheckedKeys, typedEntries } from '../../../types';
import {
  getDateWithTime,
  getFromLocalStorage,
  getObjectCopyWithoutMethods,
  getValueOfNestedObject,
  isNumber,
  isString,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../../../utils';
import { NumberInputValue } from '../../RangeBar';
import { DateInput } from '../../UI/DateInput';
import { FilterWrapper } from './FilterWrapper';
import { TitleWithControl } from './TitleWithControl';

const { expandedKeys: LSExpandedKeys, checkedKeys: LSCheckedKeys, filtersTitles: LSFiltersTitles } = LOCALSTORAGE_KEYS;

export const AdditionalFilters = () => {
  const {
    setDatabaseAdditionalFilters,
    clearDatabaseAdditionalFilters,
    resetDatabaseAdditionalFilters,
    resetDatabaseResultTable,
  } = useActionCreators();
  const [filtersTitles, setFiltersTitles] = useState(
    (getFromLocalStorage(LSFiltersTitles) as typeof DATABASE_ADDITIONAL_FILTERS_TITLES) ??
      getObjectCopyWithoutMethods(DATABASE_ADDITIONAL_FILTERS_TITLES),
  );
  const [expandedKeys, setExpandedKeys] = useState<ReactKeys>((getFromLocalStorage(LSExpandedKeys) as ReactKeys) ?? []);
  const [checkedKeys, setCheckedKeys] = useState<TreeCheckedKeys>(
    (getFromLocalStorage(LSCheckedKeys) as TreeCheckedKeys) ?? { checked: [], halfChecked: [] },
  );
  const additionalFilters = useAppSelector(selectDBAdditionalFilters);
  const [, { data: databaseResults }] = useSaveDatabaseMutation({ fixedCacheKey: 'databaseResults' });
  const [updateFiltersTrigger, { reset }] = useUpdateDBAdditionalFiltersMutation({
    fixedCacheKey: 'additionalFiltersResults',
  });

  useEffect(() => {
    return () => {
      // @ts-ignore
      setToLocalStorage(LSExpandedKeys, expandedKeys);
      // @ts-ignore
      setToLocalStorage(LSCheckedKeys, checkedKeys);
      setToLocalStorage(LSFiltersTitles, filtersTitles);
    };
  }, [expandedKeys, checkedKeys, filtersTitles]);

  const handleTreeExpand = useCallback((keys: ReactKeys) => {
    setExpandedKeys(keys);
  }, []);

  const addFilter = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.name === 'Singular-crack') {
      setFiltersTitles((prev) => ({
        ...prev,
        cracksFilter: {
          ...prev.cracksFilter,
          filters: {
            ...prev.cracksFilter.filters,
            singularCracksFilters: {
              ...prev.cracksFilter.filters.singularCracksFilters,
              filters: [...prev.cracksFilter.filters.singularCracksFilters.filters, SINGULAR_CRACK_FILTER],
            },
          },
        },
      }));
    }

    if (e.currentTarget.name === 'Backwall-distance-zones') {
      setFiltersTitles((prev) => ({
        ...prev,
        backwallDistanceFilter: {
          ...prev.backwallDistanceFilter,
          filters: {
            ...prev.backwallDistanceFilter.filters,
            zoneFilters: {
              ...prev.backwallDistanceFilter.filters.zoneFilters,
              filters: [...prev.backwallDistanceFilter.filters.zoneFilters.filters, BACKWALL_DISTANCE_ZONE_FILTER],
            },
          },
        },
      }));
    }
  };

  const handleTreeCheck = useCallback((keys: TreeCheckedKeys | ReactKeys, info: CheckInfo) => {
    if (Array.isArray(keys)) {
      setCheckedKeys(({ checked: prevCheckedKeys, halfChecked: prevHalfCheckedKeys }) => {
        if (prevCheckedKeys.length > keys.length && Array.isArray(info.halfCheckedKeys)) {
          let excludedKeys = prevCheckedKeys.filter(
            (key) => !keys.includes(key) && !info.halfCheckedKeys?.includes(key),
          );

          if (info.halfCheckedKeys.length < prevHalfCheckedKeys.length) {
            excludedKeys = excludedKeys.concat(
              ...prevHalfCheckedKeys.filter((key) => !info.halfCheckedKeys?.includes(key)),
            );
          }

          excludedKeys.forEach((key) => {
            clearDatabaseAdditionalFilters(key.toString());
          });
        }

        if (prevCheckedKeys.length < keys.length) {
          const newKeys = keys.filter((key) => !prevCheckedKeys.includes(key) && !prevHalfCheckedKeys.includes(key));
          newKeys.forEach((key) => {
            const stringKey = key.toString();
            if (stringKey.includes('billetCutShape')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: { value: SHAPES.rect } });
              return;
            }

            if (stringKey.includes('crackType')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: { value: CRACKS_TYPES.longitudinal } });
              return;
            }

            if (stringKey.includes('position')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: { value: 'Top' } });
              return;
            }

            if (stringKey.includes('zoneType')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: { value: BACKWALL_ZONES_TYPES.dendritic } });
              return;
            }

            if (stringKey.includes('sizeParameter')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: { value: 'Width' } });
              return;
            }

            if (
              stringKey.includes('producer') ||
              stringKey.includes('site') ||
              stringKey.includes('plant') ||
              stringKey.includes('steelGrade')
            ) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: { value: '' } });
              return;
            }

            if (stringKey.includes('includeRemarks')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: true });
              return;
            }

            if (stringKey.includes('preAmplificationFlawSensor') || stringKey.includes('preAmplificationBackSensor')) {
              setDatabaseAdditionalFilters({ stringKeys: stringKey, value: false });
              return;
            }

            setDatabaseAdditionalFilters({ stringKeys: stringKey, value: {} });
          });
        }

        return { checked: keys, halfChecked: info.halfCheckedKeys ?? [] };
      });
      return;
    }

    setCheckedKeys(keys);
  }, []);

  const handleNumberInputChange = useCallback(
    (value: NumberInputValue, stringKeys: string, position: 'start' | 'end') => {
      if (!isNumber(value)) return;
      setDatabaseAdditionalFilters({ stringKeys, value: { [position]: value } });
    },
    [],
  );

  const handleFilterClick = useCallback(async () => {
    await updateFiltersTrigger();
    setExpandedKeys((prev) => prev.filter((key) => key !== 'additionalFilters'));
  }, []);

  const handleFilterReset = useCallback(() => {
    resetDatabaseAdditionalFilters();
    resetDatabaseResultTable();
    setCheckedKeys({ checked: [], halfChecked: [] });
    setExpandedKeys([]);
    removeFromLocalStorage(LSExpandedKeys);
    removeFromLocalStorage(LSCheckedKeys);
    removeFromLocalStorage(LSFiltersTitles);
    reset();
  }, []);

  const handleDateChange = (value: string, pointer: 'start' | 'end', keys: string) => {
    const startDateString = getValueOfNestedObject(additionalFilters, keys)?.start;
    const endDateString = getValueOfNestedObject(additionalFilters, keys)?.end;
    const newValue = getDateWithTime(value, pointer);

    if (pointer === 'start' && (!endDateString || new Date(endDateString) < new Date(value))) {
      setDatabaseAdditionalFilters({ stringKeys: keys, value: { end: newValue } });
    }

    if (pointer === 'end' && (!startDateString || new Date(startDateString) > new Date(value))) {
      setDatabaseAdditionalFilters({ stringKeys: keys, value: { start: newValue } });
    }

    setDatabaseAdditionalFilters({ stringKeys: keys, value: { [pointer]: newValue } });
  };

  const handleDateClear = (pointer: 'start' | 'end', keys: string) => {
    setDatabaseAdditionalFilters({ stringKeys: keys, value: { [pointer]: '' } });
  };

  const renderSelectableFilter = (dataSource: { label: string; value: string }[], title: string, key: string) => (
    <FilterWrapper stringKey={key} title={title} checkedKeys={checkedKeys}>
      <Select
        dataSource={dataSource}
        dropdownMatchSelectWidth
        value={getValueOfNestedObject(additionalFilters, key)?.value ?? undefined}
        listHeight={256}
        name={key}
        onChange={(value) => setDatabaseAdditionalFilters({ stringKeys: key, value: { value } })}
        style={{
          width: 200,
        }}
      />
    </FilterWrapper>
  );

  const renderTextFilter = (title: string, key: string) => (
    <FilterWrapper stringKey={key} title={title} checkedKeys={checkedKeys}>
      <TextInput
        value={getValueOfNestedObject(additionalFilters, key)?.value ?? ''}
        name={key}
        label={''}
        onChange={(e) => setDatabaseAdditionalFilters({ stringKeys: key, value: { value: e.target.value } })}
      />
    </FilterWrapper>
  );

  const renderCheckableFilter = (title: string, key: string) => (
    <FilterWrapper stringKey={key} title={title} checkedKeys={checkedKeys}>
      <Checkbox
        name={key}
        checked={getValueOfNestedObject(additionalFilters, key) ?? false}
        onChange={(e) => setDatabaseAdditionalFilters({ stringKeys: key, value: e.target.checked })}
      />
    </FilterWrapper>
  );

  const renderDateRangeFilter = (title: string, key: string) => (
    <FilterWrapper stringKey={key} title={title} checkedKeys={checkedKeys}>
      <>
        <DateInput
          label={'modals.file-loader.start-date'}
          value={getValueOfNestedObject(additionalFilters, key)?.start ?? ''}
          name={'startDate'}
          onChange={(v) => handleDateChange(v, 'start', key)}
          onClear={() => handleDateClear('start', key)}
        />
        <DateInput
          label={'modals.file-loader.end-date'}
          value={getValueOfNestedObject(additionalFilters, key)?.end ?? ''}
          name={'endDate'}
          onChange={(v) => handleDateChange(v, 'end', key)}
          onClear={() => handleDateClear('end', key)}
        />
      </>
    </FilterWrapper>
  );

  const renderNumberRangeFilter = (title: string, key: string) => (
    <FilterWrapper stringKey={key} title={title} checkedKeys={checkedKeys}>
      <>
        <NumberInput
          name={`${key}-from`}
          value={getValueOfNestedObject(additionalFilters, key)?.start}
          placeholder={'min'}
          label={'common.from'}
          onChange={(value) => {
            handleNumberInputChange(value, key, 'start');
          }}
        />
        <NumberInput
          name={`${key}-to`}
          value={getValueOfNestedObject(additionalFilters, key)?.end}
          placeholder={'max'}
          label={'common.to'}
          onChange={(value) => {
            handleNumberInputChange(value, key, 'end');
          }}
        />
      </>
    </FilterWrapper>
  );

  const renderTitle = (key: string, value: string | DBAdditionalFilter, newKey: string) => {
    if (!isString(value)) {
      if (isNumber(+key)) {
        return key;
      }

      switch (key) {
        case 'singularCracksFilters': {
          return (
            <TitleWithControl title={value.title}>
              <Button
                name={'Singular-crack'}
                disabled={!expandedKeys.includes(newKey)}
                title={'Add'}
                onClick={addFilter}
              />
            </TitleWithControl>
          );
        }
        case 'zoneFilters': {
          return (
            <TitleWithControl title={value.title}>
              <Button
                name={'Backwall-distance-zones'}
                disabled={!expandedKeys.includes(newKey)}
                title={'Add'}
                onClick={addFilter}
              />
            </TitleWithControl>
          );
        }
        default:
          return <TranslatedText textKey={value.title} />;
      }
    }

    switch (key) {
      case 'includeRemarks': {
        return <TranslatedText textKey={value} />;
      }
      case 'productionDate': {
        return renderDateRangeFilter(value, newKey);
      }
      case 'billetCutShape': {
        return renderSelectableFilter(BILLET_CUT_SHAPE_SELECT_DATA, value, newKey);
      }
      case 'crackType': {
        return renderSelectableFilter(CRACK_TYPE_SELECT_DATA, value, newKey);
      }
      case 'position': {
        return renderSelectableFilter(SINGULAR_CRACK_POSITION_SELECT_DATA, value, newKey);
      }
      case 'zoneType': {
        return renderSelectableFilter(BACKWALL_DISTANCE_ZONE_TYPE_SELECT_DATA, value, newKey);
      }
      case 'sizeParameter': {
        return renderSelectableFilter(BACKWALL_DISTANCE_SIZE_PARAMETER_SELECT_DATA, value, newKey);
      }
      case 'preAmplificationFlawSensor': {
        return renderCheckableFilter(value, newKey);
      }
      case 'preAmplificationBackSensor': {
        return renderCheckableFilter(value, newKey);
      }
      case 'producer': {
        return renderTextFilter(value, newKey);
      }
      case 'site': {
        return renderTextFilter(value, newKey);
      }
      case 'steelGrade': {
        return renderTextFilter(value, newKey);
      }
      default:
        return renderNumberRangeFilter(value, newKey);
    }
  };

  function renderAdditionalFilters(obj: Record<string, string | DBAdditionalFilter>, initKey = ''): DataNode[] {
    return typedEntries(obj).map(([key, value]) => {
      const newKey = `${initKey}${initKey ? '-' : ''}${key}`;

      return {
        key: newKey,
        checkable: true,
        disabled: false,
        title: renderTitle(key, value, newKey),
        children: !isString(value) ? renderAdditionalFilters(value.filters, newKey) : undefined,
      };
    });
  }

  return (
    <Tree
      checkable
      selectable={false}
      expandedKeys={expandedKeys}
      checkedKeys={checkedKeys}
      onExpand={handleTreeExpand}
      onCheck={handleTreeCheck}
      treeData={[
        {
          children: [
            // @ts-ignore
            ...renderAdditionalFilters(filtersTitles),
            {
              key: 'AdditionalFiltersControls',
              checkable: false,
              title: (
                <div className="db__additional-filters__controls">
                  <Button
                    name={'Geometry-filter'}
                    disabled={!databaseResults || !databaseResults.length}
                    title={'Filter'}
                    onClick={handleFilterClick}
                  />
                  <Button name={'Reset-filter'} title={'Reset'} onClick={handleFilterReset} />
                </div>
              ),
            },
          ],
          key: 'additionalFilters',
          title: <TranslatedText textKey="Additional filters" />,
        },
      ]}
    />
  );
};
