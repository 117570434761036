import React, { FC } from 'react';

import { ORANGE_PRIMARY } from '../../../../../../consts';
import { TLineCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import { getBaseSvgElemStyles, getXOffsetFunc, getYOffsetFunc, ICartesianModeParams } from '../../../../../../utils';

interface IProps {
  data: TLineCoordinates;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  billetCutDx?: number;
  billetCutDy?: number;
}

export const Line: FC<IProps> = ({ data, cartesianModeParams, domains, billetCutDx = 0, billetCutDy = 0 }) => {
  const {
    start: { x: x1, y: y1 },
    end: { x: x2, y: y2 },
  } = data;

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  return (
    <line
      x1={getXOffset(x1 + billetCutDx)}
      y1={getYOffset(y1 + billetCutDy)}
      x2={getXOffset(x2 + billetCutDx)}
      y2={getYOffset(y2 + billetCutDy)}
      style={getBaseSvgElemStyles(ORANGE_PRIMARY, 1)}
    />
  );
};
