import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: number[] = [];

export const prevAScanDataSlice = createSlice({
  name: 'prevAScanData',
  initialState,
  reducers: {
    setAScanData: (state, action: PayloadAction<number[]>) => {
      return action.payload;
    },
  },
});

export const { actions: prevAScanDataActions, reducer: prevAScanData } = prevAScanDataSlice;
