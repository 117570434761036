import './styles.scss';

import React from 'react';

import { DatabaseForm } from '../../components/DatabaseForm';

export const Database = () => {
  return (
    <div className="page-container db-container">
      <DatabaseForm />
    </div>
  );
};
