import './styles.scss';

import { Button, NumberInput, TranslatedText, useTranslation } from '@sms/plasma-ui';
import React, { useMemo } from 'react';

import { useActionCreators, useAppSelector, useSegregation } from '../../../hooks';
import {
  selectSegThreshold,
  selectSegZoneDiameter,
  selectSegZoneHeight,
  selectSegZoneWidth,
} from '../../../store/selectors';
import { debounce } from '../../../utils';
import { NumberInputValue } from '../../RangeBar';

export const SegSettings = () => {
  const { t } = useTranslation();

  const { setSegThreshold, setSegZoneHeight, setSegZoneWidth, setSegZoneDiameter } = useActionCreators();
  const threshold = useAppSelector(selectSegThreshold);
  const zoneWidth = useAppSelector(selectSegZoneWidth);
  const zoneHeight = useAppSelector(selectSegZoneHeight);
  const zoneDiameter = useAppSelector(selectSegZoneDiameter);
  const {
    handleSegParamsChange,
    handleZoneHeightChange,
    handleBilletRectChange,
    handleBilletRoundChange,
    isRound,
    isRect,
  } = useSegregation();

  const debouncedParamsChangeHandler = useMemo(
    () => debounce((newThreshold: number) => handleSegParamsChange({ newThreshold })),
    [handleSegParamsChange]
  );
  const debouncedHeightChangeHandler = useMemo(() => debounce((value: number) => handleZoneHeightChange(value)), []);
  const debouncedWidthChangeHandler = useMemo(
    () => debounce((zoneWidth: number) => handleBilletRectChange({ zoneWidth })),
    [handleBilletRectChange]
  );
  const debouncedDiameterChangeHandler = useMemo(
    () => debounce((zoneDiameter: number) => handleBilletRoundChange({ zoneDiameter })),
    [handleBilletRoundChange]
  );

  const handleThresholdNumberInputChange = (value: NumberInputValue) => {
    if (value === undefined) return;

    setSegThreshold(+value);
    debouncedParamsChangeHandler(+value);
  };

  const handleSegHeightNumberInputChange = (value: NumberInputValue) => {
    if (value === undefined) return;

    setSegZoneHeight(+value);
    debouncedHeightChangeHandler(+value);
  };

  const handleSegWidthNumberInputChange = (value: NumberInputValue) => {
    if (value === undefined) return;

    setSegZoneWidth(+value);
    debouncedWidthChangeHandler(+value);
  };

  const handleSegDiameterNumberInputChange = (value: NumberInputValue) => {
    if (value === undefined) return;

    setSegZoneDiameter(+value);
    debouncedDiameterChangeHandler(+value);
  };

  return (
    <div className="segregation__menu__settings">
      <h3 className="segregation__menu__title side-menu__title">
        <TranslatedText textKey="pages.settings" />
      </h3>
      <div className="segregation__menu__settings__controls">
        <NumberInput
          name="segregationThreshold"
          decimalSeparator={'.'}
          step={1}
          hintMode="hidden"
          label={'Threshold'}
          value={threshold}
          onChange={handleThresholdNumberInputChange}
        />
        {!isRound && (
          <NumberInput
            name="segregationHeight"
            decimalSeparator={'.'}
            precision={2}
            step={1}
            hintMode="hidden"
            label={t('dimensions.height', { unit: '[mm]' })}
            value={zoneHeight}
            onChange={handleSegHeightNumberInputChange}
          />
        )}
        {isRect && (
          <NumberInput
            name="segregationWidth"
            decimalSeparator={'.'}
            precision={2}
            step={1}
            hintMode="hidden"
            label={t('dimensions.width', { unit: '[mm]' })}
            value={zoneWidth}
            onChange={handleSegWidthNumberInputChange}
          />
        )}
        {isRound && (
          <NumberInput
            name="segregationDiameter"
            decimalSeparator={'.'}
            precision={2}
            step={1}
            hintMode="hidden"
            label={t('dimensions.diameter', { unit: '[mm]' })}
            value={zoneDiameter}
            onChange={handleSegDiameterNumberInputChange}
          />
        )}
      </div>
      <Button
        className="auto-threshold-btn"
        title={'side-menu.auto-threshold'}
        onClick={() => handleSegParamsChange({ newThreshold: -1 })}
      />
    </div>
  );
};
