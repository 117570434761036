import './styles.scss';

import { Modal, TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { MODAL_ICONS } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { selectModals } from '../../../store/selectors';

export const MessageModal: FC = () => {
  const { message } = useAppSelector(selectModals);
  const { setMessageModal } = useActionCreators();

  const onOk = () => {
    setMessageModal(false);
  };

  if (!message) return null;

  const { title, description, type } = message;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      okText="OK"
      okType="primary"
      onOk={onOk}
      //@ts-ignore
      title={<TranslatedText textKey={title} />}
      onCancel={onOk}
      visible={!!message}
      width={460}
      zIndex={15}
      className={'message__modal'}
    >
      {MODAL_ICONS[type]}
      <p>
        <TranslatedText textKey={description} />
      </p>
    </Modal>
  );
};
