import { Action, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { middleware, reducer, reducerPath } from './api';
import {
  adaptImage,
  analysisStatus,
  backwall,
  backwallFlaws,
  bcdplusPrevState,
  billetCutParams,
  cracks,
  cRangeForLoadedGeometry,
  croppedImages,
  database,
  flaws,
  flipped,
  forceAnalysisChange,
  info,
  modals,
  modalsActions,
  palette,
  prevAScanData,
  prevSliceImages,
  recipes,
  remarks,
  report,
  sampleFileName,
  scanDataFilters,
  segregation,
  settings,
  sliceType,
  sliderRanges,
  snapshots,
  toolbarOptions,
  user,
  volumeInfo,
  zones,
} from './slices';
import { TypedRootState } from './types';

export const combinedReducer = combineReducers({
  [reducerPath]: reducer,
  analysisStatus,
  volumeInfo,
  sampleFileName,
  sliceType,
  forceAnalysisChange,
  flipped,
  palette,
  cRangeForLoadedGeometry,
  bcdplusPrevState,
  sliderRanges,
  toolbarOptions,
  billetCutParams,
  remarks,
  report,
  segregation,
  flaws,
  cracks,
  backwall,
  backwallFlaws,
  zones,
  adaptImage,
  modals,
  snapshots,
  info,
  settings,
  database,
  recipes,
  scanDataFilters,
  prevSliceImages,
  prevAScanData,
  user,
  croppedImages,
});

const rootReducer: Reducer = (state: TypedRootState, action: Action) => {
  if (action.type === 'logout') {
    state = {} as TypedRootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [modalsActions.setMessageModal.type],
        ignoredPaths: ['modals.spinner.cancelCb'],
      },
    }).concat(middleware),
});

setupListeners(store.dispatch);
