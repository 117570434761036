import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DEFAULT_FLAWS_ZONE_VALUE,
  RECIPE_ANALYSIS_TYPES_KEYS,
  RECIPE_ANALYSIS_TYPES_NUM,
  SEP1_NUM,
  SEP100_NUM,
  SLICE_TYPE_NUM,
  TYPE_DISCRIMINATOR,
} from '../../consts';
import { IAnalysisStages, IRecipeFlawZone, TSliceType, TTypeDiscriminator, typedEntries } from '../../types';
import { mergeArraysUnique, removeDuplicates } from '../../utils';
import { IRecipeResponse, IRecipeTemplateInfo } from '../api/recipes/types';
import { IScanData, IScanDataProd } from '../api/scandata/types';

const {
  segregationsAnalysisStageModel,
  flawsAnalysisStageModel,
  cracksAnalysisStageModel,
  backwallAnalysisStageModel,
} = TYPE_DISCRIMINATOR;
const BACKWALL_FLAWS_ANALYSIS_NUM = RECIPE_ANALYSIS_TYPES_NUM[RECIPE_ANALYSIS_TYPES_KEYS.Flaws];

interface IRecipeState {
  sliceType: number;
  id: string;
  name: string;
  description: string;
  isSEPIncluded: boolean;
  performAnalysis: Record<TTypeDiscriminator, boolean>;
  analysisStages: IAnalysisStages;
  selectedFiles: (IScanData | IScanDataProd)[];
  createdAt: string;
  modifiedAt: string;
  selectedRecipes: IRecipeResponse[];
}

const initialState: IRecipeState = {
  id: '',
  name: '',
  description: '',
  createdAt: '',
  modifiedAt: '',
  sliceType: SLICE_TYPE_NUM['Slab Middle'],
  isSEPIncluded: false,
  performAnalysis: {
    [cracksAnalysisStageModel]: true,
    [segregationsAnalysisStageModel]: true,
    [flawsAnalysisStageModel]: true,
    [backwallAnalysisStageModel]: true,
  },
  analysisStages: {
    [cracksAnalysisStageModel]: {
      StartRange: 6,
      EndRange: 11,
      ManualThreshold: 7000,
      IsAutoThreshold: true,
      LeftRightMargin: 0,
      IsCfgMargin: true,
      SelectedAnalysisTypes: [],
      Palette: {
        RangeStart: 1,
        RangeEnd: 32767,
        Clipped: false,
        Monochrome: true,
        Inverse: false,
      },
    },
    [segregationsAnalysisStageModel]: {
      ZoneHeight: 30,
      ZoneFraction: 0.25,
      IsPoresIncluded: true,
      StartRange: 6,
      EndRange: 11,
      ManualThreshold: 7000,
      IsAutoThreshold: true,
      LeftRightMargin: 0,
      ZoneDiameter: 30,
      IsCfgMargin: true,
      SelectedAnalysisTypes: [],
      Palette: {
        RangeStart: 1,
        RangeEnd: 32767,
        Clipped: false,
        Monochrome: true,
        Inverse: false,
      },
    },
    [flawsAnalysisStageModel]: {
      IsAutoZones: true,
      SmsZone1: null,
      SmsZone2: null,
      StartRange: 6,
      EndRange: 11,
      ManualThreshold: 7000,
      IsAutoThreshold: true,
      LeftRightMargin: 0,
      IsCfgMargin: true,
      SelectedAnalysisTypes: [],
      Palette: {
        RangeStart: 1,
        RangeEnd: 32767,
        Clipped: false,
        Monochrome: true,
        Inverse: false,
      },
    },
    [backwallAnalysisStageModel]: {
      StartRange: 19,
      EndRange: 21,
      LowerThreshold: 3000,
      UpperThreshold: 25000,
      IsAutoThreshold: false,
      SelectedAnalysisTypes: [],
      LeftRightMargin: 0,
      IsCfgMargin: true,
      Palette: {
        RangeStart: 1,
        RangeEnd: 32767,
        Clipped: false,
        Monochrome: true,
        Inverse: false,
      },
    },
  },
  selectedFiles: [],
  selectedRecipes: [],
};

export const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setRecipeInitialInfo: (state, action: PayloadAction<IRecipeTemplateInfo>) => {
      Object.assign(state, {
        ...action.payload,
        analysisStages: { ...initialState.analysisStages, ...action.payload.analysisStages },
        selectedFiles: state.selectedFiles,
        selectedRecipes: state.selectedRecipes,
      });
    },
    setRecipeSliceType: (state, action: PayloadAction<Exclude<TSliceType, 'Billet Cut'>>) => {
      state.sliceType = SLICE_TYPE_NUM[action.payload];
    },
    setRecipeMainData: (state, action: PayloadAction<{ field: string; value: string }>) => {
      const { field, value } = action.payload;
      state = Object.assign(state, { [field]: value });
    },
    setRecipePerformAnalysis: (state, action: PayloadAction<TTypeDiscriminator>) => {
      const typeDiscriminator = action.payload;
      state.performAnalysis[typeDiscriminator] = !state.performAnalysis[typeDiscriminator];
    },
    setRecipeSEPIncluded: (state) => {
      state.isSEPIncluded = !state.isSEPIncluded;

      const analysisState = state.analysisStages[segregationsAnalysisStageModel].SelectedAnalysisTypes;

      if (state.isSEPIncluded) {
        analysisState.push(SEP100_NUM);
      } else {
        state.analysisStages[segregationsAnalysisStageModel].SelectedAnalysisTypes = analysisState.filter(
          (val) => val !== SEP1_NUM && val !== SEP100_NUM,
        );
      }
    },
    setSelectedAnalysisTypes: (
      state,
      action: PayloadAction<{ typeDiscriminator: TTypeDiscriminator; value: number }>,
    ) => {
      const { typeDiscriminator, value } = action.payload;
      const analysisState = state.analysisStages[typeDiscriminator].SelectedAnalysisTypes;

      if (value === SEP1_NUM) {
        state.analysisStages[typeDiscriminator].SelectedAnalysisTypes = analysisState
          .filter((n) => n !== SEP100_NUM)
          .concat(SEP1_NUM);
        return;
      }

      if (value === SEP100_NUM) {
        state.analysisStages[typeDiscriminator].SelectedAnalysisTypes = analysisState
          .filter((n) => n !== SEP1_NUM)
          .concat(SEP100_NUM);
        return;
      }

      if (analysisState.includes(value)) {
        state.analysisStages[typeDiscriminator].SelectedAnalysisTypes = analysisState.filter((n) => n !== value);
      } else {
        analysisState.push(value);
      }
    },
    setRecipeField: (
      state,
      action: PayloadAction<{
        section: string;
        id: string;
        value: string | number | boolean;
      }>,
    ) => {
      const { section, id, value } = action.payload;

      if (id === 'ZoneHeight' && state.sliceType === SLICE_TYPE_NUM['Billet Round']) {
        // @ts-ignore
        state.analysisStages[section].ZoneDiameter = value;
      }

      if (id === 'IsAutoZones') {
        state.analysisStages[flawsAnalysisStageModel].SmsZone1 = value ? null : DEFAULT_FLAWS_ZONE_VALUE;
        state.analysisStages[flawsAnalysisStageModel].SmsZone2 = value ? null : DEFAULT_FLAWS_ZONE_VALUE;
      }

      if (id === 'LeftRightMargin' || id === 'IsCfgMargin') {
        for (const typeDiscriminator in state.analysisStages) {
          // @ts-ignore
          state.analysisStages[typeDiscriminator][id] = value;
        }
        return;
      }

      if (id.includes('.')) {
        const [mainKey, subKey] = id.split('.');
        // @ts-ignore
        state.analysisStages[section][mainKey][subKey] = value;
        return;
      }
      // @ts-ignore
      state.analysisStages[section][id] = value;
    },
    setRecipeFlawsZones: (
      state,
      action: PayloadAction<{
        SmsZone1: IRecipeFlawZone | null;
        SmsZone2: IRecipeFlawZone | null;
      }>,
    ) => {
      const { SmsZone1, SmsZone2 } = action.payload;
      state.analysisStages[flawsAnalysisStageModel].SmsZone1 = SmsZone1;
      state.analysisStages[flawsAnalysisStageModel].SmsZone2 = SmsZone2;
    },
    resetRecipeState: (state) => {
      Object.assign(state, initialState);
    },
    setRecipeSelectedFile: (state, action: PayloadAction<IScanData | IScanDataProd>) => {
      const newFile = action.payload;
      if (state.selectedFiles.find((file) => file.id === newFile.id)) {
        state.selectedFiles = state.selectedFiles.filter((file) => file.id !== newFile.id);

        return;
      }

      state.selectedFiles.push(newFile);
    },
    setRecipeSelectedFilesArray: (
      state,
      action: PayloadAction<{ items: Array<IScanData | IScanDataProd>; actionType: 'add' | 'remove' }>,
    ) => {
      const { items, actionType } = action.payload;
      const selectedFiles = state.selectedFiles;

      const actionFunc = actionType === 'add' ? mergeArraysUnique : removeDuplicates;

      state.selectedFiles = actionFunc(selectedFiles, items);
    },
    resetRecipeSelectedFile: (state) => {
      state.selectedFiles = [];
    },
    setRecipeSelectedRecipe: (state, action: PayloadAction<IRecipeResponse>) => {
      const newRecipe = action.payload;
      if (state.selectedRecipes.find((recipe) => recipe.id === newRecipe.id)) {
        state.selectedRecipes = state.selectedRecipes.filter((recipe) => recipe.id !== newRecipe.id);

        return;
      }

      state.selectedRecipes.push(newRecipe);
    },
    setRecipeSelectedRecipesArray: (
      state,
      action: PayloadAction<{ items: Array<IRecipeResponse>; actionType: 'add' | 'remove' }>,
    ) => {
      const { items, actionType } = action.payload;
      const selectedRecipes = state.selectedRecipes;

      const actionFunc = actionType === 'add' ? mergeArraysUnique : removeDuplicates;

      state.selectedRecipes = actionFunc(selectedRecipes, items);
    },
    resetRecipeSelectedRecipe: (state) => {
      state.selectedRecipes = [];
    },
    resetRecipeAnalysisTypes: (state) => {
      typedEntries(state.analysisStages).forEach(([typeDiscriminator]) => {
        if (typeDiscriminator === backwallAnalysisStageModel) return;

        state.analysisStages[typeDiscriminator].SelectedAnalysisTypes = [];
      });
    },
    setRecipeBackwallFlawsAnalysis: (state) => {
      const backwallSelectedAnalysis = state.analysisStages[backwallAnalysisStageModel].SelectedAnalysisTypes;
      const segSelectedAnalysis = state.analysisStages[segregationsAnalysisStageModel].SelectedAnalysisTypes;
      const cracksSelectedAnalysis = state.analysisStages[cracksAnalysisStageModel].SelectedAnalysisTypes;

      const isBackwallFlawsIncluded = backwallSelectedAnalysis.includes(BACKWALL_FLAWS_ANALYSIS_NUM);
      const isSegOrCracksIncluded = segSelectedAnalysis.length > 0 || cracksSelectedAnalysis.length > 0;

      if (isSegOrCracksIncluded && !isBackwallFlawsIncluded) {
        backwallSelectedAnalysis.push(BACKWALL_FLAWS_ANALYSIS_NUM);
      }

      if (!isSegOrCracksIncluded) {
        state.analysisStages[backwallAnalysisStageModel].SelectedAnalysisTypes = backwallSelectedAnalysis.filter(
          (num) => num !== BACKWALL_FLAWS_ANALYSIS_NUM,
        );
      }
    },
  },
});

export const { actions: recipesActions, reducer: recipes } = recipesSlice;
