import ReactEcharts from 'echarts-for-react';
import React, { FC } from 'react';

import { CHART_TYPES, IDs } from '../../../../../consts';
import { ChartTypes } from '../../../../../types';

interface Props {
  chartType: ChartTypes;
  options: any;
}

const styles = { width: '100%', height: '100%' };

export const DatabaseCharts: FC<Props> = ({ options, chartType }) => {
  return (
    <div id={IDs.databaseCharts} className="graphs-container">
      {chartType === CHART_TYPES.bar && <ReactEcharts option={options} style={styles} />}
      {chartType === CHART_TYPES.scatter && <ReactEcharts option={options} style={styles} />}
      {chartType === CHART_TYPES.linearRegression && <ReactEcharts option={options} style={styles} />}
      {chartType === CHART_TYPES.exponentialRegression && <ReactEcharts option={options} style={styles} />}
      {chartType === CHART_TYPES.polynomialRegression && <ReactEcharts option={options} style={styles} />}
      {chartType === CHART_TYPES.multibar && <ReactEcharts option={options} style={styles} />}
      {chartType === CHART_TYPES.pie && <ReactEcharts option={options} style={styles} />}
    </div>
  );
};
