import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { MouseEvent } from 'react';

import { BLACK_PRIMARY, BLUE_PRIMARY, GREY_SECONDARY_EXTRA_LIGHT, SCAN_VIEWS, WHITE_PRIMARY } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useCropSliceImageMutation } from '../../../store/api/adapt-image';
import { useSetProjectionsDataMutation } from '../../../store/api/slice';
import { selectAdaptImageCropCoords, selectIsAdaptImageCropping } from '../../../store/selectors';

export const Cropping = () => {
  const { setAdaptImageCrop } = useActionCreators();
  const isCropping = useAppSelector(selectIsAdaptImageCropping);
  const cropCoords = useAppSelector(selectAdaptImageCropCoords);
  const [cropTrigger] = useCropSliceImageMutation();
  const [setCProjectionDataTrigger] = useSetProjectionsDataMutation({ fixedCacheKey: 'sliceC' });

  const handleCropAreaInsertion = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    setAdaptImageCrop();
  };

  const handleCropping = async (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    await cropTrigger(cropCoords);
    await setCProjectionDataTrigger(SCAN_VIEWS.C);
  };

  return (
    <div className="adapt__menu__cropping">
      <h4 className="adapt__menu__subtitle">
        <TranslatedText textKey="adapt-image.cropping" />
      </h4>
      <Button
        title={'Insert'}
        onClick={handleCropAreaInsertion}
        style={{
          backgroundColor: isCropping ? BLUE_PRIMARY : 'transparent',
          borderColor: isCropping ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: isCropping ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
      <Button title={'common.crop'} disabled={!isCropping} onClick={handleCropping} />
    </div>
  );
};
