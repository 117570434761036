import { SLICE_TYPES } from '../consts/geometry';
import { SEG_ANALYSIS } from '../consts/segregations';
import { TSegAnalysis } from '../types';

export const getCheckedSegAnalysis = (currentAnalysis: TSegAnalysis, sliceType: string): TSegAnalysis => {
  if (sliceType === SLICE_TYPES.rect || sliceType === SLICE_TYPES.round || sliceType === SLICE_TYPES.cut) {
    if (currentAnalysis === SEG_ANALYSIS.sep) return SEG_ANALYSIS.mm;
  }

  if (sliceType === SLICE_TYPES.slabRight || sliceType === SLICE_TYPES.slabLeft || sliceType === SLICE_TYPES.slabMiddle) {
    if (currentAnalysis === SEG_ANALYSIS.astm) return SEG_ANALYSIS.mm;
  }

  return currentAnalysis;
};
