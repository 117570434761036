export const mergeArraysUnique = <T extends { id: string }>(originArr: T[], arrToAdd: T[]) => {
  for (const item of arrToAdd) {
    if (!originArr.some((existingItem) => existingItem.id === item.id)) {
      originArr.push(item);
    }
  }

  return originArr;
};

export const removeDuplicates = <T extends { id: string }>(originArr: T[], arrToRemove: T[]) => {
  const idMap: { [id: string]: boolean } = {};

  for (const item of arrToRemove) {
    idMap[item.id] = true;
  }

  return originArr.filter((item) => !idMap[item.id]);
};
