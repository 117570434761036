import { SCAN_VIEWS } from '../consts';
import { ISliderRanges } from '../store/slices/types';
import { ILineCoordinates, TTupleOfTwoNumbers } from '../types';
import { ICartesianModeParams } from './getCartesianModeParams';
import { IGraphParams } from './getGraphParams';

interface ISingleLayerLinesCoords {
  l1: ILineCoordinates;
  l2: ILineCoordinates;
}

export const getSingleLayerLinesCoords = (
  scanView: string,
  sliderRanges: ISliderRanges,
  domains: TTupleOfTwoNumbers,
  isCartesianMode: boolean,
  cartesianModeParams: ICartesianModeParams,
  graphParams: IGraphParams
): ISingleLayerLinesCoords => {
  const width = isCartesianMode ? cartesianModeParams.realSampleWidth : graphParams.innerSvgWidth;
  const height = isCartesianMode ? cartesianModeParams.realSampleHeight : graphParams.innerSvgHeight;

  const l1x1 = isCartesianMode ? cartesianModeParams.x : 0,
    l2y1 = isCartesianMode ? cartesianModeParams.y : 0;

  let l1y1 = isCartesianMode ? cartesianModeParams.y : 0,
    l1x2 = isCartesianMode ? cartesianModeParams.x : 0,
    l1y2 = isCartesianMode ? cartesianModeParams.y : 0,
    l2x1 = isCartesianMode ? cartesianModeParams.x : 0,
    l2x2 = isCartesianMode ? cartesianModeParams.x : 0,
    l2y2 = isCartesianMode ? cartesianModeParams.y : 0;

  const { sliceB, sliceC, sliceD } = sliderRanges;

  switch (scanView) {
  case SCAN_VIEWS.B:
    l1y1 += height / domains[1] * sliceC.start;
    l1x2 += width;
    l1y2 += height / domains[1] * sliceC.start;
    l2x1 += width / domains[0] * sliceD.start;
    l2x2 += width / domains[0] * sliceD.start;
    l2y2 += height;
    break;
  case SCAN_VIEWS.C:
    l1y1 += height / domains[1] * (domains[1] - sliceD.start);
    l1x2 += width;
    l1y2 += height / domains[1] * (domains[1] - sliceD.start);
    l2x1 += width / domains[0] * sliceB.start;
    l2x2 += width / domains[0] * sliceB.start;
    l2y2 += height;
    break;
  case SCAN_VIEWS.D:
    l1y1 += height / domains[1] * sliceC.start;
    l1x2 += width;
    l1y2 += height / domains[1] * sliceC.start;
    l2x1 += width / domains[0] * sliceB.start;
    l2x2 += width / domains[0] * sliceB.start;
    l2y2 += height;
    break;
  default:
  }

  return {
    l1: {
      x1: l1x1,
      y1: l1y1,
      x2: l1x2,
      y2: l1y2,
    },
    l2: {
      x1: l2x1,
      y1: l2y1,
      x2: l2x2,
      y2: l2y2,
    },
  };
};
