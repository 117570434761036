import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import Button from '@sms/plasma-ui/lib/components/Button';
import React, { useEffect } from 'react';

import {
  RECIPE_ANALYSIS_FIELDS,
  RECIPE_ANALYSIS_SECTIONS_TITLE,
  RECIPE_PALETTE_FIELDS,
  SLICE_TYPE_VALUE_FROM_NUM,
  SLICE_TYPES,
  TYPE_DISCRIMINATOR,
} from '../../consts';
import { useActionCreators, useAppSelector } from '../../hooks';
import { selectRecipeData, selectRecipePerformAnalysis, selectRecipeSliceType } from '../../store/selectors';
import { INumberInputBlurHandlerArgs, typedEntries } from '../../types';
import { isStartEndNumberInputValid } from '../../utils';
import { FormInput } from '../FormInput';
import { RecipeAnalysisTypes } from './RecipeAnalysisTypes';
import { RecipeMainDataForm } from './RecipeMainDataForm';

export const RecipeEditor = () => {
  const recipe = useAppSelector(selectRecipeData);
  const performAnalysis = useAppSelector(selectRecipePerformAnalysis);
  const sliceType = useAppSelector(selectRecipeSliceType) as keyof typeof SLICE_TYPE_VALUE_FROM_NUM;
  const { setRecipeField, setRecipeSetZonesVisible, resetRecipeAnalysisTypes } = useActionCreators();

  const isRoundSliceType = SLICE_TYPE_VALUE_FROM_NUM[sliceType] === SLICE_TYPES.round;
  const isBilletSliceType = SLICE_TYPE_VALUE_FROM_NUM[sliceType] === SLICE_TYPES.rect || isRoundSliceType;

  const handleSetZonesClick = () => {
    setRecipeSetZonesVisible();
  };

  useEffect(() => {
    resetRecipeAnalysisTypes();
  }, [isBilletSliceType]);

  const handleNumberInputBlur = ({ section, range, startKey, endKey }: INumberInputBlurHandlerArgs) => {
    //@ts-ignore
    let start = recipe[section][startKey];
    //@ts-ignore
    let end = recipe[section][endKey];

    if (startKey.includes('.')) {
      //@ts-ignore
      start = recipe[section].Palette[startKey.split('.')[1]];
    }

    if (endKey.includes('.')) {
      //@ts-ignore
      end = recipe[section].Palette[endKey.split('.')[1]];
    }

    const isInputsValid = isStartEndNumberInputValid(start, end);

    if (isInputsValid) return;

    if (range === 'start') {
      setRecipeField({ section, id: endKey, value: start });
    }
    if (range === 'end') {
      setRecipeField({ section, id: startKey, value: end });
    }
  };

  return (
    <div className="recipes__editor">
      <RecipeMainDataForm />
      {typedEntries(RECIPE_ANALYSIS_FIELDS).map(([typeDiscriminator, values]) => {
        return (
          <div key={typeDiscriminator} className="recipe__form-section">
            <h3 className="side-menu__title">
              <TranslatedText textKey={RECIPE_ANALYSIS_SECTIONS_TITLE[typeDiscriminator]} />
            </h3>
            <div className="recipe__analysis-form">
              {typedEntries(values).map(([key, label]) => {
                if (key === 'SelectedAnalysisTypes') {
                  return (
                    <RecipeAnalysisTypes
                      key={`SelectedAnalysisTypes${typeDiscriminator}`}
                      typeDiscriminator={typeDiscriminator}
                      disabled={!performAnalysis[typeDiscriminator]}
                      analysisTypeData={recipe[typeDiscriminator].SelectedAnalysisTypes}
                      isBilletSliceType={isBilletSliceType}
                    />
                  );
                }

                if (key === 'SetZones' && typeDiscriminator === TYPE_DISCRIMINATOR.flawsAnalysisStageModel) {
                  return (
                    <Button
                      key={`SetZones${typeDiscriminator}`}
                      title="recipes.set-sms-zones"
                      disabled={recipe[typeDiscriminator].IsAutoZones || isBilletSliceType}
                      onClick={handleSetZonesClick}
                    />
                  );
                }

                if (key === 'Zones' && typeDiscriminator === TYPE_DISCRIMINATOR.flawsAnalysisStageModel) {
                  const { IsAutoZones, SmsZone1, SmsZone2 } = recipe[typeDiscriminator];

                  if (IsAutoZones) return;

                  return (
                    <div key={`Zones${typeDiscriminator}`} className="recipe__flaws-zones">
                      {[SmsZone1, SmsZone2].map((zone, idx) => {
                        if (!zone) return;

                        const { X1, X2, Y1, Y2, Unit } = zone;

                        return (
                          <div key={idx} className="recipe__flaws-zones__zone">
                            <p>
                              <TranslatedText textKey="common.zone" /> {idx + 1}
                            </p>
                            <span>
                              (X1; Y1): {X1}; {Y1} ({Unit})
                            </span>
                            <span>
                              (X2; Y2): {X2}; {Y2} ({Unit})
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                if (key === 'Palette') {
                  return (
                    <div className="recipe__analysis-form" key={`Palette${typeDiscriminator}`}>
                      {typedEntries(RECIPE_PALETTE_FIELDS).map(([paletteKey, label]) => (
                        <FormInput
                          key={`${paletteKey}${typeDiscriminator}`}
                          section={typeDiscriminator}
                          id={`${key}.${paletteKey}`}
                          label={label}
                          disabled={!performAnalysis[typeDiscriminator]}
                          value={recipe[typeDiscriminator][key][paletteKey]}
                          onChange={setRecipeField}
                          onBlur={handleNumberInputBlur}
                        />
                      ))}
                    </div>
                  );
                }

                return (
                  <FormInput
                    key={`${key}${typeDiscriminator}`}
                    section={typeDiscriminator}
                    id={key}
                    label={key === 'ZoneHeight' && isRoundSliceType ? 'side-menu.zone-diameter' : label}
                    disabled={!performAnalysis[typeDiscriminator] || (key === 'IsPoresIncluded' && isBilletSliceType)}
                    //@ts-ignore
                    value={recipe[typeDiscriminator][key]}
                    onChange={setRecipeField}
                    onBlur={handleNumberInputBlur}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
