import './styles.scss';

import { Modal, Radio, TranslatedText } from '@sms/plasma-ui';
import React, { FC, useCallback, useState } from 'react';

import { GEOMETRY_CUT_TABS, SLICE_TYPES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useInitGeometryMutation } from '../../../store/api/geometry';
import {
  selectCracksAnalysis,
  selectFileType,
  selectFlawsAnalysis,
  selectMaxProjectionSliderRanges,
  selectSegAnalysis,
  selectSliceType,
} from '../../../store/selectors';
import {
  getCheckedCracksAnalysis,
  getCheckedFlawsAnalysis,
  getCheckedSegAnalysis,
  getGeometryAnalysisData,
} from '../../../utils';

interface IProps {
  isVisible: boolean;
}

export const GeometryAnalysisModal: FC<IProps> = ({ isVisible }) => {
  const {
    setCRangeForLoadedGeometry,
    setSliceType,
    setGeometryAnalysisVisible,
    setChangeCutParametersVisible,
    setDefaultCutParams,
    resetSegregation,
    setSegAnalysis,
    setFlawsAnalysis,
    setCracksAnalysis,
    resetReportAnalysis,
  } = useActionCreators();
  const sliceType = useAppSelector(selectSliceType);
  const [radioGroupValue, setRadioGroupValue] = useState<string>(sliceType);
  const { sliceC } = useAppSelector(selectMaxProjectionSliderRanges);
  const fileType = useAppSelector(selectFileType);
  const segAnalysis = useAppSelector(selectSegAnalysis);
  const flawsAnalysis = useAppSelector(selectFlawsAnalysis);
  const cracksAnalysis = useAppSelector(selectCracksAnalysis);
  const [initGeometryBaseTrigger] = useInitGeometryMutation();
  const geometryAnalysisData = getGeometryAnalysisData(fileType);

  // RadioChangeEvent isn't exported from Plasma UI
  const onChange = useCallback((e: any) => {
    if (e.target.value === GEOMETRY_CUT_TABS.cut) {
      setChangeCutParametersVisible(true);
    }
    setRadioGroupValue(e.target.value);
  }, []);

  const onCancel = useCallback(() => {
    setRadioGroupValue('');
    setGeometryAnalysisVisible(false);
  }, []);

  const onOk = useCallback(async () => {
    if (!radioGroupValue || radioGroupValue === sliceType) return;

    setCRangeForLoadedGeometry(sliceC);
    resetReportAnalysis();

    await initGeometryBaseTrigger(radioGroupValue);

    if (radioGroupValue !== SLICE_TYPES.cut) {
      setDefaultCutParams();
    }

    resetSegregation();
    setSegAnalysis(getCheckedSegAnalysis(segAnalysis, radioGroupValue));
    setFlawsAnalysis(getCheckedFlawsAnalysis(flawsAnalysis, radioGroupValue));
    setCracksAnalysis(getCheckedCracksAnalysis(cracksAnalysis, radioGroupValue));
    setSliceType(radioGroupValue);
    setRadioGroupValue('');
    setGeometryAnalysisVisible(false);
  }, [radioGroupValue, setCRangeForLoadedGeometry, sliceC, segAnalysis, flawsAnalysis, cracksAnalysis, sliceType]);

  if (!isVisible) return null;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      //@ts-ignore
      okText={<TranslatedText textKey="modals.geometry.run-analysis" />}
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      //@ts-ignore
      title={<TranslatedText textKey="modals.geometry.choose-type" />}
      visible={isVisible}
      width={460}
      zIndex={10}
      className="geometry-analysis__modal"
    >
      <div className="geometry-analysis__radio-group">
        {geometryAnalysisData.map(({ value, label, disabled }) => (
          <Radio key={value} value={value} onChange={onChange} disabled={disabled} checked={value === radioGroupValue}>
            <TranslatedText textKey={label} />
          </Radio>
        ))}
      </div>
    </Modal>
  );
};
