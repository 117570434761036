import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { ec2AutoInstanceHeader } from '../../../consts';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '..';
import { IReportClientSettings, TReportLogoData } from '../report/types';
import { ISettingsResponse } from './types';

const { setInitialGeneralSettings, setReportEmail } = allActions;

export const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query<ISettingsResponse, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ('settings');

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ISettingsResponse;
        const { generalAnalysisSettings, astmSettings, gostSettings } = data;
        dispatch(setInitialGeneralSettings({ ...generalAnalysisSettings, astmSettings, gostSettings }));

        return { data };
      },
    }),
    updateAnalysisSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: {
            generalSettings: { rangeStart: rangeStartSliceC, rangeEnd: rangeEndSliceC, ...rest },
          },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/analysisSettings/update',
          method: 'POST',
          body: Object.fromEntries(
            Object.entries({ rangeStartSliceC, rangeEndSliceC, ...rest }).map(([key, value]) => [key, value.parameter]),
          ),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    updateSegregationsSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: {
            segregationsSettings: { rangeStart: segregationsRangeStart, rangeEnd: segregationsRangeEnd, ...rest },
          },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/segregationsSettings/update',
          method: 'POST',
          body: Object.fromEntries(
            Object.entries({ segregationsRangeStart, segregationsRangeEnd, ...rest }).map(([key, value]) => [
              key,
              value.parameter,
            ]),
          ),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    updateFlawsSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: {
            flawsSettings: { rangeStart: flawsRangeStart, rangeEnd: flawsRangeEnd, ...rest },
          },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/flawsSettings/update',
          method: 'POST',
          body: Object.fromEntries(
            Object.entries({ flawsRangeStart, flawsRangeEnd, ...rest }).map(([key, value]) => [key, value.parameter]),
          ),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    updateCracksSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: {
            cracksSettings: {
              rangeStart: cracksRangeStart,
              rangeEnd: cracksRangeEnd,
              minCrackLength: minCrackLengthMm,
              centerZoneHeight: centerZoneHeightMm,
              ...rest
            },
          },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/cracksSettings/update',
          method: 'POST',
          body: Object.fromEntries(
            Object.entries({
              cracksRangeStart,
              cracksRangeEnd,
              minCrackLengthMm,
              centerZoneHeightMm,
              ...rest,
            }).map(([key, value]) => [key, value.parameter]),
          ),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Cracks Zones'],
    }),
    updateBackwallSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: {
            backwallSettings: { rangeStart: backwallStartRange, rangeEnd: backwallEndRange, ...rest },
          },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/backwallSettings/update',
          method: 'POST',
          body: Object.fromEntries(
            Object.entries({ backwallStartRange, backwallEndRange, ...rest }).map(([key, value]) => [
              key,
              value.parameter,
            ]),
          ),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    updateAstmSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: { astmSettings },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/astmSettings/update',
          method: 'POST',
          body: Object.fromEntries(Object.entries(astmSettings).map(([key, value]) => [key, value.parameter])),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    updateGostSettings: build.mutation<void, void>({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const {
          settings: { gostSettings },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ({
          url: 'settings/gostSettings/update',
          method: 'POST',
          body: Object.fromEntries(Object.entries(gostSettings).map(([key, value]) => [key, value.parameter])),
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    getLogos: build.query<TReportLogoData, void>({
      query: () => ({
        url: 'settings/logo',
        headers: ec2AutoInstanceHeader,
      }),
      providesTags: ['ReportLogo'],
    }),
    getClientSettings: build.query<IReportClientSettings, void>({
      async queryFn(_, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'settings/clientsettings',
          headers: ec2AutoInstanceHeader,
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IReportClientSettings;
        dispatch(setReportEmail(data.reportEmail));

        return { data };
      },
    }),
    setClientSettings: build.mutation<void, { clientLogo: string } | void>({
      async queryFn(args, { getState }, _extraOptions, fetchWithBQ) {
        const {
          report: { email },
        } = getState() as TypedRootState;

        if (!email && !args) {
          return ({ data: null } as unknown) as QueryReturnValue<void, FetchBaseQueryError, unknown>;
        }

        const body = {} as Record<string, unknown>;

        if (email) {
          body.reportEmail = email;
        }

        const result = await fetchWithBQ({
          url: 'settings/clientsettings',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: {
            ...body,
            ...(args ?? {}),
          },
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['ReportLogo'],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useUpdateAnalysisSettingsMutation,
  useUpdateSegregationsSettingsMutation,
  useUpdateFlawsSettingsMutation,
  useUpdateCracksSettingsMutation,
  useUpdateBackwallSettingsMutation,
  useUpdateAstmSettingsMutation,
  useUpdateGostSettingsMutation,
  useGetLogosQuery,
  useGetClientSettingsQuery,
  useSetClientSettingsMutation,
} = settingsApi;
