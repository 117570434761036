import { createSelector } from '@reduxjs/toolkit';

import { TypedRootState } from '../types';

export const selectBackwallFlawsThresholds = createSelector(
  (state: TypedRootState) => state.backwallFlaws,
  ({ upperThreshold, lowerThreshold }) => ({ lowerThreshold, upperThreshold }),
);
export const selectBackwallFlawsAllowModification = (state: TypedRootState) =>
  state.backwallFlaws.allowAreaModification;
export const selectBackwallFlawsPolygons = (state: TypedRootState) => state.backwallFlaws.polygonsData;
export const selectBackwallFlawsStatistics = (state: TypedRootState) => state.backwallFlaws.statistics;
export const selectBackwallFlawZone = (state: TypedRootState) => state.backwallFlaws.zones?.flawsZone;
export const selectBackwallFlawsSelectedPolygon = (state: TypedRootState) => state.backwallFlaws.selectedPolygon;
export const selectBackwallFlawsInfoShownPolygons = (state: TypedRootState) => state.backwallFlaws.infoShownPolygons;
export const selectBackwallFlawsForbiddenZones = (state: TypedRootState) => state.flaws.forbiddenZones;
export const selectIsBackwallFlawsCompleted = (state: TypedRootState) => state.backwallFlaws.isCompleted;
