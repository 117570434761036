import './styles.scss';
import '../../components/SettingsForm/styles.scss';

import React from 'react';

import { SettingsForm } from '../../components/SettingsForm';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useGetSettingsQuery } from '../../store/api/settings';

export const Settings = () => {
  useGetSettingsQuery();

  return (
    <DefaultLayout>
      <div className="page-container settings-page">
        <SettingsForm />
      </div>
    </DefaultLayout>
  );
};
