import React, { CSSProperties, FC } from 'react';

import { INITIAL_COORDINATES, STROKE_GREY_SECONDARY_DEEP } from '../../consts';
import { TCoordinates, TTupleOfTwoNumbers } from '../../types';
import { getBaseSvgElemStyles, ICartesianModeParams, IGraphParams } from '../../utils';

interface IProps {
  data: TCoordinates[],
  domains: TTupleOfTwoNumbers,
  graphParams: IGraphParams,
  cartesianModeParams: ICartesianModeParams,
  isCartesianMode: boolean,
  pathOffsets?: TCoordinates,
  style?: CSSProperties | undefined,
  classNames?: string,
}

export const Path: FC<IProps> = ({
  data,
  isCartesianMode,
  cartesianModeParams,
  domains,
  graphParams,
  style,
  pathOffsets = INITIAL_COORDINATES,
  classNames,
}) => {
  const d = data.map((item, idx, arr): string => {
    const x = isCartesianMode
      ? cartesianModeParams.x + item.x / domains[0] * cartesianModeParams.realSampleWidth + pathOffsets.x
      : item.x / domains[0] * graphParams.innerSvgWidth + pathOffsets.x;

    const y = isCartesianMode
      ? cartesianModeParams.y + cartesianModeParams.realSampleHeight * (1 - item.y / domains[1]) + pathOffsets.y
      : graphParams.innerSvgHeight - item.y / domains[1] * graphParams.innerSvgHeight + pathOffsets.y;

    return idx === 0
      ? `M${x} ${y}`
      : idx === arr.length - 1
        ? `L ${x} ${y} Z`
        : `L ${x} ${y}`;
  }).join(' ');

  return (
    <path className={classNames} d={d} style={style ?? getBaseSvgElemStyles(STROKE_GREY_SECONDARY_DEEP, 2)} />
  );
};
