import { createSelector } from '@reduxjs/toolkit';

import { ResultsTableState, SelectedSnapshots, TreeCheckedKeys } from '../../types';
import { TypedRootState } from '../types';

export const selectDatabase = (state: TypedRootState) => state.database;
export const selectHeightAnalysisActiveIds = (state: TypedRootState): string[] =>
  state.database.heightAnalysis.activeIds;
export const selectSelectedSnapshotsIds = (state: TypedRootState): string[] =>
  state.database.snapshots.analysisResultsIds;
export const selectDbSelectedReportIds = (state: TypedRootState): string[] => state.database.selectedReportIds;
export const selectDbSelectedSnapshotTypes = (state: TypedRootState): SelectedSnapshots =>
  state.database.snapshots.selectedSnapshotTypes;
export const selectDbSelectedSnapshotNames = (state: TypedRootState): string[] =>
  state.database.snapshots.selectedSnapshotNames;
export const selectDbActiveSnapshots = (state: TypedRootState): TreeCheckedKeys =>
  state.database.snapshots.activeSnapshots;
export const selectHeightAnalysisVisibleIds = (state: TypedRootState): string[] =>
  state.database.heightAnalysis.visibleIds;
export const selectDBAdditionalFilters = (state: TypedRootState) => state.database.additionalFilters;
export const selectDbGraphsSelectedColumns = (state: TypedRootState) => state.database.graphs.selectedColumns;
export const selectDBResultTable = (state: TypedRootState): ResultsTableState => state.database.resultTable;
export const selectDBScanDataFilters = createSelector(selectDatabase, ({ scanDataFileFilter }) => scanDataFileFilter);
export const selectDBAnalysisFilters = createSelector(selectDatabase, ({ scanDataFileFilter, ...rest }) => rest);
