import './styles.scss';

import { Radio, TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { RadioGroupData } from '../../../types';

interface IProps {
  data: RadioGroupData[];
  onChange: (e: any) => void;
  radioGroupValue: string;
}

export const CustomRadioGroup: React.FC<IProps> = ({ data, onChange, radioGroupValue }) => {
  return (
    <div className="radio-group">
      {data.map(({ value, label, disabled }) => (
        <Radio key={value} value={value} onChange={onChange} disabled={disabled} checked={value === radioGroupValue}>
          <TranslatedText textKey={label} />
        </Radio>
      ))}
    </div>
  );
};
