import { useMemo } from 'react';

import { DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE } from '../consts';
import { useSearchFilesMutation, useUpdateFilesInfoMutation } from '../store/api/scandata';
import { selectScanDataFilters } from '../store/selectors';
import { useAppSelector } from './useAppSelector';

export const useFilesSearch = () => {
  const { showDeletedFromCloud } = useAppSelector(selectScanDataFilters);
  const [updateFilesInfoTrigger, { isLoading: isFilesInfoLoading }] = useUpdateFilesInfoMutation();
  const [searchFilesTrigger, { data, isLoading }] = useSearchFilesMutation({
    fixedCacheKey: 'filesSearch',
  });

  const shownItems = useMemo(() => {
    const items = data?.items;

    if (showDeletedFromCloud) return items;

    return items?.filter((file) => file.s3Key !== DEFAULT_S3KEY_VALUE_FOR_DELETED_FILE);
  }, [showDeletedFromCloud, data]);

  const totalShownItems = useMemo(() => {
    const totalItems = data?.totalItems;
    const totalFilteredItems = data?.totalFilteredItems;

    if (showDeletedFromCloud) return totalItems;

    return totalFilteredItems ?? totalItems;
  }, [showDeletedFromCloud, data]);

  const handleSearchFiles = async () => {
    await updateFilesInfoTrigger();
    return await searchFilesTrigger();
  };

  return {
    isLoading: isLoading || isFilesInfoLoading,
    items: shownItems,
    totalItems: totalShownItems,
    handleSearchFiles,
  };
};
