import './styles.scss';

import React from 'react';

import { CracksSettings } from './CracksSettings';
import { CracksStatistics } from './CracksStatistics';

export const CracksSideMenu = () => {
  return (
    <div className="cracks__menu">
      <CracksSettings />
      <CracksStatistics />
    </div>
  );
};
