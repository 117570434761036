import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { DEFAULT_ERROR_MESSAGE, MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { createModalMessageBody, downloadArchive } from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';

const { setSpinnerVisible, setMessageModal } = allActions;

export const reportApi = api.injectEndpoints({
  endpoints: (build) => ({
    createReport: build.mutation<void, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          report: {
            analysis: { segregations, flaws, cracks, backwall, other },
            fileName,
            controller,
            addLogo: { smsLogo: addSmsLogo, hdScanLogo: addHdScanLogo, clientLogo: addClientLogo },
            sendEmail,
          },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'report/create',
          method: 'POST',
          body: {
            volumeId,
            includedAnalyses: {
              segregationsAnalyses: {
                mmanIncluded: segregations.MM,
                gostIncluded: segregations.GOST,
                astmIncluded: segregations.ASTM,
                sep100Included: segregations.SEP,
              },
              flawsAnalyses: {
                smsIncluded: flaws.SMS,
                gostIncluded: flaws.GOST,
                astmIncluded: flaws.ASTM,
              },
              cracksAnalyses: {
                smsIncluded: cracks.SMS,
                gostIncluded: cracks.GOST,
                astmIncluded: cracks.ASTM,
              },
              geometryIncluded: other.geometry,
              zonesIncluded: other.zones,
              backwallDistanceIncluded: backwall.Distance,
              backwallFlawsIncluded: backwall.Flaws,
              generateCsvTables: other.csv,
            },
            controller,
            fileName,
            addSmsLogo,
            addHdScanLogo,
            addClientLogo,
            sendEmail,
          },
          responseHandler: async (response) => await downloadArchive(response, fileName),
          // window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        });

        if (result.error) {
          dispatch(
            setMessageModal(
              createModalMessageBody(MODAL_STATUS.NOTIFICATION, MODAL_TITLE.notification, DEFAULT_ERROR_MESSAGE),
            ),
          );

          return { error: result.error as FetchBaseQueryError };
        }

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    saveResultsInDatabase: build.mutation<void, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          report: {
            analysis: { segregations, flaws, cracks, backwall, other },
            controller,
          },
        } = getState() as TypedRootState;

        dispatch(setSpinnerVisible(true));

        const result = await fetchWithBQ({
          url: 'report/save',
          method: 'POST',
          body: {
            volumeId,
            includedAnalyses: {
              segregationsAnalyses: {
                mmanIncluded: segregations.MM,
                gostIncluded: segregations.GOST,
                astmIncluded: segregations.ASTM,
                sep100Included: segregations.SEP,
              },
              flawsAnalyses: {
                smsIncluded: flaws.SMS,
                gostIncluded: flaws.GOST,
                astmIncluded: flaws.ASTM,
              },
              cracksAnalyses: {
                smsIncluded: cracks.SMS,
                gostIncluded: cracks.GOST,
                astmIncluded: cracks.ASTM,
              },
              backwallAnalyses: {
                smsIncluded: backwall.SMS,
                gostIncluded: backwall.GOST,
                astmIncluded: backwall.ASTM,
              },
              geometryIncluded: other.geometry,
              zonesIncluded: other.zones,
              backwallDistanceIncluded: backwall.Distance,
              backwallFlawsIncluded: backwall.Flaws,
              generateCsvTables: other.csv,
            },
            controller,
          },
        });

        dispatch(setSpinnerVisible(false));

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
    setRemarks: build.mutation<void, void>({
      async queryFn(body, { getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
          remarks,
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'report/remarks',
          method: 'POST',
          body: {
            volumeId,
            remarks,
          },
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
    }),
  }),
});

export const { useCreateReportMutation, useSaveResultsInDatabaseMutation, useSetRemarksMutation } = reportApi;
