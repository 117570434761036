import './styles.scss';

import { Modal, NumberInput, RadioGroup, TranslatedText, useTranslation } from '@sms/plasma-ui';
import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';

import { GEOMETRY_CHANGE_PARAMETERS_DATA, INIT_CHANGE_CUT_PARAMS, SHAPES } from '../../../consts/geometry';
import { useActionCreators } from '../../../hooks';
import { ILocalCutParams, RadioGroupData } from '../../../types';
import { NumberInputValue } from '../../RangeBar';

interface IProps {
  isVisible: boolean;
}

export const ChangeCutParametersModal: FC<IProps> = ({ isVisible }) => {
  const { t } = useTranslation();
  const { setBaseCutParams, setChangeCutParametersVisible } = useActionCreators();
  const [radioGroupValue, setRadioGroupValue] = useState<string>('');
  const [localCutParams, setLocalCutParams] = useState<ILocalCutParams>(INIT_CHANGE_CUT_PARAMS);

  const inputData = [
    {
      label: t('dimensions.width', { unit: '[mm]' }),
      value: localCutParams.Rectangular.sizeX,
      disabled: radioGroupValue !== SHAPES.rect,
      onChange: (value: NumberInputValue) => {
        if (!value) return;
        setLocalCutParams((prev) => ({
          ...prev,
          Rectangular: {
            ...prev.Rectangular,
            sizeX: +value,
          },
        }));
      },
    },
    {
      label: t('dimensions.diameterX', { unit: '[mm]' }),
      value: localCutParams.Round.sizeX,
      disabled: radioGroupValue !== SHAPES.round,
      onChange: (value: NumberInputValue) => {
        if (!value) return;
        setLocalCutParams((prev) => ({
          ...prev,
          Round: {
            ...prev.Round,
            sizeX: +value,
          },
        }));
      },
    },
    {
      label: t('dimensions.height', { unit: '[mm]' }),
      value: localCutParams.Rectangular.sizeY,
      disabled: radioGroupValue !== SHAPES.rect,
      onChange: (value: NumberInputValue) => {
        if (!value) return;
        setLocalCutParams((prev) => ({
          ...prev,
          Rectangular: {
            ...prev.Rectangular,
            sizeY: +value,
          },
        }));
      },
    },
    {
      label: t('dimensions.diameterY', { unit: '[mm]' }),
      value: localCutParams.Round.sizeY,
      disabled: radioGroupValue !== SHAPES.round,
      onChange: (value: NumberInputValue) => {
        if (!value) return;
        setLocalCutParams((prev) => ({
          ...prev,
          Round: {
            ...prev.Round,
            sizeY: +value,
          },
        }));
      },
    },
  ];

  // RadioChangeEvent isn't exported from Plasma UI
  const onChange = useCallback((e: any) => {
    setRadioGroupValue(e.target.value);
  }, []);

  const onOk = useCallback(() => {
    setBaseCutParams({
      shape: radioGroupValue,
      ...localCutParams[`${radioGroupValue}` as keyof typeof localCutParams],
    });
    setRadioGroupValue('');
    setLocalCutParams(INIT_CHANGE_CUT_PARAMS);
    setChangeCutParametersVisible(false);
  }, [radioGroupValue, localCutParams]);

  const onCancel = useCallback(() => {
    setRadioGroupValue('');
    setLocalCutParams(INIT_CHANGE_CUT_PARAMS);
    setChangeCutParametersVisible(false);
  }, []);

  const modalClasses = classNames('change-parameters__modal', {
    'ok-hidden': !radioGroupValue,
  });

  if (!isVisible) return null;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      //@ts-ignore
      okText={<TranslatedText textKey="common.confirm" />}
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      //@ts-ignore
      title={<TranslatedText textKey="modals.change-cut" />}
      visible={isVisible}
      width={460}
      zIndex={15}
      className={modalClasses}
    >
      <RadioGroup<RadioGroupData>
        name="geometryAnalysisRadioGroup"
        dataSource={GEOMETRY_CHANGE_PARAMETERS_DATA}
        value={radioGroupValue}
        onChange={onChange}
      />
      <div className="change-parameters__wrapper">
        {inputData.map(({ label, value, disabled, onChange }, idx) => (
          <NumberInput
            key={idx}
            decimalSeparator={'.'}
            value={value}
            label={label}
            hintMode="hidden"
            name={'width'}
            onChange={onChange}
            maxWidth={72}
            disabled={disabled}
            step={1}
          />
        ))}
      </div>
    </Modal>
  );
};
