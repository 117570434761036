import React, { FC } from 'react';

import { IProps } from '../index';

export const DefectsRZoneList: FC<IProps> = ({ statistics }) => {
  return (
    <>
      {statistics && 'defectsRZoneCounts' in statistics && (
        <ul className='seg__statistics__list'>
          <li className='seg__statistics__item'>
            <p>R</p>
          </li>
          {statistics.defectsRZoneCounts.map((item, idx) => (
            <li key={idx} className='seg__statistics__item'>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
