import { IMargin, typedEntries } from '../types';
import { BACKWALL_ZONES_TYPES } from './backwall';
import { SLICE_TYPES } from './geometry';

export const FILTER_RANGE = {
  start: -32767,
  end: 32767,
} as const;

export const DB_SNAPSHOT_TITLES = {
  geometryA: 'Geometry A',
  geometryB: 'Geometry B',
  geometryC: 'Geometry C',
  geometryDimensions: 'Geometry dimensions',
  geometryDiagonals: 'Geometry diagonals',
  geometryBase: 'Geometry base',
  segregationsMman: 'Segregations MM',
  segregationsSep1: 'Segregations SEP1',
  segregationsSep100: 'Segregations SEP100',
  segregationsGost: 'Segregations GOST',
  segregationsAstm: 'Segregations ASTM',
  flawsSms: 'Flaws SMS',
  flawsGost: 'Flaws GOST',
  flawsAstm: 'Flaws ASTM',
  cracks: 'Cracks',
  backwallDistance: 'Backwall Macrostructure',
  zones: 'Zones',
  userSnapshots: 'User snapshots',
};

export const DB_SNAPSHOT_TITLES_ARRAY = [
  { key: 'geometryA', title: 'Geometry A' },
  { key: 'geometryB', title: 'Geometry B' },
  { key: 'geometryC', title: 'Geometry C' },
  { key: 'geometryDimensions', title: 'geometry.geometry-dimensions' },
  { key: 'geometryDiagonals', title: 'Geometry diagonals' },
  { key: 'geometryBase', title: 'Geometry base' },
  { key: 'segregationsMman', title: 'Segregations MM' },
  { key: 'segregationsSep1', title: 'Segregations SEP1' },
  { key: 'segregationsSep100', title: 'Segregations SEP100' },
  { key: 'segregationsGost', title: 'Segregations GOST' },
  { key: 'segregationsAstm', title: 'Segregations ASTM' },
  { key: 'flawsSms', title: 'Flaws SMS' },
  { key: 'flawsGost', title: 'Flaws GOST' },
  { key: 'flawsAstm', title: 'Flaws ASTM' },
  { key: 'cracks', title: 'pages.cracks' },
  { key: 'backwallDistance', title: 'Backwall Macrostructure' },
  { key: 'zones', title: 'Zones' },
  { key: 'userSnapshots', title: 'User snapshots' },
] as const;

export const DB_HEIGHT_ANALYSIS_MARGIN: IMargin = { top: 10, right: 10, left: 90, bottom: 20 };

export const BILLET_CUT_SHAPE_SELECT_DATA = [
  {
    label: 'Rectangular',
    value: 'Rectangular',
  },
  {
    label: 'Round',
    value: 'Round',
  },
];

export const SINGULAR_CRACK_POSITION_SELECT_DATA = [
  {
    label: 'Top',
    value: 'Top',
  },
  {
    label: 'Bottom',
    value: 'Bottom',
  },
];

export const BACKWALL_DISTANCE_ZONE_TYPE_SELECT_DATA = [
  {
    label: BACKWALL_ZONES_TYPES.dendritic,
    value: BACKWALL_ZONES_TYPES.dendritic,
  },
  {
    label: BACKWALL_ZONES_TYPES.equiaxed,
    value: BACKWALL_ZONES_TYPES.equiaxed,
  },
  {
    label: BACKWALL_ZONES_TYPES.other,
    value: BACKWALL_ZONES_TYPES.other,
  },
];

export const BACKWALL_DISTANCE_SIZE_PARAMETER_SELECT_DATA = [
  {
    label: 'Width',
    value: 'Width',
  },
  {
    label: 'Height',
    value: 'Height',
  },
  {
    label: 'Area',
    value: 'Area',
  },
];

export const CRACK_TYPE_SELECT_DATA = [
  {
    label: 'Longitudinal',
    value: 'Longitudinal',
  },
  {
    label: 'OffCorner',
    value: 'OffCorner',
  },
  {
    label: 'NFN_S',
    value: 'NFN_S',
  },
  {
    label: 'NFN_T',
    value: 'NFN_T',
  },
  {
    label: 'NearSurface',
    value: 'NearSurface',
  },
  {
    label: 'TripplePoint',
    value: 'TripplePoint',
  },
  {
    label: 'Transversal',
    value: 'Transversal',
  },
  {
    label: 'Cross Section',
    value: 'CrossSection',
  },
  {
    label: 'Center',
    value: 'Center',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const DATABASE_SECTIONS = {
  scanDataFileFilter: 'scanDataFileFilter',
  geometryFilter: 'geometryFilter',
  segregationsFilter: 'segregationsFilter',
  flawsFilter: 'flawsFilter',
  cracksFilter: 'cracksFilter',
  backwallDistanceFilter: 'backwallDistanceFilter',
  analysisResultDateFilter: 'analysisResultDateFilter',
  additionalFilters: 'additionalFilters',
} as const;

export const DATABASE_SECTIONS_TITLE = {
  [DATABASE_SECTIONS.geometryFilter]: 'pages.geometry',
  [DATABASE_SECTIONS.segregationsFilter]: 'pages.segregation',
  [DATABASE_SECTIONS.flawsFilter]: 'pages.flaws',
  [DATABASE_SECTIONS.cracksFilter]: 'pages.cracks',
  [DATABASE_SECTIONS.backwallDistanceFilter]: 'pages.backwall',
  [DATABASE_SECTIONS.additionalFilters]: 'Additional filters',
};

export const DATABASE_ADDITIONAL_GEOMETRY_FILTERS_TITLES = {
  diagonalsRatio: 'Diagonal ratio',
  sampleWidth: 'Sample width',
  aLinesLeft: 'Left A lines',
  aLinesRight: 'Right A lines',
  bLinesLeftDeviationMin: 'Min deviation of left B lines',
  bLinesLeftDeviationMax: 'Max deviation of left B lines',
  bLinesRightDeviationMin: 'Min deviation of right B lines',
  bLinesRightDeviationMax: 'Max deviation of right B lines',
  cLinesLeft: 'Left C lines',
  cLinesRight: 'Right C lines',
  dimensionsWidthAvg: 'Average dimensions width',
  dimensionsWidthRSD: 'Average dimensions width RSD',
  dimensionsHeightAvg: 'Average dimensions height',
  dimensionsHeightRSD: 'Average dimensions height RSD',
  motherSampleArea: 'Mother sample area',
  motherSampleSizeX: 'Mother sample size X',
  motherSampleSizeY: 'Mother sample size Y',
  billetCutShape: 'Billet cut shape',
  includeRemarks: 'Remarks',
};

const ANALYSIS_FILTERS = {
  threshold: 'Threshold',
  rangeStart: 'Start range',
  rangeEnd: 'End range',
  zoneCenterY: 'Zone center Y',
  zoneWidth: 'Zone width',
  zoneHeight: 'Zone height',
  lessThanOne: '<1 mm',
  oneToThree: '1-3 mm',
  threeToFive: '3-5 mm',
  fiveToTen: '5-10 mm',
  moreThanTen: '>10 mm',
  area: 'Area',
  normArea: 'side-menu.norm-area',
  includeRemarks: 'Remarks',
};

export const DATABASE_ADDITIONAL_SEGREGATIONS_FILTERS_TITLES = {
  mmanFilter: {
    title: 'MM',
    filters: ANALYSIS_FILTERS,
  },
  sep1Filter: {
    title: 'SEP1',
    filters: { ...ANALYSIS_FILTERS, classDecimal: 'Decimal class' },
  },
  sep100Filter: {
    title: 'SEP100',
    filters: { ...ANALYSIS_FILTERS, classDecimal: 'Decimal class' },
  },
  astmSlabFilter: {
    title: 'ASTM Slab',
    filters: ANALYSIS_FILTERS,
  },
  gostSlabFilter: {
    title: 'GOST Slab',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      zoneCenterY: 'Zone center Y',
      zoneWidth: 'Zone width',
      zoneHeight: 'Zone height',
      poresLessThanPointFive: 'Pores <0.5 mm',
      poresFromPointFiveToOne: 'Pores 0.5-1 mm',
      poresOneToThree: 'Pores 1-3 mm',
      poresMoreThanThree: 'Pores >3 mm',
      poresClass: 'Pores Class',
      lessThanThree: '<3 mm',
      moreThanThree: '>3 mm',
      includeRemarks: 'Remarks',
    },
  },
  gostBilletFilter: {
    title: 'GOST Billet',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      zoneCenterX: 'Zone center X',
      zoneCenterY: 'Zone center Y',
      zoneWidth: 'Zone width',
      zoneHeight: 'Zone height',
      poresLessThanPointFive: 'Pores <0.5 mm',
      poresFromPointFiveToOne: 'Pores 0.5-1 mm',
      poresOneToThree: 'Pores 1-3 mm',
      poresMoreThanThree: 'Pores >3 mm',
      poresClass: 'Pores Class',
      x1: 'x1 mm',
      x2: 'x2 mm',
      lessThanOne: '<1 mm',
      oneToThree: '1-3 mm',
      threeToX1: '3-x1 mm',
      x1ToX2: 'x1-x2 mm',
      moreThanX2: '>x2 mm',
      includeRemarks: 'Remarks',
    },
  },
  astmBilletFilter: {
    title: 'ASTM Billet',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      zoneCenterX: 'Zone center X',
      zoneCenterY: 'Zone center Y',
      zoneWidth: 'Zone width',
      zoneHeight: 'Zone height',
      x1: 'x1 mm',
      x2: 'x2 mm',
      lessThanOneC: 'C <1 mm',
      oneToThreeC: 'C 1-3 mm',
      threeToX1C: 'C 3-x1 mm',
      x1ToX2C: 'C x1-x2 mm',
      moreThanX2C: 'C >x2 mm',
      lessThanOneR: 'R <1 mm',
      oneToThreeR: 'R 1-3 mm',
      threeToX1R: 'R 3-x1 mm',
      x1ToX2R: 'R x1-x2 mm',
      moreThanX2R: 'R >x2 mm',
      lessThanOneS: 'S <1 mm',
      oneToThreeS: 'S 1-3 mm',
      threeToX1S: 'S 3-x1 mm',
      x1ToX2S: 'S x1-x2 mm',
      moreThanX2S: 'S >x2 mm',
      includeRemarks: 'Remarks',
    },
  },
};

export const DATABASE_ADDITIONAL_FLAWS_FILTERS_TITLES = {
  smsFilter: {
    title: 'SMS',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      regionsCount: 'Count of regions',
      includeRemarks: 'Remarks',
    },
  },
  smsResultsByRegions: {
    title: 'SMS results by regions',
    filters: {
      regionNumber: 'Region number',
      regionX1: 'Region x1',
      regionX2: 'Region x2',
      regionY1: 'Region y1',
      regionY2: 'Region y2',
      lessThanOne: '<1 mm',
      oneToThree: '1-3 mm',
      threeToFive: '3-5 mm',
      fiveToTen: '5-10 mm',
      moreThanTen: '>10 mm',
    },
  },
  gostSlabFilter: {
    title: 'GOST Slab',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      region1X1: 'Region 1 x1',
      region1X2: 'Region 1 x2',
      region1Y1: 'Region 1 y1',
      region1Y2: 'Region 1 y2',
      region2X1: 'Region 2 x1',
      region2X2: 'Region 2 x2',
      region2Y1: 'Region 2 y1',
      region2Y2: 'Region 2 y2',
      class1: 'Class 1',
      class2: 'Class 2',
      lessThanOneReg1: 'Region 1 <1 mm',
      oneToTwoReg1: 'Region 1 1-2 mm',
      moreThanTwoReg1: 'Region 1 >2 mm',
      lessThanOneReg2: 'Region 2 <1 mm',
      oneToTwoReg2: 'Region 2 1-2 mm',
      moreThanTwoReg2: 'Region 2 >2 mm',
      includeRemarks: 'Remarks',
    },
  },
  gostBilletFilter: {
    title: 'GOST Billet',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      regionInnerX1: 'Inner region x1',
      regionInnerX2: 'Inner region x2',
      regionInnerY1: 'Inner region y1',
      regionInnerY2: 'Inner region y2',
      regionOuterX1: 'Outer region x1',
      regionOuterX2: 'Outer region x2',
      regionOuterY1: 'Outer region y1',
      regionOuterY2: 'Outer region y2',
      region1Radius: 'Region 1 radius',
      region2Radius: 'Region 2 radius',
      regionCenterX: 'Region center X',
      regionCenterY: 'Region center Y',
      lessThanPointFive: '<0.5 mm',
      pointFiveToOne: '0.5-1 mm',
      oneToThree: '1-3 mm',
      moreThanThree: '>3 mm',
      includeRemarks: 'Remarks',
    },
  },
  astmFilter: {
    title: 'ASTM',
    filters: {
      threshold: 'Threshold',
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      regionRX1: 'Region R x1',
      regionRX2: 'Region R x2',
      regionRY1: 'Region R y1',
      regionRY2: 'Region R y2',
      regionSX1: 'Region S x1',
      regionSX2: 'Region S x2',
      regionSY1: 'Region S y1',
      regionSY2: 'Region S y2',
      regionRRadius: 'Region R radius',
      regionSRadius: 'Region S radius',
      regionCenterX: 'Region center X',
      regionCenterY: 'Region center Y',
      x1: 'x1',
      x2: 'x2',
      lessThanOneR: 'R <1 mm',
      oneToThreeR: 'R 1-3 mm',
      threeToX1R: 'R 3-x1 mm',
      x1ToX2R: 'R x1-x2 mm',
      moreThanX2R: 'R >x2 mm',
      lessThanOneS: 'S <1 mm',
      oneToThreeS: 'S 1-3 mm',
      threeToX1S: 'S 3-x1 mm',
      x1ToX2S: 'S x1-x2 mm',
      moreThanX2S: 'S >x2 mm',
      includeRemarks: 'Remarks',
    },
  },
};

export const SINGULAR_CRACK_FILTER = {
  title: 'Singular',
  filters: {
    crackType: 'Crack type',
    length: 'dimensions.length',
    classSMS: 'SMS class',
    confidenceScore: 'Confidence core',
    begin: 'Begin',
    end: 'End',
    position: 'Position',
  },
};

export const DATABASE_ADDITIONAL_CRACKS_FILTERS_TITLES = {
  smsFilter: {
    title: 'SMS',
    filters: {
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      centerClass: 'Center class',
      offCornerClass: 'OffCorner class',
      otherClass: 'Other class',
      longitudinalClass: 'Longitudinal class',
      nearSurfaceClass: 'NearSurface class',
      transversalClass: 'Transversal class',
      nfN_TClass: 'Narrow face near triple class',
      nfN_SClass: 'Narrow face near surface class',
      tripplePointClass: 'Tripple-point class',
      zeroToFive: '0-5',
      sixToTwenty: '6-20',
      twentyOneToForty: '21-40',
      fortyOneToSixty: '41-60',
      moreThanSixty: '>60',
      includeRemarks: 'Remarks',
    },
  },
  gostFilter: {
    title: 'GOST',
    filters: {
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      centerClass: 'Center class',
      offCornerClass: 'OffCorner class',
      otherClass: 'Other class',
      longitudinalClass: 'Longitudinal class',
      nearSurfaceClass: 'NearSurface class',
      transversalClass: 'Transversal class',
      nfN_TClass: 'Narrow face near triple class',
      nfN_SClass: 'Narrow face near surface class',
      tripplePointClass: 'Tripple-point class',
      crossSectionClass: 'Cross-section class',
      nfN_TClassNs: 'Narrow face near triple NS class',
      nfN_SClassNs: 'Narrow face near surface NS class',
      offCornerClassNs: 'OffCorner NS class',
      tripplePointClassNs: 'Tripple-point NS class',
      nearSurfaceClassNs: 'NearSurface NS class',
      includeRemarks: 'Remarks',
    },
  },
  astmFilter: {
    title: 'ASTM',
    filters: {
      rangeStart: 'Start range',
      rangeEnd: 'End range',
      centerClass: 'Center class',
      offCornerClass: 'OffCorner class',
      otherClass: 'Other class',
      crossSectionClass: 'Cross-section class',
      includeRemarks: 'Remarks',
    },
  },
  cracksCountFilter: {
    title: 'Cracks count',
    filters: {
      longitudinalCracksFilter: {
        title: 'Longitudinal',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      nearSurfaceCracksFilter: {
        title: 'NearSurface',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      centerCracksFilter: {
        title: 'Center',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      transversalCracksFilter: {
        title: 'Transversal',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      offCornerCracksFilter: {
        title: 'OffCorner',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      nfN_TCracksFilter: {
        title: 'Narrow face near triple',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      nfN_SCracksFilter: {
        title: 'Narrow face near surface',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      tripplePointCracksFilter: {
        title: 'Tripple-point',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      crossSectionCracksFilter: {
        title: 'Cross-section',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
      otherCracksFilter: {
        title: 'Other',
        filters: {
          cracksCount: 'Cracks count',
        },
      },
    },
  },
  singularCracksFilters: {
    title: 'Singular',
    filters: [SINGULAR_CRACK_FILTER, SINGULAR_CRACK_FILTER],
  },
};

export const BACKWALL_DISTANCE_ZONE_FILTER = {
  title: 'Zone filters',
  filters: {
    zoneId: 'Id',
    zoneType: 'side-menu.type',
    width: 'Width',
    height: 'Height',
    sizePercent: 'Size %',
    sizeParameter: 'Size parameter',
  },
};

export const DATABASE_ADDITIONAL_BACKWALL_DISTANCE_FILTERS_TITLES = {
  zoneFilters: {
    title: 'Zones',
    filters: [BACKWALL_DISTANCE_ZONE_FILTER, BACKWALL_DISTANCE_ZONE_FILTER],
  },
  rangeStart: 'Start range',
  rangeEnd: 'End range',
  zonesCount: 'Zones count',
  includeRemarks: 'Remarks',
};

export const DATABASE_ADDITIONAL_SCAN_INFO_FILTERS_TITLES = {
  scanningSpeed: 'Scanning speed',
  shortDistance: 'Short distance',
  amplificationFlawSensor: 'Flaw amplification sensor',
  amplificationBackSensor: 'Back amplification sensor',
  preAmplificationFlawSensor: 'Flaw pre-amplification sensor',
  preAmplificationBackSensor: 'Back pre-amplification sensor',
};

export const DATABASE_ADDITIONAL_SAMPLE_INFO_FILTERS_TITLES = {
  manufacturerDataFilter: {
    title: 'info.manufacturerData',
    filters: {
      producer: 'Producer',
      site: 'Site',
      plant: 'Plant',
      productionDate: 'Production date',
      qualityCode: 'Quality code',
      steelGrade: 'Steel grade',
    },
  },
  materialAnalysisFilter: {
    title: 'info.materialAnalysis',
    filters: {
      c: 'C',
      si: 'Si',
      mn: 'Mn',
      p: 'P',
      s: 'S',
      n: 'N',
      al: 'Al',
      cu: 'Cu',
      cr: 'Cr',
      ni: 'Ni',
      v: 'V',
      ti: 'Ti',
      nb: 'Nb',
      b: 'B',
      mo: 'Mo',
      as: 'As',
      sn: 'Sn',
      ca: 'Ca',
      h2: 'H2',
    },
  },
  productionDataFilter: {
    title: 'info.productionData',
    filters: {
      heatNumber: 'info.productionData.heatNumber',
      sequence: 'info.productionData.sequence',
      slabId: 'info.productionData.slabId',
      slabSampleId: 'info.productionData.slabSampleId',
      numberOfSamples: 'info.productionData.numberOfSamples',
      castingMachine: 'info.productionData.castingMachine',
      tundishSuperheat: 'info.productionData.tundishSuperheat',
      slabWidth: 'info.productionData.slabWidth',
      slabThickness: 'info.productionData.slabThickness',
      castingSpeed: 'info.productionData.castingSpeed',
      specificSprayCoolingCurve: 'info.productionData.specificSprayCoolingCurve',
      thermalTaper: 'info.productionData.thermalTaper',
      thermalTaperParameters: 'info.productionData.thermalTaperParameters',
      numberOfSRSegments: 'info.productionData.numberOfSRSegments',
      softReductionRate: 'info.productionData.softReductionRate',
      softReductionParameters: 'info.productionData.softReductionParameters',
      moldOscillationFrequency: 'info.productionData.moldOscillationFrequency',
      negativeStrip: 'info.productionData.negativeStrip',
    },
  },
  includeRemarks: 'Remarks',
};

export const DATABASE_CRACKS_TYPES_TITLES = {
  longitudinalCracksFilter: 'Longitudinal',
  nearSurfaceCracksFilter: 'NearSurface',
  centerCracksFilter: 'Center',
  transversalCracksFilter: 'Transversal',
  offCornerCracksFilter: 'OffCorner',
  nfN_TCracksFilter: 'Narrow face near triple',
  nfN_SCracksFilter: 'Narrow face near surface',
  tripplePointCracksFilter: 'Triple-Point crack',
  crossSectionCracksFilter: 'Cross Section',
  otherCracksFilter: 'Other',
};

export const DATABASE_ADDITIONAL_FILTERS_TITLES = {
  geometryFilter: {
    title: 'pages.geometry',
    filters: DATABASE_ADDITIONAL_GEOMETRY_FILTERS_TITLES,
  },
  segregationsFilter: {
    title: 'pages.segregation',
    filters: DATABASE_ADDITIONAL_SEGREGATIONS_FILTERS_TITLES,
  },
  flawsFilter: {
    title: 'pages.flaws',
    filters: DATABASE_ADDITIONAL_FLAWS_FILTERS_TITLES,
  },
  cracksFilter: {
    title: 'pages.cracks',
    filters: DATABASE_ADDITIONAL_CRACKS_FILTERS_TITLES,
  },
  backwallDistanceFilter: {
    title: 'Backwall Distance',
    filters: DATABASE_ADDITIONAL_BACKWALL_DISTANCE_FILTERS_TITLES,
  },
  scanInfoFilter: {
    title: 'Scan info',
    filters: DATABASE_ADDITIONAL_SCAN_INFO_FILTERS_TITLES,
  },
  sampleInfoFilter: {
    title: 'Sample info',
    filters: DATABASE_ADDITIONAL_SAMPLE_INFO_FILTERS_TITLES,
  },
};

export const DATABASE_BACKWALL_ZONE_TITLES = {
  dendriticZoneFilter: 'Dendritic',
  equaxedZoneFilter: 'Equiaxed',
  otherZoneFilter: 'Other',
};

export const DATABASE_SLICE_TYPES_VALUES = {
  SlabMiddle: SLICE_TYPES.slabMiddle,
  BilletRectangular: SLICE_TYPES.rect,
  SlabRight: SLICE_TYPES.slabRight,
  BilletRound: SLICE_TYPES.round,
  SlabLeft: SLICE_TYPES.slabLeft,
  BilletCut: SLICE_TYPES.cut,
  SampleLong: SLICE_TYPES.sampleLong,
};

export const DATABASE_FIELDS_WITH_CLASS = [
  'mmanFilter',
  'sep1Filter',
  'sep100Filter',
  'astmSlabFilter',
  'gostFilter',
  'smsFilter',
];

const DATABASE_FROM_TO_FIELDS = {
  start: 'common.from',
  end: 'common.to',
};

export const DATABASE_RESULT_PARAMETERS_FIELDS = {
  [DATABASE_SECTIONS.geometryFilter]: {
    sliceTypes: DATABASE_SLICE_TYPES_VALUES,
    sampleHeight: DATABASE_FROM_TO_FIELDS,
  },
  [DATABASE_SECTIONS.segregationsFilter]: {
    mmanFilter: DATABASE_FROM_TO_FIELDS,
    sep1Filter: DATABASE_FROM_TO_FIELDS,
    sep100Filter: DATABASE_FROM_TO_FIELDS,
    gostFilter: DATABASE_FROM_TO_FIELDS,
    astmSlabFilter: DATABASE_FROM_TO_FIELDS,
    astmBilletFilter: {
      classC: DATABASE_FROM_TO_FIELDS,
      classR: DATABASE_FROM_TO_FIELDS,
      classS: DATABASE_FROM_TO_FIELDS,
    },
  },
  [DATABASE_SECTIONS.flawsFilter]: {
    smsFilter: DATABASE_FROM_TO_FIELDS,
    gostFilter: DATABASE_FROM_TO_FIELDS,
    astmFilter: {
      classR: DATABASE_FROM_TO_FIELDS,
      classS: DATABASE_FROM_TO_FIELDS,
    },
  },
  [DATABASE_SECTIONS.cracksFilter]: {
    cracksDetectionFilter: DATABASE_CRACKS_TYPES_TITLES,
    smsFilter: DATABASE_FROM_TO_FIELDS,
    gostFilter: DATABASE_FROM_TO_FIELDS,
    astmFilter: DATABASE_FROM_TO_FIELDS,
  },
  [DATABASE_SECTIONS.backwallDistanceFilter]: DATABASE_BACKWALL_ZONE_TITLES,
};

export const DATABASE_CRACK_TYPES_ENTRIES = typedEntries(DATABASE_CRACKS_TYPES_TITLES);
export const DATABASE_BACKWALL_TYPES_ENTRIES = typedEntries(DATABASE_BACKWALL_ZONE_TITLES);

export const DATABASE_FIELDS_TITLES = {
  sliceTypes: 'Slice Types',
  sampleHeight: 'Sample height',
  mmanFilter: 'MM Class',
  sep1Filter: 'SEP1 Class',
  sep100Filter: 'SEP100 Class',
  astmSlabFilter: 'ASTM (Sabic) Class',
  astmFilter: 'ASTM Class',
  astmBilletFilter: 'ASTM Class',
  gostFilter: 'GOST Class',
  smsFilter: 'SMS Class',
  classC: 'C',
  classR: 'R',
  classS: 'S',
  cracksDetectionFilter: 'Crack types',
};

export const BASE_DB_KEYS = [
  'name',
  'analysisDate',
  'fileLoadDate',
  'sliceType',
  'sampleHeight',
  'segregationsMmClass',
  'segregaationsSep1Class',
  'segregaationsSep100Class',
  'segregationsGostClass',
  'segregationsAstmSlabClass',
  'segregationsAstmBilletClass',
  'flawsSmsClass',
  'flawsGostClass',
  'flawsAstmClass',
  'cracksSmsClass',
  'cracksGostClass',
  'cracksAstmClass',
];

export const DB_TABLE_COLUMN_TITLES = {
  name: 'Name',
  fileType: 'File type',
  analysisDate: 'Analysis date',
  fileLoadDate: 'Loaded at',
  controllerName: 'Controller',
  sliceType: 'Slice type',
  sampleHeight: 'Sample height',
  segregationsMmClass: 'Seg MM class',
  segregationsSep1Class: 'Seg SEP1 class',
  segregationsSep100Class: 'Seg SEP100 class',
  segregationsAstmSlabClass: 'Seg ASTM slab class',
  segregationsGostClass: 'Seg GOST class',
  segregationsAstmBilletClass: 'Seg ASTM billet class',
  flawsSmsClass: 'Flaws SMS class',
  flawsGostClass: 'Flaws GOST class',
  flawsAstmClass: 'Flaws ASTM class',
  cracksSmsClass: 'Cracks SMS class',
  cracksGostClass: 'Cracks GOST class',
  cracksAstmClass: 'Cracks ASTM class',
  'geometryAnalysis-sampleWidth': 'Sample width',
  'geometryAnalysis-aLinesLeft': 'Left A lines',
  'geometryAnalysis-aLinesRight': 'Right A lines',
  'geometryAnalysis-bLinesLeftDeviationMax': 'Max deviation of left B lines',
  'geometryAnalysis-bLinesLeftDeviationMin': 'Min deviation of left B lines',
  'geometryAnalysis-bLinesRightDeviationMax': 'Max deviation of right B lines',
  'geometryAnalysis-bLinesRightDeviationMin': 'Min deviation of right B lines',
  'geometryAnalysis-cLinesLeft': 'Left B Lines',
  'geometryAnalysis-cLinesRight': 'Right B Lines',
  'geometryAnalysis-diagonalsRatio': 'Diagonal ratio',
  'geometryAnalysis-dimensionsWidthAvg': 'Average dimensions width',
  'geometryAnalysis-dimensionsWidthRSD': 'Dimensions width RSD',
  'geometryAnalysis-dimensionsHeightAvg': 'Average dimensions height',
  'geometryAnalysis-dimensionsHeightRSD': 'Dimensions height RSD',
  'geometryAnalysis-billetCutShape': 'Billet cut shape',
  'geometryAnalysis-motherSampleArea': 'Mother sample area',
  'geometryAnalysis-motherSampleSizeX': 'Mother sample size X',
  'geometryAnalysis-motherSampleSizeY': 'Mother sample size Y',
  'geometryAnalysis-remarks': 'Remarks',
  'segregationsAnalysisMM-area': 'Area',
  'segregationsAnalysisMM-classDecimal': 'Decimal class',
  'segregationsAnalysisMM-fiveToTen': '5-10, mm',
  'segregationsAnalysisMM-lessThanOne': '<1, mm',
  'segregationsAnalysisMM-moreThanTen': '>10 mm',
  'segregationsAnalysisMM-normArea': 'Norm area, %',
  'segregationsAnalysisMM-oneToThree': '1-3, mm',
  'segregationsAnalysisMM-rangeStart': 'Start range',
  'segregationsAnalysisMM-endStart': 'End range',
  'segregationsAnalysisMM-remarks': 'Remarks',
  'segregationsAnalysisMM-threeToFive': '3-5, mm',
  'segregationsAnalysisMM-threshold': 'Threshold',
  'segregationsAnalysisMM-zoneCenterY': 'Zone center Y',
  'segregationsAnalysisMM-zoneHeight': 'Zone height',
  'segregationsAnalysisMM-zoneWidth': 'Zone width',
  'segregationsAnalysisSEP1-area': 'Area',
  'segregationsAnalysisSEP1-classDecimal': 'Decimal class',
  'segregationsAnalysisSEP1-fiveToTen': '5-10, mm',
  'segregationsAnalysisSEP1-lessThanOne': '<1, mm',
  'segregationsAnalysisSEP1-moreThanTen': '>10 mm',
  'segregationsAnalysisSEP1-normArea': 'Norm area, %',
  'segregationsAnalysisSEP1-oneToThree': '1-3, %',
  'segregationsAnalysisSEP1-rangeStart': 'Start range',
  'segregationsAnalysisSEP1-endStart': 'End range',
  'segregationsAnalysisSEP1-remarks': 'Remarks',
  'segregationsAnalysisSEP1-threeToFive': '3-5, mm',
  'segregationsAnalysisSEP1-threshold': 'Threshold',
  'segregationsAnalysisSEP1-zoneCenterY': 'Zone center Y',
  'segregationsAnalysisSEP1-zoneHeight': 'Zone height',
  'segregationsAnalysisSEP1-zoneWidth': 'Zone width',
  'segregationsAnalysisSEP100-area': 'Area',
  'segregationsAnalysisSEP100-classDecimal': 'Decimal class',
  'segregationsAnalysisSEP100-fiveToTen': '5-10, mm',
  'segregationsAnalysisSEP100-lessThanOne': '<1, mm',
  'segregationsAnalysisSEP100-moreThanTen': '>10 mm',
  'segregationsAnalysisSEP100-normArea': 'Norm area, %',
  'segregationsAnalysisSEP100-oneToThree': '1-3, %',
  'segregationsAnalysisSEP100-rangeStart': 'Start range',
  'segregationsAnalysisSEP100-rangeEnd': 'End range',
  'segregationsAnalysisSEP100-remarks': 'Remarks',
  'segregationsAnalysisSEP100-threeToFive': '3-5, mm',
  'segregationsAnalysisSEP100-threshold': 'Threshold',
  'segregationsAnalysisSEP100-zoneCenterY': 'Zone center Y',
  'segregationsAnalysisSEP100-zoneHeight': 'Zone height',
  'segregationsAnalysisSEP100-zoneWidth': 'Zone width',
  'segregationsAnalysisASTM-area': 'Area',
  'segregationsAnalysisASTM-classDecimal': 'Decimal class',
  'segregationsAnalysisASTM-fiveToTen': '5-10, mm',
  'segregationsAnalysisASTM-lessThanOne': '<1, mm',
  'segregationsAnalysisASTM-moreThanTen': '>10 mm',
  'segregationsAnalysisASTM-normArea': 'Norm area, %',
  'segregationsAnalysisASTM-oneToThree': '1-3, %',
  'segregationsAnalysisASTM-rangeStart': 'Start range',
  'segregationsAnalysisASTM-endStart': 'End range',
  'segregationsAnalysisASTM-remarks': 'Remarks',
  'segregationsAnalysisASTM-threeToFive': '3-5, mm',
  'segregationsAnalysisASTM-threshold': 'Threshold',
  'segregationsAnalysisASTM-zoneCenterY': 'Zone center Y',
  'segregationsAnalysisASTM-zoneHeight': 'Zone height',
  'segregationsAnalysisASTM-zoneWidth': 'Zone width',
  'segregationsAnalysisGostSlabResult-lessThanThree': '<3, mm',
  'segregationsAnalysisGostSlabResult-poresFromPointFiveToOne': 'Pores 0.5-1, mm',
  'segregationsAnalysisGostSlabResult-poresLessThanPointFive': 'Pores <0.5, mm',
  'segregationsAnalysisGostSlabResult-poresMoreThanThree': 'Pores >3, mm',
  'segregationsAnalysisGostSlabResult-poresOneToThree': 'Pores 1-3, mm',
  'segregationsAnalysisGostSlabResult-moreThanThree': '>3, mm',
  'segregationsAnalysisGostSlabResult-zoneWidth': 'Zone width',
  'segregationsAnalysisGostSlabResult-zoneHeight': 'Zone height',
  'segregationsAnalysisGostSlabResult-threshold': 'Threshold',
  'segregationsAnalysisGostSlabResult-rangeStart': 'Range start',
  'segregationsAnalysisGostSlabResult-rangeEnd': 'Range end',
  'segregationsAnalysisGostSlabResult-remarks': 'Remarks',
  'segregationsAnalysisGostSlabResult-zoneCenterY': 'Zone center Y',
  'segregationsAnalysisGostSlabResult-poresClass': 'Pores class',
  'segregationsAnalysisGostBilletResult-lessThanOne': '<1, mm',
  'segregationsAnalysisGostBilletResult-moreThanX2': '>x2, mm',
  'segregationsAnalysisGostBilletResult-oneToThree': '1-3, mm',
  'segregationsAnalysisGostBilletResult-poresFromPointFiveToOne': 'Pores 0.5-1, mm',
  'segregationsAnalysisGostBilletResult-poresLessThanPointFive': 'Pores <0.5, mm',
  'segregationsAnalysisGostBilletResult-poresMoreThanThree': 'Pores >3, mm',
  'segregationsAnalysisGostBilletResult-poresOneToThree': 'Pores 1-3, mm',
  'segregationsAnalysisGostBilletResult-threeToX1': '3-x1, mm',
  'segregationsAnalysisGostBilletResult-x1': 'x1, mm',
  'segregationsAnalysisGostBilletResult-x1ToX2': 'x1-x2, mm',
  'segregationsAnalysisGostBilletResult-x2': 'x2, mm',
  'segregationsAnalysisGostBilletResult-zoneWidth': 'Zone width',
  'segregationsAnalysisGostBilletResult-zoneHeight': 'Zone height',
  'segregationsAnalysisGostBilletResult-threshold': 'Threshold',
  'segregationsAnalysisGostBilletResult-rangeStart': 'Range start',
  'segregationsAnalysisGostBilletResult-rangeEnd': 'Range end',
  'segregationsAnalysisGostBilletResult-remarks': 'Remarks',
  'segregationsAnalysisGostBilletResult-zoneCenterX': 'Zone center X',
  'segregationsAnalysisGostBilletResult-zoneCenterY': 'Zone center Y',
  'segregationsAnalysisGostBilletResult-poresClass': 'Pores Class',
  'segregationsAnalysisAstmBilletResult-lessThanOneC': 'C <1, mm',
  'segregationsAnalysisAstmBilletResult-lessThanOneR': 'R <1, mm',
  'segregationsAnalysisAstmBilletResult-lessThanOneS': 'S <1, mm',
  'segregationsAnalysisAstmBilletResult-moreThanX2C': 'C >x2, mm',
  'segregationsAnalysisAstmBilletResult-moreThanX2R': 'R >x2, mm',
  'segregationsAnalysisAstmBilletResult-moreThanX2S': 'S >x2, mm',
  'segregationsAnalysisAstmBilletResult-oneToThreeC': 'C 1-3, mm',
  'segregationsAnalysisAstmBilletResult-oneToThreeR': 'R 1-3, mm',
  'segregationsAnalysisAstmBilletResult-oneToThreeS': 'S 1-3, mm',
  'segregationsAnalysisAstmBilletResult-threeToX1C': 'C 3-x1, mm',
  'segregationsAnalysisAstmBilletResult-threeToX1R': 'R 3-x1, mm',
  'segregationsAnalysisAstmBilletResult-threeToX1S': 'S 3-x1, mm',
  'segregationsAnalysisAstmBilletResult-x1': 'x1, mm',
  'segregationsAnalysisAstmBilletResult-x1ToX2C': 'C x1-x2, mm',
  'segregationsAnalysisAstmBilletResult-x1ToX2R': 'R x1-x2, mm',
  'segregationsAnalysisAstmBilletResult-x1ToX2S': 'S x1-x2, mm',
  'segregationsAnalysisAstmBilletResult-x2': 'x2, mm',
  'segregationsAnalysisAstmBilletResult-zoneWidth': 'Zone width',
  'segregationsAnalysisAstmBilletResult-zoneHeight': 'Zone height',
  'segregationsAnalysisAstmBilletResult-threshold': 'Threshold',
  'segregationsAnalysisAstmBilletResult-rangeStart': 'Range start',
  'segregationsAnalysisAstmBilletResult-rangeEnd': 'Range end',
  'segregationsAnalysisAstmBilletResult-remarks': 'Remarks',
  'segregationsAnalysisAstmBilletResult-zoneCenterX': 'Zone center X',
  'segregationsAnalysisAstmBilletResult-zoneCenterY': 'Zone center Y',
  'flawsAnalysisSmsResult-threshold': 'Threshold',
  'flawsAnalysisSmsResult-rangeStart': 'Range start',
  'flawsAnalysisSmsResult-rangeEnd': 'Range end',
  'flawsAnalysisSmsResult-regionsCount': 'Count of regions',
  'flawsAnalysisSmsResult-remarks': 'Remarks',
  'flawsAnalysisSmsResult-resultsByRegions': 'Results by regions',
  'flawsAnalysisSmsResult-resultsByRegions-fiveToTen': '5-10, mm',
  'flawsAnalysisSmsResult-resultsByRegions-lessThanOne': '<1, mm',
  'flawsAnalysisSmsResult-resultsByRegions-moreThanTen': '>10, mm',
  'flawsAnalysisSmsResult-resultsByRegions-oneToThree': '1-3, mm',
  'flawsAnalysisSmsResult-resultsByRegions-regionNumber': 'Region number',
  'flawsAnalysisSmsResult-resultsByRegions-regionX1': 'Region x1',
  'flawsAnalysisSmsResult-resultsByRegions-regionX2': 'Region x2',
  'flawsAnalysisSmsResult-resultsByRegions-regionY1': 'Region y1',
  'flawsAnalysisSmsResult-resultsByRegions-regionY2': 'Region y2',
  'flawsAnalysisSmsResult-resultsByRegions-threeToFive': '3-5, mm',
  'flawsAnalysisGostSlabResult-threshold': 'Threshold',
  'flawsAnalysisGostSlabResult-rangeStart': 'Range start',
  'flawsAnalysisGostSlabResult-rangeEnd': 'Range end',
  'flawsAnalysisGostSlabResult-remarks': 'Remarks',
  'flawsAnalysisGostSlabResult-class1': 'Class 1',
  'flawsAnalysisGostSlabResult-class2': 'Class 2',
  'flawsAnalysisGostSlabResult-lessThanOneReg1': 'Region 1 <1 mm',
  'flawsAnalysisGostSlabResult-lessThanOneReg2': 'Region 2 <1 mm',
  'flawsAnalysisGostSlabResult-moreThanTwoReg1': 'Region 1 >2 mm',
  'flawsAnalysisGostSlabResult-moreThanTwoReg2': 'Region 2 >2 mm',
  'flawsAnalysisGostSlabResult-oneToTwoReg1': 'Region 1 1-2 mm',
  'flawsAnalysisGostSlabResult-oneToTwoReg2': 'Region 2 1-2 mm',
  'flawsAnalysisGostSlabResult-region1X1': 'Region 1 x1',
  'flawsAnalysisGostSlabResult-region1X2': 'Region 1 x2',
  'flawsAnalysisGostSlabResult-region1Y1': 'Region 1 y1',
  'flawsAnalysisGostSlabResult-region1Y2': 'Region 1 y2',
  'flawsAnalysisGostSlabResult-region2X1': 'Region 2 x1',
  'flawsAnalysisGostSlabResult-region2X2': 'Region 2 x2',
  'flawsAnalysisGostSlabResult-region2Y1': 'Region 2 y1',
  'flawsAnalysisGostSlabResult-region2Y2': 'Region 2 y2',
  'flawsAnalysisGostBilletResult-threshold': 'Threshold',
  'flawsAnalysisGostBilletResult-rangeStart': 'Range start',
  'flawsAnalysisGostBilletResult-rangeEnd': 'Range end',
  'flawsAnalysisGostBilletResult-remarks': 'Remarks',
  'flawsAnalysisGostBilletResult-lessThanPointFive': '<0.5 mm',
  'flawsAnalysisGostBilletResult-moreThanThree': '>3 mm',
  'flawsAnalysisGostBilletResult-oneToThree': '1-3 mm',
  'flawsAnalysisGostBilletResult-pointFiveToOne': '0.5-1 mm',
  'flawsAnalysisGostBilletResult-region1Radius': 'Region 1 radius',
  'flawsAnalysisGostBilletResult-region2Radius': 'Region 2 radius',
  'flawsAnalysisGostBilletResult-regionCenterX': 'Region center X',
  'flawsAnalysisGostBilletResult-regionCenterY': 'Region center Y',
  'flawsAnalysisGostBilletResult-regionInnerX1': 'Inner region x1',
  'flawsAnalysisGostBilletResult-regionInnerX2': 'Inner region x2',
  'flawsAnalysisGostBilletResult-regionInnerY1': 'Inner region y1',
  'flawsAnalysisGostBilletResult-regionInnerY2': 'Inner region y2',
  'flawsAnalysisGostBilletResult-regionOuterX1': 'Outer region x1',
  'flawsAnalysisGostBilletResult-regionOuterX2': 'Outer region x2',
  'flawsAnalysisGostBilletResult-regionOuterY1': 'Outer region y1',
  'flawsAnalysisGostBilletResult-regionOuterY2': 'Outer region y2',
  'flawsAnalysisAstmResult-threshold': 'Threshold',
  'flawsAnalysisAstmResult-rangeStart': 'Range start',
  'flawsAnalysisAstmResult-rangeEnd': 'Range end',
  'flawsAnalysisAstmResult-remarks': 'Remarks',
  'flawsAnalysisAstmResult-lessThanOneR': 'R <1 mm',
  'flawsAnalysisAstmResult-lessThanOneS': 'S <1 mm',
  'flawsAnalysisAstmResult-moreThanX2R': 'R >x2 mm',
  'flawsAnalysisAstmResult-moreThanX2S': 'S >x2 mm',
  'flawsAnalysisAstmResult-oneToThreeR': 'R 1-3 mm',
  'flawsAnalysisAstmResult-oneToThreeS': 'S 1-3 mm',
  'flawsAnalysisAstmResult-regionCenterX': 'Region center X',
  'flawsAnalysisAstmResult-regionCenterY': 'Region center Y',
  'flawsAnalysisAstmResult-regionRRadius': 'Region R radius',
  'flawsAnalysisAstmResult-regionRX1': 'Region R x1',
  'flawsAnalysisAstmResult-regionRX2': 'Region R x2',
  'flawsAnalysisAstmResult-regionRY1': 'Region R y1',
  'flawsAnalysisAstmResult-regionRY2': 'Region R y2',
  'flawsAnalysisAstmResult-regionSRadius': 'Region S radius',
  'flawsAnalysisAstmResult-regionSX1': 'Region S x1',
  'flawsAnalysisAstmResult-regionSX2': 'Region S x2',
  'flawsAnalysisAstmResult-regionSY1': 'Region S y1',
  'flawsAnalysisAstmResult-regionSY2': 'Region S y2',
  'flawsAnalysisAstmResult-threeToX1R': 'R 3-x1 mm',
  'flawsAnalysisAstmResult-threeToX1S': 'S 3-x1 mm',
  'flawsAnalysisAstmResult-x1': 'x1',
  'flawsAnalysisAstmResult-x1ToX2R': 'R x1-x2',
  'flawsAnalysisAstmResult-x1ToX2S': 'S x1-x2',
  'flawsAnalysisAstmResult-x2': 'x2',
  'cracksAnalysisSmsResult-rangeStart': 'Range start',
  'cracksAnalysisSmsResult-rangeEnd': 'Range end',
  'cracksAnalysisSmsResult-remarks': 'Remarks',
  'cracksAnalysisSmsResult-centerClass': 'Center class',
  'cracksAnalysisSmsResult-longitudinalClass': 'Longitudinal class',
  'cracksAnalysisSmsResult-nearSurfaceClass': 'NearSurface class',
  'cracksAnalysisSmsResult-nfN_SClass': 'Narrow face near surface class',
  'cracksAnalysisSmsResult-nfN_TClass': 'Narrow face near tripple class',
  'cracksAnalysisSmsResult-offCornerClass': 'OffCorner class',
  'cracksAnalysisSmsResult-transversalClass': 'Transversal class',
  'cracksAnalysisSmsResult-otherClass': 'Other class',
  'cracksAnalysisSmsResult-tripplePointClass': 'Tripple-point class',
  'cracksAnalysisSmsResult-zeroToFive': '0-5',
  'cracksAnalysisSmsResult-sixToTwenty': '6-20',
  'cracksAnalysisSmsResult-twentyOneToForty': '21-40',
  'cracksAnalysisSmsResult-fortyOneToSixty': '41-60',
  'cracksAnalysisSmsResult-moreThanSixty': '>60',
  'cracksAnalysisGostResult-rangeStart': 'Range start',
  'cracksAnalysisGostResult-rangeEnd': 'Range end',
  'cracksAnalysisGostResult-remarks': 'Remarks',
  'cracksAnalysisGostResult-crossSectionClass': 'Cross-section class',
  'cracksAnalysisGostResult-centerClass': 'Center class',
  'cracksAnalysisGostResult-longitudinalClass': 'Longitudinal class',
  'cracksAnalysisGostResult-nearSurfaceClass': 'NearSurface class',
  'cracksAnalysisGostResult-nearSurfaceClassNs': 'NearSurface NS class',
  'cracksAnalysisGostResult-nfN_SClass': 'Narrow face near surface class',
  'cracksAnalysisGostResult-nfN_SClassNs': 'Narrow face near surface NS class',
  'cracksAnalysisGostResult-nfN_TClass': 'Narrow face near tripple class',
  'cracksAnalysisGostResult-nfN_TClassNs': 'Narrow face near tripple NS class',
  'cracksAnalysisGostResult-offCornerClass': 'OffCorner class',
  'cracksAnalysisGostResult-offCornerClassNs': 'OffCorner NS class',
  'cracksAnalysisGostResult-transversalClass': 'Transversal class',
  'cracksAnalysisGostResult-otherClass': 'Other class',
  'cracksAnalysisGostResult-tripplePointClass': 'Tripple-point class',
  'cracksAnalysisGostResult-tripplePointClassNs': 'Tripple-point NS class',
  cracksAnalysisCracksCount: 'Cracks count',
  cracksAnalysisSingularCracks: 'Singular cracks',
  'backwallDistanceAnalysis-rangeStart': 'Range start',
  'backwallDistanceAnalysis-rangeEnd': 'Range end',
  'backwallDistanceAnalysis-remarks': 'Remarks',
  'backwallDistanceAnalysis-zonesCount': 'Zones count',
  'backwallDistanceAnalysis-zones': 'Zones',
  'scanInfo-scanningSpeed': 'Scanning speed',
  'scanInfo-shortDistance': 'Short distance',
  'scanInfo-amplificationFlawSensor': 'Flaw amplification sensor',
  'scanInfo-amplificationBackSensor': 'Back amplification sensor',
  'scanInfo-preAmplificationFlawSensor': 'Flaw pre-amplification sensor',
  'scanInfo-preAmplificationBackSensor': 'Back pre-amplification sensor',
  'sampleInfo-manufacturerData-producer': 'Producer',
  'sampleInfo-manufacturerData-site': 'Site',
  'sampleInfo-manufacturerData-plant': 'Plant',
  'sampleInfo-manufacturerData-productionDate': 'Production date',
  'sampleInfo-manufacturerData-qualityCode': 'Quality code',
  'sampleInfo-manufacturerData-steelGrade': 'Steel grade',
  'sampleInfo-materialAnalysis-al': 'Al',
  'sampleInfo-materialAnalysis-as': 'As',
  'sampleInfo-materialAnalysis-b': 'B',
  'sampleInfo-materialAnalysis-c': 'C',
  'sampleInfo-materialAnalysis-ca': 'Ca',
  'sampleInfo-materialAnalysis-cr': 'Cr',
  'sampleInfo-materialAnalysis-cu': 'Cu',
  'sampleInfo-materialAnalysis-h2': 'H2',
  'sampleInfo-materialAnalysis-mn': 'Mn',
  'sampleInfo-materialAnalysis-mo': 'Mo',
  'sampleInfo-materialAnalysis-n': 'N',
  'sampleInfo-materialAnalysis-nb': 'Nb',
  'sampleInfo-materialAnalysis-ni': 'Ni',
  'sampleInfo-materialAnalysis-p': 'P',
  'sampleInfo-materialAnalysis-s': 'S',
  'sampleInfo-materialAnalysis-si': 'Si',
  'sampleInfo-materialAnalysis-sn': 'Sn',
  'sampleInfo-materialAnalysis-ti': 'Ti',
  'sampleInfo-materialAnalysis-v': 'V',
  heightAnalysis: 'Height analysis',
  snapshots: 'Snapshots',
  report: 'Report',
};

export const NON_SELECTABLE_COLUMNS = [
  'report',
  'heightAnalysis',
  'snapshots',
  'geometryAnalysis-remarks',
  'segregationsAnalysisMM-remarks',
  'segregationsAnalysisSEP1-remarks',
  'segregationsAnalysisSEP100-remarks',
  'segregationsAnalysisASTM-remarks',
  'segregationsAnalysisGostSlabResult-remarks',
  'segregationsAnalysisGostBilletResult-remarks',
  'segregationsAnalysisAstmBilletResult-remarks',
  'flawsAnalysisSmsResult-remarks',
  'flawsAnalysisGostSlabResult-remarks',
  'flawsAnalysisGostBilletResult-remarks',
  'flawsAnalysisAstmResult-remarks',
  'cracksAnalysisSmsResult-remarks',
  'cracksAnalysisGostResult-remarks',
  'cracksAnalysisSingularCracks',
  'backwallDistanceAnalysis-remarks',
  'backwallDistanceAnalysis-zones',
  'flawsAnalysisSmsResult-resultsByRegions',
];
