import { ValueOf } from '../types';

export const APP_ROUTES = {
  images: 'images',
  info: 'info',
  adaptImage: 'adapt-image',
  bcdplus: 'bcdplus',
  bcda: 'bcda',
  b: 'b',
  c: 'c',
  d: 'd',
  db: 'database',
  dbResults: 'database/results',
  threeD: '3d',
  segregation: 'segregation',
  flaws: 'flaws',
  geometry: 'geometry',
  cracks: 'cracks',
  backwall: 'backwall',
  zones: 'zones',
  autoAnalysisCredentials: 'autoanalysiscredentials',
  report: 'report',
  recipes: 'recipes',
  home: '',
};

export type TAppPages = ValueOf<typeof APP_ROUTES>;
