import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { ISliceImage, ISliceImageCroppingCoords, ISliceImageData, ISliceImageScaleParams } from './types';

const { setAdaptImageData, setSpinnerVisible } = allActions;

export const adaptImageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSliceImage: build.query<ISliceImageData, string>({
      async queryFn(volumeId, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ(`image/sliceImage?volumeId=${volumeId}`);

        if (result.error) {
          dispatch(setSpinnerVisible(false));
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ISliceImageData;
        const { imageBase64, imageMmHeight, imageMmWidth, imagePxWidth, imagePxHeight } = data;

        dispatch(
          setAdaptImageData({
            imageBase64,
            imageParams: {
              imageMmHeight,
              imageMmWidth,
              imagePxWidth,
              imagePxHeight,
            },
          }),
        );

        dispatch(setSpinnerVisible(false));

        return { data };
      },
    }),
    restoreSliceImage: build.query<ISliceImageData, void>({
      async queryFn(args, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;
        const result = await fetchWithBQ(`image/cancel?volumeId=${volumeId}`);

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ISliceImageData;
        const { imageBase64, imageMmHeight, imageMmWidth, imagePxWidth, imagePxHeight } = data;

        dispatch(
          setAdaptImageData({
            imageBase64,
            imageParams: {
              imageMmHeight,
              imageMmWidth,
              imagePxWidth,
              imagePxHeight,
            },
          }),
        );

        return { data };
      },
    }),
    cropSliceImage: build.mutation<ISliceImage, ISliceImageCroppingCoords>({
      async queryFn(body, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const {
          volumeInfo: { volumeId },
        } = getState() as TypedRootState;

        const result = await fetchWithBQ({
          url: 'image/crop',
          method: 'POST',
          body: {
            volumeId,
            ...body,
          },
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as ISliceImage;

        dispatch(
          setAdaptImageData({
            imageBase64: data.imageBase64,
          }),
        );

        return { data };
      },
    }),
    scaleSliceImage: build.mutation<void, ISliceImageScaleParams>({
      query: (body) => ({
        url: 'image/scale',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetSliceImageQuery,
  useLazyGetSliceImageQuery,
  useCropSliceImageMutation,
  useLazyRestoreSliceImageQuery,
  useScaleSliceImageMutation,
} = adaptImageApi;
