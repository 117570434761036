import { TranslatedText } from '@sms/plasma-ui';
import React, { FC, useEffect } from 'react';

import { BLACK_PRIMARY, TRANSPARENT } from '../../../../../consts';
import { useGetCrackInfoMutation } from '../../../../../store/api/cracks';
import { IPxCoordinates } from '../../../../../store/api/slice/types';
import { TTupleOfTwoNumbers } from '../../../../../types';
import { getBaseSvgElemStyles, getToFixedValue, ICartesianModeParams } from '../../../../../utils';
import { getIntersectionsData } from '../../../../../utils/cracks';

interface IProps {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  wRatio: number;
  hRatio: number;
  probability: number;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  convertedBorderData: IPxCoordinates[];
}

export const CrackInfo: FC<IProps> = ({
  id,
  x,
  y,
  width,
  height,
  wRatio,
  hRatio,
  probability,
  cartesianModeParams,
  domains,
  convertedBorderData,
}) => {
  const [getCrackInfoTrigger, { data: crackInfo }] = useGetCrackInfoMutation();

  const { realSampleHeight, realSampleWidth } = cartesianModeParams;

  const intersections = {
    leftTop: { x, y },
    rightBottom: { x: x + width, y: y + height },
  };

  const {
    leftTop: {
      x: { delta: leftTopVerticalDelta, minDelta: minLeftTopVerticalDelta },
      y: { delta: leftTopHorizontalDelta, minDelta: minLeftTopHorizontalDelta },
    },
    rightBottom: {
      x: { delta: rightBottomVerticalDelta, minDelta: minRightBottomVerticalDelta },
      y: { delta: rightBottomHorizontalDelta, minDelta: minRightBottomHorizontalDelta },
    },
  } = getIntersectionsData(convertedBorderData, intersections);

  const ltlX1 = x / wRatio;
  const ltlY1 = y / hRatio;
  const ltlX2 = (x + leftTopHorizontalDelta) / wRatio;
  const ltlY2 = (y + leftTopVerticalDelta) / hRatio;
  const rblX1 = (x + width) / wRatio;
  const rblY1 = (y + height) / hRatio;
  const rblX2 = (x + width + rightBottomHorizontalDelta) / wRatio;
  const rblY2 = (y + height + rightBottomVerticalDelta) / hRatio;

  useEffect(() => {
    getCrackInfoTrigger(id);
  }, [id]);

  return (
    <>
      <line
        x1={ltlX1}
        y1={ltlY1}
        x2={ltlX1}
        y2={ltlY2}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSPARENT)}
      />
      <text
        textAnchor={leftTopVerticalDelta > 0 ? 'end' : 'start'}
        style={{
          fontSize: '16px',
          fill: BLACK_PRIMARY,
          transform: `translate(${ltlX1}px, ${ltlY2}px) rotate(-90deg)`,
        }}
      >
        {/* Distance: {getToFixedValue((minLeftTopVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minLeftTopHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm
      </text>
      <line
        x1={ltlX1}
        y1={ltlY1}
        x2={ltlX2}
        y2={ltlY1}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSPARENT)}
      />
      <text
        x={ltlX2}
        y={ltlY1}
        textAnchor={leftTopHorizontalDelta < 0 ? 'end' : 'start'}
        style={{
          fontSize: '16px',
          fill: BLACK_PRIMARY,
        }}
      >
        {/* Distance: {getToFixedValue((minLeftTopHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minLeftTopVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm
      </text>
      <line
        x1={rblX1}
        y1={rblY1}
        x2={rblX1}
        y2={rblY2}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSPARENT)}
      />
      <text
        textAnchor={rightBottomVerticalDelta > 0 ? 'end' : 'start'}
        alignmentBaseline="hanging"
        style={{
          fontSize: '16px',
          fill: BLACK_PRIMARY,
          transform: `translate(${rblX1}px, ${rblY2}px) rotate(-90deg)`,
        }}
      >
        {/* Distance: {getToFixedValue((minRightBottomVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minRightBottomHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm
      </text>
      <line
        x1={rblX1}
        y1={rblY1}
        x2={rblX2}
        y2={rblY1}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSPARENT)}
      />
      <text
        x={rblX2}
        y={rblY1}
        textAnchor={rightBottomHorizontalDelta < 0 ? 'end' : 'start'}
        alignmentBaseline="hanging"
        style={{
          fontSize: '16px',
          fill: BLACK_PRIMARY,
        }}
      >
        {/* Distance: {getToFixedValue((minRightBottomHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minRightBottomVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm
      </text>
      {crackInfo && (
        <>
          <text
            x={(x - 100) / wRatio}
            y={(y + height + 30) / hRatio}
            style={{
              fontSize: '12px',
              fill: BLACK_PRIMARY,
            }}
          >
            <TranslatedText textKey="dimensions.width" /> : {getToFixedValue(crackInfo.width)} mm
          </text>
          <text
            x={(x - 100) / wRatio}
            y={(y + height + 45) / hRatio}
            style={{
              fontSize: '12px',
              fill: BLACK_PRIMARY,
            }}
          >
            <TranslatedText textKey="dimensions.height" />: {getToFixedValue(crackInfo.height)} mm
          </text>
          <text
            x={(x - 100) / wRatio}
            y={(y + height + 60) / hRatio}
            style={{
              fontSize: '12px',
              fill: BLACK_PRIMARY,
            }}
          >
            Crack type: {crackInfo.crackType}
          </text>
          <text
            x={(x - 100) / wRatio}
            y={(y + height + 75) / hRatio}
            style={{
              fontSize: '12px',
              fill: BLACK_PRIMARY,
            }}
          >
            <TranslatedText textKey="side-menu.probability" />: {getToFixedValue(probability)}
          </text>
        </>
      )}
    </>
  );
};
