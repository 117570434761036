import './styles.scss';

import { TranslatedText } from '@sms/plasma-ui';
import React from 'react';

import { REPORT_TABS_TITLES, reportAnalysisEntries } from '../../consts';
import { useAppSelector } from '../../hooks';
import { selectIsAnalysisAvailable, selectReportAnalysis } from '../../store/selectors';
import { typedEntries } from '../../types';
import { ReportAnalysisListItem } from './ReportAnalysisListItem';

export const ReportAnalysisList = () => {
  const report = useAppSelector(selectReportAnalysis);
  const isAnalysisAvailable = useAppSelector(selectIsAnalysisAvailable);

  return (
    <div className="report__analysis">
      {reportAnalysisEntries.map(([analysis, options]) => (
        <div key={analysis}>
          <h3 className="sub-menu__title">
            <TranslatedText textKey={REPORT_TABS_TITLES[analysis]} />
          </h3>
          <ul className="options">
            {typedEntries(options).map(([id, label]) => (
              <ReportAnalysisListItem
                key={id}
                id={id}
                analysis={analysis}
                title={label}
                checked={report[analysis][id]}
                disabled={!isAnalysisAvailable[analysis][id]}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
