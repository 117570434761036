import React, { FC } from 'react';

import { BLACK_PRIMARY, TRANSLUCENT_DIM_GREY } from '../../../consts';

interface IProps {
  fill?: string;
  disabled?: boolean;
}

export const Cube: FC<IProps> = ({ fill = BLACK_PRIMARY, disabled = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.74 17.94C57.73 17.688 57.6571 17.4424 57.5278 17.2258C57.3986 17.0092 57.2171 16.8285 57 16.7L32.75 2.70002C32.5223 2.56738 32.2635 2.4975 32 2.4975C31.7365 2.4975 31.4777 2.56738 31.25 2.70002L7 16.7C6.77701 16.8375 6.59171 17.0284 6.46079 17.2553C6.32987 17.4822 6.25743 17.7381 6.25 18V46C6.25084 46.2633 6.32049 46.5218 6.45205 46.7498C6.58361 46.9778 6.77251 47.1675 7 47.3L31.25 61.3C31.4793 61.4279 31.7375 61.495 32 61.495C32.2625 61.495 32.5207 61.4279 32.75 61.3L57 47.3C57.2275 47.1675 57.4164 46.9778 57.5479 46.7498C57.6795 46.5218 57.7492 46.2633 57.75 46C57.74 45.94 57.76 18 57.74 17.94ZM32 5.73002L53.25 18L32 30.27L10.75 18L32 5.73002ZM9.25 20.6L30.5 32.87V57.4L9.25 45.13V20.6ZM33.5 57.4V32.87L54.75 20.6V45.13L33.5 57.4Z"
        fill={disabled ? TRANSLUCENT_DIM_GREY : fill}
      />
    </svg>
  );
};
