export const BACKWALL_ANALYSIS = {
  sms: 'SMS',
  gost: 'GOST',
  astm: 'ASTM',
  distance: 'Distance',
  flaws: 'Flaws',
} as const;

export const BACKWALL_MODIFICATION_MODES = {
  insert: 'Insert',
  delete: 'Delete',
  modify: 'Modify',
  info: 'Info',
} as const;
export const BACKWALL_MODIFICATION_OPTIONS = Object.values(BACKWALL_MODIFICATION_MODES);

export const BACKWALL_MODIFICATION_OPTIONS_TITLES = {
  [BACKWALL_MODIFICATION_MODES.insert]: 'Insert',
  [BACKWALL_MODIFICATION_MODES.delete]: 'Delete',
  [BACKWALL_MODIFICATION_MODES.modify]: 'Modify',
  [BACKWALL_MODIFICATION_MODES.info]: 'Info',
};

export const BACKWALL_ZONES_TYPES = {
  dendritic: 'Dendritic',
  equiaxed: 'Equiaxed',
  other: 'Other',
};
export const BACKWALL_ZONES_TYPES_OPTIONS = Object.values(BACKWALL_ZONES_TYPES);

export const BACKWALL_ZONES_SELECTION_TYPE = {
  lines: 'Lines',
  rectangular: 'Rectangular',
};
export const BACKWALL_ZONES_SELECTION_TYPE_OPTIONS = Object.values(BACKWALL_ZONES_SELECTION_TYPE);

export const BACKWALL_TABS = {
  distance: 'Distance',
  flaws: 'Flaws',
} as const;

export const BACKWALL_FLAWS_STATISTICS_SEGREGATION_SIZES = ['<1 mm', '1-3 mm', '3-5 mm', '5-10 mm', '>10 mm'];

export const BACKWALL_FLAWS_THRESHOLDS = {
  lowerThreshold: 'Lower threshold',
  upperThreshold: 'Upper threshold',
};
