import React, { useEffect } from 'react';

import { MainGraphWindow } from '../../components/CommonGraphWindow';
import { FlawsSideMenu } from '../../components/FlawsSideMenu';
import { PaletteBar } from '../../components/PaletteBar';
import { IDs } from '../../consts';
import { useAppSelector, useFlaw } from '../../hooks';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import {
  selectAutomaticAnalysisStatus,
  selectIsReportMode,
  selectScanDataParameters,
  selectVolumeId,
} from '../../store/selectors';

export const Flaws = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const scanDataParameters = useAppSelector((state) => selectScanDataParameters(state, volumeId));
  const { handleFlawAnalysisChange, handleTakeFlawsAutoAnalysisScreenshots } = useFlaw();
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const isReportMode = useAppSelector(selectIsReportMode);

  useEffect(() => {
    if (isAutoAnalysis) {
      handleTakeFlawsAutoAnalysisScreenshots();
      return;
    }

    if (isReportMode) return;

    handleFlawAnalysisChange();
  }, [isAutoAnalysis, handleTakeFlawsAutoAnalysisScreenshots, isReportMode]);

  return (
    <DefaultLayout>
      <div className="page-container">
        {scanDataParameters && (
          <>
            <FlawsSideMenu />
            <MainGraphWindow id={IDs.flawsGraph} scanDataParameters={scanDataParameters} />
            <PaletteBar />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
