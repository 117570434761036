import './styles.scss';

import { Button, NumberInput, TranslatedText } from '@sms/plasma-ui';
import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { FLAWS_ANALYSIS, SLICE_TYPES } from '../../../consts';
import { useActionCreators, useAppSelector, useFlaw } from '../../../hooks';
import { selectFlawsAnalysis, selectFlawsThreshold, selectSliceType } from '../../../store/selectors';
import { RadioGroupData, TFlawsAnalysis } from '../../../types';
import { debounce } from '../../../utils';
import { NumberInputValue } from '../../RangeBar';
import { CustomRadioGroup } from '../../UI/CustomRadioGroup';

export const FlawsControl = () => {
  const { setFlawsThreshold, setFlawsAnalysis, forceAnalysisChange } = useActionCreators();
  const sliceType = useAppSelector(selectSliceType);
  const flawsAnalysis = useAppSelector(selectFlawsAnalysis);
  const threshold = useAppSelector(selectFlawsThreshold);
  const { handleFlawAnalysisChange, handleTakeFlawsReportScreenshots } = useFlaw();

  const isSMSAnalysisDisabled =
    sliceType === SLICE_TYPES.rect || sliceType === SLICE_TYPES.round || sliceType === SLICE_TYPES.cut;
  const isASTMAnalysisDisabled =
    sliceType === SLICE_TYPES.slabMiddle ||
    sliceType === SLICE_TYPES.slabLeft ||
    sliceType === SLICE_TYPES.slabRight ||
    sliceType === SLICE_TYPES.sampleLong;

  const debouncedFlawThresholdChange = useMemo(
    () =>
      debounce((newThreshold: number) => {
        handleFlawAnalysisChange({ newThreshold });
      }, 200),
    [handleFlawAnalysisChange],
  );

  const handleThresholdNumberInputChange = useCallback(
    (value: NumberInputValue) => {
      if (value === undefined) return;
      setFlawsThreshold(+value);
      debouncedFlawThresholdChange(+value);
    },
    [debouncedFlawThresholdChange],
  );

  const radioGroupChangeHandler = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const newAnalysis = e.target.value as TFlawsAnalysis;

      forceAnalysisChange(true);
      await handleTakeFlawsReportScreenshots();
      setFlawsAnalysis(newAnalysis);
      await handleFlawAnalysisChange({ newAnalysis });
      forceAnalysisChange(false);
    },
    [handleTakeFlawsReportScreenshots],
  );

  const flawsAnalysisData: RadioGroupData[] = useMemo(
    () => [
      {
        value: FLAWS_ANALYSIS.sms,
        label: FLAWS_ANALYSIS.sms,
        disabled: isSMSAnalysisDisabled,
      },
      {
        value: FLAWS_ANALYSIS.gost,
        label: FLAWS_ANALYSIS.gost,
      },
      {
        value: FLAWS_ANALYSIS.astm,
        label: FLAWS_ANALYSIS.astm,
        disabled: isASTMAnalysisDisabled,
      },
    ],
    [sliceType],
  );

  return (
    <div className="flaws__menu__control">
      <h3 className="flaws__menu__title side-menu__title">
        <TranslatedText textKey="side-menu.control" />
      </h3>
      <NumberInput
        name="flawsThreshold"
        decimalSeparator={'.'}
        step={1}
        hintMode="hidden"
        label={'Threshold'}
        maxWidth={'100%'}
        value={threshold}
        onChange={handleThresholdNumberInputChange}
      />
      <div style={{ marginBottom: '4px' }}>
        <Button
          className="auto-threshold-btn"
          title={'side-menu.auto-threshold'}
          onClick={() => handleFlawAnalysisChange({ newThreshold: -1 })}
        />
      </div>
      <CustomRadioGroup data={flawsAnalysisData} radioGroupValue={flawsAnalysis} onChange={radioGroupChangeHandler} />
    </div>
  );
};
