import './styles.scss';

import React, { memo, useCallback, useState } from 'react';

import { PrimaryPanel } from './PrimaryPanel';
import { SecondaryPanel } from './SecondaryPanel';

export const Toolbar = memo(function Toolbar() {
  const [
    isSecondaryToolbarActive,
    setIsSecondaryToolbarActive,
  ] = useState<boolean>(false);

  const handleActivateSecondaryToolbar = useCallback(() => {
    setIsSecondaryToolbarActive((prev) => !prev);
  }, []);

  return (
    <div className="toolbar">
      <PrimaryPanel
        collapseAction={handleActivateSecondaryToolbar}
        isPanelCollapsed={isSecondaryToolbarActive}
      />
      <SecondaryPanel isPanelActive={isSecondaryToolbarActive} />
    </div>
  );
});
