import { navigate } from '@sms/plasma-ui';
import React, { FC, ReactNode, useEffect, useLayoutEffect, useMemo } from 'react';

import {
  APP_ROUTES,
  FILE_TYPES,
  isProduction,
  LOCALSTORAGE_KEYS,
  MODAL_STATUS,
  MODAL_TITLE,
  REFRESH_TIME,
  SCAN_VIEWS,
} from '../../consts';
import { useActionCreators, useAppDispatch, useAppSelector, useCracks, useFlaw, useSegregation } from '../../hooks';
import { useFilesSearch } from '../../hooks/useFilesSearch';
import { useGetSliceImageQuery } from '../../store/api/adapt-image';
import { useSetDistanceDataMutation } from '../../store/api/backwall';
import { useLazyClearDatabaseQuery } from '../../store/api/database';
import {
  useCheckVolumeIdQuery,
  useLazyGetScanDataParametersQuery,
  useSetScanDataMutation,
} from '../../store/api/scandata';
import { useGetAScanMutation, useGetPaletteDataQuery, useSetProjectionsDataMutation } from '../../store/api/slice';
import {
  selectAutomaticAnalysisStatus,
  selectFileType,
  selectFlipped,
  selectIsMaxProjection,
  selectSampleFileName,
  selectSliceType,
  selectVolumeId,
} from '../../store/selectors';
import {
  checkAppRoute,
  checkFileType,
  createModalMessageBody,
  getRangeOfTime,
  isExceptionalRoute,
  removeFromLocalStorage,
} from '../../utils';

const {
  SMSApplicationCode,
  SMSToken,
  volumeInfo,
  scanInfo,
  ec2InstanceId,
  ec2InstanceType,
  ec2InstanceState,
  backendState,
} = LOCALSTORAGE_KEYS;

interface IProps {
  children: ReactNode;
  user: any;
  forceAppRender: () => void;
}

export const AppWrapper: FC<IProps> = ({ children, user, forceAppRender }) => {
  const dispatch = useAppDispatch();
  const {
    setGeometryAnalysisVisible,
    setMaxProjection,
    setSpinnerVisible,
    setUserName,
    setSampleFileName,
    setVolumeInfo,
    setMessageModal,
  } = useActionCreators();
  const { handleSegParamsChange } = useSegregation();
  const { handleFlawAnalysisChange } = useFlaw();
  const { handleCracksAnalysisChange } = useCracks();
  const { handleSearchFiles, totalItems } = useFilesSearch();
  const isMaxProjection = useAppSelector(selectIsMaxProjection);
  const sliceType = useAppSelector(selectSliceType);
  const volumeId = useAppSelector(selectVolumeId);
  const fileType = useAppSelector(selectFileType);
  const { isImageFileType, isIDVFileType } = useMemo(() => checkFileType(fileType), [fileType]);
  const flipped = useAppSelector(selectFlipped);
  const fileName = useAppSelector(selectSampleFileName) || '';
  const path = window.location.pathname;
  const { isSegregation, isFlaws, isCracks, isBackwall, isAutoAnalysisCredentials } = checkAppRoute(path);
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus) || isAutoAnalysisCredentials;
  const isExceptionalPage = isExceptionalRoute(path);
  const [getScanDataParametersTrigger, { data: scanData }] = useLazyGetScanDataParametersQuery();
  const [setDistanceImageTrigger] = useSetDistanceDataMutation({
    fixedCacheKey: 'backwallDistance',
  });
  const [setBProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceB',
  });
  const [setCProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceC',
  });
  const [setDProjectionDataTrigger] = useSetProjectionsDataMutation({
    fixedCacheKey: 'sliceD',
  });
  const [getAScanTrigger] = useGetAScanMutation({ fixedCacheKey: 'AScan' });
  const [setScanDataTrigger] = useSetScanDataMutation();
  const [clearDbTrigger] = useLazyClearDatabaseQuery();

  const { data: checkedVolumeId } = useCheckVolumeIdQuery(volumeId, { skip: !volumeId || isAutoAnalysis });

  useGetPaletteDataQuery(volumeId, { skip: !checkedVolumeId || isAutoAnalysis || !volumeId });
  useGetSliceImageQuery(volumeId, {
    skip: !checkedVolumeId || isIDVFileType || isAutoAnalysis || !volumeId,
  });

  useLayoutEffect(() => {
    if (!user) return;
    setUserName(user?.username);
    forceAppRender();
  }, [user]);

  useEffect(() => {
    if (checkedVolumeId === false) {
      removeFromLocalStorage(volumeInfo);
      removeFromLocalStorage(scanInfo);
      removeFromLocalStorage(backendState);
      dispatch({ type: 'logout' });
      setSampleFileName('');
      setVolumeInfo({
        volumeId: '',
        fileType: FILE_TYPES.volume,
      });
      navigate('/');
    }
  }, [checkedVolumeId]);

  useEffect(() => {
    const loadHandler = () => {
      const loadTime = new Date();
      const unloadTimeout = new Date(JSON.stringify(localStorage.getItem('unloadTime')));
      const refreshTime = loadTime.getTime() - unloadTimeout.getTime();

      if (refreshTime > REFRESH_TIME) {
        removeFromLocalStorage(SMSToken);
        removeFromLocalStorage(SMSApplicationCode);
        removeFromLocalStorage(volumeInfo);
        removeFromLocalStorage(scanInfo);
        removeFromLocalStorage(ec2InstanceId);
        removeFromLocalStorage(ec2InstanceType);
        removeFromLocalStorage(ec2InstanceState);

        setSampleFileName('');
        setVolumeInfo({
          volumeId: '',
          fileType: FILE_TYPES.volume,
        });

        navigate('/');
      }
    };

    window.addEventListener('load', loadHandler);

    return () => window.removeEventListener('load', loadHandler);
  }, []);

  useEffect(() => {
    (async () => {
      const response = await handleSearchFiles();

      if ('error' in response) {
        setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, 'modals.service-unavailable'));
      }
    })();
  }, []);

  useEffect(() => {
    if (isMaxProjection || isImageFileType || isAutoAnalysis) return;
    getAScanTrigger();
  }, [isMaxProjection, isImageFileType, isAutoAnalysis]);

  useEffect(() => {
    if (isAutoAnalysis || isExceptionalRoute(path)) return;

    if (path.split('/')[1] === APP_ROUTES.db) {
      const navigateToDb = async () => {
        navigate('/database');
        await clearDbTrigger();
      };

      navigateToDb();
      return;
    }

    if (!volumeId) return;

    if (isIDVFileType) navigate('/bcdplus');
    if (isImageFileType) {
      setMaxProjection(false);
      navigate('/adapt-image');
    }
  }, [volumeId, fileType, isAutoAnalysis]);

  useEffect(() => {
    if (isProduction || isAutoAnalysis) return;
    (async () =>
      await setScanDataTrigger({
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        name: 'Demo Billet',
        description: 'Sample taken yesterday at 4:20.',
        sha1Hash: 'ZuZNnb0WyFT+0jlVuFcUoR+kj1I=',
        uri: '/app/idvstorage/Demo_Billet.zip',
        s3Bucket: 'none',
        s3Key: 'none',
        sourceFileDeleted: false,
        originalFileSize: 1700,
        isDeleted: false,
        fileType: 'IDV',
        recipes: [],
        analysisResults: [],
        createdAt: getRangeOfTime().start,
        modifiedAt: getRangeOfTime().start,
      }))();
  }, [isAutoAnalysis]);

  useEffect(() => {
    if (isProduction || isAutoAnalysis) return;
    (async () =>
      await setScanDataTrigger({
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
        name: 'Pic',
        description: 'Sample taken yesterday at 4:20.',
        sha1Hash: 'ZuZNnb0WyFT+0jlVuFcUoR+kj2I=',
        uri: '/app/idvstorage/pic.jpg',
        s3Bucket: 'none',
        s3Key: 'none',
        sourceFileDeleted: false,
        originalFileSize: 1700,
        isDeleted: false,
        fileType: 'Image',
        recipes: [],
        analysisResults: [],
        createdAt: getRangeOfTime().start,
        modifiedAt: getRangeOfTime().start,
      }))();
  }, [isAutoAnalysis]);

  useEffect(() => {
    if (isProduction || isAutoAnalysis) return;
    (async () =>
      await setScanDataTrigger({
        id: '3fa85f64-5717-4562-b3fc-2c963f66af42',
        name: 'Sabic_2200',
        description: 'Sample taken yesterday at 4:20.',
        sha1Hash: 'ZuZNnb0WyFT+0jlVuFcUoR+km2I=',
        uri: '/app/idvstorage/Sabic_2200.zip',
        s3Bucket: 'none',
        s3Key: 'none',
        sourceFileDeleted: false,
        originalFileSize: 1700,
        isDeleted: false,
        fileType: 'IDV',
        recipes: [],
        analysisResults: [],
        createdAt: getRangeOfTime().start,
        modifiedAt: getRangeOfTime().start,
      }))();
  }, [isAutoAnalysis]);

  useEffect(() => {
    if (isProduction || isAutoAnalysis) return;
    (async () =>
      await setScanDataTrigger({
        id: '3fa85f64-5717-4562-b3fc-2c963f66af11',
        name: 'Demo_Bramme',
        fileType: 'IDV',
        description: 'Sample taken yesterday at 4:20.',
        sha1Hash: 'ZuZNnb0WyFT+0jlVuFcUoR+km7I=',
        uri: '/app/idvstorage/Demo_Bramme.zip',
        s3Bucket: 'none',
        s3Key: null,
        sourceFileDeleted: false,
        originalFileSize: 1700,
        isDeleted: false,
        recipes: [],
        analysisResults: [],
        createdAt: getRangeOfTime().start,
        modifiedAt: getRangeOfTime().start,
      }))();
  }, [isAutoAnalysis]);

  useEffect(() => {
    if (isProduction || isAutoAnalysis) return;
    (async () =>
      await setScanDataTrigger({
        id: '2fa85f64-5117-4562-b3fc-2c963f66af11',
        name: 'k8700_506',
        description: 'Sample taken yesterday at 4:20.',
        fileType: 'IDV',
        sha1Hash: 'ZuZNMnb1WyFT+0jlVdFcUoR+km7I=',
        uri: '/app/idvstorage/k8700_506.zip',
        s3Bucket: 'none',
        s3Key: 'none',
        sourceFileDeleted: false,
        originalFileSize: 1700,
        isDeleted: false,
        recipes: [],
        analysisResults: [],
        createdAt: getRangeOfTime().start,
        modifiedAt: getRangeOfTime().start,
      }))();
  }, [isAutoAnalysis]);

  useEffect(() => {
    if (isExceptionalPage) return;

    if (scanData && !sliceType) {
      if (isImageFileType || isAutoAnalysis) return;

      setGeometryAnalysisVisible(true);
    }
  }, [sliceType, scanData, isImageFileType, isAutoAnalysis]);

  useEffect(() => {
    if (!checkedVolumeId || isAutoAnalysis || !volumeId) return;
    setSpinnerVisible(true);
    (async () => await getScanDataParametersTrigger(volumeId))();
  }, [volumeId, checkedVolumeId, isAutoAnalysis]);

  useEffect(() => {
    if (!checkedVolumeId || !scanData || isAutoAnalysis) return;

    if (isIDVFileType) {
      Promise.all([
        setBProjectionDataTrigger(SCAN_VIEWS.B),
        setDProjectionDataTrigger(SCAN_VIEWS.D),
        setCProjectionDataTrigger(SCAN_VIEWS.C),
      ]).then(() => {
        if (isSegregation) {
          handleSegParamsChange();
          return;
        }
        if (isFlaws) {
          handleFlawAnalysisChange();
        }

        if (isCracks) {
          (async () => {
            await handleCracksAnalysisChange();
          })();

          return;
        }

        if (isBackwall) {
          setDistanceImageTrigger();
          return;
        }

        setSpinnerVisible(false);
      });
    }

    if (isImageFileType) {
      (async () => {
        await setCProjectionDataTrigger(SCAN_VIEWS.C);
        setSpinnerVisible(false);
      })();
    }
  }, [isMaxProjection, volumeId, scanData, fileType, flipped, checkedVolumeId, isAutoAnalysis]);

  return (
    <>
      <div className="sample_name">{fileName}</div>
      {children}
    </>
  );
};
