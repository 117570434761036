// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useRef, useState } from 'react';

import {
  BACKWALL_MODIFICATION_MODES,
  BLACK_PRIMARY,
  GREEN_LIGHT,
  IDs,
  INITIAL_COORDINATES,
  INITIAL_LINE_PARAMS,
  TRANSPARENT,
} from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { useAddDistanceLineMutation } from '../../../../../store/api/backwall';
import { selectDistanceModificationMode, selectDistanceZoneTypes } from '../../../../../store/selectors';
import { ISVGLineParams, TTupleOfTwoNumbers } from '../../../../../types';
import { convertCartesianModeCoordsInPxToMm, getBaseSvgElemStyles, ICartesianModeParams } from '../../../../../utils';

interface IProps {
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  stroke?: string;
}

export const BackwallLineInsertion: FC<IProps> = ({ stroke = BLACK_PRIMARY, cartesianModeParams, domains }) => {
  const modificationMode = useAppSelector(selectDistanceModificationMode);
  const selectedZoneTypes = useAppSelector(selectDistanceZoneTypes);
  const startDrawingCoords = useRef({ ...INITIAL_COORDINATES });
  const [currentLine, setCurrentLine] = useState<ISVGLineParams>(INITIAL_LINE_PARAMS);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [addDistanceLineTrigger] = useAddDistanceLineMutation();

  useEffect(() => {
    if (modificationMode !== BACKWALL_MODIFICATION_MODES.insert) return;

    const drawingArea = d3.select(`#${IDs.backwallGroupWrapper}`);

    function onMouseDown(this: SVGGElement, e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      startDrawingCoords.current.x = x;
      startDrawingCoords.current.y = y;

      setIsDrawing(true);
    }

    function onMouseMove(this: SVGGElement, e: MouseEvent) {
      if (!isDrawing) return;

      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      setCurrentLine({
        x1: startDrawingCoords.current.x,
        y1: startDrawingCoords.current.y,
        x2: x,
        y2: y,
      });
    }

    function onMouseUp(this: SVGGElement, e: MouseEvent) {
      const [x, y] = d3.pointer(e, this);

      if (startDrawingCoords.current.x === x && startDrawingCoords.current.y === y) {
        setIsDrawing(false);
        setCurrentLine(INITIAL_LINE_PARAMS);

        return;
      }

      addDistanceLineTrigger({
        start: convertCartesianModeCoordsInPxToMm(
          { x: startDrawingCoords.current.x, y: startDrawingCoords.current.y },
          cartesianModeParams,
          domains
        ),
        end: convertCartesianModeCoordsInPxToMm({ x, y }, cartesianModeParams, domains),
        zoneType: selectedZoneTypes[0],
      });

      setIsDrawing(false);
      setCurrentLine(INITIAL_LINE_PARAMS);
    }

    drawingArea.on('mousedown', onMouseDown).on('mousemove', onMouseMove).on('mouseup', onMouseUp);

    return () => drawingArea.on('mousedown', null).on('mousemove', null).on('mouseup', null);
  }, [
    domains,
    cartesianModeParams.realSampleWidth,
    cartesianModeParams.realSampleHeight,
    isDrawing,
    modificationMode,
    selectedZoneTypes,
  ]);

  return (
    <>
      <line
        x1={currentLine.x1}
        y1={currentLine.y1}
        x2={currentLine.x2}
        y2={currentLine.y2}
        style={getBaseSvgElemStyles(stroke, 1, TRANSPARENT)}
      />
      <g className={'distance-group'}>
        <line
          id={'line-x'}
          x1={startDrawingCoords.current.x}
          y1={0}
          x2={startDrawingCoords.current.x}
          y2={cartesianModeParams.realSampleHeight}
          style={getBaseSvgElemStyles(GREEN_LIGHT, isDrawing ? 1 : 0, TRANSPARENT)}
        />
        <line
          id={'line-y'}
          x1={0}
          y1={startDrawingCoords.current.y}
          x2={cartesianModeParams.realSampleWidth}
          y2={startDrawingCoords.current.y}
          style={getBaseSvgElemStyles(GREEN_LIGHT, isDrawing ? 1 : 0, TRANSPARENT)}
        />
      </g>
    </>
  );
};
