import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { useCallback } from 'react';

import { BLACK_PRIMARY, GREY_SECONDARY_EXTRA_LIGHT } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useLazyClearZonesZonesQuery } from '../../../store/api/zones';
import { selectVolumeId } from '../../../store/selectors';

export const ZonesControl = () => {
  const { clearZonesZone } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const [clearZonesTrigger] = useLazyClearZonesZonesQuery();

  const handleClick = useCallback(() => {
    clearZonesZone();
    clearZonesTrigger(volumeId);
  }, [volumeId]);

  return (
    <div className="zones__control">
      <h3 className="side-menu__title">
        <TranslatedText textKey="side-menu.control" />
      </h3>
      <Button
        title={'common.clear'}
        onClick={handleClick}
        style={{
          backgroundColor: 'transparent',
          borderColor: GREY_SECONDARY_EXTRA_LIGHT,
          color: BLACK_PRIMARY,
        }}
      />
    </div>
  );
};

