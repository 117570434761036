import { useCallback } from 'react';

import { BACKWALL_ANALYSIS, BACKWALL_TABS, IDENTIFIED_SNAPSHOTS, IDs } from '../consts';
import { useSetDistanceDataMutation } from '../store/api/backwall';
import { useSetBackwallFlawsParamsMutation } from '../store/api/backwall-flaws';
import { useTakeSnapshotMutation } from '../store/api/snaphot';
import {
  selectAutomaticAnalysisStatus,
  selectBackwallFlawsAutoAnalysis,
  selectBackwallSliderAndPaletteAutoAnalysis,
} from '../store/selectors';
import { TBackwallAnalysis } from '../types';
import { asyncSetTimeout, getBase64Snapshot, getShortBase64String } from '../utils';
import { useActionCreators } from './useActionCreators';
import { useAppSelector } from './useAppSelector';

export interface IBackwallFlawsChangeParams {
  lowerThreshold?: number;
  upperThreshold?: number;
}

export const useBackwall = () => {
  const autoAnalysisMode = false;
  const { setPaletteData, setMaxProjectionSliderRange, setBackwallFlaws, setBackwallTab } = useActionCreators();
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const backwallFlawsAutoAnalysisResults = useAppSelector(selectBackwallFlawsAutoAnalysis);
  const backwallAutoAnalysisPaletteAndRange = useAppSelector(selectBackwallSliderAndPaletteAutoAnalysis);
  const [setBackwallFlawsTrigger] = useSetBackwallFlawsParamsMutation();
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();
  const [setDistanceImageTrigger] = useSetDistanceDataMutation({
    fixedCacheKey: 'backwallDistance',
  });

  const handleBackwallFlawsChange = async ({ lowerThreshold, upperThreshold }: IBackwallFlawsChangeParams = {}) => {
    if (isAutoAnalysis) return;

    await setBackwallFlawsTrigger({
      lowerThreshold: lowerThreshold ?? 0,
      upperThreshold: upperThreshold ?? 0,
      autoAnalysisMode,
    });
  };

  const takeBackwallScreenshot = async (analysis: TBackwallAnalysis = BACKWALL_ANALYSIS.distance) => {
    const name =
      analysis === BACKWALL_ANALYSIS.distance
        ? IDENTIFIED_SNAPSHOTS.backwallDistance
        : IDENTIFIED_SNAPSHOTS.backwallFlaws;

    const imageBase64String = await getBase64Snapshot(`#${IDs.commonGraphInnerWrapper}`);
    const imageWidth = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetWidth ?? 0;
    const imageHeight = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetHeight ?? 0;

    await takeSnapshotTrigger({
      imageBase64String: getShortBase64String(imageBase64String),
      name,
      description: '',
      imageWidth,
      imageHeight,
    });
  };

  const handleTakeBackwallAutoAnalysisScreenshots = useCallback(async () => {
    if (!backwallAutoAnalysisPaletteAndRange) return;

    setPaletteData(backwallAutoAnalysisPaletteAndRange.palette);
    setMaxProjectionSliderRange({ sliceC: backwallAutoAnalysisPaletteAndRange.sliderRange });

    await asyncSetTimeout(async () => await takeBackwallScreenshot(), 300);

    if (!backwallFlawsAutoAnalysisResults) return;

    setBackwallTab(BACKWALL_TABS.flaws);
    setBackwallFlaws(backwallFlawsAutoAnalysisResults);

    await asyncSetTimeout(async () => await takeBackwallScreenshot(BACKWALL_ANALYSIS.flaws), 300);
  }, [backwallAutoAnalysisPaletteAndRange, backwallFlawsAutoAnalysisResults]);

  const handleTakeBackwallReportScreenshots = async (analysis: TBackwallAnalysis, isReportMode = false) => {
    if (isReportMode) {
      await setDistanceImageTrigger();
      await handleBackwallFlawsChange();
    }

    await asyncSetTimeout(async () => await takeBackwallScreenshot(analysis), 200);
  };

  return {
    handleBackwallFlawsChange,
    takeBackwallScreenshot,
    handleTakeBackwallAutoAnalysisScreenshots,
    setDistanceImageTrigger,
    handleTakeBackwallReportScreenshots,
  };
};
