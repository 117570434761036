import React, { FC } from 'react';

import { BLACK_PRIMARY, TRANSLUCENT_DIM_GREY } from '../../consts';
import { IZonesNewZoneParams } from '../../store/api/zones/types';
import { TTupleOfTwoNumbers } from '../../types';
import { getBaseSvgElemStyles, ICartesianModeParams, IGraphParams } from '../../utils';
import { Path } from '../Path';

interface IProps {
  zones: IZonesNewZoneParams[];
  domains: TTupleOfTwoNumbers,
  graphParams: IGraphParams,
  cartesianModeParams: ICartesianModeParams,
  isCartesianMode: boolean,
}

export const ForbiddenZones: FC<IProps> = ({ zones, cartesianModeParams, graphParams, domains, isCartesianMode }) => {
  return (
    <>
      {zones.length > 0 && zones.map((z, idx) => (
        <Path
          key={idx}
          data={Object.values(z)}
          cartesianModeParams={cartesianModeParams}
          graphParams={graphParams}
          domains={domains}
          isCartesianMode={isCartesianMode}
          style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSLUCENT_DIM_GREY)}
        />
      ))}
    </>
  );
};

