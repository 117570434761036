/* eslint-disable react/no-multi-comp */
import React, { FC, Fragment } from 'react';

import { BLACK_PRIMARY, GREEN_PRIMARY } from '../../../../consts';
import { useAppSelector } from '../../../../hooks';
import { selectBorderData } from '../../../../store/selectors';
import { selectGeometryBLines } from '../../../../store/selectors/geometry.selector';
import { TCoordinates, TTupleOfTwoNumbers } from '../../../../types';
import {
  getBaseSvgElemStyles,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
  IGraphParams,
} from '../../../../utils';
import { Path } from '../../../Path';

interface ILinesProps {
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  data: TCoordinates[];
}

interface IGeometryBLinesProps {
  volumeId: string;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  graphParams: IGraphParams;
}

const Lines: FC<ILinesProps> = ({ data, cartesianModeParams, domains }) => {
  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  return (
    <>
      {data.map((point, idx) => {
        const x = getXOffset(point.x);
        const y = getYOffset(point.y);
        return (
          <Fragment key={idx}>
            <line x1={x} y1={y} x2={x} y2={y + 20} stroke={BLACK_PRIMARY} />
            <line x1={x} y1={y} x2={x} y2={y - 20} stroke={BLACK_PRIMARY} />
            <text x={x + 5} y={y} fill={BLACK_PRIMARY}>{`B${idx}`}</text>
          </Fragment>
        );
      })}
    </>
  );
};

export const GeometryBLines: FC<IGeometryBLinesProps> = ({ volumeId, cartesianModeParams, domains, graphParams }) => {
  const { outerBorder } = useAppSelector((state) => selectBorderData(state, volumeId));
  const { bPointsLeft, bPointsRight } = useAppSelector((state) => selectGeometryBLines(state, volumeId));

  return (
    <>
      <Path
        cartesianModeParams={cartesianModeParams}
        data={outerBorder}
        domains={domains}
        graphParams={graphParams}
        isCartesianMode={true}
        style={getBaseSvgElemStyles(GREEN_PRIMARY, 1.5)}
      />
      <Lines data={bPointsLeft.concat(bPointsRight)} cartesianModeParams={cartesianModeParams} domains={domains} />
    </>
  );
};
