import { IIntersections } from '../types';

export const CRACKS_ANALYSIS = {
  sms: 'SMS',
  gost: 'GOST',
  astm: 'ASTM',
} as const;

export const CRACKS_TYPES = {
  halfWay: 'half_way',
  segregation: 'segregation',
  distortion: 'distortion',
  offCorner: 'OffCorner',
  nfns: 'NFN_S',
  nfnt: 'NFN_T',
  heatInfluence: 'heat_influence',
  nearSurface: 'NearSurface',
  tripplePoint: 'TripplePoint',
  transversal: 'Transversal',
  longitudinal: 'Longitudinal',
  crossSection: 'CrossSection',
  center: 'Center',
  other: 'Other',
} as const;

export const SLAB_MIDDLE_CRACK_TYPES = {
  longitudinal: 'Longitudinal',
  transversal: 'Transversal',
  nearSurface: 'NearSurface',
  center: 'Center',
  other: 'Other',
} as const;

export const SLAB_SIDES_CRACK_TYPES = {
  longitudinal: 'Longitudinal',
  nearSurface: 'NearSurface',
  offCorner: 'OffCorner',
  center: 'Center',
  nfns: 'NFN_S',
  nfnt: 'NFN_T',
  crossSection: 'CrossSection',
  tripplePoint: 'TripplePoint',
  other: 'Other',
} as const;

export const BILLET_RECT_CRACK_TYPES = {
  crossSection: 'CrossSection',
  offCorner: 'OffCorner',
  center: 'Center',
  other: 'Other',
} as const;

export const BILLET_ROUND_CRACK_TYPES = {
  crossSection: 'CrossSection',
  center: 'Center',
  other: 'Other',
} as const;

const {
  halfWay,
  crossSection,
  segregation,
  distortion,
  center,
  nfns,
  nfnt,
  nearSurface,
  tripplePoint,
  longitudinal,
  transversal,
  offCorner,
  other,
} = CRACKS_TYPES;

export const CRACK_TYPES_LABELS = {
  [tripplePoint]: 'Triple-Point crack',
  [halfWay]: 'Half way crack',
  [segregation]: 'Segregation',
  [nfns]: 'Narrow face near surface',
  [nfnt]: 'Narrow face near triple',
  [longitudinal]: 'Longitudinal',
  [nearSurface]: 'NearSurface',
  [transversal]: 'Transversal',
  [distortion]: 'Distortion',
  [offCorner]: 'OffCorner',
  [center]: 'Center',
  [other]: 'Other',
};

export const SLAB_MIDDLE_CRACK_TYPES_LABELS = {
  [transversal]: 'Transversal',
  [longitudinal]: 'Longitudinal',
  [center]: 'Center',
  [nearSurface]: 'NearSurface',
  [other]: 'Other',
};

export const SLAB_SIDES_CRACK_TYPES_LABELS = {
  [transversal]: 'Transversal',
  [longitudinal]: 'Longitudinal',
  [nearSurface]: 'NearSurface',
  [nfns]: 'Narrow face near surface',
  [nfnt]: 'Narrow face near triple',
  [offCorner]: 'OffCorner',
  [tripplePoint]: 'Triple-Point crack',
  [center]: 'Center',
  [other]: 'Other',
};

export const BILLET_ROUND_CRACK_TYPES_LABELS = {
  [center]: 'Center',
  [crossSection]: 'CrossSection',
  [other]: 'Other',
};

export const BILLET_RECT_CRACK_TYPES_LABELS = {
  [offCorner]: 'OffCorner',
  [crossSection]: 'CrossSection',
  [center]: 'Center',
  [other]: 'Other',
};

export const CRACKS_MODIFICATION_MODES = {
  insert: 'Insert',
  delete: 'Delete',
  modify: 'Modify',
  info: 'Info',
};

export const CRACKS_MODIFICATION_OPTIONS_TITLES = {
  [CRACKS_MODIFICATION_MODES.insert]: 'Insert',
  [CRACKS_MODIFICATION_MODES.delete]: 'Delete',
  [CRACKS_MODIFICATION_MODES.modify]: 'Modify',
  [CRACKS_MODIFICATION_MODES.info]: 'Info',
};

export const INIT_CRACK_INFO_INTERSECTIONS: IIntersections = {
  leftTop: {
    x: {
      deltas: [],
      minDelta: 0,
      delta: 0,
    },
    y: {
      deltas: [],
      minDelta: 0,
      delta: 0,
    },
  },
  rightBottom: {
    x: {
      deltas: [],
      minDelta: 0,
      delta: 0,
    },
    y: {
      deltas: [],
      minDelta: 0,
      delta: 0,
    },
  },
};

export const CRACKS_MODIFICATION_OPTIONS = Object.values(CRACKS_MODIFICATION_MODES);
