import React, { MouseEvent } from 'react';

import { recipeTableColumns } from '../../consts';
import { IRecipeTemplateInfo } from '../../store/api/recipes/types';
import { Table } from '../UI/Table';
import { TableRow } from '../UI/Table/TableRow';
import { TableCell } from '../UI/Table/TableRow/TableCell';

interface IProps {
  selectedRecipe: IRecipeTemplateInfo | null;
  onRowClick: (e: MouseEvent<HTMLDivElement>, row: IRecipeTemplateInfo) => void;
  data: IRecipeTemplateInfo[];
}

export const RecipesTemplatesTable = ({ selectedRecipe, onRowClick, data }: IProps) => {
  return (
    <Table columns={recipeTableColumns}>
      {[...data]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((row) => (
          <TableRow key={row.id} rowData={row} onClick={onRowClick} isSelected={selectedRecipe?.id === row.id}>
            {recipeTableColumns.map(({ field, width, title }) => (
              <TableCell key={title} field={field} width={width} value={row[field]} />
            ))}
          </TableRow>
        ))}
    </Table>
  );
};
