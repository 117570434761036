import { Icon } from '@sms/plasma-ui/lib/components/Icon/Icon';
import React from 'react';

import { Icons } from '../components/svg';
import { BLUE_PRIMARY } from './colors';

export const MODAL_STATUS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  NOTIFICATION: 'notification',
} as const;

export const MODAL_ICONS = {
  [MODAL_STATUS.ERROR]: <Icons.Cross />,
  [MODAL_STATUS.WARNING]: <Icons.Warning />,
  [MODAL_STATUS.SUCCESS]: <Icons.Check />,
  [MODAL_STATUS.NOTIFICATION]: <Icon name="bell" style={{ color: BLUE_PRIMARY }} />,
};

export const DEFAULT_ERROR_MESSAGE = 'modals.message.error-message';

export const MODAL_TITLE = {
  expertOperation: 'modals.message.expert-operation',
  error: 'modals.message.error',
  warning: 'modals.message.warning',
  operation: 'modals.operation',
  notification: 'modals.notification',
};

export const CLOUD_MESSAGES = {
  checkingInstance: 'modals.message.checking-instance',
  stopingInstance: 'modals.message.stopping-instance',
  startingInstance: 'modals.message.starting-instance',
  checkingStatus: 'modals.message.checking-status',
  startingBackend: 'modals.message.starting-backend',
};
