import './styles.scss';

import { Tabs, TranslatedText } from '@sms/plasma-ui';
import TabPane from '@sms/plasma-ui/lib/components/Tabs/TabPane';
import React, { useEffect } from 'react';

import { BACKWALL_TABS, REPORT_TABS } from '../../consts';
import { useActionCreators, useAppSelector, useBackwall } from '../../hooks';
import { selectBackwallTab } from '../../store/selectors';
import { TBackwallTabs } from '../../types';
import { BackwallDistance } from './BackwallDistance';
import { BackwallFlaws } from './BackwallFlaws';

const { distance, flaws } = BACKWALL_TABS;

export const BackwallSideMenu = () => {
  const { setBackwallTab, forceAnalysisChange, setMadeAnalysis } = useActionCreators();
  const { handleTakeBackwallReportScreenshots } = useBackwall();
  const backwallTab = useAppSelector(selectBackwallTab);

  const handleTabChange = async (activeTab: string) => {
    forceAnalysisChange(true);
    await handleTakeBackwallReportScreenshots(backwallTab);
    forceAnalysisChange(false);
    setBackwallTab(activeTab as TBackwallTabs);
  };

  useEffect(() => {
    setMadeAnalysis({ analysis: REPORT_TABS.backwall, id: backwallTab });
  }, [backwallTab]);

  return (
    <div className="backwall__menu">
      <Tabs activeKey={backwallTab} onChange={(activeTab) => handleTabChange(activeTab)}>
        <TabPane tab={<TranslatedText textKey="Macrostructure" />} key={distance}>
          <BackwallDistance />
        </TabPane>
        <TabPane tab={<TranslatedText textKey="pages.flaws" />} key={flaws}>
          <BackwallFlaws />
        </TabPane>
      </Tabs>
    </div>
  );
};
