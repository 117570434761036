//@ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { IDs, RED_PRIMARY, YELLOW_PRIMARY } from '../../../consts';
import { ISliceImageCroppingCoords } from '../../../store/api/adapt-image/types';
import { ISVGCircleParams, TTupleOfTwoNumbers } from '../../../types';
import {
  convertPureCoordsInPxToMm,
  getBaseSvgElemStyles,
  getPureXOffsetFunc,
  getPureYOffsetFunc,
  getToFixedValue,
  IGraphParams,
} from '../../../utils';

interface IProps {
  coords: ISliceImageCroppingCoords;
  isVisible: boolean;
  graphParams: IGraphParams;
  pxDomains: TTupleOfTwoNumbers;
  setCoords: (coords: Partial<ISliceImageCroppingCoords>) => void;
}

export const CroppingArea: FC<IProps> = ({ coords, isVisible, graphParams, pxDomains, setCoords }) => {
  const { leftBottomPoint, rightBottomPoint, rightTopPoint, leftTopPoint } = coords;

  const getXOffset = getPureXOffsetFunc(graphParams, pxDomains);
  const getYOffset = getPureYOffsetFunc(graphParams, pxDomains);

  const leftBottomX = getXOffset(leftBottomPoint.i);
  const leftBottomY = getYOffset(leftBottomPoint.j);
  const leftTopX = getXOffset(leftTopPoint.i);
  const leftTopY = getYOffset(leftTopPoint.j);
  const rightTopX = getXOffset(rightTopPoint.i);
  const rightTopY = getYOffset(rightTopPoint.j);
  const rightBottomX = getXOffset(rightBottomPoint.i);
  const rightBottomY = getYOffset(rightBottomPoint.j);

  useEffect(() => {
    if (!isVisible) return;
    if (
      leftBottomX !== 0 &&
      leftBottomY !== 0 &&
      leftTopX !== 0 &&
      leftTopY !== 0 &&
      rightTopX !== 0 &&
      rightTopY !== 0 &&
      rightBottomX !== 0 &&
      rightBottomY !== 0
    )
      return;

    const x0 = getToFixedValue(pxDomains[0] * 0.05, 0);
    const y0 = getToFixedValue(pxDomains[1] * 0.05, 0);
    const x1 = getToFixedValue(pxDomains[0] * 0.95, 0);
    const y1 = getToFixedValue(pxDomains[1] * 0.95, 0);

    setCoords({
      leftBottomPoint: { i: x0, j: y0 },
      leftTopPoint: { i: x0, j: y1 },
      rightTopPoint: { i: x1, j: y1 },
      rightBottomPoint: { i: x1, j: y0 },
    });
  }, [
    isVisible,
    leftBottomX,
    leftBottomY,
    leftTopX,
    leftTopY,
    pxDomains,
    rightBottomX,
    rightBottomY,
    rightTopX,
    rightTopY,
  ]);

  useEffect(() => {
    if (!isVisible) return;

    const firstAuxiliaryPoint = d3.select(`#${IDs.cropArea} .${IDs.firstAuxiliaryPoint}`);
    const secondAuxiliaryPoint = d3.select(`#${IDs.cropArea} .${IDs.secondAuxiliaryPoint}`);
    const thirdAuxiliaryPoint = d3.select(`#${IDs.cropArea} .${IDs.thirdAuxiliaryPoint}`);
    const fourthAuxiliaryPoint = d3.select(`#${IDs.cropArea} .${IDs.fourthAuxiliaryPoint}`);
    const points = [firstAuxiliaryPoint, secondAuxiliaryPoint, thirdAuxiliaryPoint, fourthAuxiliaryPoint];

    firstAuxiliaryPoint.datum({ cx: leftBottomX, cy: leftBottomY }).attr('cursor', 'grab');

    secondAuxiliaryPoint.datum({ cx: leftTopX, cy: leftTopY }).attr('cursor', 'grab');

    thirdAuxiliaryPoint.datum({ cx: rightTopX, cy: rightTopY }).attr('cursor', 'grab');

    fourthAuxiliaryPoint.datum({ cx: rightBottomX, cy: rightBottomY }).attr('cursor', 'grab');

    function dragstarted(this: SVGCircleElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent, d: ISVGCircleParams) {
      const { x, y } = event;

      const convertedCoords = convertPureCoordsInPxToMm({ x, y }, graphParams, pxDomains);

      const finalCoords = {
        i: getToFixedValue(convertedCoords.x, 0),
        j: getToFixedValue(convertedCoords.y, 0),
      };

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = x))
          .attr('cy', (d.cy = y));

        setCoords({
          leftBottomPoint: finalCoords,
        });
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = x))
          .attr('cy', (d.cy = y));

        setCoords({
          leftTopPoint: finalCoords,
        });
      }

      if (d3.select(this).node().classList.value === IDs.thirdAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = x))
          .attr('cy', (d.cy = y));

        setCoords({
          rightTopPoint: finalCoords,
        });
      }

      if (d3.select(this).node().classList.value === IDs.fourthAuxiliaryPoint) {
        d3.select(this)
          .attr('cx', (d.cx = x))
          .attr('cy', (d.cy = y));

        setCoords({
          rightBottomPoint: finalCoords,
        });
      }
    }

    function dragended(this: SVGCircleElement) {
      d3.select(this).attr('cursor', 'grab');
    }

    points.forEach((line) => {
      line.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
    });
  }, [
    isVisible,
    graphParams,
    pxDomains,
    leftBottomX,
    leftBottomY,
    leftTopX,
    leftTopY,
    rightTopX,
    rightTopY,
    rightBottomX,
    rightBottomY,
    setCoords,
  ]);

  return (
    <>
      {isVisible && (
        <g id={IDs.cropArea}>
          <path
            d={`M ${leftBottomX} ${leftBottomY}
           L ${leftTopX} ${leftTopY}
           L ${rightTopX} ${rightTopY}
           L ${rightBottomX} ${rightBottomY} Z`}
            style={{
              ...getBaseSvgElemStyles(YELLOW_PRIMARY, 2),
              strokeDasharray: 5,
            }}
          />
          <circle className={IDs.firstAuxiliaryPoint} cx={leftBottomX} cy={leftBottomY} r={5} fill={RED_PRIMARY} />
          <circle className={IDs.secondAuxiliaryPoint} cx={leftTopX} cy={leftTopY} r={5} fill={RED_PRIMARY} />
          <circle className={IDs.thirdAuxiliaryPoint} cx={rightTopX} cy={rightTopY} r={5} fill={RED_PRIMARY} />
          <circle className={IDs.fourthAuxiliaryPoint} cx={rightBottomX} cy={rightBottomY} r={5} fill={RED_PRIMARY} />
        </g>
      )}
    </>
  );
};
