import { useCallback } from 'react';

import { FLAWS_ANALYSIS, IDENTIFIED_SNAPSHOTS, IDs } from '../consts';
import {
  useSetFlawsASTMParamsMutation,
  useSetFlawsGOSTParamsMutation,
  useSetFlawsSMSParamsMutation,
} from '../store/api/flaws';
import { useTakeSnapshotMutation } from '../store/api/snaphot';
import {
  selectFlawsAnalysis,
  selectFlawsAutomaticAnalysisResult,
  selectFlawsSliderAndPaletteAutoAnalysis,
  selectIsFlawsFilterActive,
  selectIsFlawsZonesReset,
  selectVolumeId,
} from '../store/selectors';
import { TFlawsAnalysis } from '../types';
import { asyncSetTimeout, getBase64Snapshot, getShortBase64String } from '../utils';
import { useActionCreators } from './useActionCreators';
import { useAppSelector } from './useAppSelector';
import { useAutoAnalysisNavigate } from './useAutoAnalysisNavigate';

interface IParamsChangeParams {
  newThreshold?: number;
  newAnalysis?: TFlawsAnalysis;
  forcedZonesReset?: boolean;
  forcedFilterActive?: boolean;
}

export const useFlaw = () => {
  const volumeId = useAppSelector(selectVolumeId);
  const isZonesReset = useAppSelector(selectIsFlawsZonesReset);
  const isFilterActive = useAppSelector(selectIsFlawsFilterActive);
  const flawAnalysis = useAppSelector(selectFlawsAnalysis);
  const autoAnalysisMode = false;
  const flawsAutoAnalysisResults = useAppSelector(selectFlawsAutomaticAnalysisResult);
  const flawsAutoAnalysisPaletteAndRange = useAppSelector(selectFlawsSliderAndPaletteAutoAnalysis);
  const [setSMSParamsTrigger] = useSetFlawsSMSParamsMutation();
  const [setGOSTParamsTrigger] = useSetFlawsGOSTParamsMutation();
  const [setASTMParamsTrigger] = useSetFlawsASTMParamsMutation();
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();
  const { setFlawsAnalysis, setFlaws, setPaletteData, setMaxProjectionSliderRange } = useActionCreators();

  const autoAnalysisNavigate = useAutoAnalysisNavigate();

  const takeFlawReportScreenshot = useCallback(async (analysis: TFlawsAnalysis) => {
    const imageBase64String = await getBase64Snapshot(`#${IDs.commonGraphInnerWrapper}`);
    let name = '';

    const imageWidth = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetWidth ?? 0;
    const imageHeight = document.getElementById(IDs.commonGraphInnerWrapper)?.offsetHeight ?? 0;

    const body = {
      name,
      imageBase64String: getShortBase64String(imageBase64String),
      description: '',
      imageWidth,
      imageHeight,
    };

    switch (analysis) {
      case FLAWS_ANALYSIS.sms:
        name = IDENTIFIED_SNAPSHOTS.flawsSms;
        break;
      case FLAWS_ANALYSIS.gost:
        name = IDENTIFIED_SNAPSHOTS.flawsGost;
        break;
      case FLAWS_ANALYSIS.astm:
        name = IDENTIFIED_SNAPSHOTS.flawsAstm;
        break;
      default:
        name = '';
    }

    body.name = name;

    await takeSnapshotTrigger(body);
  }, []);

  const handleFlawAnalysisChange = useCallback(
    async ({ newThreshold, newAnalysis, forcedZonesReset, forcedFilterActive }: IParamsChangeParams = {}) => {
      switch (newAnalysis ?? flawAnalysis) {
        case FLAWS_ANALYSIS.sms:
          await setSMSParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            isZonesReset: forcedZonesReset ?? isZonesReset,
            isFilterActive: forcedFilterActive ?? isFilterActive,
            autoAnalysisMode,
          });
          break;
        case FLAWS_ANALYSIS.gost:
          await setGOSTParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            isZonesReset: forcedZonesReset ?? isZonesReset,
            autoAnalysisMode,
          });
          break;
        case FLAWS_ANALYSIS.astm:
          await setASTMParamsTrigger({
            volumeId,
            threshold: newThreshold ?? 0,
            isZonesReset: forcedZonesReset ?? isZonesReset,
            autoAnalysisMode,
          });
          break;
        default:
          return;
      }
    },
    [isZonesReset, isFilterActive, flawAnalysis, volumeId, autoAnalysisMode],
  );

  const handleTakeFlawsAutoAnalysisScreenshots = useCallback(async () => {
    if (!flawsAutoAnalysisResults?.length || !flawsAutoAnalysisPaletteAndRange) return;

    setPaletteData(flawsAutoAnalysisPaletteAndRange.palette);
    setMaxProjectionSliderRange({ sliceC: flawsAutoAnalysisPaletteAndRange.sliderRange });

    for (const { analysis, data } of flawsAutoAnalysisResults) {
      if (!data) return;

      setFlawsAnalysis(analysis);
      setFlaws(data);

      await asyncSetTimeout(async () => await takeFlawReportScreenshot(analysis), 300);
    }

    autoAnalysisNavigate();
  }, [flawsAutoAnalysisResults, flawsAutoAnalysisPaletteAndRange, takeFlawReportScreenshot]);

  const handleTakeFlawsReportScreenshots = useCallback(async () => {
    await takeFlawReportScreenshot(flawAnalysis);
  }, [flawAnalysis]);

  return {
    handleFlawAnalysisChange,
    handleTakeFlawsAutoAnalysisScreenshots,
    handleTakeFlawsReportScreenshots,
  };
};
