export const replaceFalsyObjectsWithNull = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = replaceFalsyObjectsWithNull(obj[key]);

      const allFalsy = key === 'name' ? !obj.name.value : !Object.values(obj[key]).some(Boolean);

      if (allFalsy) {
        obj[key] = null;
      }
    }
  }

  return obj;
};
