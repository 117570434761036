import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IInfoData } from '../../types';

const initialState: IInfoData = {
  id: '',
  scanDataFileInfoId: '',
  manufacturerData: {
    id: '',
    sampleInfoId: '',
    producer: '',
    site: '',
    plant: '',
    productionDate: '',
    qualityCode: 0,
    steelGrade: '',
  },
  materialAnalysis: {
    id: '',
    sampleInfoId: '',
    c: 0,
    si: 0,
    mn: 0,
    p: 0,
    s: 0,
    n: 0,
    al: 0,
    cu: 0,
    cr: 0,
    ni: 0,
    v: 0,
    ti: 0,
    nb: 0,
    b: 0,
    mo: 0,
    as: 0,
    sn: 0,
    ca: 0,
    h2: 0,
  },
  productionData: {
    id: '',
    sampleInfoId: '',
    heatNumber: 0,
    sequence: 0,
    slabId: 0,
    slabSampleId: 0,
    numberOfSamples: 0,
    castingMachine: 0,
    tundishSuperheat: 0,
    slabWidth: 0,
    slabThickness: 0,
    castingSpeed: 0,
    specificSprayCoolingCurve: 0,
    thermalTaper: 0,
    thermalTaperParameters: 0,
    numberOfSRSegments: 0,
    softReductionRate: 0,
    softReductionParameters: 0,
    moldOscillationFrequency: 0,
    negativeStrip: 0,
  },
  remarks: '',
};

export const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setInitialInfo: (state, action: PayloadAction<IInfoData>) => {
      Object.assign(state, action.payload);
    },
    setInfoField: (
      state,
      action: PayloadAction<{
        section: string;
        id: string;
        value: string | number | boolean;
      }>
    ) => {
      const { section, id, value } = action.payload;
      // @ts-ignore
      state[section][id] = value;
    },
    setInfoRemarks: (state, action: PayloadAction<string>) => {
      state.remarks = action.payload;
    },
  },
});

export const { actions: infoActions, reducer: info } = infoSlice;
