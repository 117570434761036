import './styles.scss';

import React, { FC, useMemo } from 'react';

import { IDs, SCAN_VIEWS } from '../../consts';
import { useAppSelector, useScanViewRangeSlider } from '../../hooks';
import { ISampleDataParameters } from '../../store/api/scandata/types';
import { selectFileType } from '../../store/selectors';
import { checkFileType, getScanDataResolution } from '../../utils';
import { RangeBar } from '../RangeBar';
import { MainGraph } from './CommonGraph';
import { MainGraphTitle } from './CommonGraphTitle';

interface IProps {
  id: string;
  scanDataParameters: ISampleDataParameters;
}

export const MainGraphWindow: FC<IProps> = ({ id, scanDataParameters }) => {
  const fileType = useAppSelector(selectFileType);
  const { isIDVFileType } = useMemo(() => checkFileType(fileType), [fileType]);
  const {
    handleSliderRangeChange,
    handleNumberInputChange,
    size,
    isMaxProjection,
    range: { start, end },
  } = useScanViewRangeSlider(SCAN_VIEWS.C, scanDataParameters);

  return (
    <div className="common__graph__wrapper" id={IDs.commonGraphWrapper}>
      {isIDVFileType && (
        <RangeBar
          rangeSliderId={`rangeSlider-${id}`}
          size={size}
          isMaxProjection={isMaxProjection}
          rangeSlideDisabled={!isMaxProjection}
          upperValue={end}
          lowerValue={start}
          value={[start, end]}
          handleSliderRangeChange={handleSliderRangeChange}
          handleNumberInputChange={handleNumberInputChange}
          precision={2}
          decimalSeparator={'.'}
          isReversed
          step={getScanDataResolution(scanDataParameters, SCAN_VIEWS.C)}
        />
      )}
      <div id={IDs.commonGraphInnerWrapper}>
        <MainGraphTitle hasRange={isIDVFileType} />
        <MainGraph id={id} scanDataParameters={scanDataParameters} />
      </div>
    </div>
  );
};
