// @ts-ignore
import * as d3 from 'd3';
import React, { useEffect, useMemo, useRef } from 'react';

import {
  CRACKS_MODIFICATION_MODES,
  IDs,
  TRANSLUCENT_DIM_GREY,
  TRANSPARENT,
} from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { useModifyCrackMutation } from '../../../../../store/api/cracks';
import { TCrackType } from '../../../../../store/api/cracks/types';
import { selectCracksModificationMode } from '../../../../../store/selectors';
import { RectParams } from '../../../../../types';
import { getBaseSvgElemStyles } from '../../../../../utils';
import { getColorForMatchedCrackType } from '../../../../../utils/cracks';

interface Props {
  id: number;
  x0: number;
  y0: number;
  width: number;
  height: number;
  wRatio: number;
  hRatio: number;
  crackType: TCrackType;
  onCrackSelection: () => void;
  isSelected: boolean;
}

export const CrackRectangle = ({
  id,
  x0,
  y0,
  width,
  height,
  wRatio,
  hRatio,
  crackType,
  onCrackSelection,
  isSelected,
}: Props) => {
  const modificationMode = useAppSelector(selectCracksModificationMode);
  const [modifyCrackTrigger] = useModifyCrackMutation();

  const x1 = x0 + width;
  const y1 = y0 + height;

  const currentRect = useRef<RectParams>({
    x: x0,
    y: y0,
    width,
    height,
  });

  const color = useMemo(() => getColorForMatchedCrackType(crackType), [
    crackType,
  ]);

  useEffect(() => {
    if (modificationMode !== CRACKS_MODIFICATION_MODES.modify || !isSelected) {
      return;
    }

    const rect = d3.select(`#${IDs.crack}${id} rect`);
    const firstAuxiliaryPoint = d3.select(
      `#${IDs.crack}${id} .${IDs.firstAuxiliaryPoint}`
    );
    const secondAuxiliaryPoint = d3.select(
      `#${IDs.crack}${id} .${IDs.secondAuxiliaryPoint}`
    );

    const points = [firstAuxiliaryPoint, secondAuxiliaryPoint];
    points.forEach((point) => {
      point.attr('cursor', 'grab');
    });

    function dragstarted(this: SVGCircleElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent) {
      const [x, y] = d3.pointer(event, this);
      d3.select(this).attr('cx', x).attr('cy', y);

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        currentRect.current = {
          x: Math.min(x, x1),
          y: Math.min(y, y1),
          width: Math.abs(x - x1),
          height: Math.abs(y - y1),
        };
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        currentRect.current = {
          x: Math.min(x, x0),
          y: Math.min(y, y0),
          width: Math.abs(x - x0),
          height: Math.abs(y - y0),
        };
      }

      const { x: cx, y: cy, width, height } = currentRect.current;
      rect
        .attr('x', cx)
        .attr('y', cy)
        .attr('width', width)
        .attr('height', height);
    }

    function dragended(this: SVGCircleElement) {
      d3.select(this).attr('cursor', 'grab');

      const { x, y, width, height } = currentRect.current;

      modifyCrackTrigger({
        id,
        crackBox: {
          x: x * wRatio,
          y: y * hRatio,
          width: width * wRatio,
          height: height * hRatio,
        },
        crackType,
      });
    }

    points.forEach((point) => {
      point.call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );
    });

    return () => {
      points.forEach((point) => {
        point.call(
          d3.drag().on('start', null).on('drag', null).on('end', null)
        );
      });
    };
  }, [
    id,
    x0,
    y0,
    x1,
    y1,
    modificationMode,
    isSelected,
    crackType,
    hRatio,
    wRatio,
  ]);

  return (
    <>
      <g id={`${IDs.crack}${id}`}>
        <rect
          x={x0}
          y={y0}
          width={width}
          height={height}
          style={getBaseSvgElemStyles(
            color,
            isSelected ? 1.5 : 1,
            isSelected ? TRANSLUCENT_DIM_GREY : TRANSPARENT
          )}
          onClick={onCrackSelection}
        />
        {modificationMode === CRACKS_MODIFICATION_MODES.modify && isSelected && (
          <>
            <circle
              className={IDs.firstAuxiliaryPoint}
              cx={x0}
              cy={y0}
              r={3}
              style={getBaseSvgElemStyles(TRANSPARENT, 0, color)}
            />
            <circle
              className={IDs.secondAuxiliaryPoint}
              cx={x1}
              cy={y1}
              r={3}
              style={getBaseSvgElemStyles(TRANSPARENT, 0, color)}
            />
          </>
        )}
      </g>
    </>
  );
};
