import './styles.scss';

import React, { FC } from 'react';

interface IProps {
  isVisible: boolean;
}

export const DisableScreenModal: FC<IProps> = ({ isVisible }) => {
  if (!isVisible) return null;

  return <div className='disable-screen__modal' />;
};
