import './styles.scss';

import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import React, { FC } from 'react';

import { MenuListItem } from '../../../../types';

interface IDropdownMenuProps {
  list: MenuListItem[];
}

export const DropdownMenu: FC<IDropdownMenuProps> = ({ list }) => {
  return (
    <ul className="dropdown__menu__list">
      {list.map(({ name, onClick, id }) => (
        <li key={name} onClick={() => onClick(id)}>
          <TranslatedText textKey={name} />
        </li>
      ))}
    </ul>
  );
};
