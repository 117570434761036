import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { v4 as uuidv4 } from 'uuid';

import { ec2AutoInstanceHeader, MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import {
  createModalMessageBody,
  formatRecipeData,
  getFilteredAnalysisStagesJSON,
  getISODateWithTimezone,
} from '../../../utils';
import { allActions } from '../../actions';
import { TypedRootState } from '../../types';
import { api } from '../index';
import { IRecipeTemplateInfo, IRecipeTemplateInfoResponse } from '../recipes/types';

const { setRecipeInitialInfo, setMessageModal } = allActions;

export const recipesTemplateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllRecipeTemplates: build.query<IRecipeTemplateInfo[], void>({
      async queryFn(_, __, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'recipe/template/all',
          headers: ec2AutoInstanceHeader,
        });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IRecipeTemplateInfo[];

        return { data };
      },
      providesTags: ['Recipe Templates'],
    }),
    getRecipeTemplate: build.query<IRecipeTemplateInfoResponse, { id: string; showResults: boolean }>({
      async queryFn({ id, showResults }, { dispatch }, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `recipe/template?recipeTemplateId=${id}`,
          headers: ec2AutoInstanceHeader,
        });

        if (result?.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const data = result.data as IRecipeTemplateInfoResponse;

        if (showResults) {
          const recipeStateData = formatRecipeData(data);

          dispatch(setRecipeInitialInfo(recipeStateData));
        }

        return { data };
      },
    }),
    saveRecipeTemplate: build.mutation<void, void>({
      async queryFn(_, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          recipes: { sliceType, performAnalysis, name, description, analysisStages },
        } = getState() as TypedRootState;

        const analysisStagesString = getFilteredAnalysisStagesJSON(analysisStages, performAnalysis, sliceType);

        const result = await fetchWithBQ({
          url: 'recipe/template',
          method: 'POST',
          headers: ec2AutoInstanceHeader,
          body: {
            id: uuidv4(),
            name,
            description,
            modifiedAt: getISODateWithTimezone(new Date()),
            createdAt: getISODateWithTimezone(new Date()),
            analysisStages: analysisStagesString,
          },
        });

        dispatch(
          setMessageModal(
            createModalMessageBody(MODAL_STATUS.SUCCESS, MODAL_TITLE.notification, 'modals.message.save-recipe'),
          ),
        );

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Recipe Templates'],
    }),
    updateRecipeTemplate: build.mutation<void, void | string>({
      async queryFn(id, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const {
          recipes: {
            sliceType,
            performAnalysis,
            selectedFiles,
            isSEPIncluded,
            selectedRecipes,
            analysisStages,
            ...recipe
          },
        } = getState() as TypedRootState;

        let recipeInfo = recipe;

        if (id) {
          const { data } = await dispatch(
            recipesTemplateApi.endpoints.getRecipeTemplate.initiate({ id, showResults: false }),
          );

          if (data) {
            recipeInfo = data;
          }
        }

        const analysisStagesString = getFilteredAnalysisStagesJSON(analysisStages, performAnalysis, sliceType);

        const result = await fetchWithBQ({
          url: 'recipe/template',
          method: 'PUT',
          headers: ec2AutoInstanceHeader,
          body: {
            ...recipeInfo,
            modifiedAt: getISODateWithTimezone(new Date()),
            analysisStages: analysisStagesString,
          },
        });

        dispatch(
          setMessageModal(
            createModalMessageBody(MODAL_STATUS.SUCCESS, MODAL_TITLE.notification, 'modals.message.update-recipe'),
          ),
        );

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Recipe Templates'],
    }),
    deleteRecipeTemplate: build.mutation<void, string>({
      async queryFn(id, _, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `recipe/template?recipeTemplateId=${id}`,
          headers: ec2AutoInstanceHeader,
          method: 'DELETE',
        });

        return result as QueryReturnValue<void, FetchBaseQueryError, unknown>;
      },
      invalidatesTags: ['Recipe Templates'],
    }),
  }),
});

export const {
  useGetAllRecipeTemplatesQuery,
  useLazyGetRecipeTemplateQuery,
  useSaveRecipeTemplateMutation,
  useUpdateRecipeTemplateMutation,
  useDeleteRecipeTemplateMutation,
} = recipesTemplateApi;
