import { Box, Edges } from '@react-three/drei';
import React, { FC, memo } from 'react';

import { BuildTuple } from '../../../types';

interface IProps {
  boxArgs: BuildTuple<3, number>;
  scale: number;
}

export const OriginBox: FC<IProps> = memo(function OuterBox({ boxArgs, scale }) {
  return (
    <group position={[0, 0, 0]} rotation={[0, 0, 0]} scale={scale}>
      <Box args={boxArgs}>
        <meshStandardMaterial
          color={'white'}
          attachArray="material"
          opacity={0}
          depthWrite={false}
          transparent
        />
        <Edges />
      </Box>
    </group>
  );
});

