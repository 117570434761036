export const FILES_ORDER_BY = {
  CreatedAt: 'CreatedAt',
  Name: 'Name',
} as const;

export const FILES_ORDER_BY_TITLES = {
  [FILES_ORDER_BY.CreatedAt]: 'Created at',
  [FILES_ORDER_BY.Name]: 'Date',
};

export const FILES_DIRECTION_ORDER = {
  desc: 'desc',
  asc: 'asc',
} as const;

export const FILES_DIRECTIONS_ORDER = {
  name: FILES_DIRECTION_ORDER.asc,
  createdAt: FILES_DIRECTION_ORDER.asc,
} as const;

export const FILES_PAGE_SIZE = 10;
export const FILES_TABLE_HEIGHT = 40 + FILES_PAGE_SIZE * 40 + 2 + 16 + 40;
