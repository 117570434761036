import { IPaletteBarData } from '../types';

export const SWITCH_NAMES = {
  color: 'Color',
  invert: 'Invert',
  cut: 'Cut',
};

export const SWITCH_DATA: IPaletteBarData[] = [
  {
    label: 'palette.color',
    name: SWITCH_NAMES.color,
  },
  {
    label: 'palette.invert',
    name: SWITCH_NAMES.invert,
  },
  {
    label: 'palette.cut',
    name: SWITCH_NAMES.cut,
  },
];
