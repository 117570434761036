// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BLACK_PRIMARY, INITIAL_COORDINATES, INITIAL_RECT_PARAMS, TRANSPARENT } from '../../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../../hooks';
import {
  useAddZonesZoneMutation,
  useModifyZonesZoneMutation,
  useRemoveZonesZoneMutation,
  useSetZonesZoneApplymentMutation,
} from '../../../../../store/api/zones';
import { IZonesNewZoneParams, IZonesZone } from '../../../../../store/api/zones/types';
import { selectVolumeId } from '../../../../../store/selectors';
import {
  selectZonesSelectedZoneId,
  selectZonesUsedInBackwall,
  selectZonesUsedInCracks,
  selectZonesUsedInFlaws,
  selectZonesUsedInSegregations,
  selectZonesZoneModifying,
  selectZonesZoneRemoving,
} from '../../../../../store/selectors/zones.selector';
import { RectParams, TCoordinates, TTupleOfTwoNumbers } from '../../../../../types';
import {
  getBaseSvgElemStyles,
  getRotationRectCoordsOffsetsInMm,
  getToFixedValue,
  ICartesianModeParams,
} from '../../../../../utils';
import { ZonesRectangularZone } from './ZonesRectangularZone';

interface IProps {
  allowZoneInsertion: boolean;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  rotationAngle: number;
  zones: IZonesZone[];
}

export const ZoneInsertion: FC<IProps> = ({ allowZoneInsertion, domains, cartesianModeParams, zones, rotationAngle }) => {
  const { addZonesZone, removeZonesZone, setSelectedZonesZoneId, modifyZonesZone } = useActionCreators();

  const volumeId = useAppSelector(selectVolumeId);
  const allowZoneRemoving = useAppSelector(selectZonesZoneRemoving);
  const allowZoneModifying = useAppSelector(selectZonesZoneModifying);
  const selectedZoneId = useAppSelector(selectZonesSelectedZoneId);
  const isUsedInSegregations = useAppSelector(selectZonesUsedInSegregations);
  const isUsedInCracks = useAppSelector(selectZonesUsedInCracks);
  const isUsedInBackwall = useAppSelector(selectZonesUsedInBackwall);
  const isUsedInFlaws = useAppSelector(selectZonesUsedInFlaws);

  const drawingAreaRef = useRef<SVGGElement>(null!);
  const startDrawingCoords = useRef<TCoordinates>({ ...INITIAL_COORDINATES });

  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [currentRect, setCurrentRect] = useState<RectParams>(INITIAL_RECT_PARAMS);

  const [addZoneTrigger] = useAddZonesZoneMutation();
  const [removeZoneTrigger] = useRemoveZonesZoneMutation();
  const [modifyZoneTrigger] = useModifyZonesZoneMutation();
  const [setZoneApplymentTrigger] = useSetZonesZoneApplymentMutation();

  const zoneApplyment = useMemo(() => ({
    useInSegregations: isUsedInSegregations,
    useInFlaws: isUsedInFlaws,
    useInCracks: isUsedInCracks,
    useInBackwall: isUsedInBackwall,
  }), [isUsedInBackwall, isUsedInCracks, isUsedInFlaws, isUsedInSegregations]);

  const selectZoneHandler = useCallback((id: number) => {
    setIsDrawing(false);

    if (id === selectedZoneId) {
      setSelectedZonesZoneId(null);

      return;
    }

    setSelectedZonesZoneId(id);
  }, [selectedZoneId]);

  const modifyZoneHandler = useCallback((id: number, params: IZonesNewZoneParams) => {
    if (selectedZoneId === null) return;

    modifyZonesZone({
      zoneId: id,
      newZoneRectangle: params,
    });

    modifyZoneTrigger({
      volumeId,
      zoneId: id,
      newZoneRectangle: params,
      zoneApplyment: zones[selectedZoneId].zoneApplyment,
    });
  }, [volumeId, selectedZoneId, zoneApplyment, zones]);

  const zoneRemovingHandler = useCallback((e: MouseEvent, id: number) => {
    e.stopPropagation();

    removeZonesZone(id);

    removeZoneTrigger({
      volumeId,
      zoneId: id,
    });

    setIsDrawing(false);
  }, [volumeId]);

  useEffect(() => {
    if (!allowZoneInsertion) return;

    const drawingArea = d3.select(drawingAreaRef.current);

    function onMouseDown(this: SVGGElement, e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      startDrawingCoords.current.x = x;
      startDrawingCoords.current.y = y;

      setIsDrawing(true);
    }

    function onMouseMove(this: SVGGElement, e: MouseEvent) {
      if (!isDrawing) return;

      e.preventDefault();
      e.stopPropagation();

      const [x, y] = d3.pointer(e, this);

      setCurrentRect({
        x: Math.min(startDrawingCoords.current.x, x),
        y: Math.min(startDrawingCoords.current.y, y),
        width: Math.abs(startDrawingCoords.current.x - x),
        height: Math.abs(startDrawingCoords.current.y - y),
      });
    }

    function onMouseUp(this: SVGGElement, e: MouseEvent) {
      const [x, y] = d3.pointer(e, this);

      const x0 = getToFixedValue(Math.min(startDrawingCoords.current.x, x) * domains[0] / cartesianModeParams.realSampleWidth);
      const y1 = getToFixedValue(domains[1] - Math.min(startDrawingCoords.current.y, y) * domains[1] / cartesianModeParams.realSampleHeight);
      const y0 = getToFixedValue(y1 - Math.abs(startDrawingCoords.current.y - y) * domains[1] / cartesianModeParams.realSampleHeight);
      const x1 = getToFixedValue(x0 + Math.abs(startDrawingCoords.current.x - x) * domains[0] / cartesianModeParams.realSampleWidth);

      setIsDrawing(false);
      setCurrentRect(INITIAL_RECT_PARAMS);

      if (x0 === x1 || y0 === y1) return;

      const newZoneRectangle = {
        leftTop: { x: x0, y: y1 },
        leftBottom: { x: x0, y: y0 },
        rightTop: { x: x1, y: y1 },
        rightBottom: { x: x1, y: y0 },
      };

      addZonesZone({
        newZoneRectangle: getRotationRectCoordsOffsetsInMm(newZoneRectangle, rotationAngle),
        zoneApplyment,
      });

      setZoneApplymentTrigger({
        volumeId,
        zonesApplyment: zoneApplyment,
      })
        .then(
          () => addZoneTrigger({
            volumeId,
            newZoneRectangle: getRotationRectCoordsOffsetsInMm(newZoneRectangle, rotationAngle),
          }));
    }

    drawingArea
      .on('mousedown', onMouseDown)
      .on('mousemove', onMouseMove)
      .on('mouseup', onMouseUp);

    return () => drawingArea.on('mousedown', null).on('mousemove', null).on('mouseup', null);
  }, [
    volumeId,
    allowZoneInsertion,
    domains,
    cartesianModeParams.realSampleWidth,
    cartesianModeParams.realSampleHeight,
    isDrawing,
    zones.length,
    zoneApplyment,
    rotationAngle
  ]);

  return (
    <g
      ref={drawingAreaRef}
      transform={`translate(${cartesianModeParams.x}, ${cartesianModeParams.y})`}
    >
      <rect
        x={0}
        y={0}
        width={cartesianModeParams.realSampleWidth}
        height={cartesianModeParams.realSampleHeight}
        style={{ fill: TRANSPARENT }}
      />
      {
        // too bad approach to use index due to backend zones reset
        zones.length > 0 && zones.map((zone, idx) => (
          <ZonesRectangularZone
            key={idx}
            id={`zonesRect${idx}`}
            zoneParams={zone}
            billetCutDx={-cartesianModeParams.x * domains[0] / cartesianModeParams.realSampleWidth}
            selectedZoneId={selectedZoneId}
            graphParams={cartesianModeParams}
            domains={domains}
            allowModification={allowZoneModifying}
            allowZoneRemoving={allowZoneRemoving}
            zoneRemovingHandler={zoneRemovingHandler}
            selectZoneHandler={selectZoneHandler}
            setParams={modifyZoneHandler}
          />
        ))}
      <rect
        x={currentRect.x}
        y={currentRect.y}
        width={currentRect.width}
        height={currentRect.height}
        style={getBaseSvgElemStyles(BLACK_PRIMARY, 1, TRANSPARENT)}
      />
    </g>
  );
};

