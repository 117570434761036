// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect } from 'react';

import { IDs, RED_PRIMARY, SEG_LINE_Y_STROKE_DASH_ARRAY } from '../../../../../../consts';
import { useActionCreators, useAppSelector, useSegregation } from '../../../../../../hooks';
import { selectSegAllowAreaModification } from '../../../../../../store/selectors';
import { ILineCoordinates, TTupleOfTwoNumbers } from '../../../../../../types';
import { getBaseSvgElemStyles, getYOffsetFunc, ICartesianModeParams } from '../../../../../../utils';

interface IProps {
  domains: TTupleOfTwoNumbers;
  graphParams: ICartesianModeParams;
  length: number;
  yCoordinate: number;
  outerGraphHeight: number;
}

export const SegLineY: FC<IProps> = ({ yCoordinate, domains, graphParams, length, outerGraphHeight }) => {
  const allowAreaModification = useAppSelector(selectSegAllowAreaModification);
  const { handleSlabChange } = useSegregation();

  const { setSegLineY } = useActionCreators();

  const getYOffset = getYOffsetFunc(graphParams, domains);

  const x1 = 0;
  const x2 = length;
  const y = getYOffset(yCoordinate);

  useEffect(() => {
    const segLineY = d3.select(`#${IDs.segLineY}`);

    if (!allowAreaModification) {
      segLineY.attr('cursor', 'auto').call(d3.drag());

      return;
    }

    segLineY.datum({ x1, y1: y, x2, y2: y }).attr('cursor', 'grab');

    function dragstarted(this: SVGLineElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGLineElement, event: MouseEvent, d: ILineCoordinates) {
      d3.select(this)
        .attr('y1', (d.y1 = event.y))
        .attr('y2', (d.y2 = event.y));
    }

    async function dragended(this: SVGLineElement, event: MouseEvent, d: ILineCoordinates) {
      d3.select(this).attr('cursor', 'grab');

      // it's for y domain recalculation
      const ratio = outerGraphHeight / graphParams.realSampleHeight;

      // pixel to mm conversion;
      // transformation the svg coordinates from top left corner to the default coordinates;
      const segLineYPosition = domains[1] * ratio - ((d.y1 + graphParams.y) * domains[1] * ratio) / outerGraphHeight;

      setSegLineY(segLineYPosition);
      await handleSlabChange({ segLineY: segLineYPosition });
    }

    segLineY.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
  }, [x2, y, domains, graphParams, allowAreaModification, outerGraphHeight, handleSlabChange]);

  return (
    <line
      id={IDs.segLineY}
      x1={x1}
      y1={y}
      x2={x2}
      y2={y}
      style={getBaseSvgElemStyles(RED_PRIMARY, 1)}
      strokeDasharray={SEG_LINE_Y_STROKE_DASH_ARRAY}
    />
  );
};
