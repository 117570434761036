import { FILE_TYPES } from '../consts';
import { TFileType } from '../store/api/scandata/types';

interface ICheckedFileType {
  isIDVFileType: boolean;
  isImageFileType: boolean;
}

export const checkFileType = (fileType: TFileType): ICheckedFileType => {
  return {
    isIDVFileType: fileType === FILE_TYPES.volume,
    isImageFileType: fileType === FILE_TYPES.image,
  };
};
