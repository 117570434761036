import { getDateAttributes } from './getFormattedCurrentDateAndTime';

export const downloadArchive = async (response: Response, fileName: string) => {
  const date = new Date();
  const { year, MM, mm, ss, hh, DD } = getDateAttributes(date);
  const timeZoneOffset = date.getTimezoneOffset();

  const anchorElement = document.createElement('a');
  document.body.appendChild(anchorElement);
  anchorElement.style.display = 'none';

  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);

  anchorElement.href = url;
  anchorElement.download = `${fileName}_(${year}-${MM}-${DD}_${+hh + timeZoneOffset / 60}-${mm}-${ss})`;
  anchorElement.click();

  window.URL.revokeObjectURL(url);
  anchorElement.remove();
};
