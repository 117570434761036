import './styles.scss';

import { Modal, TranslatedText } from '@sms/plasma-ui';
import React, { FC } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { DEFAULT_ERROR_MESSAGE, IDs, MODAL_STATUS, MODAL_TITLE } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useExportDbComparisonSnapshotsMutation, useGetDbSnapshotsMutation } from '../../../store/api/database';
import { selectDbSelectedSnapshotNames } from '../../../store/selectors';
import {
  createModalMessageBody,
  getActiveSnapshotsData,
  getBase64Snapshot,
  getShortBase64String,
} from '../../../utils';

interface Props {
  isVisible: boolean;
}

export const SnapshotViewerModal: FC<Props> = ({ isVisible }) => {
  const { setSnapshotsViewerVisible, setMessageModal } = useActionCreators();
  const selectedSnapshotNames = useAppSelector(selectDbSelectedSnapshotNames);
  const [, { data }] = useGetDbSnapshotsMutation({ fixedCacheKey: 'dbSnapshots' });
  const [exportSnapshotTrigger] = useExportDbComparisonSnapshotsMutation();

  if (!isVisible) return null;

  const onCancel = () => {
    setSnapshotsViewerVisible(false);
  };

  const onOk = async () => {
    const imageBase64String = await getBase64Snapshot(`#${IDs.snapshotComparison}`);

    if (!imageBase64String) {
      setMessageModal(createModalMessageBody(MODAL_STATUS.ERROR, MODAL_TITLE.error, DEFAULT_ERROR_MESSAGE));
      return;
    }

    await exportSnapshotTrigger(getShortBase64String(imageBase64String));
  };

  const snapshots = getActiveSnapshotsData(data, selectedSnapshotNames);
  const length = snapshots.length;

  return (
    <Modal
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      //@ts-ignore
      okText={<TranslatedText textKey="Export" />}
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      //@ts-ignore
      title={<TranslatedText textKey="Snapshots" />}
      visible={isVisible}
      width={'100%'}
      zIndex={15}
      className={'snapshots-viewer__modal'}
    >
      <div
        id={IDs.snapshotComparison}
        style={{
          flexWrap: length >= 4 ? 'wrap' : 'nowrap',
        }}
      >
        {snapshots.map(({ snapshotName, snapshotBase64 }) => (
          <div
            key={snapshotName}
            className="snapshots-viewer__snapshot-wrapper"
            style={{
              width: length === 4 ? 'calc(50% - 20px)' : length > 4 ? 'calc(100% / 3 - 40px)' : '100%',
              height: length >= 4 ? 'calc(50% - 20px)' : '100%',
            }}
          >
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
              alignmentAnimation={{
                sizeX: 0,
                sizeY: 0,
              }}
              zoomAnimation={{
                size: 0,
              }}
              wheel={{
                smoothStep: 0.02,
              }}
            >
              <TransformComponent>
                <img src={`data:image/png;base64,${snapshotBase64}`} alt={'snapshot'} />
              </TransformComponent>
            </TransformWrapper>
            <div style={{ marginTop: '8px' }}>{snapshotName}</div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
