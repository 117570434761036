import { Button, TranslatedText } from '@sms/plasma-ui';
import React, { MouseEvent, useCallback } from 'react';

import { BLACK_PRIMARY, BLUE_PRIMARY, BUTTON_NAMES, GREY_SECONDARY_EXTRA_LIGHT, WHITE_PRIMARY } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useRemoveZonesZoneMutation } from '../../../store/api/zones';
import { selectVolumeId } from '../../../store/selectors';
import {
  selectZonesSelectedZoneId,
  selectZonesZoneInsertion,
  selectZonesZoneModifying,
  selectZonesZoneRemoving,
} from '../../../store/selectors/zones.selector';

export const ZonesZones = () => {
  const {
    allowZonesZoneInsertion,
    allowZonesZoneRemoving,
    allowZonesZoneModifying,
    removeZonesZone,
    setSelectedZonesZoneId,
  } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const selectedZoneId = useAppSelector(selectZonesSelectedZoneId);
  const allowZoneInsertion = useAppSelector(selectZonesZoneInsertion);
  const allowZoneRemoving = useAppSelector(selectZonesZoneRemoving);
  const allowZoneModifying = useAppSelector(selectZonesZoneModifying);
  const [removeZoneTrigger] = useRemoveZonesZoneMutation();

  const buttonClickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (e.currentTarget.name === BUTTON_NAMES.rectZoneInsertion) {
        if (allowZoneInsertion) {
          e.currentTarget.blur();
        }

        allowZonesZoneInsertion();

        return;
      }

      if (e.currentTarget.name === BUTTON_NAMES.rectZoneRemoving) {
        if (allowZoneRemoving) {
          e.currentTarget.blur();

          if (selectedZoneId !== null) {
            const prevId = selectedZoneId;

            setSelectedZonesZoneId(null);
            removeZonesZone(prevId);

            removeZoneTrigger({
              volumeId,
              zoneId: prevId,
            });

            return;
          }
        }

        allowZonesZoneRemoving();

        return;
      }

      if (e.currentTarget.name === BUTTON_NAMES.rectZoneModifying) {
        if (allowZoneModifying) {
          e.currentTarget.blur();
        }

        allowZonesZoneModifying();

        return;
      }
    },
    [allowZoneInsertion, allowZonesZoneInsertion, allowZoneRemoving, selectedZoneId, allowZoneModifying]
  );

  return (
    <div className="zones__zones">
      <h3 className="side-menu__title">
        <TranslatedText textKey="Zones" />
      </h3>
      <Button
        title={'Insert'}
        name={BUTTON_NAMES.rectZoneInsertion}
        onClick={buttonClickHandler}
        style={{
          backgroundColor: allowZoneInsertion ? BLUE_PRIMARY : 'transparent',
          borderColor: allowZoneInsertion ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: allowZoneInsertion ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
      <Button
        title={'Delete'}
        name={BUTTON_NAMES.rectZoneRemoving}
        onClick={buttonClickHandler}
        style={{
          backgroundColor: allowZoneRemoving ? BLUE_PRIMARY : 'transparent',
          borderColor: allowZoneRemoving ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: allowZoneRemoving ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
      <Button
        title={'Modify'}
        name={BUTTON_NAMES.rectZoneModifying}
        onClick={buttonClickHandler}
        style={{
          backgroundColor: allowZoneModifying ? BLUE_PRIMARY : 'transparent',
          borderColor: allowZoneModifying ? BLUE_PRIMARY : GREY_SECONDARY_EXTRA_LIGHT,
          color: allowZoneModifying ? WHITE_PRIMARY : BLACK_PRIMARY,
        }}
      />
    </div>
  );
};
