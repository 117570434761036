import { useTranslation } from '@sms/plasma-ui';
import classNames from 'classnames';
import React from 'react';

import { RECIPE_ANALYSIS_STATUS } from '../../../../../../consts';
import { useActionCreators, useAppSelector } from '../../../../../../hooks';
import { IRecipeResponse } from '../../../../../../store/api/recipes/types';
import { selectRecipesSelectedRecipes } from '../../../../../../store/selectors';
import { getFormatedDateAndTimeString } from '../../../../../../utils';

interface IProps {
  recipe: IRecipeResponse;
}

export const RecipeRowItem = ({ recipe }: IProps) => {
  const { id, createdAt, analysisStatus, name } = recipe;
  const { t } = useTranslation();
  const isRecipeDisabled =
    analysisStatus !== RECIPE_ANALYSIS_STATUS.new && analysisStatus !== RECIPE_ANALYSIS_STATUS.inQueue;

  const selectedRecipes = useAppSelector(selectRecipesSelectedRecipes);
  const { setRecipeSelectedRecipe } = useActionCreators();

  const classes = classNames('recipe-row__item', {
    'recipe-item__selected': selectedRecipes.find((recipe) => id === recipe.id),
    'recipe-item__disabled': isRecipeDisabled,
  });

  const handleRecipeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isRecipeDisabled) return;

    setRecipeSelectedRecipe(recipe);
  };

  return (
    <div className={classes} onClick={handleRecipeClick}>
      {name}, {getFormatedDateAndTimeString(createdAt)}, {t('common.status')}: {analysisStatus}
    </div>
  );
};
