// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import {
  GEOMETRY_CUT_TABS,
  GEOMETRY_MARGIN,
  GEOMETRY_RECT_TABS,
  IDENTIFIED_SNAPSHOTS,
  SCAN_VIEWS,
} from '../../../consts';
import { useAppSelector, useWindowSize } from '../../../hooks';
import { ISampleDataParameters } from '../../../store/api/scandata/types';
import { useTakeSnapshotMutation } from '../../../store/api/snaphot';
import {
  selectAutomaticAnalysisStatus,
  selectBilletCutParams,
  selectFileType,
  selectSliceType,
} from '../../../store/selectors';
import { selectGeometry } from '../../../store/selectors/geometry.selector';
import { TTupleOfTwoNumbers } from '../../../types';
import {
  get2dSliceSizes,
  getBase64Snapshot,
  getCartesianModeParams,
  getGraphParams,
  getShortBase64String,
} from '../../../utils';
import { GeometryALines } from './GeometryALines';
import { GeometryBase } from './GeometryBase';
import { GeometryBilletCut } from './GeometryBilletCut';
import { GeometryBLines } from './GeometryBLines';
import { GeometryCLines } from './GeometryCLines';
import { GeometryDiagonals } from './GeometryDiagonals';
import { GeometryDimensions } from './GeometryDimensions';

interface IProps {
  scanDataParameters: ISampleDataParameters;
  tab: string;
  volumeId: string;
  handleAutoTabSwitching: () => void;
}

export const GeometryGraph: FC<IProps> = ({ volumeId, tab, scanDataParameters, handleAutoTabSwitching }) => {
  const outerSvgRef = useRef<SVGSVGElement>(null);
  const [, setRerender] = useState(false);
  const resize = useWindowSize();
  const graphId = `geometry${tab.split(' ').join('')}`;
  const fileType = useAppSelector(selectFileType);
  const sliceType = useAppSelector(selectSliceType);
  const billetCutParams = useAppSelector(selectBilletCutParams);
  const isAutoAnalysis = useAppSelector(selectAutomaticAnalysisStatus);
  const [takeSnapshotTrigger] = useTakeSnapshotMutation();
  const geometryBase = useAppSelector(selectGeometry);

  const domains = useMemo(() => get2dSliceSizes(scanDataParameters, SCAN_VIEWS.C, fileType), [
    scanDataParameters,
    fileType,
  ]);

  const cutParamsDomains: TTupleOfTwoNumbers = useMemo(() => [billetCutParams.sizeX, billetCutParams.sizeY], [
    billetCutParams.sizeX,
    billetCutParams.sizeY,
  ]);
  const filledDomains: TTupleOfTwoNumbers = useMemo(
    () => [geometryBase?.sampleWidthWithBorder ?? 0, geometryBase?.sampleHeightWithBorder ?? 0],
    [geometryBase],
  );

  const graphParams = useMemo(() => getGraphParams(outerSvgRef.current, GEOMETRY_MARGIN), [
    outerSvgRef.current,
    resize,
  ]);

  const cartesianModeParams = useMemo(
    () =>
      getCartesianModeParams(
        tab === GEOMETRY_CUT_TABS.cut ? cutParamsDomains : domains,
        graphParams.innerSvgWidth,
        graphParams.innerSvgHeight,
        billetCutParams.dx,
        billetCutParams.dy,
        'start',
      ),
    [
      tab,
      domains,
      graphParams.innerSvgWidth,
      graphParams.innerSvgHeight,
      cutParamsDomains,
      billetCutParams.dx,
      billetCutParams.dy,
    ],
  );

  // force update hack, it's necessary due to an issue with Plasma UI TabPane
  useEffect(() => {
    setRerender(true);
  }, []);

  useEffect(() => {
    const innerSvg = d3.select(`#${graphId} svg`);
    const wrapper = d3.select(`#${graphId} svg g`);
    const zoomTransform = d3.zoomTransform(innerSvg.node());

    const xDomain = cartesianModeParams.xDomain;
    const yDomain = cartesianModeParams.yDomain;

    const x = d3.scaleLinear().domain(xDomain).range(graphParams.xRange);
    const y = d3.scaleLinear().domain(yDomain.reverse()).range(graphParams.yRange);

    const xAxis = d3.axisBottom(x).ticks(25).tickSize(4);
    const yAxis = d3.axisLeft(y).ticks(15).tickSize(4);

    const gX = d3.select(`#${graphId} .axis--x`).call(xAxis.scale(zoomTransform.rescaleX(x)));
    const gY = d3.select(`#${graphId} .axis--y`).call(yAxis.scale(zoomTransform.rescaleY(y)));

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 40])
      .translateExtent([
        [0, 0],
        [graphParams.width, graphParams.height],
      ])
      .on('zoom', (event: any) => {
        // d3 library doesn't have ZoomEvent type
        const zoomState = event.transform;
        gX.call(xAxis.scale(zoomState.rescaleX(x)));
        gY.call(yAxis.scale(zoomState.rescaleY(y)));

        wrapper.attr('transform', zoomState);
      });

    innerSvg.call(zoom);
  }, [graphParams, cartesianModeParams, billetCutParams.dx, billetCutParams.dy]);

  const takeScreenShot = async (tab: string) => {
    const width = cartesianModeParams.realSampleWidth + GEOMETRY_MARGIN.left + GEOMETRY_MARGIN.right;
    const height = cartesianModeParams.realSampleHeight + GEOMETRY_MARGIN.top + GEOMETRY_MARGIN.bottom;

    const imageBase64String = await getBase64Snapshot(`#${graphId}`, {
      width,
      height,
    });
    let name = '';

    const body = {
      volumeId,
      name,
      imageBase64String: getShortBase64String(imageBase64String),
      description: '',
      imageWidth: width,
      imageHeight: height,
    };

    switch (tab) {
      case GEOMETRY_RECT_TABS.aLines:
        name = IDENTIFIED_SNAPSHOTS.geometryA;
        break;
      case GEOMETRY_RECT_TABS.bLines:
        name = IDENTIFIED_SNAPSHOTS.geometryB;
        break;
      case GEOMETRY_RECT_TABS.cLines:
        name = IDENTIFIED_SNAPSHOTS.geometryC;
        break;
      case GEOMETRY_RECT_TABS.dimensions:
        name = IDENTIFIED_SNAPSHOTS.geometryDimensions;
        break;
      case GEOMETRY_RECT_TABS.diagonals:
        name = IDENTIFIED_SNAPSHOTS.geometryDiagonals;
        break;
      case GEOMETRY_RECT_TABS.base:
        name = IDENTIFIED_SNAPSHOTS.geometryBase;
        break;
      case GEOMETRY_CUT_TABS.cut:
        name = IDENTIFIED_SNAPSHOTS.geometryCut;
        break;
      default:
        name = '';
    }

    body.name = name;

    await takeSnapshotTrigger(body);
  };

  useEffect(() => {
    if (cartesianModeParams.realSampleWidth === 0) return;
    if (sliceType || isAutoAnalysis) {
      setTimeout(() => takeScreenShot(tab).then(() => handleAutoTabSwitching()), 200);
    }
  }, [sliceType, tab, cartesianModeParams.realSampleWidth]);

  return (
    <svg id={graphId} ref={outerSvgRef} width={graphParams.width} height={graphParams.height}>
      <svg
        x={GEOMETRY_MARGIN.left}
        y={GEOMETRY_MARGIN.top}
        width={graphParams.innerSvgWidth}
        height={graphParams.innerSvgHeight}
      >
        <g>
          <rect width={graphParams.innerSvgWidth} height={graphParams.innerSvgHeight} fill="transparent" />
          {tab === GEOMETRY_RECT_TABS.base && geometryBase && (
            <GeometryBase
              sliceType={sliceType}
              cartesianModeParams={cartesianModeParams}
              domains={domains}
              filledDomains={filledDomains}
              geometryBase={geometryBase}
              graphParams={graphParams}
            />
          )}
          {tab === GEOMETRY_RECT_TABS.aLines && (
            <GeometryALines
              volumeId={volumeId}
              domains={domains}
              cartesianModeParams={cartesianModeParams}
              graphParams={graphParams}
            />
          )}
          {tab === GEOMETRY_RECT_TABS.bLines && (
            <GeometryBLines
              volumeId={volumeId}
              domains={domains}
              cartesianModeParams={cartesianModeParams}
              graphParams={graphParams}
            />
          )}
          {tab === GEOMETRY_RECT_TABS.cLines && (
            <GeometryCLines
              volumeId={volumeId}
              domains={domains}
              cartesianModeParams={cartesianModeParams}
              graphParams={graphParams}
            />
          )}
          {tab === GEOMETRY_RECT_TABS.diagonals && geometryBase && (
            <GeometryDiagonals
              volumeId={volumeId}
              domains={domains}
              filledDomains={filledDomains}
              cartesianModeParams={cartesianModeParams}
              graphParams={graphParams}
              geometryBase={geometryBase}
              sliceType={sliceType}
            />
          )}
          {tab === GEOMETRY_RECT_TABS.dimensions && (
            <GeometryDimensions
              volumeId={volumeId}
              domains={domains}
              cartesianModeParams={cartesianModeParams}
              graphParams={graphParams}
            />
          )}
          {tab === GEOMETRY_CUT_TABS.cut && (
            <GeometryBilletCut
              volumeId={volumeId}
              shape={billetCutParams.shape}
              graphParams={graphParams}
              cartesianModeParams={cartesianModeParams}
              domains={domains}
              cutParamsDomains={cutParamsDomains}
            />
          )}
        </g>
      </svg>
      <g
        className="axis axis--x"
        transform={`translate(${GEOMETRY_MARGIN.left}, ${graphParams.height - GEOMETRY_MARGIN.bottom})`}
      />
      <g className="axis axis--y" transform={`translate(${GEOMETRY_MARGIN.left}, ${GEOMETRY_MARGIN.top})`} />
    </svg>
  );
};
