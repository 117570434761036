// @ts-ignore
import * as d3 from 'd3';
import React, { FC, useEffect, useRef } from 'react';

import {
  BACKWALL_MODIFICATION_MODES,
  CRACKS_MODIFICATION_MODES,
  IDs,
  TRANSLUCENT_DIM_GREY,
  TRANSPARENT,
} from '../../../../../consts';
import { useAppSelector } from '../../../../../hooks';
import { useModifyDistanceLineMutation } from '../../../../../store/api/backwall';
import { TDistanceZoneType } from '../../../../../store/api/backwall/types';
import { selectDistanceModificationMode } from '../../../../../store/selectors';
import { ISVGLineParams, TCoordinates, TTupleOfTwoNumbers } from '../../../../../types';
import {
  convertCartesianModeCoordsInPxToMm,
  getBaseSvgElemStyles,
  getLineTextCoords,
  getXOffsetFunc,
  getYOffsetFunc,
  ICartesianModeParams,
} from '../../../../../utils';

interface IProps {
  id: number;
  startCoords: TCoordinates;
  endCoords: TCoordinates;
  isSelected: boolean;
  domains: TTupleOfTwoNumbers;
  cartesianModeParams: ICartesianModeParams;
  xOffset: number;
  yOffset: number;
  zoneType: TDistanceZoneType;
  onZoneSelection: () => void;
  color: string;
}

export const BackwallLine: FC<IProps> = ({
  id,
  startCoords,
  endCoords,
  isSelected,
  domains,
  cartesianModeParams,
  xOffset,
  yOffset,
  zoneType,
  onZoneSelection,
  color,
}) => {
  const modificationMode = useAppSelector(selectDistanceModificationMode);
  const [modifyDistanceLineTigger] = useModifyDistanceLineMutation();

  const getXOffset = getXOffsetFunc(cartesianModeParams, domains);
  const getYOffset = getYOffsetFunc(cartesianModeParams, domains);

  const x1 = getXOffset(startCoords.x + xOffset);
  const y1 = getYOffset(startCoords.y - yOffset);
  const x2 = getXOffset(endCoords.x + xOffset);
  const y2 = getYOffset(endCoords.y - yOffset);
  const { textX, textY } = getLineTextCoords({ x1, y1 }, { x2, y2 });

  const currentLine = useRef<ISVGLineParams>({
    x1,
    y1,
    x2,
    y2,
  });

  useEffect(() => {
    if (modificationMode !== BACKWALL_MODIFICATION_MODES.modify || !isSelected) {
      return;
    }

    const line = d3.select(`#${IDs.backwallDistanceLine}${id} .workingLine`);
    const firstAuxiliaryPoint = d3.select(`#${IDs.backwallDistanceLine}${id} .${IDs.firstAuxiliaryPoint}`);
    const secondAuxiliaryPoint = d3.select(`#${IDs.backwallDistanceLine}${id} .${IDs.secondAuxiliaryPoint}`);

    const points = [firstAuxiliaryPoint, secondAuxiliaryPoint];
    points.forEach((point) => {
      point.attr('cursor', 'grab');
    });

    function dragstarted(this: SVGCircleElement) {
      d3.select(this).raise();
      d3.select(this).attr('cursor', 'grabbing');
    }

    function dragged(this: SVGCircleElement, event: MouseEvent) {
      const [x, y] = d3.pointer(event, this);
      d3.select(this).attr('cx', x).attr('cy', y);

      if (d3.select(this).node().classList.value === IDs.firstAuxiliaryPoint) {
        currentLine.current.x1 = x;
        currentLine.current.y1 = y;
      }

      if (d3.select(this).node().classList.value === IDs.secondAuxiliaryPoint) {
        currentLine.current.x2 = x;
        currentLine.current.y2 = y;
      }

      const { x1, y1, x2, y2 } = currentLine.current;

      line.attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2);
    }

    function dragended(this: SVGCircleElement) {
      d3.select(this).attr('cursor', 'grab');

      const { x1, y1, x2, y2 } = currentLine.current;

      modifyDistanceLineTigger({
        id,
        start: convertCartesianModeCoordsInPxToMm({ x: x1, y: y1 }, cartesianModeParams, domains),
        end: convertCartesianModeCoordsInPxToMm({ x: x2, y: y2 }, cartesianModeParams, domains),
        zoneType,
      });
    }

    points.forEach((point) => {
      point.call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));
    });

    return () => {
      points.forEach((point) => {
        point.call(d3.drag().on('start', null).on('drag', null).on('end', null));
      });
    };
  }, [id, x1, y1, x2, y2, modificationMode, isSelected, domains, cartesianModeParams, zoneType]);

  return (
    <g id={`${IDs.backwallDistanceLine}${id}`}>
      <line style={{ strokeWidth: 5, stroke: TRANSPARENT }} x1={x1} y1={y1} x2={x2} y2={y2} onClick={onZoneSelection} />
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        className="workingLine"
        style={getBaseSvgElemStyles(color, isSelected ? 1.5 : 1, isSelected ? TRANSLUCENT_DIM_GREY : TRANSPARENT)}
        onClick={onZoneSelection}
      />
      <text
        x={textX}
        y={textY + 10}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontSize: '16px',
          fill: color,
        }}
        onClick={onZoneSelection}
      >
        {id}
      </text>
      {modificationMode === CRACKS_MODIFICATION_MODES.modify && isSelected && (
        <>
          <circle
            className={IDs.firstAuxiliaryPoint}
            cx={x1}
            cy={y1}
            r={3}
            style={getBaseSvgElemStyles(TRANSPARENT, 0, color)}
          />
          <circle
            className={IDs.secondAuxiliaryPoint}
            cx={x2}
            cy={y2}
            r={3}
            style={getBaseSvgElemStyles(TRANSPARENT, 0, color)}
          />
        </>
      )}
    </g>
  );
};
