import { RadioGroupData } from '../types';

export const toolbarAnalysisData: RadioGroupData[] = [
  {
    value: 'segregation',
    label: 'pages.segregation',
  },
  {
    value: 'flaws',
    label: 'pages.flaws',
  },
  {
    value: 'geometry',
    label: 'pages.geometry',
  },
  {
    value: 'cracks',
    label: 'pages.cracks',
  },
  {
    value: 'backwall',
    label: 'pages.backwall',
  },
  {
    value: 'zones',
    label: 'Zones',
  },
  {
    value: 'report',
    label: 'Report',
  },
];

export const toolbarAnalysisDataValues = toolbarAnalysisData.map(({ value }) => value);

export const toolbarProjectionData: RadioGroupData[] = [
  {
    value: 'bcdplus',
    label: 'B C D +',
  },
  {
    value: 'bcda',
    label: 'B C D A',
  },
  {
    value: 'b',
    label: 'B',
  },
  {
    value: 'c',
    label: 'C',
  },
  {
    value: 'd',
    label: 'D',
  },
  {
    value: '3d',
    label: '3D',
  },
];

export const toolbarSubmenuData: RadioGroupData[] = [
  {
    value: 'images',
    label: 'pages.images',
  },
  {
    value: 'info',
    label: 'Info',
  },
  {
    value: 'settings',
    label: 'pages.settings',
  },
];

export const checkboxTitles: string[] = ['Cart. Mode', 'Max. Proj.', 'Border', 'Vol.'];

export const selectData: RadioGroupData[] = [
  {
    label: 'Rotate clockwise',
    value: 1,
  },
  {
    label: 'Rotate counter-clockwise',
    value: 2,
  },
  {
    label: 'Flip C and D',
    value: 3,
  },
  {
    label: 'Exact volume rendering',
    value: 4,
  },
  {
    label: 'Preferences',
    value: 5,
  },
];
