export const CHART_TYPES = {
  bar: 'bar',
  multibar: 'multibar',
  pie: 'pie',
  line: 'line',
  scatter: 'scatter',
  linearRegression: 'linearRegression',
  exponentialRegression: 'exponentialRegression',
  polynomialRegression: 'polynomialRegression',
} as const;

export const REGRESSION_TYPES = {
  linear: 'linear',
  polynomial: 'polynomial',
  exponential: 'exponential',
} as const;

export const REGRESSION_TITLES = {
  linear: 'Linear',
  polynomial: 'Polynomial',
  exponential: 'Exponential',
} as const;
