import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAutoAnalysisStatus } from '../api/automatic-analysis/types';

const initialState: IAutoAnalysisStatus = {
  analysesComplete: false,
  automaticModeEnabled: false,
};

export const analysisStatusSlice = createSlice({
  name: 'analysisStatus',
  initialState,
  reducers: {
    setAnalysisStatus: (state, action: PayloadAction<IAutoAnalysisStatus>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: analysisStatusActions, reducer: analysisStatus } = analysisStatusSlice;
