import { createSelector } from '@reduxjs/toolkit';

import { FLAWS_ANALYSIS, SEG_ANALYSIS, SLICE_TYPES } from '../../consts';
import { TFlawsAnalysis, TSegAnalysis } from '../../types';
import { checkAppRoute } from '../../utils';
import { getFormattedPaletteData } from '../../utils/getFormattedPaletteData';
import { automaticAnalysisApi } from '../api/automatic-analysis';
import { IFlawsData } from '../api/flaws/types';
import { ISegData } from '../api/segregation/types';
import { TypedRootState } from '../types';
import { selectSliceType } from './sliceType.selector';

export const selectAutomaticAnalysisStatus = (state: TypedRootState) => state.analysisStatus.analysesComplete;
export const selectIsAutomaticAnalysisModeEnabled = (state: TypedRootState) =>
  state.analysisStatus.automaticModeEnabled;
export const selectAutomaticAnalysisResult = (state: TypedRootState) =>
  automaticAnalysisApi.endpoints.getAutomaticAnalysisResult.select()(state)?.data;

export const selectSegSliderAndPaletteAutoAnalysis = createSelector(selectAutomaticAnalysisResult, (analysisResult) => {
  if (!analysisResult?.segregationsPalette) return;

  return {
    palette: getFormattedPaletteData(analysisResult.segregationsPalette),
    sliderRange: analysisResult.segregationsRange,
  };
});
export const selectAutoAnalysisSliceCImage = createSelector(selectAutomaticAnalysisResult, (analysisResult) => {
  const path = window.location.pathname;
  const { isSegregation, isFlaws, isCracks } = checkAppRoute(path);

  let sliceC;

  if (isSegregation) {
    sliceC = analysisResult?.sliceCSeregations;
  }
  if (isFlaws) {
    sliceC = analysisResult?.sliceCFlaws;
  }
  if (isCracks) {
    sliceC = analysisResult?.sliceCCracks;
  }

  return sliceC?.imageBase64;
});
export const selectSegAutomaticAnalysisResult = createSelector(
  [selectSliceType, selectAutomaticAnalysisResult],
  (sliceType, analysisResult) => {
    if (!analysisResult) return;

    let analysisResults: { data: ISegData; analysis: TSegAnalysis }[];
    const {
      segregationsMmanSlabResponse,
      segregationsMmanBilletRectResponse,
      segregationsMmanBilletRoundResponse,
      segregationsSep1Response,
      segregationsSep100Response,
      segregationsGostSlabResponse,
      segregationsGostBilletRectResponse,
      segregationsGostBilletRoundResponse,
      segregationsAstmBilletRectResponse,
      segregationsAstmBilletRoundResponse,
    } = analysisResult;

    if (sliceType === SLICE_TYPES.rect) {
      analysisResults = [
        { data: segregationsMmanBilletRectResponse, analysis: SEG_ANALYSIS.mm },
        { data: segregationsGostBilletRectResponse, analysis: SEG_ANALYSIS.gost },
        { data: segregationsAstmBilletRectResponse, analysis: SEG_ANALYSIS.astm },
      ];
    } else if (sliceType === SLICE_TYPES.round) {
      analysisResults = [
        { data: segregationsMmanBilletRoundResponse, analysis: SEG_ANALYSIS.mm },
        { data: segregationsGostBilletRoundResponse, analysis: SEG_ANALYSIS.gost },
        { data: segregationsAstmBilletRoundResponse, analysis: SEG_ANALYSIS.astm },
      ];
    } else {
      analysisResults = [
        { data: segregationsMmanSlabResponse, analysis: SEG_ANALYSIS.mm },
        { data: segregationsSep1Response, analysis: SEG_ANALYSIS.sep },
        { data: segregationsSep100Response, analysis: SEG_ANALYSIS.sep },
        { data: segregationsGostSlabResponse, analysis: SEG_ANALYSIS.gost },
      ];
    }

    return analysisResults.filter(({ data }) => !!data);
  }
);

export const selectFlawsAutomaticAnalysisResult = createSelector(
  [selectSliceType, selectAutomaticAnalysisResult],
  (sliceType, analysisResult) => {
    if (!analysisResult) return;

    let analysisResults: { data: IFlawsData; analysis: TFlawsAnalysis }[];
    const {
      flawsSmsResponse,
      flawsGostSlabResponse,
      flawsGostBilletRectResponse,
      flawsGostBilletRoundResponse,
      flawsAstmBilletRectResponse,
      flawsAstmBilletRoundResponse,
    } = analysisResult;

    if (sliceType === SLICE_TYPES.rect) {
      analysisResults = [
        { data: flawsGostBilletRectResponse, analysis: FLAWS_ANALYSIS.gost },
        { data: flawsAstmBilletRectResponse, analysis: FLAWS_ANALYSIS.astm },
      ];
    } else if (sliceType === SLICE_TYPES.round) {
      analysisResults = [
        { data: flawsGostBilletRoundResponse, analysis: FLAWS_ANALYSIS.gost },
        { data: flawsAstmBilletRoundResponse, analysis: FLAWS_ANALYSIS.astm },
      ];
    } else {
      analysisResults = [
        { data: flawsSmsResponse, analysis: FLAWS_ANALYSIS.sms },
        { data: flawsGostSlabResponse, analysis: FLAWS_ANALYSIS.gost },
      ];
    }

    return analysisResults.filter(({ data }) => !!data);
  }
);

export const selectFlawsSliderAndPaletteAutoAnalysis = createSelector(
  selectAutomaticAnalysisResult,
  (analysisResult) => {
    if (!analysisResult?.flawsPalette) return;

    return {
      palette: getFormattedPaletteData(analysisResult.flawsPalette),
      sliderRange: analysisResult.flawsRange,
    };
  }
);

export const selectCracksSliderAndPaletteAutoAnalysis = createSelector(
  selectAutomaticAnalysisResult,
  (analysisResult) => {
    if (!analysisResult?.cracksPalette) return;

    return {
      palette: getFormattedPaletteData(analysisResult.cracksPalette),
      sliderRange: analysisResult.cracksRange,
    };
  }
);

export const selectBackwallSliderAndPaletteAutoAnalysis = createSelector(
  selectAutomaticAnalysisResult,
  (analysisResult) => {
    if (!analysisResult?.backwallPalette) return;

    return {
      palette: getFormattedPaletteData(analysisResult.backwallPalette),
      sliderRange: analysisResult.backwallRange,
    };
  }
);
export const selectBackwallAutoAnalysisImage = createSelector(
  selectAutomaticAnalysisResult,
  (analysisResult) => analysisResult?.sliceCBackwall.imageBase64
);

export const selectBackwallFlawsAutoAnalysis = createSelector(
  selectAutomaticAnalysisResult,
  (analysisResult) => analysisResult?.backwallFlawsResponse
);
