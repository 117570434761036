import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const flippedSlice = createSlice({
  name: 'flipped',
  initialState,
  reducers: {
    setFlipped: (state) => !state,
  },
});

export const { actions: flippedActions, reducer: flipped } = flippedSlice;
