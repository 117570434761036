import { IMargin } from '../types';
import { ISliderRange } from '../types/scanData';

export const SLICE_MARGIN: IMargin = { top: 10, right: 10, left: 30, bottom: 20 };
export const A_SCAN_VIEW_MARGIN: IMargin = { top: 10, right: 10, left: 60, bottom: 20 };
export const TICK_SIZE = 4;
export const X_TICK_AMOUNT = 15;
export const Y_TICK_AMOUNT = 10;
export const DEFAULT_SLICE_C_RANGE: ISliderRange = { start: 6, end: 11 };
export const DEFAULT_BACKWALL_RANGE: ISliderRange = { start: 19, end: 21 };
