import TranslatedText from '@sms/plasma-ui/lib/components/TranslatedText/TranslatedText';
import React, { FC } from 'react';

import { IProps } from '../index';

export const PoresDistributionRow: FC<IProps> = ({ statistics }) => {
  return (
    <>
      {statistics && 'poresDistribution' in statistics && (
        <li className="seg__statistics__item">
          <p>
            <TranslatedText textKey="side-menu.distribution" />:
          </p>
          <p>{statistics.poresDistribution ?? '-'}</p>
        </li>
      )}
    </>
  );
};
