import { ReportAnalysisData, typedEntries } from '../types';
import { BACKWALL_ANALYSIS } from './backwall';
import { CRACKS_ANALYSIS } from './cracks';
import { FLAWS_ANALYSIS } from './flaws';
import { SEG_ANALYSIS } from './segregations';

export const REPORT_TABS = {
  segregations: 'segregations',
  flaws: 'flaws',
  cracks: 'cracks',
  backwall: 'backwall',
  other: 'other',
} as const;

export const REPORT_TABS_OTHER_KEYS = {
  geometry: 'geometry',
  zones: 'zones',
  data: 'data',
  csv: 'csv',
} as const;

export const REPORT_TABS_TITLES = {
  [REPORT_TABS.segregations]: 'pages.segregation',
  [REPORT_TABS.flaws]: 'pages.flaws',
  [REPORT_TABS.cracks]: 'pages.cracks',
  [REPORT_TABS.backwall]: 'pages.backwall',
  [REPORT_TABS.other]: 'common.other',
};

export const REPORT_ANALYSIS: ReportAnalysisData<string> = {
  segregations: {
    [SEG_ANALYSIS.mm]: 'report.include-mm',
    [SEG_ANALYSIS.sep]: 'report.include-sep',
    [SEG_ANALYSIS.gost]: 'report.include-gost',
    [SEG_ANALYSIS.astm]: 'report.include-astm',
  },
  flaws: {
    [FLAWS_ANALYSIS.sms]: 'report.include-sms',
    [FLAWS_ANALYSIS.gost]: 'report.include-gost',
    [FLAWS_ANALYSIS.astm]: 'report.include-astm',
  },
  cracks: {
    [CRACKS_ANALYSIS.sms]: 'report.include-cracks-sms',
    [CRACKS_ANALYSIS.gost]: 'report.include-cracks-gost',
    [CRACKS_ANALYSIS.astm]: 'report.include-cracks-astm',
  },
  backwall: {
    // [BACKWALL_ANALYSIS.sms]: 'report.include-backwall-sms',
    // [BACKWALL_ANALYSIS.gost]: 'report.include-backwall-gost',
    // [BACKWALL_ANALYSIS.astm]: 'report.include-backwall-astm',
    [BACKWALL_ANALYSIS.distance]: 'report.include-backwall-distance',
    [BACKWALL_ANALYSIS.flaws]: 'report.include-backwall-flaws',
  },
  other: {
    [REPORT_TABS_OTHER_KEYS.geometry]: 'report.include-geometry',
    [REPORT_TABS_OTHER_KEYS.zones]: 'report.include-zones',
    [REPORT_TABS_OTHER_KEYS.data]: 'report.include-additional-data',
    [REPORT_TABS_OTHER_KEYS.csv]: 'report.generate-csv-tables',
  },
};

export const reportAnalysisEntries = typedEntries(REPORT_ANALYSIS);
