import { TranslatedText } from '@sms/plasma-ui';
import React, { useMemo } from 'react';

import { TRANSPARENT } from '../../../../../consts';
import { IBackwallFlawsPolygonCoordinates } from '../../../../../store/api/backwall-flaws/types';
import { IPxCoordinates } from '../../../../../store/api/slice/types';
import { TTupleOfTwoNumbers } from '../../../../../types';
import { getBaseSvgElemStyles, getToFixedValue, ICartesianModeParams } from '../../../../../utils';
import { getIntersectionsData } from '../../../../../utils/cracks';
import { Icons } from '../../../../svg';
import { BackwallFlawsMainInfo } from './BackwallFlawsMainInfo';

interface Props {
  polygon: IBackwallFlawsPolygonCoordinates;
  wRatio: number;
  hRatio: number;
  cartesianModeParams: ICartesianModeParams;
  domains: TTupleOfTwoNumbers;
  imageWidth: number;
  imageHeight: number;
  color: string;
  convertedBorderData: IPxCoordinates[];
}

export const BackwallFlawInfo = ({
  polygon,
  wRatio,
  hRatio,
  cartesianModeParams,
  domains,
  imageWidth,
  imageHeight,
  color,
  convertedBorderData,
}: Props) => {
  const { realSampleHeight, realSampleWidth, x: offsetX, y: offsetY } = cartesianModeParams;
  const { width, height, rightDistancePoint, leftDistancePoint, id } = polygon;

  const { rightDistancePointPx, leftDistancePointPx } = useMemo(() => {
    const getXInPx = (x: number) => (x / domains[0]) * imageWidth;
    const getYInPx = (y: number) => (y / domains[1]) * imageHeight;

    return {
      id,
      leftDistancePointPx: { x: getXInPx(leftDistancePoint.x), y: imageHeight - getYInPx(leftDistancePoint.y) },
      rightDistancePointPx: { x: getXInPx(rightDistancePoint.x), y: imageHeight - getYInPx(rightDistancePoint.y) },
    };
  }, [polygon, domains, imageWidth, imageHeight]);

  const intersections = {
    leftTop: leftDistancePointPx,
    rightBottom: rightDistancePointPx,
  };

  const {
    leftTop: {
      x: { delta: leftTopVerticalDelta, minDelta: minLeftTopVerticalDelta },
      y: { delta: leftTopHorizontalDelta, minDelta: minLeftTopHorizontalDelta },
    },
    rightBottom: {
      x: { delta: rightBottomVerticalDelta, minDelta: minRightBottomVerticalDelta },
      y: { delta: rightBottomHorizontalDelta, minDelta: minRightBottomHorizontalDelta },
    },
  } = getIntersectionsData(convertedBorderData, intersections);

  const { x, y } = leftDistancePointPx;
  const { x: rightX, y: rightY } = rightDistancePointPx;

  const ltlX1 = x / wRatio + offsetX;
  const ltlY1 = y / hRatio + offsetY;
  const ltlX2 = (x + leftTopHorizontalDelta) / wRatio + offsetX;
  const ltlY2 = (y + leftTopVerticalDelta) / hRatio + offsetY;
  const rblX1 = rightX / wRatio + offsetX;
  const rblY1 = rightY / hRatio + offsetY;
  const rblX2 = (rightX + rightBottomHorizontalDelta) / wRatio + offsetX;
  const rblY2 = (rightY + rightBottomVerticalDelta) / hRatio + offsetY;

  const isLeftHigher = ltlY1 < rblY1;

  return (
    <>
      <line
        x1={ltlX1}
        y1={ltlY1}
        x2={ltlX1}
        y2={ltlY2}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(color, 1, TRANSPARENT)}
      />
      <text
        textAnchor={leftTopVerticalDelta > 0 ? 'end' : 'start'}
        style={{
          fontSize: '16px',
          fill: color,
          transform: `translate(${ltlX1}px, ${ltlY2}px) rotate(-90deg)`,
        }}
      >
        {/* Distance: {getToFixedValue((minLeftTopVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minLeftTopHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm
      </text>
      <line
        x1={ltlX1}
        y1={ltlY1}
        x2={ltlX2}
        y2={ltlY1}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(color, 1, TRANSPARENT)}
      />
      <text
        x={ltlX2}
        y={ltlY1}
        alignmentBaseline={isLeftHigher ? 'baseline' : 'hanging'}
        textAnchor={leftTopHorizontalDelta < 0 ? 'end' : 'start'}
        style={{
          fontSize: '16px',
          fill: color,
        }}
      >
        {/* Distance: {getToFixedValue((minLeftTopHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minLeftTopVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm
      </text>
      <line
        x1={rblX1}
        y1={rblY1}
        x2={rblX1}
        y2={rblY2}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(color, 1, TRANSPARENT)}
      />
      <text
        textAnchor={rightBottomVerticalDelta > 0 ? 'end' : 'start'}
        alignmentBaseline="hanging"
        style={{
          fontSize: '16px',
          fill: color,
          transform: `translate(${rblX1}px, ${rblY2}px) rotate(-90deg)`,
        }}
      >
        {/* Distance: {getToFixedValue((minRightBottomVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minRightBottomHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm
      </text>
      <line
        x1={rblX1}
        y1={rblY1}
        x2={rblX2}
        y2={rblY1}
        strokeDasharray={4}
        style={getBaseSvgElemStyles(color, 1, TRANSPARENT)}
      />
      <text
        x={rblX2}
        y={rblY1}
        textAnchor={rightBottomHorizontalDelta < 0 ? 'end' : 'start'}
        alignmentBaseline={isLeftHigher ? 'hanging' : 'baseline'}
        style={{
          fontSize: '16px',
          fill: color,
        }}
      >
        {/* Distance: {getToFixedValue((minRightBottomHorizontalDelta / wRatio / realSampleWidth) * domains[0], 1)} mm */}
        <TranslatedText textKey="toolbar.options.distance" />:{' '}
        {getToFixedValue((minRightBottomVerticalDelta / hRatio / realSampleHeight) * domains[1], 1)} mm
      </text>
      <Icons.Arrow
        color={color}
        startX={(x - 40) / wRatio + offsetX}
        startY={(y + 20) / hRatio + offsetY}
        endX={ltlX1 - 5}
        endY={ltlY1 + 5}
      />
      <BackwallFlawsMainInfo
        x={(x - 100) / wRatio + offsetX}
        y={(y + 30) / hRatio + offsetY}
        width={width}
        height={height}
        id={id}
        color={color}
      />
    </>
  );
};
