import { createSelector } from '@reduxjs/toolkit';

import { ReportAnalysisData, TLogo } from '../../types';
import { reportApi } from '../api/report';
import { settingsApi } from '../api/settings';
import { IReportData } from '../slices';
import { TypedRootState } from '../types';

export const selectReport = (state: TypedRootState): IReportData => state.report;
export const selectReportAnalysis = (state: TypedRootState): ReportAnalysisData<boolean> => state.report.analysis;
export const selectIsAnalysisAvailable = (state: TypedRootState): ReportAnalysisData<boolean> =>
  state.report.isAnalysisAvailable;
export const selectReportController = (state: TypedRootState): string => state.report.controller;
export const selectReportFileName = (state: TypedRootState): string => state.report.fileName;
export const selectIsUsedLogo = (state: TypedRootState) => state.report.addLogo;
export const selectReportEmail = (state: TypedRootState) => state.report.email;
export const selectSendReportEmail = (state: TypedRootState) => state.report.sendEmail;

export const selectIsReportMode = (state: TypedRootState) => state.report.isReportMode;
