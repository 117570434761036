export function getISODateWithTimezone(date: Date) {
  const timeZoneOffset = date.getTimezoneOffset();
  const isoString = new Date(date.getTime() - timeZoneOffset * 60000).toISOString().slice(0, -1);
  const fullISODate = `${isoString}${getFormattedTimezone(timeZoneOffset)}`;

  return fullISODate;
}

function getFormattedTimezone(offset: number) {
  const totalMinutes = Math.abs(offset);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${offset < 0 ? '+' : '-'}${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
}

export function getDateWithTime(value: string, range: 'start' | 'end') {
  const date = new Date(value);

  const time = range === 'start' ? 'T00:00:00.000' : 'T23:59:59.000';
  const timeZoneOffset = date.getTimezoneOffset();

  const dateWithTime = value.split('T')[0] + time + getFormattedTimezone(timeZoneOffset);

  return dateWithTime;
}

function padToTwoDigits(num: number) {
  return num.toString().padStart(2, '0');
}
