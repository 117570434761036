import './styles.scss';

import { useRouter } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { orderedBCDASlices, orderedBCDPlusSlices, SCAN_VIEWS } from '../../consts';
import { ISampleDataParameters } from '../../store/api/scandata/types';
import { checkAppRoute } from '../../utils';
import { AScanView } from '../AScanView';
import { Pseudo3d } from '../Pseudo3d';
import { ProjectionItem } from './ProjectionItem';

interface IProjectionListProps {
  scanDataParameters: ISampleDataParameters,
}

export const ProjectionList: FC<IProjectionListProps> = ({ scanDataParameters }) => {
  const { route: { path } } = useRouter('route');
  const { isBCDPlus, isBCDA } = checkAppRoute(path);

  return (
    <div className='projection__list__wrapper'>
      {isBCDPlus && orderedBCDPlusSlices.map((item) => {
        if (item === SCAN_VIEWS.threeD) return <Pseudo3d key={item} scanDataParameters={scanDataParameters} scanView={item} />;
        return <ProjectionItem key={item} scanView={item} scanDataParameters={scanDataParameters} />;
      })}
      {isBCDA && orderedBCDASlices.map((item) => {
        if (item === SCAN_VIEWS.A) return <AScanView key={item} scanView={item} scanDataParameters={scanDataParameters} />;
        return <ProjectionItem key={item} scanView={item} scanDataParameters={scanDataParameters} tooltipHidden />;
      })}
    </div>
  );
};
