import { SCAN_VIEWS } from '../consts';
import { ISampleDataParameters } from '../store/api/scandata/types';
import { getToFixedValue } from './getToFixedValue';

export const getScanDataResolution = (scanData: ISampleDataParameters, scanView: string) => {
  const { resolutionX, resolutionY, resolutionZ } = scanData;
  if (scanView === SCAN_VIEWS.B && resolutionZ) return getToFixedValue(resolutionZ);
  if (scanView === SCAN_VIEWS.C && resolutionY) return getToFixedValue(resolutionY);
  if (scanView === SCAN_VIEWS.D && resolutionX) return getToFixedValue(resolutionX);

  return 0.01;
};
