import './styles.scss';

import { Modal, TranslatedText, useTranslation } from '@sms/plasma-ui';
import React, { FC } from 'react';

import { useActionCreators, useAppSelector } from '../../../hooks';
import { useInitGeometryMutation, useLazySetSliceRangeChangedQuery } from '../../../store/api/geometry';
import { selectMaxProjectionSliderRanges, selectSliceType, selectVolumeId } from '../../../store/selectors';
import { selectCRangeForLoadedGeometry } from '../../../store/selectors/cRangeForLoadedGeometry.selector';
import { Icons } from '../../svg';

interface IProps {
  isVisible: boolean;
}

export const CRangeChangeWarningModal: FC<IProps> = ({ isVisible }) => {
  const { setCRangeForLoadedGeometry, setCRangesChangedVisible, setSpinnerVisible } = useActionCreators();
  const volumeId = useAppSelector(selectVolumeId);
  const sliceType = useAppSelector(selectSliceType);
  const { sliceC } = useAppSelector(selectMaxProjectionSliderRanges);
  const loadedCRange = useAppSelector(selectCRangeForLoadedGeometry);
  const [setSliceRangeChangedTrigger] = useLazySetSliceRangeChangedQuery();
  const [initGeometryBaseTrigger] = useInitGeometryMutation();

  const { t } = useTranslation();

  const onOk = async () => {
    await setSliceRangeChangedTrigger(volumeId);
    await initGeometryBaseTrigger(sliceType);

    setSpinnerVisible(false);
    setCRangeForLoadedGeometry(sliceC);
    setCRangesChangedVisible(false);
  };

  const onCancel = () => {
    setCRangesChangedVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Modal
      closable
      keyboard
      mask
      maskClosable
      destroyOnClose
      okText="OK"
      okType="primary"
      onOk={onOk}
      onCancel={onCancel}
      //@ts-ignore
      title={<TranslatedText textKey="modals.c-range-changed.title" />}
      //@ts-ignore
      cancelText={<TranslatedText textKey="common.cancel" />}
      visible={isVisible}
      width={460}
      zIndex={15}
      className={'CRangeChangedWarning__modal'}
    >
      <Icons.Warning />
      <p className="CRangeChangedWarning__content">
        {t('modals.c-range-changed', {
          from: `(${loadedCRange.start}/${loadedCRange.end})`,
          to: `(${sliceC.start}/${sliceC.end})`,
        })}
      </p>
    </Modal>
  );
};
