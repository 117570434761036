import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { LOCALSTORAGE_KEYS } from '../../consts';
import { getFromLocalStorage } from '../../utils';
import { IVolumeInfo } from '../api/scandata/types';

const initVolumeInfo = {
  volumeId: '',
  fileType: 'Volume',
};

const initialState: IVolumeInfo = getFromLocalStorage(LOCALSTORAGE_KEYS.volumeInfo) ?? initVolumeInfo;

export const volumeIdSlice = createSlice({
  name: 'volumeId',
  initialState,
  reducers: {
    setVolumeInfo: (state, action: PayloadAction<IVolumeInfo>) => action.payload,
    setVolumeId: (state, action: PayloadAction<string>) => {
      state.volumeId = action.payload;
    },
  },
});

export const { actions: volumeIdActions, reducer: volumeInfo } = volumeIdSlice;
