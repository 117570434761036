import { RectParams, TTupleOfTwoNumbers } from '../types';

export interface ICartesianModeParams {
  realSampleHeight: number;
  realSampleWidth: number;
  x: number;
  y: number;
  xDomain: TTupleOfTwoNumbers;
  yDomain: TTupleOfTwoNumbers;
}

export const getCartesianModeParams = (
  domains: number[],
  width: number,
  height: number,
  dx = 0,
  dy = 0,
  position = 'middle'
): ICartesianModeParams => {
  let realSampleHeight = 0,
    realSampleWidth = 0;
  const sampleSizesRatio = domains[1] / domains[0];
  const elementSizesRatio = height / width;

  if (sampleSizesRatio < 1) {
    realSampleHeight = sampleSizesRatio * width;
    realSampleWidth = width;

    if (realSampleHeight > height && elementSizesRatio <= 1) {
      realSampleWidth = (width * height) / realSampleHeight;
      realSampleHeight = height;
    }
  }

  if (sampleSizesRatio >= 1) {
    realSampleHeight = height;
    realSampleWidth = height / sampleSizesRatio;

    if (realSampleWidth > width && elementSizesRatio > 1) {
      realSampleHeight = (height * width) / realSampleWidth;
      realSampleWidth = width;
    }
  }

  // if (isHeightAnalysis) {
  //   realSampleHeight *= 3 / 4;
  //   realSampleWidth *= 3 / 4;
  // }

  const xMaxRange = (width * domains[0]) / realSampleWidth;
  const yMaxRange = (height * domains[1]) / realSampleHeight;

  const xOffset = (xMaxRange - domains[0]) / 2;
  const yOffset = (yMaxRange - domains[1]) / 2;

  return {
    realSampleHeight,
    realSampleWidth,
    y: position === 'middle' ? (height - realSampleHeight) / 2 : 0,
    x: position === 'middle' ? (width - realSampleWidth) / 2 : 0,
    xDomain: position === 'middle' ? [-xOffset - dx, xOffset + domains[0] - dx] : [-dx, 2 * xOffset + domains[0] - dx],
    // xDomain: [0 - dx, 2 * xOffset + domains[0] - dx],
    yDomain: position === 'middle' ? [-yOffset - dy, yOffset + domains[1] - dy] : [-dy, 2 * yOffset + domains[1] - dy],
  };
};

export const getInnerShapeCartesianModeParams = (
  domains: TTupleOfTwoNumbers,
  billetCutDomains: TTupleOfTwoNumbers,
  baseCartesianModeParams: ICartesianModeParams,
  dx = 0,
  dy = 0
): RectParams => {
  const innerShapeWidth = (baseCartesianModeParams.realSampleWidth * domains[0]) / billetCutDomains[0];
  const innerShapeHeight = (baseCartesianModeParams.realSampleHeight * domains[1]) / billetCutDomains[1];
  const xOffset = (dx * innerShapeWidth) / domains[0];
  const yOffset = (dy * innerShapeHeight) / domains[1];

  return {
    width: innerShapeWidth,
    height: innerShapeHeight,
    x: baseCartesianModeParams.x + xOffset,
    y: baseCartesianModeParams.y + (baseCartesianModeParams.realSampleHeight - innerShapeHeight) - yOffset,
  };
};
