import { dispose, useLoader } from '@react-three/fiber';
import React, { useEffect } from 'react';
import { Box3, Vector3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface IProps {
  scale: number;
  url: string;
  onStart: () => void;
  onLoad: () => void;
}

export const Model = ({ scale, url, onStart, onLoad }: IProps) => {
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    loader.manager.onStart = onStart;
    loader.manager.onLoad = onLoad;
  });

  useEffect(() => {
    const box = new Box3().setFromObject(gltf.scene);
    const center = box.getCenter(new Vector3());

    gltf.scene.position.x -= center.x;
    gltf.scene.position.y -= center.y;
    gltf.scene.position.z -= center.z;

    return () => {
      dispose(gltf.scene);
      useLoader.clear(GLTFLoader, url);
    };
  }, [gltf.scene, url]);

  return (
    <>
      <pointLight position={[-5, -5, -5]} />
      <pointLight position={[5, 5, 5]} />
      <primitive object={gltf.scene} scale={scale} rotation={[Math.PI, 0, 0]} />
    </>
  );
};
