import './styles.scss';

import { Checkbox, TextArea, TextInput, TranslatedText } from '@sms/plasma-ui/lib/components';
import React, { FC } from 'react';

export const ImagesInfo: FC = () => {
  return (
    <div className="images-info__container">
      <h3 className="side-menu__title">
        <TranslatedText textKey="Info" />
      </h3>
      <div className="images-info__form">
        <TextInput label="images.subtype" name="subtype" />
        {/*<TextInput label="Class sep" name="class" />*/}
        {/*<TextInput label="Position" name="position" />*/}
        {/*<TextInput label="US threshold" name="us threshold" />*/}
        <TextArea label="common.description" name="description" />
        <Checkbox hint="Hint" hintMode="hidden" name="report" title="images.include-in-report" />
      </div>
    </div>
  );
};
