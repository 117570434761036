import { DataNode, TranslatedText, Tree } from '@sms/plasma-ui';
import React, { useCallback, useLayoutEffect, useState } from 'react';

import { DB_TABLE_COLUMN_TITLES } from '../../../consts';
import { useActionCreators, useAppSelector } from '../../../hooks';
import { useSaveDatabaseMutation } from '../../../store/api/database';
import { selectDBResultTable } from '../../../store/selectors';
import { ReactKeys, ResultTableEntries, TreeCheckedKeys } from '../../../types';
import { getDbActiveEntries, getDbVisibleEntries } from '../../../utils';

export const TableController = () => {
  const { updateResultTable } = useActionCreators();
  const resultTableState = useAppSelector(selectDBResultTable);
  const [activeNodes, setActiveNodes] = useState<ResultTableEntries>([]);
  const [expandedKeys, setExpandedKeys] = useState<ReactKeys>([]);
  const [checkedKeys, setCheckedKeys] = useState<ReactKeys>([]);
  const [, { data: databaseResults }] = useSaveDatabaseMutation({ fixedCacheKey: 'databaseResults' });

  useLayoutEffect(() => {
    const activeEntries = getDbActiveEntries(resultTableState);
    setActiveNodes(activeEntries);
    setCheckedKeys(getDbVisibleEntries(activeEntries));
  }, [resultTableState]);

  const handleTreeExpand = useCallback((keys: ReactKeys) => {
    setExpandedKeys(keys);
  }, []);

  const handleTreeCheck = useCallback(
    (keys: TreeCheckedKeys | ReactKeys) => {
      if (Array.isArray(keys)) {
        setCheckedKeys((prevKeys) => {
          if (prevKeys.length > keys.length) {
            prevKeys
              .filter((key) => !keys.includes(key) && key !== 'tableController')
              .forEach((key) => {
                updateResultTable({
                  stringKeys: key.toString(),
                  value: { visible: false },
                });
              });
          }

          if (prevKeys.length < keys.length) {
            keys
              .filter((key) => !prevKeys.includes(key) && key !== 'tableController')
              .forEach((key) => {
                updateResultTable({
                  stringKeys: key.toString(),
                  value: { visible: true },
                });
              });
          }

          return keys;
        });
      }
    },
    [resultTableState],
  );

  function renderAdditionalFilters(nodes: ResultTableEntries): DataNode[] {
    return nodes.map(([key]) => ({
      key,
      checkable: true,
      disabled: !databaseResults || !databaseResults.length,
      title: <TranslatedText textKey={DB_TABLE_COLUMN_TITLES[key as keyof typeof DB_TABLE_COLUMN_TITLES]} />,
      children: undefined,
    }));
  }

  return (
    <div className="table-controller">
      <Tree
        checkable
        selectable={false}
        expandedKeys={expandedKeys}
        checkedKeys={checkedKeys}
        onExpand={handleTreeExpand}
        onCheck={handleTreeCheck}
        treeData={[
          {
            children: renderAdditionalFilters(activeNodes),
            key: 'tableController',
            title: <TranslatedText textKey="Table Controller" />,
            disabled: !databaseResults || !databaseResults.length,
          },
        ]}
      />
    </div>
  );
};
