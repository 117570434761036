import { RadioGroupData } from '../types';

export const FLAWS_ANALYSIS = {
  sms: 'SMS',
  gost: 'GOST',
  astm: 'ASTM',
} as const;

export const flawsAnalysisData: RadioGroupData[] = [
  {
    value: FLAWS_ANALYSIS.sms,
    label: FLAWS_ANALYSIS.sms,
  },
  {
    value: FLAWS_ANALYSIS.gost,
    label: FLAWS_ANALYSIS.gost,
  },
  {
    value: FLAWS_ANALYSIS.astm,
    label: FLAWS_ANALYSIS.astm,
  },
];

export const DEFAULT_ZONES_PARAMS = {
  x: 0,
  y: 0,
  x0: 0,
  x1: 0,
  y0: 0,
  y1: 0,
  outerDiameter: 0,
  innerDiameter: 0,
};
