import './styles.scss';

import React, { memo } from 'react';

import { SegAnalysis } from './SegAnalysis';
import { SegSettings } from './SegSettings';
import { SegStatistics } from './SegStatistics';

export const SegSideMenu = memo(function SegSideMenu() {
  return (
    <div className="segregation__menu">
      <SegSettings />
      <SegAnalysis />
      <SegStatistics />
    </div>
  );
});
